import algoliasearch from 'algoliasearch'
import download from 'downloadjs'
import { Field, Form, Formik } from 'formik'
import * as htmlToImage from 'html-to-image'
import moment from 'moment-timezone'
import 'moment/locale/es'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import {
	Input,
	Loading,
	ModalForm,
	More,
	Popover,
	TablesMap,
} from '../../../components'
import { firestore, functions } from '../../../config/firebase'
import { create_alert } from '../../../store/actions'
import getmail from './getmail'

const searchClient = algoliasearch(
	'MM6KWVFEQD',
	'fce390db586e17fe0242f65ae2dc9c3c'
)

moment.locale('es')

function Reservations({
	place,
	tables,
	language,
	topbar,
	creatingReservation,
	setCreatingReservation,
	create_alert,
	dayViewing,
	covid,
	search,
	setSearch,
	user,
	employee,
	setSearchChanged,
	setDoSearch,
	doSearch,
}) {
	const [submitting, setSubmitting] = useState(false)
	const [selectTable, setSelectTable] = useState(null)
	const [selectedTable, setSelectedTable] = useState(null)
	const [selectedBlock, setSelectedBlock] = useState(null)
	const [selectedSlot, setSelectedSlot] = useState(null)
	const [edit, setEdit] = useState(null)
	const [duplicate, setDuplicate] = useState(null)
	const [downloadReservation, setDownloadReservation] = useState(null)
	const [dayOccupied, setDayOccupied] = useState(true)
	const [newClient, setNewClient] = useState(true)
	const [selectedClient, setSelectedClient] = useState(null)
	const [disabled, setDisabled] = useState(false)
	const [topStatus, setTopStatus] = useState([0, 0])
	const [slotSelectStatus, setSlotSelectStatus] = useState(null)
	const [createDaySelectedData, setCreateDaySelectedData] = useState(null)
	const [dayQueue, setDayQueue] = useState([])
	const [loadingMainData, setLoadingMainData] = useState(false)
	const [searchResults, setSearchResults] = useState([])
	const [type, setType] = useState(null)
	const [confirmationsResponse, setConfirmationsResponse] = useState([])

	useEffect(() => {
		if (downloadReservation !== null) {
			let imgsrc =
				typeof place.smtp !== 'undefined' ? place.smtp.image || null : null

			if (imgsrc === null) {
				setTimeout(() => {
					printReservation(downloadReservation[0])
				}, 500)
			}
		}
	}, [downloadReservation])

	useEffect(() => {
		let dateObj = new Date(dayViewing)
		let add0 = (value) => `0${value}`.slice(-2)
		let dateFormat = `${add0(dateObj.getDate())}/${add0(
			`${dateObj.getMonth() + 1}`
		)}/${dateObj.getFullYear()}`

		let listener = firestore
			.collection(`/confirmations`)
			.where('data.date_block', 'array-contains', dateFormat)
			.onSnapshot((snap) => {
				snap = snap.docs.map((d) => d.data())
				snap = snap.filter((f) => f.data.place === place.id)
				setConfirmationsResponse(snap || [])
			})

		return () => {
			listener()
		}
	}, [dayViewing])

	useEffect(() => {
		// 1 is restaurant
		// 2 is hotel
		if (typeof place.groups === 'object') {
			if (place.groups.length > 0) {
				if (place.groups[0].category === 'hotel') setType(0)
				else setType(1)
			} else setType(1)
		} else setType(1)

		if (
			covid.current &&
			covid.current.block.index !== -1 &&
			covid.current.slot !== null
		) {
			let block = covid.config.blocks.find(
				(b, i) => b.id === covid.current.block.id
			)

			if (typeof block !== 'undefined') {
				let first_block =
					typeof block.slots[0].text !== 'undefined'
						? block.slots[0].text[0]
						: getFormattedHour(new Date(block.slots[0].starts))
				let last_block =
					typeof block.slots[block.slots.length - 1].text !== 'undefined'
						? block.slots[block.slots.length - 1].text[1]
						: getFormattedHour(
								new Date(block.slots[block.slots.length - 1].ends)
						  )
				setSelectedBlock({
					id: block.id,
					index: covid.current.block.index,
					text: `${first_block} - ${last_block}`,
				})

				let slot = covid.current.slot

				let start =
					typeof slot.text !== 'undefined'
						? slot.text[0]
						: getFormattedHour(new Date(slot.starts))
				let ends =
					typeof slot.text !== 'undefined'
						? slot.text[1]
						: getFormattedHour(new Date(slot.ends))

				setSelectedSlot({
					id: slot.id,
					index: 0,
					text: `${start} - ${ends}`,
				})

				setSelectedBlock({
					id: block.id,
					index: covid.current.block.index,
					text: `${first_block} - ${last_block}`,
				})
			}
		} else {
			let block = covid.config.blocks[0]

			if (typeof block === 'undefined') {
				// Nothing
			} else {
				let first_block =
					typeof block.slots[0].text !== 'undefined'
						? block.slots[0].text[0]
						: getFormattedHour(new Date(block.slots[0].starts))
				let last_block =
					typeof block.slots[block.slots.length - 1].text !== 'undefined'
						? block.slots[block.slots.length - 1].text[0]
						: getFormattedHour(
								new Date(block.slots[block.slots.length - 1].ends)
						  )
				setSelectedBlock({
					id: block.id,
					index: 0,
					text: `${first_block} - ${last_block}`,
				})
			}
		}
	}, [])

	useEffect(() => {
		setLoadingMainData(true)
		let dayOfTheWeek = new Date(dayViewing).getDay()
		dayOfTheWeek--
		if (dayOfTheWeek === -1) dayOfTheWeek = 6

		let blocksInThatDay = covid.config.blocks.filter(
			(b) => b.days.indexOf(dayOfTheWeek) !== -1
		)

		setDayOccupied(blocksInThatDay.length > 0)

		let numberDate = getNumberDate(new Date(dayViewing).getTime())

		let listener = firestore
			.doc(`/places/${place.id}/advanced_res_queue/${numberDate}`)
			.onSnapshot(
				(snap) => {
					if (snap.exists) {
						setDayQueue(snap.data().data)
					} else {
						setDayQueue([])
					}
					setLoadingMainData(false)
				},
				(e) => {
					console.log('ERROR', e)
				}
			)

		return () => {
			setLoadingMainData(true)
			listener()
		}
	}, [dayViewing])

	useEffect(() => {
		getTopData()
	}, [dayQueue])

	useEffect(() => {
		if (doSearch === true) {
			if (typeof search === 'string') {
				setSearchResults(null)
				getResults()
			} else {
				setSearchResults([])
			}
			setDoSearch(false)
			setSearchChanged(false)
		}
	}, [doSearch])

	const getResults = async () => {
		try {
			await searchClient.clearCache()
			const index = searchClient.initIndex('reservation_queue')
			const { hits } = await index.search(search, {
				facetFilters: [[`owner:${user.id}`], [`place:${place.id}`]],
			})
			setSearchResults(hits)
		} catch (e) {
			console.log('ERRO', e)
		}
	}

	const getTopData = () => {
		if (!dayQueue) return
		let queue = [...dayQueue]
		let queue_people = 0

		if (queue.length > 0)
			queue_people += queue.reduce((a, b) => a + b.people, 0)

		setTopStatus([queue.length, queue_people])
	}

	const renderQueue = () => {
		if (selectedBlock === null || !dayQueue) return null

		let queue = [...dayQueue]
		let all_tables = []
		tables.floors.forEach((floor) => {
			all_tables = [...all_tables, ...floor.tables]
		})

		let block = covid.config.blocks.find((b) => b.id === selectedBlock.id)

		let empty_dom = 0
		let slots_dom = null

		if (typeof block !== 'undefined') {
			let slots = []
			block.slots.forEach((slot, y) => {
				if (selectedSlot !== null && selectedSlot.id !== slot.id) {
					// Nothing
				} else {
					let starts =
						typeof slot.text !== 'undefined'
							? slot.text[0]
							: getFormattedHour(new Date(slot.starts))
					let ends =
						typeof slot.text !== 'undefined'
							? slot.text[1]
							: getFormattedHour(new Date(slot.ends))
					let blockIndex = -1

					covid.config.blocks.forEach((b, z) => {
						if (b.id === block.id) blockIndex = z
					})

					slots.push({
						...slot,
						blockIndex: selectedBlock.index,
						slotIndex: y,
						texts: {
							block: `Turno #${blockIndex + 1}`,
							slot: `Franja #${y + 1}`,
							time: `${starts} - ${ends}`,
						},
					})
				}
			})

			slots_dom = slots.map((slot, x) => {
				let sum = 0
				let queue_items = queue.filter((q) => {
					return slot.id === q.slot.slot
				})

				let cancelled_items = confirmationsResponse.filter((q) => {
					return slot.id === q.data.slot.slot && q.response === false
				})
				cancelled_items = cancelled_items.map((c) => ({
					...c.data,
					cancelled: true,
				}))

				queue_items = [...queue_items, ...cancelled_items]

				queue_items = queue_items.sort((a, b) => a.added - b.added)

				queue_items.forEach((i) => {
					if (i.cancelled !== true) {
						sum += i.people
					}
				})

				empty_dom += queue_items.length

				return (
					<>
						<thead key={slot.id} className="thead-sep">
							<tr>
								<th
									colSpan={type === 0 ? 9 : 7}
									className="spacer"
								></th>
							</tr>
							<tr>
								<th
									colSpan={type === 0 ? 9 : 7}
									className={`subhead center`}
								>
									<span className="badge badge-primary">
										<b>{slot.texts.time}</b>
									</span>{' '}
									<span>
										Total personas: <b>{sum}</b>
									</span>{' '}
									<span>
										Máximo: <b>{slot.max}</b>
									</span>{' '}
									<span
										className={`badge badge-${
											slot.max - sum > 5
												? 'success'
												: slot.max - sum > 0
												? 'warning'
												: 'danger'
										}`}
										style={{
											color: 'white',
											borderRadius: 5,
										}}
									>
										Restantes:{' '}
										<b style={{ color: 'white' }}>{slot.max - sum}</b>
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{queue_items.map((item, i) => {
								let tables_indexes = all_tables.filter(
									(table) => parseInt(table.seats) >= item.people
								)
								tables_indexes = tables_indexes.map((table) => table.id)
								place.orders.forEach((ord) => {
									let index = tables_indexes.indexOf(ord.table)
									if (index !== -1) tables_indexes.splice(index, 1)
								})

								let status = 'Esperando mesa'

								if (item.status && item.status.table !== null)
									status = `Mesa ${item.status.table.name}`

								if (item.status && item.status.assist !== null) {
									if (item.status.assist === false)
										status = `No asistió`
									else status += ` | Pasó`
								}

								let response = 'Sin Respuesta'
								let color = '#c0c0c0'
								let itemInResponse = confirmationsResponse.find(
									(f) => f.id === item.id
								)

								if (typeof itemInResponse === 'object') {
									if (itemInResponse.response === true) {
										response = 'Confirmado'
										color = '#42c972'
									} else if (itemInResponse.response === false) {
										response = 'Cancelado'
										color = '#eb4d5d'
									}
								}

								return (
									<tr key={item.id}>
										<td>
											<span className="badge">{i + 1}</span>
										</td>
										<td>
											<strong>{item.name}</strong>
										</td>
										{type === 0 && (
											<>
												<td>
													<strong>{item.room}</strong>
												</td>
												<td>
													{typeof item.group === 'object' && (
														<Popover
															content={item.group.description}
															offset={10}
															offsetY={-5}
															reversed
														>
															<strong>
																{item.group.initials}
															</strong>
														</Popover>
													)}
												</td>
											</>
										)}
										<td>
											{item.adults > 0 && `Adultos ${item.adults}, `}{' '}
											{item.children > 0 &&
												`Niños ${item.children}, `}{' '}
											{`Total ${item.people}`}
										</td>
										<td>
											{typeof item.notes === 'string' &&
												item.notes.length > 0 && (
													<Popover
														content={item.notes}
														offset={10}
														offsetY={-5}
														reversed
													>
														<strong>
															{item.notes.slice(0, 20)}
															{item.notes.length > 20 && '...'}
														</strong>
													</Popover>
												)}
										</td>
										<td
											className={`email-cell ${
												item.email.length > 0
													? 'has-email'
													: 'no-email'
											}`}
										>
											<span>
												{item.email.length > 0
													? item.email
													: 'Falta Correo'}
											</span>
										</td>
										<td>
											<div
												style={{
													display: 'block',
												}}
											>
												<div
													style={{
														width: 20,
														height: 20,
														borderRadius: 10,
														backgroundColor: color,
														display: 'inline-block',
														verticalAlign: 'middle',
														marginRight: 10,
													}}
												/>
												<p
													style={{
														display: 'inline-block',
													}}
												>
													{response}
												</p>
											</div>
										</td>
										<td>
											{item.cancelled !== true && (
												<More>
													<button
														onClick={() => setSelectTable(item)}
														type="button"
														className="button button-outline button-small"
														title="Asignar"
														disabled={submitting}
													>
														<i className="mi">bookmark</i>
														<span>Asignar</span>
													</button>
													<button
														onClick={() => {
															setDownloadReservation([item])
														}}
														type="button"
														className="button button-outline button-small"
														title="Descargar"
														disabled={submitting}
													>
														<i className="mi">print</i>
														<span>Descargar</span>
													</button>
													<button
														type="button"
														className="button button-outline button-small"
														title="Descargar"
														disabled={submitting}
														onClick={async () => {
															let ht = getEmailHtml({
																...item,
																translation: getTextContent(
																	item.lang
																),
															})
															sendEmail(
																ht,
																item.email,
																item.lang,
																item.date_block[0]
															)
														}}
													>
														<i className="mi">email</i>
														<span>Reenviar Email</span>
													</button>
													<button
														onClick={() => {
															setNewClient(true)
															setEdit(item)
														}}
														type="button"
														className="button button-outline button-small"
														title={'Ver y editar'}
														disabled={submitting}
													>
														<i className="mi">create</i>
														<span>Ver y editar</span>
													</button>
													<button
														onClick={() =>
															continueReservation(true, item)
														}
														type="button"
														className="button button-outline button-small"
														disabled={submitting}
														title="Continue"
													>
														<i className="mi">done</i>
														<span>Pasar</span>
													</button>
													<button
														onClick={() =>
															continueReservation(false, item)
														}
														type="button"
														className="button button-outline button-small"
														disabled={submitting}
														title="Continue"
													>
														<i className="mi">close</i>
														<span>No asiste</span>
													</button>
													<button
														onClick={() => {
															setDuplicate(true)
															setEdit(item)
														}}
														type="button"
														className="button button-outline button-small"
														title={'Duplicar'}
														disabled={submitting}
													>
														<i className="mi">content_copy</i>
														<span>Duplicar</span>
													</button>
													<button
														onClick={() => removeItem(item)}
														type="button"
														className="button button-outline button-small"
														title={language.cancel}
														disabled={submitting}
													>
														<i className="mi">delete</i>
														<span>Borrar</span>
													</button>
												</More>
											)}
										</td>
									</tr>
								)
							})}
						</tbody>
					</>
				)
			})
		}

		if (
			(empty_dom === 0 && selectedSlot !== null) ||
			typeof block === 'undefined'
		)
			return (
				<aside className="empty">
					<i className="mi">class</i>
					<h3>No hay reservas en esta franja</h3>
				</aside>
			)

		return (
			<div className="table-ui">
				<div>
					<table>
						<thead>
							<tr>
								<th>Posición</th>
								<th>Nombre</th>
								{type === 0 && (
									<>
										<th>Habitación</th>
										<th>Pensión</th>
									</>
								)}
								<th>Personas</th>
								<th>Notas</th>
								<th>Correo</th>
								<th>Respuesta</th>
								<th></th>
							</tr>
						</thead>
						{slots_dom}
					</table>
				</div>
			</div>
		)
	}

	const removeItem = async (item = null) => {
		if (item === null) return
		setSubmitting(true)

		let numberDate = getNumberDate(item.date_block[0])
		let dayRef = await firestore.doc(
			`/places/${place.id}/advanced_res_queue/${numberDate}`
		)
		let remove = functions.httpsCallable('removeAdvancedReservationQueue')

		await remove({
			id: item.id,
		})

		await firestore.runTransaction(async (transaction) => {
			let data = await transaction.get(dayRef)
			data = data.data().data

			transaction.update(dayRef, {
				data: data.filter((f) => f.id !== item.id),
			})
		})

		setSearch('')
		setSubmitting(false)
	}

	const addToQueue = async (values) => {
		try {
			setSubmitting(true)
			if (values.date === null) throw new Error('Error')

			let adults = typeof values.adults === 'number' ? values.adults : 0
			let children =
				typeof values.children === 'number' ? values.children : 0

			let id = `manually_${Date.now()}_${Math.floor(
				Math.random() * 100000000
			)}`
			let id_client = `client_${Date.now()}_${Math.floor(
				Math.random() * 10000000
			)}`

			let client = {
				id: id_client,
				name: values.full_name,
				added: Date.now(),
				people: adults + children,
				adults,
				children,
				room: values.room,
				phone: values.phone,
				email: values.email,
				lang: values.lang,
				group: values.group,
				from: values.from !== null ? values.from.getTime() : null,
				to: values.to !== null ? values.to.getTime() : null,
				owner: user.id,
				place: place.id,
				placeName: place.name,
			}

			if (!newClient)
				client = {
					id: selectedClient.id,
					name: selectedClient.name,
					added: selectedClient.added,
					people: adults + children,
					adults: adults,
					children: children,
					room: selectedClient.room,
					phone: selectedClient.phone,
					email: selectedClient.email,
					lang: selectedClient.lang,
					group: selectedClient.group,
					from: selectedClient.from || null,
					to: selectedClient.to || null,
					place: place.id,
					placeName: place.name,
					owner: user.id,
				}

			let numberDate = getNumberDate(values.date.getTime())

			let itemRef = firestore.doc(
				`/places/${place.id}/advanced_res_queue/${numberDate}`
			)

			firestore
				.runTransaction(async (transaction) => {
					try {
						let itemData = await transaction.get(itemRef)

						let action = 'update'
						if (!itemData.exists) {
							action = 'set'
							itemData = { data: [] }
						} else itemData = itemData.data()

						let queue_item = {
							...client,
							id,
							client_id: client.id,
							added: Date.now(),
							date: values.date !== null ? values.date.getTime() : null,
							slot: values.slot,
							notes: values.notes,
							status: {
								table: null,
								assist: null,
							},
							date_block: getDateBlock(values, covid.config),
						}

						let slot_data = itemData.data.filter(
							(f) => f.slot.slot === values.slot.slot
						)

						let total = [...slot_data].reduce((p, c, i) => {
							return p + c.people
						}, 0)

						let block_dat = covid.config.blocks.find(
							(b) => b.id === values.slot.block
						)
						if (typeof block_dat === 'undefined')
							throw new Error('Error block')

						let slot_dat = block_dat.slots.find(
							(b) => b.id === values.slot.slot
						)
						if (typeof slot_dat === 'undefined')
							throw new Error('Error slot')

						if (
							total + client.people >
							covid.config.max_slot + slot_dat.max
						)
							throw new Error('Limite sobrepasado')

						await transaction[action](itemRef, {
							data: [queue_item, ...itemData.data],
						})

						if (newClient) {
							for (let x = 0; x < user.places.length; x++) {
								const _place = user.places[x]
								let plcRf = firestore.doc(
									`/places/${_place}/advanced_res_clients/${client.id}`
								)
								await transaction.set(plcRf, client)
							}
						}

						let fn = functions.httpsCallable('saveConfirmationDocs')
						let long_id = await fn({
							reservation: {
								...queue_item,
							},
						})

						long_id = long_id.data

						return [
							getEmailHtml({
								...queue_item,
								translation: getTextContent(queue_item.lang),
							}),
							queue_item,
							itemData.data,
							getEmailHtml(
								{
									...queue_item,
									translation: getTextContent(queue_item.lang),
								},
								long_id
							),
						]
					} catch (e) {
						console.log('ERROR #4', e)
					}
				})
				.then(
					async ([
						html_string,
						queue_data,
						itemData,
						reminder_html_string,
					]) => {
						console.log('LONG HTML', reminder_html_string)
						try {
							console.log('CREATION TEST', queue_data, itemData)
							setDownloadReservation([
								queue_data,
								values.email_notification_before,
							])
							if (newClient) {
								let fn = functions.httpsCallable(
									'saveAdvancedReservationClients'
								)
								fn({
									id: id_client,
									item: client,
								})
							}
							let _fn = functions.httpsCallable(
								'saveAdvancedReservationQueue'
							)
							_fn({
								id,
								item: queue_data,
							})

							if (
								values.email_notification_now &&
								client.email.length > 6
							) {
								sendEmail(
									html_string,
									client.email,
									client.lang,
									queue_data.date_block[0]
								)
							}
							if (
								values.email_notification_before &&
								client.email.length > 6
							) {
								scheduleNotification(queue_data, reminder_html_string)
							}

							setSubmitting(false)
							setCreatingReservation(false)
							setEdit(null)
						} catch (e) {
							console.log('ERROR #5', e)
							create_alert(
								'Error creando reserva, puede que haya sobrepasado el limite',
								'danger'
							)
						}
					}
				)
				.catch((e) => {
					setSubmitting(false)
					console.log('ERROR #6', e)
					create_alert(
						'Error creando reserva, puede que haya sobrepasado el limite',
						'danger'
					)
				})
		} catch (e) {
			console.log('ERROR #7', e)
			create_alert(
				'Error creando reserva, puede que haya sobrepasado el limite',
				'danger'
			)
		}
	}

	const getEmailHtml = (item, long_id = false) => {
		try {
			let placeName = place.name
			let inBlackId = 'asia_gardens_in_black'
			let ref = `${Math.round(Math.random() * 10000)}_${getFormattedRefDate(
				new Date(item.added)
			)}`

			let slot = null
			covid.config.blocks.forEach((b) => {
				b.slots.forEach((s) => {
					if (s.id === item.slot.slot) slot = s
				})
			})
			let str = getmail(
				{
					place: placeName,
					image:
						typeof place.smtp !== 'undefined'
							? place.smtp.image || null
							: null,
					ref,
					added: getFormattedDate(new Date(item.added)),
					by: employee.name,
					name: item.name,
					date: `${item.date_block[0]} - ${
						typeof slot.text !== 'undefined'
							? slot.text[0]
							: getFormattedHour(new Date(slot.starts))
					}`,
					room: item.room,
					people: item.people,
					translation: item.translation,
				},
				type === 0,
				place.id === inBlackId,
				long_id || false
			)
			return str
		} catch (error) {
			console.log('ERROR #6', error)
		}
	}

	const sendEmail = async (html_string, email, lang, date) => {
		if (email.length > 0 && typeof place.smtp !== 'undefined') {
			let subj = ''
			switch (lang) {
				case 'fr':
					subj = `Votre réservation à ${place.name} le ${date}`
					break
				case 'de':
					subj = `Ihre Reservierung in ${place.name} für den ${date}`
					break
				case 'it':
					subj = `La tua prenotazione in ${place.name} per il ${date}`
					break
				case 'en':
					subj = `Your reservation in ${place.name} for ${date}`
					break
				default:
					subj = `Su reserva en ${place.name} para el ${date}`
					break
			}
			console.log('EMAIL DATA', html_string)
			let fn = functions.httpsCallable('sendMail')
			let res = await fn({
				place_id: place.id,
				to: email,
				subject: subj,
				html: html_string,
				secure: place.smtp.secure,
				from: `${place.name} <${place.smtp.email}>`,
			})
			create_alert(`Email enviado a ${email}`, 'success')
			return res.data
		}
		return null
	}

	const editQueue = async (values) => {
		setSubmitting(true)

		let adults = typeof values.adults === 'number' ? values.adults : 0
		let children = typeof values.children === 'number' ? values.children : 0

		let fromDate = getNumberDate(edit.date_block[0])
		let toDate = getNumberDate(values.date.getTime())

		delete edit._highlightResult

		// UPDATE RESERVATIONS
		if (fromDate === toDate) {
			let dayRef = await firestore.doc(
				`/places/${place.id}/advanced_res_queue/${toDate}`
			)

			await firestore.runTransaction(async (transaction) => {
				try {
					let resData = await transaction.get(dayRef)
					resData = resData.data()

					await transaction.update(dayRef, {
						data: resData.data.map((q) => {
							if (q.id !== edit.id) return q
							return {
								...edit,
								name: values.full_name,
								people: adults + children,
								adults,
								children,
								date:
									values.date !== null ? values.date.getTime() : null,
								from:
									values.from !== null ? values.from.getTime() : null,
								to: values.to !== null ? values.to.getTime() : null,
								slot: values.slot,
								room: values.room,
								notes: values.notes,
								phone: values.phone,
								email: values.email,
								lang: values.lang,
								group: values.group,
								place: place.id,
								placeName: place.name,
								date_block: getDateBlock(values, covid.config),
							}
						}),
					})
				} catch (e) {
					return false
				}
			})
		} else {
			let dayFromRef = await firestore.doc(
				`/places/${place.id}/advanced_res_queue/${fromDate}`
			)
			let dayToRef = await firestore.doc(
				`/places/${place.id}/advanced_res_queue/${toDate}`
			)

			await firestore.runTransaction(async (transaction) => {
				try {
					let resFrom = await transaction.get(dayFromRef)
					resFrom = resFrom.data()

					let action = 'update'
					let resTo = await transaction.get(dayToRef)
					if (resTo.exists) resTo = resTo.data().data
					else {
						action = 'set'
						resTo = []
					}

					await transaction.update(dayFromRef, {
						data: resFrom.data.filter((q) => q.id !== edit.id),
					})

					await transaction[action](dayToRef, {
						data: [
							...resTo,
							{
								...edit,
								name: values.full_name,
								people: adults + children,
								adults,
								children,
								date:
									values.date !== null ? values.date.getTime() : null,
								from:
									values.from !== null ? values.from.getTime() : null,
								to: values.to !== null ? values.to.getTime() : null,
								slot: values.slot,
								room: values.room,
								notes: values.notes,
								phone: values.phone,
								email: values.email,
								lang: values.lang,
								group: values.group,
								place: place.id,
								placeName: place.name,
								date_block: getDateBlock(values, covid.config),
							},
						],
					})
				} catch (e) {
					console.log('ERROR', e)
					create_alert('Error editando reserva', 'danger')
					return false
				}
			})
		}

		// UPDATE RESERVATION INDEX
		let add_index = functions.httpsCallable('saveAdvancedReservationQueue')
		let res = await add_index({
			id: edit.id,
			item: {
				...edit,
				name: values.full_name,
				people: adults + children,
				adults,
				children,
				date: values.date !== null ? values.date.getTime() : null,
				from: values.from !== null ? values.from.getTime() : null,
				to: values.to !== null ? values.to.getTime() : null,
				slot: values.slot,
				room: values.room,
				notes: values.notes,
				phone: values.phone,
				email: values.email,
				lang: values.lang,
				group: values.group,
				place: place.id,
				placeName: place.name,
				date_block: getDateBlock(values, covid.config),
			},
		})
		create_alert('Reserva modificada', 'success')
		setCreatingReservation(false)
		setEdit(null)
		setSubmitting(false)
		setSearch('')
	}

	const renderSelectTable = () => {
		if (selectTable === null) return null
		return (
			<ModalForm toggle={() => setSelectTable(null)}>
				<div>
					<form
						onSubmit={async (e) => {
							e.preventDefault()
							try {
								setSubmitting(true)
								let newOrder = true
								let _orders = null
								let invoiceID = 0
								let order = null
								let paid = true

								let numberDate = getNumberDate(
									selectTable.date_block[0]
								)

								let placeRef = firestore
									.collection('places')
									.doc(place.id)

								let dayRef = await firestore.doc(
									`/places/${place.id}/advanced_res_queue/${numberDate}`
								)

								firestore
									.runTransaction(async (transaction) => {
										let latestPlace = await transaction.get(placeRef)
										let latestDay = await transaction.get(dayRef)

										latestPlace = latestPlace.data()
										latestDay = latestDay.data()

										// CREATE ORDER

										if (
											typeof latestPlace.invoiceNumb !== 'undefined'
										)
											invoiceID = latestPlace.invoiceNumb + 1
										invoiceID = `_${invoiceID}`

										await transaction.update(placeRef, {
											invoiceNumb: latestPlace.invoiceNumb + 1,
										})

										order = {
											invoiceID: invoiceID,
											address: latestPlace.address.value,
											date: Date.now(),
											items: [],
											table: selectedTable.id,
											confirmed: true,
											invoiceNumb: latestPlace.invoiceNumb + 1,
											notes: [null],
											status: 1,
											hotel: {
												room: selectTable.room,
												name: selectTable.name,
											},
										}

										_orders = [...latestPlace.orders, order]

										await transaction.update(placeRef, {
											orders: _orders,
										})

										// UPDATE RESERVATION
										transaction.update(dayRef, {
											data: latestDay.data.map((m) => {
												if (m.id !== selectTable.id) return m
												return {
													...m,
													status: {
														...m.status,
														table: {
															id: selectedTable.id,
															name: selectedTable.name,
														},
													},
												}
											}),
										})
									})
									.then(async () => {
										setSubmitting(false)
										setSelectTable(null)
										setSearch('')
									})
									.catch((e) => {
										console.log('ERROR #7', e)
										create_alert('Error asignando mesa', 'danger')
										setSubmitting(false)
										setSelectTable(false)
									})
							} catch (e) {
								console.log('ERROR #8', e)
								create_alert('Error asignando mesa', 'danger')
								setSubmitting(false)
								setSelectTable(false)
							}
						}}
						className="form-ui"
					>
						<h1>{language.select_a_table}</h1>
						<div
							style={{
								width: '100%',
								height: 500,
								padding: 20,
								position: 'relative',
							}}
						>
							<TablesMap
								small_version={true}
								only_free
								onTableSelected={(table) => setSelectedTable(table)}
							/>
						</div>
						<footer>
							<button
								disabled={submitting || selectedTable === null}
								type="submit"
								className="button button-block button-primary"
							>
								<span>{language.continue}</span>
							</button>
						</footer>
					</form>
				</div>
			</ModalForm>
		)
	}

	const getFormattedHour = (date) => {
		let _date = new Date(date)

		let hr = _date.getHours()
		let mn = _date.getMinutes()

		if (mn < 30) mn = '00'
		else mn = '30'

		hr = `0${hr}`.slice(-2)
		mn = `0${mn}`.slice(-2)

		return `${hr}:${mn}`
	}

	const getNumberDate = (date) => {
		if (typeof date === 'number') {
			let dt = new Date(date)
			let val = `${dt.getFullYear()}${`0${dt.getMonth() + 1}`.slice(
				-2
			)}${`0${dt.getDate()}`.slice(-2)}`
			return parseInt(val)
		} else {
			let dat = `${date}`
			dat = [...dat.split('/')]
			dat.reverse()
			dat = dat.join('')
			dat = parseInt(dat)
			return dat
		}
	}

	const renderSlots = ({ values, setFieldValue }) => {
		let dayOfTheWeek = values.date.getDay()
		dayOfTheWeek--
		if (dayOfTheWeek === -1) dayOfTheWeek = 6

		let blocksInThatDay = covid.config.blocks.filter(
			(b) => b.days.indexOf(dayOfTheWeek) !== -1
		)

		if (blocksInThatDay.length === 0)
			return (
				<div className="alert alert-warning">
					<i className="mi">warning</i>
					<p>No hay ningun turno creado para ese día</p>
				</div>
			)

		let options = []
		blocksInThatDay.forEach((block, x) => {
			block.slots.forEach((slot, y) => {
				let starts =
					typeof slot.text !== 'undefined'
						? slot.text[0]
						: getFormattedHour(new Date(slot.starts))
				let ends =
					typeof slot.text !== 'undefined'
						? slot.text[1]
						: getFormattedHour(new Date(slot.ends))
				let blockIndex = -1

				covid.config.blocks.forEach((b, z) => {
					if (b.id === block.id) blockIndex = z
				})

				options.push({
					value: { block: block.id, slot: slot.id },
					label: `Turno #${blockIndex + 1}, Franja #${
						y + 1
					} | ${starts} - ${ends}`,
				})
			})
		})

		let defaultVal = null

		if (edit !== null) {
			let bloi = -1
			let sloi = -1
			let blo = blocksInThatDay.find((block, x) => {
				if (block.id === edit.slot.block) {
					bloi = x
					return true
				}
				return false
			})
			if (typeof blo !== 'undefined') {
				let slo = blo.slots.find((slot, y) => {
					if (slot.id === edit.slot.slot) {
						bloi = y
						return true
					}
					return false
				})
				if (typeof slo !== 'undefined') {
					let starts =
						typeof slo.text !== 'undefined'
							? slo.text[0]
							: getFormattedHour(new Date(slo.starts))
					let ends =
						typeof slo.text !== 'undefined'
							? slo.text[1]
							: getFormattedHour(new Date(slo.ends))

					defaultVal = `Turno #${bloi + 1}, Franja #${
						sloi + 1
					} | ${starts} - ${ends}`
				}
			}
		}

		if (values.slot !== null) {
			let bloc = covid.config.blocks.find((b) => b.id === values.slot.block)
			if (typeof bloc !== 'undefined') {
				let slo = bloc.slots.find((s) => s.id === values.slot.slot)
				if (typeof slo !== 'undefined') {
					let total_slot_people = 0
					createDaySelectedData.forEach((q) => {
						if (q.slot.slot === slo.id) total_slot_people += q.people
					})

					let adults =
						typeof values.adults === 'number' ? values.adults : 0
					let children =
						typeof values.children === 'number' ? values.children : 0

					let new_people = total_slot_people + adults + children
					if (edit !== null) {
						new_people -= edit.people
					}

					let max_slot = covid.config.max_slot || 0

					if (new_people > slo.max + max_slot) {
						setDisabled(true)
						setSlotSelectStatus(new_people - slo.max)
					} else {
						setSlotSelectStatus(null)
						setDisabled(false)
					}
				}
			}
		}

		if (values.slot !== null) {
			defaultVal = options.find((f) => f.value.slot === values.slot.slot)
			if (typeof defaultVal === 'undefined') defaultVal = null
		}

		return (
			<div className="form-group upper-7">
				<div className="form-row">
					<div className="col-3">
						<label>Franja Horaria:</label>
					</div>
					<div className="col-9">
						<Select
							disabled={slotSelectStatus === 'loading'}
							className="react-select"
							classNamePrefix="react-select"
							options={options}
							value={{
								value: values.slot,
								label: defaultVal === null ? '' : defaultVal.label,
							}}
							onChange={(e) => setFieldValue('slot', e.value)}
						/>
					</div>
				</div>
			</div>
		)
	}

	const renderSelectSlot = () => {
		if (selectedBlock === null) return null
		let slots = []

		let block = covid.config.blocks.find((b) => b.id === selectedBlock.id)

		if (typeof block !== 'undefined')
			slots = block.slots.map((s, x) => {
				try {
					let start =
						typeof s.text !== 'undefined'
							? s.text[0]
							: getFormattedHour(new Date(s.starts))
					let ends =
						typeof s.text !== 'undefined'
							? s.text[1]
							: getFormattedHour(new Date(s.ends))
					return (
						<li key={s.id}>
							<button
								className={`${
									selectedSlot !== null && selectedSlot.id === s.id
										? 'active'
										: ''
								}`}
								onClick={() => {
									setSelectedSlot({
										id: s.id,
										index: x,
										text: `${start} - ${ends}`,
									})
								}}
							>
								<span>
									{start} - {ends}
								</span>
							</button>
						</li>
					)
				} catch (e) {
					return null
				}
			})

		return (
			<div className="subcategories-ui">
				<div>
					<ul className={`count-2`}>
						<li key="all">
							<button
								className={`${selectedSlot === null ? 'active' : ''}`}
								onClick={() => {
									setSelectedSlot(null)
								}}
							>
								<span>Todas las franjas</span>
							</button>
						</li>
						{slots}
					</ul>
				</div>
			</div>
		)
	}

	const renderBlocks = () => {
		let dayOfTheWeek = new Date(dayViewing).getDay()
		dayOfTheWeek--
		if (dayOfTheWeek === -1) dayOfTheWeek = 6

		let blocksInThatDay = covid.config.blocks.filter(
			(b) => b.days.indexOf(dayOfTheWeek) !== -1
		)

		let blocks = blocksInThatDay.map((b, x) => {
			let first =
				typeof b.slots[0].text !== 'undefined'
					? b.slots[0].text[0]
					: getFormattedHour(new Date(b.slots[0].starts))
			let last =
				typeof b.slots[b.slots.length - 1].text !== 'undefined'
					? b.slots[b.slots.length - 1].text[0]
					: getFormattedHour(new Date(b.slots[b.slots.length - 1].ends))
			return (
				<li key={b.id}>
					<button
						className={`${
							selectedBlock !== null && selectedBlock.id === b.id
								? 'active'
								: ''
						}`}
						onClick={() => {
							setSelectedBlock({
								id: b.id,
								index: x,
								text: `${first} - ${last}`,
							})
							setSelectedSlot(null)
						}}
					>
						<span>
							Turno {first} - {last}
						</span>
					</button>
				</li>
			)
		})

		return (
			<div className="categories-ui">
				<div>
					<ul className={`count-2`}>{blocks}</ul>
				</div>
			</div>
		)
	}

	const getFormattedDate = (date) => {
		let _date = new Date(date)

		let dd = _date.getDate()
		let mm = _date.getMonth() + 1
		let yyyy = _date.getFullYear()

		if (dd < 10) dd = '0' + dd
		if (mm < 10) mm = '0' + mm

		return dd + '/' + mm + '/' + yyyy
	}

	const getFormattedRefDate = (date) => {
		let _date = new Date(date)

		let dd = _date.getDate()
		let mm = _date.getMonth() + 1
		let yyyy = `${_date.getFullYear()}`

		if (dd < 10) dd = '0' + dd
		if (mm < 10) mm = '0' + mm

		return dd + '' + mm + '' + yyyy.slice(-2)
	}

	const continueReservation = async (action = true, item = null) => {
		if (item === null) return
		setSubmitting(true)

		let numberDate = getNumberDate(item.date_block[0])
		let dayRef = await firestore.doc(
			`/places/${place.id}/advanced_res_queue/${numberDate}`
		)

		await firestore.runTransaction(async (transaction) => {
			let data = await transaction.get(dayRef)
			data = data.data().data

			transaction.update(dayRef, {
				data: data.map((d) => {
					if (d.id !== item.id) return d
					return {
						...d,
						status: {
							...d.status,
							assist: action,
						},
					}
				}),
			})
		})

		setSearch('')

		setSubmitting(false)
	}

	const renderSearch = () => {
		let items = []
		searchResults.forEach((item) => {
			let block = covid.config.blocks.find((b) => b.id === item.slot.block)
			if (typeof block !== 'undefined') {
				let slot = block.slots.find((b) => b.id === item.slot.slot)
				if (typeof slot !== 'undefined') {
					let blockName = `${
						typeof block.slots[0].text !== 'undefined'
							? block.slots[0].text[0]
							: getFormattedHour(new Date(block.slots[0].starts))
					} - ${
						typeof block.slots[block.slots.length - 1].text !==
						'undefined'
							? block.slots[block.slots.length - 1].text[1]
							: getFormattedHour(
									new Date(block.slots[block.slots.length - 1].ends)
							  )
					}`
					let slotName = `${
						typeof slot.text !== 'undefined'
							? slot.text[0]
							: getFormattedHour(new Date(slot.starts))
					} - ${
						typeof slot.text !== 'undefined'
							? slot.text[1]
							: getFormattedHour(new Date(slot.ends))
					}`
					if (typeof slot !== 'undefined') {
						let status = 'Esperando mesa'

						if (item.status && item.status.table !== null)
							status = `Mesa ${item.status.table.name}`

						if (item.status && item.status.assist !== null) {
							if (item.status.assist === false) status = `No asistió`
							else status += ` | Pasó`
						}

						items.push(
							<tr key={item.id}>
								<td>
									<span className="badge">
										{/* {getFormattedDate(new Date(item.date))} */}
										{item.date_block[0]}
									</span>
								</td>
								<td>
									<strong>
										{blockName} / {slotName}
									</strong>
								</td>
								<td>
									<strong>{item.name}</strong>
								</td>
								{type === 0 && (
									<td>
										<strong>{item.room}</strong>
									</td>
								)}
								<td>{item.people}</td>
								<td>{status}</td>
							</tr>
						)
					}
				}
			}
		})
		return (
			<div className="table-ui">
				<div>
					<table>
						<thead>
							<tr>
								<th>Fecha</th>
								<th>Turno / Franja</th>
								<th>Nombre</th>
								{type === 0 && <th>Habitación</th>}
								<th>Personas</th>
								<th>Respuesta</th>
							</tr>
						</thead>
						{items}
					</table>
				</div>
			</div>
		)
	}

	const getDateBlock = (data, config) => {
		let block = config.blocks.find((e) => data.slot.block === e.id)
		let slot = null
		if (typeof block === 'object') {
			slot = block.slots.find((e) => data.slot.slot === e.id)
			if (typeof slot === 'undefined') return ['', '']
		} else return ['', '']

		return [
			getFormattedDate(data.date),
			`${
				typeof slot.text !== 'undefined'
					? slot.text[0]
					: getFormattedHour(slot.starts)
			} - ${
				typeof slot.text !== 'undefined'
					? slot.text[1]
					: getFormattedHour(slot.ends)
			}`,
			`${
				typeof block.slots[0].text !== 'undefined'
					? block.slots[0].text[0]
					: getFormattedHour(block.slots[0].starts)
			} - ${
				typeof block.slots[block.slots.length - 1].text !== 'undefined'
					? block.slots[block.slots.length - 1].text[0]
					: getFormattedHour(block.slots[block.slots.length - 1].ends)
			}`,
		]
	}

	const getLanguageName = (lang) => {
		switch (lang) {
			case 'es':
				return 'Español'
			case 'en':
				return 'Inglés'
			case 'fr':
				return 'Francés'
			case 'de':
				return 'Alemán'
			case 'it':
				return 'Italiano'
		}
	}

	const getSpainDate = ([hr, mn], mills) => {
		let date = new Date(mills)

		let dt = `${date.toLocaleString('en-us', {
			month: 'short',
		})} ${date.getDate()} ${date.getFullYear()} ${hr}:${`${mn}0`.slice(
			-2
		)}:00 GMT+0200`

		let spainDate = new Date(dt)
		return spainDate.getTime()
	}

	const scheduleNotification = async (data, html) => {
		try {
			let blck = covid.config.blocks.find((b) => b.id === data.slot.block)
			if (typeof blck !== 'undefined') {
				let slt = blck.slots.find((b) => b.id === data.slot.slot)
				if (typeof slt !== 'undefined') {
					let timeDate = new Date(slt.starts)
					let tm = [timeDate.getHours(), timeDate.getMinutes()]

					let spainDate = getSpainDate(tm, data.date)

					await firestore
						.doc(
							`/scheduled_email_notifications/${data.id}_${Math.round(
								spainDate / 10000
							)}`
						)
						.set({
							date: spainDate,
							data,
							html,
							place: place.id,
						})
					create_alert('Notificación programada', 'success')
				}
			}
		} catch (e) {
			create_alert('Error #6', 'danger')
			console.log('ERROR #6', e)
		}
	}

	const printReservation = (res) => {
		if (!isMobile)
			htmlToImage
				.toPng(document.getElementById('PRINT_COVID'))
				.then(function (dataUrl) {
					download(dataUrl, `${place.id}-reservation.png`)
					setDownloadReservation(null)
				})
				.catch((c) => {
					console.log('ERROR # 8', c)
				})
	}

	const getTextContent = (lang) => {
		switch (lang) {
			case 'fr':
				return {
					restaurant: 'Restaurant',
					created: 'Établi',
					attended: 'En présence de',
					public_relations: 'Relations Publiques',
					client: 'Client',
					room: 'Chambre',
					people: 'Gens',
					reservation_date: 'Date et heure des réservations',
				}
			case 'de':
				return {
					restaurant: 'Restaurant',
					created: 'Erstellt',
					attended: 'Begleitet von',
					public_relations: 'Öffentlichkeitsarbeit',
					client: 'Klient',
					room: 'Zimmer',
					people: 'Menschen',
					reservation_date: 'Reservierungsdatum und -zeit',
				}
			case 'it':
				return {
					restaurant: 'Ristorante',
					created: 'Creato',
					attended: 'Frequentato da',
					public_relations: 'Relazioni Pubbliche',
					client: 'Cliente',
					room: 'Camera',
					people: 'Persone',
					reservation_date: 'Data e ora della prenotazione',
				}
			case 'en':
				return {
					restaurant: 'Restaurant',
					created: 'Created',
					attended: 'Attended By',
					public_relations: 'Public Relations',
					client: 'Client',
					room: 'Room',
					people: 'People',
					reservation_date: 'Reservation Date and Time',
				}
			default:
				return {
					restaurant: 'Restaurante',
					created: 'Creada',
					attended: 'Atendido por',
					public_relations: 'Relaciones Públicas',
					client: 'Cliente',
					room: 'Habitación',
					people: 'Personas',
					reservation_date: 'Fecha y hora de reserva',
				}
		}
	}

	const renderDownloadReservation = () => {
		let placeName = place.name
		let ref = `${Math.floor(Math.random() * 10000)}_${getFormattedRefDate(
			new Date(downloadReservation[0].added)
		)}`

		let slot = null
		covid.config.blocks.forEach((b) => {
			b.slots.forEach((s) => {
				if (s.id === downloadReservation[0].slot.slot) slot = s
			})
		})

		if (slot === null) return null

		let txt_content = getTextContent(downloadReservation[0].lang)

		let imgsrc =
			typeof place.smtp !== 'undefined' ? place.smtp.image || null : null

		return (
			<div className="reservation-receipt-ui" id="PRINT_COVID">
				<div>
					<div>
						<div>
							<img
								onLoad={() => {
									printReservation(downloadReservation[0])
								}}
								src={imgsrc}
							/>
							<h1>
								<small>{txt_content.restaurant}</small>{' '}
								<span>{placeName}</span>
							</h1>
							<small className="ref">
								<span>REF:</span> <b>{ref}</b>
							</small>
							<p>
								<span>{txt_content.created}: </span>
								<strong>
									{getFormattedDate(
										new Date(downloadReservation[0].added)
									)}
								</strong>
							</p>
							<p>
								<span>{txt_content.attended}:</span>{' '}
								<strong>{employee.name}</strong>
							</p>
							<p>
								<span>{txt_content.client}:</span>{' '}
								<strong>{downloadReservation[0].name}</strong>
							</p>
							{type === 0 && (
								<p>
									<span>{txt_content.room}:</span>{' '}
									<strong>{downloadReservation[0].room}</strong>
								</p>
							)}
							<p>
								<span>{txt_content.people}:</span>{' '}
								<strong>{downloadReservation[0].people}</strong>
							</p>

							<h1>
								<small>
									<b>{txt_content.reservation_date}:</b>
								</small>
								{downloadReservation[0].date_block[0]} -{' '}
								{typeof slot.text !== 'undefined'
									? slot.text[0]
									: getFormattedHour(new Date(slot.starts))}
							</h1>
						</div>
					</div>
				</div>
			</div>
		)
	}

	const renderTop = () => {
		return (
			<div className="indicators-ui">
				<div>
					<div>
						<p>
							<span>Total Reservas:</span> <b>{topStatus[0]}</b>
						</p>
					</div>
					<div>
						<p>
							<span>Total Personas:</span> <b>{topStatus[1]}</b>
						</p>
					</div>
				</div>
			</div>
		)
	}

	const prefillRoom = async (number, setFieldValue) => {
		let res = null
		res = await firestore
			.doc(`/items_management/${user.id}/clients/${number}`)
			.get()
		res = res.data()

		if (res.length === 0) {
			create_alert('Cliente no encontrado', 'danger')
			setFieldValue('client_val', null)
		} else {
			const checkin = new Date(res.checkin)
			const checkout = new Date(res.checkout)
			setFieldValue('full_name', res.name)
			setFieldValue('room', res.room)
			setFieldValue('from', checkin)
			setFieldValue('to', checkout)
			setFieldValue('adults', parseInt(res.adults || 0))
			setFieldValue('children', parseInt(res.children || 0))
		}
	}

	const renderCreateRes = () => {
		return (
			<ModalForm
				toggle={() => {
					setCreatingReservation(false)
					setEdit(null)
				}}
			>
				<Formik
					initialValues={{
						full_name: edit !== null ? edit.name || '' : '',
						date:
							edit !== null
								? new Date(
										parseInt(edit.date_block[0].split('/')[2]),
										parseInt(edit.date_block[0].split('/')[1]) - 1,
										parseInt(edit.date_block[0].split('/')[0])
								  ) || null
								: null,
						slot: edit !== null ? edit.slot || null : null,
						adults: edit !== null ? edit.adults || '' : '',
						children: edit !== null ? edit.children || '' : '',
						room: edit !== null ? edit.room || '' : '',
						client_val: edit !== null ? edit.client_val || null : null,
						notes: edit !== null ? edit.notes || '' : '',
						phone: edit !== null ? edit.phone || '' : '',
						email: edit !== null ? edit.email || '' : '',
						lang: edit !== null ? edit.lang || 'es' : 'es',
						group: edit !== null ? edit.group || '' : '',
						from: edit !== null ? new Date(edit.from) || null : null,
						to: edit !== null ? new Date(edit.to) || null : null,
						email_notification_now: true,
						email_notification_before: true,
					}}
					validate={(values) => {
						let adults =
							typeof values.adults === 'number' ? values.adults : 0
						let children =
							typeof values.children === 'number' ? values.children : 0
						if (newClient) {
							if (values.full_name.length < 2)
								return { full_name: 'Ingresa el nombre completo' }
							if (adults + children <= 0)
								return { adults: 'Numero de adultos' }
						}
						if (values.date === null) return { date: 'Ingresa la fecha' }
						if (values.slot === null)
							return { date: 'Selecciona una franja horaria' }
					}}
					onSubmit={(v) => {
						if (duplicate === true) addToQueue(v)
						else if (edit === null) addToQueue(v)
						else editQueue(v)
					}}
				>
					{({ errors, touched, values, setFieldValue }) => (
						<Form className="form-ui">
							<h1>
								{edit === null || duplicate === true
									? 'Agregar'
									: 'Editar'}{' '}
								Reserva
							</h1>
							{edit === null && (
								<footer className="cols-2">
									<button
										className={`button button-${
											newClient ? 'primary' : 'light'
										}`}
										type="button"
										onClick={() => setNewClient(true)}
									>
										Nuevo Cliente
									</button>
									<button
										className={`button button-${
											!newClient ? 'primary' : 'light'
										}`}
										type="button"
										onClick={() => setNewClient(false)}
									>
										Cliente Existente
									</button>
								</footer>
							)}
							{newClient && (
								<>
									{/* {type === 0 && (
										<Input
											label={'Habitación'}
											disabled={submitting}
											type="text"
											name="room"
											errors={errors}
											touched={touched}
											cols={12}
										/>
									)} */}
									<div className="form-group upper-10">
										<div className="form-row">
											<div className="col-3">
												<label>Cliente:</label>
											</div>
											<div className="col-9">
												<AsyncSelect
													placeholder={
														'Buscar por Nombre o Habitación'
													}
													className="react-select"
													classNamePrefix="react-select"
													loadOptions={async (input, callback) => {
														const index =
															searchClient.initIndex(
																'items_management'
															)
														let filter = `checkout > ${moment().valueOf()}`
														const { hits } = await index.search(
															input,
															{
																filters: filter,
																hitsPerPage: 10,
															}
														)
														let res = hits.map((h) => ({
															label: `${h.room} > ${h.name}`,
															value: h.id,
														}))
														return callback(res)
													}}
													value={values.client_val}
													onChange={(e) => {
														setFieldValue('client_val', e)
														prefillRoom(e.value, setFieldValue)
													}}
												/>
											</div>
										</div>
									</div>
									<hr></hr>
									<Input
										label={'Nombre Completo'}
										disabled={submitting}
										type="text"
										name="full_name"
										errors={errors}
										touched={touched}
									/>
									<Input
										label={'Adultos'}
										disabled={submitting}
										type="number"
										name="adults"
										errors={errors}
										touched={touched}
										cols={6}
										colside="left"
										rowcolleft={4}
										rowcolright={8}
									/>
									<Input
										label={'Niños'}
										disabled={submitting}
										type="number"
										name="children"
										errors={errors}
										touched={touched}
										cols={6}
										colside="right"
										rowcolleft={4}
										rowcolright={8}
									/>
									{type === 0 && (
										<>
											<div className="form-group cols-6 colside-left">
												<div className="form-row">
													<div className="col-5">
														<label>Inicio Estancia:</label>
													</div>
													<div className="col-7">
														<DatePicker
															locale="es"
															disabled={submitting}
															selected={values.from}
															onChange={(e) => {
																if (e === null) {
																	setFieldValue('from', null)
																} else {
																	let dt = new Date(
																		e.getTime()
																	)
																	dt.setHours(0)
																	dt.setMinutes(0)
																	setFieldValue('from', dt)
																}
															}}
															minDate={new Date()}
															className="form-control"
															timeCaption="Hora 24"
															// showTimeSelect
															timeFormat="HH:mm"
															dateFormat="dd/MM/yy"
														/>
													</div>
												</div>
											</div>
											<div className="form-group cols-6 colside-right">
												<div className="form-row">
													<div className="col-5">
														<label>Fin Estancia:</label>
													</div>
													<div className="col-7">
														<DatePicker
															locale="es"
															disabled={submitting}
															selected={values.to}
															onChange={(e) => {
																if (e === null) {
																	setFieldValue('to', null)
																} else {
																	let dt = new Date(
																		e.getTime()
																	)
																	dt.setHours(23)
																	dt.setMinutes(59)
																	setFieldValue('to', dt)
																}
															}}
															minDate={values.from || new Date()}
															className="form-control"
															timeCaption="Hora 24"
															// showTimeSelect
															timeFormat="HH:mm"
															dateFormat="dd/MM/yy"
														/>
													</div>
												</div>
											</div>
										</>
									)}
									<Input
										label={'Habitación'}
										disabled={submitting}
										type="text"
										name="room"
										errors={errors}
										touched={touched}
										colside="left"
										rowcolleft={4}
										rowcolright={8}
										cols={6}
									/>
									<Input
										label={'Teléfono'}
										disabled={submitting}
										type="text"
										name="phone"
										errors={errors}
										touched={touched}
										colside={type === 0 ? 'right' : ''}
										rowcolleft={4}
										rowcolright={8}
										cols={type === 0 ? 6 : 12}
									/>
									<Input
										label={'Correo'}
										disabled={submitting}
										type="text"
										name="email"
										errors={errors}
										touched={touched}
									/>
									{typeof covid.config.groups === 'object' &&
										covid.config.groups.length > 0 && (
											<div className="form-group upper-8">
												<div className="form-row">
													<div className="col-3">
														<label>Pensión:</label>
													</div>
													<div className="col-9">
														<Select
															className="react-select"
															classNamePrefix="react-select"
															options={covid.config.groups.map(
																(g) => ({
																	value: g,
																	label: g.initials,
																})
															)}
															onChange={(e) => {
																setFieldValue('group', e.value)
															}}
															defaultValue={{
																value: values.group,
																label: values.group.initials,
															}}
														/>
													</div>
												</div>
											</div>
										)}
									<div className="form-group upper-7">
										<div className="form-row">
											<div className="col-3">
												<label>Idioma:</label>
											</div>
											<div className="col-9">
												<Select
													className="react-select"
													classNamePrefix="react-select"
													options={[
														{
															value: 'es',
															label: 'Español',
														},
														{
															value: 'en',
															label: 'Inglés',
														},
														{
															value: 'fr',
															label: 'Francés',
														},
														{
															value: 'de',
															label: 'Alemán',
														},
														{
															value: 'it',
															label: 'Italiano',
														},
													]}
													onChange={(e) => {
														setFieldValue('lang', e.value)
													}}
													defaultValue={{
														value: values.lang,
														label: getLanguageName(values.lang),
													}}
												/>
											</div>
										</div>
									</div>
								</>
							)}
							{!newClient && (
								<>
									<div className="form-group upper-7">
										<div className="form-row">
											<div className="col-2">
												<label>Cliente: </label>
											</div>
											<div className="col-10">
												<AsyncSelect
													className="react-select"
													placeholder="Busca uno"
													classNamePrefix="react-select"
													cacheOptions
													loadOptions={async (v) => {
														const index = searchClient.initIndex(
															'reservation_clients'
														)
														const { hits } = await index.search(
															v,
															{
																facetFilters: `owner:${user.id}`,
															}
														)
														return hits.map((m) => ({
															label: m.name,
															value: m,
														}))
													}}
													onChange={(e) => {
														setSelectedClient(e.value)
														setFieldValue(
															'adults',
															e.value.adults
														)
														setFieldValue(
															'children',
															e.value.children
														)
													}}
												/>
											</div>
										</div>
									</div>
									{selectedClient !== null && (
										<>
											<Input
												label={'Adultos'}
												disabled={submitting}
												type="number"
												name="adults"
												errors={errors}
												touched={touched}
												cols={6}
												colside="left"
												rowcolleft={4}
												rowcolright={8}
											/>
											<Input
												label={'Niños'}
												disabled={submitting}
												type="number"
												name="children"
												errors={errors}
												touched={touched}
												cols={6}
												colside="right"
												rowcolleft={4}
												rowcolright={8}
											/>
										</>
									)}
								</>
							)}
							<hr
								style={{
									marginTop: 30,
									marginBottom: 30,
								}}
							/>
							<div className="form-group cols-12">
								<div className="form-row">
									<div>
										<label htmlFor="notes">
											Notas de la reserva:
										</label>
									</div>
									<div>
										<Field
											name="notes"
											disabled={submitting}
											component="textarea"
										/>
									</div>
								</div>
							</div>
							<div className="form-group">
								<div className="form-row">
									<div className="col-3">
										<label>Fecha:</label>
									</div>
									<div className="col-9">
										<DatePicker
											locale="es"
											disabled={submitting}
											selected={values.date}
											onChange={async (e) => {
												setFieldValue('date', e)
												setFieldValue('slot', null)

												let numberDate = getNumberDate(e.getTime())

												let res = await firestore
													.doc(
														`/places/${place.id}/advanced_res_queue/${numberDate}`
													)
													.get()
												let data = res.exists ? res.data().data : []
												setCreateDaySelectedData(data)
											}}
											minDate={new Date()}
											dateFormat="dd/MM/yy"
											className="form-control"
										/>
									</div>
								</div>
							</div>
							{values.date !== null &&
								Array.isArray(createDaySelectedData) &&
								renderSlots({ values, setFieldValue })}

							{typeof slotSelectStatus === 'number' && (
								<small className={`badge danger`}>
									Numero de personas excedido por{' '}
									<strong>{slotSelectStatus}</strong>
								</small>
							)}

							{((edit === null && values.email.length > 0) ||
								!newClient) && (
								<>
									<div className="form-group form-group-check">
										<div className="form-row">
											<div className="col-12">
												<Field
													type="checkbox"
													name="email_notification_now"
													id="email_notification_now"
													checked={values.email_notification_now}
												/>
												<label htmlFor="email_notification_now">
													<b></b>{' '}
													<span>
														Enviar notificación por Email Ahora
													</span>
												</label>
											</div>
										</div>
									</div>
									<div className="form-group form-group-check">
										<div className="form-row">
											<div className="col-12">
												<Field
													type="checkbox"
													name="email_notification_before"
													id="email_notification_before"
													checked={
														values.email_notification_before
													}
												/>
												<label htmlFor="email_notification_before">
													<b></b>{' '}
													<span>
														Enviar notificación por Email 1 Hora
														antes
													</span>
												</label>
											</div>
										</div>
									</div>
								</>
							)}

							<footer>
								<button
									type="submit"
									disabled={
										submitting ||
										values.slot === null ||
										values.date === null
										// disabled
									}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">
												{language.loading}...
											</span>
										</div>
									)}
									{!submitting && (
										<>
											<span>Continuar</span>
										</>
									)}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	if (type === null || loadingMainData || searchResults === null)
		return <Loading />

	if (typeof search === 'string' && search.length > 1) {
		return (
			<>
				{renderSelectTable()}
				{(creatingReservation === true || edit !== null) &&
					renderCreateRes()}
				{renderSearch()}
				{downloadReservation !== null && renderDownloadReservation()}
			</>
		)
	}

	return (
		<>
			{renderSelectTable()}
			{dayOccupied && (
				<>
					{renderTop()}
					{renderBlocks()}
					{renderSelectSlot()}
					{renderQueue()}
				</>
			)}
			{!dayOccupied && (
				<aside
					className="empty"
					style={{
						marginTop: 50,
					}}
				>
					<i className="mi">layers</i>
					<h3>No hay turnos para este día</h3>
				</aside>
			)}
			{(creatingReservation === true || edit !== null) && renderCreateRes()}
			{downloadReservation !== null && renderDownloadReservation()}
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		tables: state.tables,
		language: state.language.dictionary,
		topbar: state.topbar,
		covid: state.covid,
		user: state.user,
		employee: state.employee,
	}),
	{ create_alert }
)(Reservations)

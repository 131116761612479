import React, { useEffect, useState } from 'react'

function Chrono({
	time,
	countdown = false,
	nodays,
	showSeconds = false,
	showHours = true
}) {
	const [tick, setTick] = useState(new Date().getTime())

	useEffect(() => {
		let interval = setInterval(() => {
			setTick(new Date().getTime())
		}, 1000)
		return () => {
			clearInterval(interval)
		}
	}, [])

	function getTime(_tick, _incoming_time) {
		let _time = Math.floor(_tick / 1000) - Math.floor(_incoming_time / 1000)
		if (countdown) _time = _incoming_time - Math.floor(_tick / 1000)

		let seconds = Math.floor(_time % 60)
		let minutes = Math.floor((_time / 60) % 60)
		let hours = Math.floor(_time / (60 * 60))
		if (!nodays) hours = Math.floor(hours % 24)
		let days = Math.floor(_time / (60 * 60) / 24)
		let separator = seconds % 2 === 0 ? ':' : ' '

		let seconds_data = null
		if (showSeconds) {
			if (seconds === 0) seconds = '00'
			else seconds = seconds < 10 ? '0' + seconds : seconds
			seconds_data = `${separator}${seconds}`
			minutes = Math.floor(_time / 60)
			hours = hours < 10 ? '0' + hours : hours
			minutes = minutes - hours * 60
			if (!nodays) minutes = minutes - days * 24 * 60
			minutes = minutes < 10 ? '0' + minutes : minutes
		} else {
			hours = hours < 10 ? '0' + hours : hours
			minutes = minutes - hours * 60
			minutes = minutes < 10 ? '0' + minutes : minutes
		}
		return `${nodays ? '' : days}${nodays ? '' : separator}${
			!showHours ? '' : hours
		}${!showHours ? '' : separator}${minutes}${
			seconds_data !== null ? seconds_data : ''
		}`
	}

	return <pre className="chrono">{getTime(tick, time)}</pre>
}

export default Chrono

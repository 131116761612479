export default [
	{ key: 'products', label: 'Products' },
	{ key: 'tables', label: 'Tables' },
	{ key: 'queue', label: 'Queue' },
	{ key: 'reservations', label: 'Reservations' },
	{ key: 'inventory', label: 'Inventory' },
	{ key: 'employees', label: 'Employees' },
	{ key: 'events', label: 'Events and Tickets' },
	{ key: 'show_my_place_in_app', label: 'Show in App' },
	{ key: 'receive_orders_from_app', label: 'Orders from App' },
	{ key: 'reservations_covid', label: 'Reserva Avanzada' },
]

// Module imports
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// Local imports
import { remove_alert } from '../../store/actions'

// Component
function Alert({
	content,
	type,
	id,
	remove_alert,
	language,
	history,
	redirect,
	sound,
}) {
	const [classname, setclassname] = useState('')

	useEffect(() => {
		let aud = new Audio(require('../../assets/notification.mp3'))
		if (sound) aud.play()
		setTimeout(() => {
			setclassname('fade show ' + type)
		}, 100)
		setTimeout(() => {
			remove_alert(id)
		}, 9000)
	}, [])

	let icon_clss =
		type === 'success'
			? 'icon-action-done'
			: type === 'danger'
			? 'icon-alert-error'
			: ''
	return (
		<div
			role="alert"
			className={`toast ${classname} ${redirect !== null ? 'hover' : ''}`}
			data-autohide="false"
			onClick={(e) => {
				if (redirect !== null) history.replace(redirect)
			}}
		>
			<div className="toast-header">
				<strong className="mr-auto">
					<i className={icon_clss} />{' '}
					{type === 'success'
						? language.success
						: type === 'warning'
						? language.warning
						: language.error}
				</strong>
			</div>
			<div className="toast-body">
				<p>{content}</p>
			</div>
		</div>
	)
}

// Required props
Alert.propTypes = {
	content: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['danger', 'success', 'warning']).isRequired,
	id: PropTypes.number.isRequired,
}

// Export component
export default connect((state) => ({ language: state.language.dictionary }), {
	remove_alert,
})(withRouter(Alert))

// Module imports
import React from 'react'
import thunk from 'redux-thunk'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'

// Local imports
import App from './App'
import reducers from './store/reducers'
import * as serviceWorker from './serviceWorker'
import { CookiesProvider } from 'react-cookie'

// Redux devtools and store creation
const composeEnhancers =
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose()
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)))

// Render the webapp
ReactDOM.render(
	<Provider store={store}>
		<CookiesProvider>
			<App />
		</CookiesProvider>
	</Provider>,
	document.getElementById('root')
)

// Unegister service worker
serviceWorker.unregister()

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	AreaChart,
	Area,
	Line,
	LineChart,
	ResponsiveContainer,
	Text,
	Label,
} from 'recharts'

import { firestore } from '../../../config/firebase'

function Events({ place, language, date, data, products }) {
	const [popEvents, setPopEvents] = useState(null)
	const [revenueEvents, setRevenueEvents] = useState(null)

	useEffect(() => {
		getData()
	}, [])

	useEffect(() => {
		if (data !== null) getData()
	}, [date])

	const getData = () => {
		getPopEvents(data)
		getRevEvents(data)
	}

	const getPopEvents = (data) => {
		let _events = []

		let evs = data.events || {}
		Object.keys(evs).forEach((ev) => {
			let _ev = evs[ev]
			_events.push({
				name: _ev.name,
				quantity: _ev.quantity,
			})
		})

		_events = _events.sort((a, b) => b.quantity - a.quantity)

		if (_events.length > 0) setPopEvents(_events)
	}
	const getRevEvents = (data) => {
		let _events = []

		let evs = data.events || {}
		Object.keys(evs).forEach((ev) => {
			let _ev = evs[ev]
			_events.push({
				name: _ev.name,
				money: _ev.money,
			})
		})

		_events = _events.sort((a, b) => b.money - a.money)

		if (_events.length > 0) setRevenueEvents(_events)
	}

	if (popEvents === null)
		return (
			<aside className="empty">
				<i className="mi">insert_chart</i>
				<h3>No hay estadísticas de eventos.</h3>
			</aside>
		)

	return (
		<>
			<div className="ui-stats">
				{popEvents !== null && (
					<div className="stat">
						<div>
							<h1>Eventos populares</h1>
							<div className="wrapper">
								<ResponsiveContainer width="100%">
									<BarChart data={popEvents} layout="vertical">
										<XAxis
											dy={5}
											type="number"
											domain={[0, 'dataMax + 5']}
										/>
										<YAxis
											dx={-5}
											dataKey="name"
											type="category"
											width={200}
										/>
										<CartesianGrid
											strokeDasharray="1 0"
											stroke="#dee2e6"
										/>
										<Tooltip
											isAnimationActive={false}
											cursor={{
												stroke: '#000',
												strokeWidth: 0,
												fill: 'rgba(0,0,0,0.05)',
											}}
										/>
										<Bar
											radius={[0, 10, 10, 0]}
											dataKey="quantity"
											fill="#2589f9"
											legendType="none"
											name="Entradas vendidas"
										/>
									</BarChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
				)}
				{revenueEvents !== null && (
					<div className="stat">
						<div>
							<h1>Dinero recaudado</h1>
							<div className="wrapper">
								<ResponsiveContainer width="100%">
									<BarChart data={revenueEvents} layout="vertical">
										<XAxis dy={5} type="number" unit="€" />
										<YAxis
											dx={-5}
											dataKey="name"
											type="category"
											width={200}
										/>
										<CartesianGrid
											strokeDasharray="1 0"
											stroke="#dee2e6"
										/>
										<Tooltip
											isAnimationActive={false}
											cursor={{
												stroke: '#000',
												strokeWidth: 0,
												fill: 'rgba(0,0,0,0.05)',
											}}
										/>
										<Bar
											radius={[0, 10, 10, 0]}
											dataKey="money"
											fill="#2589f9"
											legendType="none"
											name="Dinero ganado"
										/>
									</BarChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default connect((state) => ({
	place: state.place,
	language: state.language.dictionary,
	products: state.products,
}))(Events)

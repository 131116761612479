import { Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
// import ReactCrop from 'react-image-crop'
// import 'react-image-crop/lib/ReactCrop.scss'
import { connect } from 'react-redux'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {
	Confirmation,
	Input,
	Loading,
	ModalForm
} from '../../../../../components'
import _allergens from '../../../../../config/allergens'
import { firestore, storage } from '../../../../../config/firebase'
import { create_alert, update_products } from '../../../../../store/actions'
import Additions from './Additions'

function Create({
	place,
	categories,
	history,
	create_alert,
	inventory,
	toggle,
	refresh,
	editting = false,
	update_products,
	products,
	language,
	service = false,
	setService,
}) {
	const [submitting, setSubmitting] = useState(false)
	const [crop, setCrop] = useState({
		unit: '%',
		width: 100,
		height: 100,
		x: 0,
		y: 0,
		aspect: 1,
	})
	const [inventoryItems, setInventoryItems] = useState(0)
	const [displayImage, setDisplayImage] = useState(null)
	const [imageRef, setImageRef] = useState(null)
	const [finalImage, setFinalImage] = useState(null)
	const [selectImage, setSelectImage] = useState(false)
	const [allergens, setAllergens] = useState([])
	const [opts, setOpts] = useState([])
	const [intialEditImg, setInitialEditImg] = useState(false)
	const [initialAdds, setInitialAdds] = useState(null)
	const [indexAdds, setIndexAdds] = useState(null)
	const [deleting, setDeleting] = useState(false)

	useEffect(() => {
		if (editting) {
			setAllergens(
				editting.allergens.map((al) => ({
					value: al.value,
					label: al.label,
				}))
			)
			let _adds = {}
			let iadds = []
			editting.additions.forEach((add) => {
				let id = `add_${Math.floor(Math.random() * 10000)}`
				iadds.push({ id, items: add.items.length })
				_adds[id] = add
			})
			setInitialAdds(_adds)
			setIndexAdds(iadds)
			if (editting.image === 'no_image') {
			} else {
				setInitialEditImg(true)
				setDisplayImage(editting.image)
				setFinalImage(editting.image)
			}
		} else {
			setInitialAdds({})
		}
	}, [])

	useEffect(() => {
		if (inventory !== null) {
			let _opts = inventory.items.items.map((it) => ({
				value: it.id,
				label: it.name,
			}))
			let record = []
			_opts = _opts.filter((it) => {
				if (record.indexOf(it.label) === -1) {
					record.push(it.label)
					return true
				}
				return false
			})
			setOpts(_opts)
		}
	}, [inventory])

	useEffect(() => {
		if (imageRef !== null) {
			let size = imageRef.width
			if (imageRef.height < size) size = imageRef.height
			if (imageRef !== null) {
				makeClientCrop({
					unit: 'px',
					width: size,
					height: size,
					x: 0,
					y: 0,
				})
			}
		}
	}, [imageRef])

	const deleteItem = async () => {
		await firestore
			.collection('places')
			.doc(place.id)
			.update({
				products: place.products.filter((pr) => pr !== editting.id),
			})
		await firestore
			.collection('places')
			.doc(place.id)
			.collection('products')
			.doc(editting.id)
			.delete()
		create_alert(language.product_removed_successfully, 'success')
		setSubmitting(false)
		setDeleting(false)
		toggle()
	}

	const onUpdate = async (values) => {
		try {
			// Handle image
			let imageToUse = null
			if (finalImage === null) {
				imageToUse = 'no_image'
			} else if (finalImage.startsWith('data:image')) {
				const storageRef = storage.ref()
				const imageRef = storageRef.child(
					`/product_images/${place.id}/${editting.id}`
				)
				await imageRef.putString(finalImage, 'data_url')
				imageToUse = await imageRef.getDownloadURL()
			} else {
				imageToUse = editting.image
			}

			let _additions = []

			if (
				typeof values.additions !== 'undefined' &&
				values.additions !== null
			) {
				Object.keys(values.additions).forEach((key) => {
					let addition = values.additions[key]
					let items = []
					addition.items.forEach((it, i) => {
						let item = {
							name: it.name,
							value: it.value,
						}
						if (item.name !== '') {
							if (typeof item.value === 'undefined') item.value = 0
							items.push(item)
						}
					})
					addition.items = items
					if (addition.items.length > 0) _additions.push(addition)
				})
			}

			let _allergens_ = allergens === null ? [] : allergens

			let price = parseFloat(values.price)
			let prep_time = values.preparation_time * 60000
			let product_slug = editting.id

			let isNewCategory = true
			categories.product.forEach((cat) => {
				if (cat === values.category) isNewCategory = false
			})

			firestore
				.runTransaction(async (transaction) => {
					let placeDocRef = firestore.collection('places').doc(place.id)
					let placeListRef = firestore
						.collection('places_list')
						.doc(place.id)
					let latestPlaceData = await transaction.get(placeDocRef)
					latestPlaceData = latestPlaceData.data()
					let latestPlaceListData = await transaction.get(placeListRef)
					latestPlaceListData = latestPlaceListData.data()

					if (isNewCategory) {
						await transaction.update(placeDocRef, {
							product_categories: [
								...latestPlaceData.product_categories,
								values.category,
							],
						})
						await transaction.update(placeListRef, {
							product_categories: [
								...latestPlaceData.product_categories,
								values.category,
							],
						})
					}

					// Subcategory
					if (values.subcategory !== '') {
						if (isNewCategory) {
							await transaction.update(placeDocRef, {
								product_subcategories: {
									...latestPlaceData.product_subcategories,
									[values.category]: [values.subcategory],
								},
							})
							await transaction.update(placeListRef, {
								product_subcategories: {
									...latestPlaceData.product_subcategories,
									[values.category]: [values.subcategory],
								},
							})
						} else {
							let isNewSubcategory = true
							let hasSubcategorie =
								typeof latestPlaceData.product_subcategories[
									values.category
								] !== 'undefined'

							if (hasSubcategorie) {
								latestPlaceData.product_subcategories[
									values.category
								].forEach((cat) => {
									if (cat === values.subcategory)
										isNewSubcategory = false
								})
								if (isNewSubcategory) {
									await transaction.update(placeDocRef, {
										product_subcategories: {
											...latestPlaceData.product_subcategories,
											[values.category]: [
												...latestPlaceData.product_subcategories[
													values.category
												],
												values.subcategory,
											],
										},
									})
									await transaction.update(placeListRef, {
										product_subcategories: {
											...latestPlaceData.product_subcategories,
											[values.category]: [
												...latestPlaceData.product_subcategories[
													values.category
												],
												values.subcategory,
											],
										},
									})
								}
							} else {
								await transaction.update(placeDocRef, {
									product_subcategories: {
										...latestPlaceData.product_subcategories,
										[values.category]: [values.subcategory],
									},
								})
							}
						}
					}

					let productDocRef = firestore
						.collection('places')
						.doc(latestPlaceData.id)
						.collection('products')
						.doc(product_slug)

					let product = {
						...editting,
						name: values.name,
						price,
						image: imageToUse,
						additions: _additions,
						description: values.description,
						category: values.category,
						preparation_time: prep_time,
						type: values.type,
						allergens: _allergens_,
						subcategory: values.subcategory,
						taxrate: values.taxrate,
						price_type: values.price_type,
						unit: values.unit,
						allow_order_from_app: values.allow_order_from_app,
						show_in_products_list: values.show_in_products_list,
						show_price_in_products_list:
							values.show_price_in_products_list,
						service: service,
					}

					await transaction.update(placeListRef, {
						products: latestPlaceListData.products.map((pr) => {
							if (pr.id !== product.id) return pr
							return product
						}),
					})

					await transaction.set(productDocRef, product)
				})
				.then(() => {
					create_alert(language.product_updated, 'success')
					setSubmitting(false)
					toggle(false)
					update_products(
						products.map((pr) => {
							if (pr.id !== editting.id) return pr
							return {
								...pr,
								name: values.name,
								price,
								image: imageToUse,
								additions: _additions,
								description: values.description,
								category: values.category,
								preparation_time: prep_time,
								type: values.type,
								allergens: _allergens_,
								subcategory: values.subcategory,
								taxrate: values.taxrate,
								price_type: values.price_type,
								unit: values.unit,
								allow_order_from_app: values.allow_order_from_app,
								show_in_products_list: values.show_in_products_list,
								show_price_in_products_list:
									values.show_price_in_products_list,
								service: service,
							}
						})
					)
				})
				.catch((e) => {
					toggle(false)

					create_alert(language.an_error_ocurred, 'danger')
					setSubmitting(false)
				})
		} catch (e) {
			toggle(false)

			create_alert(language.an_error_ocurred, 'danger')
			setSubmitting(false)
		}
	}

	const onSubmit = async (values) => {
		try {
			setSubmitting(true)
			if (editting) {
				onUpdate(values)
				return
			}
			let _additions = []
			if (
				typeof values.additions !== 'undefined' &&
				values.additions !== null
			) {
				Object.keys(values.additions).forEach((key) => {
					let addition = values.additions[key]
					let items = []
					addition.items.forEach((it, i) => {
						let item = {
							name: it.name,
							value: it.value,
						}
						if (item.name !== '') {
							if (typeof item.value === 'undefined') item.value = 0
							items.push(item)
						}
					})
					addition.items = items
					if (typeof addition.name === 'undefined') addition.name = ''
					if (addition.items.length > 0) _additions.push(addition)
				})
			}

			let _allergens_ = allergens === null ? [] : allergens

			let price = parseFloat(values.price)
			let prep_time = values.preparation_time * 60000
			let product_slug =
				values.name.replace(/ /g, '_') +
				'_' +
				Math.floor(Math.random() * 1000)
			product_slug = product_slug.toLowerCase()

			let url = 'no_image'
			if (finalImage !== null) {
				const storageRef = storage.ref()
				const imageRef = storageRef.child(
					`/product_images/${place.id}/${product_slug}`
				)
				await imageRef.putString(finalImage, 'data_url')
				url = await imageRef.getDownloadURL()
			}

			firestore
				.runTransaction(async (transaction) => {
					let placeDocRef = firestore.collection('places').doc(place.id)
					let placeListRef = firestore
						.collection('places_list')
						.doc(place.id)
					let latestPlaceData = await transaction.get(placeDocRef)
					latestPlaceData = latestPlaceData.data()
					let latestPlaceListData = await transaction.get(placeListRef)
					latestPlaceListData = latestPlaceListData.data()

					let isNewCategory = true
					categories.product.forEach((cat) => {
						if (cat === values.category) isNewCategory = false
					})

					if (isNewCategory) {
						await transaction.update(placeDocRef, {
							product_categories: [
								...latestPlaceData.product_categories,
								values.category,
							],
						})
						await transaction.update(placeListRef, {
							product_categories: [
								...latestPlaceListData.product_categories,
								values.category,
							],
						})
					}

					if (values.subcategory !== '') {
						if (isNewCategory) {
							await transaction.update(placeDocRef, {
								product_subcategories: {
									...latestPlaceData.product_subcategories,
									[values.category]: [values.subcategory],
								},
							})
							await transaction.update(placeListRef, {
								product_subcategories: {
									...latestPlaceListData.product_subcategories,
									[values.category]: [values.subcategory],
								},
							})
						} else {
							let isNewSubcategory = true
							let hasSubcategorie =
								typeof latestPlaceData.product_subcategories[
									values.category
								] !== 'undefined'

							if (hasSubcategorie) {
								latestPlaceData.product_subcategories[
									values.category
								].forEach((cat) => {
									if (cat === values.subcategory)
										isNewSubcategory = false
								})
								if (isNewSubcategory) {
									await transaction.update(placeDocRef, {
										product_subcategories: {
											...latestPlaceData.product_subcategories,
											[values.category]: [
												...latestPlaceData.product_subcategories[
													values.category
												],
												values.subcategory,
											],
										},
									})
									await transaction.update(placeListRef, {
										product_subcategories: {
											...latestPlaceListData.product_subcategories,
											[values.category]: [
												...latestPlaceListData
													.product_subcategories[values.category],
												values.subcategory,
											],
										},
									})
								}
							} else {
								await transaction.update(placeDocRef, {
									product_subcategories: {
										...latestPlaceData.product_subcategories,
										[values.category]: [values.subcategory],
									},
								})

								await transaction.update(placeListRef, {
									product_subcategories: {
										...latestPlaceListData.product_subcategories,
										[values.category]: [values.subcategory],
									},
								})
							}
						}
					}

					let productDocRef = firestore
						.collection('places')
						.doc(latestPlaceData.id)
						.collection('products')
						.doc(product_slug)

					let product = {
						name: values.name,
						id: product_slug,
						price,
						image: url,
						additions: _additions,
						description: values.description,
						category: values.category,
						subcategory: values.subcategory,
						preparation_time: prep_time,
						type: values.type,
						allergens: _allergens_,
						service: service,
						upvotes: 0,
						taxrate: values.taxrate,
						price_type: values.price_type,
						unit: values.unit,
						allow_order_from_app: values.allow_order_from_app,
						show_in_products_list: values.show_in_products_list,
						show_price_in_products_list:
							values.show_price_in_products_list,
						inventory:
							typeof values.inventory_items === 'undefined'
								? []
								: values.inventory_items[0].id === ''
								? []
								: values.inventory_items,
					}

					await transaction.update(placeListRef, {
						products: [...latestPlaceListData.products, product],
					})

					await transaction.set(productDocRef, product)

					let accProdRecommendations =
						latestPlaceData.accumulated_product_recommendations + 1
					let newProdRecommendations = latestPlaceData.recommendations
					if (accProdRecommendations === 2) {
						accProdRecommendations = 0
						newProdRecommendations += 1
					}

					await transaction.update(placeDocRef, {
						products: [...latestPlaceData.products, product_slug],
						accumulated_product_recommendations: accProdRecommendations,
						recommendations: newProdRecommendations,
					})
				})
				.then(() => {
					create_alert(language.product_created, 'success')
					setSubmitting(false)
					toggle(false)
				})
				.catch((e) => {
					toggle(false)
					create_alert(language.an_error_ocurred, 'danger')

					setSubmitting(false)
				})
		} catch (e) {
			toggle(false)
			create_alert(language.an_error_ocurred, 'danger')

			setSubmitting(false)
		}
	}

	const getPrevCategories = () => {
		let cats = categories.product.filter((r) => r !== '')

		return cats.map((cat) => {
			return {
				value: cat,
				label: cat,
			}
		})
	}

	const getPrevSubCategories = (cat) => {
		let data = place.product_subcategories[cat]
		if (typeof data === 'undefined') return []
		else {
			let cats = data.filter((r) => r !== '')
			return cats.map((cat) => {
				return {
					value: cat,
					label: cat,
				}
			})
		}
	}

	const validate = (values) => {
		let err = {}
		if (values.name.length < 3)
			return { name: `${language.cant_be_less_than} 3 caracteres` }
		if (isNaN(parseInt(values.price)) || parseInt(values.price) < 0)
			return { price: `${language.cant_be_less_than} 0` }
		if (values.taxrate === '' || !(values.taxrate >= 0))
			return { taxrate: `${language.cant_be_less_than} 0` }
		if (['u', 'kg', 'lb', 'lt', 'pt'].indexOf(values.unit) === -1)
			return { unit: `Error con la unidad` }

		if (['fixed', 'units'].indexOf(values.price_type) === -1)
			return { price_type: 'Error con el precio' }

		if (values.preparation_time < 0)
			return { preparation_time: `${language.cant_be_less_than} 0` }
		// if (values.category === '') return { category: language.cant_be_empty }
		Object.keys(values.additions).forEach((key) => {
			let add = values.additions[key]
			if (typeof add.name === 'undefined')
				err.additions = language.cant_be_empty
			if (typeof add.min === 'undefined') values.additions[key].min = 0
			if (typeof add.max === 'undefined') values.additions[key].max = 1
			values.additions[key].min = parseInt(values.additions[key].min)
			values.additions[key].max = parseInt(values.additions[key].max)
			if (parseInt(add.min) > parseInt(add.max))
				err.additions =
					language.min_additions_can_not_be_grater_than_max_additions
			values.additions[key].items = values.additions[key].items.map((i) => ({
				...i,
				value: isNaN(parseInt(i.value)) ? 0 : parseInt(i.value),
			}))
		})
		return err
	}

	const onSelectFile = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			setInitialEditImg(false)
			const reader = new FileReader()
			reader.addEventListener('load', () => setDisplayImage(reader.result))
			reader.readAsDataURL(e.target.files[0])
		}
	}

	const makeClientCrop = async (crop) => {
		if (imageRef && crop.width && crop.height) {
			const croppedImageUrl = await getCroppedImg(
				imageRef,
				crop,
				'newFile.jpeg'
			)
			setFinalImage(croppedImageUrl)
		}
	}

	const getCroppedImg = (image, crop, fileName) => {
		const canvas = document.createElement('canvas')
		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		canvas.width = crop.width
		canvas.height = crop.height
		const ctx = canvas.getContext('2d')

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		)

		return new Promise((resolve, reject) => {
			let str = canvas.toDataURL()
			resolve(str)
		})
	}

	const onImageLoaded = (image) => {
		setImageRef(image)
	}

	const renderAddFields = (setFieldValue) => {
		let adds_fields = []
		for (let i = 0; i < inventoryItems; i++) {
			let fieldset_class = ''
			if (i === 0) fieldset_class += ' first-child'
			if (i === inventoryItems.length - 1) fieldset_class += ' last-child'
			adds_fields.push(
				<fieldset key={i} className={fieldset_class}>
					<div className="fieldset-header">
						<h2></h2>
						{inventoryItems - 1 === i && (
							<button
								onClick={() => removeInventoryItem(setFieldValue, i)}
								type="button"
								className="button button-link button-link-sm button-link-delete"
							>
								<i className="mi">delete</i>
								<span>
									{language.remove} {language.item}
								</span>
							</button>
						)}
					</div>
					<div className="fieldset-body">
						<div className="form-group upper-11 cols-8 colside-left">
							<div className="form-row">
								<div className="col-3">
									<label>{language.item}</label>
								</div>
								<div className="col-9">
									<Select
										onChange={(e) =>
											setFieldValue(
												`inventory_items[${i}].id`,
												e.label
											)
										}
										defaultValue={[]}
										name={`inventory_items[${i}].id`}
										options={opts}
										className="react-select"
										classNamePrefix="react-select"
									/>
								</div>
							</div>
						</div>
						<Input
							label={language.quantity}
							submitting={submitting}
							type="number"
							name={`inventory_items[${i}].quantity`}
							defaultValue={1}
							cols="4"
							colside="right"
							rowcolleft="6"
							rowcolright="6"
						/>
					</div>
				</fieldset>
			)
		}
		return adds_fields
	}

	const removeInventoryItem = (setFieldValue, i) => {
		setInventoryItems(inventoryItems - 1)
		setFieldValue(`inventory_items[${i}].id`, '')
		setFieldValue(`inventory_items[${i}].quantity`, 0)
	}

	const unitValToSelectVal = (val = 'u') => {
		switch (val) {
			case 'u':
				return {
					value: 'u',
					label: 'Unidades',
				}
			case 'kg':
				return {
					value: 'kg',
					label: 'Kilos',
				}
			case 'lt':
				return {
					value: 'lt',
					label: 'Litros',
				}
			case 'pt':
				return {
					value: 'pt',
					label: 'Raciones',
				}
			case 'lb':
				return {
					value: 'lb',
					label: 'Libras',
				}
		}
	}

	if (categories.product === null) return <Loading />

	let displayingSomeImg = displayImage !== null

	if (initialAdds === null) return null

	if (deleting)
		return (
			<Confirmation
				label={language.remove}
				icon="delete"
				submitting={submitting}
				onChange={async (res) => {
					if (res === false) {
						setDeleting(false)
					} else {
						setSubmitting(true)
						deleteItem()
						// setDeleting(false)
					}
				}}
			>
				{language.sure_you_want_to_delete} {editting.name}
			</Confirmation>
		)

	return (
		<>
			<ModalForm toggle={toggle}>
				<Formik
					validateOnBlur={false}
					validateOnChange={false}
					initialValues={{
						name: !editting ? '' : editting.name,
						price: !editting ? '' : editting.price,
						preparation_time: !editting
							? place.type === 'store'
								? 0
								: 20
							: editting.preparation_time / 60000,
						category: !editting ? '' : editting.category,
						type: !editting ? 'main_course' : editting.type,
						description: !editting ? '' : editting.description,
						additions: initialAdds !== null ? initialAdds : {},
						subcategory: !editting ? '' : editting.subcategory,
						taxrate: !editting ? 10 : editting.taxrate,
						price_type: !editting ? 'units' : editting.price_type,
						unit: !editting ? 'u' : editting.unit,
						allow_order_from_app: !editting
							? true
							: editting.allow_order_from_app,
						show_in_products_list: !editting
							? true
							: editting.show_in_products_list,
						show_price_in_products_list: !editting
							? true
							: editting.show_price_in_products_list,
					}}
					validate={validate}
					onSubmit={onSubmit}
				>
					{({ errors, touched, setFieldValue, values }) => {
						let earnings = 0
						if (parseFloat(values.price) > 0)
							earnings =
								parseFloat(values.price) -
								(parseFloat(values.price) *
									parseFloat(values.taxrate)) /
									100

						return (
							<Form
								className="form-ui"
								onKeyPress={(e) => {
									if (e.charCode === 13) {
										e.preventDefault()
									}
								}}
							>
								<h1>
									{!editting
										? service
											? 'Nuevo servicio'
											: language.new_product
										: `${language.edit} ${editting.name}`}
								</h1>

								{editting && place.type === 'beauty' && (
									<footer className="cols-2">
										<button
											className={`button button-${
												service ? 'light' : 'primary'
											}`}
											onClick={() => setService(false)}
											type="button"
										>
											Producto
										</button>
										<button
											className={`button button-${
												service ? 'primary' : 'light'
											}`}
											onClick={() => setService(true)}
											type="button"
										>
											Servicio
										</button>
									</footer>
								)}

								{displayingSomeImg && (
									<fieldset className="first-child last-child fieldset-image fieldset-image-single">
										<div className="fieldset-header">
											<h2>
												{service
													? 'Imagen del servicio'
													: language.product_image}
											</h2>
											<button
												onClick={() => {
													setFinalImage(null)
													setDisplayImage(null)
													setInitialEditImg(false)
													setSelectImage(false)
												}}
												className="button button-link button-link-sm button-link-delete"
												type="button"
											>
												<i className="mi">delete</i>
												<span>{language.remove}</span>
											</button>
										</div>
										{intialEditImg && <img src={displayImage}></img>}
										{displayImage === null && finalImage !== null && (
											<img src={finalImage}></img>
										)}
										{/* {displayImage !== null && !intialEditImg && (
											<ReactCrop
												onImageLoaded={onImageLoaded}
												src={displayImage}
												crop={crop}
												onChange={(crop) => setCrop(crop)}
												onComplete={makeClientCrop}
											/>
										)} */}
									</fieldset>
								)}
								{!displayingSomeImg && (
									<div className="adder adder-single">
										<label
											htmlFor="featImageInput"
											className="button button-light"
										>
											{service
												? `${
														service
															? 'Imagen del servicio'
															: language.product_image
												  }`
												: `${language.select} ${language.product_image}`}
										</label>
										<input
											type="file"
											onChange={onSelectFile}
											id="featImageInput"
										/>
									</div>
								)}

								<Input
									label={language.name}
									disabled={submitting}
									type="text"
									name="name"
									errors={errors}
									touched={touched}
								/>

								{place.type === 'restaurant' && (
									<div className="form-group form-group-radio">
										<div className="form-row">
											<div className="col-12">
												<ul>
													<li>
														<Field
															type="radio"
															name="type"
															value={'main_course'}
															id="main_course"
															checked={
																values.type === 'main_course'
															}
														/>
														<label htmlFor="main_course">
															<b></b>{' '}
															<span>{language.main_course}</span>
														</label>
													</li>
													<li>
														<Field
															type="radio"
															name="type"
															value={'starter'}
															id="starter"
															checked={values.type === 'starter'}
														/>
														<label htmlFor="starter">
															<b></b>{' '}
															<span>{language.starter}</span>
														</label>
													</li>
													<li>
														<Field
															type="radio"
															name="type"
															value={'dessert'}
															id="dessert"
															checked={values.type === 'dessert'}
														/>
														<label htmlFor="dessert">
															<b></b>{' '}
															<span>{language.dessert}</span>
														</label>
													</li>
													<li>
														<Field
															type="radio"
															name="type"
															value={'drink'}
															id="drink"
															checked={values.type === 'drink'}
														/>
														<label htmlFor="drink">
															<b></b>{' '}
															<span>{language.drink}</span>
														</label>
													</li>
												</ul>
											</div>
										</div>
									</div>
								)}

								<div className="form-group cols-12">
									<div className="form-row">
										<div>
											<label htmlFor="description">
												{language.description} (Opcional):
											</label>
										</div>
										<div>
											<Field
												name="description"
												disabled={submitting}
												component="textarea"
											/>
										</div>
									</div>
								</div>

								{/* <div className="form-group form-group-radio">
									<div className="form-row">
										<div className="col-12">
											<ul>
												<li>
													<Field
														type="radio"
														name="price_type"
														value={'fixed'}
														id="fixed"
														checked={
															values.price_type === 'fixed'
														}
													/>
													<label htmlFor="fixed">
														<b></b>
														<span>Precio Fijo</span>
													</label>
												</li>
												<li>
													<Field
														type="radio"
														name="price_type"
														value={'units'}
														id="units"
														checked={
															values.price_type === 'units'
														}
													/>
													<label htmlFor="units">
														<b></b>
														<span>Precio por Unidades</span>
													</label>
												</li>
											</ul>
										</div>
									</div>
								</div> */}

								{/* {values.price_type === 'fixed' && (
									<>
										<Input
											label={language.price}
											disabled={submitting}
											type="number"
											name="price"
											errors={errors}
											touched={touched}
											append={<i className="mi">euro_symbol</i>}
											cols="6"
											colside="left"
											// colside="left"
											// rowcolleft="5"
											// rowcolright="7"
											custom={{
												step: '0.01',
											}}
										/>

										<Input
											label={language.taxes}
											disabled={submitting}
											type="number"
											name="taxrate"
											errors={errors}
											touched={touched}
											append={'%'}
											custom={{
												step: '1',
												value: values.taxrate,
												onChange: (v) => {
													let n = parseInt(v.target.value)
													if (isNaN(n))
														setFieldValue('taxrate', '')
													if (n > 100 || n < 0)
														setFieldValue('taxrate', 0)
													else setFieldValue('taxrate', n)
												},
											}}
											rowcolleft="4"
											rowcolright="8"
											cols="6"
											colside="right"
										/>

										<Input
											label={language.earnings}
											disabled={true}
											append={<i className="mi">euro_symbol</i>}
											custom={{
												value: earnings,
												onChange: (e) => false,
											}}
											cols="12"
											rowcolleft="4"
											rowcolright="8"
										/>
										<div
											style={{
												width: '100%',
												height: 50,
											}}
										></div>
									</>
								)} */}
								{values.price_type === 'units' && (
									<>
										<div className="form-group upper-9 cols-6 colside-left">
											<div className="form-row">
												<div className="col-3">
													<label>Medida</label>
												</div>
												<div className="col-9">
													<Select
														onChange={(e) =>
															setFieldValue(`unit`, e.value)
														}
														defaultValue={unitValToSelectVal(
															values.unit
														)}
														isDisabled={service}
														name={`unit`}
														options={[
															{
																value: 'kg',
																label: 'Kilos',
															},
															{
																value: 'lb',
																label: 'Libras',
															},
															{
																value: 'lt',
																label: 'Litros',
															},
															{
																value: 'pt',
																label: 'Raciones',
															},
															{
																value: 'u',
																label: 'Unidades',
															},
														]}
														className="react-select"
														classNamePrefix="react-select"
													/>
												</div>
											</div>
										</div>

										<Input
											label="Precio por unidad"
											disabled={submitting}
											type="number"
											name="price"
											errors={errors}
											touched={touched}
											append={<i className="mi">euro_symbol</i>}
											cols="6"
											colside="right"
											rowcolleft="6"
											rowcolright="6"
											custom={{
												step: '0.01',
											}}
										/>

										<Input
											label="Impuestos por unidad"
											disabled={submitting}
											type="number"
											name="taxrate"
											errors={errors}
											touched={touched}
											append={'%'}
											custom={{
												step: '1',
												value: values.taxrate,
												onChange: (v) => {
													let n = parseInt(v.target.value)
													if (isNaN(n))
														setFieldValue('taxrate', '')
													if (n > 100 || n < 0)
														setFieldValue('taxrate', 0)
													else setFieldValue('taxrate', n)
												},
											}}
											colside="left"
											rowcolleft="7"
											rowcolright="5"
											cols="6"
										/>
										<Input
											label="Ganancias por unidad"
											disabled={true}
											append={<i className="mi">euro_symbol</i>}
											custom={{
												value: earnings,
												onChange: (e) => false,
											}}
											colside="right"
											cols="6"
											rowcolleft="7"
											rowcolright="5"
										/>
										<div
											style={{
												width: '100%',
												height: 50,
											}}
										></div>
									</>
								)}

								{place.type !== 'store' && place.type !== 'beauty' && (
									<Input
										label={language.preparation_time}
										disabled={submitting}
										type="number"
										name="preparation_time"
										errors={errors}
										touched={touched}
										append="min"
										cols="12"
										rowcolleft="4"
										rowcolright="8"
									/>
								)}

								<div
									className={`form-group upper-7 ${
										errors.category ? 'is-invalid' : ''
									}`}
								>
									<div
										className="form-row "
										style={{
											zIndex: 110,
										}}
									>
										<div className="col-4">
											<label>
												{language.category} ({language.optional}):
											</label>
										</div>
										<div className="col-8">
											<CreatableSelect
												className="react-select"
												formatCreateLabel={(v) => (
													<span>
														{language.new_category}: {v}
													</span>
												)}
												classNamePrefix="react-select"
												isClearable
												onChange={(res) => {
													if (res === null) {
														setFieldValue('category', '')
														setFieldValue('subcategory', '')
													} else {
														setFieldValue('category', res.value)
													}
												}}
												options={getPrevCategories()}
												defaultValue={{
													label: !editting
														? ''
														: editting.category,
													value: !editting
														? ''
														: editting.category,
												}}
												formatCreateLabel={(str) =>
													`Crear "${str}"`
												}
												noOptionsMessage={() => language.nooptions}
											/>
										</div>
									</div>
									{errors.category && <small>{errors.category}</small>}
								</div>
								<div
									className={`form-group upper-5 ${
										values.category === '' ? 'is-disabled' : ''
									} ${errors.subcategory ? 'is-invalid' : ''}`}
								>
									<div
										className="form-row"
										style={{
											zIndex: 100,
										}}
									>
										<div className="col-4">
											<label>
												{language.subcategory} ({language.optional}
												):
											</label>
										</div>
										<div className="col-8">
											<CreatableSelect
												isDisabled={values.category === ''}
												className="react-select"
												formatCreateLabel={(v) => (
													<span>
														{language.new_subcategory}: {v}
													</span>
												)}
												classNamePrefix="react-select"
												isClearable
												onChange={(res) => {
													if (res === null) {
														setFieldValue('subcategory', '')
													} else {
														setFieldValue(
															'subcategory',
															res.value
														)
													}
												}}
												options={getPrevSubCategories(
													values.category
												)}
												defaultValue={{
													label: !editting
														? ''
														: editting.subcategory,
													value: !editting
														? ''
														: editting.subcategory,
												}}
												formatCreateLabel={(str) =>
													`Crear "${str}"`
												}
												noOptionsMessage={() => language.nooptions}
											/>
										</div>
									</div>
									{errors.subcategory && (
										<small>{errors.subcategory}</small>
									)}
								</div>

								{place.type !== 'beauty' && (
									<div className="form-group upper-3">
										<div className="form-row">
											<div className="col-4">
												<label>
													{language.allergens} (Opcional):
												</label>
											</div>
											<div className="col-8">
												<Select
													onChange={setAllergens}
													value={allergens}
													isMulti
													name="colors"
													options={_allergens.map((all) => ({
														value: all.value,
														label: language[all.value],
													}))}
													className="react-select"
													classNamePrefix="react-select"
													placeholder="Seleccione"
												/>
											</div>
										</div>
									</div>
								)}

								{errors.additions ? (
									<div className="alert-error">{errors.additions}</div>
								) : null}

								<Additions
									touched={touched}
									errors={errors}
									values={values}
									submitting={submitting}
									setFieldValue={(a, b) => setFieldValue(a, b)}
									initial={indexAdds}
									language={language}
								/>
								{renderAddFields(setFieldValue)}

								{/* <div className="adder has-popover">
								<button
									className="button button-light"
									onClick={e => {
										e.preventDefault()
										setFieldValue(
											`inventory_items[${inventoryItems}].id`,
											''
										)
										setFieldValue(
											`inventory_items[${inventoryItems}].quantity`,
											1
										)
										setInventoryItems(inventoryItems + 1)
									}}
								>
									<i className="mi">add</i>{' '}
									<span>Add inventory item</span>
								</button>
								<Popover
									content="Select the necessary inventory items to cook a portion of this product"
									prevent={false}
									offset={10}
								>
									<i className="mi">help</i>
								</Popover>
							</div> */}
								{!service && (
									<>
										<h3>CARTA</h3>
										<div className={`form-group form-group-check`}>
											<div className="form-row">
												<div className="col-12">
													<Field
														type="checkbox"
														name="show_in_products_list"
														id="show_in_products_list"
														checked={values.show_in_products_list}
														onChange={(e) => {
															setFieldValue(
																'show_in_products_list',
																e.target.checked
															)
															if (!e.target.checked) {
																setFieldValue(
																	'show_price_in_products_list',
																	false
																)
																setFieldValue(
																	'allow_order_from_app',
																	false
																)
															}
														}}
													/>
													<label htmlFor="show_in_products_list">
														<b></b>{' '}
														<span>
															{place.type === 'store'
																? 'Mostrar este producto en la app'
																: 'Mostrar producto en la carta'}
														</span>
													</label>
												</div>
											</div>
										</div>
										<div className={`form-group form-group-check`}>
											<div className="form-row">
												<div className="col-12">
													<Field
														type="checkbox"
														name="show_price_in_products_list"
														id="show_price_in_products_list"
														checked={
															values.show_price_in_products_list
														}
														onChange={(e) => {
															setFieldValue(
																'show_price_in_products_list',
																e.target.checked
															)
															if (e.target.checked) {
																setFieldValue(
																	'show_in_products_list',
																	true
																)
															} else {
																setFieldValue(
																	'allow_order_from_app',
																	false
																)
															}
														}}
														// value={values.show_price_in_products_list}
													/>
													<label htmlFor="show_price_in_products_list">
														<b></b>{' '}
														<span>
															Mostrar precio del producto en la
															carta
														</span>
													</label>
												</div>
											</div>
										</div>
										<h3
											style={{
												marginTop: 50,
											}}
										>
											PEDIDOS
										</h3>

										<div className="form-group form-group-check">
											<div className="form-row">
												<div className="col-12">
													<Field
														type="checkbox"
														name="allow_order_from_app"
														id="allow_order_from_app"
														checked={values.allow_order_from_app}
														onChange={(e) => {
															setFieldValue(
																'allow_order_from_app',
																e.target.checked
															)
															if (e.target.checked) {
																setFieldValue(
																	'show_in_products_list',
																	true
																)
																setFieldValue(
																	'show_price_in_products_list',
																	true
																)
															}
														}}
														// value={values.allow_order_from_app}
													/>
													<label htmlFor="allow_order_from_app">
														<b></b>{' '}
														<span>
															Recibir pedidos de este producto
															para consumir aquí
														</span>
													</label>
												</div>
											</div>
										</div>
										<div className="form-group form-group-check">
											<div className="form-row">
												<div className="col-12">
													<Field
														type="checkbox"
														name="allow_order_from_app_pickup"
														id="allow_order_from_app_pickup"
														checked={
															values.allow_order_from_app_pickup
														}
														onChange={(e) => {
															setFieldValue(
																'allow_order_from_app_pickup',
																e.target.checked
															)
															if (e.target.checked) {
																setFieldValue(
																	'show_in_products_list',
																	true
																)
																setFieldValue(
																	'show_price_in_products_list',
																	true
																)
															}
														}}
														// value={values.allow_order_from_app_pickup}
													/>
													<label htmlFor="allow_order_from_app_pickup">
														<b></b>{' '}
														<span>
															Recibir pedidos de este producto
															para recoger
														</span>
													</label>
												</div>
											</div>
										</div>
									</>
								)}
								<footer className="cols-2">
									<button
										onClick={toggle}
										className="button button-light"
										type="button"
									>
										{language.cancel}
									</button>

									<button
										disabled={submitting}
										type="submit"
										className="button button-primary"
									>
										{submitting && (
											<div role="status">
												<span>{language.loading}...</span>
											</div>
										)}
										{!submitting && language.save}
									</button>
									{editting && (
										<button
											onClick={(e) => setDeleting(true)}
											className="button button-link button-link-sm button-link-delete"
											type="button"
										>
											<i className="mi">delete</i>
											<span>{language.remove}</span>
										</button>
									)}
								</footer>
							</Form>
						)
					}}
				</Formik>
			</ModalForm>
		</>
	)
}

const mapStateToProps = (state) => ({
	categories: state.categories,
	place: state.place,
	inventory: state.inventory,
	products: state.products,
	language: state.language.dictionary,
})

export default connect(mapStateToProps, { create_alert, update_products })(
	Create
)

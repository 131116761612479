const initialState = null

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'SET_USER':
			let main_password = payload.main_password || null
			return { ...payload, main_password }
		case 'UPDATE_USER':
			let usr = { ...state, ...payload }
			let _main_password = usr.main_password || null
			let re = { ...usr, main_password: _main_password }

			return re
		case 'SIGN_OUT':
			return false
		case 'REMOVE_USER':
			return false
		default:
			return state
	}
}

const initialState = {
	top: 0,
	paddingTop: 0,
}

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'UPDATE_TOPBAR':
			return { ...state, ...payload }

		default:
			return state
	}
}

const initialState = { employee: null, product: null }

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'UPDATE_EMPLOYEE_CATEGORIES':
			return { ...state, employee: payload }
		case 'UPDATE_PRODUCT_CATEGORIES':
			return { ...state, product: payload }
		case 'SIGN_OUT':
			return initialState
		default:
			return state
	}
}

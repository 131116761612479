import addYears from 'date-fns/addYears'
import QRCode from 'qrcode'
import React, { useEffect, useState, useRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Input, ModalForm } from '../../../components'
import firebase, { firestore, functions, auth } from '../../../config/firebase'
import { create_alert, events } from '../../../store/actions'
import { isMobile } from 'react-device-detect'
import * as htmlToImage from 'html-to-image'
import { useCookies } from 'react-cookie'
import download from 'downloadjs'

function SellTickets({
	setSellTickets,
	updateEvents,
	event,
	user,
	place,
	create_alert,
	isCollaborator = null,
	language,
	events,
	set_events,
}) {
	const [submitting, setSubmitting] = useState(false)
	const [tickets, setTickets] = useState([
		{
			name: '',
			dni: '',
			email: '',
			seat: 'none',
			birth: null,
			event: event.slug,
			place: place.id,
			id: parseInt(
				Math.floor(Math.random() * 10000000) + '' + new Date().getTime()
			),
		},
	])
	const [promo, setPromo] = useState(null)
	const [cookies, setCookie, removeCookie] = useCookies(['print'])
	const [stage, setStage] = useState(0)
	const [method, setMethod] = useState('cash')
	const [received, setReceived] = useState('NaN')
	const [colDiscount, setColDiscount] = useState(0)
	const [printInvoice, setPrintInvoice] = useState(false)
	const invoiceRef = useRef(null)

	useEffect(() => {
		getDiscount()
	}, [])

	const doInvoice = (id) => {
		if (!isMobile)
			htmlToImage
				.toPng(document.getElementById('PRINT_INVOICE'))
				.then(function (dataUrl) {
					download(dataUrl, `${place.id}-ticket-${id}.png`)
					create_alert(language.tickets_sold, 'success')
					setPrintInvoice(false)
					setSellTickets(null)
				})
	}

	const printOnDesktop = async (order) => {
		let id = Math.floor(Math.random() * 100000000000)
		let obj = {
			order,
			place,
			id,
		}

		await firestore.runTransaction(async (trans) => {
			let latestData = await trans.get(
				firestore.doc(
					`/users/${auth.currentUser.uid}/printers/${cookies.print}`
				)
			)
			latestData = latestData.data()
			let q = latestData.queue || []
			trans.update(
				firestore.doc(
					`/users/${auth.currentUser.uid}/printers/${cookies.print}`
				),
				{
					queue: [...q, obj],
				}
			)
		})
	}

	const renderInvoice = () => {
		// let total = 0
		// let total_taxed = 0
		// printInvoice.items.forEach((it) => {
		// 	let qty = it.quantity || it.units
		// 	let it_charge = it.price_type === 'units' ? it.price * qty : it.price
		// 	total += it_charge
		// 	total_taxed +=
		// 		it.taxrate && it.taxrate > 0 ? it_charge * (it.taxrate / 100) : 0
		// 	it.additions.forEach((add) => {
		// 		add.items.forEach((_it) => {
		// 			if (_it.quantity > 0) {
		// 				total += _it.value * _it.quantity
		// 				total_taxed +=
		// 					it.taxrate && it.taxrate > 0
		// 						? _it.value * _it.quantity * (it.taxrate / 100)
		// 						: 0
		// 			}
		// 		})
		// 	})
		// })

		let billing_info = Object.keys(place.billing).map((info_key) => {
			return (
				<span>
					{language[info_key]}: {place.billing[info_key]}
				</span>
			)
		})

		let invoiceDate = new Date(printInvoice.date)
		let invoiceTime = `${`0${invoiceDate.getHours()}`.slice(
			-2
		)}:${`0${invoiceDate.getMinutes()}`.slice(-2)}`

		return (
			<div ref={invoiceRef} id="PRINT_INVOICE">
				<h3>{place.name}</h3>
				<strong>TICKET ID #{printInvoice.invoiceID.slice(-6)}</strong>
				<p className="billing">
					<span>
						Fecha: {invoiceDate.toLocaleDateString()} {invoiceTime}
					</span>
					{billing_info}
				</p>
				<table>
					<thead>
						<tr>
							<th>Producto</th>
							<th></th>
							<th></th>
							<th>Valor</th>
						</tr>
					</thead>
					<tbody>
						{printInvoice.items.map((it) => {
							return (
								<>
									<tr>
										<td>
											<strong>{it.name}</strong>
										</td>
										<td />
										<td />
										<td>{it.price} €</td>
									</tr>
									<tr>
										<td colSpan="4">
											<img
												src={it.qr}
												style={{
													width: 500,
													height: 500,
												}}
											/>
										</td>
									</tr>
								</>
							)
						})}
						{/* {printInvoice.items.map((it) => {
							let additions = []
							let qty = it.units || it.quantity
							let it_total =
								it.price_type === 'units' ? it.price * qty : it.price
							let it_taxed =
								it.taxrate && it.taxrate > 0
									? it_total * (it.taxrate / 100)
									: 0
							it.additions.forEach((add) => {
								let hasAny = false
								add.items.forEach((_it) => {
									if (_it.quantity > 0) hasAny = true
								})
								if (hasAny)
									additions.push({
										...add,
										items: add.items.filter((a) => a.quantity > 0),
									})
							})

							let adds_rows = additions.map((add) => (
								<>
									<tr>
										<td colSpan="4">— {add.name}</td>
									</tr>
									{add.items.map((it_) => {
										let it__charge =
											typeof it_.quantity !== 'undefined'
												? it_.quantity * it_.value
												: it_.value
										let it__taxed =
											it.taxrate && it.taxrate > 0
												? it__charge * (it.taxrate / 100)
												: 0
										return (
											<tr>
												<td>—— {it_.name}</td>
												<td>{it_.quantity}</td>
												<td>{it__taxed.toFixed(2)} €</td>
												<td>{it__charge} €</td>
											</tr>
										)
									})}
								</>
							))

							return (
								<>
									<tr>
										<td>
											<strong>{it.name}</strong>
										</td>
										<td></td>
										<td>{it_taxed.toFixed(2)} €</td>
										<td>{it_total} €</td>
									</tr>
									{adds_rows}
								</>
							)
						})} */}
						<tr>
							<td colSpan="3">
								<strong>Total:</strong>
							</td>

							<td>
								<strong>{printInvoice.amount} €</strong>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}

	const getDiscount = async () => {
		if (!user.phone) return
		let phone = null
		if (isCollaborator) phone = isCollaborator.phone
		else phone = user.phone.replace('+', '')
		if (typeof event.place !== 'undefined') {
			let _user = await firestore
				.collection('collaborators')
				.doc(phone)
				.get()
			_user = _user.data()
			let event_name = event.place
			if (typeof event_name === 'object') event_name = event_name.place
			setColDiscount(_user.places[event_name].discount)
		}
	}

	const addToCollaboratorSell = async ({ value, event, id, place }) => {
		try {
			let email = null
			if (isCollaborator) email = isCollaborator.email
			else email = user.email.replace('+', '')

			let col = await firestore.collection('collaborators').doc(email).get()
			col = col.data()

			await firestore
				.collection('collaborators')
				.doc(email)
				.update({
					places: {
						...col.places,
						[place]: {
							...col.places[place],
							sales: [...col.places[place].sales, { event, value, id }],
						},
					},
				})
		} catch (e) {
		} finally {
			return true
		}
	}

	const onPay = async (qrs) => {
		let _place_ = place
		let _user = null

		let is_selling_as_collaborator = false

		const fn = functions.httpsCallable('getCollaboratorPlace')

		if (isCollaborator) {
			let res = await fn({
				place: event.place,
			})
			if (res.data !== false) _place_ = res.data
			is_selling_as_collaborator = true
		} else if (typeof event.place !== 'undefined') {
			_user = await firestore
				.collection('collaborators')
				.doc(user.email)
				.get()
			_user = _user.data()

			let res = await fn({
				place: event.place.place,
			})
			if (res.data !== false) _place_ = res.data
			is_selling_as_collaborator = true
		} else {
			_place_ = place
		}

		let price = 0
		tickets.forEach((ti) => {
			if (ti.seat !== 'none') {
				let seat = event.groups.find((gr) => gr.id === ti.seat)
				if (seat.multiple_prices) {
					let subseat = -1
					seat.prices.forEach((s, x) => {
						if (s.name === ti.subseat) subseat = x
					})
					if (subseat !== -1) price += seat.prices[subseat].price
				} else {
					price += seat.price
				}
			}
		})
		let discount_rate = 0
		if (promo !== null) {
			let _promo = event.promotions.find((pr) => pr.code === promo)
			if (typeof _promo !== 'undefined') {
				discount_rate = parseInt(_promo.discount) / 100
			}
		}

		if (typeof _user !== 'undefined' && _user !== null)
			discount_rate += _user.places[_place_.id].discount

		let total = price - price * discount_rate

		let invoiceID = `${_place_.id}_INV_${tickets[0].dni}${Math.floor(
			Date.now() / 10000
		)}${Math.floor(Math.random() * 1000)}`
		try {
			const addCollaboratorInvoice = functions.httpsCallable(
				'addCollaboratorInvoice'
			)
			const updateCollaboratorEvent = functions.httpsCallable(
				'updateCollaboratorEvent'
			)
			const updateSoldCollaborator = functions.httpsCallable(
				'updateSoldCollaborator'
			)
			await addCollaboratorInvoice({
				place: _place_.id,
				invoice: invoiceID,
				data: {
					dni: tickets[0].dni,
					first_name: tickets[0].name,
					last_name: '',
					address: _place_.address.value,
					invoiceID: invoiceID,
					date: Date.now(),
					amount: total,
					taxes: total * (_place_.taxRate / 100),
					items: tickets.map((tik, i) => {
						let item_price = 0
						if (tik.is_multiple) {
							let seat = event.groups.find((gr) => gr.id === tik.seat)
							let subseat_ = -1
							seat.prices.forEach((s, x) => {
								if (s.name === tik.subseat) subseat_ = x
							})

							if (subseat_ !== -1)
								item_price = seat.prices[subseat_].price
						} else {
							item_price = event.groups.find((gr) => gr.id === tik.seat)
								.price
						}
						return {
							id: tik.id,
							name: event.groups.find((gr) => gr.id === tik.seat).name,
							price: item_price,
							qr: qrs[i],
						}
					}),
					place: _place_.name,
					place_address: _place_.address.value,
					seller: isCollaborator
						? isCollaborator.name
						: `${user.first_name} ${user.last_name}`,
				},
			})

			let tick = {
				dni: tickets[0].dni,
				first_name: tickets[0].name,
				last_name: '',
				address: _place_.address.value,
				invoiceID: invoiceID,
				date: Date.now(),
				amount: total,
				taxes: total * (_place_.taxRate / 100),
				items: tickets.map((tik, i) => {
					let item_price = 0
					if (tik.is_multiple) {
						let seat = event.groups.find((gr) => gr.id === tik.seat)
						let subseat_ = -1
						seat.prices.forEach((s, x) => {
							if (s.name === tik.subseat) subseat_ = x
						})

						if (subseat_ !== -1) item_price = seat.prices[subseat_].price
					} else {
						item_price = event.groups.find((gr) => gr.id === tik.seat)
							.price
					}
					return {
						id: tik.id,
						name: event.groups.find((gr) => gr.id === tik.seat).name,
						price: item_price,
						qr: qrs[i],
					}
				}),
				place: _place_.name,
				place_address: _place_.address.value,
				seller: isCollaborator
					? isCollaborator.name
					: `${user.first_name} ${user.last_name}`,
			}

			for (let x = 0; x < tickets.length; x++) {
				try {
					const element = tickets[x]

					// ({ value, event, id, place }
					if (isCollaborator || typeof event.place !== 'undefined') {
						let event_name = event.place
						if (typeof event_name === 'object')
							event_name = event_name.place
						await addToCollaboratorSell({
							value:
								event.groups.find((g) => g.id === element.seat).price *
								(1 - discount_rate),
							event: event.slug,
							place: event_name,
							id: element.id.toString(),
						})
					}

					await updateCollaboratorEvent({
						place: _place_.id,
						event: event.slug,
						data: {
							groups: event.groups.map((g) => {
								if (g.id !== element.seat) return g
								return {
									...g,
									purchased: g.purchased + 1,
								}
							}),
						},
					})

					set_events(
						events.map((e) => {
							if (e.slug !== event.slug) return e
							return {
								...e,
								groups: event.groups.map((g) => {
									if (g.id !== element.seat) return g
									return {
										...g,
										purchased: g.purchased + 1,
									}
								}),
							}
						})
					)

					await updateSoldCollaborator({
						place: _place_.id,
						event: event.slug,
						element,
						data: {
							...tick,
							...element,
							used: 0,
							last_used: null,
							category: event.groups.find((gr) => gr.id === element.seat)
								.name,
						},
					})
				} catch (e) {}
			}
			if (!is_selling_as_collaborator) {
				let res_sales = await firestore
					.collection('places')
					.doc(place.id)
					.collection('stats')
					.doc('sales')
					.get()

				let data = res_sales.data()
				let eventsData = {}
				if (typeof data !== 'undefined') eventsData = data.events

				eventsData[event.slug] = {
					quantity:
						typeof eventsData[event.slug] !== 'undefined'
							? eventsData[event.slug].quantity + tickets.length
							: tickets.length,
					money:
						typeof eventsData[event.slug] !== 'undefined'
							? eventsData[event.slug].money + total
							: total,
					name: event.name,
				}

				await firestore
					.collection('places')
					.doc(place.id)
					.collection('stats')
					.doc('sales')
					.update({
						events: eventsData,
					})
			}
			if (cookies.print) {
				printOnDesktop(tick)
				create_alert(`Imprimiendo en ${cookies.print}`, 'success')
			} else {
				setPrintInvoice(tick)
				setTimeout(() => {
					doInvoice(tick.invoiceID)
				}, 200)
			}
		} catch (e) {
			create_alert(language.an_error_ocurred, 'danger')
			setSellTickets(null)
		}
	}

	const onSellTicket = (e) => {
		setSubmitting(true)
		e.preventDefault()
		e.stopPropagation()
		let qrs = []
		tickets.forEach((ticket) => {
			QRCode.toDataURL(ticket.id.toString(), (err, _url) => {
				qrs.push(_url)
			})
		})
		onPay(qrs)
	}

	const getSeatOptions = () => {
		let opts = []
		opts.push({
			value: 'none',
			label: '',
		})
		event.groups.forEach((gr) => {
			let multiple = gr.multiple_prices || false
			opts.push({
				value: gr.id,
				label: `${gr.name} | ${gr.seats - gr.purchased} ${language.left} ${
					multiple ? '' : `| ${gr.price} €`
				}`,
			})
		})
		return opts
	}
	const getSeatSubOptions = (gr) => {
		let opts = []
		gr.prices.forEach((pr) => {
			opts.push({
				value: pr.name,
				label: `${pr.name} | ${pr.price}€`,
			})
		})
		return opts
	}

	const renderSubseat = (ticket, index) => {
		let group = event.groups.find((g) => g.id === ticket.seat)

		if (
			typeof group === 'undefined' ||
			!group.multiple_prices ||
			ticket.seat === 'none'
		)
			return null

		return (
			<div className="form-group">
				<div className="form-row">
					<div className="col-4">
						<label>Tipo *:</label>
					</div>
					<div className="col-8">
						<Select
							className="react-select"
							classNamePrefix="react-select"
							options={getSeatSubOptions(group)}
							onChange={(e) => {
								setTickets(
									tickets.map((_ticket, j) => {
										if (index !== j) return _ticket
										return {
											..._ticket,
											subseat: e.value,
										}
									})
								)
							}}
							// noOptionsMessage="No hay opciones"
							defaultValue={{
								value: 'none',
								label: ``,
							}}
							placeholder={language.select}
						/>
					</div>
				</div>
			</div>
		)
	}

	const getReadableDate = (date) => {
		let date_obj = new Date(date)
		let label = date_obj.toDateString() + ' '
		let hours = date_obj.getHours()
		let minutes = date_obj.getMinutes()
		label += hours < 10 ? `0${hours}` : hours
		label += ':'
		label += minutes < 10 ? `0${minutes}` : minutes
		return label
	}

	let price = 0
	tickets.forEach((ti) => {
		if (ti.seat !== 'none') {
			let seat = event.groups.find((gr) => gr.id === ti.seat)
			if (seat.multiple_prices) {
				let subseat = -1
				seat.prices.forEach((s, x) => {
					if (s.name === ti.subseat) subseat = x
				})
				if (subseat !== -1) price += seat.prices[subseat].price
			} else {
				price += seat.price
			}
		}
	})

	let discount_rate = colDiscount
	if (promo !== null) {
		let _promo = event.promotions.find((pr) => pr.code === promo)
		if (typeof _promo !== 'undefined') {
			discount_rate += parseInt(_promo.discount) / 100
		}
	}

	let good = true
	tickets.forEach((tk) => {
		if (tk.name === '') good = false
		if (tk.dni === '') good = false
		if (tk.seat === 'none') good = false
		if (tk.birth === null) good = false
		if (tk.birth === null) good = false
		if (tk.is_multiple && !tk.subseat) good = false
	})

	if (stage === 1) {
		let cash_change = (received - (price - price * discount_rate)).toFixed(2)
		let cash_change_clss =
			cash_change < 0 ? 'price price-red' : 'price price-gray'
		if (isNaN(cash_change)) cash_change_clss = 'price price-red'
		return (
			<>
				<ModalForm toggle={() => setSellTickets(null)}>
					<form className="form-ui" onSubmit={onSellTicket}>
						<h1>{language.checkout}</h1>
						<em>
							{event.name} x {tickets.length} {language.seats}
						</em>
						<Input
							label={language.promotion_code}
							submitting={submitting}
							type="text"
							noformik
							custom={{
								onChange: (e) => setPromo(e.target.value),
							}}
						/>
						<Input
							label={language.payment_method}
							submitting={submitting}
							type="select"
							noformik
							custom={{
								component: 'select',
								onChange: (e) => setMethod(e.target.value.toString()),
								value: method,
							}}
						>
							<option value="cash">{language.cash}</option>
							<option value="card">{language.card}</option>
						</Input>
						{method === 'cash' && (
							<Input
								label={language.received_amount}
								submitting={submitting}
								type="number"
								noformik
								custom={{
									onChange: (e) => {
										let vl = isNaN(parseFloat(e.target.value))
											? ''
											: parseFloat(e.target.value)
										setReceived(vl)
									},
									value: parseFloat(received),
								}}
							/>
						)}
						<div className="checkout-summary">
							<div>
								<b>{language.price}:</b>
								<span className="price price-gray">
									<pre>{price.toFixed(2)}</pre>
									<i className="mi">euro_symbol</i>
								</span>
							</div>
							<div>
								<b>{language.discount}:</b>
								<span className="price price-gray">
									<pre>{(price * discount_rate).toFixed(2)}</pre>
									<i className="mi">euro_symbol</i>
								</span>
							</div>
							<div>
								<b>{language.total}:</b>
								<span className="price">
									<pre>
										{(price - price * discount_rate).toFixed(2)}
									</pre>
									<i className="mi">euro_symbol</i>
								</span>
							</div>
							{method === 'cash' && (
								<div>
									<b>{language.change}:</b>
									<span className={`${cash_change_clss}`}>
										<pre>
											{isNaN(cash_change) ? '...' : cash_change}
										</pre>
										<i className="mi">euro_symbol</i>
									</span>
								</div>
							)}
						</div>
						<footer>
							<button
								type="submit"
								disabled={
									submitting ||
									((cash_change < 0 || isNaN(cash_change)) &&
										method === 'cash')
								}
								className="button button-primary"
							>
								{submitting && (
									<div
										className="spinner-border spinner-border-sm"
										role="status"
									>
										<span className="sr-only">
											{language.loading}...
										</span>
									</div>
								)}
								{!submitting && (
									<>
										<i className="mi">done</i>
										<span>{language.continue}</span>
									</>
								)}
							</button>
						</footer>
					</form>
				</ModalForm>
				{printInvoice !== false && renderInvoice()}
			</>
		)
	}

	if (stage === 0) {
		let img = event.images[0]
		return (
			<ModalForm toggle={() => setSellTickets(null)}>
				<form
					className="form-ui"
					onSubmit={(e) => {
						e.preventDefault()
						e.stopPropagation()
						setStage(1)
					}}
				>
					<h1>{language.sell_tickets}</h1>
					<div className="info-card">
						<aside>
							<span
								className="poster"
								style={{
									backgroundImage: `url(${img})`,
								}}
							>
								{(img === null ||
									typeof img === 'undefined' ||
									img.length === 0) && (
									<i className="mi">local_activity</i>
								)}
							</span>
						</aside>
						<div>
							<strong>{event.name}</strong> <em>{event.description}</em>
							{typeof event.placeName !== 'undefined' && (
								<div className="badges">
									<b>
										<i className="mi">star</i> {event.placeName}
									</b>
								</div>
							)}
							<ul>
								<li>
									<b>{language.minimum_age}:</b> {event.age}{' '}
								</li>
								<li>
									<b>{language.start_date}:</b>{' '}
									{getReadableDate(event.startDate)}{' '}
								</li>
								<li>
									<b>{language.end_date}:</b>{' '}
									{getReadableDate(event.endDate)}
								</li>
							</ul>
							<div className="sells">
								{event.groups.map((gr) => {
									let perc = (gr.purchased * 100) / gr.seats
									return (
										<div key={gr.name} className="progress-bar">
											<b title={`${perc}% sold`}>
												<i style={{ width: `${perc}%` }}></i>
											</b>
											<strong>{gr.name}</strong>
											<small>
												{gr.purchased} / {gr.seats} {language.seats}
											</small>
										</div>
									)
								})}
							</div>
						</div>
					</div>

					{tickets.map((ticket, i) => {
						let fieldset_class = ''
						if (i === 0) fieldset_class += ' first-child'
						if (i === tickets.length - 1) fieldset_class += ' last-child'
						return (
							<fieldset key={ticket.id} className={fieldset_class}>
								<div className="fieldset-header">
									<h2>{`${language.ticket} #${i + 1}`}</h2>
									{i !== 0 && (
										<button
											type="button"
											className="button button-link button-link-sm button-link-delete"
											onClick={(e) => {
												e.preventDefault()
												e.stopPropagation()
												setTickets(
													tickets.filter(
														(tk) => tk.id !== ticket.id
													)
												)
											}}
										>
											<i className="mi">delete</i>
											<span>{language.remove}</span>
										</button>
									)}
								</div>
								<Input
									label={`${language.full_name} *`}
									submitting={submitting}
									type="text"
									noformik
									rowcolleft={4}
									rowcolright={8}
									custom={{
										onChange: (e) => {
											setTickets(
												tickets.map((_ticket, j) => {
													if (i !== j) return _ticket
													return {
														..._ticket,
														name: e.target.value,
													}
												})
											)
										},
										value: ticket.name,
									}}
								/>
								<div className="form-group">
									<div className="form-row">
										<div className="col-4">
											<label htmlFor="">
												{language.birthdate} *:
											</label>
										</div>
										<div className="col-8">
											<DatePicker
												showYearDropdown
												scrollableYearDropdown
												locale="es"
												yearDropdownItemNumber={100}
												selected={ticket.birth}
												onChange={(e) => {
													setTickets(
														tickets.map((_ticket, j) => {
															if (i !== j) return _ticket
															return {
																..._ticket,
																birth: e,
															}
														})
													)
												}}
												maxDate={addYears(
													new Date(),
													event.age * -1
												)}
											/>
										</div>
									</div>
								</div>
								<p></p>

								<Input
									label="DNI / ID *"
									submitting={submitting}
									type="text"
									noformik
									rowcolleft={4}
									rowcolright={8}
									custom={{
										onChange: (e) => {
											setTickets(
												tickets.map((_ticket, j) => {
													if (i !== j) return _ticket
													return {
														..._ticket,
														dni: e.target.value,
													}
												})
											)
										},
										value: ticket.dni,
									}}
								/>
								<Input
									label={`${language.email} `}
									submitting={submitting}
									type="email"
									noformik
									rowcolleft={4}
									rowcolright={8}
									custom={{
										onChange: (e) => {
											setTickets(
												tickets.map((_ticket, j) => {
													if (i !== j) return _ticket
													return {
														..._ticket,
														email: e.target.value,
													}
												})
											)
										},
										value: ticket.email,
									}}
								/>
								<div className="form-group upper-7">
									<div className="form-row">
										<div className="col-4">
											<label>{language.seat} *:</label>
										</div>
										<div className="col-8">
											<Select
												className="react-select"
												classNamePrefix="react-select"
												options={getSeatOptions()}
												onChange={(e) => {
													setTickets(
														tickets.map((_ticket, j) => {
															if (i !== j) return _ticket
															if (e.value === 'none')
																return {
																	..._ticket,
																	seat: 'none',
																}

															let group = event.groups.find(
																(g) =>
																	g.id === parseInt(e.value)
															)

															return {
																..._ticket,
																seat: parseInt(e.value),
																is_multiple:
																	group.multiple_prices ||
																	false,
															}
														})
													)
												}}
												defaultValue={{
													value: 'none',
													label: ``,
												}}
												placeholder={language.select}
											/>
										</div>
									</div>
								</div>
								{renderSubseat(ticket, i)}
							</fieldset>
						)
					})}
					<div className="adder">
						<button
							disabled={submitting}
							className="button button-light"
							type="button"
							onClick={() => {
								setTickets([
									...tickets,
									{
										name: '',
										dni: '',
										email: '',
										seat: 'none',
										event: event.slug,
										place: place.id,
										id: parseInt(
											Math.floor(Math.random() * 10000000) +
												'' +
												new Date().getTime()
										),
									},
								])
							}}
						>
							{language.add_ticket}
						</button>
					</div>
					<footer>
						<button
							type="submit"
							disabled={!good}
							className="button button-primary"
						>
							{submitting && (
								<div
									className="spinner-border spinner-border-sm"
									role="status"
								>
									<span className="sr-only">
										{language.loading}...
									</span>
								</div>
							)}
							{!submitting && (
								<>
									<i className="mi">arrow_forward</i>
									<span>{language.continue}</span>
								</>
							)}
						</button>
					</footer>
				</form>
			</ModalForm>
		)
	}
}

export default connect(
	(state) => ({
		place: state.place,
		user: state.user,
		language: state.language.dictionary,
		events: state.events,
	}),
	{
		create_alert,
		set_events: events,
	}
)(SellTickets)

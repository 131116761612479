import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, { registerLocale } from 'react-datepicker'
import addDays from 'date-fns/addDays'
import addMinutes from 'date-fns/addMinutes'
import { connect } from 'react-redux'
import { create_alert } from '../../../store/actions'
import { ModalForm, Input, TablesMap, Loading } from '../../../components'
import Select from 'react-select'
import { firestore, functions } from '../../../config/firebase'

function Reservations({
	place,
	tables,
	create_alert,
	language,
	reservations,
	topbar,
	dayViewing,
	setDayViewing,
	showAdd,
	setShowAdd,
	todayIs,
	confirmingReservation,
	user,
	history,
	setConfirmingReservation,
}) {
	const [submitting, setSubmitting] = useState(false)
	const [loading, setLoading] = useState(false)
	const [tablesState, setTablesState] = useState(null)
	const [ableToConsult, setAbleToConsult] = useState(false)
	const [selectedTable, setSelectedTable] = useState(null)
	const [reservationsGeneralData, setReservationsGeneralData] = useState(null)
	const [editingReservation, setEditingReservation] = useState(null)
	const [placeTables, setPlaceTables] = useState(0)

	useEffect(() => {
		setDayViewing(new Date().getTime())
		let all_tables = 0
		tables.floors.forEach((floor) => {
			floor.tables.forEach((table) => {
				all_tables++
			})
		})
		setPlaceTables(all_tables)
	}, [])

	useEffect(() => {
		if (dayViewing !== null) {
			let openHours = JSON.parse(place.openHours)
			let today = new Date(dayViewing).getDay() - 1
			if (today < 0) today = 6
			today = openHours[today]
			let opensAt = `${`0${today.hours[0][0][0]}`.slice(
				-2
			)}${`0${today.hours[0][0][1]}`.slice(-2)}`

			let closesAt = `${`0${today.hours[0][1][0]}`.slice(
				-2
			)}${`0${today.hours[0][1][1]}`.slice(-2)}`

			if (today.hours[0][1][0] >= 24) closesAt = '2400'

			let opensHalf =
				(parseInt(opensAt.slice(-2)) + parseInt(opensAt.slice(0, 2)) * 60) /
				30

			let closesHalf =
				(parseInt(closesAt.slice(-2)) +
					parseInt(closesAt.slice(0, 2)) * 60) /
				30

			let dayLengthInHalfHours = closesHalf - opensHalf

			setReservationsGeneralData({
				opensAt,
				closesAt,
				opensHalf,
				closesHalf,
				dayLengthInHalfHours,
			})
		}
	}, [dayViewing])

	useEffect(() => {
		if (typeof confirmingReservation === 'object') {
			setAbleToConsult(true)
			setEditingReservation(confirmingReservation)
		}
	}, [confirmingReservation])

	const formatDate = (date) => {
		let _date = new Date(date)
		let day = _date.getDate()
		let month = _date.getMonth() + 1
		let year = _date.getFullYear()
		return `${`0${day}`.slice(-2)}/${`0${month}`.slice(-2)}/${year}`
	}

	const getRandomString = (length) => {
		var result = ''
		var characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
		var charactersLength = characters.length
		for (var i = 0; i < length; i++)
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			)

		return result
	}

	const removeReservation = async (res_id) => {
		if (reservations.list[res_id].uid) {
			const fn = functions.httpsCallable('modifyReservation')
			let res = await fn({
				reservation_id: reservations.list[res_id].id,
				place_id: reservations.list[res_id].place,
				client_id: reservations.list[res_id].uid,
				action: [0, 0],
				reservation: reservations.list[res_id],
			})
			const _func = functions.httpsCallable('sendNotification')
			const _res = await _func({
				content: `Reserva en ${confirmingReservation.placeName} Cancelada`,
				client_id: confirmingReservation.uid,
				channel: 'reservations',
				data: {
					type: 'reservations',
				},
			})
		}

		let new_list = reservations.list
		delete new_list[res_id]

		await firestore
			.collection('places')
			.doc(place.id)
			.collection('reservations')
			.doc('list')
			.set(new_list)

		let new_tables = {}

		Object.keys(reservations.tables).forEach((t) => {
			let _t = {}
			Object.keys(reservations.tables[t]).forEach((d) => {
				let _d = {}
				Object.keys(reservations.tables[t][d]).forEach((h) => {
					if (reservations.tables[t][d][h] !== res_id)
						_d[h] = reservations.tables[t][d][h]
				})
				_t[d] = _d
			})
			new_tables[t] = _t
		})

		await firestore
			.collection('places')
			.doc(place.id)
			.collection('reservations')
			.doc('tables')
			.set(new_tables)

		return {
			list: new_tables,
			tables: new_tables,
		}
	}

	const submitAddReservations = async (values) => {
		setSubmitting(true)

		let _reservations = reservations

		if (editingReservation !== null && !confirmingReservation) {
			_reservations = await removeReservation(editingReservation)
		}

		setTablesState(null)
		setAbleToConsult(false)
		setSelectedTable(null)

		let start_date = new Date(values.date)
		let formatted_date = getFormattedDate(start_date)

		let halve_hours = values.duration / 30
		let duration_hours = []

		for (let h = 0; h < halve_hours; h++) {
			let dt = addMinutes(start_date, h * 30)
			duration_hours.push(getFormattedHour(dt))
		}

		let reservation_id = getRandomString(20)
		if (typeof confirmingReservation === 'object')
			reservation_id = confirmingReservation.id
		let reservation = {
			id: reservation_id,
			name: values.name,
			date: new Date(values.date).getTime(),
			duration: parseInt(values.duration),
			phone: values.phone,
			email: values.email,
			party_of: parseInt(values.party_of),
			hours: duration_hours,
			notes: `${values.notes}`,
		}
		if (typeof confirmingReservation === 'object')
			reservation.uid = confirmingReservation.uid

		let hours_object = {}
		duration_hours.forEach((h) => {
			hours_object[h] = reservation_id
		})

		let reservations_list = await firestore
			.collection('places')
			.doc(place.id)
			.collection('reservations')
			.doc('list')
			.get()

		if (reservations_list.exists) {
			await firestore
				.collection('places')
				.doc(place.id)
				.collection('reservations')
				.doc('list')
				.update({
					[reservation_id]: reservation,
				})
		} else {
			await firestore
				.collection('places')
				.doc(place.id)
				.collection('reservations')
				.doc('list')
				.set({
					[reservation_id]: reservation,
				})
		}

		let tableRes =
			typeof _reservations.tables[selectedTable.id] === 'undefined'
				? {}
				: _reservations.tables[selectedTable.id]

		let tableDate =
			typeof tableRes[formatted_date] === 'undefined'
				? {}
				: tableRes[formatted_date]

		let reservation_tables = await firestore
			.collection('places')
			.doc(place.id)
			.collection('reservations')
			.doc('tables')
			.get()

		if (reservation_tables.exists) {
			await firestore
				.collection('places')
				.doc(place.id)
				.collection('reservations')
				.doc('tables')
				.update({
					[selectedTable.id]: {
						...tableRes,
						[formatted_date]: {
							...tableDate,
							...hours_object,
						},
					},
				})
		} else {
			await firestore
				.collection('places')
				.doc(place.id)
				.collection('reservations')
				.doc('tables')
				.set({
					[selectedTable.id]: {
						...tableRes,
						[formatted_date]: {
							...tableDate,
							...hours_object,
						},
					},
				})
		}

		let statsRef = firestore
			.collection('places')
			.doc(place.id)
			.collection('stats')
			.doc('sales')

		return firestore
			.runTransaction(function (transaction) {
				return transaction.get(statsRef).then(function (statDoc) {
					let stats = {
						reservations: {},
					}
					if (statDoc.exists) {
						stats = statDoc.data()
					}
					let saved_reservations =
						typeof stats.reservations === 'undefined'
							? {}
							: stats.reservations
					let day_reservations =
						typeof saved_reservations[formatted_date] === 'undefined'
							? { quantity: 0 }
							: saved_reservations[formatted_date]
					let reservations_obj = {
						...saved_reservations,
						[formatted_date]: {
							quantity: day_reservations.quantity + 1,
						},
					}
					if (statDoc.exists)
						transaction.update(statsRef, {
							reservations: reservations_obj,
						})
					else
						transaction.set(statsRef, { reservations: reservations_obj })
				})
			})
			.then(async () => {
				if (user.type === 'admin_payasyougo') {
					const func_ = functions.httpsCallable('updateOrdersPricing')

					let reservationsSub = user.stripe_customer.subscriptions.data[0].items.data.find(
						(s) => s.plan.id === 'plan_HHUszh4Up8klqD'
					)
					if (typeof reservationsSub === 'undefined') throw { error: true }

					await func_({
						subscription_item: reservationsSub.id,
						quantity: 1,
					})
				}

				if (typeof confirmingReservation === 'object') {
					let userDate = confirmingReservation.date
					let mn = confirmingReservation.time.slice(-2)
					let hrs = `00${confirmingReservation.time}`.slice(-4)
					hrs = hrs.slice(-4)
					hrs = hrs.slice(0, 2)
					userDate.setHours(parseInt(hrs))
					userDate.setMinutes(parseInt(mn))
					userDate.setMilliseconds(0)
					userDate.setSeconds(0)

					let newDate = new Date(values.date).getTime()
					let newTime = `${new Date(values.date).getHours()}${`0${new Date(
						values.date
					).getMinutes()}`.slice(-2)}`

					let isModified = userDate.getTime() === newDate

					let action = isModified ? [1, 1] : [0, 1]

					if (isModified) {
						const _func = functions.httpsCallable('sendNotification')
						const _res = await _func({
							content: `Reserva en ${confirmingReservation.placeName} Confirmada`,
							client_id: confirmingReservation.uid,
							channel: 'reservations',
							data: {
								type: 'reservations',
							},
						})
					} else {
						const _func = functions.httpsCallable('sendNotification')
						const _res = await _func({
							content: `Reserva en ${confirmingReservation.placeName} Modificada`,
							client_id: confirmingReservation.uid,
							channel: 'reservations',
							data: {
								type: 'reservations',
							},
						})
					}

					const fn = functions.httpsCallable('modifyReservation')
					await fn({
						reservation_id: confirmingReservation.id,
						place_id: confirmingReservation.place,
						client_id: confirmingReservation.uid,
						action,
						reservation: confirmingReservation,
						payload: {
							date: newDate,
							time: newTime,
						},
					})
					setConfirmingReservation(false)
					history.replace('/reservations/pending')
				}
				setSubmitting(false)
				create_alert(language.reservation_created, 'success')
				setEditingReservation(null)
				setShowAdd(false)
			})
			.catch((error) => {
				setSubmitting(false)
				create_alert('Algo sucedio mientras creabamos la reserva', 'danger')
				setEditingReservation(null)
				setShowAdd(false)
				if (typeof confirmingReservation === 'object') {
					setConfirmingReservation(false)
					history.replace('/reservations/pending')
				}
			})
	}

	const validateAddReservation = (values) => {
		if (
			values.date !== null &&
			parseInt(values.party_of) > 0 &&
			parseInt(values.duration) > 0
		)
			setAbleToConsult(true)
		else setAbleToConsult(false)

		let errors = {}
		if (values.name.length < 2) errors.name = language.invalid_field
		return errors
	}

	const getFormattedDate = (date) => {
		let _date = new Date(date)

		let dd = _date.getDate()
		let mm = _date.getMonth() + 1
		let yyyy = _date.getFullYear()

		if (dd < 10) dd = '0' + dd
		if (mm < 10) mm = '0' + mm

		return dd + '/' + mm + '/' + yyyy
	}

	const getFormattedHour = (date) => {
		let _date = new Date(date)

		let hr = _date.getHours()
		let mn = _date.getMinutes()

		hr = `0${hr}`.slice(-2)
		mn = `0${mn}`.slice(-2)

		return `${hr}${mn}`
	}

	const checkAvailability = async (values) => {
		setLoading(true)

		let start_date = new Date(values.date)
		let formatted_date = getFormattedDate(start_date)

		let duration_hours = []
		let halve_hours = values.duration / 30
		for (let h = 0; h < halve_hours; h++) {
			let dt = addMinutes(start_date, h * 30)
			duration_hours.push(getFormattedHour(dt))
		}

		let tables_state = []
		tables.floors.forEach((floor) => {
			floor.tables.forEach((table) => {
				let free = false
				let res_table = reservations.tables[table.id]
				if (typeof res_table === 'undefined') free = true
				else {
					let table_day = res_table[formatted_date]
					free = true
					duration_hours.forEach((hr) => {
						if (typeof table_day === 'undefined') {
							// Good
						} else if (typeof table_day[hr] === 'undefined') {
							// Good
						} else if (table_day[hr] === null) {
							// Good
						} else if (
							editingReservation !== null &&
							table_day[hr] === editingReservation
						) {
							// Good
						} else {
							free = false
						}
					})
				}
				tables_state.push({
					id: table.id,
					table: table,
					free,
				})
			})
		})

		setTimeout(() => {
			setTablesState(tables_state)
			setLoading(false)
		}, 500)
	}

	const getDurationOptions = (length) => {
		let opts = []
		for (let x = 1; x <= length; x++) {
			let hrs = Math.floor((30 * x) / 60)
			let mins = Math.floor((30 * x) % 60)
			hrs = `0${hrs}`.slice(-2)
			mins = `0${mins}`.slice(-2)
			opts.push({
				value: 30 * x,
				label: `${hrs}:${mins}`,
			})
		}
		return opts
	}

	const renderAddReservation = (editing = false) => {
		let reservation = {}
		if (typeof confirmingReservation === 'object')
			reservation = confirmingReservation
		else if (editing) reservation = reservations.list[editingReservation]
		if (typeof reservation === 'undefined') {
			editing = false
			reservation = {}
		}

		return (
			<ModalForm
				toggle={() => {
					if (editing) {
						setEditingReservation(null)
						if (typeof confirmingReservation === 'object') {
							setConfirmingReservation(false)
							history.replace('/reservations/pending')
						}
					} else setShowAdd(false)
				}}
				size="lg"
			>
				<Formik
					initialValues={{
						name: editing ? reservation.name : '',
						party_of: editing ? reservation.party_of : '',
						phone: editing ? reservation.phone : '',
						email: editing ? reservation.email : '',
						date: editing ? reservation.date : null,
						duration: editing ? reservation.duration : 60,
						notes: editing ? reservation.notes : '',
					}}
					validateOnChange
					validate={validateAddReservation}
					onSubmit={submitAddReservations}
				>
					{({ errors, touched, values, setFieldValue }) => {
						let openHours = JSON.parse(place.openHours)
						let today = null
						if (values.date !== null) {
							today = new Date(values.date).getDay() - 1
						} else {
							today = new Date().getDay() - 1
						}
						if (today < 0) today = 6
						today = openHours[today]

						let openDate = new Date()
						openDate.setHours(
							parseInt(today.hours[0][0][0]),
							parseInt(today.hours[0][0][1])
						)

						let closeDate = new Date()
						closeDate.setHours(
							parseInt(today.hours[0][1][0]),
							parseInt(today.hours[0][1][1])
						)

						return (
							<Form className="form-ui">
								<h1>
									{confirmingReservation === false && editing
										? 'Editar Reserva'
										: confirmingReservation !== false
										? 'Confirmar Reserva'
										: 'Nueva Reserva'}
								</h1>
								<Input
									label={language.name}
									disabled={submitting}
									name="name"
									errors={errors}
									touched={touched}
									cols={8}
									colside="left"
									rowcolleft={4}
									rowcolright={8}
								/>

								<Input
									label={language.phone}
									disabled={submitting}
									name="phone"
									errors={errors}
									touched={touched}
									cols={4}
									colside="right"
									rowcolleft={5}
									rowcolright={7}
								/>

								<Input
									label={language.email}
									disabled={submitting}
									name="email"
									type="email"
									errors={errors}
									touched={touched}
									cols={8}
									colside="left"
									rowcolleft={4}
									rowcolright={8}
								/>

								<Input
									label={language.party_of}
									disabled={submitting}
									name="party_of"
									type="number"
									errors={errors}
									touched={touched}
									cols={4}
									colside="right"
									rowcolleft={6}
									rowcolright={6}
								/>
								<div className="form-group cols-12">
									<div className="form-row">
										<div>
											<label htmlFor="notes">Notas :</label>
										</div>
										<div>
											<Field name="notes" component="textarea" />
										</div>
									</div>
								</div>
								<div className="form-group cols-8 colside-left">
									<div className="form-row">
										<div className="col-4">
											<label>{language.date_and_time} :</label>
										</div>
										<div className="col-8">
											<DatePicker
												selected={values.date}
												onChange={(date) => {
													setSelectedTable(null)
													setTablesState(null)
													setFieldValue('date', date)
												}}
												showTimeSelect
												timeFormat="HH:mm"
												timeIntervals={30}
												timeCaption="time"
												dateFormat="MMMM d, yyyy HH:mm"
												locale="es"
												minDate={new Date().getTime()}
												maxDate={addDays(new Date(), 30)}
												timeCaption={language.hour}
												minTime={openDate}
												maxTime={closeDate}
											/>
										</div>
									</div>
								</div>
								<div className="form-group cols-4 colside-right upper-3">
									<div className="form-row">
										<div className="col-4">
											<label>{language.duration}:</label>
										</div>
										<div className="col-8">
											<Select
												className="react-select"
												classNamePrefix="react-select"
												options={getDurationOptions(20)}
												onChange={(e) => {
													setSelectedTable(null)
													setTablesState(null)
													setFieldValue('duration', e.value)
												}}
												defaultValue={{
													value: values.duration,
													label: `${`0${Math.floor(
														values.duration / 60
													)}`.slice(-2)}:${`0${Math.floor(
														values.duration % 60
													)}`.slice(-2)}`,
												}}
											/>
										</div>
									</div>
								</div>
								{tablesState !== null && (
									<>
										<div
											style={{
												height: 500,
												width: '100%',
												position: 'relative',
											}}
										>
											<TablesMap
												reservations_data={tablesState}
												reservations_ignore={
													editing ? editingReservation : null
												}
												onTableSelected={(t) => {
													if (t !== null) setSelectedTable(t)
												}}
											/>
										</div>
									</>
								)}

								<footer className="cols-2">
									<button
										className="button button-light "
										onClick={(e) => checkAvailability(values)}
										disabled={!ableToConsult || loading}
									>
										{language.check_availability}
									</button>
									<button
										type="submit"
										disabled={submitting || selectedTable === null}
										className="button button-primary"
									>
										{submitting && (
											<div
												className="spinner-border spinner-border-sm"
												role="status"
											>
												<span className="sr-only">
													{language.loading}...
												</span>
											</div>
										)}
										{!submitting && (
											<>
												<span>{language.continue}</span>
											</>
										)}
									</button>
									{editingReservation !== null &&
										confirmingReservation === false && (
											<button
												onClick={async (e) => {
													setSubmitting(true)
													await removeReservation(
														editingReservation
													)
													setSubmitting(false)
													setEditingReservation(null)
												}}
												className="button button-link button-link-sm button-link-delete"
												type="button"
											>
												<i className="mi">delete</i>
												<span>{language.remove}</span>
											</button>
										)}
								</footer>
							</Form>
						)
					}}
				</Formik>
			</ModalForm>
		)
	}

	const renderTablesList = () => {
		let tables_state = []
		tables.floors.forEach((floor) => {
			floor.tables.forEach((table) => {
				tables_state.push({
					id: table.id,
					name: table.name,
				})
			})
		})
		return (
			<div className="tables">
				<header>
					<div className="r"></div>
				</header>
				<section>
					{tables_state.map((t) => (
						<div className="r">
							<strong>{t.name}</strong>
						</div>
					))}
				</section>
			</div>
		)
	}

	const renderHours = () => {
		let len = new Array(reservationsGeneralData.dayLengthInHalfHours).fill(
			null
		)
		return (
			<header>
				<div className="r">
					{len.map((v, i) => {
						let mns = 0
						mns += parseInt(reservationsGeneralData.opensAt.slice(-2))
						mns +=
							parseInt(reservationsGeneralData.opensAt.slice(0, 2)) * 60
						// let mns = reservationsGeneralData.opensHalf * 2 * 60

						let hr = Math.floor(mns / 60) + Math.floor((i * 30) / 60)
						hr = `0${hr}`.slice(-2)

						let mn = Math.floor(mns % 60) + Math.floor((i * 30) % 60)
						mn = `0${mn}`.slice(-2)
						if (mn === '60') {
							mn = '00'
							hr = Math.floor(mns / 60) + Math.floor((i * 30) / 60)
							hr += 1
							hr = `0${hr}`.slice(-2)
						}

						return (
							<span className="slot">
								<time>
									{hr}:{mn}
								</time>
							</span>
						)
					})}
				</div>
			</header>
		)
	}

	const renderReservations = () => {
		let tables_state = []
		let rows = []
		let len = new Array(reservationsGeneralData.dayLengthInHalfHours).fill(
			null
		)

		tables.floors.forEach((floor) => {
			floor.tables.forEach((table) => {
				tables_state.push({
					id: table.id,
					name: table.name,
				})
			})
		})

		rows = tables_state.map((ta) => {
			let res_table = reservations.tables[ta.id]
			let items = []
			if (typeof res_table !== 'undefined') {
				res_table = res_table[formatDate(dayViewing)]
				if (typeof res_table === 'undefined') res_table = {}
			} else res_table = {}

			if (Object.keys(res_table).length === 0) {
				//Nothing
				items = new Array(len.length).fill(<div className="slot"></div>)
			} else {
				let items_data = []
				len.forEach((v, i) => {
					let mns = 0
					mns += parseInt(reservationsGeneralData.opensAt.slice(-2))
					mns += parseInt(reservationsGeneralData.opensAt.slice(0, 2)) * 60

					let hr = Math.floor(mns / 60) + Math.floor((i * 30) / 60)
					hr = `0${hr}`.slice(-2)

					let mn = Math.floor(mns % 60) + Math.floor((i * 30) % 60)
					mn = `0${mn}`.slice(-2)

					let time = `${hr}${mn}`

					if (typeof res_table[time] !== 'undefined') {
						let prev = items_data.find((x) => x.id === res_table[time])
						if (typeof prev === 'object') {
							items_data = items_data.map((d) => {
								if (d.id !== res_table[time]) return d
								return {
									...d,
									width: d.width + 1,
								}
							})
						} else if (
							typeof reservations.list[res_table[time]] !== 'undefined'
						) {
							items_data.push({
								empty: false,
								width: 1,
								id: res_table[time],
								name: reservations.list[res_table[time]].name,
								notes: reservations.list[res_table[time]].notes,
							})
						}
					} else {
						items_data.push({ empty: true, width: null, id: null })
					}
				})
				items = items_data.map((data) => {
					if (data.empty) return <div className="slot"></div>
					return (
						<button
							className={`reservation cols-${data.width} confirmed`}
							onClick={(e) => setEditingReservation(data.id)}
						>
							<cite>
								<b>{data.name}</b>
							</cite>
							{typeof data.notes !== 'undefined' &&
								data.notes.length > 0 && <i className="mi">note</i>}
						</button>
					)
				})
			}

			return <div className="r">{items}</div>
		})

		return <section>{rows}</section>
	}

	if (
		reservationsGeneralData === null ||
		todayIs === null ||
		dayViewing === null ||
		reservations === null
	)
		return <Loading />

	if (placeTables === 0)
		return (
			<aside className="empty">
				<i className="mi">class</i>
				<h3>No has creado mesas</h3>
			</aside>
		)

	return (
		<>
			<div className="reservations-grid">
				<div>
					{renderTablesList()}
					<div
						className={`hours cols-${reservationsGeneralData.dayLengthInHalfHours}`}
					>
						{renderHours()}
						{renderReservations()}
					</div>
				</div>
			</div>
			{showAdd && renderAddReservation()}
			{/* {confirmingReservation && renderAddReservation(confirmingReservation)} */}
			{editingReservation && renderAddReservation(true)}
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		tables: state.tables,
		language: state.language.dictionary,
		reservations: state.reservations,
		topbar: state.topbar,
		user: state.user,
	}),
	{ create_alert }
)(Reservations)

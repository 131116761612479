import { Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Input } from '../../components'
import { auth, firestore, storage } from '../../config/firebase'
import { create_alert } from '../../store/actions'
// import ReactCrop from 'react-image-crop'
// import 'react-image-crop/lib/ReactCrop.scss'

function PlaceSetup({
	user,
	create_alert,
	placesToChoose = null,
	language,
	close,
	edit = null,
	update_group,
}) {
	const [imageLogo, setImageLogo] = useState(
		edit === null ? null : edit.list_image
	)
	const [imageTop, setImageTop] = useState(
		edit === null ? null : edit.featured_image
	)
	const [places, setPlaces] = useState(edit === null ? [] : edit.places)
	const [submitting, setSubmitting] = useState(false)

	// Logo
	const [cropLogo, setCropLogo] = useState({
		unit: '%',
		width: 0,
		height: 0,
		x: 0,
		y: 0,
		aspect: 1,
	})
	const [currentImgLogo, setCurrentImgLogo] = useState(null)
	const [displayImageLogo, setDisplayImageLogo] = useState(null)
	const [imageRefLogo, setImageRefLogo] = useState(null)

	// Top
	const [cropTop, setCropTop] = useState({
		unit: '%',
		width: 0,
		height: 0,
		x: 0,
		y: 0,
		aspect: 1.777777777777778,
	})
	const [currentImgTop, setCurrentImgTop] = useState(null)
	const [displayImageTop, setDisplayImageTop] = useState(null)
	const [imageRefTop, setImageRefTop] = useState(null)

	useEffect(() => {
		if (edit !== null && edit.list_image) {
			setCurrentImgLogo(edit.list_image)
			setCropLogo({
				unit: '%',
				width: 0,
				height: 0,
				x: 0,
				y: 0,
				aspect: 1,
			})
		}
		if (edit !== null && edit.featured_image) {
			setCurrentImgTop(edit.featured_image)
			setCropTop({
				unit: '%',
				width: 0,
				height: 0,
				x: 0,
				y: 0,
				aspect: 1.777777777777778,
			})
		}
	}, [])

	useEffect(() => {
		if (imageRefLogo !== null) {
			let size = imageRefLogo.width
			if (imageRefLogo.height < size) size = imageRefLogo.height
			if (imageRefLogo !== null) {
				setCropLogo({
					unit: 'px',
					width: 50,
					height: 50,
					x: 0,
					y: 0,
					aspect: 1,
				})
				makeClientCropLogo({
					unit: 'px',
					width: 50,
					height: 50,
					x: 0,
					y: 0,
				})
			}
		}
	}, [imageRefLogo])

	useEffect(() => {
		if (imageRefTop !== null) {
			let size = imageRefTop.width
			if (imageRefTop.height < size) size = imageRefTop.height
			if (imageRefTop !== null) {
				setCropTop({
					unit: 'px',
					width: 50,
					height: 50,
					x: 0,
					y: 0,
					aspect: 1.777777777777778,
				})
				makeClientCropTop({
					unit: 'px',
					width: 50,
					height: 50,
					x: 0,
					y: 0,
				})
			}
		}
	}, [imageRefTop])

	const makeClientCropLogo = async (crop) => {
		if (imageRefLogo && crop.width && crop.height) {
			const croppedImageUrl = await getCroppedImg(
				imageRefLogo,
				crop,
				'image_logo.jpeg'
			)
			setImageLogo(croppedImageUrl)
		}
	}

	const makeClientCropTop = async (crop) => {
		if (imageRefTop && crop.width && crop.height) {
			const croppedImageUrl = await getCroppedImg(
				imageRefTop,
				crop,
				'image_top.jpeg'
			)
			setImageTop(croppedImageUrl)
		}
	}

	const getCroppedImg = (image, crop) => {
		const canvas = document.createElement('canvas')
		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		canvas.width = crop.width
		canvas.height = crop.height
		const ctx = canvas.getContext('2d')

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		)

		return new Promise((resolve) => {
			let str = canvas.toDataURL()
			resolve(str)
		})
	}

	const onSelectFileLogo = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				setImageLogo(reader.result)
				setDisplayImageLogo(reader.result)
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}

	const onSelectFileTop = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				setImageTop(reader.result)
				setDisplayImageTop(reader.result)
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}

	const onSubmit = async (values) => {
		if (edit === null) submitCreation(values)
		else submitEdit(values)
	}

	const submitCreation = async (values) => {
		try {
			setSubmitting(true)
			if (places.length < 2) {
				create_alert('Selecciona por lo menos dos lugares', 'warning')
				setSubmitting(false)

				return
			}
			let group_id = values.name
			group_id = group_id.replace(/ /g, '_')
			group_id = group_id.toLowerCase()
			group_id =
				group_id +
				'_' +
				Math.floor(Math.random() * 1000000) +
				'_' +
				Math.floor(new Date().getTime() / 1000)

			const storageRef = storage.ref()

			let listUrl = null
			let featUrl = null

			// Upload list image
			if (`${imageLogo}`.startsWith('data')) {
				const listImageRef = storageRef.child(`/list_images/${group_id}`)
				await listImageRef.putString(imageLogo, 'data_url')
				listUrl = await listImageRef.getDownloadURL()
			}

			// Upload feat image
			if (`${imageTop}`.startsWith('data')) {
				const featImageRef = storageRef.child(
					`/featured_images/${group_id}`
				)
				await featImageRef.putString(imageTop, 'data_url')
				featUrl = await featImageRef.getDownloadURL()
			}

			let newImages = {}
			if (listUrl !== null) newImages.list_image = listUrl
			if (featUrl !== null) newImages.featured_image = featUrl

			await firestore.runTransaction(async (transaction) => {
				let areas = []
				for (const place of places) {
					areas.push(placesToChoose.find((p) => p.id === place).area)
					await transaction.update(firestore.doc(`/places/${place}`), {
						is_grouped: true,
						groups: [
							{
								name: values.name,
								id: group_id,
								owner: true,
								photo: listUrl,
								category: values.category,
								showPlace: values.showPlace,
							},
						],
					})
					await transaction.update(
						firestore.doc(`/places_list/${place}`),
						{
							is_grouped: true,
							groups: [
								{
									name: values.name,
									id: group_id,
									owner: true,
									photo: listUrl,
									category: values.category,
									showPlace: values.showPlace,
								},
							],
						}
					)
				}
				areas = [...new Set(areas)]
				await transaction.set(firestore.doc(`/groups/${group_id}`), {
					name: values.name,
					id: group_id,
					...newImages,
					places,
					owner: auth.currentUser.uid,
					category: values.category,
					popularity: 10,
					areas,
					showPlace: values.showPlace,
				})
			})
			setSubmitting(false)
			close()
		} catch (e) {
			setSubmitting(false)
		}
	}

	const submitEdit = async (values) => {
		try {
			setSubmitting(true)
			if (places.length < 2) {
				create_alert('Selecciona por lo menos dos lugares', 'warning')
				setSubmitting(false)

				return
			}

			const storageRef = storage.ref()

			let listUrl = null
			let featUrl = null

			// Upload list image
			if (`${imageLogo}`.startsWith('data')) {
				const listImageRef = storageRef.child(`/list_images/${edit.id}`)
				await listImageRef.putString(imageLogo, 'data_url')
				listUrl = await listImageRef.getDownloadURL()
			}

			// Upload feat image
			if (`${imageTop}`.startsWith('data')) {
				const featImageRef = storageRef.child(`/featured_images/${edit.id}`)
				await featImageRef.putString(imageTop, 'data_url')
				featUrl = await featImageRef.getDownloadURL()
			}

			let newImages = {}
			if (listUrl !== null) newImages.list_image = listUrl
			if (featUrl !== null) newImages.featured_image = featUrl

			await firestore.runTransaction(async (transaction) => {
				await transaction.update(firestore.doc(`/groups/${edit.id}`), {
					name: values.name,
					...newImages,
					places,
					owner: auth.currentUser.uid,
					category: values.category,
					showPlace: values.showPlace,
				})

				for (const plc of edit.places) {
					if (places.indexOf(plc) === -1) {
						await transaction.update(firestore.doc(`/places/${plc}`), {
							is_grouped: false,
							groups: [],
						})
						await transaction.update(
							firestore.doc(`/places_list/${plc}`),
							{
								is_grouped: false,
								groups: [],
							}
						)
					}
				}

				for (const place of places) {
					await transaction.update(firestore.doc(`/places/${place}`), {
						is_grouped: true,
						groups: [
							{
								name: values.name,
								id: edit.id,
								owner: true,
								photo: listUrl,
								category: values.category,
								showPlace: values.showPlace,
							},
						],
					})
					await transaction.update(
						firestore.doc(`/places_list/${place}`),
						{
							is_grouped: true,
							groups: [
								{
									name: values.name,
									id: edit.id,
									owner: true,
									photo: listUrl,
									category: values.category,
									showPlace: values.showPlace,
								},
							],
						}
					)
				}
			})

			setSubmitting(false)
			close()
		} catch (e) {
			setSubmitting(false)
		}
	}

	const validate = (values) => {
		if (values.name.length < 4) return { name: 'Nombre muy corto' }
		if (values.category === null)
			return { category: 'Selecciona una categoría' }
	}

	const getPlaceOptions = () => {
		let vars = [
			{
				value: 'restaurant',
				label: language.restaurant,
			},
		]

		if (user.type !== 'admin_free') {
			vars = [
				{ value: '', label: language.select_type },
				...vars,
				{
					value: 'hotel',
					label: language.hotel,
				},
				{
					value: 'disco',
					label: language.discos_and_pubs,
				},
				{
					value: 'beauty',
					label: 'Belleza',
				},
			]
		}
		return vars
	}

	const onImageLoadedLogo = (image) => setImageRefLogo(image)
	const onImageLoadedTop = (image) => setImageRefTop(image)

	return (
		<div className="login-ui login-ui-lg">
			<div>
				<Formik
					initialValues={{
						name: edit === null ? '' : edit.name,
						category: edit === null ? null : edit.category || null,
						showPlace: edit === null ? false : edit.showPlace || false,
					}}
					validateOnBlur={false}
					validateOnChange={false}
					onSubmit={onSubmit}
					validate={validate}
				>
					{({ errors, touched, setFieldValue, values }) => (
						<Form className="form-ui">
							<h1>{edit === null ? 'Crea un grupo' : `Editar grupo`}</h1>
							<Input
								placeholder=""
								name="name"
								errors={errors}
								touched={touched}
								label="Nombre"
								submitting={submitting}
							/>
							<div className="form-group upper-8">
								<div className="form-row">
									<div className="col-3">
										<label>Categoría:</label>
									</div>
									<div className="col-9">
										<Select
											className="react-select"
											classNamePrefix="react-select"
											options={getPlaceOptions()}
											onChange={(e) => {
												setFieldValue('category', e.value)
											}}
											defaultValue={
												values.category === null
													? {
															value: '',
															label: language.select_type,
													  }
													: {
															value: values.category,
															label: language[values.category],
													  }
											}
										/>
									</div>
								</div>
								{errors.category && touched.category ? (
									<small>
										<span>{errors.category}</span>
									</small>
								) : null}
							</div>
							<div className="form-group form-group-check">
								<div className="form-row">
									<div className="col-12">
										<Field
											type="checkbox"
											name="showPlace"
											id="showPlace"
											checked={values.showPlace}
										/>
										<label htmlFor="showPlace">
											<b></b> <span>Mostrar mi grupo en la App</span>
										</label>
									</div>
								</div>
							</div>
							<div className="alert alert-blue">
								Recomendamos una imagen de 512px ancho y 512px alto para
								el Logo y una imagen de 640px ancho y 360px alto para la
								portada.
							</div>

							{displayImageLogo === null && currentImgLogo !== null && (
								<div className="img-container img-logo">
									<div>
										<img src={currentImgLogo} />
									</div>
								</div>
							)}
							{/* {displayImageLogo !== null && (
								<ReactCrop
									onImageLoaded={onImageLoadedLogo}
									src={displayImageLogo}
									crop={cropLogo}
									onChange={(crop) => setCropLogo(crop)}
									onComplete={makeClientCropLogo}
								/>
							)} */}

							<div className="adder adder-single">
								<label
									htmlFor="listImageInput"
									className="button button-light"
								>
									Seleccionar logo
								</label>
								<input
									type="file"
									onChange={onSelectFileLogo}
									id="listImageInput"
								/>
							</div>

							{/* {imageTop !== null && (
								<div className="img-container img-cover">
									<div>
										<img src={imageTop} />
									</div>
								</div>
							)} */}

							{displayImageTop === null && currentImgTop !== null && (
								<div className="img-container img-logo">
									<div>
										<img src={currentImgTop} />
									</div>
								</div>
							)}
							{/* {displayImageTop !== null && (
								<ReactCrop
									onImageLoaded={onImageLoadedTop}
									src={displayImageTop}
									crop={cropTop}
									onChange={(crop) => setCropTop(crop)}
									onComplete={makeClientCropTop}
								/>
							)} */}

							<div className="adder adder-single">
								<label
									htmlFor="topImageInput"
									className="button button-light"
								>
									Seleccionar portada
								</label>
								<input
									type="file"
									onChange={onSelectFileTop}
									id="topImageInput"
								/>
							</div>

							<ul className="product-import-list">
								{placesToChoose !== null &&
									placesToChoose.map((place) => {
										let pr_cats = (
											<em>
												<i className="mi">chevron_right</i>{' '}
												<b>{language[place.subcategory]}</b>
											</em>
										)
										return (
											<li key={place.id} className="product-import">
												<div key={place.id}>
													<div className="input-check">
														<input
															type="checkbox"
															name={place.id}
															id={place.id}
															onChange={(e) => {
																if (e.target.checked) {
																	setPlaces([
																		...places,
																		place.id,
																	])
																} else {
																	setPlaces(
																		places.filter(
																			(f) => f !== place.id
																		)
																	)
																}
															}}
															checked={
																places.indexOf(place.id) !== -1
															}
														/>
														<label htmlFor={place.id}>
															Toggle
														</label>
													</div>
													<label htmlFor={place.id}>
														<span
															className="img"
															style={{
																backgroundImage: `url(${place.list_image})`,
															}}
														></span>

														<span>{place.name}</span>
														{place.category !== '' && pr_cats}
													</label>
												</div>
											</li>
										)
									})}
							</ul>

							<footer>
								<button
									disabled={submitting}
									type="submit"
									className="button button-primary"
								>
									{submitting && (
										<div role="status">
											<span>Cargando ...</span>
										</div>
									)}
									{!submitting && 'Continuar'}
								</button>
								<button className="button button-link" onClick={close}>
									Atrás
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	)
}

export default connect(
	(state) => ({
		user: state.user,
		language: state.language.dictionary,
	}),
	{
		create_alert,
	}
)(PlaceSetup)

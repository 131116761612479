export default {
	Order: 'Pedir',
	X_has_been_deleted: 'X ha sido eliminado',
	_continue: 'Pasar',
	accept_payments: 'Aceptar pagos',
	accept_payments_description:
		'Conéctese a una cuenta Stripe para recibir pagos desde aplicación Vywap',
	active_features: 'Activar funcionalidades',
	add: 'Añadir',
	add_additions_group: 'Agregar Opcionales',
	add_floor: 'Agregar zona',
	add_image: 'Añadir imagen',
	add_people: 'Añadir personas',
	reservations_covid: 'Reservas Covid19',
	advanced_reservations: 'Reserva avanzada',
	add_products: 'Agregar productos',
	add_reservation: 'Agregar reserva',
	add_table: 'Agregar mesa',
	hotel: 'Hotel',
	add_terrace: 'Añadir terraza',
	add_ticket: 'Agregar entrada',
	add_to_cart: 'Añadir al carrito',
	add_to_table: 'Agregar a la mesa',
	add_to_table_cart: 'Añadir al carrito de la mesa',
	added_by: 'Añadido por',
	added_to_table: 'Agregado a la mesa',
	additions: 'Adiciones',
	admin_credentials: 'Credenciales de administrador',
	all: 'Todos',
	allergens: 'Alérgenos',
	allowed_events: 'Eventos permitidos',
	an_error_ocurred: 'Ocurrió un error',
	app: 'App',
	availability: 'Disponibilidad',
	basic: 'Básico',
	birthdate: 'Fecha de nacimiento',
	cancel: 'Cancelar',
	cant_be_empty: 'No puede estar vacio',
	cant_be_less_than: 'No puede ser menos de',
	card: 'Tarjeta',
	card_number: 'Número de tarjeta',
	cart_empty: 'Tu carrito esta vacío',
	cash: 'Efectivo',
	category: 'Categoría',
	celery: 'Apio',
	change: 'Cambio',
	charge: 'Cobrar',
	check_availability: 'Consultar disponibilidad',
	checkout: 'Pagar',
	closed: 'Cerrado',
	code: 'Código',
	collaborators: 'Colaboradores',
	complete_order: 'Completar pedido',
	completed: 'Completada',
	connect_account: 'Conecta tu cuenta',
	continue: 'Continuar',
	continue_with_google: 'Continuar con Google',
	continuous_service: 'Servicio continuo',
	cover_image: 'Imagen de portada',
	create: 'Crear',
	'create_a_group_of_additions_like_sauces,_drinks_or_complements':
		'Cree un grupo de adiciones como salsas, bebidas o complementos',
	create_your_place: 'Crea tu lugar',
	credit_card_not_recognized: 'Tarjeta de crédito no reconocida',
	crustaceans: 'Crustáceos',
	date: 'Fecha',
	date_and_time: 'Fecha y hora',
	delivered: 'entregado',
	description: 'Descripción',
	dessert: 'Postre',
	disco: 'Discoteca',
	discos_and_pubs: 'Discos y pubs',
	discount: 'Descuento',
	dismiss: 'Ocultar',
	drink: 'Bebida',
	duration: 'Duración',
	earnings: 'Ganancias',
	edit: 'Editar',
	edit_employee: 'Editar empleado',
	edit_event: 'Editar evento',
	edit_floors: 'Editar zonas',
	edit_reservation: 'Editar reserva',
	eggs: 'Huevos',
	email: 'Correo Electrónico',
	employees: 'Empleados',
	end_date: 'Fecha final',
	enter: 'Entrar',
	enter_a_valid_value: 'Introduce un valor valido',
	invalid_chars: 'Algunos símbolos no permitidos',
	enter_as_collaborator: 'Entrar como colaborador',
	enter_the_employee_password: 'Contraseña de empleado',
	error: 'Error',
	events: 'Eventos',
	exit: 'Salir',
	expiration_date: 'Fecha de caducidad',
	favorites: 'Favoritos',
	finish: 'Termina',
	fish: 'Pescado',
	floor: 'Zona',
	free: 'Libre',
	free_: 'Gratis',
	friday: 'viernes',
	full_name: 'Nombre completo',
	general: 'General',
	get_lineup_qr: 'Obtener QR de cola',
	get_tickets: 'Comprar entradas',
	group: 'Opcional',
	hour: 'Hora',
	images_uploaded_successfully: 'Imágenes cargadas con éxito',
	invalid_field: 'Campo inválido',
	item: 'Articulo',
	item_in_your_cart: 'Artículo en tu carrito',
	item_remaining: 'Artículo restante',
	items: 'artículos',
	items_in_your_cart: 'Artículos en tu carrito',
	items_remaining: 'Artículos restantes',
	its_not_enough: 'No es suficiente',
	left: 'Restantes',
	load_more: 'Cargar más',
	loading: 'Cargando',
	lupin: 'Lupino',
	main_admin: 'Administrador principal',
	main_course: 'Plato principal',
	mandatory: 'Obligatorio',
	manually: 'Manualmente',
	max_selection: 'Max selección',
	menu: 'Carta',
	milk: 'Leche',
	min_additions_can_not_be_grater_than_max_additions:
		'Las adiciones mínimas no pueden ser mayores que las adiciones máximas',
	min_selection: 'Selección mínima',
	minimum_age: 'Edad mínima',
	mobile_number: 'Número de teléfono móvil',
	molluscs: 'Moluscos',
	monday: 'lunes',
	month: 'Mes',
	mustard: 'Mostaza',
	name: 'Nombre',
	new_area: 'NUEVA Área',
	new_category: 'Nueva categoría',
	new_collaborator: 'Nuevo colaborador',
	new_employee: 'Nuevo empleado',
	new_event: 'Nuevo evento',
	new_product: 'Nuevo producto',
	new_promotion: 'Nueva promoción',
	new_reservation: 'Nueva reserva',
	new_table: 'Nueva mesa',
	no_collaborators_found: 'No se encontraron colaboradores',
	no_events_found: 'No se encontraron eventos.',
	no_options: 'Sin opciones',
	no_pending_orders: 'No hay pedidos pendientes.',
	no_popular_places_nearby: 'No hay lugares populares cerca',
	no_products_found: 'No se encontraron productos.',
	no_queue: 'No hay cola',
	no_tables: 'No hay mesas',
	none: 'Ninguna',
	nooptions: 'Sin opciones (escribe para crear una)',
	notes: 'Notas',
	nuts: 'Nueces',
	one_table: 'Una mesa',
	open: 'Abierto',
	optional: 'Opcional',
	order: 'Pedido',
	order_from: 'Pedido de',
	order_now: 'Pedir ahora',
	order_received: 'Pedido recibida',
	order_status: 'Estado del pedido',
	orders: 'Pedidos',
	paid: 'Pagado',
	paid_and_added_to_orders: 'Pagado y agregado a pedidos',
	party_of: 'Mesa para',
	password: 'Contraseña',
	passwords_dont_match: 'Las contraseñas no coinciden',
	payment_method: 'Método de pago',
	payment_methods: 'Métodos de pago',
	payment_pending: 'Pago pendiente',
	payments: 'Pagos',
	peanut: 'Maní',
	pending_orders: 'Pedidos pendientes',
	permissions: 'Permisos',
	phone: 'Teléfono',
	pin_length: 'El pin debe tener al menos 4 dígitos de largo',
	place_configuration: 'Configuración',
	place_name: 'Nombre del lugar',
	place_no_products: '¡UPS! Parece que este lugar no tiene ningún producto',
	place_updated: 'Lugar actualizado',
	popular: 'Popular',
	popular_discos_and_pubs: 'Discos y pubs populares',
	popular_events: 'Eventos populares',
	popular_places: 'Lugares populares',
	popular_restaurants: 'Restaurantes populares',
	position: 'Posición',
	premium: 'Premium',
	preparation_time: 'Tiempo de preparación',
	preparing_your_order: 'Preparando su pedido',
	price: 'Precio',
	processing_your_order: 'Procesando su pedido',
	product_created: 'Producto creado',
	product_image: 'Imagen del producto',
	product_removed_successfully: 'Producto eliminado con éxito',
	product_updated: 'Producto actualizado',
	products: 'Productos',
	promotion_code: 'Código de promoción',
	promotion_label_example: 'Descuento especial de octubre',
	pub: 'pub',
	quantity: 'Cantidad',
	queue: 'Turnos',
	received_amount: 'Cantidad recibida',
	register: 'Registrarse',
	reject_account: 'No aceptar pagos',
	reload: 'Recargar',
	remaining: 'Restante',
	remaining_due: 'Cantidad restante',
	remove: 'Eliminar',
	remove_x_from_your_employees: '¿Eliminar X de tus empleados?',
	repassword: 'repite la contraseña',
	report: 'Reportar horas',
	reservation_created: 'Reserva creada',
	reservations: 'Reservas',
	reserved: 'Reservado',
	restaurant: 'Restaurante',
	restaurants: 'Restaurantes',
	resting: 'Descansando',
	revenue: 'Ingresos',
	sales: 'Ventas',
	saturday: 'sábado',
	save: 'Guardar',
	schedule: 'Horario',
	search: 'Buscar',
	search_or_select_a_place_in_the_map:
		'Busca o selecciona un lugar en el mapa',
	seat: 'Asiento',
	seats: 'Asientos',
	security_code: 'Código de seguridad (CVC)',
	select: 'Seleccionar',
	select_a_place: 'Selecciona un lugar',
	select_a_table: 'Selecciona una mesa',
	select_permissions_for_that_category:
		'Seleccionar permisos para esa categoría',
	select_subcategory: 'Seleccionar subcategoría',
	select_type: 'Seleccione tipo',
	sell_tickets: 'Venta de entradas',
	sesame: 'Sésamo',
	set_free: 'Liberar',
	set_one: 'Establecer uno',
	settings: 'Configuraciones',
	show_product_in_app: 'Mostrar producto en la aplicación',
	sign_in_or_sign_up_using_your_mobile_number:
		'Inicie sesión o regístrese con su número de teléfono móvil',
	sign_out: 'Salir',
	sign_out_from: 'Salir de',
	sold: 'Vendido',
	soya: 'Soja',
	split_service: 'Servicio dividido',
	start_date: 'Fecha de inicio',
	starter: 'Entrada',
	starts: 'Empieza',
	stats: 'Estadísticas',
	status: 'Estado',
	subcategory: 'Subcategoría',
	success: 'Enhorabuena',
	sulphur: 'Dióxido de azufre',
	sunday: 'domingo',
	sure_you_want_to_delete: 'Seguro que quieres eliminar',
	table: 'Mesa',
	table_number: 'Número de mesa',
	table_paid: 'Mesa pagada',
	table_x_is_now_free: 'La mesa X esta Libre',
	tables: 'Mesas',
	taxes: 'Impuestos',
	terrace: 'Terraza',
	the_name_of_the_group_of_choices: 'El nombre del grupo de opciones.',
	the_name_of_the_item_to_choose: 'El nombre del artículo a elegir.',
	this_applies_to_all_the_tickets_that_this_collaborator_sells:
		'Esto se aplica a todas las entradas que vende este colaborador',
	thumbnail: 'Miniatura',
	thursday: 'jueves',
	ticket: 'Entrada',
	tickets_reader: 'Lector de entradas',
	tickets_sold: 'Entradas vendidas',
	time: 'Hora',
	time_waiting: 'Tiempo de espera',
	today: 'Hoy',
	total: 'Total',
	total_due: 'Precio total',
	try_again: 'Inténtalo de nuevo',
	tuesday: 'martes',
	type: 'Tipo',
	type_a_category_to_create_it: 'Elija una categoría o escriba una nueva',
	type_or_select_a_category: 'Elija una categoría o escriba una nueva',
	update: 'Actualizar',
	update_credit_card: 'Actualizar tarjeta de crédito',
	user_not_found: 'Usuario no encontrado',
	verification_code: 'Código de verificación',
	verification_code_sent: 'código de verificacion enviado',
	view_menu: 'Ver la carta',
	waiting: 'Esperando',
	waiting_order: 'Esperando pedido',
	waiting_time: 'Tiempo esperando',
	warning: 'Advertencia',
	weak_password: 'contraseña debil',
	wednesday: 'miércoles',
	welcome: 'Bienvenido',
	wheat: 'Cereales que contienen trigo',
	working: 'Trabajando',
	wrong_password: 'Contraseña incorrecta',
	x_has_been_updated: 'x ha sido actualizado',
	x_has_exited_from_work: 'x ha salido del trabajo',
	x_has_started_to_work: 'x ha empezado a funcionar',
	x_is_now_an_employee: 'x ahora es un empleado',
	x_tables: 'x mesas',
	you_have_one_pending_order: 'Tienes un pedido pendiente',
	you_have_x_pending_orders: 'Tienes x pedidos pendientes',
	your_order_in_x: 'Tu pedido en x',
	your_order_is_ready: 'Su pedido está listo',
	your_payment_method_is: 'Tu forma de pago es',
	new_place: 'Nuevo lugar',
	back: 'Atrás',
	custom_notes: 'Notas adicionales:',
	notes: 'Notes',
	switch_navbar: 'Cambiar de usuario',
	import: 'Importar productos',
	enter_account_password: 'Contraseña principal',
	no_place_selected: 'Selecciona un lugar',
	forgot_password: 'Recuperar contraseña',
	plus_like: 'Añadir like',
	recover: 'Recuperar',
	chinese_food: 'China',
	mexican_food: 'Mexicano',
	arroceria_food: 'Arrocería',
	pizza_food: 'Pizzería',
	cocina_de_mercado_food: 'Cocina de Mercado',
	horno_pasteleria_food: 'Horno y Pastelería',
	cafe_panaderia_food: 'Cafetería Panadería',
	italian_food: 'Italiano',
	american_food: 'Americana',
	to_go_food: 'Comida para Llevar',
	tradicional_food: 'Tradicional',
	mediterranea_food: 'Mediterránea',
	tapas_food: 'Tapas',
	pinchos_food: 'Pinchos',
	store: 'Alimentación',
	carniceria: 'Carnicería',
	supermercado: 'Supermercado',
	pescaderia: 'Pescadería',
	prepared_food: 'Comida Preparada',
	fruteria: 'Frutería',
	tienda_de_alimentacion: 'Tienda de Alimentación',
	select_plan: 'Seleccionar Plan',
	selected_plan: 'Plan Seleccionado',
	address: 'Dirección',
	city: 'Ciudad',
	dni: 'DNI o CIF',
	province: 'Provincia',
	postal_code: 'Código Postal',
	hairdressing: 'Peluquería',
	esthetic: 'Estética',
	spa: 'Spa',
	buffet: 'Buffet',
	asiatico: 'Asiático',
	gastronomico: 'Gastronómico',
	bar: 'Bar',
	chiringuito: 'Chiringuito',
}

import { Field } from 'formik'
import React, { useState } from 'react'
import Popover from './Popover'

function Input({
	children,
	label = '',
	errors = {},
	touched = {},
	submitting = false,
	placeholder = '',
	name = '',
	type = 'text',
	custom,
	noformik = false,
	disabled = false,
	append = null,
	prepend = null,
	popover = null,
	cols = null,
	colside = null,
	rowcolleft = 3,
	rowcolright = 9,
	min = 0,
	max = 1000000000,
	showSearch = false,
	onClickSearch = () => {},
	forceInvalid = false,
}) {
	const [focused, setFocused] = useState(false)

	const renderField = (field) => {
		if (typeof custom !== 'undefined' && custom.component === 'select')
			return <div className="select">{field}</div>
		else return field
	}

	return (
		<div
			className={`form-group ${cols ? 'cols-' + cols : ''} ${
				colside ? 'colside-' + colside : ''
			} ${focused ? 'active' : ''} ${disabled ? 'is-disabled' : ''} ${
				errors[name] && touched[name] ? ' is-invalid' : ''
			} ${popover !== null ? 'has-popover' : ''} ${
				forceInvalid ? 'is-invalid' : ''
			}`}
		>
			<div
				className={`form-row ${append ? 'has-append' : ''}  ${
					prepend ? 'has-prepend' : ''
				}`}
			>
				{append && (
					<span className="append">
						<i>{append}</i>
					</span>
				)}
				{prepend && (
					<span className="prepend">
						<i>{prepend}</i>
					</span>
				)}
				{label.length > 0 && (
					<div className={`col-${rowcolleft}`}>
						<label htmlFor={name}>{label} :</label>
					</div>
				)}

				<div className={`col-${rowcolright}`}>
					{noformik === false &&
						renderField(
							<Field
								onFocus={() => setFocused(true)}
								onBlur={() => setFocused(false)}
								{...custom}
								disabled={submitting || disabled}
								type={type}
								id={name}
								name={name}
								placeholder={placeholder}
								min={min}
								max={max}
							>
								{children}
							</Field>
						)}
					{noformik === true &&
						type !== 'select' &&
						renderField(
							<input
								onFocus={() => setFocused(true)}
								onBlur={() => setFocused(false)}
								{...custom}
								disabled={submitting || disabled}
								type={type}
								id={name}
								name={name}
								placeholder={placeholder}
							/>
						)}
					{noformik === true &&
						type === 'select' &&
						renderField(
							<select
								onFocus={() => setFocused(true)}
								onBlur={() => setFocused(false)}
								{...custom}
								disabled={submitting || disabled}
								id={name}
								name={name}
								placeholder={placeholder}
							>
								{children}
							</select>
						)}
					{popover !== null && (
						<Popover
							content={popover}
							prevent={popover === null}
							offset={10}
							reversed
						>
							<i className="mi">help</i>
						</Popover>
					)}
				</div>

				{showSearch && (
					<button
						onClick={() => {}}
						className="button button-primary"
						type="button"
						style={{
							position: 'absolute',
							right: 5,
							width: 50,
							paddingLeft: 15,
						}}
						onClick={onClickSearch}
					>
						<i className="mi">search</i>
					</button>
				)}
			</div>
			{errors[name] && touched[name] ? (
				<small>
					<span>{errors[name]}</span>
				</small>
			) : null}
		</div>
	)
}

export default Input

import algoliasearch from 'algoliasearch'
import { Field, Form, Formik } from 'formik'
import moment from 'moment-timezone'
import 'moment/locale/es'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import { v4 as uuidv4 } from 'uuid'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import Select from 'react-select'
import { Confirmation, Input, ModalForm, Spinner } from '../../../components'
import { firestore } from '../../../config/firebase'
import { create_alert } from '../../../store/actions'

const searchClientAlg = algoliasearch(
	'MM6KWVFEQD',
	'fce390db586e17fe0242f65ae2dc9c3c'
)

const actionOptions = [
	{
		value: 'deliver',
		label: 'Entrega',
	},
	{
		value: 'change',
		label: 'Cambio',
	},
	{
		value: 'return',
		label: 'Devolución',
	},
]

const defaultFilters = {
	room: '',
	action: null,
	item: null,
	place: null,
	checkout_today: false,
}

moment.locale('es')

const actionToText = (action) => {
	if (action === 'deliver') return 'Entrega'
	if (action === 'return') return 'Devolución'
	if (action === 'change') return 'Cambio'
	return ''
}

const getMaterialName = (materials, material) => {
	return materials.find((f) => f.id === material)?.name || ''
}

function ItemsManagement({ user, topbar, create_alert }) {
	let history = useHistory()
	const selectedPlaceId = history?.location?.pathname?.split('/')[2]

	const [mainData, setMainData] = useState(null)
	const [placeData, setPlaceData] = useState(null)
	const [currentServiceData, setCurrentServiceData] = useState(null)
	const [submitting, setSubmitting] = useState(false)

	const [allPlacesData, setAllPlacesData] = useState({})
	const allPlacesRef = useRef({})
	const [currentServiceID, setCurrentServiceID] = useState(null)

	const [showEditStock, setShowEditStock] = useState(false)
	const [showRecover, setShowRecover] = useState(false)
	const [showFilter, setShowFilter] = useState(false)

	const [activeTab, setActiveTab] = useState(0)
	const [registers, setRegisters] = useState([])
	const [moreChanges, setMoreChanges] = useState([])
	const [noReturnHosted, setNoReturnHosted] = useState([])
	const [noReturnToday, setNoReturnToday] = useState([])
	const [noReturnCheckout, setNoReturnCheckout] = useState([])
	const [noReturnGone, setNoReturnGone] = useState([])
	const [filter, setFilter] = useState(defaultFilters)
	const [renderConfirm, setRenderConfirm] = useState(false)
	const [temporalClientData, setTemporalClientData] = useState(null)

	useEffect(() => {
		let mainListener = firestore
			.doc(`/items_management/${user.id}`)
			.onSnapshot((snap) => {
				snap = snap.data()
				setMainData(snap || null)
			})

		let placeListener = () => {}

		placeListener = firestore
			.doc(`/items_management/${user.id}/places/${selectedPlaceId}`)
			.onSnapshot((snap) => {
				snap = snap.data()
				setPlaceData(snap || null)
			})

		return () => {
			mainListener()
			placeListener()
		}
	}, [])

	useEffect(() => {
		if (currentServiceID !== null && currentServiceID[1] !== null) {
			let listeners = []

			for (const place of currentServiceID[0] || []) {
				listeners.push(
					firestore
						.doc(
							`/items_management/${user.id}/places/${place.id}/services/${currentServiceID[1]}`
						)
						.onSnapshot((snap) => {
							let dat = snap.data()
							let new_val = { ...allPlacesRef.current, [place.id]: dat }
							allPlacesRef.current = new_val
							setAllPlacesData(new_val)
						})
				)
			}

			return () => {
				for (const listener of listeners) listener()
			}
		}
	}, [currentServiceID])

	useEffect(() => {
		let serviceListener = () => {}

		if (placeData?.current_service === null) setCurrentServiceData(null)
		else if (
			currentServiceData === null ||
			currentServiceData?.id !== placeData?.current_service
		) {
			serviceListener = firestore
				.doc(
					`/items_management/${user.id}/places/${selectedPlaceId}/services/${placeData?.current_service}`
				)
				.onSnapshot((snap) => {
					snap = snap.data()
					setCurrentServiceData(snap || null)
				})
		}

		let registersListener = () => {}
		let moreListener = () => {}

		if (placeData?.current_service) {
			registersListener = firestore
				.collection(`/items_management/${user.id}/clients`)
				.where(
					'services',
					'array-contains',
					moment().tz('Europe/Madrid').format('DD/MM/YYYY')
				)
				.onSnapshot((snap) => {
					snap = snap.docs.map((d) => d.data())
					setRegisters(snap || null)
				})

			moreListener = firestore
				.collection(`/items_management/${user.id}/clients`)
				.where(
					'services',
					'array-contains',
					moment().tz('Europe/Madrid').format('DD/MM/YYYY')
				)
				.where('changes_length', '>', 1)
				.orderBy('changes_length', 'desc')
				.onSnapshot((snap) => {
					snap = snap.docs.map((d) => d.data())

					snap = snap.filter((f) => {
						let changes_today = 0
						for (const change of f.change) {
							if (
								change.date_str ===
								moment().tz('Europe/Madrid').format('DD/MM/YYYY')
							)
								changes_today += 1
						}
						return changes_today > 1
					})

					setMoreChanges(snap || null)
				})
		}

		let noReturnHostedListener = firestore
			.collection(`/items_management/${user.id}/clients`)
			.where('checkout', '>', moment().valueOf())
			.orderBy('checkout', 'desc')
			.onSnapshot((snap) => {
				snap = snap.docs.map((d) => d.data())
				snap = snap.map((s) => {
					let returns = 0
					for (const _return of s.return)
						if (
							moment(_return.date)
								.tz('Europe/Madrid')
								.isSame(moment().valueOf(), 'day')
						)
							returns += _return.quantity

					let delivers = 0
					for (const _deliver of s.deliver)
						if (
							moment(_deliver.date)
								.tz('Europe/Madrid')
								.isSame(moment().valueOf(), 'day')
						)
							delivers += _deliver.quantity

					return { ...s, returns_today: returns, delivers_today: delivers }
				})
				let today_snap = snap.filter(
					(f) => f.returns_today < f.delivers_today
				)
				let hosted_snap = snap.filter((f) => f.returns < f.delivers)
				let checkout_snap = hosted_snap.filter((f) => {
					return moment(f.checkout)
						.tz('Europe/Madrid')
						.isSame(moment().valueOf(), 'day')
				})

				checkout_snap = checkout_snap.filter(
					(f) => f.pending_material_regularization_completed === false
				)

				setNoReturnToday(today_snap || [])
				setNoReturnHosted(hosted_snap || [])
				setNoReturnCheckout(checkout_snap || [])
				// setNoReturnCheckout(hosted_snap || [])
			})

		let noReturnGoneListener = firestore
			.collection(`/items_management/${user.id}/clients`)
			.where('owe_items', '>', 0)
			.orderBy('owe_items', 'desc')
			.onSnapshot((snap) => {
				snap = snap.docs.map((d) => d.data())
				snap = snap.filter((f) => f.checkout < moment().valueOf())
				snap = snap.filter(
					(f) => f.pending_material_regularization_completed === false
				)
				setNoReturnGone(snap || null)
			})

		return () => {
			serviceListener()
			registersListener()
			moreListener()
			noReturnHostedListener()
			noReturnGoneListener()
		}
	}, [placeData?.current_service])

	const getNoReturnQuantity = (clients, today = false) => {
		let total = 0

		for (const client of clients) {
			let client_total = 0

			if (today) {
				let today_delivers = 0
				let today_returns = 0

				for (const del of client.deliver) {
					if (
						del.date_str ===
						moment().tz('Europe/Madrid').format('DD/MM/YYYY')
					)
						today_delivers += del.quantity
				}
				for (const ret of client.return) {
					if (
						ret.date_str ===
						moment().tz('Europe/Madrid').format('DD/MM/YYYY')
					)
						today_returns += ret.quantity
				}

				client_total = today_delivers - today_returns
			} else {
				client_total = client.delivers - client.returns
			}

			total += client_total
		}

		return total
	}

	const getRegistersData = () => {
		return filterItems(registers).map((m) => {
			let actions = getActions(m, false, false, false, false, true)

			return [
				`${m.room} - ${m.name}`,
				actions,
				moment(m.checkout).tz('Europe/Madrid').format('DD/MM/YYYY'),
			]
		})
	}

	const getMoreChangesData = () => {
		return filterItems(moreChanges).map((m) => {
			return [
				`${m?.room} > ${m?.name}`,
				getActions(m, true, true, false, false, true),
				`${moment(m.checkout).tz('Europe/Madrid').format('DD/MM/YYYY')}`,
			]
		})
	}

	const getNoReturnTodayData = () => {
		return filterItems(noReturnToday).map((m) => {
			let actions = getActions(m, true, false, false, false, true)
			return [
				`${m?.room} - ${m?.name}`,
				`${actions}`,
				`${moment(m.checkout).tz('Europe/Madrid').format('DD/MM/YYYY')}`,
			]
		})
	}

	const getNoReturnHosted = () => {
		return filterItems(noReturnHosted).map((m) => {
			let actions = getActions(m, false, false, true, false, true)
			return [
				`${m?.room} > ${m?.name}`,
				actions,
				`${moment(m.checkout).tz('Europe/Madrid').format('DD/MM/YYYY')}`,
			]
		})
	}

	const getCheckoutTodayData = () => {
		return filterItems(noReturnCheckout).map((m) => {
			let actions = getActions(m, false, false, false, true, true)

			return [
				`${m?.room} > ${m?.name}`,
				actions,
				`${moment(m.checkout).tz('Europe/Madrid').format('DD/MM/YYYY')}`,
			]
		})
	}

	const getPendingCheckoutData = () => {
		return filterItems(noReturnGone).map((m) => {
			let actions = getActions(m, false, false, false, true, true)

			return [
				`${m?.room} > ${m?.name}`,
				actions,
				`${moment(m.checkout).tz('Europe/Madrid').format('DD/MM/YYYY')}`,
			]
		})
	}

	const doReturn = async (
		m = {},
		material = '',
		recovered = false,
		today = false
	) => {
		let quantity = 0

		if (!today) {
			quantity = m.delivers - m.returns
		} else {
			let delivers_today = 0
			let returns_today = 0

			for (const del of m.deliver) {
				if (
					del.date_str ===
					moment().tz('Europe/Madrid').format('DD/MM/YYYY')
				)
					delivers_today += del.quantity
			}
			for (const ret of m.return) {
				if (
					ret.date_str ===
					moment().tz('Europe/Madrid').format('DD/MM/YYYY')
				)
					returns_today += ret.quantity
			}

			quantity = delivers_today - returns_today
		}

		let action_id = uuidv4()

		let placeRef = firestore.doc(
			`/items_management/${user.id}/places/${selectedPlaceId}`
		)
		let currentServiceRef = firestore.doc(
			`/items_management/${user.id}/places/${selectedPlaceId}/services/${placeData.current_service}`
		)

		await firestore.runTransaction(async (trans) => {
			let placeData = await trans.get(placeRef)
			placeData = placeData.data() || {}

			let currentData = await trans.get(currentServiceRef)
			currentData = currentData.data() || {}

			let materials = currentData?.materials || []
			materials = materials.map((m) => {
				if (m.id !== material) return m
				let numbers = m.numbers || {}

				numbers.laundry = parseInt(
					parseInt(numbers.laundry || 0) + parseInt(quantity)
				)

				return {
					...m,
					numbers,
				}
			})

			let action = {
				id: action_id,
				date: moment().valueOf(),
				place: selectedPlaceId,
				service: placeData.current_service,
				date_str: `${moment().tz('Europe/Madrid').format('DD/MM/YYYY')}`,
				type: 'main_action',
				value: {
					action: 'return',
					adults: m.adults || 0,
					checkin: m.checkin || 0,
					checkout: m.checkout || 0,
					children: m.children || 0,
					client_id: m.id,
					material,
					name: m.name || '',
					quantity,
					room: m.room,
					recovered,
				},
			}

			await trans.set(
				firestore.doc(`/items_management/${user.id}/actions/${action.id}`),
				action
			)

			await trans.update(currentServiceRef, {
				materials,
			})
		})

		let clientRef = firestore.doc(
			`/items_management/${user.id}/clients/${m.id}`
		)

		await firestore.runTransaction(async (trans) => {
			let clientData = await trans.get(clientRef)
			clientData = clientData.data()

			let changed_data = {}
			changed_data.return = [
				...clientData.return,
				{
					action: 'return',
					adults: m.adults || 0,
					checkin: m.checkin || 0,
					checkout: m.checkout || 0,
					children: m.children || 0,
					client_id: m.id,
					material,
					name: m.name || '',
					quantity,
					room: m.room,
					recovered,

					id: action_id,
					date: moment().valueOf(),
					date_str: `${moment().tz('Europe/Madrid').format('DD/MM/YYYY')}`,
					place: selectedPlaceId,
					service: placeData.current_service,
				},
			]
			changed_data.returns = clientData.returns + quantity
			changed_data.returns_length = parseInt(changed_data?.return?.length)

			changed_data.services = clientData.services
			changed_data.services.push(
				moment().tz('Europe/Madrid').format('DD/MM/YYYY')
			)
			changed_data.services = new Set(changed_data.services)
			changed_data.services = [...changed_data.services]

			let final_data = {
				...clientData,
				...changed_data,
			}

			changed_data.owe_items = final_data.delivers - final_data.returns
			await trans.update(clientRef, changed_data)
		})

		return
	}

	const getPdf = async () => {
		let headers = []
		let values = []

		switch (activeTab) {
			case 0:
				headers = ['HABITACIÓN / CLIENTE', 'RESUMEN', 'CHECKOUT']
				values = getRegistersData()
				break
			case 1:
				headers = [
					'HABITACIÓN / CLIENTE',
					'CANTIDAD DE CAMBIOS',
					'CHECKOUT',
				]
				values = getMoreChangesData()
				break
			case 2:
				headers = ['HABITACIÓN / CLIENTE', 'ENTREGAS HOY', 'CHECKOUT']
				values = getNoReturnTodayData()
				break
			case 3:
				headers = [
					'HABITACIÓN / CLIENTE',
					'RESUMEN DE ENTREGAS',
					'CHECKOUT',
				]
				values = getNoReturnHosted()
				break
			case 4:
				headers = [
					'HABITACIÓN / CLIENTE',
					'RESUMEN DE ENTREGAS',
					'CHECKOUT',
				]
				values = getCheckoutTodayData()
				break
			case 5:
				headers = [
					'HABITACIÓN / CLIENTE',
					'RESUMEN DE ENTREGAS',
					'CHECKOUT',
				]
				values = getPendingCheckoutData()
				break
			default:
				break
		}

		var docDefinition = {
			pageMargins: 20,
			pageSize: {
				width: 1240,
				height: 1748,
			},
			defaultStyle: {
				fontSize: 20,
			},
			content: [
				{
					table: {
						body: [headers, ...values],
						widths: ['30%', '55%', '15%'],
					},
				},
			],
		}
		pdfMake.createPdf(docDefinition).print()
	}

	const startService = async () => {
		setSubmitting(true)

		let _data = {
			id: uuidv4(),
			started: moment().valueOf(),
			ended: null,
		}

		for (const place of mainData.places) {
			let placeRef = firestore.doc(
				`/items_management/${user.id}/places/${place.id}`
			)
			await firestore.runTransaction(async (trans) => {
				let plcDt = await trans.get(placeRef)
				plcDt = plcDt.data()

				let last_service_data = null
				if (plcDt.last_service !== null) {
					last_service_data = await firestore
						.doc(
							`/items_management/${user.id}/places/${place.id}/services/${plcDt.last_service}`
						)
						.get()
					last_service_data = last_service_data.data() || null
				}

				let _materials = (mainData.materials || []).map((m) => {
					let numbers = {
						available: 0,
						delivered: 0,
						laundry: 0,
						no_returned: 0,
					}
					if (last_service_data !== null) {
						let found = last_service_data.materials.find(
							(f) => f.id === m.id
						)
						if (typeof found !== 'undefined') {
							numbers.available = found.numbers.available
						}
					}
					return {
						...m,
						numbers,
					}
				})
				let local_data = {
					..._data,
					materials: _materials,
				}

				await trans.set(
					firestore.doc(
						`/items_management/${user.id}/places/${place.id}/services/${local_data.id}`
					),
					local_data
				)
			})
			await firestore
				.doc(`/items_management/${user.id}/places/${place.id}`)
				.update({
					current_service: _data.id,
				})
		}
		setSubmitting(false)
	}

	const finishService = async () => {
		setSubmitting(true)

		for (const place of mainData.places) {
			await firestore
				.doc(`/items_management/${user.id}/places/${place.id}`)
				.update({
					current_service: null,
					last_service: currentServiceData?.id,
				})

			await firestore
				.doc(
					`/items_management/${user.id}/places/${place.id}/services/${currentServiceData?.id}`
				)
				.update({
					ended: moment().valueOf(),
				})
		}
		setSubmitting(false)
	}

	const getIndicator = (name = '', force_val = false) => {
		let indicator = currentServiceData?.materials || []
		indicator = indicator.map((v) => {
			return (
				<div
					key={v.id}
					style={{
						paddingLeft: 0,
						paddingRight: 0,
					}}
				>
					<b>{v.name}</b>
					<b>{force_val ? force_val : v.numbers[name]}</b>
				</div>
			)
		})
		return indicator
	}

	const renderIndicators = () => {
		return (
			<div>
				<div>
					<div>
						<small>Existencias:</small>
						{getIndicator('available')}
					</div>
				</div>
				<div>
					<div>
						<small>Entregadas:</small>
						{getIndicator('delivered')}
					</div>
				</div>
				<div>
					<div>
						<small>Para lavandería:</small>
						{getIndicator('laundry')}
					</div>
				</div>
				<div>
					<div>
						<small>Sin devolución:</small>
						{getIndicator(
							'no_returned',
							getNoReturnQuantity(noReturnToday, true)
						)}
					</div>
				</div>
			</div>
		)
	}

	const prefillRoom = async (number, setFieldValue, withId = false) => {
		let res = null
		if (withId) {
			res = await firestore
				.doc(`/items_management/${user.id}/clients/${number}`)
				.get()
			res = res.data()
		} else {
			res = await firestore
				.collection(`/items_management/${user.id}/clients`)
				.where('room', '==', `${number}`)
				.where('checkin', '<=', moment().valueOf())
				.orderBy('checkin', 'desc')
				.limit(20)
				.get()
			res = res.docs.map((d) => d.data())
			res = res.filter((f) => f.status !== 'due-in')
			res = res[0]
		}

		if (res.length === 0) {
			create_alert('Cliente no encontrado', 'danger')
			setFieldValue('client_id', null)
			setFieldValue('name', '')
			setFieldValue('checkin', '')
			setFieldValue('checkout', '')
			setFieldValue('adults', 0)
			setFieldValue('children', 0)
		} else {
			setTemporalClientData(res)
			setFieldValue('client_id', res.id)
			setFieldValue('name', res.name)
			setFieldValue('room', res.room)
			setFieldValue('checkin', res.checkin)
			setFieldValue('checkout', res.checkout)
			setFieldValue('adults', parseInt(res.adults || 0))
			setFieldValue('children', parseInt(res.children || 0))
		}
	}

	const renderMainActions = () => {
		return (
			<div>
				<div>
					<Formik
						initialValues={{
							client_id: null,
							room: '',
							name: '',
							client_val: null,
							checkin: null,
							checkout: null,
							action: null,
							quantity: '',
							material: '95020f04-7bea-48d8-8c5a-48741f4fb895',
							adults: 0,
							children: 0,
						}}
						validate={(values) => {
							let errors = {}
							if (
								values.name === '' ||
								values.room === '' ||
								values.client_id === null
							)
								errors.room = 'Selecciona una habitación'
							if (values.material === null)
								errors.material = 'Selecciona un material'
							if (values.quantity <= 0)
								errors.quantity = 'Selecciona una cantidad'
							if (values.action <= 0)
								errors.action = 'Selecciona una acción'
							return errors
						}}
						onSubmit={async (values, { resetForm }) => {
							setSubmitting(true)

							let action_id = uuidv4()

							let placeRef = firestore.doc(
								`/items_management/${user.id}/places/${selectedPlaceId}`
							)

							let currentServiceRef = firestore.doc(
								`/items_management/${user.id}/places/${selectedPlaceId}/services/${placeData.current_service}`
							)

							await firestore.runTransaction(async (trans) => {
								let placeData = await trans.get(placeRef)
								placeData = placeData.data() || {}

								let currentData = await trans.get(currentServiceRef)
								currentData = currentData.data() || {}

								let materials = currentData?.materials || []
								materials = materials.map((m) => {
									if (m.id !== values.material) return m

									let numbers = m.numbers || {}

									if (values.action === 'deliver') {
										numbers.available = parseInt(
											parseInt(numbers.available || 0) -
												parseInt(values.quantity)
										)
										numbers.delivered = parseInt(
											parseInt(numbers.delivered || 0) +
												parseInt(values.quantity)
										)
									} else if (values.action === 'change') {
										numbers.available = parseInt(
											parseInt(numbers.available || 0) -
												parseInt(values.quantity)
										)
										numbers.delivered = parseInt(
											parseInt(numbers.delivered || 0) +
												parseInt(values.quantity)
										)
										numbers.laundry = parseInt(
											parseInt(numbers.laundry || 0) +
												parseInt(values.quantity)
										)
									} else if (values.action === 'return') {
										numbers.laundry = parseInt(
											parseInt(numbers.laundry || 0) +
												parseInt(values.quantity)
										)
									}

									return {
										...m,
										numbers,
									}
								})

								let action = {
									id: action_id,
									date: moment().valueOf(),
									place: selectedPlaceId,
									service: placeData.current_service,
									date_str: `${moment()
										.tz('Europe/Madrid')
										.format('DD/MM/YYYY')}`,
									type: 'main_action',
									value: {
										...values,
									},
								}

								await trans.set(
									firestore.doc(
										`/items_management/${user.id}/actions/${action.id}`
									),
									action
								)

								await trans.update(currentServiceRef, {
									materials,
								})
							})

							let clientRef = firestore.doc(
								`/items_management/${user.id}/clients/${values.client_id}`
							)

							await firestore.runTransaction(async (trans) => {
								let clientData = await trans.get(clientRef)
								clientData = clientData.data()

								let changed_data = {}

								if (values.action === 'deliver') {
									changed_data.deliver = [
										...clientData.deliver,
										{
											...values,
											id: action_id,
											date: moment().valueOf(),
											date_str: `${moment()
												.tz('Europe/Madrid')
												.format('DD/MM/YYYY')}`,
											place: selectedPlaceId,
											service: placeData.current_service,
										},
									]
									changed_data.delivers =
										clientData.delivers + values.quantity
									changed_data.delivers_length = parseInt(
										changed_data?.deliver?.length
									)
								} else if (values.action === 'change') {
									changed_data.change = [
										...clientData.change,
										{
											...values,
											id: action_id,
											date: moment().valueOf(),
											date_str: `${moment()
												.tz('Europe/Madrid')
												.format('DD/MM/YYYY')}`,
											place: selectedPlaceId,
											service: placeData.current_service,
										},
									]
									changed_data.changes =
										clientData.changes + values.quantity
									changed_data.changes_length = parseInt(
										changed_data?.change?.length
									)
								} else if (values.action === 'return') {
									changed_data.return = [
										...clientData.return,
										{
											...values,
											id: action_id,
											date: moment().valueOf(),
											date_str: `${moment()
												.tz('Europe/Madrid')
												.format('DD/MM/YYYY')}`,
											place: selectedPlaceId,
											service: placeData.current_service,
										},
									]
									changed_data.returns =
										clientData.returns + values.quantity
									changed_data.returns_length = parseInt(
										changed_data?.return?.length
									)
								}

								changed_data.services = clientData.services
								changed_data.services.push(
									moment().tz('Europe/Madrid').format('DD/MM/YYYY')
								)
								changed_data.services = new Set(changed_data.services)
								changed_data.services = [...changed_data.services]

								let final_data = {
									...clientData,
									...changed_data,
								}

								changed_data.owe_items =
									final_data.delivers - final_data.returns
								await trans.update(clientRef, changed_data)
							})

							resetForm()
							setSubmitting(false)
							setShowRecover(false)
						}}
					>
						{({ values, errors, touched, setFieldValue, submitForm }) => {
							return (
								<Form
									className="form-ui"
									style={{
										paddingLeft: 0,
										paddingRight: 0,
										paddingTop: 20,
									}}
								>
									{/* <Input
										label="Nº Habitación"
										disabled={submitting}
										name="room"
										errors={errors}
										touched={touched}
										cols={6}
										colside="left"
										type="number"
										min={0}
										onClickSearch={() =>
											prefillRoom(values.room, setFieldValue)
										}
										showSearch
										custom={{
											onKeyPress: (e) => {
												if (e.key === 'Enter') {
													e.preventDefault()
													prefillRoom(values.room, setFieldValue)
												}
											},
										}}
									/> */}
									<div className="form-group upper-10">
										<div className="form-row">
											<div className="col-3">
												<label>Cliente:</label>
											</div>
											<div className="col-9">
												<AsyncSelect
													className="react-select"
													classNamePrefix="react-select"
													placeholder={'Buscar'}
													loadOptions={async (input, callback) => {
														const index =
															searchClientAlg.initIndex(
																'items_management'
															)
														let filter = `checkout > ${moment().valueOf()}`
														const { hits } = await index.search(
															input,
															{
																filters: filter,
																hitsPerPage: 10,
															}
														)
														let res = hits.map((h) => ({
															label: `${h.room} > ${h.name}`,
															value: h.id,
														}))
														return callback(res)
													}}
													value={values.client_val}
													onChange={(e) => {
														setFieldValue('client_val', e)
														prefillRoom(
															e.value,
															setFieldValue,
															true
														)
													}}
												/>
											</div>
										</div>
									</div>
									<Input
										label="CheckIn"
										noformik
										colside="left"
										cols={6}
										// disabled={true}
										custom={{
											value: moment(values.checkin).isValid()
												? moment(values.checkin)
														.tz('Europe/Madrid')
														.format('DD/MM/YYYY')
												: '',
											onChange: (e) => e.preventDefault(),
										}}
									/>
									<Input
										label="CheckOut"
										colside="right"
										cols={6}
										// disabled={true}
										name="checkout"
										custom={{
											value: moment(values.checkout).isValid()
												? moment(values.checkout)
														.tz('Europe/Madrid')
														.format('DD/MM/YYYY')
												: '',
											onChange: (e) => e.preventDefault(),
										}}
										forceInvalid={moment()
											.tz('Europe/Madrid')
											.isSameOrAfter(values.checkout, 'day')}
									/>
									<Input
										label="Personas"
										noformik
										colside="left"
										cols={6}
										// disabled={true}
										custom={{
											value:
												values.client_id !== null
													? `Total ${
															values.children + values.adults
													  } (${values.adults} Adultos, ${
															values.children
													  } Niños)`
													: '',
											onChange: (e) => e.preventDefault(),
										}}
									/>
									<div
										className={`form-group cols-6 colside-right upper-7`}
									>
										<div className="form-row">
											<div className="col-2">
												<label>Acción:</label>
											</div>
											<div className="col-10">
												<Select
													className="react-select"
													classNamePrefix="react-select"
													placeholder="Seleccionar"
													options={actionOptions}
													onChange={(e) => {
														setFieldValue(
															'action',
															e?.value || null
														)
													}}
													isClearable
													value={
														actionOptions.find(
															(f) => f.value === values.action
														) || null
													}
												/>
											</div>
										</div>
									</div>
									<div
										className={`form-group cols-6 colside-left upper-6`}
									>
										<div className="form-row">
											<div className="col-2">
												<label>Material:</label>
											</div>
											<div className="col-10">
												<Select
													className="react-select"
													classNamePrefix="react-select"
													placeholder="Seleccionar"
													options={mainData.materials.map((m) => ({
														value: m.id,
														label: m.name,
													}))}
													isClearable
													onChange={(e) => {
														setFieldValue(
															'material',
															e?.value || null
														)
													}}
													value={
														typeof mainData.materials.find(
															(f) => f.id === values.material
														) === 'undefined'
															? null
															: {
																	label: mainData.materials.find(
																		(f) =>
																			f.id ===
																			values.material
																	).name,
																	value: values.material,
															  }
													}
													disabled={submitting}
												/>
											</div>
										</div>
									</div>
									<Input
										label="Cantidad"
										disabled={submitting}
										name="quantity"
										errors={errors}
										touched={touched}
										cols={6}
										colside="right"
										type="number"
										placeholder="Seleccionar"
									/>
									<footer style={{ marginTop: 10 }}>
										<button
											type="button"
											disabled={
												submitting || Object.keys(errors).length > 0
											}
											className="button button-primary"
											onClick={() => {
												let number_of_people =
													values.adults + values.children

												let delivered_items_material = 0
												let returned_items_material = 0
												let has_delivers_today = false

												for (const _return of temporalClientData.return)
													if (_return.material === values.material)
														returned_items_material +=
															_return.quantity

												for (const _deliver of temporalClientData.deliver)
													if (
														_deliver.material === values.material
													) {
														if (
															moment(_deliver.date)
																.tz('Europe/Madrid')
																.isSame(
																	moment()
																		.tz('Europe/Madrid')
																		.valueOf(),
																	'day'
																)
														) {
															has_delivers_today = true
														}
														delivered_items_material +=
															_deliver.quantity
													}

												let current_items =
													delivered_items_material -
													returned_items_material

												if (
													values.action === 'deliver' &&
													current_items + values.quantity >
														number_of_people
												) {
													return setRenderConfirm([
														'extra_items',
														() => {
															submitForm()
														},
														<>
															<p>
																La habitación tiene{' '}
																{current_items} items
															</p>
															<br />
															<p>
																La habitación tiene{' '}
																{number_of_people} personas
															</p>
															<br />
															<p>
																La habitación quedaría con{' '}
																{current_items +
																	values.quantity}{' '}
																items
															</p>
															<br />
															<p>
																Ya se han hecho{' '}
																{temporalClientData?.deliver
																	?.length || 0}{' '}
																entregas
															</p>
															<br />
														</>,
													])
												} else if (
													has_delivers_today &&
													values.action === 'deliver'
												) {
													return setRenderConfirm([
														'extra_deliver',
														() => {
															submitForm()
														},
														<>
															<p>
																La habitación tiene{' '}
																{current_items} items
															</p>
															<br />
															<p>
																La habitación tiene{' '}
																{number_of_people} personas
															</p>
															<br />
															<p>
																La habitación quedaría con{' '}
																{current_items +
																	values.quantity}{' '}
																items
															</p>
															<br />
															<p>
																Ya se han hecho{' '}
																{temporalClientData?.deliver
																	?.length || 0}{' '}
																entregas
															</p>
															<br />
														</>,
													])
												} else if (
													values.action === 'return' &&
													values.quantity > current_items
												) {
													alert(
														`El cliente solo tiene ${current_items} item(s), no puede devolver ${values.quantity}`
													)
												} else if (
													values.action === 'change' &&
													values.quantity > current_items
												) {
													alert(
														`El cliente solo tiene ${current_items} item(s), no puede cambiar ${values.quantity}`
													)
												} else {
													submitForm()
												}
											}}
										>
											{submitting && (
												<div
													className="spinner-border spinner-border-sm"
													role="status"
												>
													<span className="sr-only">
														Cargando...
													</span>
												</div>
											)}
											{!submitting && (
												<>
													<span>Confirmar</span>
												</>
											)}
										</button>
									</footer>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		)
	}

	const renderEditStock = () => {
		return (
			<ModalForm toggle={() => setShowEditStock(false)} size="sm">
				<Formik
					initialValues={{
						item: null,
						status: 'available',
						quantity: 0,
					}}
					validateOnBlur={false}
					validateOnChange={false}
					validate={(values) => {
						let errors = {}
						if (values.item === null)
							errors.item = 'Selecciona un material'
						if (values.status === null)
							errors.status = 'Selecciona un estado'
						if (values.quantity === 0)
							errors.quantity = 'Selecciona una cantidad'
						return errors
					}}
					onSubmit={async (values) => {
						setSubmitting(true)
						let currentServiceRef = firestore.doc(
							`/items_management/${user.id}/places/${selectedPlaceId}/services/${placeData.current_service}`
						)

						let action_id = uuidv4()

						await firestore.runTransaction(async (trans) => {
							let currentData = await trans.get(currentServiceRef)
							currentData = currentData.data() || {}

							let materials = currentData?.materials || []
							materials = materials.map((m) => {
								if (m.id !== values.item) return m
								return {
									...m,
									numbers: {
										...(m.numbers || {}),
										[values.status]:
											(m.numbers[values.status] || 0) +
											values.quantity,
									},
								}
							})

							let action = {
								id: action_id,
								date: moment().valueOf(),
								place: selectedPlaceId,
								service: placeData.current_service,
								type: 'stock',
								date_str: `${moment()
									.tz('Europe/Madrid')
									.format('DD/MM/YYYY')}`,
								value: {
									...values,
								},
							}

							await trans.set(
								firestore.doc(
									`/items_management/${user.id}/actions/${action.id}`
								),
								action
							)

							await trans.update(currentServiceRef, {
								materials,
							})
						})

						setSubmitting(false)
						setShowEditStock(false)
					}}
				>
					{({ errors, touched, setFieldValue }) => (
						<Form className="form-ui">
							<h1>Reponer Stock</h1>
							<div
								style={{
									padding: 15,
								}}
							>
								<div
									className={`form-group upper-8 ${
										errors.item && 'is-invalid'
									}`}
								>
									<div className="form-row">
										<div className="col-3">
											<label>Material:</label>
										</div>
										<div className="col-9">
											<Select
												className="react-select"
												classNamePrefix="react-select"
												placeholder="Seleccionar"
												options={mainData.materials.map((m) => ({
													value: m.id,
													label: m.name,
												}))}
												isClearable
												onChange={(e) => {
													setFieldValue('item', e?.value || null)
												}}
											/>
										</div>
									</div>
									{errors.item && touched.item ? (
										<small>
											<span>{errors.item}</span>
										</small>
									) : null}
								</div>
								<Input
									label="Cantidad"
									disabled={submitting}
									name="quantity"
									errors={errors}
									touched={touched}
									type="number"
									min={-10000}
									max={10000}
								/>
							</div>
							<footer style={{ marginTop: 10 }}>
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">Cargando...</span>
										</div>
									)}
									{!submitting && (
										<>
											<span>Modificar</span>
										</>
									)}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	const renderRecover = () => {
		return (
			<ModalForm toggle={() => setShowRecover(false)} size="sm">
				<Formik
					initialValues={{
						item: null,
						quantity: 0,
					}}
					validateOnBlur={false}
					validateOnChange={false}
					validate={(values) => {
						let errors = {}
						if (values.item === null)
							errors.item = 'Selecciona un material'
						if (values.quantity === 0)
							errors.quantity = 'Selecciona una cantidad'
						return errors
					}}
					onSubmit={async (values) => {
						setSubmitting(true)
						let currentServiceRef = firestore.doc(
							`/items_management/${user.id}/places/${selectedPlaceId}/services/${placeData.current_service}`
						)
						let action_id = uuidv4()
						await firestore.runTransaction(async (trans) => {
							let currentData = await trans.get(currentServiceRef)
							currentData = currentData.data() || {}

							let materials = currentData?.materials || []
							materials = materials.map((m) => {
								if (m.id !== values.item) return m
								return {
									...m,
									numbers: {
										...(m.numbers || {}),
										no_returned:
											(m.numbers?.no_returned || 0) +
											values.quantity,
										laundry:
											(m.numbers?.laundry || 0) + values.quantity,
									},
								}
							})

							let action = {
								id: action_id,
								date: moment().valueOf(),
								type: 'recover',
								place: selectedPlaceId,
								service: placeData.current_service,
								date_str: `${moment()
									.tz('Europe/Madrid')
									.format('DD/MM/YYYY')}`,
								value: {
									...values,
								},
							}

							await trans.set(
								firestore.doc(
									`/items_management/${user.id}/actions/${action.id}`
								),
								action
							)

							await trans.update(currentServiceRef, {
								materials,
							})
						})

						setSubmitting(false)
						setShowRecover(false)
					}}
				>
					{({ errors, touched, setFieldValue }) => (
						<Form className="form-ui">
							<h1>Recuperar</h1>
							<div
								style={{
									padding: 15,
								}}
							>
								<div
									className={`form-group upper-8 ${
										errors.item && 'is-invalid'
									}`}
								>
									<div className="form-row">
										<div className="col-3">
											<label>Material:</label>
										</div>
										<div className="col-9">
											<Select
												className="react-select"
												classNamePrefix="react-select"
												placeholder="Seleccionar"
												options={mainData.materials.map((m) => ({
													value: m.id,
													label: m.name,
												}))}
												isClearable
												onChange={(e) => {
													setFieldValue('item', e?.value || null)
												}}
											/>
										</div>
									</div>
									{errors.item && touched.item ? (
										<small>
											<span>{errors.item}</span>
										</small>
									) : null}
								</div>
								<Input
									label="Cantidad"
									disabled={submitting}
									name="quantity"
									errors={errors}
									touched={touched}
									type="number"
									min={-10000}
									max={10000}
								/>
							</div>
							<footer style={{ marginTop: 10 }}>
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">Cargando...</span>
										</div>
									)}
									{!submitting && (
										<>
											<span>Recuperar</span>
										</>
									)}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	const renderRegisters = () => {
		return (
			<div className="table-ui">
				<div>
					<table>
						<thead>
							<tr>
								<th>Habitación / Cliente</th>
								<th>Resumen</th>
								<th>Checkout</th>
							</tr>
						</thead>
						<tbody>
							{filterItems(registers).map((m) => {
								let actions = getActions(
									m,
									false,
									false,
									false,
									false,
									false,
									true
								)

								return (
									<tr key={m.id}>
										<td>
											{m?.room}
											{' - '}
											{m?.name}
										</td>
										<td>{actions}</td>
										<td>
											<strong
												style={{
													color: moment()
														.tz('Europe/Madrid')
														.isSameOrAfter(m.checkout, 'days')
														? 'red'
														: 'black',
												}}
											>
												{moment(m.checkout)
													.tz('Europe/Madrid')
													.format('DD/MM/YYYY')}{' '}
											</strong>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
		)
	}

	const reverseAction = async (j, l) => {
		setSubmitting(true)

		let placeRef = firestore.doc(
			`/items_management/${user.id}/places/${j.place}`
		)
		let currentServiceRef = firestore.doc(
			`/items_management/${user.id}/places/${j.place}/services/${j.service}`
		)

		await firestore.runTransaction(async (trans) => {
			let placeData = await trans.get(placeRef)
			placeData = placeData.data() || {}

			let currentData = await trans.get(currentServiceRef)
			currentData = currentData.data() || {}

			let materials = currentData?.materials || []
			materials = materials.map((m) => {
				if (m.id !== j.material) return m

				let numbers = m.numbers || {}

				if (j.action === 'deliver') {
					numbers.available = parseInt(
						parseInt(numbers.available || 0) + parseInt(j.quantity)
					)
					numbers.delivered = parseInt(
						parseInt(numbers.delivered || 0) - parseInt(j.quantity)
					)
				} else if (j.action === 'change') {
					numbers.available = parseInt(
						parseInt(numbers.available || 0) + parseInt(j.quantity)
					)
					numbers.delivered = parseInt(
						parseInt(numbers.delivered || 0) - parseInt(j.quantity)
					)
					numbers.laundry = parseInt(
						parseInt(numbers.laundry || 0) - parseInt(j.quantity)
					)
				} else if (j.action === 'return') {
					numbers.laundry = parseInt(
						parseInt(numbers.laundry || 0) - parseInt(j.quantity)
					)
				}

				return {
					...m,
					numbers,
				}
			})

			await trans.set(
				firestore.doc(
					`/items_management/${user.id}/deleted_actions/${j.id}`
				),
				j
			)

			await trans.delete(
				firestore.doc(`/items_management/${user.id}/actions/${j.id}`)
			)

			await trans.update(currentServiceRef, {
				materials,
			})
		})

		let clientRef = firestore.doc(
			`/items_management/${user.id}/clients/${j.client_id}`
		)

		await firestore.runTransaction(async (trans) => {
			let clientData = await trans.get(clientRef)
			clientData = clientData.data()

			let changed_data = {}

			if (j.action === 'deliver') {
				changed_data.deliver = clientData.deliver.filter(
					(f) => f.id !== j.id
				)
				changed_data.delivers = clientData.delivers - j.quantity
				changed_data.delivers_length = parseInt(
					changed_data?.deliver?.length
				)
			} else if (j.action === 'change') {
				changed_data.change = clientData.change.filter((f) => f.id !== j.id)
				changed_data.changes = clientData.changes - j.quantity
				changed_data.changes_length = parseInt(changed_data?.change?.length)
			} else if (j.action === 'return') {
				changed_data.return = clientData.return.filter((f) => f.id !== j.id)
				changed_data.returns = clientData.returns - j.quantity
				changed_data.returns_length = parseInt(changed_data?.return?.length)
			}

			let final_data = {
				...clientData,
				...changed_data,
			}

			let total_movements =
				final_data.delivers + final_data.returns + final_data.changes
			if (total_movements === 0) {
				changed_data.services = clientData.services.filter(
					(f) => f !== moment().tz('Europe/Madrid').format('DD/MM/YYYY')
				)
			}

			changed_data.owe_items = final_data.delivers - final_data.returns
			await trans.update(clientRef, changed_data)
		})

		setSubmitting(false)
	}

	const getActions = (
		m,
		filterToday = false,
		onlyChanges = false,
		onlyDelivers = false,
		checkout = false,
		plain = false,
		showReverse = false
	) => {
		let actions = [...m.deliver, ...m.return, ...m.change]
		actions = actions.sort((a, b) => a.date - b.date)
		if (filterToday) {
			actions = actions.filter((a) => {
				return (
					moment().tz('Europe/Madrid').format('DD/MM/YYYY') ===
					moment(a.date).tz('Europe/Madrid').format('DD/MM/YYYY')
				)
			})
		}

		if (onlyChanges) {
			actions = actions.filter((a) => a.action === 'change')
		} else if (onlyDelivers) {
			actions = actions.filter((a) => a.action === 'deliver')
		}

		let people = m.adults + m.children

		let totalMaterialChanged = {}
		let totalMaterialDelivered = {}

		let extraDeliversQuantity = 0

		actions = actions.map((m) => {
			let dt = moment(m.date).tz('Europe/Madrid').format('DD/MM/YYYY')
			if (m.action === 'change') {
				let _tmc = totalMaterialChanged[dt] || 0
				_tmc += m.quantity
				totalMaterialChanged[dt] = _tmc

				let extraChanges = ''
				let correct_change = false

				let peopleLeft = people - _tmc
				if (peopleLeft < 0) {
					extraChanges = Math.abs(peopleLeft)
					if (extraChanges < m.quantity) {
						extraChanges = ` (${extraChanges})`
					} else {
						extraChanges = ''
					}
				}

				if (totalMaterialChanged[dt] <= people) {
					correct_change = true
				}

				return {
					...m,
					correct_change,
					extraChanges,
				}
			} else if (m.action === 'deliver') {
				let _tmc = totalMaterialDelivered[dt] || 0
				_tmc += m.quantity
				totalMaterialDelivered[dt] = _tmc

				let extraDelivers = ''
				let totalExtraDelivers = false
				let correct_deliver = false

				let peopleLeft = people - _tmc
				if (peopleLeft < 0) {
					extraDelivers = Math.abs(peopleLeft)
					if (extraDelivers < m.quantity) {
						totalExtraDelivers = extraDelivers
						extraDelivers = ` (${extraDelivers})`
					} else {
						extraDelivers = ''
					}
				}

				if (totalMaterialDelivered[dt] <= people) {
					correct_deliver = true
				} else {
					if (totalExtraDelivers === false) {
						extraDeliversQuantity += m.quantity
					} else {
						extraDeliversQuantity += totalExtraDelivers
					}
				}

				return {
					...m,
					correct_deliver,
					extraDelivers,
				}
			}
			return m
		})

		let totalChanges = 0
		let extraMaterialUsed = 0

		for (const act of actions) {
			if (act.action === 'change') {
				if (
					filterToday === false ||
					act.date_str ===
						moment().tz('Europe/Madrid').format('DD/MM/YYYY')
				)
					totalChanges += 1
			}
		}

		for (const u of Object.keys(totalMaterialChanged)) {
			let q = totalMaterialChanged[u]
			extraMaterialUsed += q
		}

		extraMaterialUsed -= people
		if (extraMaterialUsed < 0) extraMaterialUsed = 0

		extraMaterialUsed += extraDeliversQuantity

		actions = actions.map((k, i) => {
			let date = moment(k.date)
				.tz('Europe/Madrid')
				.format('DD/MM/YYYY HH:mm')
			let content = ` ${actionToText(k.action)} ${getMaterialName(
				mainData.materials,
				k.material
			)} (${k.quantity})`

			let change_type =
				k.correct_change === true ? 'Cambio correcto' : 'Cambio adicional'
			let deliver_type =
				k.correct_deliver === true ? '' : 'Entrega adicional'
			if (plain === false) {
				return (
					<p key={k.id}>
						{mainData?.places?.find((f) => f.id === k.place)?.name || ''}{' '}
						<strong
							style={{
								marginRight: 10,
							}}
						>
							{date}
						</strong>
						<span>{content}</span>
						{k.recovered === true && (
							<small style={{ color: 'red' }}>Recuperado</small>
						)}
						{k.action === 'deliver' && deliver_type !== '' && (
							<small
								style={{
									color: 'red',
									opacity: 0.5,
								}}
							>
								{deliver_type}
								{k.extraDelivers}
							</small>
						)}
						{k.action === 'change' && (
							<small
								style={{
									color: k.correct_change ? 'green' : 'red',
									opacity: 0.5,
								}}
							>
								{change_type}
								{k.extraChanges}
							</small>
						)}
						{showReverse && (
							<small
								style={{
									marginLeft: 10,
									color: 'red',
									fontSize: 12,
								}}
								onClick={() => {
									if (!submitting) {
										return setRenderConfirm([
											'remove_action',
											() => {
												reverseAction(k, m)
											},
											<>
												<p>Seguro que quieres eliminar la acción</p>
											</>,
										])
									}
								}}
							>
								Eliminar
							</small>
						)}
					</p>
				)
			}
			return `${
				mainData?.places?.find((f) => f.id === k.place)?.name || ''
			} ${date} - ${content} ${k.recovered === true ? 'Recuperado' : ''}${
				k.action === 'deliver' && deliver_type !== ''
					? ` ${deliver_type}${k.extraDelivers}`
					: ''
			}${
				k.action === 'change' ? ` ${change_type}${k.extraChanges}` : ''
			}\n\n`
		})

		let delivers = 0
		let returns = 0
		let owe_items = 0

		if (filterToday) {
			let del_quantity = 0
			let ret_quantity = 0
			for (const del of m.deliver) {
				if (
					del.date_str ===
					moment().tz('Europe/Madrid').format('DD/MM/YYYY')
				) {
					delivers += 1
					del_quantity += del.quantity
				}
			}
			for (const retu of m.return) {
				if (
					retu.date_str ===
					moment().tz('Europe/Madrid').format('DD/MM/YYYY')
				) {
					returns += 1
					ret_quantity += retu.quantity
				}
			}
			owe_items = del_quantity - ret_quantity
		} else {
			delivers = m.delivers_length
			returns = m.returns_length
			owe_items = m.owe_items
		}

		if (plain === false)
			actions = [
				...actions,
				<hr />,
				<p key={'resumen'}>
					Entregas: <b>{delivers}</b>
					<br />
					Devoluciones: <b>{returns}</b>
					<br />
					Cambios: <b>{totalChanges}</b>
					<br />
					Material extra utilizado: <b>{extraMaterialUsed}</b>
					<br />
					Material no entregado: <b>{owe_items}</b>
				</p>,
			]
		else
			actions = [
				...actions,
				`${
					onlyDelivers
						? `Entregas: ${m.delivers_length}\nDevoluciones: ${m.returns_length}\nMaterial no entregado: ${m.owe_items}\n`
						: `Entregas: ${m.delivers_length}\nDevoluciones: ${
								m.returns_length
						  }\nCambios: ${totalChanges}\nMaterial extra utilizado: ${extraMaterialUsed}\n${
								checkout ? `Material no entregado: ${m.owe_items}` : ''
						  }`
				}`,
			]
		return actions
	}

	const renderMoreChanges = () => {
		return (
			<div className="table-ui">
				<div>
					<table>
						<thead>
							<tr>
								<th>Habitación / Cliente</th>
								<th>Resumen</th>
								<th>Checkout</th>
							</tr>
						</thead>
						<tbody>
							{filterItems(moreChanges).map((m) => {
								let actions = getActions(m, true, true)
								// if (actions.length === 1) return null
								return (
									<tr key={m.id}>
										<td>
											<strong>{m?.room}</strong>
											{' > '}
											{m?.name}
										</td>
										<td>{actions}</td>
										<td>
											<strong
												style={{
													color: moment()
														.tz('Europe/Madrid')
														.isSameOrAfter(m.checkout, 'days')
														? 'red'
														: 'black',
												}}
											>
												{moment(m.checkout)
													.tz('Europe/Madrid')
													.format('DD/MM/YYYY')}{' '}
											</strong>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
		)
	}

	const renderNoReturnToday = () => {
		let items = filterItems(noReturnToday)
		return (
			<div className="table-ui">
				<div>
					<table>
						<thead>
							<tr>
								<th>Habitación / Cliente</th>
								<th>
									Resumen (Total no entregado{' '}
									{getNoReturnQuantity(noReturnToday, true)})
								</th>
								<th>Checkout</th>
								<th>Acción</th>
							</tr>
						</thead>
						<tbody>
							{items.map((m) => {
								let owe_items_data = []
								for (const material of mainData.materials) {
									let delivered_material = 0
									for (const action of m.deliver)
										if (action.material === material.id)
											delivered_material += action.quantity

									let returned_material = 0
									for (const action of m.return)
										if (action.material === material.id)
											returned_material += action.quantity

									if (returned_material < delivered_material) {
										owe_items_data.push({
											...material,
											delivered_material,
											returned_material,
										})
									}
								}

								let actions = getActions(m, true, false, true)

								return (
									<tr key={m.id}>
										<td>
											{m?.room}
											{' - '}
											{m?.name}
										</td>
										<td>{actions}</td>
										<td>
											<strong
												style={{
													color: moment()
														.tz('Europe/Madrid')
														.isSameOrAfter(m.checkout, 'days')
														? 'red'
														: 'black',
												}}
											>
												{moment(m.checkout)
													.tz('Europe/Madrid')
													.format('DD/MM/YYYY')}{' '}
											</strong>
										</td>
										<td>
											{owe_items_data.map((itm) => {
												return (
													<>
														<p
															style={{
																width: '100%',
																textAlign: 'center',
															}}
														>
															<strong>{itm.name}</strong>
														</p>
														<div
															style={{
																marginTop: 10,
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'center',
																alignItems: 'center',
																marginBottom: 20,
															}}
														>
															<button
																style={{
																	maxWidth: 150,
																	margin: 3,
																}}
																className={`button button-small button-primary`}
																disabled={submitting}
																onClick={async () => {
																	setSubmitting(true)
																	await doReturn(
																		m,
																		itm.id,
																		false,
																		true
																	)
																	setSubmitting(false)
																}}
															>
																Devolución
															</button>
															<button
																style={{
																	maxWidth: 150,
																	margin: 3,
																}}
																className={`button button-small button-primary`}
																disabled={submitting}
																onClick={async () => {
																	setSubmitting(true)
																	await doReturn(
																		m,
																		itm.id,
																		true,
																		true
																	)
																	setSubmitting(false)
																}}
															>
																Recuperación
															</button>
														</div>
													</>
												)
											})}
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
		)
	}

	const renderNoReturnHosted = () => {
		return (
			<div className="table-ui">
				<div>
					<table>
						<thead>
							<tr>
								<th>Habitación / Cliente</th>
								<th>
									Resumen (Total no entregado{' '}
									{getNoReturnQuantity(noReturnHosted)})
								</th>
								<th>Checkout</th>
								<th>Acción</th>
							</tr>
						</thead>
						<tbody>
							{filterItems(noReturnHosted).map((m) => {
								let owe_items_data = []
								for (const material of mainData.materials) {
									let delivered_material = 0
									for (const action of m.deliver)
										if (action.material === material.id)
											delivered_material += action.quantity

									let returned_material = 0
									for (const action of m.return)
										if (action.material === material.id)
											returned_material += action.quantity

									if (returned_material < delivered_material) {
										owe_items_data.push({
											...material,
											delivered_material,
											returned_material,
										})
									}
								}

								let actions = getActions(m, false, false, true)
								return (
									<tr key={m.id}>
										<td>
											{m?.room}
											{' - '}
											{m?.name}
										</td>
										<td>{actions}</td>
										<td>
											<strong
												style={{
													color: moment()
														.tz('Europe/Madrid')
														.isSameOrAfter(m.checkout, 'days')
														? 'red'
														: 'black',
												}}
											>
												{moment(m.checkout)
													.tz('Europe/Madrid')
													.format('DD/MM/YYYY')}{' '}
											</strong>
										</td>
										<td>
											{owe_items_data.map((itm) => {
												return (
													<>
														<p
															style={{
																width: '100%',
																textAlign: 'center',
															}}
														>
															<strong>{itm.name}</strong>
														</p>
														<div
															style={{
																marginTop: 10,
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'center',
																alignItems: 'center',
																marginBottom: 20,
															}}
														>
															<button
																style={{
																	maxWidth: 150,
																	margin: 3,
																}}
																className={`button button-small button-primary`}
																disabled={submitting}
																onClick={async () => {
																	setSubmitting(true)
																	await doReturn(
																		m,
																		itm.id,
																		false,
																		false
																	)
																	setSubmitting(false)
																}}
															>
																Devolución
															</button>
															<button
																style={{
																	maxWidth: 150,
																	margin: 3,
																}}
																className={`button button-small button-primary`}
																disabled={submitting}
																onClick={async () => {
																	setSubmitting(true)
																	await doReturn(
																		m,
																		itm.id,
																		true,
																		false
																	)
																	setSubmitting(false)
																}}
															>
																Recuperación
															</button>
														</div>
													</>
												)
											})}
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
		)
	}

	const regulatePendingMaterial = async (
		state = '',
		usr = {},
		itm = {},
		owe = []
	) => {
		let rf = firestore.doc(`/items_management/${user.id}/clients/${usr.id}`)
		await firestore.runTransaction(async (trans) => {
			let dt = await trans.get(rf)
			dt = dt.data() || {}

			let done_keys = Object.keys(dt.pending_material_regularization || {})
			done_keys.push(itm.id)
			done_keys = done_keys.sort()

			let owe_keys = owe.map((o) => o.id).sort()

			let pending_material_regularization_completed =
				JSON.stringify(done_keys) === JSON.stringify(owe_keys)

			await trans.update(rf, {
				pending_material_regularization: {
					...(dt.pending_material_regularization || {}),
					[itm.id]: state,
				},
				pending_material_regularization_completed,
			})
		})
	}

	const renderPendingCheckout = () => {
		return (
			<div className="table-ui">
				<div>
					<table>
						<thead>
							<tr>
								<th>Habitación / Cliente</th>
								<th>Resumen</th>
								<th>Checkout</th>
								<th>Regularización de material pendiente</th>
							</tr>
						</thead>
						<tbody>
							{filterItems(noReturnGone).map((m) => {
								let owe_items_data = []
								for (const material of mainData.materials) {
									let delivered_material = 0
									for (const action of m.deliver)
										if (action.material === material.id)
											delivered_material += action.quantity

									let returned_material = 0
									for (const action of m.return)
										if (action.material === material.id)
											returned_material += action.quantity

									if (returned_material < delivered_material) {
										owe_items_data.push({
											...material,
											delivered_material,
											returned_material,
										})
									}
								}
								let actions = getActions(m, false, false, false, true)

								return (
									<tr key={m.id}>
										<td>
											<strong>{m?.room}</strong>
											{' > '}
											{m?.name}
										</td>
										<td>{actions}</td>
										<td>
											<strong
												style={{
													color: moment()
														.tz('Europe/Madrid')
														.isSameOrAfter(m.checkout, 'days')
														? 'red'
														: 'black',
												}}
											>
												{moment(m.checkout)
													.tz('Europe/Madrid')
													.format('DD/MM/YYYY')}{' '}
											</strong>
										</td>
										<td>
											{owe_items_data.map((itm) => {
												let selected_state =
													m?.pending_material_regularization
														? m.pending_material_regularization[
																itm.id
														  ] || ''
														: ''
												return (
													<>
														<strong
															style={{
																width: '100%',
																textAlign: 'center',
															}}
														>
															{itm.name}
														</strong>
														<div
															style={{
																marginTop: 10,
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'center',
																alignItems: 'center',
																marginBottom: 20,
															}}
														>
															<button
																style={{
																	maxWidth: 150,
																	margin: 3,
																}}
																className={`button button-small button-primary ${
																	selected_state !== 'charged'
																		? 'button-primary-outline'
																		: ''
																}`}
																disabled={
																	submitting ||
																	selected_state === 'charged'
																}
																onClick={async () => {
																	setSubmitting(true)
																	await regulatePendingMaterial(
																		'charged',
																		m,
																		itm,
																		owe_items_data
																	)
																	setSubmitting(false)
																}}
															>
																Cobrado
															</button>
															<button
																style={{
																	maxWidth: 150,
																	margin: 3,
																}}
																className={`button button-small button-primary ${
																	selected_state !==
																	'recovered'
																		? 'button-primary-outline'
																		: ''
																}`}
																disabled={
																	submitting ||
																	selected_state ===
																		'recovered'
																}
																onClick={async () => {
																	setSubmitting(true)
																	await regulatePendingMaterial(
																		'recovered',
																		m,
																		itm,
																		owe_items_data
																	)
																	setSubmitting(false)
																}}
															>
																Recuperado
															</button>
															<button
																style={{
																	maxWidth: 150,
																	margin: 3,
																}}
																className={`button button-small button-primary ${
																	selected_state !== 'other'
																		? 'button-primary-outline'
																		: ''
																}`}
																disabled={
																	submitting ||
																	selected_state === 'other'
																}
																onClick={async () => {
																	setSubmitting(true)
																	await regulatePendingMaterial(
																		'other',
																		m,
																		itm,
																		owe_items_data
																	)
																	setSubmitting(false)
																}}
															>
																Otros
															</button>
														</div>
													</>
												)
											})}
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
		)
	}

	const renderCheckoutToday = () => {
		return (
			<div className="table-ui">
				<div>
					<table>
						<thead>
							<tr>
								<th>Habitación / Cliente</th>
								<th>Resumen</th>
								<th>Checkout</th>
								<th>Regularización de material pendiente</th>
							</tr>
						</thead>
						<tbody>
							{filterItems(noReturnCheckout).map((m) => {
								let owe_items_data = []
								for (const material of mainData.materials) {
									let delivered_material = 0
									for (const action of m.deliver)
										if (action.material === material.id)
											delivered_material += action.quantity

									let returned_material = 0
									for (const action of m.return)
										if (action.material === material.id)
											returned_material += action.quantity

									if (returned_material < delivered_material) {
										owe_items_data.push({
											...material,
											delivered_material,
											returned_material,
										})
									}
								}

								let actions = getActions(m, false, false, false, true)
								return (
									<tr key={m.id}>
										<td>
											<strong>{m?.room}</strong>
											{' > '}
											{m?.name}
										</td>
										<td>{actions}</td>
										<td>
											<strong
												style={{
													color: moment()
														.tz('Europe/Madrid')
														.isSameOrAfter(m.checkout, 'days')
														? 'red'
														: 'black',
												}}
											>
												{moment(m.checkout)
													.tz('Europe/Madrid')
													.format('DD/MM/YYYY')}{' '}
											</strong>
										</td>
										<td>
											{owe_items_data.map((itm) => {
												let selected_state =
													m?.pending_material_regularization
														? m.pending_material_regularization[
																itm.id
														  ] || ''
														: ''
												return (
													<>
														<strong
															style={{
																width: '100%',
																textAlign: 'center',
															}}
														>
															{itm.name}
														</strong>
														<div
															style={{
																marginTop: 10,
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'center',
																alignItems: 'center',
																marginBottom: 20,
															}}
														>
															<button
																style={{
																	maxWidth: 150,
																	margin: 3,
																}}
																className={`button button-small button-primary ${
																	selected_state !== 'charged'
																		? 'button-primary-outline'
																		: ''
																}`}
																disabled={
																	submitting ||
																	selected_state === 'charged'
																}
																onClick={async () => {
																	setSubmitting(true)
																	await regulatePendingMaterial(
																		'charged',
																		m,
																		itm,
																		owe_items_data
																	)
																	setSubmitting(false)
																}}
															>
																Cobrado
															</button>
															<button
																style={{
																	maxWidth: 150,
																	margin: 3,
																}}
																className={`button button-small button-primary ${
																	selected_state !==
																	'recovered'
																		? 'button-primary-outline'
																		: ''
																}`}
																disabled={
																	submitting ||
																	selected_state ===
																		'recovered'
																}
																onClick={async () => {
																	setSubmitting(true)
																	await regulatePendingMaterial(
																		'recovered',
																		m,
																		itm,
																		owe_items_data
																	)
																	setSubmitting(false)
																}}
															>
																Recuperado
															</button>
															<button
																style={{
																	maxWidth: 150,
																	margin: 3,
																}}
																className={`button button-small button-primary ${
																	selected_state !== 'other'
																		? 'button-primary-outline'
																		: ''
																}`}
																disabled={
																	submitting ||
																	selected_state === 'other'
																}
																onClick={async () => {
																	setSubmitting(true)
																	await regulatePendingMaterial(
																		'other',
																		m,
																		itm,
																		owe_items_data
																	)
																	setSubmitting(false)
																}}
															>
																Otros
															</button>
														</div>
													</>
												)
											})}
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
		)
	}

	const renderFilter = () => {
		return (
			<ModalForm toggle={() => setShowFilter(false)} size="sm">
				<Formik
					initialValues={{
						...filter,
					}}
					onSubmit={(values) => {
						setFilter(values)
						setShowFilter(false)
					}}
				>
					{({ values, setFieldValue }) => {
						return (
							<Form className="form-ui">
								<h1>Filtro</h1>
								<div
									style={{
										padding: 15,
									}}
								>
									<div className={`form-group upper-9`}>
										<div className="form-row">
											<div className="col-3">
												<label>Material:</label>
											</div>
											<div className="col-9">
												<Select
													className="react-select"
													classNamePrefix="react-select"
													placeholder="Seleccionar"
													options={mainData.materials.map((m) => ({
														value: m.id,
														label: m.name,
													}))}
													isClearable
													onChange={(e) => {
														setFieldValue(
															'item',
															e?.value || null
														)
													}}
													value={
														mainData.materials
															?.map((m) => ({
																label: m.name,
																value: m.id,
															}))
															.find(
																(f) => f.value === values.item
															) || null
													}
												/>
											</div>
										</div>
									</div>
									<div className={`form-group upper-8`}>
										<div className="form-row">
											<div className="col-3">
												<label>Acción:</label>
											</div>
											<div className="col-9">
												<Select
													className="react-select"
													classNamePrefix="react-select"
													placeholder="Seleccionar"
													options={actionOptions}
													onChange={(e) => {
														setFieldValue(
															'action',
															e?.value || null
														)
													}}
													isClearable
													value={
														actionOptions?.find(
															(f) => f.value === values.action
														) || null
													}
												/>
											</div>
										</div>
									</div>
									<div className={`form-group upper-7`}>
										<div className="form-row">
											<div className="col-3">
												<label>Puesto:</label>
											</div>
											<div className="col-9">
												<Select
													className="react-select"
													classNamePrefix="react-select"
													placeholder="Seleccionar"
													options={mainData.places.map((p) => ({
														value: p.id,
														label: p.name,
													}))}
													onChange={(e) => {
														setFieldValue(
															'place',
															e?.value || null
														)
													}}
													isClearable
													value={
														mainData?.places
															?.map((m) => ({
																label: m.name,
																value: m.id,
															}))
															.find(
																(f) => f.value === values.place
															) || null
													}
												/>
											</div>
										</div>
									</div>
									<Input
										label="Habitación"
										disabled={submitting}
										name="room"
									/>
									<div className="form-group form-group-check">
										<div className="form-row">
											<div className="col-12">
												<Field
													type="checkbox"
													name="checkout_today"
													id="checkout_today"
													checked={values.checkout_today}
												/>
												<label htmlFor="checkout_today">
													<b></b> <span>Checkout Hoy</span>
												</label>
											</div>
										</div>
									</div>
								</div>
								<footer style={{ marginTop: 10 }}>
									<button
										type="submit"
										disabled={submitting}
										className="button button-primary"
									>
										{submitting && (
											<div
												className="spinner-border spinner-border-sm"
												role="status"
											>
												<span className="sr-only">Cargando...</span>
											</div>
										)}
										{!submitting && (
											<>
												<span>Modificar</span>
											</>
										)}
									</button>
								</footer>
							</Form>
						)
					}}
				</Formik>
			</ModalForm>
		)
	}

	const filterItems = (items = []) => {
		if (filter.item !== null)
			items = items.filter(
				(f) =>
					(f?.item ||
						f?.value?.item ||
						f?.material ||
						f?.value?.material) === filter.item
			)

		if (`${filter.room}`.length > 0)
			items = items.filter(
				(f) => `${f?.room || f?.value?.room}` === `${filter.room}`
			)

		if (filter.action !== null)
			items = items.filter(
				(f) => `${f?.value?.action}` === `${filter.action}`
			)

		if (filter.place !== null)
			items = items.filter((f) => `${f?.place}` === `${filter.place}`)

		if (filter.checkout_today === true)
			items = items.filter(
				(f) =>
					moment(parseInt(f?.value?.checkout || 0))
						.tz('Europe/Madrid')
						.format('DD/MM/YYYY') ===
					moment().tz('Europe/Madrid').format('DD/MM/YYYY')
			)

		return items
	}

	const _renderConfirm = () => {
		let label = 'Continuar'
		let cancel_label = 'Cancelar'
		let type = 'primary'
		let fn = renderConfirm[1]
		if (renderConfirm[0] === 'end_service') {
			label = 'Si'
			cancel_label = 'No'
			type = 'danger'
		} else if (
			renderConfirm[0] === 'extra_items' ||
			renderConfirm[0] === 'extra_deliver'
		) {
			label = 'Continuar de todas formas'
			cancel_label = 'Cancelar'
			type = 'primary'
		} else if (renderConfirm[0] === 'remove_action') {
			label = 'Eliminar'
			cancel_label = 'Cancelar'
			type = 'danger'
		}
		return (
			<Confirmation
				label={label}
				cancelLabel={cancel_label}
				type={type}
				renderPlain
				onChange={async (e) => {
					if (!e) setRenderConfirm(false)
					else {
						await fn()
						setRenderConfirm(false)
					}
				}}
			>
				{renderConfirm[2]}
			</Confirmation>
		)
	}

	if (mainData === null || placeData === null) return <Spinner />

	return (
		<div className="main">
			{Array.isArray(renderConfirm) && _renderConfirm()}
			{showEditStock && renderEditStock()}
			{showRecover && renderRecover()}
			{showFilter && renderFilter()}
			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs">
					<li className="center">
						<button
							onClick={() => history.push('/choose/items-management')}
							disabled={submitting}
							className="button button-dark-outline inline"
							style={{
								marginRight: 15,
							}}
							title="Atrás"
						>
							<i className="mi">arrow_back</i>
						</button>
					</li>
				</ul>
				<div className="actions">
					<button
						className="button button-dark-outline inline"
						onClick={(e) => window.location.reload()}
					>
						<i className="mi">refresh</i>
					</button>

					{currentServiceData === null && (
						<button
							onClick={() => startService()}
							disabled={submitting}
							className="button button-success button-success-outline inline button-small"
						>
							<span>Iniciar Servicio</span>
						</button>
					)}
					{currentServiceData !== null && (
						<button
							onClick={(e) =>
								setRenderConfirm([
									'end_service',
									() => {
										finishService()
									},
									<>
										<p>Seguro que quieres terminar el servicio?</p>
									</>,
								])
							}
							disabled={submitting}
							className="button button-danger button-danger-outline inline button-small"
						>
							<i className="mi">cancel</i> <span>Terminar Servicio</span>
						</button>
					)}

					{currentServiceData !== null && (
						<>
							<button
								disabled={submitting}
								className="button button-primary button-primary-outline inline"
								onClick={() => setShowRecover(true)}
							>
								<span>Recuperar Material En Piscinas</span>
							</button>
							<button
								disabled={submitting}
								className="button button-primary inline"
								onClick={() => setShowEditStock(true)}
							>
								<span>Reponer Stock</span>
							</button>
						</>
					)}
				</div>
			</div>
			<header>
				<h1>{placeData.name}</h1>
				{currentServiceData !== null && (
					<p>
						Servicio iniciado a las{' '}
						{moment(currentServiceData?.started)
							.tz('Europe/Madrid')
							.format(`HH:mm`)}
					</p>
				)}
			</header>
			<div className="row">
				<div className="col-6 order-sm-2">
					{currentServiceData !== null && (
						<div className="indicators-ui">{renderIndicators()}</div>
					)}
				</div>
				<div className="col-6">
					{currentServiceData !== null && renderMainActions()}
				</div>
			</div>
			<div className="registrations">
				<nav>
					<ul className="tabs">
						<li className={`${activeTab === 0 ? 'active' : ''}`}>
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault()
									setActiveTab(0)
								}}
							>
								Movimientos
							</a>
						</li>
						<li className={`${activeTab === 1 ? 'active' : ''}`}>
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault()
									setActiveTab(1)
								}}
							>
								Más de 1 cambio
							</a>
						</li>
						<li className={`${activeTab === 2 ? 'active' : ''}`}>
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault()
									setActiveTab(2)
								}}
							>
								Sin entregar
							</a>
						</li>
						<li className={`${activeTab === 3 ? 'active' : ''}`}>
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault()
									setActiveTab(3)
								}}
							>
								Sin entregar estancia
							</a>
						</li>
						<li className={`${activeTab === 4 ? 'active' : ''}`}>
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault()
									setActiveTab(4)
								}}
							>
								Checkout Hoy
							</a>
						</li>
						<li className={`${activeTab === 5 ? 'active' : ''}`}>
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault()
									setActiveTab(5)
								}}
							>
								Checkout Pendientes
							</a>
						</li>
					</ul>
					<div className="actions">
						<button
							onClick={getPdf}
							className="button button-light inline"
						>
							<i className="mi">print</i> <span>Imprimir</span>
						</button>
						<button
							onClick={() => setShowFilter(true)}
							className="button button-light inline"
						>
							<i className="mi">search</i> <span>Filtrar</span>
						</button>
						{JSON.stringify(filter) !==
							JSON.stringify(defaultFilters) && (
							<button
								onClick={() => setFilter(defaultFilters)}
								className="button button-danger button-danger-outline inline"
							>
								<i className="mi">clear</i> <span>Limpiar Filtros</span>
							</button>
						)}
					</div>
				</nav>
				<div>
					{activeTab === 0 && renderRegisters()}
					{activeTab === 1 && renderMoreChanges()}
					{activeTab === 2 && renderNoReturnToday()}
					{activeTab === 3 && renderNoReturnHosted()}
					{activeTab === 4 && renderCheckoutToday()}
					{activeTab === 5 && renderPendingCheckout()}
				</div>
			</div>
		</div>
	)
}

export default connect(
	(state) => ({ user: state.user, topbar: state.topbar }),
	{ create_alert }
)(ItemsManagement)

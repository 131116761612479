import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Loading } from '../components'
import { auth, firestore } from '../config/firebase'
import { add_event, events } from '../store/actions'
import Events from './Dashboard/Events'
import Navbar from './Dashboard/Navbar'

function OnlyCollaborator({ events, set_events, add_event }) {
	const [navbarStatus, setNavbarStatus] = useState('navbar-collapsed')
	const [user, setUser] = useState(null)

	useEffect(() => {
		auth.onAuthStateChanged(user => fetchUser(user))
	}, [])

	const fetchUser = async user => {
		if (user === null) return
		let _user = await firestore
			.collection('collaborators')
			.doc(user.phoneNumber.replace('+', ''))
			.get()
		_user = _user.data()
		setUser(_user)
	}

	if (user === null) return <Loading />

	if (window.location.pathname !== '/events/list') {
		return <Redirect to="/events/list" />
	}

	return (
		<div id="main">
			<div className={`dashboard ${navbarStatus} has-navbar`}>
				<Navbar
					collapse={() => {
						setNavbarStatus('navbar-collapsed')
					}}
					onToggle={(forced = null) => {
						let st = ''
						if (forced === null) {
							st = navbarStatus === '' ? 'navbar-collapsed' : ''
						} else {
							st = !forced ? 'navbar-collapsed' : ''
						}
						setNavbarStatus(st)
					}}
					isCollapsed={navbarStatus !== ''}
					collaborator
					user={user}
				/>
				<div className="main">
					<Events
						isCollaborator={user}
						events={events}
						set_events={set_events}
						add_event={add_event}
					/>
				</div>
			</div>
		</div>
	)
}

export default connect(state => ({ events: state.events }), {
	set_events: events,
	add_event
})(OnlyCollaborator)

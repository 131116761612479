const initialState = null

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'UPDATE_RESERVATIONS':
			return { ...payload }
		case 'SIGN_OUT':
			return null
		default:
			return state
	}
}

import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { Loading } from '../components'
import { firestore, functions } from '../config/firebase'

const getNumberDate = (date) => {
	if (typeof date === 'number') {
		let dt = new Date(date)
		let val = `${dt.getFullYear()}${`0${dt.getMonth() + 1}`.slice(
			-2
		)}${`0${dt.getDate()}`.slice(-2)}`
		return parseInt(val)
	} else {
		let dat = `${date}`
		dat = [...dat.split('/')]
		dat.reverse()
		dat = dat.join('')
		dat = parseInt(dat)
		return dat
	}
}

export default function ConfirmReservation({}) {
	const { id, type } = useParams()
	const [reservation, setReservation] = useState(null)
	const [loading, setLoading] = useState(null)
	const [customNote, setCustomNote] = useState('')
	const [finalMessage, setFinalMessage] = useState(null)

	const getReservation = async () => {
		let res = await firestore
			.collection(`/confirmations`)
			.where('longid', '==', id)
			.get()

		if (res.docs.length === 1) {
			let dt = res.docs[0].data()
			if (dt.response === true) {
				setFinalMessage('Tu reserva ha sido confirmada')
			} else if (dt.response === false) {
				setFinalMessage('Tu reserva ha sido cancelada')
			} else {
				setReservation(dt)
			}
		} else {
			setReservation(false)
		}
	}

	useEffect(() => {
		getReservation()
	}, [id])

	const confirm = async () => {
		setLoading(true)
		await firestore.doc(`/confirmations/${reservation.data.id}`).update({
			response: true,
			message: 'Reserva confirmada',
		})
		setLoading(false)
		setFinalMessage(
			'Tu reserva ha sido confirmada, puedes cerrar esta pagina.'
		)
	}

	const cancel = async () => {
		try {
			setLoading(true)
			await firestore.doc(`/confirmations/${reservation.data.id}`).update({
				response: false,
				message: customNote || 'Reserva Cancelada',
			})

			let numberDate = getNumberDate(reservation.data.date_block[0])

			let remove = functions.httpsCallable('removeAdvancedReservationQueue')
			await remove({
				id: reservation.data.id,
			})

			let remove2 = functions.httpsCallable('removeAdvancedReservationDay')
			await remove2({
				reservation: reservation.data,
				numberDate,
			})

			setLoading(false)
			setFinalMessage(
				'Tu reserva ha sido cancelada, puedes cerrar esta pagina.'
			)
		} catch (e) {
			console.log('ERROR', e)
			setLoading(false)
			setFinalMessage('Error al cancelar tu reserva')
		}
	}

	if (typeof finalMessage === 'string')
		return (
			<div
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					flexDirection: 'column',
					marginTop: 50,
				}}
			>
				<h3>{finalMessage}</h3>
			</div>
		)
	if (reservation === null) return <Loading />
	if (reservation === false || (type !== 'cancel' && type !== 'confirm'))
		return (
			<div
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					flexDirection: 'column',
					marginTop: 50,
				}}
			>
				<h1>404</h1>
				<h2>Reservation not found</h2>
			</div>
		)

	return (
		<>
			<Helmet>
				<title>{reservation.data.placeName}</title>
			</Helmet>
			<div
				style={{
					width: '100%',
					maxWidth: 600,
					margin: '0 auto',
					height: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					flexDirection: 'column',
					marginTop: 50,
				}}
			>
				<h1>{reservation.data.placeName}</h1>
				<hr
					style={{
						width: '100%',
					}}
				/>
				{type === 'cancel' && (
					<div
						style={{
							width: '100%',
						}}
					>
						<h3
							style={{
								width: '100%',
								textAlign: 'center',
							}}
						>
							Cancelar reserva
						</h3>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<textarea
								placeholder={'Mensaje para cancelación'}
								onChange={(e) => {
									setCustomNote(e.target.value)
								}}
								value={customNote}
								maxLength="100"
								style={{
									width: '60%',
									marginTop: 25,
								}}
							></textarea>
							{loading === true && (
								<p
									style={{
										padding: 15,
									}}
								>
									Espera un momento
								</p>
							)}
							<button
								disabled={loading}
								className="button button-danger button-small inline"
								style={{
									marginTop: 25,
								}}
								onClick={cancel}
							>
								Cancelar Reserva
							</button>
						</div>
					</div>
				)}
				{type === 'confirm' && (
					<div
						style={{
							width: '100%',
						}}
					>
						<h3
							style={{
								width: '100%',
								textAlign: 'center',
							}}
						>
							Confirmar reserva
						</h3>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<button
								disabled={loading}
								className="button button-success button-small inline"
								style={{
									marginTop: 25,
								}}
								onClick={confirm}
							>
								Click aqui para confirmar
							</button>
						</div>
					</div>
				)}
			</div>
		</>
	)
}

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../../components/Loading'
import { functions } from '../../config/firebase'

function ReservationHistory({ selectedDay, user }) {
	const [data, setData] = useState(null)

	useEffect(() => {
		getData()
	}, [selectedDay])

	const getNumberDate = (date) => {
		let dt = new Date(date)
		let val = `${dt.getFullYear()}${`0${dt.getMonth() + 1}`.slice(
			-2
		)}${`0${dt.getDate()}`.slice(-2)}`
		return parseInt(val)
	}

	const getData = async () => {
		setData(null)
		let fn = functions.httpsCallable('getlogdata')
		let res = await fn({
			_date: getNumberDate(selectedDay),
			places: user.places || [],
		})
		res = res.data
		setData(res)
	}

	const getFormattedDate = (date) => {
		let _date = new Date(date)

		let dd = _date.getDate()
		let mm = _date.getMonth() + 1
		let yyyy = _date.getFullYear()

		if (dd < 10) dd = '0' + dd
		if (mm < 10) mm = '0' + mm

		return dd + '/' + mm + '/' + yyyy
	}

	const renderQueue = () => {
		return data.data.map((p) => {
			if (p.type === 'update') return null
			return (
				<tr>
					<td>
						<span
							className={`badge ${
								p.type === 'create' ? 'badge-success' : 'badge-danger'
							}`}
						>
							{p.type === 'create' ? 'Creado' : 'Borrado'}
						</span>
					</td>
					<td>{p.data[0] ? p.data[0].placeName || '' : ''}</td>
					<td>
						{getFormattedDate(new Date(p.created_at))} -{' '}
						{`${`0${new Date(p.created_at).getHours()}`.slice(
							-2
						)}:${`0${new Date(p.created_at).getMinutes()}`.slice(-2)}`}
					</td>
					<td>{p.data[0] ? p.data[0].name || '' : ''}</td>
					<td>{p.data[0] ? p.data[0].room || '' : ''}</td>
					<td>
						{p.data[0]
							? typeof p.data[0].group === 'object'
								? p.data[0].group.initials || ''
								: ''
							: ''}
					</td>
					<td>{p.data[0] ? p.data[0].people || '' : ''}</td>
					<td>{p.data[0] ? p.data[0].notes || '' : ''}</td>
					<td>{p.data[0] ? p.data[0].email || '' : ''}</td>
				</tr>
			)
		})
	}

	if (data === null) return <Loading />

	return (
		<div
			className="table-ui"
			id="MAINSCREEN_SEARCH"
			style={{
				backgroundColor: 'white',
			}}
		>
			<div>
				<table>
					<thead>
						<tr>
							<th>Acción</th>
							<th>Lugar</th>
							<th>Fecha de modificación</th>
							<th>Nombre</th>
							<th>Habitación</th>
							<th>Pensión</th>
							<th>Personas</th>
							<th>Notas</th>
							<th>Correo</th>
						</tr>
					</thead>
					{renderQueue()}
				</table>
			</div>
		</div>
	)
}

export default connect((state) => ({ user: state.user }))(ReservationHistory)

import React, { useState, useEffect } from 'react'
import FlipMove from 'react-flip-move'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { TablesMap } from '../../../components'
import { firestore } from '../../../config/firebase'
import qs from 'query-string'

function Tables({ place, tables, language, employee, topbar, history, covid }) {
	const [tableSelected, setTableSelected] = useState(null)
	const [tableOrder, setTableOrder] = useState(null)
	const [occupied, setOccupied] = useState(false)
	const [tablePaid, setTablePaid] = useState(false)

	const [addTable, setAddTable] = useState(false)
	const [editFloors, setEditFloors] = useState(false)

	const [reload, setReload] = useState(false)

	const [error, setError] = useState(null)

	useEffect(() => {
		let query = qs.parse(window.location.search)
		if (query.reload === '1') {
			setReload(true)
			setTimeout(() => {
				setReload(false)
			}, 50)
		}
	}, [place.orders])

	const deleteProduct = (prod, ord) => {
		let _orders = []
		place.orders.forEach((_ord) => {
			let _ord_ = null
			if (_ord.invoiceID !== ord.invoiceID) {
				_ord_ = _ord
			} else {
				_ord_ = {
					..._ord,
					items: _ord.items.filter((it) => it.cartId !== prod.cartId),
				}
			}
			if (_ord_.items.length > 0) _orders.push(_ord_)
		})
		firestore.collection('places').doc(place.id).update({
			orders: _orders,
		})
	}

	const renderCartProducts = () => {
		let ord = null
		let _items = place.orders.find((_ord) => {
			if (tableSelected === null) return false
			if (_ord.table === tableSelected.id) ord = _ord
			return _ord.table === tableSelected.id
		})
		let items = []
		if (typeof _items !== 'undefined') {
			items = _items.items.map((prod) => {
				let adds = prod.additions.map((add) => {
					let no_adds = true
					let add_items = add.items.map((it) => {
						if (it.quantity <= 0) return null
						no_adds = false
						return (
							<li key={it.name}>
								<label>{it.name}</label>
								<span>{it.value} €</span>
							</li>
						)
					})
					if (no_adds) return null
					return (
						<li>
							<strong>{add.name}</strong>
							<div>{add_items}</div>
						</li>
					)
				})
				return (
					<li key={prod.cartId}>
						<span>
							{prod.image !== 'no_image' && (
								<img src={prod.image} width="50" alt="product_image" />
							)}
						</span>
						{!prod.paid && (
							<button
								onClick={() => deleteProduct(prod, ord)}
								title="Remove product"
							>
								<i />
							</button>
						)}
						<div>
							<h4>
								{prod.name}
								<span>
									<strong>{prod.price.toFixed(2)} €</strong>
								</span>
							</h4>
							<ol>{adds}</ol>
						</div>
					</li>
				)
			})
		}
		return (
			<FlipMove
				enterAnimation="accordionVertical"
				leaveAnimation="accordionVertical"
				duration={100}
				typeName="ul"
			>
				{items}
			</FlipMove>
		)
	}

	const onTableSelected = (table, occupied, order) => {
		setTableSelected(table)
		setTableOrder(order)
		setOccupied(occupied)
		if (order !== null) {
			let paid = true
			order.items.forEach((it) => {
				if (!it.paid) paid = false
			})
			setTablePaid(paid)
		}
	}

	const closeTable = () => {
		firestore
			.collection('places')
			.doc(place.id)
			.update({
				orders: place.orders.filter(
					(ord) => ord.invoiceID !== tableOrder.invoiceID
				),
			})
	}

	const renderOptions = () => {
		if (tableSelected === null) return null
		let options = []
		if (occupied && !tablePaid) {
			options.push(
				<div key="checkout">
					<Link
						key="checkout-table"
						to={`/invoicing?table=${tableSelected.id}&type=checkout`}
					>
						Charge table
					</Link>
				</div>
			)
		}
		if (tablePaid) {
			options.push(
				<div key="close">
					<button onClick={closeTable} key="checkout-table">
						Close table
					</button>
				</div>
			)
		}
		options.push(
			<div key="add">
				<Link
					key="add-to-table"
					to={`/invoicing?table=${tableSelected.id}&type=add`}
				>
					Add products
				</Link>
			</div>
		)
		if (options.length) {
			return (
				<footer className={`options-count-` + options.length}>
					<div>{options}</div>
				</footer>
			)
		} else {
			return null
		}
	}

	const renderTableContent = () => (
		<>
			<h3>
				{language.table} {tableSelected.number}
			</h3>
			{renderCartProducts()}
			{renderOptions()}
		</>
	)

	const getFormattedHour = (date) => {
		let _date = new Date(date)

		let hr = _date.getHours()
		let mn = _date.getMinutes()

		if (mn < 30) mn = '00'
		else mn = '30'

		hr = `0${hr}`.slice(-2)
		mn = `0${mn}`.slice(-2)

		return `${hr}:${mn}`
	}

	if (reload) return null

	if (error !== null) return <p>{error}</p>

	let employee_permissions = place.employee_categories.find(
		(cat) => cat.id === employee.category
	).permissions

	return (
		<>
			<div className="topbar" style={{ top: topbar.top }}>
				<div>
					{covid !== null &&
						covid.current &&
						covid.current.slot !== null &&
						covid.current.block.index !== -1 && (
							<h4>
								<span
									className="badge badge-primary"
									style={{
										paddingLeft: 10,
										paddingRight: 10,
										borderRadius: 5,
										marginLeft: 20,
									}}
								>
									Turno {covid.current.block.index + 1}
								</span>
								<span
									className="badge badge-dark"
									style={{
										paddingLeft: 10,
										paddingRight: 10,
										borderRadius: 5,
										marginLeft: 20,
									}}
								>
									Franja{' '}
									{getFormattedHour(
										new Date(covid.current.slot.starts)
									)}{' '}
									-{' '}
									{getFormattedHour(new Date(covid.current.slot.ends))}
								</span>
								<span
									className="badge badge-secondary"
									style={{
										paddingLeft: 10,
										paddingRight: 10,
										borderRadius: 5,
										marginLeft: 20,
									}}
								>
									Ocupación {covid.current.assisted} /{' '}
									{covid.current.blockMax}
								</span>
							</h4>
						)}
				</div>
				<div className="actions">
					{employee_permissions.indexOf('administrator') !== -1 && (
						<>
							<button
								onClick={() => {
									setEditFloors(true)
								}}
								className="button button-light inline"
							>
								<i className="mi">edit</i>
								<span>{language.edit_floors}</span>
							</button>
							<button
								onClick={() => {
									setAddTable(true)
								}}
								className="button inline button-primary"
							>
								<i className="mi">add</i>
								<span>{language.new_table}</span>
							</button>
						</>
					)}
				</div>
			</div>
			<div className="tables-map-holder">
				<TablesMap
					editFloors={editFloors}
					setEditFloors={setEditFloors}
					addTable={addTable}
					setAddTable={setAddTable}
					show_select={true}
					employee={employee}
					show_near_reservation
					setError={setError}
					history={history}
				/>
				<div>{tableSelected !== null && renderTableContent()}</div>
			</div>
		</>
	)
}

export default connect((state) => ({
	place: state.place,
	tables: state.tables,
	language: state.language.dictionary,
	employee: state.employee,
	topbar: state.topbar,
	covid: state.covid,
}))(Tables)

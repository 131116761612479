export default (content, type, redirect = null, sound = false) => (
	dispatch,
	getState
) => {
	const state = getState()
	let prevAlerts = state.alerts
	let newAlerts = [
		...prevAlerts,
		{
			content,
			type,
			id: Math.floor(Math.random() * 1000000),
			redirect,
			sound,
		},
	]
	dispatch({
		type: 'MODIFY_ALERTS',
		payload: newAlerts,
	})
}

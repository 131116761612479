import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { getTrackBackground, Range } from 'react-range'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { firestore } from '../../../config/firebase'
import { create_alert } from '../../../store/actions'
import { distributeHours, getSchedule } from './functions'

function Schedule({ place, create_alert }) {
	const [selectedCategory, setSelectedCategory] = useState('all')
	const [timelineRef, setTimelineRef] = useState(null)
	const [pixelsByHour, setPixelsByHour] = useState(0)
	const [hourDiff, setHourDiff] = useState(0)
	const [opensAt, setOpensAt] = useState(0)
	const [closesAt, setClosesAt] = useState(0)
	const [hiddenDays, setHiddenDays] = useState([])
	const [headerPos, setHeaderPos] = useState(-1)
	const [containerW, setContainerW] = useState(0)
	const [onlyBusy, setOnlyBusy] = useState(false)

	let sliderObj = {}
	place.employees.forEach(emp => {
		for (let i = 0; i < 7; i++) {
			let val = [-1, -1]
			if (typeof place.schedule[`${emp.id}_${i}`] !== 'undefined')
				val = place.schedule[`${emp.id}_${i}`]
			sliderObj[`${emp.id}_${i}`] = val
		}
	})
	const [sliderStatus, setSliderStatus] = useState(sliderObj)

	useEffect(() => {
		if (
			Object.keys(place.schedule).length === 0 &&
			place.schedule_config !== null
		) {
			let config = place.schedule_config
			let employees = place.employees.map(emp => ({
				id: emp.id,
				schedule: [[], [], [], [], [], [], []],
				name: emp.name,
				hours: 0,
				category: emp.category
			}))
			let emps = distributeHours(employees, config)
			let sch = getSchedule(emps, config)
			firestore
				.collection('places')
				.doc(place.id)
				.update({
					schedule: sch
				})
		} else if (place.schedule_config !== null) {
			getHourDiff()
			handleChange(null)
		}
	}, [])

	useEffect(() => {
		if (timelineRef !== null) {
			let hrDiff = getHourDiff()
			let pxByHour = Math.floor(timelineRef / (hrDiff + 2))
			setPixelsByHour(pxByHour)
		}
	}, [timelineRef])

	useEffect(() => {
		if (place.employees.length > 0) {
			if (headerPos !== -1) window.addEventListener('scroll', handleChange)
			return () => window.removeEventListener('scroll', handleChange)
		}
	}, [headerPos])

	const handleChange = function(e) {
		if (place.employees.length > 0 && place.schedule.length > 0) {
			if (headerPos.offsetTop - 20 <= window.scrollY) {
				document.getElementById('header').style.top = `${window.scrollY -
					(headerPos.offsetTop - 20)}px`
			} else document.getElementById('header').style.top = '0px'
		}
	}

	const getHourDiff = () => {
		if (place.schedule_config === null) return

		let sch_conf = JSON.parse(place.schedule_config)
		let minTime = [-1, 0]
		let maxTime = [99, 0]
		let _op = 99
		let _cl = -1

		sch_conf.forEach(gr => {
			gr.shifts.forEach(sh => {
				let op = sh.enter[0] + sh.enter[1] / 60
				let cl = sh.exit[0] + sh.exit[1] / 60
				if (op < _op) {
					_op = op
					minTime = sh.enter
				}
				if (cl > _cl) {
					_cl = cl
					maxTime = sh.exit
				}
			})
		})

		let hrDiff = maxTime[0] - minTime[0]
		if (minTime[1] / 60 === 0 && maxTime[1] / 60 === 0) hrDiff += 0
		else if (minTime[1] / 60 === 0.5 && maxTime[1] / 60 === 0) hrDiff -= 0.5
		else if (minTime[1] / 60 === 0.5 && maxTime[1] / 60 === 0.5) hrDiff += 0
		else if (minTime[1] / 60 === 0 && maxTime[1] / 60 === 0.5) hrDiff += 0.5

		setHourDiff(hrDiff)
		setOpensAt(minTime)
		setClosesAt(maxTime)
		return hrDiff
	}

	const renderCategoriesAsOptions = () => {
		return [{ id: 'all', name: 'All' }, ...place.employee_categories].map(
			cat => (
				<option key={cat.id} value={cat.id}>
					{cat.name}
				</option>
			)
		)
	}

	const renderHours = () => {
		let items = []
		for (let x = 0; x <= hourDiff * 2; x++) {
			let showHr = ''
			let hour = opensAt[0] + x / 2
			if (hour > 23) showHr += '+ '
			if (hour >= 24) hour -= 24
			showHr += hour < 10 ? `0${hour}` : hour
			if (x % 2 === 0)
				items.push(
					<div
						style={{
							height: 40,
							width: pixelsByHour,
							position: 'relative'
						}}
					>
						<p
							style={{
								position: 'absolute',
								right: -25,
								bottom: 15,
								margin: 0,
								width: 50,
								textAlign: 'center',
								fontSize: 10
							}}
						>
							{showHr}:00
						</p>
						<span
							style={{
								width: 1,
								height: 15,
								backgroundColor: '#dee2e6',
								position: 'absolute',
								right: 0,
								bottom: 0
							}}
						></span>
					</div>
				)
		}
		return items
	}

	const renderHeader = () => {
		return (
			<div
				className="row m-0"
				id="header"
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					zIndex: 200,
					width: '100%',
					backgroundColor: 'white'
				}}
			>
				<div
					style={{
						position: 'absolute',
						height: 20,
						width: '100%',
						backgroundColor: 'white',
						zIndex: 1000,
						top: -20,
						left: 0
					}}
				></div>
				<div
					className="col-2 p-1 border-top border-left border-bottom"
					style={{ zIndex: 200, backgroundColor: 'white' }}
				></div>
				<div className="col-9 p-0 border">
					<div
						ref={ref => {
							if (ref !== null) {
								setTimelineRef(ref.clientWidth)
								setContainerW(ref.clientWidth)
							}
						}}
						style={{
							width: '100%',
							height: 40,
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<div
							style={{
								height: '100%',
								width:
									containerW % 2 !== 0 ? containerW - 1 : containerW,
								display: 'flex',
								flexDirection: 'row'
							}}
						>
							{renderHours()}
						</div>
					</div>
				</div>
				<div
					className="col-1 p-1 border-top border-right d-flex justify-content-center border-bottom"
					style={{ zIndex: 200, backgroundColor: 'white' }}
				>
					Total
				</div>
			</div>
		)
	}

	const saveSchedule = async values => {
		await firestore
			.collection('places')
			.doc(place.id)
			.update({
				schedule: values
			})
	}

	const renderSlider = (id, dayIndex, length) => {
		let error = typeof place.schedule[`${id}_${dayIndex}`] === 'undefined'
		if (!error && place.schedule[`${id}_${dayIndex}`][0] === -1) error = true

		if (error)
			return (
				<div
					style={{
						height: '100%',
						width: pixelsByHour * length,
						paddingLeft: pixelsByHour
					}}
				>
					<div
						style={{
							backgroundColor: '#EDEDED',
							width: '100%',
							height: '100%'
						}}
					></div>
				</div>
			)
		return (
			<Range
				values={sliderStatus[`${id}_${dayIndex}`].map(v => {
					if (opensAt === 0) return 0
					if (v < 0) return 0
					let op = opensAt[0] + opensAt[1] / 60
					let res = (v - op) * 2
					return res
				})}
				step={1}
				min={0}
				max={hourDiff > 0 ? (length - 1) * 2 : 9999}
				onChange={values => {
					let newObj = sliderStatus
					let op = opensAt[0] + opensAt[1] / 60
					let val_0 = values[0] / 2 + op
					let val_1 = values[1] / 2 + op
					newObj[`${id}_${dayIndex}`] = [val_0, val_1]
					setSliderStatus({ ...sliderStatus, ...newObj })
				}}
				onFinalChange={values => {
					let newObj = sliderStatus
					let op = opensAt[0] + opensAt[1] / 60
					let val_0 = values[0] / 2 + op
					let val_1 = values[1] / 2 + op
					newObj[`${id}_${dayIndex}`] = [val_0, val_1]
					saveSchedule(newObj)
				}}
				renderTrack={({ props, children }) => (
					<div
						onMouseDown={props.onMouseDown}
						onTouchStart={props.onTouchStart}
						style={{
							...props.style,
							height: '100%',
							display: 'flex',
							width: pixelsByHour * length,
							paddingLeft: pixelsByHour
						}}
					>
						<div
							ref={props.ref}
							style={{
								height: '100%',
								width: '100%',
								borderRadius: 0,
								background: getTrackBackground({
									values: sliderStatus[`${id}_${dayIndex}`].map(v => {
										if (v === -1) return 0
										if (opensAt === 0) return 0
										if (v < 0) return 0
										let op = opensAt[0] + opensAt[1] / 60
										let res = (v - op) * 2
										return res
									}),
									colors: ['#EDEDED', '#ABABAB', '#EDEDED'],
									min: 0,
									max: hourDiff > 0 ? (length - 1) * 2 : 48
								}),
								alignSelf: 'center'
							}}
						>
							{children}
						</div>
					</div>
				)}
				renderThumb={({ props, isDragged }) => {
					let op = opensAt[0] + opensAt[1] / 60
					let hour = ''
					let index = props.key
					let hr = Math.floor(sliderStatus[`${id}_${dayIndex}`][index])
					let mn = (sliderStatus[`${id}_${dayIndex}`][index] - hr) * 60

					hour += `${hr < 10 ? `0${hr}` : hr}:${mn < 10 ? `0${mn}` : mn}`
					return (
						<div
							{...props}
							style={{
								...props.style,
								height: '100%',
								width: 10,
								backgroundColor: '#919191',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 0,
								zIndex: 9999
							}}
						>
							{isDragged && (
								<div
									className="bg-primary"
									style={{
										width: 50,
										position: 'absolute',
										top: '-28px',
										borderRadius: '4px',
										justifyContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										paddingTop: 4,
										paddingBottom: 4
									}}
								>
									<p
										style={{
											color: '#fff',
											fontWeight: 'bold',
											fontSize: '12px',
											margin: 0
										}}
									>
										{hour}
									</p>
								</div>
							)}
							<div
								style={{
									height: 13,
									width: 1,
									backgroundColor: 'white'
								}}
							/>
						</div>
					)
				}}
			/>
		)
	}

	const renderDays = () => {
		let items = []
		let dayNames = [
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday',
			'Sunday'
		]
		for (let y = 0; y < 7; y++) {
			items.push(
				<div className="row m-0">
					<div
						className="col-2 p-1 pl-3 border-left border-bottom"
						style={{
							position: 'relative',
							zIndex: 150,
							backgroundColor: '#f7f7f7'
						}}
					>
						{dayNames[y]}
						<button
							style={{
								backgroundColor: 'transparent',
								outline: 'none',
								border: 'none',
								position: 'absolute',
								right: 10
							}}
							onClick={() => {
								if (hiddenDays.indexOf(y) === -1)
									setHiddenDays([...hiddenDays, y])
								else setHiddenDays(hiddenDays.filter(hd => hd !== y))
							}}
						>
							<i
								className={`icon-hardware-keyboard-arrow-${
									hiddenDays.indexOf(y) === -1 ? 'up' : 'down'
								}`}
							></i>
						</button>
					</div>
					<div
						className="col-9 pr-3 border-left border-right border-bottom"
						style={{ backgroundColor: '#f7f7f7' }}
					></div>
					<div
						className="col-1 p-1 border-right d-flex justify-content-center border-bottom"
						style={{
							position: 'relative',
							zIndex: 150,
							backgroundColor: '#f7f7f7'
						}}
					></div>
				</div>
			)
			if (hiddenDays.indexOf(y) === -1)
				place.employees.forEach(emp => {
					let length = hourDiff + 1
					let day = y
					let schedule_day = place.schedule[`${emp.id}_${y}`]
					let worked_hours = 0
					if (typeof schedule_day !== 'undefined')
						worked_hours = schedule_day[1] - schedule_day[0]
					if (!onlyBusy || (onlyBusy && worked_hours > 0))
						if (
							selectedCategory === 'all' ||
							emp.category === selectedCategory
						)
							items.push(
								<div className="row m-0">
									<div
										className="col-2 border-left border-bottom"
										style={{
											padding: 10,
											paddingLeft: 30,
											backgroundColor: 'white',
											zIndex: 150,
											position: 'relative',
											display: 'flex',
											flexDirection: 'row'
										}}
									>
										<div
											style={{
												width: 20,
												height: 20,
												backgroundColor: '#d3d3d3',
												borderRadius: 100,
												marginRight: 10,
												backgroundPosition: 'center',
												backgroundSize: 'cover'
											}}
										></div>
										{emp.name}
										<input
											style={{
												position: 'absolute',
												right: 20
											}}
											type="checkbox"
											name={`${emp.id}_${y}`}
											id={`${emp.id}_${y}`}
											checked={
												typeof place.schedule[`${emp.id}_${y}`] ===
												'undefined'
													? false
													: place.schedule[`${emp.id}_${y}`][0] !==
													  -1
											}
											onChange={e => {
												if (!e.target.checked) {
													firestore
														.collection('places')
														.doc(place.id)
														.update({
															schedule: {
																...place.schedule,
																[`${emp.id}_${y}`]: [-1, -1]
															}
														})
												} else {
													firestore
														.collection('places')
														.doc(place.id)
														.update({
															schedule: {
																...place.schedule,
																[`${emp.id}_${y}`]: [
																	opensAt[0] + opensAt[1] / 60,
																	opensAt[0] +
																		opensAt[1] / 60 +
																		1
																]
															}
														})
												}
											}}
										/>
									</div>
									<div
										className="col-9 p-0 m-0 border-left border-right border-bottom"
										style={{
											backgroundColor: 'white'
										}}
									>
										<div
											style={{
												width: '100%',
												height: '100%',
												padding: 0,
												margin: 0
											}}
										>
											<div
												style={{
													width: containerW - 1,
													height: '100%',
													padding: 0,
													margin: 0
												}}
											>
												{renderSlider(emp.id, y, length)}
											</div>
										</div>
									</div>
									<div
										className="col-1 p-1 border-right d-flex justify-content-center border-bottom"
										style={{
											backgroundColor: 'white',
											zIndex: 150
										}}
									>
										{worked_hours}
									</div>
								</div>
							)
				})
		}
		return items
	}

	if (place.schedule_config === null) {
		create_alert('First you have to configure your schedule', 'success')
		return <Redirect to="/employees/schedule/config"></Redirect>
	}

	if (Object.keys(place.schedule).length === 0) {
		return (
			<div
				style={{
					position: 'absolute',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
					top: 0,
					left: 0,
					zIndex: -1
				}}
			>
				<div className="spinner-border text-primary mb-3" role="status">
					<span className="sr-only">Loading...</span>
				</div>
				<h5>Creating your schedule</h5>
			</div>
		)
	}

	return (
		<div className="section p-3">
			<button
				className="btn btn-secondary m-1"
				onClick={() => {
					firestore
						.collection('places')
						.doc(place.id)
						.update({
							schedule: {}
						})
				}}
			>
				Shuffle schedule
			</button>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row'
				}}
				className="m-1"
			>
				<input
					type="checkbox"
					value={!!onlyBusy}
					onChange={e => setOnlyBusy(!!e.target.checked)}
				/>
				<p>Show only busy employees</p>
			</div>
			<select
				defaultValue="all"
				onChange={e => {
					setSelectedCategory(e.target.value)
				}}
				className="form-control"
				style={{
					marginBottom: 25
				}}
			>
				{renderCategoriesAsOptions()}
			</select>
			<div
				className="cont"
				style={{
					position: 'relative',
					paddingTop: 42
				}}
				ref={ref => {
					if (ref !== null) setHeaderPos(ref)
				}}
				onMouseMove={e =>
					(document.getElementById('caret').style.left = `${e.clientX -
						17}px`)
				}
				onMouseLeave={() =>
					(document.getElementById('caret').style.left = '-500px')
				}
			>
				<div
					id="caret"
					style={{
						position: 'absolute',
						top: 0,
						height: '100%',
						width: 1,
						backgroundColor: 'red',
						zIndex: 100
					}}
				/>

				{renderHeader()}
				{renderDays()}
			</div>
		</div>
	)
}

export default connect(
	state => ({
		place: state.place
	}),
	{ create_alert }
)(Schedule)

import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import { Input, Loading } from '../../../../components'
import { create_alert } from '../../../../store/actions'
import { firestore } from '../../../../config/firebase'
import download from 'downloadjs'

function Print({ language, place, create_alert, user }) {
	const [submitting, setSubmitting] = useState(false)
	const [currentBackupState, setCurrentBackupState] = useState(null)

	useEffect(() => {
		setCurrentBackupState(place.backupStatus || false)
	}, [place])

	const validate = (v) => {}

	const onSubmit = (v) => {
		console.log('SUBMITTED')
	}

	const downloadData = async () => {
		setSubmitting('Espera mientras borramos los datos')
		let active_orders = place.orders || []
		let archive_orders = await firestore
			.doc(`/places/${place.id}/archive/orders`)
			.get()
		if (!archive_orders.exists) archive_orders = []
		else {
			archive_orders = archive_orders.data()
			archive_orders = archive_orders.orders
		}

		let invoices = await firestore
			.collection(`/places/${place.id}/invoices`)
			.get()
		if (invoices.empty) invoices = {}
		else {
			let dt = {}
			invoices.docs.forEach((d) => {
				dt[d.id] = d.data()
			})
			invoices = dt
		}

		let stats = await firestore.doc(`/places/${place.id}/stats/sales`).get()
		if (!stats.exists)
			stats = {
				events: {},
				payment_methods: {},
				products: {},
				reservations: {},
				sales: {},
			}
		else {
			stats = stats.data()
			stats = {
				events: {},
				payment_methods: {},
				products: {},
				reservations: {},
				sales: {},
				...stats,
			}
		}

		let data = {
			active_orders,
			archive_orders,
			invoices,
			stats,
		}

		let dataStr =
			'data:text/json;charset=utf-8,' +
			encodeURIComponent(JSON.stringify(data))
		download(dataStr, `datos_backup.json`)

		// Remove firebase data
		await firestore.doc(`/places/${place.id}`).update({
			orders: [],
			backupStatus: true,
		})

		await firestore.doc(`/places/${place.id}/archive/orders`).delete()

		for (let i = 0; i < Object.keys(data.invoices).length; i++) {
			const item = data.invoices[Object.keys(data.invoices)[i]]

			await firestore
				.doc(`/places/${place.id}/invoices/${item.invoiceID}`)
				.delete()
		}
		await firestore.doc(`/places/${place.id}/stats/sales`).delete()

		setSubmitting(false)
	}

	const uploadData = async (data) => {
		try {
			setSubmitting('Espera mientras subimos los datos')

			let archive_orders = await firestore
				.doc(`/places/${place.id}/archive/orders`)
				.get()
			let action = 'update'
			if (!archive_orders.exists) {
				archive_orders = []
				action = 'set'
			} else {
				archive_orders = archive_orders.data()
				archive_orders = archive_orders.orders
			}

			await firestore.doc(`/places/${place.id}/archive/orders`)[action]({
				orders: [...archive_orders, ...data.archive_orders],
			})

			for (let i = 0; i < Object.keys(data.invoices).length; i++) {
				const item = data.invoices[Object.keys(data.invoices)[i]]

				await firestore
					.doc(`/places/${place.id}/invoices/${item.invoiceID}`)
					.set(item)
			}

			await firestore.doc(`/places/${place.id}/stats/sales`).set(data.stats)

			await firestore.doc(`/places/${place.id}`).update({
				orders: [...place.orders, ...data.active_orders],
				backupStatus: false,
			})

			setSubmitting(false)
		} catch (e) {
			console.log('Error subiendo datos', e)
			create_alert('Error subiendo los datos', 'danger')
			setSubmitting(false)
		}
	}

	const onSelectFile = (e) => {
		try {
			if (e.target.files && e.target.files.length > 0) {
				const reader = new FileReader()
				reader.addEventListener('load', () =>
					uploadData(JSON.parse(reader.result))
				)
				reader.readAsText(e.target.files[0])
			}
		} catch (e) {
			create_alert('Error con el archivo', 'danger')
		}
	}

	if (currentBackupState === null) return <Loading />

	return (
		<>
			{submitting !== false && (
				<div className="modal-form modal-form-md show">
					<div>
						<form className="form-ui">
							<h1>{submitting}</h1>
						</form>
					</div>
				</div>
			)}
			<div className="login-ui login-ui-lg">
				<div>
					{place.id !== null && (
						<Formik initialValues={{}} onSubmit={onSubmit}>
							{({ errors, touched, setFieldValue, values }) => (
								<Form className="form-ui">
									<h1>Seguridad</h1>
									{currentBackupState === true && (
										<>
											<div className="alert alert-primary">
												Resube los datos que habias descargado.
											</div>
											<hr />
											<input
												type="file"
												onChange={onSelectFile}
												id="featImageInput"
											/>
											<footer>
												<label
													htmlFor="featImageInput"
													className="button button-primary"
												>
													Subir Datos
												</label>
											</footer>
										</>
									)}
									{currentBackupState === false && (
										<>
											<div className="alert alert-warning">
												Descarga tus datos de facturación, y
												borralos de la nube, puedes volver a
												subirlos cuando quieras.
											</div>
											<div className="alert alert-warning">
												Si quieres borrar otro tipo de datos, o
												tienes dudas puedes contactarnos por el chat
												en la esquina inferior derecha.
											</div>
											<hr />
											<footer>
												<button
													onClick={() => downloadData()}
													type="button"
													className="button button-danger"
												>
													Descargar y Borrar datos
												</button>
											</footer>
										</>
									)}
									{/* <Input
									label="Nombre"
									submitting={submitting}
									type="text"
									name="name"
									errors={errors}
									touched={touched}
								/>
								<footer>
									<button
										disabled={submitting}
										type="submit"
										className="button button-primary"
									>
										{submitting && (
											<div role="status">
												<span>Cargando...</span>
											</div>
										)}
										{!submitting && 'Guardar'}
									</button>
								</footer> */}
								</Form>
							)}
						</Formik>
					)}
				</div>
			</div>
		</>
	)
}

export default connect(
	(state) => ({
		language: state.language.dictionary,
		place: state.place,
		user: state.user,
	}),
	{ create_alert }
)(Print)

import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Input } from '../../../../components'
import { create_alert } from '../../../../store/actions'
import Map from '../../../PlaceSetup/Map'
import { firestore, functions, storage } from '../../../../config/firebase'

function General({ language, place, create_alert }) {
	const [submitting, setSubmitting] = useState(false)
	const [displayImage, setDisplayImage] = useState(null)

	useEffect(() => {
		if (place.smtp) {
			if (place.smtp.image) setDisplayImage(place.smtp.image)
		}
	}, [])

	const validate = (values) => {
		let errors = {}
		if (values.host.length < 4) errors.host = 'Completa el host'
		if (values.port.length < 2) errors.port = 'Completa el puerto'
		if (values.email.length < 6) errors.email = 'Completa el email'
		if (values.password.length < 2) errors.password = 'Completa la contraseña'
		return errors
	}

	const onSelectFile = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				setDisplayImage(reader.result)
				onSubmitImage(reader.result)
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}

	const onSubmitImage = async (v) => {
		setSubmitting(true)
		let placeRef = firestore.doc(`/places/${place.id}`)

		let storageRef = storage.ref()
		const listImageRef = storageRef.child(`/mails/${place.id}.jpg`)

		await listImageRef.putString(v, 'data_url')
		let dwnUrl = await listImageRef.getDownloadURL()

		firestore
			.runTransaction(async (transaction) => {
				let latestPlace = await transaction.get(placeRef)
				latestPlace = latestPlace.data()

				let prevSmtpData = latestPlace.smtp || {}

				transaction.update(placeRef, {
					...latestPlace,
					smtp: {
						...prevSmtpData,
						image: dwnUrl,
					},
				})
			})
			.then(() => {
				create_alert('Imagen actualizada', 'success')
				setSubmitting(false)
			})
			.catch((err) => {
				create_alert('Error #3', 'danger')
				setSubmitting(false)
			})
	}

	const onSubmit = (v) => {
		setSubmitting(true)
		let placeRef = firestore.doc(`/places/${place.id}`)
		firestore
			.runTransaction(async (transaction) => {
				let latestPlace = await transaction.get(placeRef)
				latestPlace = latestPlace.data()
				let prevSmtpData = latestPlace.smtp || {}

				transaction.update(placeRef, {
					...latestPlace,
					smtp: {
						...prevSmtpData,
						...v,
						secure: v.secure === 'true',
					},
				})
			})
			.then(() => {
				create_alert('Datos actualizados', 'success')
				setSubmitting(false)
			})
			.catch((err) => {
				create_alert(err.message, 'danger')
				setSubmitting(false)
			})
	}

	return (
		<div className="login-ui login-ui-lg">
			<div>
				<Formik
					initialValues={{
						host:
							typeof place.smtp !== 'undefined' ? place.smtp.host : '',
						port:
							typeof place.smtp !== 'undefined' ? place.smtp.port : '',
						email:
							typeof place.smtp !== 'undefined' ? place.smtp.email : '',
						secure:
							typeof place.smtp !== 'undefined'
								? place.smtp.secure
								: 'true',
						password:
							typeof place.smtp !== 'undefined'
								? place.smtp.password
								: '',
					}}
					validate={validate}
					onSubmit={onSubmit}
				>
					{({ errors, touched, setFieldValue, values }) => (
						<Form className="form-ui">
							<h1>Logo para el correo</h1>
							<div className="alert alert-blue">
								Recomendamos una imagen de 300px ancho y 70px alto,
								monocromatica con transparencia
							</div>
							{displayImage !== null && (
								<img
									style={{
										padding: 50,
									}}
									src={displayImage}
								/>
							)}
							<div className="adder adder-single">
								<label
									htmlFor="listImageInput"
									className="button button-light"
								>
									Subir Imagen
								</label>
								<input
									disabled={submitting}
									type="file"
									onChange={onSelectFile}
									id="listImageInput"
								/>
							</div>
							<hr />
							<h1>Datos de SMTP</h1>
							<div className="alert alert-blue">
								Datos para enviar los correos de marketing y
								notificaciones a nombre de tu empresa
							</div>
							<Input
								label="Host"
								submitting={submitting}
								type="text"
								name="host"
								errors={errors}
								touched={touched}
							/>
							<Input
								label="Port"
								submitting={submitting}
								type="text"
								name="port"
								errors={errors}
								touched={touched}
							/>
							<Input
								label="Secure"
								submitting={submitting}
								type="text"
								name="secure"
								errors={errors}
								touched={touched}
							/>
							<Input
								label="Email"
								submitting={submitting}
								type="text"
								name="email"
								errors={errors}
								touched={touched}
							/>
							<Input
								label="Password"
								submitting={submitting}
								type="text"
								name="password"
								errors={errors}
								touched={touched}
							/>

							<footer>
								<button
									disabled={submitting}
									type="submit"
									className="button button-primary"
								>
									{submitting && (
										<div role="status">
											<span>{language.loading}...</span>
										</div>
									)}
									{!submitting && language.save}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	)
}

export default connect(
	(state) => ({
		language: state.language.dictionary,
		place: state.place,
	}),
	{ create_alert }
)(General)

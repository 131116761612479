import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Input } from '../../../../components'
import { create_alert } from '../../../../store/actions'
import Map from '../../../PlaceSetup/Map'
import { firestore } from '../../../../config/firebase'

function General({ language, place, create_alert }) {
	const [submitting, setSubmitting] = useState(false)

	const onValidate = (values) => {
		let cleanStringRegex = /[\[|&;$%@"<>()+,\]]/g
		let numericRegex = /^\d+$/
		const errors = {}
		if (!values.name || values.name.length < 3) {
			errors.name = language.enter_a_valid_value
		}
		if (cleanStringRegex.test(values.name)) {
			errors.name = language.invalid_chars
		}
		if (!values.address || values.address.length < 3) {
			errors.address = language.enter_a_valid_value
		}
		if (cleanStringRegex.test(values.address)) {
			errors.address = language.invalid_chars
		}
		if (!values.dni || values.dni.length < 3) {
			errors.dni = language.enter_a_valid_value
		}
		if (cleanStringRegex.test(values.dni)) {
			errors.dni = language.invalid_chars
		}
		if (!values.province || values.province.length < 3) {
			errors.province = language.enter_a_valid_value
		}
		if (cleanStringRegex.test(values.province)) {
			errors.province = language.invalid_chars
		}
		if (!values.city || values.city.length < 3) {
			errors.city = language.enter_a_valid_value
		}
		if (cleanStringRegex.test(values.city)) {
			errors.city = language.invalid_chars
		}
		if (!values.postal_code || values.postal_code.length < 3) {
			errors.postal_code = language.enter_a_valid_value
		}
		if (!numericRegex.test(values.postal_code)) {
			errors.postal_code = language.invalid_chars
		}
		return errors
	}

	const onSubmit = (v) => {
		setSubmitting(true)
		let placeRef = firestore.doc(`/places/${place.id}`)
		firestore
			.runTransaction(async (transaction) => {
				let latestPlace = await transaction.get(placeRef)
				latestPlace = latestPlace.data()
				transaction.update(placeRef, {
					...latestPlace,
					invoiceNumb: v.invoiceNumb,
					billing: v,
				})
			})
			.then(() => {
				create_alert('Datos actualizados', 'success')
				setSubmitting(false)
			})
			.catch((err) => {
				create_alert(err.message, 'danger')
				setSubmitting(false)
			})
	}

	const getPlaceInitialValues = () => {
		return {
			name:
				typeof place.billing.name !== 'undefined' ? place.billing.name : '',
			dni: typeof place.billing.dni !== 'undefined' ? place.billing.dni : '',
			address:
				typeof place.billing.address !== 'undefined'
					? place.billing.address
					: '',
			postal_code:
				typeof place.billing.postal_code !== 'undefined'
					? place.billing.postal_code
					: '',
			city:
				typeof place.billing.city !== 'undefined' ? place.billing.city : '',
			province:
				typeof place.billing.province !== 'undefined'
					? place.billing.province
					: '',
			invoiceNumb: place.invoiceNumb || 0,
		}
	}

	return (
		<div className="login-ui login-ui-lg">
			<div>
				{place.id !== null && (
					<Formik
						initialValues={getPlaceInitialValues()}
						validate={onValidate}
						onSubmit={onSubmit}
					>
						{({ errors, touched, setFieldValue, values }) => (
							<Form className="form-ui">
								<h1>Datos de facturación</h1>
								<Input
									label="Nombre"
									submitting={submitting}
									type="text"
									name="name"
									errors={errors}
									touched={touched}
								/>
								<Input
									label="DNI o CIF"
									submitting={submitting}
									type="text"
									name="dni"
									errors={errors}
									touched={touched}
								/>
								<Input
									label="Dirección"
									submitting={submitting}
									type="text"
									name="address"
									errors={errors}
									touched={touched}
								/>
								<Input
									label="Provincia"
									submitting={submitting}
									type="text"
									name="province"
									errors={errors}
									touched={touched}
								/>
								<Input
									label="Ciudad"
									submitting={submitting}
									type="text"
									name="city"
									errors={errors}
									touched={touched}
								/>
								<Input
									label="Código postal"
									submitting={submitting}
									type="text"
									name="postal_code"
									errors={errors}
									touched={touched}
								/>
								<hr />
								<Input
									rowcolleft="4"
									rowcolright="8"
									label="Numero de factura actual"
									submitting={submitting}
									type="number"
									name="invoiceNumb"
									errors={errors}
									touched={touched}
								/>

								<footer>
									<button
										disabled={submitting}
										type="submit"
										className="button button-primary"
									>
										{submitting && (
											<div role="status">
												<span>{language.loading}...</span>
											</div>
										)}
										{!submitting && language.save}
									</button>
								</footer>
							</Form>
						)}
					</Formik>
				)}
			</div>
		</div>
	)
}

export default connect(
	(state) => ({
		language: state.language.dictionary,
		place: state.place,
	}),
	{ create_alert }
)(General)

const shuffle = a => {
	for (let i = a.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1))
		;[a[i], a[j]] = [a[j], a[i]]
	}
	return a
}

const getMinEmp = (emps, day) => {
	let min = 999999999999
	let min_index = -1

	emps = shuffle(emps)

	emps.forEach((em, i) => {
		if (em.schedule[day].length === 0) {
			if (em.hours < min) {
				min = em
				min_index = i
			}
		}
	})

	return min_index
}

export const distributeHours = (employees, sch_conf) => {
	let categories = []
	let emps = employees
	let final_emps = []

	// Get the categories
	sch_conf = JSON.parse(sch_conf)
	sch_conf.forEach((sch, a) => {
		sch.shifts.forEach((sh, b) => {
			let id = `${Math.floor(Math.random() * 1000000000)}${a}${b}`
			id = parseInt(id)
			sch_conf[a].shifts[b] = {
				...sch_conf[a].shifts[b],
				id
			}
			Object.keys(sh.employees).forEach(key => {
				if (categories.indexOf(key) === -1) categories.push(key)
			})
		})
	})

	// Run through every category
	categories.forEach(cat => {
		let ems = emps.filter(em => em.category === cat)
		let ems_hrs = ems.map(em => em.hours)

		// Find all the empty positions
		let cat_shifts = []
		sch_conf.forEach((gro, z) => {
			gro.days.forEach((day, j) => {
				gro.shifts.forEach((sh, k) => {
					let duration = sh.exit[0] - sh.enter[0]
					duration += sh.exit[1] / 60 + sh.enter[1] / 60
					if (Object.keys(sh.employees).indexOf(cat) !== -1) {
						for (let i = 0; i < sh.employees[cat]; i++) {
							cat_shifts.push({
								id: parseInt(`${sh.id}${day}${i}`),
								duration,
								week_day: day,
								work_group: z,
								day_index: j,
								_shift: k
							})
						}
					}
				})
			})
		})

		// Group the shifts by weekday
		let week_shifts = [[], [], [], [], [], [], []]
		cat_shifts.forEach(sh => {
			week_shifts[sh.week_day].push(sh)
		})

		let done_weekdays = [0, 1, 2, 3, 4, 5, 6]

		while (done_weekdays.length !== 0) {
			ems_hrs = ems.map(em => em.hours)
			let day_index = Math.floor(Math.random() * done_weekdays.length)
			let day = done_weekdays[day_index]
			if (week_shifts[day].length === 0) {
				done_weekdays = done_weekdays.filter((w, z) => z !== day_index)
				// BREAK
			} else {
				let shift = week_shifts[day][0]
				let emp = getMinEmp(ems, day)

				if (emp === -1) {
					console.error('Not enough employees')
					break
				}

				ems[emp].schedule[day] = [shift]
				ems[emp].hours = ems[emp].hours + shift.duration
				week_shifts[day].shift()
				if (week_shifts[day].length === 0) {
					done_weekdays = done_weekdays.filter((w, z) => z !== day_index)
				}
			}
		}

		final_emps = [...final_emps, ...ems]
	})

	return final_emps
}

export const getSchedule = (employees, sch_conf) => {
	sch_conf = JSON.parse(sch_conf)
	let sch = {}
	employees.forEach(emp => {
		emp.schedule.forEach((day, i) => {
			if (day.length > 0) {
				let shift = sch_conf[day[0].work_group].shifts[day[0]._shift]
				if (typeof shift !== 'undefined') {
					let enter = shift.enter[0]
					enter += shift.enter[1] / 60
					let exit = shift.exit[0]
					exit += shift.exit[1] / 60
					sch[`${emp.id}_${i}`] = [enter, exit]
				}
			}
		})
	})
	return sch
}

import React from 'react'

function Pricing({ children, values }) {
	return (
		<table class="table-subscriptions">
			<thead>
				<tr>
					<th scope="col"></th>
					<th
						className={`${values.type === 'admin_free' ? 'active' : ''}`}
						scope="col"
					>
						Gratis
					</th>
					{/* <th
						className={`${
							values.type === 'admin_payasyougo' ? 'active' : ''
						}`}
						scope="col"
					>
						Pago a medida
					</th> */}
					<th
						className={`${
							values.type === 'admin_premium' ? 'active' : ''
						}`}
						scope="col"
					>
						Premium
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<th scope="row">Lugares</th>
					<td
						className={`${values.type === 'admin_free' ? 'active' : ''}`}
					>
						Hasta <em>5</em> <>Restaurantes</>
					</td>
					{/* <td
						className={`${
							values.type === 'admin_payasyougo' ? 'active' : ''
						}`}
					>
						Hasta <em>10</em> <>Lugares </>
						<small>
							(Restaurantes, Alimentación, Discos y pubs, Belleza)
						</small>
					</td> */}
					<td
						className={`${
							values.type === 'admin_premium' ? 'active' : ''
						}`}
					>
						<em>1</em> <>Lugar</> por{' '}
						<span className="price">
							<pre>50</pre>
							<i className="mi">euro_symbol</i>
						</span>{' '}
						+{' '}
						<span className="price">
							<pre>30</pre>
							<i className="mi">euro_symbol</i>
						</span>{' '}
						por cada Lugar extra
					</td>
				</tr>
				<tr>
					<th scope="row">Productos</th>
					<td
						className={`${values.type === 'admin_free' ? 'active' : ''}`}
					>
						Hasta <em>70</em> <>Productos </>
						por restaurante
					</td>
					{/* <td
						className={`${
							values.type === 'admin_payasyougo' ? 'active' : ''
						}`}
					>
						Hasta <em>100</em> <>Productos </>
						por lugar
					</td> */}
					<td
						className={`${
							values.type === 'admin_premium' ? 'active' : ''
						}`}
					>
						Productos ilimitados
					</td>
				</tr>
				<tr>
					<th scope="row">Pedidos</th>
					<td
						className={`${values.type === 'admin_free' ? 'active' : ''}`}
					>
						No
					</td>
					{/* <td
						className={`${
							values.type === 'admin_payasyougo' ? 'active' : ''
						}`}
					>
						<em>10%</em> del valor del pedido
					</td> */}
					<td
						className={`${
							values.type === 'admin_premium' ? 'active' : ''
						}`}
					>
						<p>
							Hasta <em>100</em> pedidos
							<span className="price">
								<pre>30</pre>
								<i className="mi">euro_symbol</i>
							</span>
						</p>
						<p>
							Hasta <em>500</em> pedidos
							<span className="price">
								<pre>50</pre>
								<i className="mi">euro_symbol</i>
							</span>{' '}
						</p>
						<p>
							Hasta <em>1000</em> pedidos
							<span className="price">
								<pre>75</pre>
								<i className="mi">euro_symbol</i>
							</span>
						</p>
						<p>
							Mas de <em>1000</em> pedidos
							<span className="price">
								<pre>0.1</pre>
								<i className="mi">euro_symbol</i>
							</span>{' '}
							/ Pedido extra
						</p>
					</td>
				</tr>
				<tr>
					<th scope="row">Reservas</th>
					<td
						className={`${values.type === 'admin_free' ? 'active' : ''}`}
					>
						No
					</td>
					{/* <td
						className={`${
							values.type === 'admin_payasyougo' ? 'active' : ''
						}`}
					>
						<span className="price">
							<pre>1</pre>
							<i className="mi">euro_symbol</i>
						</span>{' '}
						/ Reserva
						<br />
						<small>
							* En lugares de belleza se cada reserva equivale a un
							pedido
						</small>
					</td> */}
					<td
						className={`${
							values.type === 'admin_premium' ? 'active' : ''
						}`}
					>
						Ilimitadas
						<br />
						<small>
							* En lugares de belleza se cada reserva equivale a un
							pedido
						</small>
					</td>
				</tr>
				<tr>
					<th scope="row">Soporte</th>
					<td
						className={`${values.type === 'admin_free' ? 'active' : ''}`}
					>
						No
					</td>
					{/* <td
						className={`${
							values.type === 'admin_payasyougo' ? 'active' : ''
						}`}
					>
						Basico
					</td> */}
					<td
						className={`${
							values.type === 'admin_premium' ? 'active' : ''
						}`}
					>
						Preferencial
					</td>
				</tr>

				<tr>
					<th scope="row">Estadísticas</th>
					<td
						className={`${values.type === 'admin_free' ? 'active' : ''}`}
					>
						No
					</td>
					{/* <td
						className={`${
							values.type === 'admin_payasyougo' ? 'active' : ''
						}`}
					>
						Si
					</td> */}
					<td
						className={`${
							values.type === 'admin_premium' ? 'active' : ''
						}`}
					>
						Si
					</td>
				</tr>
				<tr>
					<th scope="row">Eventos</th>
					<td
						className={`${values.type === 'admin_free' ? 'active' : ''}`}
					>
						No
					</td>
					{/* <td
						className={`${
							values.type === 'admin_payasyougo' ? 'active' : ''
						}`}
					>
						Si
					</td> */}
					<td
						className={`${
							values.type === 'admin_premium' ? 'active' : ''
						}`}
					>
						Si
					</td>
				</tr>
				<tr>
					<th scope="row">Gestión de mesas</th>
					<td
						className={`${values.type === 'admin_free' ? 'active' : ''}`}
					>
						No
					</td>
					{/* <td
						className={`${
							values.type === 'admin_payasyougo' ? 'active' : ''
						}`}
					>
						No
					</td> */}
					<td
						className={`${
							values.type === 'admin_premium' ? 'active' : ''
						}`}
					>
						Si
					</td>
				</tr>
				<tr>
					<th scope="row">Cola virtual</th>
					<td
						className={`${values.type === 'admin_free' ? 'active' : ''}`}
					>
						No
					</td>
					{/* <td
						className={`${
							values.type === 'admin_payasyougo' ? 'active' : ''
						}`}
					>
						No
					</td> */}
					<td
						className={`${
							values.type === 'admin_premium' ? 'active' : ''
						}`}
					>
						Si
					</td>
				</tr>
				{children}
			</tbody>
		</table>
	)
}

export default Pricing

import React, { useEffect, useState, useRef } from 'react'
import FlipMove from 'react-flip-move'
import { connect } from 'react-redux'
import { Loading } from '../../../components'
import Select from 'react-select'
import Chrono from '../../../components/Chrono'
import { firestore, functions, auth } from '../../../config/firebase'
import { create_alert, update_place } from '../../../store/actions'
import { isMobile } from 'react-device-detect'

import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'
import { useCookies } from 'react-cookie'

const months = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre',
]

function Accepted({
	place,
	categories,
	tables,
	create_alert,
	language,
	topbar,
	user,
	update_place,
}) {
	const [selectedCategory, setSelectedCategory] = useState('all')
	const [selectedTable, setSelectedTable] = useState('all')
	const [submittingOrder, setSubmittingOrder] = useState(null)
	const [allTables, setAllTables] = useState([])
	const [printInvoice, setPrintInvoice] = useState(false)
	const [acceptedOrdersQty, setAcceptedOrdersQty] = useState(0)
	const [collapsed, setCollapsed] = useState(false)
	const invoiceRef = useRef(null)
	const [filter, setFilter] = useState('')
	const [filterTable, setFilterTable] = useState(null)
	const [filterFrom, setFilterFrom] = useState(null)
	const [cookies, setCookie, removeCookie] = useCookies(['print'])

	useEffect(() => {
		let alltables = []
		tables.floors.forEach((floor) => {
			floor.tables.forEach((table) => {
				alltables.push(table)
			})
		})
		setAllTables(alltables)

		let _orders = [...place.orders]
		let _ordersQty = 0
		_orders.forEach((order) => {
			let hasItemsInThisCat = false
			if (selectedCategory === 'all') hasItemsInThisCat = true
			if (selectedCategory !== 'all') {
				order.items.forEach((item) => {
					if (item.category === selectedCategory) hasItemsInThisCat = true
				})
			}
			if (order.confirmed !== true) hasItemsInThisCat = false

			if (order.table === filterTable && filterTable !== null) {
				hasItemsInThisCat = false
			}

			let source =
				order.invoiceID.split('_')[1] !== 'PICKUP' ? 'admin' : `app`

			if (source === filterFrom && filterFrom !== null) {
				hasItemsInThisCat = true
			} else if (filterFrom !== null) {
				hasItemsInThisCat = false
			}

			if (hasItemsInThisCat) {
				_ordersQty++
			}
		})

		setAcceptedOrdersQty(_ordersQty)
	}, [])

	useEffect(() => {
		let f = filter === '' ? 'all' : filter
		setSelectedCategory(f)
	}, [filter])

	const getOptions = () => {
		return categories.product.map((c) => ({
			label: c === '' ? 'Todos' : c.toUpperCase(),
			value: c,
		}))
	}
	const getOptionsTables = () => {
		let sorted_tables = allTables.sort((a, b) => {
			return parseInt(a.name) - parseInt(b.name)
		})
		return [
			{ label: 'Mesa', value: null },
			...sorted_tables.map((t) => ({
				label: t.name,
				value: t.id,
			})),
		]
	}

	const doInvoice = (order) => {
		if (!isMobile)
			htmlToImage
				.toPng(document.getElementById('PRINT_INVOICE'))
				.then(function (dataUrl) {
					download(dataUrl, `${place.id}-factura-${order.invoiceNumb}.png`)
					setPrintInvoice(false)
				})
	}

	const renderInvoice = () => {
		let total = 0
		let total_taxed = 0
		printInvoice.items.forEach((it) => {
			let qty = it.quantity || it.units
			let it_charge = it.price_type === 'units' ? it.price * qty : it.price
			total += it_charge
			total_taxed +=
				it.taxrate && it.taxrate > 0 ? it_charge * (it.taxrate / 100) : 0
			it.additions.forEach((add) => {
				add.items.forEach((_it) => {
					if (_it.quantity > 0) {
						total += _it.value * _it.quantity
						total_taxed +=
							it.taxrate && it.taxrate > 0
								? _it.value * _it.quantity * (it.taxrate / 100)
								: 0
					}
				})
			})
		})

		let billing_info = Object.keys(place.billing).map((info_key) => {
			return (
				<span>
					{language[info_key]}: {place.billing[info_key]}
				</span>
			)
		})

		let invoiceDate = new Date(printInvoice.date)
		let invoiceTime = `${`0${invoiceDate.getHours()}`.slice(
			-2
		)}:${`0${invoiceDate.getMinutes()}`.slice(-2)}`

		return (
			<div ref={invoiceRef} id="PRINT_INVOICE">
				<h3>{place.name}</h3>
				<strong>FACTURA #{printInvoice.invoiceNumb}</strong>
				<p className="billing">
					<span>
						Fecha: {invoiceDate.toLocaleDateString()} {invoiceTime}
					</span>
					{billing_info}
				</p>
				<table>
					<thead>
						<tr>
							<th>Producto</th>
							<th>Cantidad</th>
							<th>Imp.</th>
							<th>Valor</th>
						</tr>
					</thead>
					<tbody>
						{printInvoice.items.map((it) => {
							let additions = []
							let qty = it.units || it.quantity
							let it_total =
								it.price_type === 'units' ? it.price * qty : it.price
							let it_taxed =
								it.taxrate && it.taxrate > 0
									? it_total * (it.taxrate / 100)
									: 0
							it.additions.forEach((add) => {
								let hasAny = false
								add.items.forEach((_it) => {
									if (_it.quantity > 0) hasAny = true
								})
								if (hasAny)
									additions.push({
										...add,
										items: add.items.filter((a) => a.quantity > 0),
									})
							})

							let adds_rows = additions.map((add) => (
								<>
									<tr>
										<td colSpan="4">— {add.name}</td>
									</tr>
									{add.items.map((it_) => {
										let it__charge =
											typeof it_.quantity !== 'undefined'
												? it_.quantity * it_.value
												: it_.value
										let it__taxed =
											it.taxrate && it.taxrate > 0
												? it__charge * (it.taxrate / 100)
												: 0
										return (
											<tr>
												<td>—— {it_.name}</td>
												<td>{it_.quantity}</td>
												<td>{it__taxed.toFixed(2)} €</td>
												<td>{it__charge} €</td>
											</tr>
										)
									})}
								</>
							))

							return (
								<>
									<tr>
										<td>
											<strong>{it.name}</strong>
										</td>
										<td>
											{it.price_type === 'units'
												? `${qty} ${getUnitName(it.unit, qty)}`
												: 1}
										</td>
										<td>{it_taxed.toFixed(2)} €</td>
										<td>{it_total} €</td>
									</tr>
									{adds_rows}
								</>
							)
						})}
						<tr>
							<td colSpan="3">
								<strong>Total:</strong>
							</td>

							<td>
								<strong>{total} €</strong>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}

	const getUnitName = (u = 'u', quantity) => {
		switch (u) {
			case 'u':
				if (quantity > 1) return 'Unidades'
				return 'Unidad'
			case 'kg':
				if (quantity > 1) return 'Kilos'
				return 'Kilo'
			case 'lt':
				if (quantity > 1) return 'Litros'
				return 'Litro'
			case 'pt':
				if (quantity > 1) return 'Raciones'
				return 'Ración'
			case 'lb':
				if (quantity > 1) return 'Libras'
				return 'Libra'
		}
	}

	const renderAdditions = (item) => {
		if (typeof item.additions === 'undefined' || item.additions.length === 0)
			return <p></p>
		if (typeof item.additions !== 'undefined') {
			let adds_dom = item.additions.map((add) => {
				let no_adds = true
				let add_items = add.items.map((it) => {
					if (typeof it.quantity === 'undefined' || it.quantity <= 0)
						return null
					no_adds = false
					return (
						<li key={it.name}>
							<span>{it.name}</span>
						</li>
					)
				})

				if (no_adds) return <p key={`NO`}></p>
				return (
					<div className="additions" key={add.name}>
						<b>{add.name}</b>
						<ul>{add_items}</ul>
					</div>
				)
			})
			return <>{adds_dom}</>
		}
	}

	const changeOrderState = async (type, order) => {
		setSubmittingOrder(order.invoiceID)
		let placeRef = firestore.doc(`/places/${place.id}`)
		firestore
			.runTransaction(async (transaction) => {
				let latestPlaceData = await transaction.get(placeRef)
				latestPlaceData = latestPlaceData.data()

				if (type === 2) {
					let archiveDoc = firestore.doc(
						`/places/${place.id}/archive/orders`
					)
					let latestArchive = await transaction.get(archiveDoc)
					let latestArchiveOrders = []
					let action = 'set'
					if (
						latestArchive.exists &&
						typeof latestArchive.data().orders === 'object'
					) {
						latestArchiveOrders = latestArchive.data().orders
						action = 'update'
					}

					await transaction[action](archiveDoc, {
						orders: [...latestArchiveOrders, order],
					})
					await transaction.update(placeRef, {
						orders: latestPlaceData.orders.filter(
							(or) => or.invoiceID !== order.invoiceID
						),
					})
				} else {
					await transaction.update(placeRef, {
						orders: latestPlaceData.orders.map((or, i) => {
							if (or.invoiceID !== order.invoiceID)
								return latestPlaceData.orders[i]
							return {
								...latestPlaceData.orders[i],
								status: type,
							}
						}),
					})
				}
			})
			.then(async () => {
				const func = functions.httpsCallable('updateOrderStatus')
				const res = await func({
					order: { ...order, place_id: place.id },
					status: true,
					client_id: order.uid,
					stage: type,
				})
				if (res === false) throw { error: 'true' }
				if (type === 1) {
					const _func = functions.httpsCallable('sendNotification')
					const _res = await _func({
						content: 'Ya puedes reclamar tu pedido',
						client_id: order.uid,
					})
					if (_res === false) throw { error: 'true' }
				}
				return true
			})
			.then(() => {
				if (type === 1)
					create_alert(
						'Le hemos avisado a tu cliente que ya puede reclamar el producto',
						'success'
					)
				if (type === 2)
					create_alert('Pedido entregado con éxito', 'success')
				setSubmittingOrder(null)
			})
			.catch((e) => {
				create_alert('Error con el pedido', 'danger')
				setSubmittingOrder(null)
			})
	}

	const checkProduct = async (status, prod, order) => {
		let placeRef = firestore.doc(`/places/${place.id}`)
		let newState = status ? 2 : 0

		update_place({
			...place,
			orders: place.orders.map((ord) => {
				if (ord.invoiceId !== order.invoiceId) return ord
				return {
					...ord,
					items: ord.items.map((it) => {
						if (it.cartId !== prod.cartId) return it
						return {
							...it,
							state: newState,
						}
					}),
				}
			}),
		})

		await firestore.runTransaction(async (trans) => {
			let latestPlace = await trans.get(placeRef)
			latestPlace = latestPlace.data()

			trans.update(placeRef, {
				orders: latestPlace.orders.map((ord) => {
					if (ord.invoiceId !== order.invoiceId) return ord
					return {
						...ord,
						items: ord.items.map((it) => {
							if (it.cartId !== prod.cartId) return it
							return {
								...it,
								state: newState,
							}
						}),
					}
				}),
			})
		})
	}

	const printOnDesktop = async (order) => {
		let id = Math.floor(Math.random() * 100000000000)
		let obj = {
			order,
			place,
			id,
		}
		await firestore.runTransaction(async (trans) => {
			let latestData = await trans.get(
				firestore.doc(
					`/users/${auth.currentUser.uid}/printers/${cookies.print}`
				)
			)
			latestData = latestData.data()
			let q = latestData.queue || []
			trans.update(
				firestore.doc(
					`/users/${auth.currentUser.uid}/printers/${cookies.print}`
				),
				{
					queue: [...q, obj],
				}
			)
		})
	}

	const renderItems = (items, order, delivered, itemsRemaining, notes) => {
		if (items.length === 0) return []
		if (order.table !== selectedTable && selectedTable !== 'all') return []
		let _items = []

		let topClassName =
			itemsRemaining === 0
				? 'success'
				: itemsRemaining === order.items.length
				? 'danger'
				: 'warning'

		let orderName =
			order.invoiceID.split('_')[1] !== 'PICKUP'
				? `${language.order}`
				: `${language.order_from} ${order.first_name} `

		let orderNumb = '#' + order.invoiceNumb

		_items.push(
			<tr className={`sep`} key={order.invoiceID}>
				<td
					colSpan={`${
						place.features.indexOf('tables') !== -1 &&
						user.type === 'admin_premium'
							? 9
							: 8
					}`}
				>
					<h4>
						<div className="title">
							{orderName}
							<span className="order-name-text">{orderNumb}</span>
						</div>
						<div className="buttons">
							{order.status === 0 && (
								<>
									<button
										className="button inline button-secondary button-done button-small"
										disabled={submittingOrder !== null}
										onClick={() => changeOrderState(1, order)}
										type="button"
									>
										<i className="mi">done</i> <span>Listo</span>
									</button>
								</>
							)}
							{order.status === 1 && (
								<>
									<button
										className="button inline button-primary button-done button-small"
										disabled={submittingOrder !== null}
										onClick={() => changeOrderState(2, order)}
										type="button"
									>
										<i className="mi">done</i> <span>Entregado</span>
									</button>
								</>
							)}
							<button
								className="button inline button-light button-small"
								// disabled={submittingOrder === order.invoiceID}
								// onClick={() => changeOrderState(2, order)}

								disabled={submittingOrder !== null}
								onClick={() => {
									if (cookies.print) {
										printOnDesktop(order)
										create_alert(
											`Imprimiendo en ${cookies.print}`,
											'success'
										)
									} else {
										setPrintInvoice(order)
										setTimeout(() => {
											doInvoice(order)
										}, 200)
									}
								}}
								type="button"
							>
								<i className="mi">receipt</i> <span>Factura</span>
							</button>
						</div>
					</h4>
				</td>
			</tr>
		)

		if (!collapsed)
			items.forEach((item, x) => {
				let tableRef = allTables.find((el) => el.id === order.table)
				// if (delivered && item.state !== 2) return
				// else if (!delivered && item.state === 2) return

				if (
					item.category !== selectedCategory &&
					selectedCategory !== 'all'
				)
					return
				let _date = order.date
				if (typeof item.date !== 'undefined') _date = item.date
				// let promo_img_clss = item.category === 'promotions' || item.category === 'menu' ? 'promo' : ''
				let product_img =
					item.image !== 'no_image'
						? { backgroundImage: `url(${item.image})` }
						: {}
				let qty = item.quantity || item.units
				let last_clss =
					x == items.length - 1 &&
					(typeof order.notes === 'undefined' ||
						order.notes === null ||
						(order.notes.length && !order.notes[0]))
						? 'last-item'
						: ''

				_items.push(
					<tr
						key={`${item.cartId}_${x}_${order.invoiceID}_${order.date}`}
						className={`color-border ${
							item.state === 2 ? 'strike' : ''
						} ${last_clss}`}
					>
						<td className="color"></td>
						<td className="center">
							{!delivered && x === 0 && (
								<Chrono time={_date} nodays showSeconds />
							)}
						</td>

						{tables !== null &&
							place.features.indexOf('tables') !== -1 &&
							user.type === 'admin_premium' && (
								<td className="center">
									{typeof tableRef === 'undefined'
										? language.none
										: tableRef.name}
								</td>
							)}
						<td className="center">
							<span className="img" style={product_img}></span>
						</td>
						<td key={item.id}>
							<strong>
								{item.name}{' '}
								{item.price_type === 'units' &&
									` - ${qty} ${getUnitName(item.unit, qty)}`}
							</strong>
						</td>
						<td className="center">{item.category}</td>
						<td>{renderAdditions(item)}</td>
						<td>
							<div className="form-group form-group-check">
								<div className="form-row">
									<div className="col-12">
										<input
											id={`checked-${item.cartId}`}
											type="checkbox"
											checked={item.state === 2}
											disabled={submittingOrder !== null}
											onChange={(e) =>
												checkProduct(e.target.checked, item, order)
											}
										/>
										<label htmlFor={`checked-${item.cartId}`}>
											<b></b>
											<span>Listo</span>
										</label>
									</div>
								</div>
							</div>
						</td>
						<td className="color"></td>
					</tr>
				)
			})

		return _items
	}

	const renderNotes = (_notes, id, fromapp) => {
		if (typeof _notes === 'undefined' || _notes === null || collapsed)
			return null

		let items = []

		let notes = [..._notes]
		notes.reverse()

		if (notes.length > 1 && fromapp) {
			// Payment row
			items.push(
				<li className="" key={'PAY'}>
					<i className="mi">account_balance_wallet</i>{' '}
					<span>
						<strong>Estado del pago</strong>
						{' - '}
						{notes[0] === 'notpaid'
							? 'Paga en tienda'
							: 'Paga desde la App'}
					</span>
				</li>
			)

			let month = 0
			let day = 0
			let hour = 0

			if (notes[0] !== 'now') {
				let date = new Date(parseInt(notes[1]))
				month = months[date.getMonth()]
				day = date.getDate()
				hour = `${`0${date.getHours()}`.slice(
					-2
				)}:${`0${date.getMinutes()}`.slice(-2)}`
			}

			// Time row
			items.push(
				<li className="" key="WHEN">
					<i className="mi">insert_invitation</i>{' '}
					<span>
						<strong>Para cuando</strong>
						{' - '}
						{notes[1] === 'now'
							? 'Lo más pronto posible'
							: `Para el ${day} de ${month} a las ${hour}`}
					</span>
				</li>
			)

			let deliver = ''
			try {
				let ob = JSON.parse(notes[2]).name
				deliver = ob
			} catch (e) {
				deliver = allTables.find((t) => t.id === notes[2])
				if (typeof deliver !== 'undefined') deliver = deliver.name
			} finally {
				items.push(
					<li className="" key="HOW">
						<i className="mi">local_mall</i>{' '}
						<span>
							<strong>Forma de entrega</strong>
							{' - '}
							{notes[2] === 'pickup'
								? 'Para recoger'
								: notes[2] === 'inplace'
								? 'Para consumir aquí'
								: `Para la mesa ${deliver}`}
						</span>
					</li>
				)
			}

			if (notes[3]) {
				items.push(
					<li className="" key="NOTE">
						<i className="mi">note</i>{' '}
						<span>
							<strong>Notas del cliente</strong>
							{' - '}
							{`${notes[3]}`}
						</span>
					</li>
				)
			}
		} else {
			notes.forEach((n) => {
				if (n !== null) {
					items.push(
						<li className="" key="NOTE">
							<i className="mi">note</i>{' '}
							<span>
								<strong>Notas del cliente</strong>
								{' - '}
								{`${n}`}
							</span>
						</li>
					)
				}
			})
		}

		if (notes.length > 1 || notes[0] !== null) {
			return (
				<tr className="color-border last-item" key={id + '_NOTES'}>
					<td className="color"></td>
					<td
						colSpan={`${
							place.features.indexOf('tables') !== -1 &&
							user.type === 'admin_premium'
								? 7
								: 6
						}`}
						className="custom-notes"
					>
						<h4>Información adicional</h4>
						<ul>{items}</ul>
					</td>
					<td className="color"></td>
				</tr>
			)
		} else return null
	}

	const renderOrders = (delivered = false) => {
		let _orders = [...place.orders]
		_orders = _orders.sort((a, b) => {
			if (a.date > b.date) return 1
			return 1
		})
		let items = []
		_orders.forEach((order) => {
			let hasItemsInThisCat = false
			if (selectedCategory === 'all') hasItemsInThisCat = true
			if (selectedCategory !== 'all') {
				order.items.forEach((item) => {
					if (item.category === selectedCategory) hasItemsInThisCat = true
				})
			}
			if (order.items.length === 0) hasItemsInThisCat = false
			if (order.confirmed !== true) hasItemsInThisCat = false

			if (order.table === filterTable && filterTable !== null) {
				hasItemsInThisCat = true
			} else if (filterTable !== null) {
				hasItemsInThisCat = false
			}

			let source =
				order.invoiceID.split('_')[1] !== 'PICKUP' ? 'admin' : `app`

			if (source === filterFrom && filterFrom !== null) {
				hasItemsInThisCat = true
			} else if (filterFrom !== null) {
				hasItemsInThisCat = false
			}

			if (hasItemsInThisCat) {
				let itemsRemaining = 0
				order.items.forEach((it) => {
					if (it.state !== 2) itemsRemaining++
				})
				items = [
					...items,
					...renderItems(order.items, order, delivered, itemsRemaining),
					renderNotes(
						order.notes,
						order.invoiceID,
						typeof order.uid !== 'undefined'
					),
				]
			}
		})
		return items
	}

	if (tables === null) return <Loading />

	if (acceptedOrdersQty === 0)
		return (
			<aside className="empty">
				<i className="mi">receipt</i>
				<h3>No hay pedidos</h3>
			</aside>
		)

	return (
		<>
			{/* <button
				onClick={async (e) => {
					const func = functions.httpsCallable('updateOrdersPricing')
					const res = await func({
						subscription_id: user.stripe_subscription.items.data[0].id,
					})
					
				}}
			>
				TEST
			</button> */}

			{place.orders.length > 0 && (
				<>
					<div className="table-ui table-ui-groups">
						<div>
							<div className="archive-filters">
								<Select
									options={getOptions()}
									className="select-filter"
									defaultValue={{
										label: 'Categoría',
										value: '',
									}}
									onChange={(e) => setFilter(e.value)}
								/>
								<Select
									options={getOptionsTables()}
									className="select-filter"
									defaultValue={{
										label: 'Mesa',
										value: null,
									}}
									onChange={(e) => setFilterTable(e.value)}
								/>
								<Select
									options={[
										{ label: 'Origen', value: null },
										{ label: 'Desde la App', value: 'app' },
										{ label: 'Desde el Admin', value: 'admin' },
									]}
									className="select-filter"
									defaultValue={{
										label: 'Origen',
										value: null,
									}}
									onChange={(e) => setFilterFrom(e.value)}
								/>
							</div>
							<table>
								<thead>
									<tr>
										<th width="2" className="color"></th>
										<th className="center">Tiempo en espera</th>
										{place.features.indexOf('tables') !== -1 &&
											user.type === 'admin_premium' && (
												<th className="center">{language.table}</th>
											)}
										<th></th>
										<th>{language.product}</th>
										<th className="center">{language.category}</th>
										<th>{language.additions}</th>
										<th width="120" />
										<th width="2" className="color"></th>
									</tr>
								</thead>
								<FlipMove
									enterAnimation="accordionVertical"
									leaveAnimation="accordionVertical"
									duration={100}
									typeName="tbody"
								>
									{renderOrders()}
								</FlipMove>
							</table>
						</div>
					</div>
				</>
			)}
			{printInvoice !== false && renderInvoice()}
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		categories: state.categories,
		tables: state.tables,
		language: state.language.dictionary,
		topbar: state.topbar,
		user: state.user,
	}),
	{ create_alert, update_place }
)(Accepted)

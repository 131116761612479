import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { CardElement, injectStripe } from 'react-stripe-elements'
import { Loading, Input } from '../../components'
import { firestore, functions } from '../../config/firebase'
import { update_user } from '../../store/actions'

function PaymentForm({
	user,
	stripe,
	create_alert,
	language,
	update_user,
	goBack = () => {},
	type = '',
	discount_code = '',
	customSubmit = null,
}) {
	const [submitting, setSubmitting] = useState(false)
	const [loading, setLoading] = useState(false)

	const onSubmit = async (values) => {
		setSubmitting(true)
		try {
			let { token } = await stripe.createToken({ name: values.name })
			if (customSubmit !== null) return customSubmit(token, values)

			let plans = []

			if (type === 'admin_premium')
				plans = ['plan_HHUta0XNKTVuIw', 'plan_HHUtYkkB8HAVBN']
			else plans = ['plan_HHUspfEOAP3LFO', 'plan_HHUszh4Up8klqD']

			const func = functions.httpsCallable('subscribeToPlan')
			const res = await func({
				token: token.id,
				email: user.email,
				name: values.name,
				plan: plans,
				discount: discount_code,
			})

			switch (res.data.error_type) {
				case null:
					switch (res.data.subscription.status) {
						case 'incomplete':
							setSubmitting(false)
							create_alert('Hubo un error con tu pago', 'danger')
							break
						case 'active':
							await firestore.collection('users').doc(user.id).update({
								stripe_customer: res.data.customer,
								stripe_subscription: res.data.subscription,
								type,
							})
							update_user({
								stripe_customer: res.data.customer,
								stripe_subscription: res.data.subscription,
								type,
							})
							break
						default:
							setSubmitting(false)
							create_alert('Hubo un error con tu pago', 'danger')
							break
					}
					break
				default:
					setSubmitting(false)
					create_alert('Hubo un error con tu pago', 'danger')
					break
			}
		} catch (e) {
			setSubmitting(false)
			create_alert('Hubo un error con tu pago', 'danger')
			return null
		}
	}

	if (loading) return <Loading />

	return (
		<div className="login-ui">
			<div>
				<Formik
					initialValues={{
						name: '',
					}}
					onSubmit={onSubmit}
					validate={(values) => {
						if (!values.name) return { name: 'Please type your name' }
					}}
				>
					{({ errors, touched }) => (
						<Form className="form-ui">
							<span className="logo"></span>
							<h1>{language.payment_method}</h1>
							<Input
								name="name"
								errors={errors}
								touched={touched}
								label={language.name}
								submitting={submitting}
								rowcolleft="3"
								rowcolright="9"
							/>
							<div className="form-group form-group-stripe">
								<div className="form-row">
									<div className="col-12">
										<CardElement />
									</div>
								</div>
							</div>
							<footer>
								<button
									disabled={submitting}
									type="submit"
									className="button button-primary button-block"
								>
									{submitting && (
										<div role="status">
											<span>{language.loading}...</span>
										</div>
									)}
									{!submitting && language.continue}
								</button>
								<button
									className="button button-link button-block"
									onClick={goBack}
								>
									Regresar
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	)
}

export default injectStripe(
	connect((state) => ({ language: state.language.dictionary }), {
		update_user,
	})(PaymentForm)
)

import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { auth } from '../../config/firebase'
import { create_alert } from '../../store/actions'

function RecoverPassword({ history, create_alert }) {
	const [submitting, setSubmitting] = useState(false)

	const onSubmit = values => {
		setSubmitting(true)
		auth
			.sendPasswordResetEmail(values.email)
			.then(() => {
				setSubmitting(false)
				create_alert('Check your email', 'success')
				history.replace('/')
			})
			.catch(() => {
				setSubmitting(false)
				create_alert('Something happened, try again', 'danger')
				history.replace('/')
			})
	}

	return (
		<div className="signin login recover-password">
			<div>
				<Link to="/" className="logo"></Link>
				<Formik
					validateOnBlur={false}
					validateOnChange={false}
					initialValues={{ email: '', password: '' }}
					onSubmit={onSubmit}
				>
					{({ errors, touched }) => {
						return (
							<Form className="form">
								<h1>Vywap</h1>
								<p>Reset your password</p>
								<Field
									className={
										errors.email && touched.email ? ' is-invalid' : ''
									}
									disabled={submitting}
									type="email"
									name="email"
									placeholder="Email"
								/>
								{errors.email && touched.email ? (
									<div>{errors.email}</div>
								) : null}
								<footer>
									<button
										disabled={submitting}
										type="submit"
										className="button button-primary"
									>
										{submitting && (
											<div role="status">
												<span>Loading...</span>
											</div>
										)}
										{!submitting && 'Continue'}
									</button>
								</footer>
							</Form>
						)
					}}
				</Formik>
				<p>
					<Link to="/signin">
						<i className="icon-navigation-arrow-back"></i> Sign in
					</Link>
				</p>
			</div>
		</div>
	)
}

export default connect(null, { create_alert })(RecoverPassword)

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	AreaChart,
	Area,
	Line,
	LineChart,
	ResponsiveContainer
} from 'recharts'

import { firestore } from '../../../config/firebase'

function Reservations({ place, language, date, data }) {
	const [monthReservations, setMonthReservations] = useState(null)
	const [accMonthReservations, setAccMonthReservations] = useState(null)

	useEffect(() => {
		getVals(data)
	}, [])

	useEffect(() => {
		if (data !== null) getVals(data)
	}, [date])

	const getVals = data => {
		getMonthReservations(data)
	}

	const upper = lower => lower.charAt(0).toUpperCase() + lower.substring(1)

	const getMonthReservations = data => {
		let days = []
		let _accumulated = 0
		let today = new Date(date)
		let month_length = new Date(
			today.getFullYear(),
			today.getMonth() + 1,
			0
		).getDate()

		for (let x = 0; x < month_length; x++) {
			let formatted_date = `${`0${x + 1}`.slice(-2)}/${`0${today.getMonth() +
				1}`.slice(-2)}/${today.getFullYear()}`
			let day_data = data.reservations[formatted_date]
			if (typeof day_data === 'undefined') {
				days.push({
					accumulated: _accumulated,
					quantity: 0,
					name: `0${x + 1}`.slice(-2)
				})
			} else {
				_accumulated += day_data.quantity

				days.push({
					accumulated: _accumulated,
					quantity: day_data.quantity,
					name: `0${x + 1}`.slice(-2)
				})
			}
		}
		setAccMonthReservations(_accumulated)
		setMonthReservations(days)
	}

	if(monthReservations === null)
		return <aside className="empty"><i className="mi">insert_chart</i><h3>No hay estadísticas de reservas.</h3></aside>


	return (
		<>
			<div className="ui-stats">
				{monthReservations !== null && (
					<div className="stat stat-wide">
						<div>
							<h1>
								Reservas del mes
								<span className="badge">{accMonthReservations}</span>
							</h1>
							<div className="wrapper">
								<ResponsiveContainer width="100%" height="100%">
									<LineChart data={monthReservations}>
										<CartesianGrid
											strokeDasharray="1 0"
											stroke="#dee2e6"
										/>
										<XAxis dy={5} dataKey="name" />
										<YAxis
											dx={-5}
											domain={[10, 'dataMax + 2']}
											type="number"
										/>
										<Tooltip
											formatter={(value, name, props) => value}
											labelFormatter={(value, name, props) =>
												`${upper(
													new Date(date).toLocaleString('es-ES', {
														month: 'long'
													})
												)} ${value}`
											}
											isAnimationActive={false}
											cursor={{ stroke: '#000', strokeWidth: 3 }}
										/>
										<Legend />
										<Line
											type="monotone"
											dataKey="quantity"
											label="Cantidad"
											name="Cantidad"
											stroke="#2589f9"
											strokeWidth="3"
										/>
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default connect(state => ({
	place: state.place,
	language: state.language.dictionary
}))(Reservations)

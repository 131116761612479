import React, { useState } from 'react'
import { connect } from 'react-redux'
import { functions } from '../../config/firebase'
import { create_alert } from '../../store/actions'
import Input from '../../components/Input'

function Announcements({ topbar, place, create_alert }) {
	const [content, setContent] = useState('')
	const [submitting, setSubmitting] = useState(false)

	return (
		<>
			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs limit-w">
					{/* <li className={active === 0 ? 'active' : ''}>
						<Link
							to="/stats/sales"
							onClick={(e) => {
								setActive(0)
							}}
						>
							{language.sales}
						</Link>
					</li> */}
				</ul>
				<div className="pull-right">
					<div className="actions"></div>
				</div>
			</div>
			<div>
				<header className="header-ui">
					<div>
						<h2>Cómo usar los anuncios</h2>
						<p>
							Desde la App las personas pueden activar los anuncios en tu
							lugar para recibir las notificaciones que tu puedes enviar
							desde esta pantalla.
						</p>
					</div>
				</header>
			</div>
			<div className="login-ui login-ui-lg">
				<div>
					<form
						className="form-ui"
						onSubmit={(e) => e.preventDefault()}
						style={{
							paddingTop: 20,
							paddingTop: 20,
						}}
					>
						<Input
							label="Contenido"
							noformik
							disabled={submitting}
							custom={{
								onChange: (e) => setContent(e.target.value),
								value: content,
							}}
						/>
						<footer>
							<button
								disabled={submitting}
								type="button"
								className="button button-primary"
								style={{
									marginTop: 20,
								}}
								onClick={async () => {
									try {
										create_alert('Enviando notificaciones', 'warning')
										setSubmitting(true)

										let fn = functions.httpsCallable(
											'sendNotificationsSubscribers'
										)
										await fn({
											content,
											place: place.id,
											place_name: place.name,
											subscribers: place.subscribers,
										})

										setSubmitting(false)
										setContent('')
										create_alert('Notificaciones enviadas', 'success')
									} catch (e) {
										setSubmitting(false)
										setContent('')
										create_alert(
											'Hubo un problema enviando las notificaciones',
											'danger'
										)
									}
								}}
							>
								Enviar
							</button>
						</footer>
					</form>
				</div>
			</div>
		</>
	)
}
export default connect(
	(state) => ({
		topbar: state.topbar,
		place: state.place,
	}),
	{
		create_alert,
	}
)(Announcements)

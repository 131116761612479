import React, { useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import Accepted from './Accepted'
import Pending from './Pending'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'

registerLocale('es', es)

function RegularReservations({ place }) {
	const [active, setActive] = useState(0)
	const [dayViewing, setDayViewing] = useState(new Date().getTime())
	const [todayIs, setTodayIs] = useState(null)
	const [showAdd, setShowAdd] = useState(false)
	const [confirmingReservation, setConfirmingReservation] = useState(false)
	const [editSeats, setEditSeats] = useState(false)

	useEffect(() => {
		setTodayIs(formatDate(new Date().getTime()))

		const arrayOfLinks = ['accepted', 'pending']
		let pathname = window.location.pathname
		pathname = pathname.split('/')[2]
		let index = 0

		arrayOfLinks.forEach((id, i) => {
			if (id === pathname) {
				index = i
			}
		})

		setActive(index)
	}, [])

	const formatDate = (date) => {
		let _date = new Date(date)
		let day = _date.getDate()
		let month = _date.getMonth() + 1
		let year = _date.getFullYear()
		return `${`0${day}`.slice(-2)}/${`0${month}`.slice(-2)}/${year}`
	}

	return (
		<>
			<div className="topbar">
				<ul className="tabs">
					<li className={active === 0 ? 'active' : ''}>
						<Link
							to="/reservations/accepted"
							onClick={(e) => {
								setActive(0)
							}}
						>
							Confirmadas
						</Link>
					</li>

					<li className={active === 1 ? 'active' : ''}>
						<Link
							to="/reservations/pending"
							onClick={(e) => {
								setActive(1)
							}}
						>
							Pendientes
						</Link>
						{/* {Object.keys(pending_reservations).length > 0 && (
							<pre>{Object.keys(pending_reservations).length}</pre>
						)} */}
					</li>
				</ul>
				<div className="pull-right">
					<div className="actions">
						{active === 0 && (
							<>
								<div
									className="date-wrapper"
									style={{
										paddingRight: 0,
									}}
								>
									<DatePicker
										selected={dayViewing}
										onChange={(date) => setDayViewing(date)}
										locale="es"
										minDate={new Date().getTime()}
										customInput={
											<button className="button button-secondary">
												<span>
													{formatDate(dayViewing) === todayIs
														? 'Hoy'
														: formatDate(dayViewing)}
												</span>
											</button>
										}
										popperClassName="some-custom-class"
										popperPlacement="bottom-left"
										popperModifiers={{
											offset: {
												enabled: true,
												offset: '5px, 10px',
											},
											preventOverflow: {
												enabled: true,
												escapeWithReference: false,
												boundariesElement: 'viewport',
											},
										}}
									/>
								</div>
								<button
									className="button button-light"
									onClick={() => setEditSeats(true)}
								>
									<i className="mi">event_seat</i>{' '}
									<span>Editar puestos</span>
								</button>
								<button
									className="button button-primary"
									onClick={() => setShowAdd(true)}
								>
									<i className="mi">add</i>{' '}
									<span>Agregar Reserva</span>
								</button>
							</>
						)}
					</div>
				</div>
			</div>
			<Switch>
				<Route
					path="/reservations/pending"
					render={(props) => (
						<Pending
							confirmingReservation={confirmingReservation}
							setConfirmingReservation={setConfirmingReservation}
							{...props}
						/>
					)}
				/>
				<Route
					path="/reservations"
					render={(props) => (
						<Accepted
							todayIs={todayIs}
							setDayViewing={setDayViewing}
							showAdd={showAdd}
							dayViewing={dayViewing}
							setShowAdd={setShowAdd}
							confirmingReservation={confirmingReservation}
							setConfirmingReservation={setConfirmingReservation}
							editSeats={editSeats}
							setEditSeats={setEditSeats}
							{...props}
						/>
					)}
				/>
			</Switch>
		</>
	)
}

export default connect((state) => ({
	place: state.place,
}))(RegularReservations)

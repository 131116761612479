import React, { useEffect, useState } from 'react'
import FlipMove from 'react-flip-move'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { Loading } from '../../../components'

import Chrono from '../../../components/Chrono'
import { firestore, functions, auth } from '../../../config/firebase'
import { create_alert } from '../../../store/actions'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useCookies } from 'react-cookie'

const months = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre',
]

function Pending({
	place,
	categories,
	tables,
	create_alert,
	language,
	topbar,
	user,
}) {
	const [allTables, setAllTables] = useState([])
	const [editingOrder, setEditingOrder] = useState(null)
	const [submittingOrder, setSubmittingOrder] = useState(null)
	const [selectedDate, setSelectedDate] = useState(new Date())
	const [cookies, setCookie, removeCookie] = useCookies(['print'])

	const [orders, setOrders] = useState([])

	const [pendingOrders, setpendingOrders] = useState([])

	useEffect(() => {
		let alltables = []
		tables.floors.forEach((floor) => {
			floor.tables.forEach((table) => {
				alltables.push(table)
			})
		})
		setAllTables(alltables)
	}, [])

	useEffect(() => {
		setOrders(place.orders)
		let _orders = place.orders.filter(
			(_order) => _order.confirmed !== true && _order.items.length > 0
		)
		_orders = _orders.sort((a, b) => {
			if (a.date > b.date) return 1
			return -1
		})

		setpendingOrders(_orders)
	}, [place.orders])

	useEffect(() => {
		let _orders = orders.filter(
			(_order) => _order.confirmed !== true && _order.items.length > 0
		)
		_orders = _orders.sort((a, b) => {
			if (a.date > b.date) return 1
			return -1
		})
		setpendingOrders(_orders)
	}, [orders])

	useEffect(() => {}, [pendingOrders])

	const printOnDesktop = async (order) => {
		let id = Math.floor(Math.random() * 100000000000)
		let obj = {
			order,
			place,
			id,
		}
		await firestore.runTransaction(async (trans) => {
			let latestData = await trans.get(
				firestore.doc(
					`/users/${auth.currentUser.uid}/printers/${cookies.print}`
				)
			)
			latestData = latestData.data()
			let q = latestData.queue || []
			trans.update(
				firestore.doc(
					`/users/${auth.currentUser.uid}/printers/${cookies.print}`
				),
				{
					queue: [...q, obj],
				}
			)
		})
	}

	const getUnitName = (u = 'u', quantity) => {
		switch (u) {
			case 'u':
				if (quantity > 1) return 'Unidades'
				return 'Unidad'
			case 'kg':
				if (quantity > 1) return 'Kilos'
				return 'Kilo'
			case 'lt':
				if (quantity > 1) return 'Litros'
				return 'Litro'
			case 'pt':
				if (quantity > 1) return 'Raciones'
				return 'Ración'
			case 'lb':
				if (quantity > 1) return 'Libras'
				return 'Libra'
		}
	}

	const renderAdditions = (item) => {
		if (typeof item.additions !== 'undefined') {
			let adds_dom = item.additions.map((add) => {
				let no_adds = true
				let add_items = add.items.map((it) => {
					if (typeof it.quantity === 'undefined' || it.quantity <= 0)
						return null
					no_adds = false
					return (
						<li key={it.name}>
							<span>{it.name}</span>
						</li>
					)
				})
				if (no_adds) return null
				return (
					<div className="additions" key={add.name}>
						<b>{add.name}</b>
						<ul>{add_items}</ul>
					</div>
				)
			})
			return <>{adds_dom}</>
		}
	}

	const chuck = (str, n) => {
		let ret = []
		let i
		let len

		for (i = 0, len = str.length; i < len; i += n) {
			ret.push(str.substr(i, n))
		}

		return ret
	}

	const confirmOrder = async (order) => {
		try {
			setSubmittingOrder(order.invoiceID)

			let _order = [...orders].find((or) => {
				return or.invoiceID === order.invoiceID
			})

			let placeRef = firestore.doc(`/places/${place.id}`)

			let paymentData = null

			if (_order.paid === 'card') {
				const attemptCharge = functions.httpsCallable('capturePayment')
				let res = await attemptCharge({
					client: order.uid,
					place: place.id,
					order,
				})

				if (res.data.error_type !== null) throw 'error'

				paymentData = res.data
				if (paymentData.paid !== true) throw 'error'
			}

			firestore
				.runTransaction(async (transaction) => {
					let latestPlaceData = await transaction.get(placeRef)
					latestPlaceData = latestPlaceData.data()
					await transaction.update(placeRef, {
						invoiceNumb: latestPlaceData.invoiceNumb + 1,
						orders: latestPlaceData.orders.map((or) => {
							if (or.invoiceID !== order.invoiceID) return or
							return {
								..._order,
								confirmed: true,
								invoiceNumb: latestPlaceData.invoiceNumb + 1,
								status: 0,
								paymentData,
							}
						}),
					})
					return latestPlaceData.invoiceNumb + 1
				})
				.then(async (numb) => {
					const func = functions.httpsCallable('updateOrderStatus')
					const res = await func({
						order: { ..._order, place_id: place.id, invoiceNumb: numb },
						status: true,
						client_id: _order.uid,
					})
					if (res === false) throw { error: 'true' }
					const _func = functions.httpsCallable('sendNotification')
					let cont = 'Tu pedido ha sido confirmado'
					const _res = await _func({
						content: cont,
						client_id: order.uid,
					})
					if (_res === false) throw { error: 'true' }

					const func_ = functions.httpsCallable('updateOrdersPricing')
					if (user.type === 'admin_payasyougo') {
						let total = 0
						_order.items.forEach((it) => {
							let qty = it.quantity || it.units
							let it_charge =
								it.price_type === 'units' ? it.price * qty : it.price
							total += it_charge
							it.additions.forEach((add) => {
								add.items.forEach((_it) => {
									if (_it.quantity > 0)
										total += _it.value * _it.quantity
								})
							})
						})

						let placesSub = user.stripe_customer.subscriptions.data[0].items.data.find(
							(s) => s.plan.id === 'plan_HHUspfEOAP3LFO'
						)
						if (typeof placesSub === 'undefined') throw { error: true }

						let _total = total * 100

						let percentage = _total * 0.1

						await func_({
							subscription_item: placesSub.id,
							quantity: percentage,
						})
					} else {
						let placesSub = user.stripe_customer.subscriptions.data[0].items.data.find(
							(s) => s.plan.id === 'plan_HHUta0XNKTVuIw'
						)
						if (typeof placesSub === 'undefined') throw { error: true }

						await func_({
							subscription_item: placesSub.id,
						})
					}

					if (cookies.print) {
						printOnDesktop(order)
						create_alert(`Imprimiendo en ${cookies.print}`, 'success')
					}

					setSubmittingOrder(null)
				})
				.catch((e) => {
					// create_alert('Error borrando el pedido', 'danger')
					setSubmittingOrder(null)
				})
		} catch (e) {
			if (e === 'error') {
				create_alert(
					'No se pudo hacer el cobro a la tarjeta, pedido rechazado',
					'danger'
				)
				removeOrder(order, 'Problema con el pago')
			}

			setSubmittingOrder(null)
		}
	}

	const removeOrder = async (order, additional = null) => {
		setSubmittingOrder(order.invoiceID)
		let placeRef = firestore.doc(`/places/${place.id}`)

		firestore
			.runTransaction(async (transaction) => {
				let latestPlaceData = await transaction.get(placeRef)
				latestPlaceData = latestPlaceData.data()
				await transaction.update(placeRef, {
					orders: latestPlaceData.orders.filter(
						(or) => or.invoiceID !== order.invoiceID
					),
				})
			})
			.then(async () => {
				const func = functions.httpsCallable('updateOrderStatus')
				const res = await func({
					order: { ...order, place_id: place.id },
					status: false,
					client_id: order.uid,
				})
				if (res === false) throw { error: 'true' }
				const _func = functions.httpsCallable('sendNotification')
				let cont = `Tu pedido ha sido rechazado ${
					additional !== null ? `| ${additional}` : ''
				}`
				const _res = await _func({
					content: cont,
					client_id: order.uid,
				})
				if (_res === false) throw { error: 'true' }
				setSubmittingOrder(null)
			})
			.catch(() => {
				// create_alert('Error borrando el pedido', 'danger')
				setSubmittingOrder(null)
			})
	}

	const renderItems = (items, order, delivered) => {
		if (items.length === 0) return []

		let _items = []

		let opacity = submittingOrder === order.invoiceID ? 0.3 : 1

		let orderName =
			order.invoiceID.split('_')[1] !== 'PICKUP'
				? `${language.order}`
				: `${language.order_from} ${order.first_name} `

		let orderNumb =
			order.invoiceID.split('_')[1] !== 'PICKUP'
				? `#${order.invoiceID.split('_')[1]}`
				: ` #${chuck(order.invoiceID.split('_')[3], 3).join('-')}`

		let hours_in_milliseconds = 3600000
		let time_elapsed = new Date().getTime() - order.date
		let millis = hours_in_milliseconds * 2
		let due_order = time_elapsed > millis

		_items.push(
			<tr
				className={`sep`}
				key={order.invoiceID + '_HEAD'}
				style={{
					opacity,
				}}
			>
				<td colSpan="9">
					<h4>
						<div className="title">
							{orderName}{' '}
							<span className="order-name-text">{orderNumb}</span>
						</div>
						{editingOrder !== order.invoiceID && (
							<div className="buttons">
								<button
									className="button inline button-danger"
									style={{ marginLeft: 10 }}
									onClick={(e) => removeOrder(order)}
								>
									<i className="mi" style={{ marginRight: 10 }}>
										close
									</i>
									RECHAZAR
								</button>
								{!due_order && (
									<>
										<button
											className="button inline button-light"
											style={{ marginLeft: 10 }}
											onClick={(e) => {
												let date = new Date().getTime()
												if (typeof order.notes[1] === 'number')
													date = order.notes[1]
												setSelectedDate(new Date(date))
												setEditingOrder(order.invoiceID)
											}}
										>
											<i className="mi" style={{ marginRight: 10 }}>
												edit
											</i>
											MODIFICAR
										</button>
										<button
											className="button inline button-success"
											style={{ marginLeft: 10 }}
											onClick={(e) => confirmOrder(order)}
										>
											<i className="mi" style={{ marginRight: 10 }}>
												done
											</i>
											CONFIRMAR
										</button>
									</>
								)}
							</div>
						)}
						{editingOrder === order.invoiceID && (
							<div className="buttons">
								<button
									className="button inline button-light"
									style={{ marginLeft: 10 }}
									onClick={(e) => {
										setOrders(place.orders)
										setEditingOrder(null)
									}}
								>
									<i className="mi" style={{ marginRight: 10 }}>
										close
									</i>
									CANCELAR
								</button>
								<button
									className="button inline button-success"
									style={{ marginLeft: 10 }}
									onClick={(e) => {
										setEditingOrder(null)
									}}
								>
									<i className="mi" style={{ marginRight: 10 }}>
										done
									</i>
									GUARDAR
								</button>
							</div>
						)}
					</h4>
				</td>
			</tr>
		)

		items.forEach((item, x) => {
			let tableRef = allTables.find((el) => el.id === order.table)
			let isEditing =
				editingOrder === order.invoiceID && order.items.length > 1
			let _date = order.date
			if (typeof item.date !== 'undefined') _date = item.date
			// let promo_img_clss = item.category === 'promotions' || item.category === 'menu' ? 'promo' : ''
			let product_img =
				item.image !== 'no_image'
					? { backgroundImage: `url(${item.image})` }
					: {}
			let last_clss =
				x == items.length - 1 &&
				(typeof order.notes === 'undefined' ||
					order.notes === null ||
					(order.notes.length && !order[0]))
					? 'last-item'
					: ''
			_items.push(
				<tr
					key={order.invoiceID + '_ITEM_' + item.cartId}
					className={`color-border ${last_clss}`}
					style={{
						opacity,
					}}
				>
					<td className="color"></td>
					{tables !== null && place.features.indexOf('tables') !== -1 && (
						<td className="center">
							{typeof tableRef === 'undefined'
								? language.none
								: tableRef.name}
						</td>
					)}
					<td
						className="center"
						style={{
							position: 'relative',
						}}
					>
						<span className="img" style={product_img}></span>
					</td>
					<td key={item.id}>
						<strong>
							{item.name}{' '}
							{item.price_type === 'units' &&
								` - ${item.quantity} ${getUnitName(
									item.unit,
									item.quantity
								)}`}
						</strong>
					</td>
					<td className="center">{item.category}</td>
					<td>{renderAdditions(item)}</td>
					<td>
						<span className="price">
							<pre>{item.price.toFixed(2)}</pre>
							<i className="mi">euro_symbol</i>
						</span>
					</td>
					<td width={isEditing ? 140 : 0}>
						{isEditing && (
							<button
								className="button button-danger button-small"
								style={{ width: 130 }}
								onClick={(e) => {
									let ords = orders.map((or) => {
										if (or.invoiceID !== order.invoiceID) return or
										return {
											...or,
											items: or.items.filter(
												(i) => i.cartId !== item.cartId
											),
										}
									})
									setOrders(ords)
								}}
							>
								<i className="mi">delete</i>
								<span>Eliminar</span>
							</button>
						)}
					</td>
					<td className="color"></td>
				</tr>
			)
		})

		return _items
	}

	const renderNotes = (_notes, id) => {
		if (typeof _notes === 'undefined' || _notes === null) return null

		let items = []

		let notes = [..._notes]
		notes.reverse()

		// Payment row
		items.push(
			<li className="" key={'PAY'}>
				<i className="mi">account_balance_wallet</i>{' '}
				<span>
					<strong>Estado del pago</strong>
					{' - '}
					{notes[0] === 'notpaid' ? 'Paga en tienda' : 'Paga desde la App'}
				</span>
			</li>
		)

		let month = 0
		let day = 0
		let hour = 0

		if (notes[0] !== 'now') {
			let date = new Date(parseInt(notes[1]))
			month = months[date.getMonth()]
			day = date.getDate()
			hour = `${`0${date.getHours()}`.slice(
				-2
			)}:${`0${date.getMinutes()}`.slice(-2)}`
		}

		// Time row
		items.push(
			<>
				{editingOrder !== id && (
					<li className="" key="WHEN">
						<i className="mi">insert_invitation</i>{' '}
						<span>
							<strong>Para cuando</strong>
							{' - '}
							{notes[1] === 'now'
								? 'Lo más pronto posible'
								: `Para el ${day} de ${month} a las ${hour}`}
						</span>
					</li>
				)}
				{editingOrder === id && (
					<li className="" key="WHEN">
						<i className="mi">insert_invitation</i>{' '}
						<span>
							<strong>Para cuando</strong>
							{' - '}
							<DatePicker
								selected={selectedDate}
								onChange={(date) => {
									let ords = orders.map((or) => {
										if (or.invoiceID !== editingOrder) return or
										return {
											...or,
											notes: or.notes.map((n, m) => {
												let ind = 1
												if (or.notes.length === 4) ind = 2
												if (m === ind) {
													return date.getTime()
												}
												return n
											}),
										}
									})

									setOrders(ords)
									setSelectedDate(date)
								}}
								withPortal
								className="form-control"
								locale="es"
								showTimeSelect
								timeCaption={'Hora'}
								timeFormat="HH:mm"
								timeIntervals={30}
								dateFormat="dd/MM/yy - HH:mm"
								className="form-control"
								minDate={new Date()}
							/>
						</span>
					</li>
				)}
			</>
		)

		// Deliver
		let deliver = ''
		try {
			let ob = JSON.parse(notes[2]).name
			deliver = ob
		} catch (e) {
			deliver = allTables.find((t) => t.id === notes[2])
			if (typeof deliver !== 'undefined') deliver = deliver.name
		} finally {
			items.push(
				<li className="" key="HOW">
					<i className="mi">local_mall</i>{' '}
					<span>
						<strong>Forma de entrega</strong>
						{' - '}
						{notes[2] === 'pickup'
							? 'Para recoger'
							: notes[2] === 'inplace'
							? 'Para consumir aquí'
							: `Para la mesa ${deliver}`}
					</span>
				</li>
			)
		}

		if (notes[3]) {
			items.push(
				<li className="" key="NOTE">
					<i className="mi">note</i>{' '}
					<span>
						<strong>Notas del cliente</strong>
						{' - '}
						{`${notes[3]}`}
					</span>
				</li>
			)
		}

		return (
			<tr className="color-border last-item" key={id + '_NOTES'}>
				<td className="color"></td>
				<td colSpan="7" className="custom-notes">
					<h4>Información adicional</h4>
					<ul>
						{items}
						{/* {notes.map((a_note, i) => {
							return (
								<li style={{}}>
									<i className="mi">warning</i> <span>{a_note}</span>
								</li>
							)
						})} */}
					</ul>
				</td>
				<td className="color"></td>
			</tr>
		)
	}

	const renderOrders = (delivered = false) => {
		let items = []
		pendingOrders.forEach((order) => {
			items = [
				...items,
				...renderItems(order.items, order, delivered),
				renderNotes(order.notes, order.invoiceID),
			]
		})
		return items
	}

	if (tables === null) return <Loading />

	if (pendingOrders.length === 0)
		return (
			<aside className="empty">
				<i className="mi">receipt</i>
				<h3>No hay pedidos pendientes</h3>
			</aside>
		)

	return (
		<>
			{pendingOrders.length > 0 && (
				<div className="table-ui table-ui-groups">
					<div>
						<table>
							<thead>
								<tr>
									<th width="2" className="color"></th>
									{place.features.indexOf('tables') !== -1 && (
										<th className="center">{language.table}</th>
									)}
									<th></th>
									<th>{language.product}</th>
									<th className="center">{language.category}</th>
									<th>{language.additions}</th>
									<th>Precio</th>
									<th></th>
									<th width="2" className="color"></th>
								</tr>
							</thead>
							<FlipMove
								enterAnimation="accordionVertical"
								leaveAnimation="accordionVertical"
								duration={100}
								typeName="tbody"
							>
								{renderOrders()}
							</FlipMove>
						</table>
					</div>
				</div>
			)}
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		categories: state.categories,
		tables: state.tables,
		language: state.language.dictionary,
		topbar: state.topbar,
		user: state.user,
	}),
	{ create_alert }
)(Pending)

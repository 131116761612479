import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	AreaChart,
	Area,
	Line,
	LineChart,
	ResponsiveContainer,
} from 'recharts'

import { firestore } from '../../../config/firebase'

function Sales({ place, language, date, data }) {
	const [avgSales, setAvgSales] = useState(null)
	const [avgProfit, setAvgProfit] = useState(null)
	const [monthSales, setMonthSales] = useState(null)
	const [monthMoney, setMonthMoney] = useState(null)
	const [paymentMethods, setPaymentMethods] = useState(null)
	const [accQuantity, setAccQuantity] = useState(0)
	const [accMoney, setAccMoney] = useState(0)

	useEffect(() => {
		getVals(data)
	}, [])

	useEffect(() => {
		if (data !== null) getVals(data)
	}, [date])

	const getVals = (data) => {
		getAvgSales(data)
		getAvgProfit(data)
		getMonthSales(data)
		getMonthMoney(data)
		getPaymentMethods(data)
	}

	const upper = (lower) => lower.charAt(0).toUpperCase() + lower.substring(1)

	const getAvgSales = (data) => {
		let weekdays_names = [
			language.monday,
			language.tuesday,
			language.wednesday,
			language.thursday,
			language.friday,
			language.saturday,
			language.sunday,
		]
		let weekdays = []
		let weekdaysCount = [0, 0, 0, 0, 0, 0, 0]
		for (let x = 0; x < 7; x++) {
			weekdays.push({
				name: upper(weekdays_names[x]),
				quantity: 0,
			})
		}

		let sls = data.sales || {}

		Object.keys(sls).forEach((key) => {
			let day = sls[key]
			let _date = new Date()
			_date.setFullYear(parseInt(key.slice(-4)))
			_date.setMonth(parseInt(key.slice(3, 5)) - 1)
			_date.setDate(parseInt(key.slice(0, 2)))
			let weekDay = _date.getDay()
			weekDay -= 1
			if (weekDay < 0) weekDay = 6
			weekdays[weekDay] = {
				...weekdays[weekDay],
				quantity: weekdays[weekDay].quantity + day.quantity,
			}
			weekdaysCount[weekDay] = weekdaysCount[weekDay] + 1
		})

		let avg_sales = weekdays.map((wd, z) => ({
			...wd,
			quantity: weekdaysCount[z] === 0 ? 0 : wd.quantity / weekdaysCount[z],
		}))

		setAvgSales(avg_sales)
	}

	const getAvgProfit = (data) => {
		let weekdays_names = [
			language.monday,
			language.tuesday,
			language.wednesday,
			language.thursday,
			language.friday,
			language.saturday,
			language.sunday,
		]
		let weekdays = []
		let weekdaysCount = [0, 0, 0, 0, 0, 0, 0]
		for (let x = 0; x < 7; x++) {
			weekdays.push({
				name: upper(weekdays_names[x]),
				quantity: 0,
			})
		}

		let sls = data.sales || {}

		Object.keys(sls).forEach((key) => {
			let day = sls[key]
			let _date = new Date()
			_date.setFullYear(parseInt(key.slice(-4)))
			_date.setMonth(parseInt(key.slice(3, 5)) - 1)
			_date.setDate(parseInt(key.slice(0, 2)))
			let weekDay = _date.getDay()
			weekDay -= 1
			if (weekDay < 0) weekDay = 6
			weekdays[weekDay] = {
				...weekdays[weekDay],
				quantity: weekdays[weekDay].quantity + day.money,
			}
			weekdaysCount[weekDay] = weekdaysCount[weekDay] + 1
		})

		let avg_sales = weekdays.map((wd, z) => ({
			...wd,
			quantity: weekdaysCount[z] === 0 ? 0 : wd.quantity / weekdaysCount[z],
		}))

		setAvgProfit(avg_sales)
	}

	const getMonthSales = (data) => {
		let days = []
		let _accumulated = 0
		let today = new Date(date)
		let month_length = new Date(
			today.getFullYear(),
			today.getMonth() + 1,
			0
		).getDate()

		for (let x = 0; x < month_length; x++) {
			let formatted_date = `${`0${x + 1}`.slice(-2)}/${`0${
				today.getMonth() + 1
			}`.slice(-2)}/${today.getFullYear()}`
			let sls = data.sales || {}
			let day_data = sls[formatted_date]
			if (typeof day_data === 'undefined') {
				days.push({
					accumulated: _accumulated,
					quantity: 0,
					name: `0${x + 1}`.slice(-2),
				})
			} else {
				_accumulated += day_data.quantity

				days.push({
					accumulated: _accumulated,
					quantity: day_data.quantity,
					name: `0${x + 1}`.slice(-2),
				})
			}
		}
		setAccQuantity(_accumulated)
		setMonthSales(days)
	}

	const getMonthMoney = (data) => {
		let days = []
		let _accumulated = 0
		let today = new Date(date)
		let month_length = new Date(
			today.getFullYear(),
			today.getMonth() + 1,
			0
		).getDate()

		for (let x = 0; x < month_length; x++) {
			let formatted_date = `${`0${x + 1}`.slice(-2)}/${`0${
				today.getMonth() + 1
			}`.slice(-2)}/${today.getFullYear()}`
			let sls = data.sales || {}
			let day_data = sls[formatted_date]
			if (typeof day_data === 'undefined') {
				days.push({
					accumulated: _accumulated,
					quantity: 0,
					name: `0${x + 1}`.slice(-2),
				})
			} else {
				_accumulated += day_data.money
				days.push({
					accumulated: _accumulated,
					quantity: day_data.money,
					name: `0${x + 1}`.slice(-2),
				})
			}
		}
		setAccMoney(_accumulated)
		setMonthMoney(days)
	}

	const getPaymentMethods = (data) => {
		let methods = [
			{ name: 'Efectivo', quantity: 0 },
			{ name: 'Tarjeta', quantity: 0 },
		]

		if (typeof data.payment_methods !== 'undefined') {
			methods[0] = {
				...methods[0],
				quantity: data.payment_methods.cash,
			}
			methods[1] = {
				...methods[1],
				quantity: data.payment_methods.card,
			}
		}

		setPaymentMethods(methods)
	}

	if (monthSales === null)
		return (
			<aside className="empty">
				<i className="mi">insert_chart</i>
				<h3>No hay estadísticas de ventas.</h3>
			</aside>
		)

	return (
		<>
			<div className="ui-stats">
				{monthSales !== null && (
					<div className="stat">
						<div>
							<h1>
								Ventas del mes
								<span className="badge">{accQuantity}</span>
							</h1>
							<div className="wrapper">
								<ResponsiveContainer width="100%" height="100%">
									<LineChart data={monthSales}>
										<CartesianGrid
											strokeDasharray="1 0"
											stroke="#dee2e6"
										/>
										<XAxis dy={5} dataKey="name" />
										<YAxis
											dx={-5}
											domain={[10, 'dataMax + 10']}
											type="number"
										/>
										<Tooltip
											formatter={(value, name, props) => value}
											labelFormatter={(value, name, props) =>
												`${upper(
													new Date(date).toLocaleString('es-ES', {
														month: 'long',
													})
												)} ${value}`
											}
											isAnimationActive={false}
											cursor={{ stroke: '#000', strokeWidth: 3 }}
										/>
										<Legend />
										<Line
											type="linear"
											dataKey="quantity"
											label="Cantidad"
											name="Cantidad"
											stroke="#2589f9"
											strokeWidth="3"
										/>
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
				)}
				{monthMoney !== null && (
					<div className="stat">
						<div>
							<h1>
								Dinero del mes
								<span className="badge">{accMoney} €</span>
							</h1>
							<div className="wrapper">
								<ResponsiveContainer width="100%">
									<LineChart data={monthMoney}>
										<CartesianGrid
											strokeDasharray="1 0"
											stroke="#dee2e6"
										/>
										<XAxis dy={5} dataKey="name" />
										<YAxis
											dx={-5}
											unit="€"
											domain={[10, 'dataMax']}
											domain={[10, 'dataMax + 10']}
											type="number"
										/>

										<Tooltip
											formatter={(value, name, props) => value}
											labelFormatter={(value, name, props) =>
												`${upper(
													new Date(date).toLocaleString('es-ES', {
														month: 'long',
													})
												)} ${value}`
											}
											isAnimationActive={false}
											cursor={{ stroke: '#000', strokeWidth: 3 }}
										/>
										<Legend />
										<Line
											connectNulls
											type="linear"
											dataKey="quantity"
											name="Cantidad"
											stroke="#2589f9"
											strokeWidth="3"
										/>
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
				)}

				{avgSales !== null && (
					<div className="stat">
						<div>
							<h1>Días de la semana con mayores ventas</h1>
							<div className="wrapper">
								<ResponsiveContainer width="100%">
									<BarChart data={avgSales}>
										<XAxis dy={5} dataKey="name" />
										<YAxis dx={-5} />
										<CartesianGrid
											strokeDasharray="1 0"
											stroke="#dee2e6"
										/>
										<Tooltip
											formatter={(value, name, props) => value}
											labelFormatter={(value, name, props) =>
												upper(value)
											}
											isAnimationActive={false}
											cursor={{
												stroke: '#000',
												strokeWidth: 0,
												fill: 'rgba(0,0,0,0.05)',
											}}
										/>
										<Bar
											radius={[10, 10, 0, 0]}
											dataKey="quantity"
											fill="#2589f9"
											legendType="none"
											name="Cantidad"
										/>
									</BarChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
				)}
				{avgProfit !== null && (
					<div className="stat">
						<div>
							<h1>Días de la semana con mayores ingresos</h1>
							<div className="wrapper">
								<ResponsiveContainer width="100%">
									<BarChart data={avgProfit}>
										<XAxis dy={5} dataKey="name" />
										<YAxis dx={-5} unit="€" />
										<CartesianGrid
											strokeDasharray="1 0"
											stroke="#dee2e6"
										/>
										<Tooltip
											formatter={(value, name, props) => value}
											labelFormatter={(value, name, props) =>
												upper(value)
											}
											isAnimationActive={false}
											cursor={{
												stroke: '#000',
												strokeWidth: 0,
												fill: 'rgba(0,0,0,0.05)',
											}}
										/>
										<Bar
											radius={[10, 10, 0, 0]}
											dataKey="quantity"
											fill="#2589f9"
											legendType="none"
											name="Euros"
										/>
									</BarChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
				)}
				{paymentMethods !== null && (
					<div className="stat stat-wide">
						<div>
							<h1>Medios de pago</h1>
							<div className="wrapper">
								<ResponsiveContainer width="100%">
									<BarChart data={paymentMethods} layout="vertical">
										<XAxis
											dy={5}
											type="number"
											domain={[0, 'dataMax + 5']}
										/>
										<YAxis
											dx={-5}
											dataKey="name"
											type="category"
											width={200}
										/>
										<CartesianGrid
											strokeDasharray="1 0"
											stroke="#dee2e6"
										/>
										<Tooltip
											isAnimationActive={false}
											cursor={{
												stroke: '#000',
												strokeWidth: 0,
												fill: 'rgba(0,0,0,0.05)',
											}}
										/>
										<Bar
											radius={[0, 10, 10, 0]}
											dataKey="quantity"
											fill="#2589f9"
											legendType="none"
											name="Cantidad"
										/>
									</BarChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default connect((state) => ({
	place: state.place,
	language: state.language.dictionary,
}))(Sales)

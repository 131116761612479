import React, { useEffect, useState } from 'react'
import { firestore } from '../../config/firebase'

function Invoice() {
	const [invoiceID, setInvoiceID] = useState(null)
	const [invoice, setInvoice] = useState(null)

	useEffect(() => {
		setInvoiceID(decodeURI(window.location.pathname.split('/')[2]))
	}, [])

	useEffect(() => {
		if (invoiceID !== null) {
			let app = document.getElementsByTagName('body')[0]
			app.classList.add('invoice-print')
			let placeID = decodeURI(
				window.location.pathname.split('_INV_')[0].split('/')[2]
			)
			firestore
				.collection('places')
				.doc(placeID)
				.collection('invoices')
				.doc(invoiceID)
				.get()
				.then(doc => {
					if (doc.exists) {
						setInvoice(doc.data())
					} else {
						setInvoice(false)
					}
				})
				.catch(e => {
					setInvoice(false)
				})
		}
	}, [invoiceID])

	const renderProducts = () => {
		return invoice.items.map(prod => {
			let adds = []
			let qrs = []
			if (typeof prod.additions !== 'undefined') {
				prod.additions.forEach(add => {
					add.items.forEach(it => {
						if (it.quantity > 0) {
							adds.push(
								<tr key={it.name}>
									<td>
										{' >>> '}
										{it.name} {it.quantity}
									</td>
									<td>{(it.value * it.quantity).toFixed(2)} €</td>
								</tr>
							)
						}
					})
				})
			}
			return (
				<>
					<tr key={prod.id}>
						<td>{prod.name}</td>
						<td>{prod.price.toFixed(2)} €</td>
					</tr>
					{adds}
					{typeof prod.qr !== 'undefined' && (
						<div
							style={{
								width: 200,
								height: 200,
								backgroundImage: `url(${prod.qr})`,
								backgroundPosition: 'center',
								backgroundSize: 'cover'
							}}
						></div>
					)}
				</>
			)
		})
	}

	if (invoice === null) return <p>...Loading...</p>

	if (invoice === false) return <p>Error</p>

	let date = new Date(invoice.date)

	return (
		<>
			<table>
				<tbody>
					<tr>
						<td colSpan="2">{`${date.toLocaleDateString()} - ${date.getHours()}:${date.getMinutes()}`}</td>
					</tr>
					<tr>
						<td colSpan="2">{invoice.place}</td>
					</tr>
					<tr>
						<td colSpan="2">{invoice.place_address}</td>
					</tr>
					<tr>
						<td colSpan="2">Cashier: {invoice.seller}</td>
					</tr>
					{renderProducts()}
					<tr>
						<td>Subtotal</td>
						<td>{(invoice.amount - invoice.taxes).toFixed(2)} €</td>
					</tr>
					<tr>
						<td>Taxes</td>
						<td>
							{invoice.taxes.toFixed(2)}€ (
							{Math.round((invoice.taxes * 100) / invoice.amount)}%)
						</td>
					</tr>
					<tr>
						<td>Total</td>
						<td>{invoice.amount.toFixed(2)}€</td>
					</tr>
					{invoice.dni !== 'anonymous' && (
						<>
							<tr>
								<td>Client's ID:</td>
								<td>{invoice.dni}</td>
							</tr>
							<tr>
								<td>Client's name:</td>
								<td>
									{invoice.first_name} {invoice.last_name}
								</td>
							</tr>
						</>
					)}
					{/* <tr>
						<td colSpan="2"># Invoice: {invoice.invoiceID}</td>
					</tr> */}
				</tbody>
			</table>
			<button onClick={() => window.print()}>Print</button>
		</>
	)
}

export default Invoice

import React from 'react'

function Spinner({ label }) {
	return (
		<div className="loading">
			<div className="spinner spinner2"></div>
		</div>
	)
}

export default Spinner

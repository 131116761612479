import React, { useEffect, useState, useRef } from 'react'
import FlipMove from 'react-flip-move'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { Loading } from '../../../components'

import Chrono from '../../../components/Chrono'
import { firestore, functions, auth } from '../../../config/firebase'
import { isMobile } from 'react-device-detect'

import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'
import { create_alert } from '../../../store/actions'
import { useCookies } from 'react-cookie'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const months = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre',
]

function History({
	place,
	categories,
	tables,
	create_alert,
	language,
	topbar,
	user,
}) {
	const [allTables, setAllTables] = useState([])
	const [editingOrder, setEditingOrder] = useState(null)
	const [printInvoice, setPrintInvoice] = useState(false)
	const [submittingOrder, setSubmittingOrder] = useState(null)
	const [selectedDate, setSelectedDate] = useState(new Date())
	const invoiceRef = useRef(null)
	const [cookies, setCookie, removeCookie] = useCookies(['print'])

	const [orders, setOrders] = useState([])

	useEffect(() => {
		let alltables = []
		tables.floors.forEach((floor) => {
			floor.tables.forEach((table) => {
				alltables.push(table)
			})
		})
		setAllTables(alltables)

		firestore
			.doc(`places/${place.id}/archive/orders`)
			.get()
			.then((res) => {
				let data = res.data()
				data = data.orders
				data = data.sort((a, b) => b.invoiceNumb - a.invoiceNumb)
				let indexes = []
				data = data.filter((a) => {
					if (indexes.indexOf(a.invoiceNumb) === -1) {
						indexes.push(a.invoiceNumb)
						return true
					}
					return false
				})
				setOrders(data)
			})
			.catch((e) => {})
	}, [])

	const getUnitName = (u = 'u', quantity) => {
		switch (u) {
			case 'u':
				if (quantity > 1) return 'Unidades'
				return 'Unidad'
			case 'kg':
				if (quantity > 1) return 'Kilos'
				return 'Kilo'
			case 'lt':
				if (quantity > 1) return 'Litros'
				return 'Litro'
			case 'pt':
				if (quantity > 1) return 'Raciones'
				return 'Ración'
			case 'lb':
				if (quantity > 1) return 'Libras'
				return 'Libra'
		}
	}

	const printOnDesktop = async (order) => {
		let id = Math.floor(Math.random() * 100000000000)
		let obj = {
			order,
			place,
			id,
		}
		await firestore.runTransaction(async (trans) => {
			let latestData = await trans.get(
				firestore.doc(
					`/users/${auth.currentUser.uid}/printers/${cookies.print}`
				)
			)
			latestData = latestData.data()
			let q = latestData.queue || []
			trans.update(
				firestore.doc(
					`/users/${auth.currentUser.uid}/printers/${cookies.print}`
				),
				{
					queue: [...q, obj],
				}
			)
		})
	}

	const renderInvoice = () => {
		let total = 0
		let total_taxed = 0
		printInvoice.items.forEach((it) => {
			let qty = it.quantity || it.units
			let it_charge = it.price_type === 'units' ? it.price * qty : it.price
			total += it_charge
			total_taxed +=
				it.taxrate && it.taxrate > 0 ? it_charge * (it.taxrate / 100) : 0
			it.additions.forEach((add) => {
				add.items.forEach((_it) => {
					if (_it.quantity > 0) {
						total += _it.value * _it.quantity
						total_taxed +=
							it.taxrate && it.taxrate > 0
								? _it.value * _it.quantity * (it.taxrate / 100)
								: 0
					}
				})
			})
		})

		let billing_info = Object.keys(place.billing).map((info_key) => {
			return (
				<span>
					{language[info_key]}: {place.billing[info_key]}
				</span>
			)
		})

		let invoiceDate = new Date(printInvoice.date)
		let invoiceTime = `${`0${invoiceDate.getHours()}`.slice(
			-2
		)}:${`0${invoiceDate.getMinutes()}`.slice(-2)}`

		return (
			<div ref={invoiceRef} id="PRINT_INVOICE">
				<h3>{place.name}</h3>
				<strong>FACTURA #{printInvoice.invoiceNumb}</strong>
				<p className="billing">
					<span>
						Fecha: {invoiceDate.toLocaleDateString()} {invoiceTime}
					</span>
					{billing_info}
				</p>
				<table>
					<thead>
						<tr>
							<th>Producto</th>
							<th>Cantidad</th>
							<th>Imp.</th>
							<th>Valor</th>
						</tr>
					</thead>
					<tbody>
						{printInvoice.items.map((it) => {
							let additions = []
							let qty = it.units || it.quantity
							let it_total =
								it.price_type === 'units' ? it.price * qty : it.price
							let it_taxed =
								it.taxrate && it.taxrate > 0
									? it_total * (it.taxrate / 100)
									: 0
							it.additions.forEach((add) => {
								let hasAny = false
								add.items.forEach((_it) => {
									if (_it.quantity > 0) hasAny = true
								})
								if (hasAny)
									additions.push({
										...add,
										items: add.items.filter((a) => a.quantity > 0),
									})
							})

							let adds_rows = additions.map((add) => (
								<>
									<tr>
										<td colSpan="4">— {add.name}</td>
									</tr>
									{add.items.map((it_) => {
										let it__charge =
											typeof it_.quantity !== 'undefined'
												? it_.quantity * it_.value
												: it_.value
										let it__taxed =
											it.taxrate && it.taxrate > 0
												? it__charge * (it.taxrate / 100)
												: 0
										return (
											<tr>
												<td>—— {it_.name}</td>
												<td>{it_.quantity}</td>
												<td>{it__taxed.toFixed(2)} €</td>
												<td>{it__charge} €</td>
											</tr>
										)
									})}
								</>
							))

							return (
								<>
									<tr>
										<td>
											<strong>{it.name}</strong>
										</td>
										<td>
											{it.price_type === 'units'
												? `${qty} ${getUnitName(it.unit, qty)}`
												: 1}
										</td>
										<td>{it_taxed.toFixed(2)} €</td>
										<td>{it_total} €</td>
									</tr>
									{adds_rows}
								</>
							)
						})}
						<tr>
							<td colSpan="3">
								<strong>Total:</strong>
							</td>

							<td>
								<strong>{total} €</strong>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}

	const renderAdditions = (item) => {
		if (typeof item.additions !== 'undefined') {
			let adds_dom = item.additions.map((add) => {
				let no_adds = true
				let add_items = add.items.map((it) => {
					if (typeof it.quantity === 'undefined' || it.quantity <= 0)
						return null
					no_adds = false
					return (
						<li key={it.name}>
							<span>{it.name}</span>
						</li>
					)
				})
				if (no_adds) return null
				return (
					<div className="additions" key={add.name}>
						<b>{add.name}</b>
						<ul>{add_items}</ul>
					</div>
				)
			})
			return <>{adds_dom}</>
		}
	}

	const doInvoice = (order) => {
		if (!isMobile)
			htmlToImage
				.toPng(document.getElementById('PRINT_INVOICE'))
				.then(function (dataUrl) {
					download(dataUrl, `${place.id}-factura-${order.invoiceNumb}.png`)
					setPrintInvoice(false)
				})
	}

	const chuck = (str, n) => {
		let ret = []
		let i
		let len

		for (i = 0, len = str.length; i < len; i += n) {
			ret.push(str.substr(i, n))
		}

		return ret
	}

	const renderItems = (items, order, delivered, index) => {
		if (items.length === 0) return []

		let _items = []

		let opacity = submittingOrder === order.invoiceID ? 0.3 : 1

		let orderName =
			order.invoiceID.split('_')[1] !== 'PICKUP'
				? `${language.order}`
				: `${language.order_from} ${order.first_name} `

		let orderNumb =
			order.invoiceID.split('_')[1] !== 'PICKUP'
				? `#${order.invoiceNumb}`
				: ` #${order.invoiceNumb}`

		_items.push(
			<tr
				className={`sep`}
				key={order.invoiceID + '_HEAD'}
				style={{
					opacity,
				}}
			>
				<td colSpan="8">
					<h4>
						<div className="title">
							{orderName}{' '}
							<span className="order-name-text">{orderNumb}</span>
						</div>
						<div className="buttons">
							<button
								className="button inline button-light button-small"
								// disabled={submittingOrder === order.invoiceID}
								// onClick={() => changeOrderState(2, order)}
								style={{
									marginLeft: 20,
								}}
								disabled={submittingOrder !== null}
								onClick={() => {
									if (cookies.print) {
										printOnDesktop(order)
										create_alert(
											`Imprimiendo en ${cookies.print}`,
											'success'
										)
									} else {
										setPrintInvoice(order)
										setTimeout(() => {
											doInvoice(order)
										}, 200)
									}
								}}
								type="button"
							>
								<i className="mi">receipt</i> <span>Factura</span>
							</button>
							{index === 0 && (
								<button
									className="button inline button-danger button-small"
									style={{
										marginLeft: 20,
									}}
									disabled={submittingOrder !== null}
									onClick={async () => {
										// setPrintInvoice(order)
										// setTimeout(() => {
										// 	doInvoice(order)
										// }, 200)
										try {
											setSubmittingOrder(true)

											let lat = await firestore.runTransaction(
												async (transaction) => {
													let latestArchive = await transaction.get(
														firestore.doc(
															`places/${place.id}/archive/orders`
														)
													)
													latestArchive = latestArchive.data()

													let latestPlaceData = await transaction.get(
														firestore.doc(`places/${place.id}`)
													)
													latestPlaceData = latestPlaceData.data()

													transaction.update(
														firestore.doc(
															`places/${place.id}/archive/orders`
														),
														{
															orders: latestArchive.orders.filter(
																(o) =>
																	o.invoiceID !==
																	order.invoiceID
															),
														}
													)

													transaction.update(
														firestore.doc(`places/${place.id}`),
														{
															invoiceNumb:
																latestPlaceData.invoiceNumb - 1,
														}
													)
													return latestArchive
												}
											)

											let ords = lat.orders.filter(
												(o) => o.invoiceID !== order.invoiceID
											)
											ords = ords.sort(
												(a, b) => b.invoiceNumb - a.invoiceNumb
											)
											let indexes = []
											ords = ords.filter((a) => {
												if (indexes.indexOf(a.invoiceNumb) === -1) {
													indexes.push(a.invoiceNumb)
													return true
												}
												return false
											})
											setOrders(ords)
											setSubmittingOrder(null)
										} catch (e) {
										} finally {
										}
									}}
									type="button"
								>
									<i className="mi">close</i> <span>Borrar</span>
								</button>
							)}
						</div>
					</h4>
				</td>
			</tr>
		)

		items.forEach((item, x) => {
			let tableRef = allTables.find((el) => el.id === order.table)

			let _date = order.date
			if (typeof item.date !== 'undefined') _date = item.date
			// let promo_img_clss = item.category === 'promotions' || item.category === 'menu' ? 'promo' : ''
			let product_img =
				item.image !== 'no_image'
					? { backgroundImage: `url(${item.image})` }
					: {}
			let last_clss =
				x == items.length - 1 &&
				(typeof order.notes === 'undefined' ||
					order.notes === null ||
					(order.notes.length && !order[0]))
					? 'last-item'
					: ''
			_items.push(
				<tr
					key={`${item.cartId}_${x}_${order.invoiceID}_${order.date}`}
					className={`color-border ${last_clss}`}
					style={{
						opacity,
					}}
				>
					<td className="color"></td>
					{tables !== null && place.features.indexOf('tables') !== -1 && (
						<td className="center">
							{typeof tableRef === 'undefined'
								? language.none
								: tableRef.name}
						</td>
					)}
					<td
						className="center"
						style={{
							position: 'relative',
						}}
					>
						<span className="img" style={product_img}></span>
					</td>
					<td key={item.id}>
						<strong>
							{item.name}{' '}
							{item.price_type === 'units' &&
								` - ${item.quantity} ${getUnitName(
									item.unit,
									item.quantity
								)}`}
						</strong>
					</td>
					<td className="center">{item.category}</td>
					<td>{renderAdditions(item)}</td>
					<td>
						<span className="price">
							<pre>{item.price.toFixed(2)}</pre>
							<i className="mi">euro_symbol</i>
						</span>
					</td>
					<td className="color"></td>
				</tr>
			)
		})

		return _items
	}

	const renderNotes = (_notes, id) => {
		return null
	}

	const renderOrders = (delivered = false) => {
		let items = []
		orders.forEach((order, x) => {
			items = [
				...items,
				...renderItems(order.items, order, delivered, x),
				renderNotes(order.notes, order.invoiceID),
			]
		})
		return items
	}

	if (tables === null) return <Loading />

	if (orders.length === 0)
		return (
			<aside className="empty">
				<i className="mi">receipt</i>
				<h3>No hay pedidos pendientes</h3>
			</aside>
		)

	return (
		<>
			{orders.length > 0 && (
				<div className="table-ui table-ui-groups">
					<div>
						<table>
							<thead>
								<tr>
									<th width="2" className="color"></th>
									{place.features.indexOf('tables') !== -1 && (
										<th className="center">{language.table}</th>
									)}
									<th></th>
									<th>{language.product}</th>
									<th className="center">{language.category}</th>
									<th>{language.additions}</th>
									<th>Precio</th>
									<th width="2" className="color"></th>
								</tr>
							</thead>
							<FlipMove
								enterAnimation="accordionVertical"
								leaveAnimation="accordionVertical"
								duration={100}
								typeName="tbody"
							>
								{renderOrders()}
							</FlipMove>
						</table>
					</div>
				</div>
			)}
			{printInvoice !== false && renderInvoice()}
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		categories: state.categories,
		tables: state.tables,
		language: state.language.dictionary,
		topbar: state.topbar,
		user: state.user,
	}),
	{ create_alert }
)(History)

import QRCode from 'qrcode'
import React, { useEffect, useState } from 'react'
import FlipMove from 'react-flip-move'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Chrono, Input, ModalForm, More, TablesMap } from '../../../components'
import firebase, { firestore, functions } from '../../../config/firebase'

function Queue({ place, tables, language, topbar }) {
	const [inputName, setInputName] = useState('')
	const [inputQuantity, setInputQuantity] = useState(NaN)
	const [addModal, setAddModal] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const [selectTable, setSelectTable] = useState(null)
	const [selectedTable, setSelectedTable] = useState(null)
	const [qr, setQr] = useState(null)

	useEffect(() => {
		getQR()
	}, [])

	const increment = firebase.firestore.FieldValue.increment(1)

	const renderQueue = () => {
		let queue = [...place.queue].reverse()
		let all_tables = []
		tables.floors.forEach((floor) => {
			all_tables = [...all_tables, ...floor.tables]
		})
		return queue.map((item, i) => {
			let tables_indexes = all_tables.filter(
				(table) => parseInt(table.seats) >= item.party_of
			)
			tables_indexes = tables_indexes.map((table) => table.id)
			place.orders.forEach((ord) => {
				let index = tables_indexes.indexOf(ord.table)
				if (index !== -1) tables_indexes.splice(index, 1)
			})
			return (
				<tr key={item.when}>
					<td>
						<span className="badge">{i + 1}</span>
					</td>
					<td>
						<Chrono time={item.when} nodays showSeconds showHours />
					</td>
					<td>
						<strong>{item.name}</strong>
					</td>
					<td>{item.how}</td>
					{place.features.indexOf('tables') !== -1 && (
						<>
							<td>{isNaN(item.party_of) ? '' : item.party_of}</td>
							<td>{renderAlert(tables_indexes)}</td>
							<td>
								<button
									onClick={() => setSelectTable(item)}
									type="button"
									className="button button-light button-small"
									title={language.continue}
									disabled={submitting}
								>
									<span>Asignar mesa</span>
								</button>
							</td>
						</>
					)}
					<td>
						<button
							onClick={() => removeItem(item)}
							type="button"
							className="button button-primary  button-small"
							title={language.continue}
							disabled={submitting}
						>
							<span>{language._continue}</span>
						</button>
					</td>

					<td>
						<button
							onClick={() => removeItem(item)}
							type="button"
							className="button button-outline button-small"
							title={language.cancel}
							disabled={submitting}
							title="Borrar"
						>
							<i className="mi">delete</i>
						</button>
					</td>
				</tr>
			)
		})
	}

	const removeItem = async (item) => {
		setSubmitting(true)
		let placeRef = firestore.doc(`/places/${place.id}`)
		let placeListRef = firestore.doc(`/places_list/${place.id}`)
		let new_first = await firestore.runTransaction(async (transaction) => {
			let placeData = await transaction.get(placeRef)
			let placeListData = await transaction.get(placeListRef)
			placeData = placeData.data()
			placeListData = placeListData.data()

			if (item.how === 'App') {
				const fn = functions.httpsCallable('removeFromQueue')
				await fn({ client_id: item.id })
			}

			let newQueue = placeData.queue.filter((it) => it.id !== item.id)

			await transaction.update(placeRef, {
				queue: newQueue,
			})
			await transaction.update(placeListRef, {
				queue: placeListData.queue.filter((x) => x !== item.id),
			})

			return [newQueue[newQueue.length - 1], newQueue[newQueue.length - 3]]
		})
		if (new_first[0] && new_first[0].how === 'App') {
			const _func = functions.httpsCallable('sendNotification')
			await _func({
				content: place.name + ' - Eres el primero en la fila',
				client_id: new_first[0].id,
			})
		}
		if (new_first[1] && new_first[1].how === 'App') {
			const _func = functions.httpsCallable('sendNotification')
			await _func({
				content: place.name + ' - Eres el tercero en la fila',
				client_id: new_first[1].id,
			})
		}

		setSubmitting(false)
	}

	const renderAlert = (tables_indexes) => {
		let content =
			tables_indexes.length === 0
				? language.no_tables
				: tables_indexes.length === 1
				? language.one_table
				: language.x_tables.replace('x', tables_indexes.length)
		return (
			<span
				className={`badge badge-${
					tables_indexes.length > 0 ? 'success' : 'danger'
				}`}
			>
				{content}
			</span>
		)
	}

	const renderAddButton = () => {
		return (
			<button
				className="button button-primary button-add inline"
				onClick={(e) => setAddModal(!addModal)}
			>
				<i className="mi">add</i> <span>{language.add_people}</span>
			</button>
		)
	}

	const addToQueue = () => {
		setSubmitting(true)
		if (inputName.length === 0) {
			setSubmitting(false)
			return
		}

		let id = `manually_${Date.now()}_${Math.floor(Math.random() * 1000)}`

		let placeRef = firestore.doc(`/places/${place.id}`)

		firestore
			.runTransaction(async (transaction) => {
				try {
					let placeListData = await transaction.get(
						firestore.doc(`/places_list/${place.id}`)
					)
					placeListData = placeListData.data()

					let queue =
						typeof placeListData.queue === 'object'
							? placeListData.queue
							: []

					await transaction.update(placeRef, {
						queue: [
							{
								id,
								name: inputName,
								how: language.manually,
								when: Date.now(),
								party_of: inputQuantity,
							},
							...place.queue,
						],
					})

					await transaction.update(
						firestore.doc(`/places_list/${place.id}`),
						{
							queue: [id, ...queue],
						}
					)
				} catch (e) {}
			})
			.then(() => {
				setInputName('')
				setInputQuantity(0)
				setSubmitting(false)
				setAddModal(false)
			})
			.catch((e) => {})
	}

	const getQR = () => {
		let obj = {
			place: place.id,
			created: Date.now(),
			id: Math.floor(Math.random() * 100000000000000),
			type: 'lineup',
		}
		QRCode.toDataURL(JSON.stringify(obj), async (err, _url) => {
			setQr(_url)
		})
	}

	const renderSelectTable = () => {
		if (selectTable === null) return null
		return (
			<ModalForm toggle={() => setSelectTable(null)}>
				<div>
					<form
						onSubmit={async (e) => {
							e.preventDefault()

							try {
								setSubmitting(true)
								let newOrder = true
								let _orders = null
								let invoiceID = 0
								let order = null
								let paid = true

								let placeRef = firestore
									.collection('places')
									.doc(place.id)

								firestore
									.runTransaction(async (transaction) => {
										let latestPlace = await transaction.get(placeRef)
										latestPlace = latestPlace.data()

										if (
											typeof latestPlace.invoiceNumb !== 'undefined'
										)
											invoiceID = latestPlace.invoiceNumb + 1
										invoiceID = `_${invoiceID}`

										await transaction.update(placeRef, {
											invoiceNumb: latestPlace.invoiceNumb + 1,
										})

										order = {
											invoiceID: invoiceID,
											address: latestPlace.address.value,
											date: Date.now(),
											items: [],
											table: selectedTable.id,
											confirmed: true,
											invoiceNumb: latestPlace.invoiceNumb + 1,
											notes: [null],
											status: 1,
										}

										_orders = [...latestPlace.orders, order]

										await transaction.update(placeRef, {
											orders: _orders,
										})
									})
									.then(async () => {
										await removeItem(selectTable)
										setSubmitting(false)
										setSelectTable(null)
									})
							} catch (e) {}
						}}
						className="form-ui"
					>
						<h1>{language.select_a_table}</h1>
						<div
							style={{
								width: '100%',
								height: 500,
								padding: 20,
								position: 'relative',
							}}
						>
							<TablesMap
								small_version={true}
								only_free
								onTableSelected={(table) => setSelectedTable(table)}
							/>
						</div>
						<footer>
							<button
								disabled={submitting || selectedTable === null}
								type="submit"
								className="button button-block button-primary"
							>
								<span>{language.continue}</span>
							</button>
						</footer>
					</form>
				</div>
			</ModalForm>
		)
	}

	if (tables === null) return null

	return (
		<>
			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs"></ul>
				<div className="actions">
					{place.require_queue_qr && (
						<a
							className="button button-light inline"
							download={`${place.id}_lineup`}
							href={qr}
							title="Download"
						>
							<i className="mi">fullscreen</i>{' '}
							<span>{language.get_lineup_qr}</span>
						</a>
					)}
					{renderAddButton()}
				</div>
			</div>
			{renderSelectTable()}
			{place.queue.length === 0 && (
				<aside className="empty">
					<i className="mi">supervisor_account</i>
					<h3>{language.no_queue}</h3>
					{renderAddButton()}
				</aside>
			)}
			{place.queue.length > 0 && (
				<div className="table-ui">
					<div>
						<table>
							<thead>
								<tr>
									<th>{language.position}</th>
									<th>Tiempo en espera</th>
									<th>{language.name}</th>
									<th>{language.added_by}</th>
									{place.features.indexOf('tables') !== -1 && (
										<>
											<th>{language.party_of}</th>
											<th>{language.availability}</th>
											<th />
										</>
									)}
									<th></th>
									<th></th>
								</tr>
							</thead>
							<FlipMove
								enterAnimation="accordionVertical"
								leaveAnimation="accordionVertical"
								duration={100}
								typeName="tbody"
							>
								{renderQueue()}
							</FlipMove>
						</table>
					</div>
				</div>
			)}
			{addModal === true && (
				<ModalForm toggle={setAddModal}>
					<div>
						<form
							onSubmit={(e) => {
								e.preventDefault()
								addToQueue()
							}}
							className="form-ui"
						>
							<h1>{language.add_people}</h1>
							<Input
								noformik
								type="text"
								name="name"
								label={language.name}
								custom={{
									onChange: (e) => setInputName(e.target.value),
									value: inputName,
								}}
							/>
							{place.features.indexOf('tables') !== -1 && (
								<Input
									noformik
									type="number"
									name="party_of"
									label={language.party_of}
									custom={{
										onChange: (e) => {
											if (e.target.value >= 1)
												setInputQuantity(e.target.value)
											else setInputQuantity('')
										},
										value: inputQuantity,
									}}
								/>
							)}
							<footer>
								<button
									disabled={submitting}
									type="submit"
									className="button button-block"
								>
									<i className="mi">add</i>
									<span>{language.add}</span>
								</button>
							</footer>
						</form>
					</div>
				</ModalForm>
			)}
		</>
	)
}

export default connect((state) => ({
	place: state.place,
	tables: state.tables,
	language: state.language.dictionary,
	topbar: state.topbar,
}))(Queue)

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { functions } from '../../config/firebase'
import { Loading } from '../../components'

function Chat({ topbar, place }) {
	const [currentChats, setCurrentChats] = useState(null)
	const [openChat, setOpenChat] = useState(null)
	const [message, setMessage] = useState('')

	useEffect(() => {
		let chats = []
		if (typeof place.chat !== 'undefined') {
			Object.keys(place.chat).forEach((k) => {
				let chat = place.chat[k]
				chats.push({
					key: k,
					chat,
				})
				if (openChat !== null && openChat.key === k)
					setOpenChat({
						key: k,
						chat,
					})
			})
		}
		setCurrentChats(chats)
	}, [place.chat])

	const removeChat = async () => {
		let fn = functions.httpsCallable('endChat')
		let cus = openChat.chat[0].customer

		await fn({
			administrator: place.id,
			customer: cus,
		})
	}

	const toHour = (date) => {
		let dt = new Date(date)
		return `${`0${dt.getHours()}`.slice(-2)}:${`0${dt.getMinutes()}`.slice(
			-2
		)}`
	}

	const sendMessage = async () => {
		try {
			let cus = openChat.chat[0].customer
			let cus_name = openChat.chat[0].customer_name
			let msg = `${message}`
			setMessage('')
			let _msg = {
				fake: true,
				message: msg,
				id: `fake_${Math.floor(Math.random() * 1000)}`,
				sender: place.id,
				customer: cus,
			}

			setOpenChat({ ...openChat, chat: [...openChat.chat, _msg] })
			let fn = functions.httpsCallable('sendChat')
			let res = await fn({
				message: msg,
				administrator: place.id,
				customer: cus,
				name: cus_name,
				sender: place.id,
			})
			if (res.data === false) throw 'error'
			const _func = functions.httpsCallable('sendNotification')
			const _res = await _func({
				content: `${place.name} - ${msg}`,
				client_id: cus,
			})
		} catch (e) {
			//Error
		}
	}

	if (currentChats === null) return <Loading></Loading>

	if (currentChats.length === 0)
		return (
			<aside className="empty">
				<i className="mi">chat</i>
				<h3>No hay conversaciones</h3>
			</aside>
		)

	return (
		<>
			<div className="chat-ui">
				<>
					<ul className="people">
						{currentChats.map((c) => {
							return (
								<li key={c.id}>
									<div
										className={`card vcard chat-card ${
											openChat !== null && openChat.key === c.key
												? 'active'
												: ''
										} `}
										onClick={() => setOpenChat(c)}
									>
										<div className="avatar">
											<span className="img">
												<i className="mi">person</i>
											</span>
										</div>
										<div className="body">
											<strong className="title">
												{c.chat[0].customer_name}
											</strong>
											{c.chat[c.chat.length - 1].sender !==
											place.id ? (
												<span className="badge badge-primary">
													Nuevos Mensajes
												</span>
											) : (
												<span className="badge badge-light">
													{toHour(c.chat[c.chat.length - 1].date)}
												</span>
											)}
										</div>
									</div>
								</li>
							)
						})}
					</ul>
					<div className="messages">
						<div>
							{openChat !== null ? (
								<>
									<header>
										<h1>{openChat.chat[0].customer_name}</h1>
										<button
											className="button button-light"
											onClick={() => {
												removeChat()
											}}
										>
											<i className="mi">close</i>
											<span>Salir</span>
										</button>
									</header>
									<aside>
										{openChat.chat.map((c) => {
											return (
												<div
													className={`${
														c.sender === c.administrator ||
														typeof c.fake !== 'undefined'
															? 'right'
															: 'left'
													} ${
														typeof c.fake !== 'undefined'
															? 'fake'
															: ''
													}`}
												>
													<p>
														<span>{c.message}</span>{' '}
														<em>
															{typeof c.fake !== 'undefined'
																? 'Enviando...'
																: toHour(c.date)}
														</em>
													</p>
												</div>
											)
										})}
									</aside>
									<footer>
										<input
											value={message}
											onChange={(e) => setMessage(e.target.value)}
										/>
										<button
											className="button button-primary"
											onClick={sendMessage}
										>
											<i className="mi">send</i> <span>Enviar</span>
										</button>
									</footer>
								</>
							) : (
								<aside className="empty">
									<i className="mi">chat</i>
									<h3>Selecciona una conversación</h3>
								</aside>
							)}
						</div>
					</div>
				</>
			</div>
		</>
	)
}

export default connect((state) => ({
	topbar: state.topbar,
	place: state.place,
}))(Chat)

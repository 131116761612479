import React from 'react'

const images = {
	celery: require('../img/allergens/celery.png'),
	crustaceans: require('../img/allergens/crustaceans.png'),
	eggs: require('../img/allergens/eggs.png'),
	fish: require('../img/allergens/fish.png'),
	lupin: require('../img/allergens/lupin.png'),
	milk: require('../img/allergens/milk.png'),
	molluscs: require('../img/allergens/molluscs.png'),
	mustard: require('../img/allergens/mustard.png'),
	nuts: require('../img/allergens/nuts.png'),
	peanut: require('../img/allergens/peanut.png'),
	sesame: require('../img/allergens/sesame.png'),
	soya: require('../img/allergens/soya.png'),
	sulphur: require('../img/allergens/sulphur.png'),
	wheat: require('../img/allergens/wheat.png')
}

function Allergens({ allergens, style, imgstyle, language }) {
	const renderAllergens = () => {
		return allergens.map(all => (
			<div>
				<img
					title={all.value}
					key={all.value}
					src={images[all.value]}
					style={{ ...imgstyle, marginLeft: 15, marginRight: 15 }}
				/>
				<p
					style={{
						margin: 0,
						padding: 0,
						textAlign: 'center',
						fontSize: '0.9em',
						fontWeight: 'bold',
						opacity: 0.8,
						width: 70
					}}
				>
					{language[all.value].toUpperCase()}
				</p>
			</div>
		))
	}

	return (
		<div className="allergens" style={style}>
			{renderAllergens()}
		</div>
	)
}

export default Allergens

import axios from 'axios'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { create_alert } from '../../store/actions'

const MapComponent = ({
	onPointSelected,
	google,
	style,
	_point,
	create_alert,
	language,
	lang,
}) => {
	const [search, setSearch] = useState('')
	const [formattedSearch, setFormattedSearch] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [searchError, setSearchError] = useState(false)
	const [mapRef, setMapRef] = useState(null)
	const [searchTimeout, setSearchTimeout] = useState(null)

	useEffect(() => {
		if (_point === null) {
			onPointSelected({
				lat: 40,
				lng: -3,
			})
		}
	}, [_point])

	useEffect(() => {
		if (searchTimeout !== null) clearTimeout(searchTimeout)
		if (search !== '') setSubmitting(true)
		setSearchError(false)
		setFormattedSearch(null)
		if (search.length >= 2) {
			setSearchTimeout(
				setTimeout(() => {
					goSearch()
				}, 1000)
			)
		}
	}, [search])

	const goSearch = async () => {
		try {
			let urlText = encodeURIComponent(search)

			let res = await axios.get(
				`https://maps.googleapis.com/maps/api/geocode/json?address=${urlText}&key=AIzaSyDeio_usEPlxcZTa0nIv7mLE89R-_ljiDU&language=en&components=country:es`
			)
			const { lat, lng } = res.data.results[0].geometry.location
			setFormattedSearch(res.data.results[0].formatted_address)
			if (mapRef !== null) {
				mapRef.panTo({ lat, lng })
			}
			onPointSelected({ lat, lng })
			setSubmitting(false)
			setSearchError(false)
		} catch (e) {
			setSubmitting(false)
			setSearchError(true)
		}
	}

	return (
		<>
			<div
				className="search-location"
				style={{
					zIndex: 300,
				}}
			>
				<div
					className={`form-group ${submitting ? 'loading' : ''} ${
						searchError ? 'is-invalid' : ''
					}`}
				>
					<input
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						onKeyPress={(e) => {
							if (e.key === 'Enter') e.preventDefault()
						}}
						type="text"
						placeholder={language.search_or_select_a_place_in_the_map}
					/>
				</div>
			</div>
			<div className="map-embed">
				{submitting && (
					<div
						style={{
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(0,0,0,0.5)',
							position: 'absolute',
							top: 0,
							left: 0,
							zIndex: 200,
						}}
					>
						<div
							style={{
								width: '100%',
								height: '100%',
								position: 'relative',
							}}
						>
							<div className="spinner spinner2"></div>
						</div>
					</div>
				)}
				<Map
					onClick={(ref, map, ev) => {
						map.panTo(ev.latLng)
						onPointSelected({
							lat: ev.latLng.lat(),
							lng: ev.latLng.lng(),
						})
					}}
					google={google}
					zoom={12}
					initialCenter={_point}
					disableDefaultUI
					onReady={(a, b) => {
						setMapRef(b)
					}}
				>
					{_point !== null && (
						<Marker
							position={_point}
							label={{
								fontWeight: 'bold',
								text: formattedSearch,
							}}
						/>
					)}
				</Map>
			</div>
		</>
	)
}

export default connect(
	(state) => ({
		language: state.language.dictionary,
		lang: state.language.lang,
	}),
	{ create_alert }
)(
	GoogleApiWrapper({
		apiKey: 'AIzaSyDeio_usEPlxcZTa0nIv7mLE89R-_ljiDU',
	})(MapComponent)
)

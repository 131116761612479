import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import FlipMove from 'react-flip-move'
import { connect } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'
import { Loading, ModalForm } from '../../../components'
import { firestore } from '../../../config/firebase'
import Clients from './Clients'
import Config from './Config'
import Reservations from './Reservations'

function ReservationsCovid({ language, place, topbar, covid }) {
	const [active, setActive] = useState(0)
	const [creatingReservation, setCreatingReservation] = useState(false)
	const [todayIs, setTodayIs] = useState(null)
	const [dayViewing, setDayViewing] = useState(new Date())
	const [search, setSearch] = useState('')
	const [searchClient, setSearchClient] = useState('')
	const [sendMail, setSendMail] = useState(false)
	const [searchChanged, setSearchChanged] = useState(false)
	const [doSearch, setDoSearch] = useState(false)
	const [cancelledReservationsModal, setCancelledReservationsModal] =
		useState(false)
	const [cancelledReservationsData, setCancelledReservationsData] = useState(
		[]
	)

	const formatDate = (date) => {
		let _date = new Date(date)
		let day = _date.getDate()
		let month = _date.getMonth() + 1
		let year = _date.getFullYear()
		return `${`0${day}`.slice(-2)}/${`0${month}`.slice(-2)}/${year}`
	}

	useEffect(() => {
		let listener = firestore
			.collection(`/confirmations`)
			.where('response', '==', false)
			.where('dismissed', '==', false)
			.onSnapshot((snap) => {
				snap = snap.docs.map((d) => d.data())
				snap = snap.filter((f) => f.data.place === place.id)
				setCancelledReservationsData(snap || [])
			})
		return () => {
			listener()
		}
	}, [])

	useEffect(() => {
		setTodayIs(formatDate(new Date().getTime()))

		const arrayOfLinks = ['list', 'config', 'clients']
		let pathname = window.location.pathname
		pathname = pathname.split('/')[2]
		let index = 0

		arrayOfLinks.forEach((id, i) => {
			if (id === pathname) {
				index = i
			}
		})

		setActive(index)
	}, [])

	useEffect(() => {
		if (search.length > 2) {
			setSearchChanged(true)
		} else {
			setSearchChanged(false)
		}
	}, [search])

	if (covid === null) return <Loading />

	return (
		<>
			{cancelledReservationsModal === true && (
				<ModalForm
					toggle={() => {
						setCancelledReservationsModal(false)
					}}
				>
					<div>
						<div className="form-ui">
							<h1>Reservas canceladas</h1>
							<div
								style={{
									width: '100%',
									height: 500,
									padding: 20,
									position: 'relative',
									overflowY: 'scroll',
								}}
							>
								{Array.isArray(cancelledReservationsData) && (
									<FlipMove>
										{cancelledReservationsData.map((d) => {
											if (d.data.place !== place.id) return null

											let block = (covid?.config?.blocks || []).find(
												(f) => f.id === d.data.slot.block
											)
											let slot = (block?.slots || []).find(
												(f) => f.id === d.data.slot.slot
											)

											return (
												<div
													key={d.id}
													style={{
														width: '100%',
														border: 'solid grey 1px',
														borderRadius: 5,
														padding: 15,
														marginBottom: 10,
														position: 'relative',
													}}
												>
													<h4>{d.data.placeName}</h4>
													<p
														style={{
															margin: 0,
															marginTop: 10,
														}}
													>
														<strong>Nombre y Correo: </strong>
														{d.data.name}{' '}
														<small>({d.data.email})</small>
													</p>
													<p
														style={{
															margin: 0,
														}}
													>
														<strong>Notas:</strong> {d.data.notes}
													</p>
													<p
														style={{
															margin: 0,
														}}
													>
														<strong>Habitación:</strong>{' '}
														{d.data.room}
													</p>
													{typeof slot === 'object' && (
														<p
															style={{
																margin: 0,
															}}
														>
															<strong>Fecha y Bloque: </strong>
															{d.data.date_block[0]} -{' '}
															{slot.text[0]}-{slot.text[1]}
														</p>
													)}
													<p
														style={{
															margin: 0,
														}}
													>
														<strong>Personas: </strong>Adultos{' '}
														{d.data.adults} - Niños{' '}
														{d.data.children}
													</p>
													<br />
													<p>{d.message}</p>
													<button
														onClick={async () => {
															await firestore
																.doc(`/confirmations/${d.id}`)
																.update({
																	dismissed: true,
																})
														}}
														className="button button-danger button-small"
														style={{
															position: 'absolute',
															bottom: 15,
															right: 15,
															width: 200,
														}}
													>
														Borrar Alerta
													</button>
												</div>
											)
										})}
									</FlipMove>
								)}
							</div>
						</div>
					</div>
				</ModalForm>
			)}
			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs">
					<li className={active === 0 ? 'active' : ''}>
						<Link
							to="/reservations_covid/list"
							onClick={(e) => {
								setActive(0)
							}}
						>
							Lista
						</Link>
					</li>
					<li className={active === 2 ? 'active' : ''}>
						<Link
							to="/reservations_covid/clients"
							onClick={(e) => {
								setActive(2)
							}}
						>
							Clientes
						</Link>
					</li>
					<li className={active === 1 ? 'active' : ''}>
						<Link
							to="/reservations_covid/config"
							onClick={(e) => {
								setActive(1)
							}}
						>
							Configuración
						</Link>
					</li>
				</ul>
				<div className="pull-right">
					<div className="actions">
						{active === 0 && (
							<>
								<div
									className="search"
									style={{
										flexDirection: 'row',
									}}
								>
									<input
										disabled={creatingReservation}
										type="text"
										placeholder={`${language.search}...`}
										onChange={(e) => setSearch(e.target.value)}
										value={search}
										style={{
											minWidth: 200,
										}}
									/>
									<i className="mi">search</i>
								</div>
								{search.length > 0 && (
									<>
										<button
											disabled={!searchChanged}
											className="button button-primary button-add inline"
											onClick={() => setDoSearch(true)}
										>
											<span>Buscar Reservas</span>
										</button>
										<button
											className="button button-light button-add inline"
											onClick={() => setSearch('')}
										>
											<i className="mi">close</i>
										</button>
									</>
								)}

								{search.length === 0 && (
									<>
										<div
											className="date-wrapper"
											style={{
												paddingRight: 0,
											}}
										>
											<DatePicker
												selected={dayViewing}
												onChange={(date) => setDayViewing(date)}
												locale="es"
												customInput={
													<button className="button button-secondary">
														<span>
															{formatDate(dayViewing) === todayIs
																? 'Hoy'
																: formatDate(dayViewing)}
														</span>
													</button>
												}
												popperClassName="some-custom-class"
												popperPlacement="bottom-left"
												popperModifiers={{
													offset: {
														enabled: true,
														offset: '5px, 10px',
													},
													preventOverflow: {
														enabled: true,
														escapeWithReference: false,
														boundariesElement: 'viewport',
													},
												}}
											/>
										</div>
										<button
											className="button button-primary button-add inline"
											onClick={(e) => setCreatingReservation(true)}
										>
											<i className="mi">add</i>{' '}
											<span>Agregar Reserva</span>
										</button>
									</>
								)}
							</>
						)}
						{active === 2 && (
							<>
								<div className="search">
									<input
										disabled={creatingReservation}
										type="text"
										placeholder={`Buscar clientes...`}
										style={{
											minWidth: 200,
										}}
										onChange={(e) => setSearchClient(e.target.value)}
										value={searchClient}
									/>
									<i className="mi">search</i>
								</div>
								<button
									className="button button-primary button-add inline"
									onClick={(e) => setSendMail(true)}
								>
									<i className="mi">email</i>{' '}
									<span>Enviar Emails</span>
								</button>
							</>
						)}
					</div>
				</div>
			</div>
			{Array.isArray(cancelledReservationsData) &&
				cancelledReservationsData.length > 0 && (
					<button
						className="button button-danger"
						onClick={() => setCancelledReservationsModal(true)}
						style={{
							position: 'fixed',
							bottom: 25,
							right: 25,
							width: 350,
						}}
					>
						<i className="mi">close</i>{' '}
						<span>
							Reservas Canceladas ({cancelledReservationsData.length})
						</span>
					</button>
				)}
			<Switch>
				<Route
					path="/reservations_covid/list"
					render={() => (
						<Reservations
							setCreatingReservation={setCreatingReservation}
							creatingReservation={creatingReservation}
							dayViewing={dayViewing}
							search={search}
							setSearch={setSearch}
							setSearchChanged={setSearchChanged}
							doSearch={doSearch}
							setDoSearch={setDoSearch}
						/>
					)}
				/>
				<Route
					path="/reservations_covid/config"
					render={() => <Config />}
				/>
				<Route
					path="/reservations_covid/clients"
					render={() => (
						<Clients
							search={searchClient}
							sendMail={sendMail}
							setSendMail={setSendMail}
						/>
					)}
				/>
			</Switch>
		</>
	)
}

export default connect((state) => ({
	language: state.language.dictionary,
	place: state.place,
	topbar: state.topbar,
	covid: state.covid,
}))(ReservationsCovid)

import React, { useEffect, useState } from 'react'
import { firestore } from '../../../../config/firebase'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Loading } from '../../../../components'
import { create_alert, update_products } from '../../../../store/actions'
import FlipMove from 'react-flip-move'
import Helmet from 'react-helmet'

function Recommendations({
	place,
	products,
	employee,
	language,
	update_products,
	topbar,
}) {
	const [active, setActive] = useState(0)
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		const arrayOfLinks = ['', 'recommendations']
		let pathname = window.location.pathname
		pathname = pathname.split('/')[2]
		let index = 0

		arrayOfLinks.forEach((id, i) => {
			if (id === pathname) {
				index = i
			}
		})

		setActive(index)
	})

	const addLike = (prod, type = 1) => {
		if (place.recommendations <= 0) return
		setSubmitting(true)
		let productDocRef = firestore
			.collection('places')
			.doc(place.id)
			.collection('products')
			.doc(prod.id)
		let placeDocRef = firestore.collection('places').doc(place.id)
		firestore
			.runTransaction(async (transaction) => {
				let product = await transaction.get(productDocRef)
				let _place = await transaction.get(placeDocRef)
				product = product.data()
				_place = _place.data()
				await transaction.update(productDocRef, {
					upvotes: product.upvotes + 1 * type,
				})
				await transaction.update(placeDocRef, {
					recommendations: _place.recommendations - 1,
				})
				return product
			})
			.then((product) => {
				setSubmitting(false)
				update_products(
					products.map((pr) => {
						if (pr.id !== prod.id) return pr
						return {
							...pr,
							upvotes: product.upvotes + 1 * type,
						}
					})
				)
			})
			.catch(() => {
				create_alert(language.an_error_ocurred)
				setSubmitting(false)
			})
	}

	const renderProducts = () => {
		if (products === null) return <Loading />
		let sorted = place.products.sort()
		let sorted_prods = sorted.map((s) => products.find((e) => e.id === s))

		sorted_prods = sorted_prods.sort((a, b) => b.upvotes - a.upvotes)

		let prods = sorted_prods.map((prod, v) => {
			if (typeof prod === 'undefined') return null
			let type = language[prod.type]

			return (
				<tr
					key={prod.id}
					onClick={(e) => {
						e.stopPropagation()
						// setDetailsModal(prod)
					}}
				>
					<td>
						<span className="img">
							{prod.image !== 'no_image' && (
								<i
									style={{ backgroundImage: `url(${prod.image})` }}
								></i>
							)}
						</span>
					</td>
					<td>
						<strong>{prod.name}</strong>
					</td>
					<td className="center">
						<p>
							{prod.category !== ''
								? `${prod.category} ${
										typeof prod.subcategory !== 'undefined' &&
										prod.subcategory !== ''
											? ` - ${prod.subcategory}`
											: ''
								  }`
								: ''}
						</p>
					</td>
					<td className="center">
						<p>{type}</p>
					</td>
					<td className="center">
						<strong>{prod.upvotes}</strong>
					</td>
					{place.recommendations > 0 && (
						<td className="center">
							<div className="product-recommendations">
								<button
									className="button button-primary"
									disabled={submitting}
									onClick={() => addLike(prod)}
								>
									<i className="mi">thumb_up</i>
									<span>{language.plus_like}</span>
								</button>
							</div>
						</td>
					)}
				</tr>
			)
		})

		return (
			<>
				<div className="table-ui">
					<div>
						<table>
							<thead>
								<tr>
									<th />
									<th>{language.name}</th>
									<th className="center">{language.category}</th>
									<th className="center">{language.type}</th>
									<th className="center">Likes</th>
									{place.recommendations > 0 && (
										<th style={{ width: 200 }} />
									)}
								</tr>
							</thead>
							<FlipMove typeName="tbody">{prods}</FlipMove>
						</table>
					</div>
				</div>
			</>
		)
	}

	return (
		<>
			<Helmet>
				<title>Vywap | Recomendaciones</title>
			</Helmet>
			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs">
					<li className={`nav-link ${active === 0 ? 'active' : ''}`}>
						<Link onClick={() => setActive(0)} to="/products">
							Productos
						</Link>
					</li>
					<li className={`nav-link ${active === 1 ? 'active' : ''}`}>
						<Link
							onClick={() => setActive(1)}
							to="/products/recommendations"
						>
							Recomendaciones
						</Link>
					</li>
				</ul>
				<div className="actions"></div>
			</div>
			<div>
				<header className="header-ui">
					<div>
						{place.recommendations > 0 && (
							<h2>
								Tienes {place.recommendations} like
								{place.recommendations > 1 ? 's' : ''} para repartir
							</h2>
						)}
						{place.recommendations === 0 && (
							<h2>No tienes likes para repartir</h2>
						)}
						<p>
							Reparte tus likes entre los productos que quieres
							recomendar en la carta, estos se sumarán a los likes que
							las personas agreguen desde la app.
						</p>
						<p>
							Tendrás un nuevo like por cada 2 productos que agregues.
						</p>
					</div>
				</header>
				{renderProducts()}
			</div>
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		products: state.products,
		language: state.language.dictionary,
		employee: state.employee,
		topbar: state.topbar,
	}),
	{ update_products }
)(Recommendations)

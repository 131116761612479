import React, { useEffect, useState } from 'react'
import FlipMove from 'react-flip-move'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {
	Chrono,
	Input,
	ModalForm,
	More,
	TablesMap,
	Loading,
	Popover,
} from '../../../components'
import firebase, { firestore, functions } from '../../../config/firebase'
import { Formik, Form, Field } from 'formik'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import AsyncSelect from 'react-select/async'

import Select from 'react-select'
import { create_alert } from '../../../store/actions'
import { isMobile } from 'react-device-detect'
import download from 'downloadjs'
import * as htmlToImage from 'html-to-image'
import { create } from 'lodash'
import algoliasearch from 'algoliasearch'
import getcustommail from './getcustommail'

const searchClient = algoliasearch(
	'MM6KWVFEQD',
	'fce390db586e17fe0242f65ae2dc9c3c'
)

function Clients({
	place_,
	covid_,
	_place = null,
	_covid = null,
	tables,
	language,
	topbar,
	create_alert,
	dayViewing,
	search = '',
	user,
	sendMail,
	setSendMail,
	groups = null,
}) {
	const [submitting, setSubmitting] = useState(false)
	const [edit, setEdit] = useState(null)
	const [place, setPlace] = useState(_place === null ? place_ : _place)
	const [covid, setCovid] = useState(_covid === null ? covid_ : _covid)
	const [fromFilter, setFromFilter] = useState(null)
	const [toFilter, setToFilter] = useState(null)
	const [clientsList, setClientsList] = useState([])
	const [page, setPage] = useState(0)
	const [pages, setPages] = useState(1)
	const [loading, setLoading] = useState(false)

	const [type, setType] = useState(null)

	useEffect(() => {
		doSearch()
	}, [page, search, fromFilter, toFilter])

	useEffect(() => {
		// 1 is restaurant
		// 2 is hotel
		if (groups === null) {
			if (typeof place.groups === 'object') {
				if (place.groups.length > 0) {
					if (place.groups[0].category === 'hotel') setType(0)
					else setType(1)
				} else setType(1)
			} else setType(1)
		} else {
			let res = 1
			groups.forEach((g) => {
				if (g.category === 'hotel') res = 0
			})
			setType(res)
		}
	}, [place])

	useEffect(() => {
		setPlace(_place === null ? place_ : _place)
		setCovid(_covid === null ? covid_ : _covid)
	}, [place_, _place, _covid, covid_])

	const doSearch = async () => {
		setLoading(true)
		const index = searchClient.initIndex('reservation_clients')
		let fromDate = fromFilter === null ? 1000 : fromFilter.getTime()
		let toDate = toFilter === null ? 1912051302000 : toFilter.getTime()

		let filter = ''
		if (fromFilter !== null || toFilter !== null)
			filter = `from >= ${fromDate} OR to >= ${fromDate} AND from <= ${toDate} OR to <= ${toDate}`

		const { hits, nbPages } = await index.search(search, {
			facetFilters: `owner:${user.id}`,
			filters: filter,
			page: page,
			hitsPerPage: 50,
		})
		setPages(nbPages)
		setClientsList(hits)
		setLoading(false)
	}

	const removeItem = async (item) => {
		try {
			setSubmitting(true)

			create_alert('Borrando cliente', 'warning')

			for (const pla of user.places) {
				console.log(`/places/${pla}/advanced_res_clients/${item.id}`)
				await firestore
					.doc(`/places/${pla}/advanced_res_clients/${item.id}`)
					.delete()
			}

			let fn = functions.httpsCallable('removeAdvancedReservationClients')
			await fn({
				id: item.id,
			})

			setTimeout(() => {
				create_alert('Cliente ha sido borrado', 'success')
				doSearch()
			}, 2000)
			setSubmitting(false)
		} catch (e) {
			console.log('BATA', e)
		}
	}

	const editItem = async (item) => {
		try {
			setSubmitting(true)

			let adults = typeof item.adults === 'number' ? item.adults : 0
			let children = typeof item.children === 'number' ? item.children : 0
			let client = {
				...edit,
				...item,
				id: edit.id,
				name: item.name,
				people: adults + children,
				adults: adults,
				children: children,
				room: item.room,
				phone: item.phone,
				email: item.email,
				lang: item.lang,
				group: item.group,
				from: item.from !== null ? item.from.getTime() : null,
				to: item.to !== null ? item.to.getTime() : null,
			}

			for (let x = 0; x < user.places.length; x++) {
				const _place = user.places[x]
				await firestore
					.doc(`/places/${_place}/advanced_res_clients/${client.id}`)
					.update({
						...client,
					})
			}

			let fn = functions.httpsCallable('saveAdvancedReservationClients')
			await fn({
				id: client.id,
				item: client,
			})

			setTimeout(() => {
				doSearch()
			}, 2000)

			create_alert('Cliente modificado', 'success')
			setSubmitting(false)
			setEdit(null)
		} catch (e) {
			setSubmitting(false)
			create_alert('Error editando cliente', 'danger')
		}
	}

	const getLanguageName = (lang) => {
		switch (lang) {
			case 'es':
				return 'Español'
			case 'en':
				return 'Inglés'
			case 'fr':
				return 'Francés'
			case 'de':
				return 'Alemán'
			case 'it':
				return 'Italiano'
		}
	}

	const renderEdit = () => {
		return (
			<ModalForm toggle={() => setEdit(null)}>
				<Formik
					initialValues={{
						name: edit.name || '',
						adults: edit.adults || '',
						children: edit.children || '',
						room: edit.room || '',
						phone: edit.phone || '',
						email: edit.email || '',
						lang: edit.lang || 'es',
						group: edit.group || '',
						from: edit.from ? new Date(edit.from) : null,
						to: edit.to ? new Date(edit.to) : null,
						update_reservations: false,
					}}
					validate={(values) => {
						let err = {}
						if (values.name.length < 2)
							err.name = 'Ingresa el nombre completo'
						let adults =
							typeof values.adults === 'number' ? values.adults : 0
						let children =
							typeof values.children === 'number' ? values.children : 0
						if (adults + children <= 0) err.adults = 'Numero de adultos'

						return err
					}}
					onSubmit={(v) => editItem(v)}
				>
					{({ errors, touched, values, setFieldValue }) => (
						<Form className="form-ui">
							<h1>Editar Cliente</h1>
							<Input
								label={'Nombre Completo'}
								disabled={submitting}
								type="text"
								name="name"
								errors={errors}
								touched={touched}
							/>
							<Input
								label={'Adultos'}
								disabled={submitting}
								type="number"
								name="adults"
								errors={errors}
								touched={touched}
								cols={6}
								colside="left"
								rowcolleft={4}
								rowcolright={8}
							/>
							<Input
								label={'Niños'}
								disabled={submitting}
								type="number"
								name="children"
								errors={errors}
								touched={touched}
								cols={6}
								colside="right"
								rowcolleft={4}
								rowcolright={8}
							/>
							<div className="form-group cols-6 colside-left">
								<div className="form-row">
									<div className="col-5">
										<label>Inicio Estancia:</label>
									</div>
									<div className="col-7">
										<DatePicker
											locale="es"
											disabled={submitting}
											selected={values.from}
											onChange={(e) => {
												if (e === null) {
													setFieldValue('from', null)
												} else {
													let dt = new Date(e.getTime())
													dt.setHours(23)
													dt.setMinutes(59)
													setFieldValue('from', dt)
												}
											}}
											minDate={new Date()}
											className="form-control"
											timeCaption="Hora 24"
											// showTimeSelect
											timeFormat="HH:mm"
											dateFormat="dd/MM/yy"
										/>
									</div>
								</div>
							</div>
							<div className="form-group cols-6 colside-right">
								<div className="form-row">
									<div className="col-5">
										<label>Fin Estancia:</label>
									</div>
									<div className="col-7">
										<DatePicker
											locale="es"
											disabled={submitting}
											selected={values.to}
											onChange={(e) => {
												if (e === null) {
													setFieldValue('to', null)
												} else {
													let dt = new Date(e.getTime())
													dt.setHours(0)
													dt.setMinutes(0)
													setFieldValue('to', dt)
												}
											}}
											minDate={values.from || new Date()}
											className="form-control"
											timeCaption="Hora 24"
											// showTimeSelect
											timeFormat="HH:mm"
											dateFormat="dd/MM/yy"
										/>
									</div>
								</div>
							</div>
							<Input
								label={'Habitación'}
								disabled={submitting}
								type="text"
								name="room"
								errors={errors}
								touched={touched}
								colside="left"
								rowcolleft={4}
								rowcolright={8}
								cols={6}
							/>
							<Input
								label={'Teléfono'}
								disabled={submitting}
								type="text"
								name="phone"
								errors={errors}
								touched={touched}
								colside="right"
								rowcolleft={4}
								rowcolright={8}
								cols={6}
							/>
							<Input
								label={'Correo'}
								disabled={submitting}
								type="text"
								name="email"
								errors={errors}
								touched={touched}
							/>
							{typeof covid.config.groups === 'object' &&
								covid.config.groups.length > 0 && (
									<div className="form-group upper-8">
										<div className="form-row">
											<div className="col-3">
												<label>Pensión:</label>
											</div>
											<div className="col-9">
												<Select
													className="react-select"
													classNamePrefix="react-select"
													options={covid.config.groups.map(
														(g) => ({
															value: g,
															label: g.initials,
														})
													)}
													onChange={(e) => {
														setFieldValue('group', e.value)
													}}
													defaultValue={{
														value: values.group,
														label: values.group.initials,
													}}
												/>
											</div>
										</div>
									</div>
								)}
							<div className="form-group upper-7">
								<div className="form-row">
									<div className="col-3">
										<label>Idioma:</label>
									</div>
									<div className="col-9">
										<Select
											className="react-select"
											classNamePrefix="react-select"
											options={[
												{
													value: 'es',
													label: 'Español',
												},
												{
													value: 'en',
													label: 'Inglés',
												},
												{
													value: 'fr',
													label: 'Francés',
												},
												{
													value: 'de',
													label: 'Alemán',
												},
												{
													value: 'it',
													label: 'Italiano',
												},
											]}
											onChange={(e) => {
												setFieldValue('lang', e.value)
											}}
											defaultValue={{
												value: values.lang,
												label: getLanguageName(values.lang),
											}}
										/>
									</div>
								</div>
							</div>
							{/* <div className="form-group form-group-check">
								<div className="form-row">
									<div className="col-12">
										<Field
											type="checkbox"
											name="update_reservations"
											id="update_reservations"
											checked={values.update_reservations}
											onChange={(e) => {
												setFieldValue(
													'update_reservations',
													e.target.checked
												)
												if (e.target.checked) {
													setFieldValue(
														'show_in_products_list',
														true
													)
													setFieldValue(
														'show_price_in_products_list',
														true
													)
												}
											}}
										/>
										<label htmlFor="update_reservations">
											<b></b>{' '}
											<span>Actualizar las reservas existentes</span>
										</label>
									</div>
								</div>
							</div> */}
							<footer>
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">
												{language.loading}...
											</span>
										</div>
									)}
									{!submitting && <span>Continuar</span>}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	const getPeopleOptions = () => {
		return []
		// if (typeof clientsList !== 'object') return []
		// return clientsList.map((c) => ({
		// 	label: c.name,
		// 	value: c,
		// }))
	}

	const getInitialPeople = () => {
		return []
	}

	const getFormattedDate = (date) => {
		let _date = new Date(date)

		let dd = _date.getDate()
		let mm = _date.getMonth() + 1
		let yyyy = _date.getFullYear()

		if (dd < 10) dd = '0' + dd
		if (mm < 10) mm = '0' + mm

		return dd + '/' + mm + '/' + yyyy
	}

	const renderList = () => {
		return clientsList.map((item) => (
			<tr key={item.id}>
				<td>
					<strong>{item.name} </strong>
				</td>
				{type === 0 && (
					<>
						<td>
							{typeof item.group === 'object' && (
								<Popover
									content={item.group.description}
									offset={10}
									offsetY={-5}
									reversed
								>
									<strong>{item.group.initials}</strong>
								</Popover>
							)}
						</td>
						<td>
							<strong>{item.room}</strong>
						</td>
					</>
				)}
				<td>
					{item.adults > 0 && `Adultos ${item.adults}, `}{' '}
					{item.children > 0 && `Niños ${item.children}, `}{' '}
					{`Total ${item.people}`}
				</td>
				<td>{getLanguageName(item.lang)}</td>
				{type === 0 && (
					<td>
						{typeof item.from === 'number' &&
							typeof item.to === 'number' &&
							`${getFormattedDate(item.from)}  -  ${getFormattedDate(
								item.to
							)}`}
					</td>
				)}
				<td>
					{covid !== null && covid.config && (
						<More>
							<button
								onClick={() => setEdit(item)}
								type="button"
								className="button button-outline button-small"
								disabled={submitting}
								title="Editar"
							>
								<i className="mi">create</i>
								<span>Editar</span>
							</button>
							<button
								onClick={() => removeItem(item)}
								type="button"
								className="button button-outline button-small"
								disabled={submitting}
								title="Borrar"
							>
								<i className="mi">delete</i>
								<span>Borrar</span>
							</button>
						</More>
					)}
				</td>
			</tr>
		))
	}

	const sendMails = async (v) => {
		try {
			setSubmitting(true)
			let langsNeeded = []
			v.people.forEach((p) => {
				let fnd = langsNeeded.find((e) => e === p.lang)
				if (typeof fnd === 'undefined') langsNeeded.push(p.lang)
			})

			let translations = {}
			for (let x = 0; x < langsNeeded.length; x++) {
				const lang = langsNeeded[x]

				if (lang === 'es') {
					translations[lang] = [v.subject, v.content]
				} else {
					let translatedSubject = await axios
						.post(
							`https://www.googleapis.com/language/translate/v2?key=AIzaSyA4mvs2CtraG-0xgBzoUG7GACUvxoaNjQs&source=es&target=${lang}&q=${v.subject}`
						)
						.catch((r) => {
							console.log('RES', r)
						})
					translatedSubject =
						translatedSubject.data.data.translations[0].translatedText

					let translatedContent = await axios.post(
						`https://www.googleapis.com/language/translate/v2?key=AIzaSyA4mvs2CtraG-0xgBzoUG7GACUvxoaNjQs&source=es&target=${lang}&q=${v.content}`
					)
					translatedContent =
						translatedContent.data.data.translations[0].translatedText

					translations[lang] = [translatedSubject, translatedContent]
				}
			}

			for (let y = 0; y < v.people.length; y++) {
				const person = v.people[y]
				let email = person.email
				if (typeof email !== 'string' || email.length === 0) {
					// Nothing
				} else {
					let langData = translations[person.lang]

					let fn = functions.httpsCallable('sendMail')
					fn({
						place_id: place.id,
						to: email,
						subject: langData[0],
						html: getcustommail({
							image:
								typeof place.smtp !== 'undefined'
									? place.smtp.image || null
									: null,
							content: langData[1],
							subject: langData[0],
						}),
						secure: place.smtp.secure,
						from: `${place.name} <${place.smtp.email}>`,
					})
				}
			}
			create_alert('Emails enviados', 'success')
			setSendMail(false)
			setSubmitting(false)
		} catch (e) {
			console.log('ERROR #3', e)
			create_alert('Problema con los mail', 'danger')
			setSendMail(false)
			setSubmitting(false)
		}
	}

	const renderMail = () => {
		if (typeof place.smtp === 'undefined') return null
		return (
			<ModalForm toggle={() => setSendMail(false)}>
				<Formik
					initialValues={{
						people: getInitialPeople(),
						content: '',
						subject: '',
					}}
					validate={(values) => {
						let err = {}
						if (values.people.length === 0)
							err.people = 'Selecciona los clientes'
						if (values.content.length === 0)
							err.content = 'Ingresa contenido del correo'
						if (values.subject.length === 0)
							err.subject = 'Ingresa el asunto del correo'
						return err
					}}
					onSubmit={(v) => sendMails(v)}
				>
					{({ errors, touched, values, setFieldValue }) => (
						<Form className="form-ui">
							<h1>Enviar Emails</h1>
							<Input
								label={'Asunto'}
								disabled={submitting}
								type="text"
								name="subject"
								errors={errors}
								touched={touched}
							/>
							<div className="form-group cols-12">
								<div className="form-row">
									<div>
										<label htmlFor="notes">Contenido :</label>
									</div>
									<div>
										<Field
											name="content"
											disabled={submitting}
											component="textarea"
										/>
									</div>
								</div>
							</div>
							<hr />
							<div className="form-group upper-7">
								<div className="form-row">
									<div className="col-3">
										<label>Clientes :</label>
									</div>
									<div className="col-9">
										<AsyncSelect
											className="react-select"
											placeholder="Busca uno"
											classNamePrefix="react-select"
											cacheOptions
											loadOptions={async (v) => {
												const index = searchClient.initIndex(
													'reservation_clients'
												)
												const { hits } = await index.search(v, {
													facetFilters: `owner:${user.id}`,
												})
												return hits.map((m) => ({
													label: m.name,
													value: m,
												}))
											}}
											isMulti
											onChange={(e) => {
												if (e === null) setFieldValue('people', [])
												else
													setFieldValue(
														'people',
														e.map((v) => v.value)
													)
											}}
										/>
										{/* <Select
											className="react-select"
											classNamePrefix="react-select"
											
											options={getPeopleOptions()}
											
											value={values.people.map((p) => ({
												label: p.name,
												value: p,
											}))}
											placeholder=""
										/> */}
									</div>
								</div>
							</div>
							<footer>
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">
												{language.loading}...
											</span>
										</div>
									)}
									{!submitting && <span>Continuar</span>}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	const renderPagination = () => {
		let p = []
		for (let i = 0; i < pages; i++) {
			p.push(
				<button
					className={`button button-${i === page ? 'primary' : 'link'}`}
					style={{
						width: 50,
					}}
					onClick={() => setPage(i)}
				>
					{i + 1}
				</button>
			)
		}
		return p
	}

	if (type === null || loading) return <Loading />

	if (typeof clientsList !== 'undefined' && clientsList.length > 0)
		return (
			<>
				{sendMail && renderMail()}
				{edit !== null && renderEdit()}
				<div className="table-ui">
					<div>
						{type === 0 && (
							<div className="archive-filters">
								<div className="form-group">
									<div className="form-row">
										<div className="col-5">
											<label>Inicio Estancia:</label>
										</div>
										<div className="col-7">
											<DatePicker
												locale="es"
												disabled={submitting}
												selected={fromFilter}
												onChange={(e) => setFromFilter(e)}
												className="form-control"
												timeCaption="Hora 24"
												placeholderText="Seleccionar Fecha..."
												// showTimeSelect
												timeFormat="HH:mm"
												dateFormat="dd/MM/yy"
											/>
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="form-row">
										<div className="col-5">
											<label>Fin Estancia:</label>
										</div>
										<div className="col-7">
											<DatePicker
												locale="es"
												disabled={submitting}
												selected={toFilter}
												onChange={(e) => setToFilter(e)}
												minDate={fromFilter || new Date()}
												className="form-control"
												timeCaption="Hora 24"
												placeholderText="Seleccionar Fecha..."
												// showTimeSelect
												timeFormat="HH:mm"
												dateFormat="dd/MM/yy"
											/>
										</div>
									</div>
								</div>
								<button
									className="button button-link"
									style={{
										display: 'inline',
										width: 'auto',
									}}
									onClick={() => {
										setFromFilter(null)
										setToFilter(null)
									}}
								>
									Limpiar Filtros
								</button>
							</div>
						)}
						<table>
							<thead>
								<tr>
									<th>Nombre</th>
									{type === 0 && (
										<>
											<th>Pensión</th>
											<th>Habitación</th>
										</>
									)}
									<th>Personas</th>
									<th>Idioma</th>
									{type === 0 && (
										<th>Inicio Estancia / Fin Estancia</th>
									)}
									<th />
								</tr>
							</thead>
							<tbody>{renderList()}</tbody>
						</table>
					</div>
				</div>
				<div
					style={{
						width: '100%',
						height: 50,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'row',
					}}
				>
					{renderPagination()}
				</div>
			</>
		)
	return (
		<>
			{type === 0 && (
				<div className="table-ui">
					<div>
						<div className="archive-filters">
							<div className="form-group">
								<div className="form-row">
									<div className="col-5">
										<label>Inicio Estancia:</label>
									</div>
									<div className="col-7">
										<DatePicker
											locale="es"
											disabled={submitting}
											selected={fromFilter}
											onChange={(e) => setFromFilter(e)}
											className="form-control"
											timeCaption="Hora 24"
											placeholderText="Seleccionar Fecha..."
											// showTimeSelect
											timeFormat="HH:mm"
											dateFormat="dd/MM/yy"
										/>
									</div>
								</div>
							</div>
							<div className="form-group">
								<div className="form-row">
									<div className="col-5">
										<label>Fin Estancia:</label>
									</div>
									<div className="col-7">
										<DatePicker
											locale="es"
											disabled={submitting}
											selected={toFilter}
											onChange={(e) => setToFilter(e)}
											minDate={fromFilter || new Date()}
											className="form-control"
											timeCaption="Hora 24"
											placeholderText="Seleccionar Fecha..."
											// showTimeSelect
											timeFormat="HH:mm"
											dateFormat="dd/MM/yy"
										/>
									</div>
								</div>
							</div>
							<button
								className="button button-link"
								style={{
									display: 'inline',
									width: 'auto',
								}}
								onClick={() => {
									setFromFilter(null)
									setToFilter(null)
								}}
							>
								Limpiar Filtros
							</button>
						</div>
					</div>
				</div>
			)}
			<aside className="empty">
				<i className="mi">face</i>
				<h3>No tienes clientes</h3>
			</aside>
		</>
	)
}

export default connect(
	(state) => ({
		place_: state.place,
		language: state.language.dictionary,
		topbar: state.topbar,
		covid_: state.covid,
		user: state.user,
	}),
	{ create_alert }
)(Clients)

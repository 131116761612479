import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'
import { firestore, storage } from '../../../config/firebase'
import Collaborators from './Collaborators'
import Events from './Events'

function EventsContainer({
	place,
	set_events,
	events = [],
	isCollaborator = false,
	add_event,
	language,
	topbar,
}) {
	const [active, setActive] = useState(0)
	const [showReader, setShowReader] = useState(false)
	const [showCreateEvent, setShowCreateEvent] = useState(false)
	const [createCollaborator, setCreateCollaborator] = useState(false)
	const [loading, setLoading] = useState(true)
	const [showProductReader, setShowProductReader] = useState(false)

	useEffect(() => {
		if (isCollaborator !== false) {
			getCollaboratorsData()
		} else {
			firestore
				.collection('places')
				.doc(place.id)
				.collection('events')
				.get()
				.then((res) => fetchEvents(res))
		}
		const arrayOfLinks = ['list', 'collaborators']
		let pathname = window.location.pathname
		pathname = pathname.split('/')[2]
		let index = 0

		arrayOfLinks.forEach((id, i) => {
			if (id === pathname) {
				index = i
			}
		})

		setActive(index)

		// firestore
		// 	.collection('events_list')
		// 	.where('area', '==', 'valencian_community')
		// 	.orderBy('popularity', 'desc')
		// 	.limit(10)
		// 	.get()
		// 	.then(res => {
		// 		let dcs = res.docs.map(r => r.data())
		//
		// 	})
		// 	.catch(e => {
		//
		// 	})
	}, [])

	const getCollaboratorsData = async () => {
		let _events = []
		set_events(_events)
		listenToOuterEventChanges()
	}

	const listenToOuterEventChanges = () => {
		Object.keys(isCollaborator.places).forEach((_place) => {
			const _place_ = isCollaborator.places[_place]
			_place_.events.forEach((ev) => {
				firestore
					.collection('places')
					.doc(_place)
					.collection('events')
					.doc(ev)
					.get()
					.then((res) => {
						add_event([{ ...res.data(), place: _place }])
					})
			})
		})
	}

	const fetchEvents = async (event_docs) => {
		let _events = []
		for (let i = 0; i < event_docs.docs.length; i++) {
			const ev = event_docs.docs[i]
			let _ev = ev.data()
			let _img = null
			if (_ev.images > 0) {
				let pathReference = storage.ref(
					`events/${place.id}/${_ev.slug}/image_0_670x670.jpg`
				)
				_img = await pathReference.getDownloadURL()
			}
			_events.push({ ...ev.data(), thumbnail: _img })
		}
		set_events(_events)
		setLoading(false)
	}

	return (
		<>
			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs">
					<li className={active === 0 ? 'active' : ''}>
						<Link
							to="/events/list"
							onClick={(e) => {
								setActive(0)
							}}
						>
							{language.events}
						</Link>
					</li>
					{!isCollaborator && (
						<li className={active === 1 ? 'active' : ''}>
							<Link
								to="/events/collaborators"
								onClick={(e) => {
									setActive(1)
								}}
							>
								{language.collaborators}
							</Link>
						</li>
					)}
				</ul>

				<div className="actions">
					{active === 0 && !isCollaborator && (
						<>
							<button
								className="button button-add button-light inline"
								onClick={() => setShowProductReader(true)}
							>
								<i className="mi">local_bar</i>
								<span>Validar Productos</span>
							</button>
							<button
								className="button button-add button-light inline"
								onClick={() => setShowReader(true)}
							>
								<i className="mi">crop_free</i>
								<span>{language.tickets_reader}</span>
							</button>
							<button
								className="button button-add inline button-primary"
								onClick={() => setShowCreateEvent(true)}
							>
								<i className="mi">add</i>
								<span>{language.new_event}</span>
							</button>
						</>
					)}
					{active === 1 && !isCollaborator && (
						<>
							<button
								className="button button-add  inline"
								onClick={() => setCreateCollaborator(true)}
							>
								<i className="mi">add</i>
								<span>{language.new_collaborator}</span>
							</button>
						</>
					)}
				</div>
			</div>
			<Switch>
				{isCollaborator === false && (
					<Route
						path="/events/collaborators"
						render={(props) => (
							<Collaborators
								topbarCreateCollaborator={createCollaborator}
								setTopbarCreateCollaborator={setCreateCollaborator}
							/>
						)}
					/>
				)}
				<Route
					path="/events/list"
					render={(props) => (
						<Events
							loading={loading}
							isCollaborator={isCollaborator}
							showReader={showReader}
							showProductReader={showProductReader}
							setShowProductReader={setShowProductReader}
							setShowReader={setShowReader}
							showCreateEvent={showCreateEvent}
							setShowCreateEvent={setShowCreateEvent}
						/>
					)}
				/>
			</Switch>
		</>
	)
}

export default connect((state) => ({
	language: state.language.dictionary,
	topbar: state.topbar,
}))(EventsContainer)

const initialState = []

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'UPDATE_FEEDBACK':
			return [...payload]
		default:
			return state
	}
}

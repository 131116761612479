import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, { registerLocale } from 'react-datepicker'
import addDays from 'date-fns/addDays'
import addMinutes from 'date-fns/addMinutes'
import { connect } from 'react-redux'
import { create_alert } from '../../../store/actions'
import { ModalForm, Input, TablesMap, Loading } from '../../../components'
import Select from 'react-select'
import { firestore, functions } from '../../../config/firebase'

function Accepted({
	place,
	tables,
	create_alert,
	language,
	reservations,
	topbar,
	dayViewing,
	setDayViewing,
	showAdd,
	setShowAdd,
	todayIs,
	confirmingReservation,
	user,
	history,
	setConfirmingReservation,
	editSeats,
	setEditSeats,
	products,
}) {
	const [submitting, setSubmitting] = useState(false)
	const [loading, setLoading] = useState(false)
	const [tablesState, setTablesState] = useState(null)
	const [ableToConsult, setAbleToConsult] = useState(false)
	const [selectedTable, setSelectedTable] = useState(null)
	const [reservationsGeneralData, setReservationsGeneralData] = useState(null)
	const [editingReservation, setEditingReservation] = useState(null)
	const [placeTables, setPlaceTables] = useState(place.seats.length)

	useEffect(() => {
		if (dayViewing !== null) {
			let openHours = JSON.parse(place.openHours)
			let today = new Date(dayViewing).getDay() - 1
			if (today < 0) today = 6
			today = openHours[today]
			let opensAt = `${`0${today.hours[0][0][0]}`.slice(
				-2
			)}${`0${today.hours[0][0][1]}`.slice(-2)}`

			let closesAt = `${`0${today.hours[0][1][0]}`.slice(
				-2
			)}${`0${today.hours[0][1][1]}`.slice(-2)}`

			if (today.hours[0][1][0] >= 24) closesAt = '2400'

			let opensHalf =
				(parseInt(opensAt.slice(-2)) + parseInt(opensAt.slice(0, 2)) * 60) /
				30

			let closesHalf =
				(parseInt(closesAt.slice(-2)) +
					parseInt(closesAt.slice(0, 2)) * 60) /
				30

			let dayLengthInHalfHours = closesHalf - opensHalf

			setReservationsGeneralData({
				opensAt,
				closesAt,
				opensHalf,
				closesHalf,
				dayLengthInHalfHours,
			})
		}
	}, [dayViewing])

	useEffect(() => {
		if (typeof confirmingReservation === 'object') {
			setAbleToConsult(true)
			setEditingReservation(confirmingReservation)
		}
	}, [confirmingReservation])

	const formatDate = (date) => {
		let _date = new Date(date)
		let day = _date.getDate()
		let month = _date.getMonth() + 1
		let year = _date.getFullYear()
		return `${`0${day}`.slice(-2)}/${`0${month}`.slice(-2)}/${year}`
	}

	const getRandomString = (length) => {
		var result = ''
		var characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
		var charactersLength = characters.length
		for (var i = 0; i < length; i++)
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			)

		return result
	}

	const removeReservation = async (res_id) => {
		if (reservations.list[res_id].uid) {
			const fn = functions.httpsCallable('modifyReservation')
			let res = await fn({
				reservation_id: reservations.list[res_id].id,
				place_id: reservations.list[res_id].place,
				client_id: reservations.list[res_id].uid,
				action: [0, 0],
				reservation: reservations.list[res_id],
			})
			const _func = functions.httpsCallable('sendNotification')
			const _res = await _func({
				content: `Reserva en ${confirmingReservation.placeName} Cancelada`,
				client_id: confirmingReservation.uid,
				channel: 'reservations',
				data: {
					type: 'reservations',
				},
			})
		}
		let new_list = reservations.list
		delete new_list[res_id]
		await firestore
			.collection('places')
			.doc(place.id)
			.collection('reservations')
			.doc('list')
			.set(new_list)
		let new_tables = {}
		Object.keys(reservations.tables).forEach((t) => {
			let _t = {}
			Object.keys(reservations.tables[t]).forEach((d) => {
				let _d = {}
				Object.keys(reservations.tables[t][d]).forEach((h) => {
					if (reservations.tables[t][d][h] !== res_id)
						_d[h] = reservations.tables[t][d][h]
				})
				_t[d] = _d
			})
			new_tables[t] = _t
		})
		await firestore
			.collection('places')
			.doc(place.id)
			.collection('reservations')
			.doc('tables')
			.set(new_tables)
		return {
			list: new_tables,
			tables: new_tables,
		}
	}

	const submitAddReservations = async (values) => {
		setSubmitting(true)
		let _reservations = reservations
		if (editingReservation !== null && !confirmingReservation) {
			_reservations = await removeReservation(editingReservation)
		}
		setTablesState(null)
		setAbleToConsult(false)
		setSelectedTable(null)

		let start_date = new Date(values.date)
		let formatted_date = getFormattedDate(start_date)
		let halve_hours = values.duration / 30
		let duration_hours = []
		for (let h = 0; h < halve_hours; h++) {
			let dt = addMinutes(start_date, h * 30)
			duration_hours.push(getFormattedHour(dt))
		}

		let reservation_id = getRandomString(20)
		if (typeof confirmingReservation === 'object')
			reservation_id = confirmingReservation.id
		let reservation = {
			id: reservation_id,
			name: values.name,
			date: new Date(values.date).getTime(),
			duration: parseInt(values.duration),
			phone: parseInt(values.phone),
			email: values.email,
			hours: duration_hours,
			notes: `${values.notes}`,
			products: values.products,
		}

		if (typeof confirmingReservation === 'object')
			reservation.uid = confirmingReservation.uid
		let hours_object = {}
		duration_hours.forEach((h) => {
			hours_object[h] = reservation_id
		})

		let reservations_list = await firestore
			.collection('places')
			.doc(place.id)
			.collection('reservations')
			.doc('list')
			.get()
		if (reservations_list.exists) {
			await firestore
				.collection('places')
				.doc(place.id)
				.collection('reservations')
				.doc('list')
				.update({
					[reservation_id]: reservation,
				})
		} else {
			await firestore
				.collection('places')
				.doc(place.id)
				.collection('reservations')
				.doc('list')
				.set({
					[reservation_id]: reservation,
				})
		}

		let tableRes =
			typeof _reservations.tables[selectedTable.id] === 'undefined'
				? {}
				: _reservations.tables[selectedTable.id]
		let tableDate =
			typeof tableRes[formatted_date] === 'undefined'
				? {}
				: tableRes[formatted_date]
		let reservation_tables = await firestore
			.collection('places')
			.doc(place.id)
			.collection('reservations')
			.doc('tables')
			.get()
		if (reservation_tables.exists) {
			await firestore
				.collection('places')
				.doc(place.id)
				.collection('reservations')
				.doc('tables')
				.update({
					[selectedTable.id]: {
						...tableRes,
						[formatted_date]: {
							...tableDate,
							...hours_object,
						},
					},
				})
		} else {
			await firestore
				.collection('places')
				.doc(place.id)
				.collection('reservations')
				.doc('tables')
				.set({
					[selectedTable.id]: {
						...tableRes,
						[formatted_date]: {
							...tableDate,
							...hours_object,
						},
					},
				})
		}

		if (user.type === 'admin_payasyougo') {
			const func_ = functions.httpsCallable('updateOrdersPricing')
			let reservationsSub = user.stripe_customer.subscriptions.data[0].items.data.find(
				(s) => s.plan.id === 'plan_HHUszh4Up8klqD'
			)
			if (typeof reservationsSub === 'undefined') throw { error: true }
			await func_({
				subscription_item: reservationsSub.id,
				quantity: 1,
			})
		}
		if (typeof confirmingReservation === 'object') {
			let userDate = confirmingReservation.date
			let mn = confirmingReservation.time.slice(-2)
			let hrs = `00${confirmingReservation.time}`.slice(-4)
			hrs = hrs.slice(-4)
			hrs = hrs.slice(0, 2)
			userDate.setHours(parseInt(hrs))
			userDate.setMinutes(parseInt(mn))
			userDate.setMilliseconds(0)
			userDate.setSeconds(0)
			let newDate = new Date(values.date).getTime()
			let newTime = `${new Date(values.date).getHours()}${`0${new Date(
				values.date
			).getMinutes()}`.slice(-2)}`
			let isModified = userDate.getTime() === newDate
			let action = isModified ? [1, 1] : [0, 1]
			if (isModified) {
				const _func = functions.httpsCallable('sendNotification')
				const _res = await _func({
					content: `Reserva en ${confirmingReservation.placeName} Confirmada`,
					client_id: confirmingReservation.uid,
					channel: 'reservations',
					data: {
						type: 'reservations',
					},
				})
			} else {
				const _func = functions.httpsCallable('sendNotification')
				const _res = await _func({
					content: `Reserva en ${confirmingReservation.placeName} Modificada`,
					client_id: confirmingReservation.uid,
					channel: 'reservations',
					data: {
						type: 'reservations',
					},
				})
			}

			const fn = functions.httpsCallable('modifyReservation')
			await fn({
				reservation_id: confirmingReservation.id,
				place_id: confirmingReservation.place,
				client_id: confirmingReservation.uid,
				action,
				reservation: confirmingReservation,
				payload: {
					date: newDate,
					time: newTime,
				},
			})
			setConfirmingReservation(false)
			history.replace('/reservations/pending')
		}
		setSubmitting(false)
		create_alert(language.reservation_created, 'success')
		setEditingReservation(null)
		setShowAdd(false)
	}

	const validateAddReservation = (values) => {
		if (values.date !== null && parseInt(values.duration) > 0)
			setAbleToConsult(true)
		else setAbleToConsult(false)
		let errors = {}
		if (values.name.length < 2) errors.name = language.invalid_field
		if (isNaN(values.phone) || `${values.phone}`.length < 5)
			errors.phone = language.invalid_field
		return errors
	}

	const getFormattedDate = (date) => {
		let _date = new Date(date)

		let dd = _date.getDate()
		let mm = _date.getMonth() + 1
		let yyyy = _date.getFullYear()

		if (dd < 10) dd = '0' + dd
		if (mm < 10) mm = '0' + mm

		return dd + '/' + mm + '/' + yyyy
	}

	const getFormattedHour = (date) => {
		let _date = new Date(date)

		let hr = _date.getHours()
		let mn = _date.getMinutes()

		hr = `0${hr}`.slice(-2)
		mn = `0${mn}`.slice(-2)

		return `${hr}${mn}`
	}

	const checkAvailability = async (values) => {
		setLoading(true)
		let start_date = new Date(values.date)
		let formatted_date = getFormattedDate(start_date)
		let duration_hours = []
		let halve_hours = values.duration / 30
		for (let h = 0; h < halve_hours; h++) {
			let dt = addMinutes(start_date, h * 30)
			duration_hours.push(getFormattedHour(dt))
		}
		let tables_state = []
		place.seats.forEach((seat) => {
			let free = false
			let res_table = reservations.tables[seat.id]
			if (typeof res_table === 'undefined') free = true
			else {
				let table_day = res_table[formatted_date]
				free = true
				duration_hours.forEach((hr) => {
					if (typeof table_day === 'undefined') {
						// Good
					} else if (typeof table_day[hr] === 'undefined') {
						// Good
					} else if (table_day[hr] === null) {
						// Good
					} else if (
						editingReservation !== null &&
						table_day[hr] === editingReservation
					) {
						// Good
					} else {
						free = false
					}
				})
			}
			tables_state.push({
				id: seat.id,
				seat: seat,
				free,
			})
		})
		setTimeout(() => {
			setTablesState(tables_state)
			setLoading(false)
		}, 500)
	}

	const getDurationOptions = (length) => {
		let opts = []
		for (let x = 1; x <= length; x++) {
			let hrs = Math.floor((30 * x) / 60)
			let mins = Math.floor((30 * x) % 60)
			hrs = `0${hrs}`.slice(-2)
			mins = `0${mins}`.slice(-2)
			opts.push({
				value: 30 * x,
				label: `${hrs}:${mins}`,
			})
		}
		return opts
	}

	const renderEditSeats = () => {
		return (
			<ModalForm toggle={() => setEditSeats(false)} size="lg">
				<Formik
					initialValues={{
						seats: place.seats,
					}}
					validateOnBlur={false}
					validateOnChange={false}
					validate={(values) => {
						let errors = {}
						values.seats.forEach((s) => {
							if (s.name === '') {
								errors.seats = 'No puede estar vacio'
							}
						})

						return errors
					}}
					onSubmit={async (values) => {
						setSubmitting(true)
						await firestore.doc(`/places/${place.id}`).update({
							seats: values.seats,
						})
						setSubmitting(false)
						setEditSeats(false)
					}}
				>
					{({ errors, touched, values, setFieldValue }) => {
						return (
							<Form className="form-ui">
								<h1>Editar puestos</h1>
								{/* {errors.seats && <div>No puede estar vacio</div>} */}
								{values.seats.map((seat) => {
									return (
										<div key={seat.id} className="row-group">
											<div className="col-10">
												<Input
													label={language.name}
													disabled={submitting}
													name={seat.id}
													errors={errors}
													touched={touched}
													noformik
													custom={{
														onChange: (e) =>
															setFieldValue(
																'seats',
																values.seats.map((m) => {
																	if (m.id === seat.id) {
																		return {
																			...m,
																			name: e.target.value,
																		}
																	}
																	return m
																})
															),
														value: seat.name,
													}}
												/>
											</div>
											<div className="col-2">
												<button
													type="button"
													className="button button-danger button-small"
													onClick={() => {
														setFieldValue(
															'seats',
															values.seats.filter(
																(s) => s.id !== seat.id
															)
														)
													}}
												>
													<i className="mi">delete</i>
													<span>Eliminar</span>
												</button>
											</div>
										</div>
									)
								})}
								{values.seats.length === 0 && (
									<p className="info">
										<i className="mi">info</i>{' '}
										<strong>No tienes puestos</strong>
									</p>
								)}
								<div className="form-group">
									<button
										className="button button-primary-outline"
										type="button"
										onClick={() => {
											setFieldValue('seats', [
												...values.seats,
												{
													id: Math.floor(
														Math.random() * 10000000000
													),
													name: '',
												},
											])
										}}
									>
										<i className="mi">add</i>{' '}
										<span>Nuevo puesto</span>
									</button>
								</div>
								<footer className="cols-2">
									<button
										className="button button-light"
										onClick={(e) => {
											setEditSeats(false)
										}}
									>
										<i className="mi">close</i>
										<span>{language.cancel}</span>
									</button>
									<button
										type="submit"
										disabled={submitting}
										className="button button-primary"
									>
										{submitting && (
											<div
												className="spinner-border spinner-border-sm"
												role="status"
											>
												<span className="sr-only">
													{language.loading}...
												</span>
											</div>
										)}
										{!submitting && <span>{language.save}</span>}
									</button>
								</footer>
							</Form>
						)
					}}
				</Formik>
			</ModalForm>
		)
	}

	const renderAddReservation = (editing = false) => {
		let reservation = {}
		if (typeof confirmingReservation === 'object')
			reservation = confirmingReservation
		else if (editing) reservation = reservations.list[editingReservation]
		if (typeof reservation === 'undefined') {
			editing = false
			reservation = {}
		}
		return (
			<ModalForm
				toggle={() => {
					if (editing) {
						setEditingReservation(null)
						if (typeof confirmingReservation === 'object') {
							setConfirmingReservation(false)
							history.replace('/reservations/pending')
						}
					} else {
						setSelectedTable(null)
						setShowAdd(false)
						setSubmitting(false)
						setEditingReservation(null)
						setTablesState(null)
						setAbleToConsult(false)
					}
				}}
				size="lg"
			>
				<Formik
					initialValues={{
						name: editing ? reservation.name : '',
						// party_of: editing ? reservation.party_of : '',
						phone: editing ? reservation.phone : '',
						email: editing ? reservation.email : '',
						date: editing ? reservation.date : null,
						duration: editing ? reservation.duration : 60,
						notes: editing ? reservation.notes : '',
						products: editing
							? typeof reservation.products !== 'undefined'
								? reservation.products
								: []
							: [],
					}}
					validateOnChange
					validate={validateAddReservation}
					onSubmit={submitAddReservations}
				>
					{({ errors, touched, values, setFieldValue }) => {
						let dis = selectedTable === null
						let openHours = JSON.parse(place.openHours)
						let today = null
						if (values.date !== null) {
							today = new Date(values.date).getDay() - 1
						} else {
							today = new Date().getDay() - 1
						}
						if (today < 0) today = 6
						today = openHours[today]
						let openDate = new Date()
						openDate.setHours(
							parseInt(today.hours[0][0][0]),
							parseInt(today.hours[0][0][1])
						)
						let closeDate = new Date()
						closeDate.setHours(
							parseInt(today.hours[0][1][0]),
							parseInt(today.hours[0][1][1])
						)
						return (
							<Form className="form-ui">
								<h1>
									{confirmingReservation === false && editing
										? 'Editar Reserva'
										: confirmingReservation !== false
										? 'Confirmar Reserva'
										: 'Nueva Reserva'}
								</h1>
								<Input
									label={language.name}
									disabled={submitting}
									name="name"
									errors={errors}
									touched={touched}
									cols={8}
									colside="left"
									rowcolleft={4}
									rowcolright={8}
								/>
								<Input
									label={language.phone}
									disabled={submitting}
									name="phone"
									errors={errors}
									touched={touched}
									cols={4}
									colside="right"
									rowcolleft={5}
									rowcolright={7}
								/>
								<Input
									label={language.email}
									disabled={submitting}
									name="email"
									type="email"
									errors={errors}
									touched={touched}
									rowcolleft={4}
									rowcolright={8}
								/>
								<div className="form-group cols-12">
									<div className="form-row">
										<div>
											<label htmlFor="notes">Notas :</label>
										</div>
										<div>
											<Field name="notes" component="textarea" />
										</div>
									</div>
								</div>
								<div className="form-group cols-8 colside-left">
									<div className="form-row">
										<div className="col-4">
											<label>{language.date_and_time} :</label>
										</div>
										<div className="col-8">
											<DatePicker
												selected={values.date}
												onChange={(date) => {
													setSelectedTable(null)
													setTablesState(null)
													setFieldValue('date', date)
												}}
												showTimeSelect
												timeFormat="HH:mm"
												timeIntervals={30}
												timeCaption="time"
												dateFormat="MMMM d, yyyy HH:mm"
												locale="es"
												minDate={new Date().getTime()}
												maxDate={addDays(new Date(), 30)}
												timeCaption={language.hour}
												minTime={openDate}
												maxTime={closeDate}
											/>
										</div>
									</div>
								</div>
								<div className="form-group cols-4 colside-right upper-3">
									<div className="form-row">
										<div className="col-4">
											<label>{language.duration}:</label>
										</div>
										<div className="col-8">
											<Select
												className="react-select"
												classNamePrefix="react-select"
												options={getDurationOptions(20)}
												onChange={(e) => {
													setSelectedTable(null)
													setTablesState(null)
													setFieldValue('duration', e.value)
												}}
												defaultValue={{
													value: values.duration,
													label: `${`0${Math.floor(
														values.duration / 60
													)}`.slice(-2)}:${`0${Math.floor(
														values.duration % 60
													)}`.slice(-2)}`,
												}}
											/>
										</div>
									</div>
								</div>
								<div className="form-group upper-11">
									<div className="form-row">
										<div className="col-2">
											<label>Productos:</label>
										</div>
										<div className="col-10">
											<Select
												defaultValue={values.products}
												isMulti
												name="products"
												options={products.map((p) => ({
													label: p.name,
													value: p.id,
												}))}
												onChange={(e) =>
													setFieldValue('products', e)
												}
												className="basic-multi-select react-select"
												classNamePrefix="select react-select"
												placeholder="Selecciona los productos a reservar"
											/>
										</div>
									</div>
								</div>
								{tablesState !== null && (
									<div className="form-group form-group-list-select">
										<h4>Seleccionar puesto:</h4>
										<ul>
											{tablesState.map((seat) => (
												<li key={seat.id}>
													<button
														disabled={!seat.free}
														className={`button button-${
															!seat.free ? 'light' : 'success'
														} ${
															selectedTable !== null &&
															seat.id === selectedTable.id
																? 'active'
																: ''
														}`}
														type="button"
														onClick={() => setSelectedTable(seat)}
													>
														<i className="mi">event_seat</i>
														<span>{seat.seat.name}</span>
													</button>
												</li>
											))}
										</ul>
									</div>
								)}
								<footer className={`cols-${dis ? '1' : '2'}`}>
									<button
										className="button button-light "
										onClick={(e) => checkAvailability(values)}
										disabled={!ableToConsult || loading}
										type="button"
									>
										{language.check_availability}
									</button>
									{!dis && (
										<button
											type="submit"
											disabled={submitting || dis}
											className="button button-primary"
										>
											{submitting && (
												<div
													className="spinner-border spinner-border-sm"
													role="status"
												>
													<span className="sr-only">
														{language.loading}...
													</span>
												</div>
											)}
											{!submitting && (
												<>
													<span>{language.continue}</span>
												</>
											)}
										</button>
									)}
									{editingReservation !== null &&
										confirmingReservation === false && (
											<button
												onClick={async (e) => {
													setSubmitting(true)
													await removeReservation(
														editingReservation
													)
													setSubmitting(false)
													setEditingReservation(null)
												}}
												className="button button-link button-link-sm button-link-delete"
												type="button"
											>
												<i className="mi">delete</i>
												<span>{language.remove}</span>
											</button>
										)}
								</footer>
							</Form>
						)
					}}
				</Formik>
			</ModalForm>
		)
	}

	const renderTablesList = () => {
		let tables_state = place.seats
		return (
			<div className="tables">
				<header>
					<div className="r"></div>
				</header>
				<section>
					{tables_state.map((t) => (
						<div className="r" key={t.id}>
							<strong>{t.name}</strong>
						</div>
					))}
				</section>
			</div>
		)
	}

	const renderHours = () => {
		let len = new Array(reservationsGeneralData.dayLengthInHalfHours).fill(
			null
		)
		return (
			<header>
				<div className="r">
					{len.map((v, i) => {
						let mns = 0
						mns += parseInt(reservationsGeneralData.opensAt.slice(-2))
						mns +=
							parseInt(reservationsGeneralData.opensAt.slice(0, 2)) * 60
						// let mns = reservationsGeneralData.opensHalf * 2 * 60

						let hr = Math.floor(mns / 60) + Math.floor((i * 30) / 60)
						hr = `0${hr}`.slice(-2)

						let mn = Math.floor(mns % 60) + Math.floor((i * 30) % 60)
						mn = `0${mn}`.slice(-2)
						if (mn === '60') {
							mn = '00'
							hr = Math.floor(mns / 60) + Math.floor((i * 30) / 60)
							hr += 1
							hr = `0${hr}`.slice(-2)
						}

						return (
							<span className="slot">
								<time>
									{hr}:{mn}
								</time>
							</span>
						)
					})}
				</div>
			</header>
		)
	}

	const renderReservations = () => {
		let tables_state = []
		let rows = []
		let len = new Array(reservationsGeneralData.dayLengthInHalfHours).fill(
			null
		)

		place.seats.forEach((s) => {
			tables_state.push({
				id: s.id,
				name: s.name,
			})
		})
		rows = tables_state.map((ta) => {
			let res_table = reservations.tables[ta.id]
			let items = []
			if (typeof res_table !== 'undefined') {
				res_table = res_table[formatDate(dayViewing)]
				if (typeof res_table === 'undefined') res_table = {}
			} else res_table = {}
			if (Object.keys(res_table).length === 0) {
				//Nothing
				items = new Array(len.length).fill(<div className="slot"></div>)
			} else {
				let items_data = []
				len.forEach((v, i) => {
					let mns = 0
					mns += parseInt(reservationsGeneralData.opensAt.slice(-2))
					mns += parseInt(reservationsGeneralData.opensAt.slice(0, 2)) * 60
					let hr = Math.floor(mns / 60) + Math.floor((i * 30) / 60)
					hr = `0${hr}`.slice(-2)
					let mn = Math.floor(mns % 60) + Math.floor((i * 30) % 60)
					mn = `0${mn}`.slice(-2)
					let time = `${hr}${mn}`
					if (typeof res_table[time] !== 'undefined') {
						let prev = items_data.find((x) => x.id === res_table[time])
						if (typeof prev === 'object') {
							items_data = items_data.map((d) => {
								if (d.id !== res_table[time]) return d
								return {
									...d,
									width: d.width + 1,
								}
							})
						} else if (
							typeof reservations.list[res_table[time]] !== 'undefined'
						) {
							items_data.push({
								empty: false,
								width: 1,
								id: res_table[time],
								name: reservations.list[res_table[time]].name,
								notes: reservations.list[res_table[time]].notes,
							})
						}
					} else {
						items_data.push({ empty: true, width: null, id: null })
					}
				})
				items = items_data.map((data) => {
					if (data.empty) return <div className="slot"></div>
					return (
						<button
							className={`reservation cols-${data.width} confirmed`}
							onClick={(e) => setEditingReservation(data.id)}
							type="button"
						>
							<cite>
								<b>{data.name}</b>
							</cite>
							{typeof data.notes !== 'undefined' &&
								data.notes.length > 0 && <i className="mi">note</i>}
						</button>
					)
				})
			}
			return <div className="r">{items}</div>
		})
		return <section>{rows}</section>
	}

	if (
		reservationsGeneralData === null ||
		todayIs === null ||
		dayViewing === null ||
		reservations === null
	)
		return <Loading />

	if (placeTables === 0)
		return (
			<>
				{editSeats && renderEditSeats()}
				<aside className="empty">
					<i className="mi">class</i>
					<h3>No has creado puestos</h3>
				</aside>
			</>
		)

	return (
		<>
			{editSeats && renderEditSeats()}
			<div className="reservations-grid has-seats">
				<div>
					{renderTablesList()}
					<div
						className={`hours cols-${reservationsGeneralData.dayLengthInHalfHours}`}
					>
						{renderHours()}
						{renderReservations()}
					</div>
				</div>
			</div>
			{showAdd && renderAddReservation()}
			{/* {confirmingReservation && renderAddReservation(confirmingReservation)} */}
			{editingReservation && renderAddReservation(true)}
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		tables: state.tables,
		language: state.language.dictionary,
		reservations: state.reservations,
		topbar: state.topbar,
		user: state.user,
		products: state.products,
	}),
	{ create_alert }
)(Accepted)

import { Form, Formik } from 'formik'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import FlipMove from 'react-flip-move'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Select from 'react-select'
import QRCode from 'qrcode'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import { Confirmation, Input, ModalForm, More } from '../../components'
import { firestore } from '../../config/firebase'
import { create_alert, update_tables, update_place } from '../../store/actions'

function TablesMap({
	onTableSelected = () => {},
	selectedPositions = [],
	place,
	from = null,
	editRow = () => {},
	addTable = false,
	setAddTable = () => {},
	editFloors = false,
	setEditFloors = () => {},
	disableState = false,
	customPaint = null,
	initialSelected = null,
	_tables,
	update_tables,
	small_version = false,
	show_select = false,
	create_alert,
	language,
	employee,
	reservations_data = null,
	reservations_ignore = null,
	show_near_reservation = false,
	reservations,
	only_free = false,
	update_place,
	history,
}) {
	const [submitting, setSubmitting] = useState(false)
	const [zoom, setZoom] = useState(5)
	const [pan, setPan] = useState([0, 0])
	const [floor, setFloor] = useState([0, false])
	const [confirmingDelete, setConfirmingDelete] = useState(false)
	const [selected, setSelected] = useState(null)
	const [redirect, setRedirect] = useState(null)
	const [deleteConfirmation, setDeleteConfirmation] = useState(false)
	const [editTable, setEditTable] = useState(null)

	const [draggingMap, setDraggingMap] = useState(false)
	const [startMapDragPos, setStartMapDragPos] = useState(null)
	const [startMapPos, setStartMapPos] = useState(null)
	const [currentTimeFormatted, setCurrentTimeFormatted] = useState(null)
	const [editZone, setEditZone] = useState(null)
	const [editZoneName, setEditZoneName] = useState(null)
	const [tableSearch, setTableSearch] = useState('')

	const [creatingTable, setCreatingTable] = useState({
		creating: false,
		size: [1, 1],
		rotation: 0,
		name: '',
		seats: 1,
	})

	const [tables, setTables] = useState([])
	const [qr, setQr] = useState(null)
	const [timeo, setTimeo] = useState(null)

	const mapRef = useRef(null)
	const centerRef = useRef(null)
	const overmouseRef = useRef(null)

	useEffect(() => {
		setCurrentTimeFormatted(getFormattedHour(new Date()))
	}, [])

	useEffect(() => {
		if (timeo !== null) clearTimeout(timeo)
		let timeou = setTimeout(() => {
			if (tableSearch.length !== 0) {
				let search_term = `${tableSearch}`
				search_term = search_term
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
				search_term = search_term.toLowerCase()

				let all_tables = []
				_tables.floors.forEach((floor, x) => {
					all_tables = [
						...all_tables,
						...floor.tables.map((t) => ({
							...t,
							floor: { ...floor, index: x },
						})),
					]
				})

				let tableNames = all_tables.map((t) => {
					let val = `${t.name}`
					val = val.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
					val = val.toLowerCase()
					return { value: val, data: t }
				})
				let result = tableNames.find((t) => t.value === search_term)
				if (typeof result === 'object') {
					setFloor([result.data.floor.index, false])
					onTableSelected(result.data)
					setSelected(result.data)
				}
			} else {
				setSelected(null)
			}
		}, 500)
		setTimeo(timeou)
	}, [tableSearch])

	useEffect(() => {
		if (zoom > 8) setZoom(8)
		if (zoom < 2) setZoom(2)
	}, [zoom])

	useEffect(() => {
		if (_tables !== null && _tables.floors.length > 0) {
			if (!floor[1]) setTables(_tables.floors[floor[0]].tables)
			if (floor[1]) setTables(_tables.terraces[floor[0]].tables)
		} else {
			firestore.doc(`/places/${place.id}/table_maps/tables`).update({
				floors: [{ tables: [] }],
			})
		}
	}, [floor, _tables])

	useEffect(() => {
		if (tables.length > 0 && typeof tables[0].status !== 'undefined') {
			setTables(
				tables.map((table) => {
					// 0 is free
					// 1 is waiting for order
					// 2 is payment pending
					// 3 is paid but occupied

					let status = 0
					let order = null
					place.orders.forEach((ord) => {
						if (ord.table === table.id) {
							if (ord.confirmed === true) {
								order = ord
								let paid = true
								let delivered = true
								ord.items.forEach((it) => {
									if (!it.paid) paid = false
									if (it.state !== 2) delivered = false
								})
								if (!delivered || order.items.length === 0) status = 1
								else if (!paid) status = 2
								else status = 3
							}
						}
					})

					return { ...table, status, order }
				})
			)
		}
	}, [place.orders])
	useEffect(() => {
		if (tables.length > 0 && typeof tables[0].status === 'undefined') {
			setTables(
				tables.map((table) => {
					// 0 is free
					// 1 is waiting for order
					// 2 is payment pending
					// 3 is paid but occupied

					let status = 0
					let order = null
					place.orders.forEach((ord) => {
						if (ord.table === table.id) {
							if (ord.confirmed === true) {
								order = ord
								let paid = true
								let delivered = true
								ord.items.forEach((it) => {
									if (!it.paid) paid = false
									if (it.state !== 2) delivered = false
								})
								if (!delivered || order.items.length === 0) status = 1
								else if (!paid) status = 2
								else status = 3
							}
						}
					})

					return { ...table, status, order }
				})
			)
		}
	}, [tables])

	useEffect(() => {
		if (selected !== null) {
			QRCode.toDataURL(
				JSON.stringify({
					place: place.id,
					name: selected.name,
					table: selected.id,
				}),
				(err, url) => {
					setQr(url)
				}
			)
		}
	}, [selected])

	const getFormattedHour = (date) => {
		let _date = new Date(date)

		let hr = _date.getHours()
		let mn = _date.getMinutes()

		if (mn < 30) mn = '00'
		else mn = '30'

		hr = `0${hr}`.slice(-2)
		mn = `0${mn}`.slice(-2)

		return `${hr}${mn}`
	}

	const getFormattedDate = (date) => {
		let _date = new Date(date)

		let dd = _date.getDate()
		let mm = _date.getMonth() + 1
		let yyyy = _date.getFullYear()

		if (dd < 10) dd = '0' + dd
		if (mm < 10) mm = '0' + mm

		return dd + '/' + mm + '/' + yyyy
	}

	const deleteProduct = (prod, ord) => {
		let _orders = []
		let newOrder = null
		place.orders.forEach((_ord) => {
			let _ord_ = null
			if (_ord.invoiceID !== ord.invoiceID) {
				_ord_ = _ord
			} else {
				_ord_ = {
					..._ord,
					items: _ord.items.filter((it) => it.cartId !== prod.cartId),
				}
			}
			if (_ord_.items.length > 0) {
				_orders.push(_ord_)
				newOrder = _ord_
			}
		})
		setSelected({ ...selected, order: newOrder })
		firestore.collection('places').doc(place.id).update({
			orders: _orders,
		})
	}

	const renderCustomNotes = () => {
		return null
		// let _notes =
		// 	typeof selected.order.notes !== 'undefined' &&
		// 	selected.order.notes !== null
		// 		? selected.order.notes.filter(
		// 				(a_note) => typeof a_note !== 'undefined' && a_note !== null
		// 		  )
		// 		: []
		// if (_notes.length) {
		// 	return (
		// 		<div className="custom-notes">
		// 			<h4>{language.custom_notes}</h4>
		// 			<ul>
		// 				{_notes.map((a_note) => (
		// 					<li>
		// 						<i className="mi">warning</i>
		// 						<span>{a_note}</span>
		// 					</li>
		// 				))}
		// 			</ul>
		// 		</div>
		// 	)
		// }
	}

	const getUnitName = (u = 'u', quantity) => {
		switch (u) {
			case 'u':
				if (quantity > 1) return 'Unidades'
				return 'Unidad'
			case 'kg':
				if (quantity > 1) return 'Kilos'
				return 'Kilo'
			case 'lt':
				if (quantity > 1) return 'Litros'
				return 'Litro'
			case 'pt':
				if (quantity > 1) return 'Raciones'
				return 'Ración'
			case 'lb':
				if (quantity > 1) return 'Libras'
				return 'Libra'
		}
	}

	const renderModalSelected = () => {
		let sel_text = null
		let sel_class = null
		switch (`${selected.status}`) {
			case '1':
				sel_class = 'danger'
				sel_text = language.waiting_order
				break
			case '2':
				sel_class = 'warning'
				sel_text = language.payment_pending
				break
			case '3':
				sel_class = 'success'
				sel_text = language.paid
				break
			default:
				sel_class = 'primary'
				sel_text = language.free
				break
		}
		let total = 0
		let remaining = 0
		let paid = true
		if (selected.order) {
			selected.order.items.forEach((item) => {
				let qty = item.quantity || item.units
				let it_charge =
					item.price_type === 'units' ? item.price * qty : item.price
				if (!item.paid) {
					paid = false
					remaining += it_charge
				}
				total += it_charge
				item.additions.forEach((add) => {
					add.items.forEach((it) => {
						if (!it.quantity || it.quantity <= 0) {
							// Nothing
						} else {
							if (!item.paid) remaining += it.value * it.quantity
							total += it.value * it.quantity
						}
					})
				})
			})
		}

		// let total = 0
		// let total_taxed = 0
		// printInvoice.items.forEach((it) => {

		// 	total += it_charge
		// 	total_taxed +=
		// 		it.taxrate && it.taxrate > 0 ? it_charge * (it.taxrate / 100) : 0
		// 	it.additions.forEach((add) => {
		// 		add.items.forEach((_it) => {
		// 			if (_it.quantity > 0) {
		// 				total += _it.value * _it.quantity
		// 				total_taxed +=
		// 					it.taxrate && it.taxrate > 0
		// 						? _it.value * _it.quantity * (it.taxrate / 100)
		// 						: 0
		// 			}
		// 		})
		// 	})
		// })

		let reserved_obj = {}
		let reserved = false
		let tb = reservations.tables[selected.id]
		if (typeof tb !== 'undefined') {
			let currentDate = getFormattedDate(new Date())
			let day = tb[currentDate]
			if (typeof day !== 'undefined') {
				let hr = parseInt(currentTimeFormatted.slice(0, 2))
				let mn = parseInt(currentTimeFormatted.slice(-2))
				mn += 30
				if (mn >= 60) {
					mn = 0
					hr += 1
				}
				let next_hour = `${`0${hr}`.slice(-2)}${`0${mn}`.slice(-2)}`
				let _hr = day[next_hour]
				if (typeof _hr !== 'undefined') {
					reserved_obj = reservations.list[_hr]
					sel_text = language.reserved
					reserved = true
				}
			}
		}

		if (total === remaining) remaining = 0

		let employee_permissions = place.employee_categories.find(
			(cat) => cat.id === employee.category
		).permissions

		return (
			<ModalForm size="md" toggle={() => setSelected(null)}>
				{selected.status === 0 &&
					employee_permissions.indexOf('administrator') !== -1 && (
						<div className="more-holder">
							<More>
								<a
									className="button button-light"
									download={`${place.id}_table_${selected.name}`}
									href={qr}
								>
									<i className="mi">get_app</i>
									<span>Descargar QR de mesa</span>
								</a>
								<button
									onClick={() => {
										// setDeleteConfirmation(true)
										let sel = { ...selected }
										setEditTable(selected)
										setSelected(null)
									}}
									type="button"
									style={{
										marginTop: 5,
									}}
								>
									<i className="mi">edit</i>
									<span>Editar mesa</span>
								</button>
								<button
									onClick={() => {
										setDeleteConfirmation(true)
									}}
									type="button"
									style={{
										marginTop: 5,
									}}
								>
									<i className="mi">delete</i>
									<span>
										{language.remove} {language.table}
									</span>
								</button>
							</More>
						</div>
					)}
				<div>
					<div className="form-ui">
						<h1>
							{language.table} {selected.name}
						</h1>
						{selected.order !== null &&
							typeof selected.order === 'object' &&
							typeof selected.order.hotel === 'object' && (
								<div className="alert alert-blue">
									<p>
										Nombre:{' '}
										<strong>{selected.order.hotel.name}</strong>
									</p>
									{`${selected.order.hotel.room}`.length > 0 && (
										<p>
											Habitación:{' '}
											<strong>{selected.order.hotel.room}</strong>
										</p>
									)}
								</div>
							)}
						{sel_class !== 'primary' && (
							<small className={`badge ${sel_class}`}>{sel_text}</small>
						)}
						{sel_class === 'primary' && (
							<aside className="empty">
								<i className="mi">{!reserved ? 'layers' : 'book'}</i>

								<h3>{sel_text}</h3>
								{reserved && (
									<>
										<h4>
											{reserved_obj.name} - {reserved_obj.phone}
										</h4>
										<p>{reserved_obj.notes}</p>
									</>
								)}
							</aside>
						)}
						<FlipMove
							enterAnimation="accordionVertical"
							leaveAnimation="accordionVertical"
							duration={100}
							typeName="ol"
							className="shop-list shop-list-table"
						>
							{selected.order !== null &&
								typeof selected.order !== 'undefined' &&
								selected.order.items.map((item) => {
									let qty = item.quantity || item.units
									return (
										<li>
											<blockquote>
												<div>
													<span
														className="img"
														style={{
															backgroundImage: `url(${item.image})`,
														}}
													/>
													<strong>
														{item.name}{' '}
														{item.price_type === 'units'
															? ` - ${qty} ${getUnitName(
																	item.unit,
																	qty
															  )}`
															: ''}
													</strong>

													<span
														className={`order-status badge ${
															item.state === 0
																? 'danger'
																: 'success'
														}`}
													>
														{item.state === 0
															? language.waiting
															: language.delivered}
													</span>
													{item.paid &&
														sel_class !== 'success' && (
															<span
																className={`order-status badge success`}
															>
																{language.paid}
															</span>
														)}
												</div>
												<span className="price">
													<pre>{item.price}</pre>
													<i className="mi">euro_symbol</i>
												</span>
											</blockquote>
											{item.state === 0 && (
												<button
													type="button"
													onClick={(e) =>
														deleteProduct(item, selected.order)
													}
													className="button button-link button-link-sm button-link-delete"
												>
													<i className="mi">
														remove_circle_outline
													</i>
												</button>
											)}
											<div className="adds">
												{item.additions.map((add) => {
													return add.items.map((it) => {
														if (
															!it.quantity ||
															it.quantity <= 0
														) {
															return null
														} else {
															return (
																<div>
																	<label className="no-toggle">
																		<p className="add">
																			<b>{it.name}</b>
																			<span className="price price-sm price-gray">
																				<pre>
																					{it.value}
																				</pre>
																				<i className="mi">
																					euro_symbol
																				</i>
																			</span>
																		</p>
																	</label>
																</div>
															)
														}
													})
												})}
											</div>
										</li>
									)
								})}
						</FlipMove>
						{selected.order !== null &&
							typeof selected.order !== 'undefined' && (
								<span
									className="price price-lg"
									style={{
										opacity: remaining === 0 ? 1 : 0.45,
									}}
								>
									<small>{language.total_due}:</small>
									<pre>{total}</pre>
									<i className="mi">euro_symbol</i>
								</span>
							)}
						{selected.order !== null &&
							remaining !== 0 &&
							typeof selected.order !== 'undefined' && (
								<span className="price price-lg">
									<small>{language.remaining_due}:</small>
									<pre>{remaining}</pre>
									<i className="mi">euro_symbol</i>
								</span>
							)}
						{selected.order !== null && renderCustomNotes()}

						<footer className={`${paid ? 'cols-1' : 'cols-2'}`}>
							<button
								className="button button-outline"
								onClick={(e) =>
									setRedirect(`/checkout?table=${selected.id}`)
								}
								type="button"
							>
								<i className="mi">add</i>
								<span>{language.add_products}</span>
							</button>
							{!paid && (
								<button
									type="button"
									onClick={(e) =>
										setRedirect(
											`/checkout?table=${selected.id}&charge=true`
										)
									}
									className="button button-primary"
								>
									<i className="mi">arrow_forward</i>
									<span>{language.charge}</span>
								</button>
							)}
							{selected.order !== null &&
								typeof selected.order !== 'undefined' && (
									<button
										type="button"
										onClick={(e) => setFree()}
										className="button button-link button-link-sm button-link-gray"
									>
										<i className="mi">flip_to_back</i>{' '}
										<span>{language.set_free}</span>
									</button>
								)}
						</footer>
					</div>
				</div>
			</ModalForm>
		)
	}

	const getSizeBySeats = (seats) => {
		if (seats > 0 && seats <= 4) return [1, 1]
		if (seats <= 6) return [2, 1]
		if (seats <= 8) return [3, 1]
		if (seats <= 10) return [4, 1]
		if (seats <= 12) return [5, 1]
		if (seats <= 14) return [6, 1]
		if (seats <= 16) return [7, 1]
		if (seats <= 18) return [8, 1]
		if (seats <= 20) return [9, 1]
	}

	const getTableFloors = () => {
		let options = []
		for (let x = 0; x < place.table_floors; x++) {
			let zone_name = _tables.floors[x].name || `Zona ${x + 1}`

			options.push({ value: `0_${x}`, label: zone_name })
		}
		return options
	}

	const setFree = async () => {
		let _orders = place.orders.filter((ord) => ord.table !== selected.id)

		await firestore.collection('places').doc(place.id).update({
			orders: _orders,
		})

		update_place({
			orders: _orders,
		})
		create_alert(
			language.table_x_is_now_free.replace('X', selected.name),
			'success'
		)
		setSelected(null)
		history.replace('/orders/accepted')
	}

	const deleteFloor = async (n) => {
		await firestore
			.collection('places')
			.doc(place.id)
			.update({
				table_floors: place.table_floors - 1,
			})
		let newTables = _tables.floors.filter((a, b) => b !== n)

		await firestore
			.collection('places')
			.doc(place.id)
			.collection('table_maps')
			.doc('tables')
			.update({
				floors: newTables,
			})
		update_tables({
			floors: newTables,
		})
		setSubmitting(false)
	}

	const doEditZoneName = async (i) => {
		let newFloors = _tables.floors.map((fm, j) => {
			if (j !== i) return fm
			return { ...fm, name: editZoneName }
		})

		setEditZoneName(null)

		update_tables({
			floors: [...newFloors],
		})
		await firestore
			.collection('places')
			.doc(place.id)
			.collection('table_maps')
			.doc('tables')
			.update({
				floors: [...newFloors],
			})
	}

	const renderFloors = () => {
		let floors = []

		for (let i = 0; i < place.table_floors; i++) {
			let zone_name = _tables.floors[i].name || `Zona ${i + 1}`
			floors.push(
				<div
					key={i}
					className="floor"
					style={{
						marginBottom: 10,
						height: 45,
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-start',
						paddingLeft: 20,
						paddingRight: 10,
					}}
				>
					{editZone === i && (
						<input
							className="form-control"
							defaultValue={zone_name}
							onChange={(e) => setEditZoneName(e.target.value)}
							value={editZoneName}
						/>
					)}
					{editZone !== i && (
						<p
							style={{
								margin: 0,
								fontSize: 17,
								fontWeight: 'bold',
							}}
						>
							{zone_name}
						</p>
					)}
					{i !== 0 && editZone === i && (
						<button
							style={{
								backgroundColor: 'transparent',
								border: 'none',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginLeft: 15,
							}}
							onClick={() => {
								setConfirmingDelete([i, false])
							}}
							type="button"
						>
							<i className="mi">delete</i>
						</button>
					)}
					{editZone === null && (
						<button
							className="button button-link button-small inline"
							style={{
								marginLeft: 15,
							}}
							onClick={() => {
								setEditZoneName(zone_name)
								setEditZone(i)
							}}
						>
							Editar
						</button>
					)}
					{editZone === i && (
						<>
							<button
								className="button button-primary button-small inline"
								style={{
									marginLeft: 15,
								}}
								onClick={async () => {
									setSubmitting(true)
									await doEditZoneName(i)
									setSubmitting(false)
									setEditZone(null)
								}}
							>
								Guardar
							</button>
							<button
								className="button button-light button-small inline"
								style={{
									marginLeft: 5,
								}}
								onClick={() => {
									setEditZone(null)
								}}
							>
								Cancelar
							</button>
						</>
					)}
				</div>
			)
		}

		floors.reverse()
		floors.unshift(
			<button
				type="button"
				className="button block add_floor"
				style={{
					marginBottom: 10,
				}}
				onClick={async () => {
					update_tables({
						floors: [..._tables.floors, { tables: [] }],
					})
					await firestore
						.collection('places')
						.doc(place.id)
						.update({
							table_floors: place.table_floors + 1,
						})
					await firestore
						.collection('places')
						.doc(place.id)
						.collection('table_maps')
						.doc('tables')
						.update({
							floors: [..._tables.floors, { tables: [] }],
						})
				}}
			>
				{language.add_floor} <i className="mi">add</i>
			</button>
		)

		return (
			<div
				className="floor_container"
				style={{
					padding: 20,
					width: '100%',
				}}
			>
				<FlipMove
					enterAnimation="accordionVertical"
					leaveAnimation="accordionVertical"
					duration={100}
				>
					{floors}
				</FlipMove>
			</div>
		)
	}

	const doEditTable = async (values, tbl) => {
		let newTables = _tables.floors.map((f, x) => {
			if (x !== floor[0]) return f
			return {
				...f,
				tables: f.tables.map((t) => {
					if (t.id !== tbl.id) return t
					return {
						...t,
						name: values.name,
						seats: values.seats,
					}
				}),
			}
		})
		update_tables({
			floors: newTables,
		})
		await firestore
			.collection('places')
			.doc(place.id)
			.collection('table_maps')
			.doc('tables')
			.update({
				floors: newTables,
			})
		setEditTable(null)
	}

	const createTable = async (e) => {
		let centerPos = centerRef.current.getBoundingClientRect()
		let diff = [(centerPos.x - e.clientX) * -1, centerPos.y - e.clientY]
		let withoutZoom = [diff[0] / zoom, diff[1] / zoom]
		let units = [withoutZoom[0] / 10, withoutZoom[1] / 10]
		let newTable = {
			position: [units[0], units[1]],
			size: [creatingTable.size[0], creatingTable.size[1]],
			rotation: creatingTable.rotation,
			id: `${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`,
			seats: creatingTable.seats,
			name: creatingTable.name,
		}
		setCreatingTable({
			creating: false,
			size: [1, 1],
			rotation: 0,
			name: '',
			seats: 1,
		})
		setTables([...tables, newTable])
		let newFloors = _tables.floors.map((fl, i) => {
			if (i !== floor[0]) return fl
			return { ...fl, tables: [...fl.tables, newTable] }
		})
		update_tables({
			floors: [...newFloors],
		})
		await firestore
			.collection('places')
			.doc(place.id)
			.collection('table_maps')
			.doc('tables')
			.update({
				floors: [...newFloors],
			})
	}

	const renderAddTable = (edit = false) => {
		let vals = {}
		if (edit !== false)
			vals = { name: editTable.name, seats: editTable.seats }

		return (
			<ModalForm size="sm" toggle={() => setAddTable(false)}>
				<div>
					<Formik
						initialValues={{
							name: vals.name || '',
							seats: vals.seats || '',
						}}
						validate={(values) => {
							if (isNaN(parseInt(values.name))) {
								return {
									name: 'Debe ser un número',
								}
							} else if (isNaN(parseInt(values.seats))) {
								return {
									seats: 'Debe ser un número',
								}
							} else if (parseInt(values.seats) > 20) {
								return {
									seats: 'Debe ser un número menor a 21',
								}
							}
						}}
						onSubmit={(values) => {
							if (edit) {
								doEditTable(values, editTable)
							} else {
								setCreatingTable({
									creating: true,
									size: getSizeBySeats(values.seats),
									rotation: 0,
									name: values.name,
									seats: values.seats,
								})
								setAddTable(false)
							}
						}}
					>
						{({ errors, touched, values, setFieldValue }) => {
							return (
								<Form className="form-ui">
									<h1>{edit ? 'Editar mesa' : language.new_table}</h1>
									<Input
										label={language.table_number}
										disabled={submitting}
										type="text"
										name="name"
										errors={errors}
										touched={touched}
										rowcolleft={4}
										rowcolright={8}
									/>
									<Input
										label={language.seats}
										disabled={submitting}
										type="text"
										name="seats"
										errors={errors}
										touched={touched}
										rowcolleft={4}
										rowcolright={8}
									/>
									{edit !== false && (
										<small
											style={{
												marginTop: 20,
												marginBottom: 20,
												width: '100%',
												textAlign: 'center',
												paddingLeft: 40,
												paddingRight: 40,
											}}
										>
											Los cambios pueden no verse correctamente
											reflejados en los pedidos y reservas existentes
											**
										</small>
									)}
									<footer className="cols-2">
										<button
											onClick={() => {
												setAddTable(false)
											}}
											className="button button-light"
											type="button"
										>
											{language.cancel}
										</button>
										<button
											type="submit"
											disabled={submitting}
											className="button button-primary"
										>
											{submitting && (
												<div role="status">
													<span>{language.loading}...</span>
												</div>
											)}
											{!submitting &&
												(edit
													? 'Actualizar mesa'
													: language.add_table)}
										</button>
									</footer>
								</Form>
							)
						}}
					</Formik>
				</div>
			</ModalForm>
		)
	}

	const onMouseMoveRan = (e) => {
		e.persist()
		if (creatingTable.creating && overmouseRef.current !== null) {
			overmouseRef.current.style.left = `${
				e.clientX - ((creatingTable.size[0] * 10) / 2) * zoom
			}px`
			overmouseRef.current.style.top = `${
				e.clientY - ((creatingTable.size[1] * 10) / 2) * zoom
			}px`
		}
		if (startMapDragPos === null || startMapPos === null || !draggingMap)
			return
		let mapPos = mapRef.current.getBoundingClientRect()
		let pos = [mapPos.x - e.clientX, mapPos.y - e.clientY]
		let diff = [
			(startMapDragPos[0] - pos[0]) * -1,
			(startMapDragPos[1] - pos[1]) * -1,
		]
		setPan([startMapPos[0] - diff[0], startMapPos[1] - diff[1]])
	}

	const deleteTable = async (id) => {
		let newFloors = _tables.floors.map((fl) => ({
			tables: fl.tables.filter((tb) => tb.id !== id),
		}))
		update_tables({
			floors: [...newFloors],
		})
		await firestore
			.collection('places')
			.doc(place.id)
			.collection('table_maps')
			.doc('tables')
			.update({
				floors: [...newFloors],
			})
	}

	const handleMouseMove = useCallback(
		_.throttle(
			(e) => {
				e.persist()
				onMouseMoveRan(e)
			},
			50,
			{ leading: false }
		),
		[onMouseMoveRan]
	)

	return (
		<>
			{redirect && <Redirect to={redirect} />}
			{addTable && renderAddTable()}
			{editTable !== null && renderAddTable(true)}
			{show_select && selected !== null && renderModalSelected()}
			{editFloors && confirmingDelete === false && (
				<ModalForm size="lg" toggle={() => setEditFloors(false)}>
					<div>
						<form
							className="form-ui"
							onSubmit={(e) => e.preventDefault()}
						>
							<h1>{language.edit_floors}</h1>
							{renderFloors()}
						</form>
					</div>
				</ModalForm>
			)}
			{confirmingDelete !== false && (
				<Confirmation
					label={language.remove}
					icon="delete"
					submitting={submitting}
					onChange={async (res) => {
						if (res === false) {
							setConfirmingDelete(false)
						} else {
							setSubmitting(true)
							await deleteFloor(confirmingDelete[0])
							setConfirmingDelete(false)
							setEditZone(null)
						}
					}}
				>
					{language.sure_you_want_to_delete}
					<br />
					{_tables.floors[confirmingDelete[0]].name}?
				</Confirmation>
			)}
			{deleteConfirmation !== false && (
				<Confirmation
					label={language.remove}
					icon="delete"
					submitting={submitting}
					onChange={async (res) => {
						if (res === false) {
							setDeleteConfirmation(false)
							setSubmitting(false)
						} else {
							setSubmitting(true)
							await deleteTable(selected.id)
							setSelected(null)
							setSubmitting(false)
							setDeleteConfirmation(false)
						}
					}}
				>
					{language.sure_you_want_to_delete}
					<br /> {language.table} {selected ? selected.name : ''}?
				</Confirmation>
			)}
			{creatingTable.creating && (
				<>
					<div className="adding-table">
						<p
							style={{
								fontSize: 16,
							}}
						>
							Coloca la mesa en donde quieras dando click
						</p>
						<button
							type="button"
							className="button button-small"
							onClick={() => {
								let rot = creatingTable.rotation + 45
								if (rot === 365) rot = 0
								setCreatingTable({
									...creatingTable,
									rotation: rot,
								})
							}}
						>
							<i className="mi">rotate_right</i>
							<span>Rotar mesa</span>
						</button>
						<button
							type="button"
							className="button button-light button-small"
							onClick={() => {
								setCreatingTable({
									creating: false,
									size: [1, 1],
									rotation: 0,
									name: '',
									seats: 1,
								})
							}}
						>
							<i className="mi">cancel</i>
							<span>Cancelar</span>
						</button>
					</div>
					<MouseTooltip visible={true} offsetX={0} offsetY={0}>
						<div
							ref={overmouseRef}
							style={{
								position: 'fixed',
								width: creatingTable.size[0] * 10 * zoom,
								height: creatingTable.size[1] * 10 * zoom,
								zIndex: 100,
								borderRadius: 5,
								transform: `rotate(${creatingTable.rotation}deg)`,
							}}
							className="overmouse"
						/>
					</MouseTooltip>
				</>
			)}
			<div
				className={`tables-component ${draggingMap ? 'grabbing' : ''} ${
					selected !== null ? 'selecting' : ''
				} `}
				style={{
					userSelect: 'none',
				}}
				ref={mapRef}
				onMouseDown={(e) => {
					setDraggingMap(true)
					let mapPos = mapRef.current.getBoundingClientRect()
					setStartMapDragPos([mapPos.x - e.clientX, mapPos.y - e.clientY])
					setStartMapPos([pan[0], pan[1]])
				}}
				onMouseMove={(e) => {
					e.persist()
					handleMouseMove(e)
				}}
				onMouseUp={(e) => {
					if (
						startMapDragPos === null ||
						startMapPos === null ||
						!draggingMap
					)
						return
					if (
						startMapPos[0] === pan[0] &&
						startMapPos[1] === pan[1] &&
						creatingTable.creating
					)
						createTable(e)
					setStartMapDragPos(null)
					setDraggingMap(false)
				}}
				onMouseLeave={(e) => {
					setStartMapDragPos(null)
					setDraggingMap(false)
				}}
			>
				{!only_free && (
					<aside className="conventions">
						{reservations_data === null && (
							<>
								<span>
									<i className="badge primary"></i> <b>Libre</b>
								</span>
								<span>
									<i className="badge orange"></i> <b>Reservado</b>
								</span>
								<span>
									<i className="badge warning"></i>{' '}
									<b>Pago Pendiente</b>
								</span>
								<span>
									<i className="badge danger"></i> <b>Esperando</b>
								</span>
								<span>
									<i className="badge success"></i> <b>Pagado</b>
								</span>
							</>
						)}
						{reservations_data !== null && (
							<>
								<span>
									<i className="badge warning"></i> <b>Ocupada</b>
								</span>
								<span>
									<i className="badge success"></i> <b>Libre</b>
								</span>
							</>
						)}
					</aside>
				)}
				<div
					className="center"
					style={{
						marginLeft: pan[0],
						marginTop: pan[1],
					}}
					data-zoom={zoom}
					ref={centerRef}
				>
					<div
						className="bgimage"
						style={{
							backgroundSize: `${20 * zoom}px ${20 * zoom}px`,
						}}
					/>
					{tables.map((table) => {
						let status = 0
						let disabled = false
						let reserved = false
						if (typeof table.status !== 'undefined') status = table.status
						if (reservations_data !== null) {
							status = 3
							let _table = reservations_data.find(
								(t) => t.id === table.id
							)
							if (!_table.free && table.id !== reservations_ignore) {
								status = 2
								disabled = true
							}
						}
						if (show_near_reservation && reservations !== null) {
							let tb = reservations.tables[table.id]
							if (typeof tb !== 'undefined') {
								let currentDate = getFormattedDate(new Date())
								let day = tb[currentDate]
								if (typeof day !== 'undefined') {
									let hr = parseInt(currentTimeFormatted.slice(0, 2))
									let mn = parseInt(currentTimeFormatted.slice(-2))
									mn += 30
									if (mn >= 60) {
										mn = 0
										hr += 1
									}
									let next_hour = `${`0${hr}`.slice(
										-2
									)}${`0${mn}`.slice(-2)}`
									let _hr = day[next_hour]
									if (typeof _hr !== 'undefined') reserved = true
								}
							}
						}

						if (only_free && status !== 0) {
							disabled = true
						}
						return (
							<button
								key={table.id}
								disabled={disabled}
								type="button"
								className={`table ${
									selected !== null && table.id === selected.id
										? 'selected'
										: ''
								} ${
									status === 1
										? 'danger'
										: status === 2
										? 'warning'
										: status === 3
										? 'success'
										: ''
								} ${reserved ? 'reserved' : ''}`}
								data-w={table.size[0]}
								data-h={table.size[1]}
								data-r={table.rotation}
								data-x={`${table.position[0].toFixed(1)}`}
								data-y={`${(table.position[1] * -1).toFixed(1)}`}
								data-s={table.seats}
								data-o={table.seats % 2 === 0 ? 0 : 1}
								onClick={(e) => {
									e.stopPropagation()
									onTableSelected(table)
									setSelected(table)
								}}
								onMouseDown={(e) => {
									e.stopPropagation()
								}}
							>
								<i className="l"></i>
								<i className="t"></i>
								<i className="r"></i>
								<i className="b"></i>
								<b>
									<span>{table.name}</span>
								</b>
								<em>{table.seats}</em>
								{table.order !== null &&
									typeof table.order === 'object' &&
									typeof table.order.hotel === 'object' &&
									`${table.order.hotel.room}`.length > 0 && (
										<strong>Hab: {table.order.hotel.room}</strong>
									)}
							</button>
						)
					})}
				</div>
			</div>
			<div className="floors-holder">
				<div className="input-search">
					<input
						type="text"
						autoFocus={true}
						value={tableSearch}
						onChange={(e) => setTableSearch(e.target.value)}
						className="form-control"
						placeholder="Buscar mesa..."
					/>
					<i className="mi">search</i>
				</div>
				<Select
					options={getTableFloors()}
					isDisabled={creatingTable.creating}
					onChange={(e) => {
						setPan([0, 0])
						setZoom(5)
						setFloor([
							parseInt(`${e.value}`.split('_')[1]),
							!!parseInt(`${e.value}`.split('_')[0]),
						])
					}}
					defaultValue={{ value: 0, label: `${language.floor} 1` }}
					placeholder={language.select}
				/>
			</div>

			<aside style={{ zIndex: 1000 }} className="controls">
				<button
					onClick={(e) => {
						setPan([0, 0])
						setZoom(5)
					}}
					className="recenter"
					type="button"
				>
					<i className="mi">my_location</i>
				</button>
				<button
					disabled={zoom >= 8}
					onClick={(e) => {
						setZoom(zoom + 1)
					}}
					className="zoom-in"
					type="button"
				>
					<i className="mi">add</i>
				</button>
				<button
					disabled={zoom <= 2}
					onClick={(e) => {
						setZoom(zoom - 1)
					}}
					className="zoom-out"
					type="button"
				>
					<i className="mi">remove</i>
				</button>
			</aside>
		</>
	)
}

export default connect(
	(state) => ({
		_tables: state.tables,
		place: state.place,
		language: state.language.dictionary,
		reservations: state.reservations,
	}),
	{
		update_tables,
		create_alert,
		update_place,
	}
)(TablesMap)

import React, { useEffect, useState } from 'react'

function ModalForm({
	children,
	toggle = () => {},
	hideClose = false,
	size = 'md'
}) {
	const [className, setClassName] = useState('modal-form modal-form-' + size)

	useEffect(() => {
		setTimeout(() => {
			setClassName(`${className} show`)
		}, 50)
	}, [])

	const onClose = () => {
		setClassName(`${className} close`)
		setTimeout(() => toggle(false), 200)
	}

	return (
		<div className={className}>
			<div onClick={e => e.stopPropagation()}>
				<>{children}</>
				{!hideClose && (
					<button className="close" onClick={onClose}>
						<i className="mi">close</i>
					</button>
				)}
			</div>
		</div>
	)
}

export default ModalForm

export default payload => {
	if (payload === false)
		return {
			type: 'SIGNOUT_EMPLOYEE'
		}
	else
		return {
			type: 'SIGNIN_EMPLOYEE',
			payload
		}
}

import algoliasearch from 'algoliasearch'
import { Field, Form, Formik } from 'formik'
import moment from 'moment-timezone'
import 'moment/locale/es'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
pdfMake.vfs = pdfFonts.pdfMake.vfs

import CreatableSelect from 'react-select/creatable'
import { ModalForm, Spinner } from '../../../components'
import { firestore, functions, storage } from '../../../config/firebase'

import { create_alert } from '../../../store/actions'

const searchClientAlg = algoliasearch(
	'MM6KWVFEQD',
	'fce390db586e17fe0242f65ae2dc9c3c'
)

moment.locale('es')

const actionToText = (action) => {
	if (action === 'deliver') return 'Entrega'
	if (action === 'return') return 'Devolución'
	if (action === 'change') return 'Cambio'
	return ''
}

const getMaterialName = (materials, material) => {
	return materials.find((f) => f.id === material)?.name || ''
}

function Report({ user, topbar, create_alert }) {
	let history = useHistory()

	const [mainData, setMainData] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [showEditEmails, setShowEditEmails] = useState(false)
	const [results, setResults] = useState([])
	const [temporalClientData, setTemporalClientData] = useState(null)

	useEffect(() => {
		let mainListener = firestore
			.doc(`/items_management/${user.id}`)
			.onSnapshot((snap) => {
				snap = snap.data()
				setMainData(snap || null)
			})

		return () => {
			mainListener()
		}
	}, [])

	const renderEditEmails = () => {
		return (
			<ModalForm toggle={() => setShowEditEmails(false)} size="sm">
				<Formik
					initialValues={{
						emails: mainData.report_emails || [],
					}}
					validateOnBlur={false}
					validateOnChange={false}
					onSubmit={async (values) => {
						setSubmitting(true)
						await firestore.doc(`/items_management/${user.id}`).update({
							report_emails: values.emails,
						})
						setSubmitting(false)
						setShowEditEmails(false)
					}}
				>
					{({ errors, touched, setFieldValue, values }) => (
						<Form className="form-ui">
							<h1>Editar Emails</h1>
							<div
								style={{
									padding: 15,
								}}
							>
								<div className={`form-group upper-1`}>
									<div
										className="form-row"
										style={{
											paddingBottom: 10,
										}}
									>
										<div
											className="col-3"
											style={{
												marginBottom: 10,
											}}
										>
											<label>Emails:</label>
										</div>
										<div className="col-9">
											<CreatableSelect
												placeholder={'Emails'}
												isDisabled={submitting}
												isClearable
												onChange={(v) =>
													v === null
														? setFieldValue('emails', [])
														: setFieldValue(
																'emails',
																v.map((r) =>
																	`${r.value}`
																		.trim()
																		.toLowerCase()
																)
														  )
												}
												options={[]}
												value={values.emails.map((r) => ({
													label: r,
													value: r,
												}))}
												className="react-select"
												classNamePrefix="react-select"
												isMulti
												autoFocus
												hideSelectedOptions
												formatCreateLabel={(str) =>
													`Agregar "${str}"`
												}
											/>
										</div>
									</div>
								</div>
							</div>
							<footer style={{ marginTop: 10 }}>
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">Cargando...</span>
										</div>
									)}
									{!submitting && (
										<>
											<span>Guardar</span>
										</>
									)}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	const prefillRoom = async (number, setFieldValue, withId = false) => {
		let res = null
		if (withId) {
			res = await firestore
				.doc(`/items_management/${user.id}/clients/${number}`)
				.get()
			res = res.data()
		} else {
			res = await firestore
				.collection(`/items_management/${user.id}/clients`)
				.where('room', '==', `${number}`)
				.where('checkin', '<=', moment().valueOf())
				.orderBy('checkin', 'desc')
				.limit(20)
				.get()
			res = res.docs.map((d) => d.data())
			res = res.filter((f) => f.status !== 'due-in')
			res = res[0]
		}

		if (res.length === 0) {
			create_alert('Cliente no encontrado', 'danger')
			setFieldValue('client_id', null)
			setFieldValue('name', '')
			setFieldValue('room', '')
			// setFieldValue('checkin', '')
			// setFieldValue('checkout', '')
			// setFieldValue('adults', 0)
			// setFieldValue('children', 0)
		} else {
			setTemporalClientData(res)
			setFieldValue('client_id', res.id)
			setFieldValue('name', res.name)
			setFieldValue('room', res.room)
			// setFieldValue('checkin', res.checkin)
			// setFieldValue('checkout', res.checkout)
			// setFieldValue('adults', parseInt(res.adults || 0))
			// setFieldValue('children', parseInt(res.children || 0))
		}
	}

	const renderGenerateReport = () => {
		return (
			<div
				style={{
					marginTop: 35,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<div
					style={{
						maxWidth: 800,
					}}
				>
					<Formik
						initialValues={{
							today: false,
							checkin: null,
							checkout: null,
							more_one_change: false,
							no_return: false,
							checkout_today: false,
							pending_checkout: false,
							all: true,
							name: '',
							room: '',
							client_val: null,
						}}
						validate={(values) => {
							let errors = {}
							return errors
						}}
						onSubmit={async (values, { resetForm }) => {
							setSubmitting(true)

							let result = null

							if (values.client_val) {
								result = [temporalClientData]
							} else {
								// DATE FILTERS
								if (
									values.checkin !== null ||
									values.checkout !== null ||
									values.today !== false
								) {
									let from = null
									let to = null
									if (values.today) {
										from = moment()
											.tz('Europe/Madrid')
											.set({ hour: 0, minute: 0, second: 0 })
											.valueOf()
										to = moment()
											.tz('Europe/Madrid')
											.set({ hour: 23, minute: 59, second: 59 })
											.valueOf()
									} else {
										if (values.checkin) {
											from = moment(values.checkin)
												.tz('Europe/Madrid')
												.set({ hour: 0, minute: 0, second: 0 })
												.valueOf()
										}
										if (values.checkout) {
											to = moment(values.checkout)
												.tz('Europe/Madrid')
												.set({ hour: 23, minute: 59, second: 59 })
												.valueOf()
										}
									}

									let accumulated_result = []
									if (from !== null) {
										accumulated_result = await firestore
											.collection(
												`/items_management/${user.id}/clients`
											)
											.where('checkout', '>', from)
											.get()
										accumulated_result = accumulated_result.docs.map(
											(d) => d.data()
										)
										if (to !== null)
											accumulated_result = accumulated_result.filter(
												(f) => f.checkin < to
											)
									} else if (to !== null) {
										accumulated_result = await firestore
											.collection(
												`/items_management/${user.id}/clients`
											)
											.where('checkin', '<', to)
											.get()
										accumulated_result = accumulated_result.docs.map(
											(d) => d.data()
										)
									}

									result = accumulated_result
								}

								// FIELD FILTER
								if (Array.isArray(result)) {
									if (values.more_one_change) {
										result = result.filter(
											(f) => f.changes_length > 1
										)
									}
									if (values.no_return) {
										result = result.filter((f) => f.owe_items > 0)
									}
									if (values.checkout_today) {
										result = result.filter((f) =>
											moment(f.checkout)
												.tz('Europe/Madrid')
												.isSame(moment().valueOf(), 'day')
										)
									}
									if (values.pending_checkout) {
										console.log('PENDING')
									}
								} else {
									let accumulated_result = null
									if (values.all) {
										let res = await firestore
											.collection(
												`/items_management/${user.id}/clients`
											)
											.get()
										res = res.docs.map((d) => d.data())
										accumulated_result = res
									} else {
										if (values.more_one_change) {
											let res = await firestore
												.collection(
													`/items_management/${user.id}/clients`
												)
												.where('changes_length', '>', 1)
												.get()
											res = res.docs.map((d) => d.data())
											if (Array.isArray(accumulated_result)) {
												res = res.map((r) => r.id)
												accumulated_result =
													accumulated_result.filter((f) =>
														res.includes(f.id)
													)
											} else {
												accumulated_result = res
											}
										}
										if (values.no_return) {
											let res = await firestore
												.collection(
													`/items_management/${user.id}/clients`
												)
												.where('owe_items', '>', 0)
												.get()
											res = res.docs.map((d) => d.data())
											if (Array.isArray(accumulated_result)) {
												res = res.map((r) => r.id)
												accumulated_result =
													accumulated_result.filter((f) =>
														res.includes(f.id)
													)
											} else {
												accumulated_result = res
											}
										}
										if (values.checkout_today) {
											let res = await firestore
												.collection(
													`/items_management/${user.id}/clients`
												)
												.where(
													'checkout',
													'>',
													moment()
														.tz('Europe/Madrid')
														.set({
															hour: 0,
															minute: 0,
															second: 0,
														})
														.valueOf()
												)
												.get()
											res = res.docs.map((d) => d.data())
											res = res.filter((f) =>
												moment(f.checkout)
													.tz('Europe/Madrid')
													.isSame(moment().valueOf(), 'day')
											)
											if (Array.isArray(accumulated_result)) {
												res = res.map((r) => r.id)
												accumulated_result =
													accumulated_result.filter((f) =>
														res.includes(f.id)
													)
											} else {
												accumulated_result = res
											}
										}
										if (values.pending_checkout) {
											let res = await firestore
												.collection(
													`/items_management/${user.id}/clients`
												)
												.where('owe_items', '>', 0)
												.orderBy('owe_items', 'desc')
												.get()
											res = res.docs.map((d) => d.data())
											res = res.filter(
												(f) => f.checkout < moment().valueOf()
											)
											res = res.filter(
												(f) =>
													f.pending_material_regularization_completed ===
													false
											)
											if (Array.isArray(accumulated_result)) {
												res = res.map((r) => r.id)
												accumulated_result =
													accumulated_result.filter((f) =>
														res.includes(f.id)
													)
											} else {
												accumulated_result = res
											}
										}
									}

									result = accumulated_result
								}
							}

							setResults(result || [])
							setSubmitting(false)
						}}
					>
						{({ values, errors, touched, setFieldValue, submitForm }) => (
							<Form
								className="form-ui"
								style={{
									paddingLeft: 0,
									paddingRight: 0,
									paddingTop: 20,
								}}
							>
								<div
									className="form-group cols-12 form-group-check"
									style={{
										marginBottom: 20,
									}}
								>
									<div className="form-row">
										<div className="col-12">
											<Field
												type="checkbox"
												name="today"
												id="today"
												checked={values.today}
												onChange={(e) => {
													setFieldValue('today', e.target.checked)
													setFieldValue('checkin', null)
													setFieldValue('checkout', null)
												}}
											/>
											<label htmlFor="today">
												<b></b> <span>Hoy</span>
											</label>
										</div>
									</div>
								</div>
								<div
									className="form-group cols-6 colside-left"
									style={{
										margin: 0,
									}}
								>
									<div className="form-row">
										<div className="col-3">
											<label>Fecha Entrada:</label>
										</div>
										<div className="col-9">
											<DatePicker
												locale="es"
												disabled={submitting}
												selected={values.checkin}
												onChange={(e) => {
													setFieldValue(
														'checkin',
														e === null ? null : e
													)
													setFieldValue('today', false)
												}}
												className="form-control"
												placeholderText="Seleccionar Fecha..."
												dateFormat="dd/MM/yy"
												isClearable
											/>
										</div>
									</div>
								</div>
								<div
									className="form-group cols-6 colside-right"
									style={{
										margin: 0,
									}}
								>
									<div className="form-row">
										<div className="col-3">
											<label>Fecha Salida:</label>
										</div>
										<div className="col-9">
											<DatePicker
												locale="es"
												disabled={submitting}
												selected={values.checkout}
												onChange={(e) => {
													setFieldValue(
														'checkout',
														e === null ? null : e
													)
													setFieldValue('today', false)
												}}
												className="form-control"
												placeholderText="Seleccionar Fecha..."
												dateFormat="dd/MM/yy"
												isClearable
											/>
										</div>
									</div>
								</div>
								<hr />
								<div
									className="form-group cols-6 form-group-check"
									style={{
										marginBottom: 20,
									}}
								>
									<div className="form-row">
										<div className="col-12">
											<Field
												type="checkbox"
												name="more_one_change"
												id="more_one_change"
												checked={values.more_one_change}
												onChange={(e) => {
													setFieldValue(
														'more_one_change',
														e.target.checked
													)
													if (
														!e.target.checked &&
														!values.no_return &&
														!values.checkout_today &&
														!values.pending_checkout
													)
														setFieldValue('all', true)
													else {
														setFieldValue(
															'pending_checkout',
															false
														)
														setFieldValue('all', false)
													}
												}}
											/>
											<label htmlFor="more_one_change">
												<b></b> <span>MAS DE 1 CAMBIO</span>
											</label>
										</div>
									</div>
								</div>
								<div
									className="form-group cols-6 form-group-check"
									style={{
										marginBottom: 20,
									}}
								>
									<div className="form-row">
										<div className="col-12">
											<Field
												type="checkbox"
												name="no_return"
												id="no_return"
												checked={values.no_return}
												onChange={(e) => {
													setFieldValue(
														'no_return',
														e.target.checked
													)
													if (
														!e.target.checked &&
														!values.checkout_today &&
														!values.more_one_change &&
														!values.pending_checkout
													)
														setFieldValue('all', true)
													else {
														setFieldValue(
															'pending_checkout',
															false
														)
														setFieldValue('all', false)
													}
												}}
											/>
											<label htmlFor="no_return">
												<b></b> <span>SIN DEVOLUCIÓN</span>
											</label>
										</div>
									</div>
								</div>
								<div
									className="form-group cols-6 form-group-check"
									style={{
										marginBottom: 20,
									}}
								>
									<div className="form-row">
										<div className="col-12">
											<Field
												type="checkbox"
												name="checkout_today"
												id="checkout_today"
												checked={values.checkout_today}
												onChange={(e) => {
													setFieldValue(
														'checkout_today',
														e.target.checked
													)
													if (
														!e.target.checked &&
														!values.no_return &&
														!values.more_one_change &&
														!values.pending_checkout
													)
														setFieldValue('all', true)
													else {
														setFieldValue('all', false)
														setFieldValue(
															'pending_checkout',
															false
														)
													}
												}}
											/>
											<label htmlFor="checkout_today">
												<b></b> <span>CHECKOUT HOY</span>
											</label>
										</div>
									</div>
								</div>
								<div
									className="form-group cols-6 form-group-check"
									style={{
										marginBottom: 20,
									}}
								>
									<div className="form-row">
										<div className="col-12">
											<Field
												type="checkbox"
												name="pending_checkout"
												id="pending_checkout"
												checked={values.pending_checkout}
												onChange={(e) => {
													setFieldValue(
														'pending_checkout',
														e.target.checked
													)
													if (
														!e.target.checked &&
														!values.no_return &&
														!values.more_one_change &&
														!values.checkout_today
													)
														setFieldValue('all', true)
													else {
														setFieldValue('all', false)
														if (e.target.checked) {
															setFieldValue('no_return', false)
															setFieldValue(
																'more_one_change',
																false
															)
															setFieldValue(
																'checkout_today',
																false
															)
														}
													}
												}}
											/>
											<label htmlFor="pending_checkout">
												<b></b> <span>CHECKOUT PENDIENTE</span>
											</label>
										</div>
									</div>
								</div>
								<div
									className="form-group cols-12 form-group-check"
									style={{
										marginBottom: 20,
									}}
								>
									<div className="form-row">
										<div className="col-12">
											<Field
												type="checkbox"
												name="all"
												id="all"
												checked={values.all}
												onChange={(e) => {
													if (e.target.checked) {
														setFieldValue('all', e.target.checked)
														setFieldValue(
															'more_one_change',
															false
														)
														setFieldValue('no_return', false)
														setFieldValue('checkout_today', false)
														setFieldValue(
															'pending_checkout',
															false
														)
													}
												}}
											/>
											<label htmlFor="all">
												<b></b> <span>TODOS</span>
											</label>
										</div>
									</div>
								</div>
								<hr />
								<div className="form-group upper-10">
									<div className="form-row">
										<div className="col-3">
											<label>Clientefsdfsd:</label>
										</div>
										<div className="col-9">
											<AsyncSelect
												className="react-select"
												classNamePrefix="react-select"
												placeholder={'Buscar'}
												loadOptions={async (input, callback) => {
													const index =
														searchClientAlg.initIndex(
															'items_management'
														)
													const { hits } = await index.search(
														input,
														{
															hitsPerPage: 20,
														}
													)
													let res = hits.map((h) => ({
														label: `${h.room} > ${h.name}`,
														value: h.id,
													}))
													return callback(res)
												}}
												isClearable
												value={values.client_val}
												onChange={(e) => {
													setFieldValue('client_val', e)
													if (e !== null)
														prefillRoom(
															e.value,
															setFieldValue,
															true
														)
												}}
											/>
										</div>
									</div>
								</div>
								{/* <Input
									label="Nº Habitación"
									disabled={submitting}
									name="room"
									errors={errors}
									touched={touched}
									cols={6}
									colside="left"
									type="number"
									min={0}
									// onClickSearch={() => {}}
									// showSearch
									// custom={{
									// 	onKeyPress: (e) => {
									// 		if (e.key === 'Enter') {
									// 			e.preventDefault()
									// 		}
									// 	},
									// }}
								/>
								<Input
									label="Nombre"
									disabled={submitting || true}
									name="name"
									errors={errors}
									touched={touched}
									cols={6}
									colside="right"
									// onClickSearch={() => {}}
									// showSearch
									// custom={{
									// 	onKeyPress: (e) => {
									// 		if (e.key === 'Enter') {
									// 			e.preventDefault()
									// 		}
									// 	},
									// }}
								/> */}
								{/* <Input
									label="CheckIn"
									noformik
									colside="left"
									cols={6}
									disabled={true}
									custom={{
										value: moment(values.checkin).isValid()
											? moment(values.checkin)
													.tz('Europe/Madrid')
													.format('DD/MM/YYYY')
											: '',
										onChange: (e) => e.preventDefault(),
									}}
								/>
								<Input
									label="CheckOut"
									colside="right"
									cols={6}
									// disabled={true}
									name="checkout"
									custom={{
										value: moment(values.checkout).isValid()
											? moment(values.checkout)
													.tz('Europe/Madrid')
													.format('DD/MM/YYYY')
											: '',
										onChange: (e) => e.preventDefault(),
									}}
									forceInvalid={moment()
										.tz('Europe/Madrid')
										.isSameOrAfter(values.checkout, 'days')}
								/>
								<Input
									label="Personas"
									noformik
									colside="left"
									cols={6}
									// disabled={true}
									custom={{
										value:
											values.client_id !== null
												? `Total ${
														values.children + values.adults
												  } (${values.adults} Adultos, ${
														values.children
												  } Niños)`
												: '',
										onChange: (e) => e.preventDefault(),
									}}
								/> */}
								<footer style={{ marginTop: 10 }}>
									<button
										type="submit"
										disabled={submitting}
										className="button button-primary"
									>
										{submitting && (
											<div
												className="spinner-border spinner-border-sm"
												role="status"
											>
												<span className="sr-only">Cargando...</span>
											</div>
										)}
										{!submitting && (
											<>
												<span>Generar Reporte</span>
											</>
										)}
									</button>
								</footer>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		)
	}

	const getPendingMaterialName = (list, plain = false) => {
		let res = []
		for (const item of Object.keys(list)) {
			let itm = list[item]
			let status = ''
			if (itm === 'charged') status = 'COBRADO'
			if (itm === 'recovered') status = 'RECUPERADO'
			status = 'OTRO'
			if (plain) {
				res.push(
					`${
						mainData.materials.find((f) => f.id === item)?.name || ''
					} ${status}`
				)
			} else {
				res.push(
					<p key={item}>
						{mainData.materials.find((f) => f.id === item)?.name || ''}
						{' - '}
						{status}
					</p>
				)
			}
		}
		if (plain) res = res.join(', ')
		return res
	}

	const getActions = (m, plain = false) => {
		let actions = [...m.deliver, ...m.return, ...m.change]
		actions = actions.sort((a, b) => a.date - b.date)
		let people = m.adults + m.children
		let totalMaterialChanged = {}
		let totalMaterialDelivered = {}

		let extraDeliversQuantity = 0

		actions = actions.map((m) => {
			let dt = moment(m.date).tz('Europe/Madrid').format('DD/MM/YYYY')
			if (m.action === 'change') {
				let _tmc = totalMaterialChanged[dt] || 0
				_tmc += m.quantity
				totalMaterialChanged[dt] = _tmc
				let correct_change = false
				let extraChanges = ''

				let peopleLeft = people - _tmc
				if (peopleLeft < 0) {
					extraChanges = Math.abs(peopleLeft)
					if (extraChanges < m.quantity) {
						extraChanges = ` (${extraChanges})`
					} else {
						extraChanges = ''
					}
				}

				if (totalMaterialChanged[dt] <= people) {
					correct_change = true
				}

				return {
					...m,
					correct_change,
					extraChanges,
				}
			} else if (m.action === 'deliver') {
				let _tmc = totalMaterialDelivered[dt] || 0
				_tmc += m.quantity
				totalMaterialDelivered[dt] = _tmc

				let extraDelivers = ''
				let totalExtraDelivers = false
				let correct_deliver = false

				let peopleLeft = people - _tmc
				if (peopleLeft < 0) {
					extraDelivers = Math.abs(peopleLeft)
					if (extraDelivers < m.quantity) {
						totalExtraDelivers = extraDelivers
						extraDelivers = ` (${extraDelivers})`
					} else {
						extraDelivers = ''
					}
				}

				if (totalMaterialDelivered[dt] <= people) {
					correct_deliver = true
				} else {
					if (totalExtraDelivers === false) {
						extraDeliversQuantity += m.quantity
					} else {
						extraDeliversQuantity += totalExtraDelivers
					}
				}

				return {
					...m,
					correct_deliver,
					extraDelivers,
				}
			}
			return m
		})

		let totalChanges = 0
		let extraMaterialUsed = 0
		for (const act of actions) {
			if (act.action === 'change') {
				totalChanges += 1
			}
		}

		for (const u of Object.keys(totalMaterialChanged)) {
			let q = totalMaterialChanged[u]
			extraMaterialUsed += q
		}

		extraMaterialUsed -= people
		if (extraMaterialUsed < 0) extraMaterialUsed = 0

		extraMaterialUsed += extraDeliversQuantity

		actions = actions.map((k, i) => {
			let date = moment(k.date)
				.tz('Europe/Madrid')
				.format('DD/MM/YYYY HH:mm')
			let content = ` ${actionToText(k.action)} ${getMaterialName(
				mainData.materials,
				k.material
			)} (${k.quantity})`

			let change_type =
				k.correct_change === true ? 'Cambio correcto' : 'Cambio adicional'
			let deliver_type =
				k.correct_deliver === true ? '' : 'Entrega adicional'
			if (plain === false) {
				return (
					<p
						key={k.id}
						style={{
							maxWidth: '100%',
						}}
					>
						{mainData?.places?.find((f) => f.id === k.place)?.name || ''}{' '}
						<strong
							style={{
								marginRight: 10,
							}}
						>
							{date}
						</strong>
						<span>{content}</span>
						{k.recovered === true && (
							<small style={{ color: 'red' }}>Recuperado</small>
						)}
						{k.action === 'deliver' && deliver_type !== '' && (
							<small
								style={{
									color: 'red',
									opacity: 0.5,
								}}
							>
								{deliver_type}
								{k.extraDelivers}
							</small>
						)}
						{k.action === 'change' && (
							<small
								style={{
									color: k.correct_change ? 'green' : 'red',
									opacity: 0.5,
								}}
							>
								{' '}
								{change_type}
								{k.extraChanges}
							</small>
						)}
					</p>
				)
			}
			return `${
				mainData?.places?.find((f) => f.id === k.place)?.name || ''
			} ${date} - ${content} ${k.recovered === true ? 'Recuperado' : ''}${
				k.action === 'deliver' && deliver_type !== ''
					? ` ${deliver_type}${k.extraDelivers}`
					: ''
			}${
				k.action === 'change' ? ` ${change_type}${k.extraChanges}` : ''
			}\n\n`
		})

		let delivers = 0
		let returns = 0
		let owe_items = 0

		delivers = m.delivers_length
		returns = m.returns_length
		owe_items = m.owe_items

		if (plain === false)
			actions = [
				...actions,
				<hr />,
				<p key={'resumen'}>
					Entregas: <b>{delivers}</b>
					<br />
					Devoluciones: <b>{returns}</b>
					<br />
					Cambios: <b>{totalChanges}</b>
					<br />
					Material extra utilizado: <b>{extraMaterialUsed}</b>
					<br />
					Material no entregado: {owe_items}
				</p>,
			]
		else
			actions = [
				...actions,
				`${`Entregas: ${m.delivers_length}\nDevoluciones: ${m.returns_length}\nCambios: ${totalChanges}\nMaterial extra utilizado: ${extraMaterialUsed}\nMaterial no entregado: ${m.owe_items}`}`,
			]
		return actions
	}

	const renderResults = () => {
		return results.map((m) => {
			let total_movements = m.delivers + m.returns + m.changes
			if (total_movements === 0) return null

			let actions = getActions(m)
			return (
				<tr key={m.id}>
					<td>
						<strong>{m?.room}</strong>
						{' > '}
						{m?.name}
					</td>
					<td>{actions}</td>
					<td>
						{getPendingMaterialName(m.pending_material_regularization)}
					</td>
				</tr>
			)
		})
	}

	const getPdf = async (action = 'print') => {
		let headers = ['HABITACIÓN / CLIENTE', 'RESUMEN', 'ESTADO']
		let values = results
		values = values.filter((f) => {
			let total_movements = f.delivers + f.returns + f.changes
			if (total_movements === 0) return false
			return true
		})
		values = values.map((m) => {
			let actions = getActions(m, true)
			return [
				`${m?.room} > ${m?.name}`,
				actions,
				getPendingMaterialName(m.pending_material_regularization, true),
			]
		})

		values.push([
			{
				text: `Fecha Informa ${moment()
					.tz('Europe/Madrid')
					.format('DD/MM/YYYY HH:mm')}`,
				colSpan: 3,
				alignment: 'left',
				width: '100%',
				margin: 20,
				fontSize: 25,
			},
			'',
			'',
		])

		var docDefinition = {
			pageMargins: 20,
			pageSize: {
				width: 1240,
				height: 1748,
			},
			defaultStyle: {
				fontSize: 20,
			},
			content: [
				{
					table: {
						body: [headers, ...values],
						widths: ['30%', '55%', '15%'],
					},
				},
			],
		}

		if (action === 'print') pdfMake.createPdf(docDefinition).print()
		else {
			setSubmitting(true)
			pdfMake.createPdf(docDefinition).getDataUrl(async (data) => {
				let storageRef = storage.ref()
				const listImageRef = storageRef.child(
					`/items_report/${moment().valueOf()}.pdf`
				)
				await listImageRef.putString(data, 'data_url')
				let url = await listImageRef.getDownloadURL()
				let fn = functions.httpsCallable('sendReportEmail')
				await fn(url)
				setSubmitting(false)
			})
		}
	}

	if (mainData === null) return <Spinner />

	return (
		<div className="main">
			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs limit-w">
					<li className="center">
						<button
							onClick={() => history.push('/choose/items-management')}
							disabled={submitting}
							className="button button-light inline button-small"
							style={{
								marginRight: 15,
							}}
						>
							<span>Atrás</span>
						</button>
					</li>

					<li className="title">
						<p>Informe</p>
					</li>
				</ul>

				<button
					disabled={submitting}
					className="button button-primary button-primary-outline inline"
					onClick={() => setShowEditEmails(true)}
				>
					<span>Editar Emails</span>
				</button>
			</div>
			{showEditEmails && renderEditEmails()}
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				{renderGenerateReport()}
			</div>
			<div
				style={{
					paddingLeft: 35,
					paddingRight: 35,
					marginTop: 20,
				}}
			>
				<div
					className="registrations"
					style={{
						width: '100%',
						minHeight: 300,
						paddingTop: 0,
						paddingLeft: 0,
						paddingRight: 0,
					}}
				>
					<nav>
						<ul
							className="tabs limit-w"
							style={{
								width: '100%',
								position: 'relative',
								paddingTop: 60,
							}}
						>
							<div
								className="actions"
								style={{
									position: 'absolute',
									top: 0,
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<button
									onClick={() => getPdf('print')}
									className="button button-primary button-primary-outline inline"
								>
									<i className="mi">print</i> <span>Imprimir</span>
								</button>
								<button
									onClick={() => getPdf('email')}
									className="button button-primary inline"
								>
									<i className="mi">email</i>{' '}
									<span>Enviar por correo</span>
								</button>
							</div>
						</ul>
					</nav>

					<div className="table-ui">
						<div>
							<table>
								<thead>
									<tr>
										<th>Habitación / Cliente</th>
										<th>Resumen</th>
										<th>Estado</th>
									</tr>
								</thead>
								<tbody>{renderResults()}</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default connect(
	(state) => ({ user: state.user, topbar: state.topbar }),
	{ create_alert }
)(Report)

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { clear_alerts, create_alert, update_user } from '../../store/actions'
import PaymentForm from './PaymentForm'
import { Formik, Form, Field } from 'formik'
import { firestore, auth } from '../../config/firebase'
import { Loading, Input, Pricing } from '../../components'

function PaymentSetup({
	history,
	create_alert,
	clear_alerts,
	user,
	language,
	update_user,
}) {
	const [selectedSubscription, setSelectedSubscription] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [selectedDiscount, setSelectedDiscount] = useState(null)
	const plans = [
		{
			name: 'Gratis',
			description: <></>,
			price: '<ol><li>0.00 €</li></ol> ',
			id: 'admin_free',
			color: 'blue',
		},
	]

	useEffect(() => {
		detectSub()
	}, [selectedSubscription])

	const detectSub = async () => {
		if (selectedSubscription === 'admin_free') {
			firestore.collection('users').doc(user.id).update({
				type: 'admin_free',
			})
			window.location.reload()
		}
	}

	const discounts = [{ id: 'initial_discount', code: 'VWP2020' }]

	const renderSelectSubscriptions = () => {
		return (
			<div className={`login-ui pricing-ui`}>
				<div>
					<Formik
						initialValues={{
							type: null,
							coupon: '',
						}}
						validate={({ coupon }) => {
							let x = discounts.find(
								(e) => e.code.toLowerCase() === coupon.toLowerCase()
							)
							if (!x && coupon !== '')
								return { coupon: 'Código no reconocido' }
						}}
						validateOnChange={true}
						onSubmit={(r) => {
							setSelectedSubscription(r.type)
							let code = discounts.find(
								(e) => e.code.toLowerCase() === r.coupon.toLowerCase()
							)
							if (code) setSelectedDiscount(code.id)
						}}
					>
						{({ errors, touched, values, setFieldValue }) => {
							return (
								<Form className="form-ui">
									<span className="logo"></span>
									<h1>Selecciona tu Suscripción</h1>
									<Pricing values={values}>
										<tr>
											<th scope="row"></th>
											<td
												className={`${
													values.type === 'admin_free'
														? 'active'
														: ''
												}`}
											>
												<button
													type="button"
													disabled={values.type === 'admin_free'}
													onClick={() =>
														setFieldValue('type', 'admin_free')
													}
													className="button button-cyan-outline"
												>
													{`${
														values.type === 'admin_free'
															? 'Seleccionado'
															: 'Seleccionar'
													}`}
												</button>
											</td>
											{/* <td
												className={`${
													values.type === 'admin_payasyougo'
														? 'active'
														: ''
												}`}
											>
												<button
													type="button"
													disabled={
														values.type === 'admin_payasyougo'
													}
													onClick={() =>
														setFieldValue(
															'type',
															'admin_payasyougo'
														)
													}
													className="button button-cyan"
												>
													{`${
														values.type === 'admin_payasyougo'
															? 'Seleccionado'
															: 'Seleccionar'
													}`}
												</button>
											</td> */}
											<td
												className={`${
													values.type === 'admin_premium'
														? 'active'
														: ''
												}`}
											>
												<button
													type="button"
													disabled={
														values.type === 'admin_premium'
													}
													onClick={() =>
														setFieldValue('type', 'admin_premium')
													}
													className="button button-orange"
												>
													{`${
														values.type === 'admin_free'
															? 'Seleccionado'
															: 'Seleccionar'
													}`}
												</button>
											</td>
										</tr>
									</Pricing>
									<div
										style={{
											paddingLeft: 15,
											paddingRight: 15,
											paddingBottom: 15,
											marginBottom: 25,
										}}
									>
										<Input
											name="coupon"
											errors={errors}
											touched={{ coupon: true }}
											label="Código de descuento"
											type="text"
										/>
									</div>
									{values.type !== null && (
										<footer>
											<button
												disabled={submitting}
												type="submit"
												className="button button-primary"
											>
												{submitting && (
													<div role="status">
														<span>{language.loading}...</span>
													</div>
												)}
												{!submitting && language.continue}
											</button>
										</footer>
									)}
								</Form>
							)
						}}
					</Formik>
				</div>
				<button
					className="button button-link"
					style={{
						marginBottom: 40,
						marginTop: 20,
					}}
					onClick={(e) => {
						auth.signOut()
						localStorage.clear()
						window.location.reload()
					}}
				>
					Cerrar sesión
				</button>
			</div>
		)
	}

	if (selectedSubscription === 'admin_free') return <Loading />

	if (selectedSubscription === null) return renderSelectSubscriptions()

	return (
		<StripeProvider apiKey="pk_live_A0gAYAY0ucS2kSjZO0FniJf000A9UAn76Q">
			<Elements>
				<PaymentForm
					goBack={(e) => setSelectedSubscription(null)}
					user={user}
					create_alert={create_alert}
					type={selectedSubscription}
					discount_code={selectedDiscount}
				/>
			</Elements>
		</StripeProvider>
	)
}

const mapStateToProps = (state) => ({
	user: state.user,
	language: state.language.dictionary,
})

export default connect(mapStateToProps, {
	create_alert,
	clear_alerts,
	update_user,
})(PaymentSetup)

import { Form, Formik } from 'formik'
import qs from 'query-string'
import React, { useEffect, useState, useRef } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { connect } from 'react-redux'
import { Input, ModalForm, TablesMap } from '../../../../../components'
import firebase, { firestore, auth } from '../../../../../config/firebase'
import { create_alert } from '../../../../../store/actions'
import { isMobile } from 'react-device-detect'
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'
import { useCookies } from 'react-cookie'

function Cart({
	cart,
	toggle,
	set_cart,
	place,
	employee,
	inventory,
	table,
	create_alert,
	refreshTable = () => {},
	total = 0,
	checkoutTable = false,
	language,
	setRedirect,
	user,
}) {
	const [submitting, setSubmitting] = useState(false)
	const [paying, setPaying] = useState(false)
	const [addingToTable, setAddingToTable] = useState(false)
	const [selectedTable, setSelectedTable] = useState(null)
	const [checkout, setCheckout] = useState(false)
	const invoiceRef = useRef(null)
	const [printInvoice, setPrintInvoice] = useState(false)
	const [customNote, setCustomNote] = useState(null)
	const [discount, setDiscount] = useState(0)
	const [_tab, set_tab] = useState(null)
	const [applyDiscount, setApplyDiscount] = useState(false)
	const [askAdminPassword, setAskAdminPassword] = useState(false)
	const [cookies, setCookie, removeCookie] = useCookies(['print'])

	useEffect(() => {
		setPaying(checkoutTable !== false)
		let query = qs.parse(window.location.search)
		if (query.charge === 'true') setCheckout(true)
		if (query.table) set_tab(query.table)
	}, [])

	useEffect(() => {
		if (table !== null) {
			setSelectedTable(table)
		}
	}, [table])

	useEffect(() => {
		if (cart.items.length === 0) toggle(false)
	}, [cart])

	const printOnDesktop = async (order) => {
		let id = Math.floor(Math.random() * 100000000000)
		let obj = {
			order,
			place,
			id,
		}
		await firestore.runTransaction(async (trans) => {
			let latestData = await trans.get(
				firestore.doc(
					`/users/${auth.currentUser.uid}/printers/${cookies.print}`
				)
			)
			latestData = latestData.data()
			let q = latestData.queue || []
			trans.update(
				firestore.doc(
					`/users/${auth.currentUser.uid}/printers/${cookies.print}`
				),
				{
					queue: [...q, obj],
				}
			)
		})
	}

	const renderInvoice = () => {
		let total = 0
		let total_taxed = 0
		printInvoice.items.forEach((it) => {
			let qty = it.quantity || it.units
			let it_charge = it.price_type === 'units' ? it.price * qty : it.price
			total += it_charge
			total_taxed +=
				it.taxrate && it.taxrate > 0 ? it_charge * (it.taxrate / 100) : 0
			it.additions.forEach((add) => {
				add.items.forEach((_it) => {
					if (_it.quantity > 0) {
						total += _it.value * _it.quantity
						total_taxed +=
							it.taxrate && it.taxrate > 0
								? _it.value * _it.quantity * (it.taxrate / 100)
								: 0
					}
				})
			})
		})

		let billing_info = Object.keys(place.billing).map((info_key) => {
			return (
				<span>
					{language[info_key]}: {place.billing[info_key]}
				</span>
			)
		})

		let invoiceDate = new Date(printInvoice.date)
		let invoiceTime = `${`0${invoiceDate.getHours()}`.slice(
			-2
		)}:${`0${invoiceDate.getMinutes()}`.slice(-2)}`

		return (
			<div ref={invoiceRef} id="PRINT_INVOICE">
				<h3>{place.name}</h3>
				<strong>FACTURA #{printInvoice.invoiceNumb}</strong>
				<p className="billing">
					<span>
						Fecha: {invoiceDate.toLocaleDateString()} {invoiceTime}
					</span>
					{billing_info}
				</p>
				<table>
					<thead>
						<tr>
							<th>Producto</th>
							<th>Cantidad</th>
							<th>Imp.</th>
							<th>Valor</th>
						</tr>
					</thead>
					<tbody>
						{printInvoice.items.map((it) => {
							let additions = []
							let qty = it.units || it.quantity
							let it_total =
								it.price_type === 'units' ? it.price * qty : it.price
							let it_taxed =
								it.taxrate && it.taxrate > 0
									? it_total * (it.taxrate / 100)
									: 0
							it.additions.forEach((add) => {
								let hasAny = false
								add.items.forEach((_it) => {
									if (_it.quantity > 0) hasAny = true
								})
								if (hasAny)
									additions.push({
										...add,
										items: add.items.filter((a) => a.quantity > 0),
									})
							})

							let adds_rows = additions.map((add) => (
								<>
									<tr>
										<td colSpan="4">— {add.name}</td>
									</tr>
									{add.items.map((it_) => {
										let it__charge =
											typeof it_.quantity !== 'undefined'
												? it_.quantity * it_.value
												: it_.value
										let it__taxed =
											it.taxrate && it.taxrate > 0
												? it__charge * (it.taxrate / 100)
												: 0
										return (
											<tr>
												<td>—— {it_.name}</td>
												<td>{it_.quantity}</td>
												<td>{it__taxed.toFixed(2)} €</td>
												<td>{it__charge} €</td>
											</tr>
										)
									})}
								</>
							))

							return (
								<>
									<tr>
										<td>
											<strong>{it.name}</strong>
										</td>
										<td>
											{it.price_type === 'units'
												? `${qty} ${getUnitName(it.unit, qty)}`
												: 1}
										</td>
										<td>{it_taxed.toFixed(2)} €</td>
										<td>{it_total} €</td>
									</tr>
									{adds_rows}
								</>
							)
						})}
						<tr>
							<td colSpan="3">
								<strong>Total:</strong>
							</td>

							<td>
								<strong>{total} €</strong>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}

	const onValidatePaymentDetails = (values) => {
		let val = cart.value - cart.value * (discount / 100)
		if (values.received_amount < val && values.payment_method === 'cash') {
			return { received_amount: language.its_not_enough }
		}
	}

	const formatDate = (date) => {
		let _date = new Date(date)
		let day = _date.getDate()
		let month = _date.getMonth() + 1
		let year = _date.getFullYear()
		return `${`0${day}`.slice(-2)}/${`0${month}`.slice(-2)}/${year}`
	}

	const removeFromInventory = async () => {
		let nu = inventory.items.items
		cart.items.forEach((item) => {
			if (typeof item.inventory !== 'undefined') {
				item.inventory.forEach((it) => {
					let its = [...inventory.items.items].filter(
						(_it) => it.id.toLowerCase() === _it.name.toLowerCase()
					)
					let selected = its.sort((a, b) => {
						let date_a = new Date(a.due),
							date_b = new Date(b.due)
						date_a.setHours(0)
						date_a.setMinutes(0)
						date_b.setHours(0)
						date_b.setMinutes(0)
						if (a.perishable && !b.perishable) return -1
						if (!a.perishable && b.perishable) return 1
						if (date_a.getTime() < date_b.getTime()) return -1
						if (date_a.getTime() > date_b.getTime()) return 1
					})[0]

					nu = nu.map((_it) => {
						if (
							typeof selected !== 'undefined' &&
							_it.id === selected.id
						) {
							let qua =
								_it.quantity - it.quantity < 0
									? 0
									: _it.quantity - it.quantity
							return { ..._it, quantity: qua }
						} else return _it
					})
				})
			}
		})
		await firestore
			.collection('places')
			.doc(place.id)
			.collection('inventory')
			.doc('items')
			.update({
				items: nu,
			})
	}

	const doInvoice = (order) => {
		if (!isMobile)
			htmlToImage
				.toPng(document.getElementById('PRINT_INVOICE'))
				.then(function (dataUrl) {
					download(dataUrl, `${place.id}-factura-${order.invoiceNumb}.png`)
					setPrintInvoice(false)
					create_alert(language.table_paid, 'success')
					set_cart({ items: [], value: 0 })
					setSubmitting(false)
					refreshTable()
				})
	}

	const increment = firebase.firestore.FieldValue.increment(1)

	const onSubmitPaymentDetails = async (
		table = null,
		payment_method = 'cash'
	) => {
		let statsRef = firestore
			.collection('places')
			.doc(place.id)
			.collection('stats')
			.doc('sales')
		let placeRef = firestore.collection('places').doc(place.id)
		setSubmitting(true)
		firestore
			.runTransaction(async (transaction) => {
				let statsData = await transaction.get(statsRef)
				let placeData = await transaction.get(placeRef)
				placeData = placeData.data()
				statsData = statsData.data()
				let newOrder = true
				let _orders = null
				let invoiceID = 0
				let order = null
				let paid = false

				if (typeof placeData.invoiceNumb !== 'undefined')
					invoiceID = placeData.invoiceNumb + 1
				invoiceID = `_${invoiceID}`

				if (table === null) paid = true

				// await removeFromInventory()

				if (table !== null) {
					placeData.orders.forEach((ord) => {
						if (ord.table === selectedTable.id) {
							newOrder = ord
							invoiceID = ord.invoiceID
						}
					})
				} else if (checkoutTable !== false) {
					newOrder = checkoutTable
					invoiceID = checkoutTable.invoiceID
					_orders = placeData.orders.map((ord) => {
						if (ord.invoiceID !== invoiceID) return ord
						order = {
							...ord,
							items: [
								...ord.items.map((_item) => ({ ..._item, paid: true })),
							],
							notes:
								customNote === null
									? ord.notes
									: [...ord.notes, customNote],
						}
						return order
					})
				}

				if (newOrder === true) {
					await transaction.update(placeRef, {
						invoiceNumb: increment,
					})
					order = {
						invoiceID: invoiceID,
						invoiceNumb: placeData.invoiceNumb + 1,
						confirmed: true,
						status: 1,
						address: placeData.address.value,
						date: Date.now(),
						items: cart.items.map((_item) => {
							if (typeof _item.state === 'undefined')
								return { ..._item, state: 0, paid }
							return { ..._item, paid }
						}),
						notes: [customNote],
						table: table !== null ? selectedTable.id : null,
						discount,
					}
					_orders = [...placeData.orders, order]
				} else if (checkoutTable === false) {
					_orders = placeData.orders.map((ord) => {
						if (ord.invoiceID !== invoiceID) return ord
						order = {
							...ord,
							items: [
								...ord.items,
								...cart.items.map((_item) => {
									if (typeof _item.state === 'undefined')
										return { ..._item, state: 0, paid }
									return { ..._item, paid }
								}),
							],
							notes:
								customNote === null
									? ord.notes
									: [...ord.notes, customNote],
						}
						return order
					})
				}

				await transaction.update(placeRef, {
					orders: _orders,
				})

				let todayData = {
					money: 0,
					quantity: 0,
				}

				if (typeof statsData !== 'undefined') {
					let prevToday = statsData.sales[formatDate(new Date())]
					if (typeof prevToday !== 'undefined') todayData = prevToday
				}

				todayData.money = todayData.money + cart.value
				todayData.quantity = todayData.quantity + 1

				if (typeof statsData === 'undefined')
					await transaction.set(statsRef, {
						sales: {},
						products: {},
						events: {},
					})

				let _products_ = {}
				let _products = {}
				let _sales = {}
				if (typeof statsData !== 'undefined') {
					_sales = statsData.sales || {}
					_products = statsData.products || {}
				}

				Object.keys(_products).forEach((key) => {
					_products_[key] = _products[key]
				})

				cart.items.forEach((it) => {
					_products_[it.id] = {
						ordered:
							typeof _products_[it.id] !== 'undefined'
								? _products_[it.id].ordered + 1
								: 1,
					}
				})

				let payment_methods = { cash: 0, card: 0 }

				if (typeof statsData !== 'undefined') {
					payment_methods =
						typeof statsData.payment_methods === 'undefined'
							? { cash: 0, card: 0 }
							: statsData.payment_methods

					payment_methods[payment_method] =
						payment_methods[payment_method] + 1

					await transaction.update(statsRef, {
						sales: {
							..._sales,
							[formatDate(new Date())]: todayData,
						},
						products: {
							..._products_,
						},
						payment_methods,
						events: statsData.events ? statsData.events : {},
						reservations: statsData.reservations
							? statsData.reservations
							: {},
					})
				} else {
					payment_methods = { cash: 0, card: 0 }
					payment_methods[payment_method] =
						payment_methods[payment_method] + 1

					await transaction.update(statsRef, {
						sales: {
							..._sales,
							[formatDate(new Date())]: todayData,
						},
						products: {
							..._products_,
						},
						payment_methods,
						events: {},
						reservations: {},
					})
				}
				return order
			})
			.then((order) => {
				if (table === null) {
					if (checkout) {
						if (!isMobile) {
							let ord = {}
							place.orders.forEach((_ord) => {
								if (_ord.table === _tab) {
									ord = _ord
								}
							})

							if (cookies.print) {
								printOnDesktop(ord)
								create_alert(
									`Imprimiendo en ${cookies.print}`,
									'success'
								)
								setRedirect('')
								set_cart({ items: [], value: 0 })
								setSubmitting(false)
							} else {
								setPrintInvoice(ord)
								setTimeout(() => {
									doInvoice(ord)
								}, 200)
							}
						}
					} else {
						if (cookies.print) {
							printOnDesktop(order)
							create_alert(`Imprimiendo en ${cookies.print}`, 'success')
						}
						create_alert(language.paid_and_added_to_orders, 'success')
						setRedirect('')
						set_cart({ items: [], value: 0 })
						setSubmitting(false)
					}
				} else {
					if (cookies.print) {
						printOnDesktop(order)
						create_alert(`Imprimiendo en ${cookies.print}`, 'success')
					}
					create_alert(
						`${language.added_to_table} ${selectedTable.name}`,
						'success'
					)
					set_cart({ items: [], value: 0 })
					refreshTable()

					setSubmitting(false)
				}
			})
			.catch((e) => {
				create_alert(`Hubo un error`, 'danger')
				setSubmitting(false)
			})
	}

	const getUnitName = (u = 'u', quantity) => {
		switch (u) {
			case 'u':
				if (quantity > 1) return 'Unidades'
				return 'Unidad'
			case 'kg':
				if (quantity > 1) return 'Kilos'
				return 'Kilo'
			case 'lt':
				if (quantity > 1) return 'Litros'
				return 'Litro'
			case 'pt':
				if (quantity > 1) return 'Raciones'
				return 'Ración'
			case 'lb':
				if (quantity > 1) return 'Libras'
				return 'Libra'
		}
	}

	const showPayment = () => {
		let calc_discount = cart.value - cart.value * (1 - discount / 100)

		return (
			<OutsideClickHandler
				onOutsideClick={(e) => {
					setTimeout(() => {
						toggle(false)
					}, 50)
				}}
			>
				<div>
					<Formik
						initialValues={{
							payment_method: 'cash',
							received_amount: 0,
						}}
						onSubmit={(values) =>
							onSubmitPaymentDetails(null, values.payment_method)
						}
						validate={onValidatePaymentDetails}
					>
						{({ errors, touched, values }) => {
							return (
								<>
									<Form className="form-ui">
										<Input
											label={language.payment_method}
											errors={errors}
											touched={touched}
											name="payment_method"
											type="select"
											rowcolleft="5"
											rowcolright="7"
											submitting={submitting}
											custom={{
												component: 'select',
											}}
											disabled={submitting}
										>
											<option value="cash">{language.cash}</option>
											<option value="card">{language.card}</option>
										</Input>
										{values.payment_method === 'cash' && (
											<>
												<Input
													disabled={submitting}
													label={language.received_amount}
													errors={errors}
													touched={touched}
													name="received_amount"
													type="number"
													rowcolleft="5"
													rowcolright="7"
													submitting={submitting}
													append={
														<i className="mi">euro_symbol</i>
													}
													custom={{
														step: '0.01',
														min: 0,
														max: 100000000,
													}}
												/>
												{values.received_amount - total >= 0 && (
													<div className="form-group info-group">
														<div className="form-row has-append">
															<span className="append">
																<i>
																	<i className="mi">
																		euro_symbol
																	</i>
																</i>
															</span>
															<div className="col-5">
																<label>{language.change}</label>
															</div>
															<div className="col-7">
																<input
																	type="number"
																	disabled
																	value={(
																		values.received_amount -
																		total
																	).toFixed(2)}
																/>
															</div>
														</div>
													</div>
												)}
											</>
										)}
										<div className="summary">
											<table>
												<tbody>
													<tr>
														<th>Valor:</th>
														<td>
															<span className="price">
																<pre>
																	{cart.value.toFixed(2)}
																</pre>
																<i className="mi">
																	euro_symbol
																</i>
															</span>
														</td>
													</tr>
													{calc_discount > 0 && (
														<tr>
															<th>Descuento:</th>
															<td>
																<span className="price price-red">
																	<pre>
																		{'-'}
																		{calc_discount.toFixed(2)}
																	</pre>
																	<i className="mi">
																		euro_symbol
																	</i>
																</span>
															</td>
														</tr>
													)}

													<tr>
														<th>Total:</th>
														<td>
															<span className="price price-lg">
																<pre>
																	{(
																		cart.value -
																		cart.value *
																			(discount / 100)
																	).toFixed(2)}
																</pre>
																<i className="mi">
																	euro_symbol
																</i>
															</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<footer className="cols-2">
											<button
												type="button"
												className="button button-light"
												onClick={(e) => setPaying(false)}
												disabled={submitting}
											>
												<i className="mi">clear</i>
												<span>{language.cancel}</span>
											</button>
											<button
												type="submit"
												className="button button-primary"
												disabled={submitting}
											>
												<span>{language.continue}</span>
												<i className="mi">arrow_forward</i>
											</button>
										</footer>
									</Form>
								</>
							)
						}}
					</Formik>
				</div>
				<b
					onClick={(e) => {
						setTimeout(() => {
							toggle(false)
						}, 50)
					}}
				></b>
			</OutsideClickHandler>
		)
	}

	const renderAddToTable = () => {
		return (
			<ModalForm size="lg" toggle={() => setAddingToTable(false)}>
				<div>
					<form className="form-ui" onSubmit={(e) => e.preventDefault()}>
						<h1>{language.add_to_table}</h1>
						<div
							style={{
								width: '100%',
								height: 500,
								position: 'relative',
							}}
						>
							<TablesMap
								small_version={true}
								onTableSelected={(table) => setSelectedTable(table)}
							/>
						</div>
						<footer className="cols-2">
							<button
								onClick={() => {
									setAddingToTable(false)
								}}
								className="button button-light"
								type="button"
							>
								{language.cancel}
							</button>
							<button
								type="submit"
								disabled={submitting || selectedTable === null}
								className="button button-primary"
								onClick={(e) => {
									onSubmitPaymentDetails(true)
								}}
							>
								{submitting && (
									<div role="status">
										<span>{language.loading}...</span>
									</div>
								)}
								{!submitting && language.add}
							</button>
						</footer>
					</form>
				</div>
			</ModalForm>
		)
	}

	const onConfirmPassword = (values, { setFieldError }) => {
		if (values.password !== user.main_password) {
			setFieldError('password', 'Contraseña incorrecta')
		} else {
			setApplyDiscount(true)
			setAskAdminPassword(false)
		}
	}

	const renderAskAdminPassword = () => {
		return (
			<ModalForm toggle={() => setAskAdminPassword(false)} size="sm">
				<Formik
					initialValues={{
						password: '',
					}}
					onSubmit={onConfirmPassword}
				>
					{({ errors, touched }) => (
						<Form className="form-ui">
							<h1>Contraseña de Admin principal</h1>
							<Input
								label="Contraseña"
								disabled={submitting}
								type="password"
								name="password"
								errors={errors}
								touched={touched}
							/>
							<footer>
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">
												{language.loading}...
											</span>
										</div>
									)}
									{!submitting && (
										<>
											<span>{language.continue}</span>
										</>
									)}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	return (
		<>
			{addingToTable && renderAddToTable()}
			{askAdminPassword && renderAskAdminPassword()}
			<div className="cart-overlay"></div>
			<div className="cart-dropdown">
				{paying && showPayment()}
				{!paying && (
					<OutsideClickHandler
						onOutsideClick={(e) => {
							if (addingToTable || submitting || askAdminPassword) return
							setTimeout(() => {
								toggle(false)
							}, 50)
						}}
					>
						<div>
							<ol className="shop-list">
								{cart.items.map((it) => {
									let pimage =
										it.image !== 'no_image'
											? { backgroundImage: `url(${it.image})` }
											: {}
									return (
										<li key={it.cartId}>
											<blockquote>
												<div>
													<span
														className="img"
														style={pimage}
													></span>
													<strong>
														{it.name}{' '}
														{it.price_type === 'units' &&
															`  - ${it.quantity} ${getUnitName(
																it.unit,
																it.quantity
															)}`}
													</strong>
												</div>
												<span className="price">
													<pre>{it.price}</pre>{' '}
													<i className="mi">euro_symbol</i>
												</span>
											</blockquote>
											{it.additions.map((add, x) => {
												let total = 0
												let disabled = false
												add.items.forEach((it) => {
													total += it.quantity
												})

												if (total >= add.max) disabled = true
												let items = add.items.map((_it, y) => (
													<label key={_it.name}>
														<div className="input-check">
															<input
																type="checkbox"
																disabled={
																	disabled && !_it.quantity
																}
																checked={!!_it.quantity}
																onChange={(e) => {
																	let newProd = {
																		...it,
																		additions: it.additions.map(
																			(_add, a) => {
																				if (a !== x)
																					return _add
																				return {
																					..._add,
																					items: _add.items.map(
																						(_it, b) => {
																							if (
																								b !== y
																							)
																								return _it
																							return {
																								..._it,
																								quantity: !!e
																									.target
																									.checked
																									? 1
																									: 0,
																							}
																						}
																					),
																				}
																			}
																		),
																	}
																	let newItems = cart.items.map(
																		(_it) => {
																			if (
																				_it.cartId !==
																				it.cartId
																			)
																				return _it
																			return newProd
																		}
																	)
																	let value = 0
																	newItems.forEach((prod) => {
																		let qty =
																			prod.quantity ||
																			prod.units
																		let it_charge =
																			prod.price_type ===
																			'units'
																				? prod.price * qty
																				: prod.price
																		value += it_charge
																		prod.additions.forEach(
																			(add) => {
																				add.items.forEach(
																					(it) => {
																						if (
																							it.quantity >
																							0
																						) {
																							value +=
																								it.value
																						}
																					}
																				)
																			}
																		)
																	})
																	set_cart({
																		value,
																		items: newItems,
																	})
																}}
															/>
															<span className="label"></span>
														</div>
														<p className="add">
															<b>{_it.name}</b>
															<span className="price price-sm price-gray">
																<b>{_it.value}</b>{' '}
																<i className="mi">
																	euro_symbol
																</i>
															</span>
														</p>
													</label>
												))
												return (
													<div key={add.name} className="adds">
														<em>
															{add.name}: (Max {add.max})
														</em>
														{items.length > 0 && (
															<div>{items}</div>
														)}
													</div>
												)
											})}
											<button
												onClick={(e) => {
													e.preventDefault()
													e.stopPropagation()
													let newItems = cart.items.filter(
														(_it) => _it.cartId !== it.cartId
													)
													let value = 0
													newItems.forEach((prod) => {
														let qty = prod.quantity || prod.units
														let it_charge =
															prod.price_type === 'units'
																? prod.price * qty
																: prod.price
														value += it_charge
														prod.additions.forEach((add) => {
															add.items.forEach((it) => {
																if (it.quantity > 0) {
																	value += it.value
																}
															})
														})
													})
													set_cart({
														value,
														items: newItems,
													})
												}}
												title={`Remove ${it.name}`}
												className="button button-link button-link-sm button-link-delete"
											>
												<i className="mi">remove_circle_outline</i>
											</button>
										</li>
									)
								})}
							</ol>
							<textarea
								placeholder={language.custom_notes}
								onChange={(e) => {
									setCustomNote(e.target.value)
								}}
								maxLength="100"
							>
								{customNote}
							</textarea>
							{!applyDiscount && (
								<button
									className="button button-link"
									onClick={() => setAskAdminPassword(true)}
								>
									<i className="mi">local_offer</i>
									<span>Aplicar un descuento</span>
								</button>
							)}
							{applyDiscount && (
								<Input
									label="Descuento"
									disabled={submitting}
									type="number"
									name="discount"
									noformik
									custom={{
										onChange: (v) => {
											let val = parseInt(v.target.value)
											if (val < 0) setDiscount(0)
											else if (val > 100) setDiscount(100)
											else setDiscount(val)
										},
										step: '1',
										value: discount,
									}}
									rowcolleft="5"
									rowcolright="7"
									append={'%'}
								/>
							)}
						</div>
						<footer>
							<div>
								{table === null && (
									<>
										{place.features.indexOf('tables') !== -1 &&
											user.type === 'admin_premium' && (
												<button
													onClick={(e) => setAddingToTable(true)}
													className="button button-outline button-icon-on-r button-light"
												>
													<i className="mi">add</i>
													<span>{language.add_to_table}</span>
												</button>
											)}
										<button
											className={`button button-primary ${
												place.features.indexOf('tables') === -1
													? 'w-100'
													: ''
											}`}
											onClick={() => setPaying(true)}
										>
											<span>{language.checkout}</span>
											<i className="mi">arrow_forward</i>
										</button>
									</>
								)}
								{table !== null &&
									place.features.indexOf('tables') !== -1 && (
										<button
											style={{
												width: '100%',
											}}
											className="button button-icon-on-r button-block"
											onClick={() => {
												onSubmitPaymentDetails(true)
											}}
											disabled={submitting}
										>
											<span>
												{language.add_to_table} {table.name}
											</span>
											<i className="mi">arrow_forward</i>
										</button>
									)}
							</div>
						</footer>
						<b
							onClick={(e) => {
								setTimeout(() => {
									toggle(false)
								}, 50)
							}}
						></b>
					</OutsideClickHandler>
				)}
			</div>
			{printInvoice !== false && renderInvoice()}
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		employee: state.employee,
		inventory: state.inventory,
		language: state.language.dictionary,
		user: state.user,
	}),
	{ create_alert }
)(Cart)

import { useEffect, useState } from 'react'
// import ReactCrop from 'react-image-crop'
// import 'react-image-crop/lib/ReactCrop.scss'
import { firestore } from '../../../../../config/firebase'

function FeatImage({ place, setFeatImage, language }) {
	const [crop, setCrop] = useState({
		unit: '%',
		width: 0,
		height: 0,
		x: 0,
		y: 0,
		aspect: 1.77777777778
	})
	const [currentImg, setCurrentImg] = useState(null)
	const [displayImage, setDisplayImage] = useState(null)
	const [imageRef, setImageRef] = useState(null)

	useEffect(() => {
		if (place.featured_image !== null) fetchImage()
	}, [])

	useEffect(() => {
		calcCrop()
	}, [displayImage, imageRef])

	const calcCrop = () => {
		if (imageRef !== null) {
			let w = 160
			let h = 90
			setCrop({
				unit: 'px',
				width: 160,
				height: 90,
				x: 0,
				y: 0,
				aspect: 1.77777777778
			})
			makeClientCrop({
				unit: 'px',
				width: w,
				height: h,
				x: 160,
				y: 90
			})
		}
	}

	const fetchImage = async () => {
		// let blob = await fetch(place.featured_image).then(r => r.blob())
		// let dataUrl = await new Promise(resolve => {
		// 	let reader = new FileReader()
		// 	reader.onload = () => resolve(reader.result)
		// 	reader.readAsDataURL(blob)
		// })
		setCurrentImg(place.featured_image)
	}

	const onSelectFile = e => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				setDisplayImage(reader.result)
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}

	const makeClientCrop = async crop => {
		if (imageRef && crop.width && crop.height) {
			const croppedImageUrl = await getCroppedImg(
				imageRef,
				crop,
				'newFile.jpeg'
			)
			setFeatImage(croppedImageUrl)
		}
	}

	const getCroppedImg = (image, crop) => {
		const canvas = document.createElement('canvas')
		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		canvas.width = crop.width
		canvas.height = crop.height
		const ctx = canvas.getContext('2d')

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		)

		return new Promise(resolve => {
			let str = canvas.toDataURL()
			resolve(str)
		})
	}

	const onImageLoaded = image => setImageRef(image)

	let displayingSomeImg =
		(displayImage === null && currentImg !== null) || displayImage !== null

	return (
		<>
			{displayingSomeImg && (
				<fieldset className="first-child last-child fieldset-image fieldset-image-single">
					<div className="fieldset-header">
						<h2>{language.cover_image}</h2>
						<button
							className="button button-link button-link-sm button-link-delete"
							type="button"
							onClick={e => {
								firestore
									.collection('places')
									.doc(place.id)
									.update({
										featured_image: null
									})
								setCurrentImg(null)
								setDisplayImage(null)
								setImageRef(null)
								setCrop({
									unit: '%',
									width: 0,
									height: 0,
									x: 0,
									y: 0,
									aspect: 1.77777777778
								})
							}}
						>
							<i className="mi">delete</i>
							<span>{language.remove}</span>
						</button>
					</div>
					{displayImage === null && currentImg !== null && (
						<img src={currentImg}></img>
					)}
					{/* {displayImage !== null && (
						<ReactCrop
							onImageLoaded={onImageLoaded}
							src={displayImage}
							crop={crop}
							onChange={crop => setCrop(crop)}
							onComplete={makeClientCrop}
						/>
					)} */}
				</fieldset>
			)}
			{!displayingSomeImg && (
				<div className="adder adder-single">
					<label htmlFor="featImageInput" className="button button-light">
						{language.select} {language.cover_image}
					</label>
					<input type="file" onChange={onSelectFile} id="featImageInput" />
				</div>
			)}
		</>
	)
}

export default FeatImage

const initialState = {
	lang: 'es',
	dictionary: null
}

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'CHANGE_LANGUAGE':
			return { ...state, lang: payload }
		case 'LOAD_DICTIONARY':
			return { ...state, dictionary: { ...payload } }
		default:
			return state
	}
}

import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import {
	Allergens,
	Loading,
	ModalForm,
	More,
	Input,
} from '../../../../../components'
import firebase, { firestore, auth } from '../../../../../config/firebase'
import { update_products, create_alert } from '../../../../../store/actions'
import Create from '../Create'
import Cart from './Cart'
import { Formik, Form, Field } from 'formik'
import Helmet from 'react-helmet'
import csv from 'csvtojson'
import Translate from './Translate'

function View({
	place,
	products,
	categories,
	history,
	update_products,
	tables,
	language,
	employee,
	user,
	create_alert,
	topbar,
	checkout = false,
}) {
	const [submitting, setSubmitting] = useState(false)
	const [creatingProduct, setCreatingProduct] = useState(false)
	const [creatingService, setCreatingService] = useState(false)
	const [edittingProduct, setEdittingProduct] = useState(false)
	const [detailsModal, setDetailsModal] = useState(false)
	const [selectedCategory, setSelectedCategory] = useState(null)
	const [selectedSubCategory, setSelectedSubCategory] = useState(null)
	const [showCart, setShowCart] = useState(false)
	const [addBtnAnimation, setAddBtnAnimation] = useState(null)
	const [oldProds, setOldProds] = useState(JSON.stringify(place.products))
	const [importProducts, setImportProducts] = useState(false)
	const [editCategories, setEditCategories] = useState(false)
	const [showCustom, setShowCustom] = useState(false)
	const [cart, setCart] = useState({
		items: [],
		value: 0,
		checkoutTable: false,
	})
	const [importType, setImportType] = useState(null)
	const [addingTo, setAddingTo] = useState(null)
	const [redirect, setRedirect] = useState(null)
	const [userPlaces, setUserPlaces] = useState(null)
	const [userProducts, setUserProducts] = useState(null)
	const [placeholderProducts, setPlaceholderProducts] = useState(null)
	const [active, setActive] = useState(0)
	const [sellQuantity, setSellQuantity] = useState(1)
	const [editService, setEditService] = useState(false)
	const [translatingProd, setTranslatingProd] = useState(false)

	useEffect(() => {
		const arrayOfLinks = ['', 'recommendations']
		let pathname = window.location.pathname
		pathname = pathname.split('/')[2]
		let index = 0

		arrayOfLinks.forEach((id, i) => {
			if (id === pathname) {
				index = i
			}
		})

		setActive(index)
	})

	useEffect(() => {
		if (tables !== null) {
			let query = qs.parse(window.location.search)
			let allTables = []
			Object.keys(tables).forEach((key) => {
				tables[key].forEach((floor) => {
					floor.tables.forEach((table) => {
						allTables.push(table)
					})
				})
			})
			if (query.charge === 'true') {
				let order = place.orders.find((ord) => ord.table === query.table)
				let price = 0
				let order_its = order.items.filter((it) => !it.paid)
				order_its.forEach((it) => {
					let qty = it.quantity || it.units
					let it_charge =
						it.price_type === 'units' ? it.price * qty : it.price
					price += it_charge
					it.additions.forEach((add) => {
						add.items.forEach((it) => {
							let _it = { quantity: 0, ...it }
							price += _it.value * _it.quantity
						})
					})
				})

				setCart({
					items: order_its,
					value: price,
					checkoutTable: order,
				})
				setShowCart(true)
			} else if (query.table) {
				setCart({
					items: [],
					value: 0,
					checkoutTable: false,
				})
				let table = allTables.find((table) => table.id === query.table)
				setAddingTo(table)
			}
			if (localStorage.getItem('cart') !== null && !query.table) {
				setCart(JSON.parse(localStorage.getItem('cart')))
			}
		}
	}, [tables])

	useEffect(() => {
		localStorage.setItem('cart', JSON.stringify(cart))
	}, [cart])

	useEffect(() => {
		if (JSON.stringify(place.products) !== oldProds) {
			updateProducts()
			setOldProds(JSON.stringify(place.products))
		}
	}, [place.products])

	useEffect(() => {}, [userProducts])

	const upper = (lower) => lower.charAt(0).toUpperCase() + lower.substring(1)

	const updateProducts = () => {
		let promises = place.products.map((prod_id) => {
			return firestore
				.collection('places')
				.doc(place.id)
				.collection('products')
				.doc(prod_id)
				.get()
		})
		Promise.all(promises).then((prods) => {
			let products = prods.map((prod) => prod.data())
			update_products(products)
		})
	}

	const renderEmpty = () => {
		return (
			<aside className="empty">
				<i className="mi">restaurant_menu</i>
				<h3>{language.no_products_found}</h3>
				{!checkout && (
					<button
						className="button inline button-primary"
						onClick={() => setCreatingProduct(true)}
					>
						<i className="mi">add</i>
						<span>{language.new_product}</span>
					</button>
				)}
			</aside>
		)
	}

	const renderCategories = (subcat) => {
		if (categories.product === null || categories.length === 0) return null
		let _cats = categories.product.filter((cat) => {
			let prodsInCat = products.find((pr) => pr.category === cat)
			let hasProducts = typeof prodsInCat !== 'undefined'
			return !!hasProducts
		})
		let cats = _cats.map((cat) => {
			if (cat === '') return null
			return (
				<li key={Math.round(Math.random() * 100000000000)}>
					<button
						className={`${cat === selectedCategory ? 'active' : ''}`}
						onClick={() => {
							setSelectedCategory(cat)
							setSelectedSubCategory(null)
						}}
					>
						<span>{cat}</span>
					</button>
				</li>
			)
		})
		// if (selectedCategory !== null) return null
		// if ((selectedCategory && !subcat) || selectedCategory === null)
		return (
			<div className="categories-ui">
				<div>
					<ul className={`count-${cats.length}`}>
						<li>
							<button
								className={`${
									selectedCategory === null ? 'active' : ''
								}`}
								onClick={() => {
									setSelectedCategory(null)
									setSelectedSubCategory(null)
								}}
							>
								<span>{language.all}</span>
							</button>
						</li>
						{cats}
					</ul>
				</div>
			</div>
		)
	}

	const renderSubCategories = (subcat) => {
		if (typeof subcat === 'undefined' || selectedCategory === null)
			return null

		let cats = subcat.map((cat) => {
			if (cat === '') return null
			return (
				<li key={Math.round(Math.random() * 100000000000)}>
					<button
						className={`${cat === selectedSubCategory ? 'active' : ''}`}
						onClick={() => setSelectedSubCategory(cat)}
					>
						<span>{cat}</span>
					</button>
				</li>
			)
		})
		// cats.unshift(
		// 	<li>
		// 		<button
		// 			className={`${selectedSubCategory === null ? 'active' : ''}`}
		// 			onClick={() => {
		// 				setSelectedCategory(selectedCategory)
		// 				setSelectedSubCategory(null)
		// 			}}
		// 		>
		// 			<span>{selectedCategory}</span>
		// 		</button>
		// 	</li>
		// )
		// cats.unshift(
		// 	<li className="back">
		// 		<button
		// 			className="back-button"
		// 			onClick={() => {
		// 				setSelectedCategory(null)
		// 				setSelectedSubCategory(null)
		// 			}}
		// 		>
		// 			<i className="mi">arrow_back</i>
		// 			<span>{language.back}</span>
		// 		</button>
		// 	</li>
		// )
		return (
			<div className="subcategories-ui">
				<div>
					<ul className={`count-${cats.length}`}>{cats}</ul>
				</div>
			</div>
		)
	}

	const getUserPlaces = () => {
		let plcs = user.places.filter((pl) => pl !== place.id)
		let promises = plcs.map((place_id) => {
			return firestore.collection('places').doc(place_id).get()
		})
		Promise.all(promises).then((_places) => {
			let places = _places.map((place) => place.data())
			setUserPlaces(places)
			getUserProducts(places)
		})
	}

	const getUserProducts = (places) => {
		let product_ids = []
		places.forEach((place) => {
			let f_prods = []
			let prods = place.products.map((pr) => ({
				place: place.id,
				product: pr,
			}))

			f_prods = prods.filter(
				(pr) =>
					typeof product_ids.find((x) => x.product === pr.product) ===
					'undefined'
			)

			product_ids = [...product_ids, ...f_prods]
		})

		product_ids = product_ids.filter(
			(pr) => place.products.indexOf(pr.product) === -1
		)

		let promises = product_ids.map((product_id) => {
			return firestore
				.collection('places')
				.doc(product_id.place)
				.collection('products')
				.doc(product_id.product)
				.get()
		})
		Promise.all(promises).then((_products) => {
			let products = _products.map((prod) => prod.data())
			setUserProducts(products)
		})
	}

	const getUnitName = (u = 'u') => {
		switch (u) {
			case 'u':
				return 'Unidad'
			case 'kg':
				return 'Kilo'
			case 'lt':
				return 'Litro'
			case 'pt':
				return 'Ración'
			case 'lb':
				return 'Libra'
		}
	}

	const renderProducts = () => {
		if (products === null) return <Loading />
		let employee_permissions = place.employee_categories.find(
			(cat) => cat.id === employee.category
		).permissions
		let sorted = place.products.sort()
		let sorted_prods = sorted.map((s) => products.find((e) => e.id === s))

		let subcat =
			selectedCategory !== null
				? place.product_subcategories[selectedCategory]
				: null

		let prods = sorted_prods.map((prod, v) => {
			if (typeof prod === 'undefined') return null
			if (selectedCategory !== null && prod.category !== selectedCategory)
				return null
			if (
				selectedSubCategory !== null &&
				prod.subcategory !== selectedSubCategory
			)
				return null
			let type = language[prod.type]

			let addClassname = addBtnAnimation === `${v}` ? 'pop-it' : ''

			return (
				<tr
					key={prod.id}
					className="clickable"
					onClick={(e) => {
						if (checkout) {
							e.stopPropagation()
							setDetailsModal(prod)
						}
					}}
				>
					<td>
						<span className="img">
							{prod.image !== 'no_image' && (
								<i
									style={{ backgroundImage: `url(${prod.image})` }}
								></i>
							)}
						</span>
					</td>
					<td>
						<strong>{prod.name}</strong>
					</td>
					<td>
						<span className="price">
							<pre>{prod.price.toFixed(2)}</pre>{' '}
							<i className="mi">euro_symbol</i>
							{prod.price_type === 'units' && (
								<pre> / {getUnitName(prod.unit)}</pre>
							)}
						</span>
					</td>
					<td className="center">
						<p>
							{prod.category !== ''
								? `${prod.category} ${
										typeof prod.subcategory !== 'undefined' &&
										prod.subcategory !== ''
											? ` - ${prod.subcategory}`
											: ''
								  }`
								: ''}
						</p>
					</td>
					{/* <td className="center">
						{place === 'restaurant' && <p>{type}</p>}
					</td> */}
					<td className="center">
						{checkout && (
							<button
								className={`button button-outline button-small inline ${addClassname} button-primary`}
								style={{
									position: 'relative',
								}}
								disabled={addClassname !== ''}
								onClick={(e) => {
									e.stopPropagation()
									if (
										prod.additions.length > 0 ||
										prod.price_type === 'units'
									)
										setDetailsModal(prod)
									else addProductToCart(prod)
									setAddBtnAnimation(`${v}`)
									setTimeout(() => setAddBtnAnimation(null), 300)
								}}
							>
								<span
									style={{
										opacity: addClassname !== '' ? 0 : 1,
									}}
								>
									{addingTo === null
										? language.add_to_cart
										: `${language.add_to_table_cart}`}
								</span>

								{addClassname !== '' && (
									<span
										style={{
											position: 'absolute',
											width: '100%',
											textAlign: 'center',
											left: 0,
											marginLeft: 0,
										}}
									>
										+1
									</span>
								)}
							</button>
						)}
					</td>

					<td className="more-cell">
						{employee_permissions.indexOf('administrator') !== -1 &&
							!checkout && (
								<More>
									<button
										title="Edit"
										onClick={(e) => {
											e.stopPropagation()
											let isService = prod.service || false
											setEditService(isService)
											setEdittingProduct(prod)
										}}
									>
										<i className="mi">edit</i>
										<span>{language.edit}</span>
									</button>
									<button
										title="Edit"
										onClick={(e) => setTranslatingProd(prod)}
									>
										<i className="mi">translate</i>
										<span>Traducir</span>
									</button>
								</More>
							)}
					</td>
				</tr>
			)
		})

		return (
			<>
				{renderCategories(subcat)}
				{renderSubCategories(subcat)}
				<div className="table-ui">
					<div>
						<table>
							<thead>
								<tr>
									<th></th>
									<th>{language.name}</th>
									<th>{language.price}</th>
									<th className="center">{language.category}</th>
									{/* <th className="center">
										{place.type === 'restaurant' && language.type}
									</th> */}
									<th />
									<th />
								</tr>
							</thead>
							<tbody>{prods}</tbody>
						</table>
					</div>
				</div>
			</>
		)
	}

	const renderAddModal = () => {
		let pimage =
			detailsModal.image !== 'no_image'
				? { backgroundImage: `url(${detailsModal.image})` }
				: {}
		return (
			<ModalForm toggle={setDetailsModal} size="sm">
				<div className="form-ui">
					<span className="img" style={pimage}></span>
					<h1>{detailsModal.name}</h1>
					<p className="lead">{detailsModal.description}</p>
					<span className="price price-lg">
						<pre>{detailsModal.price}</pre>
						<i className="mi">euro_symbol</i>
					</span>

					<div>
						<div
							className="form-group mb-3"
							style={{
								display: 'flex',
								flexDirection: 'row',
								width: 300,
								margin: '0 auto',
							}}
						>
							<div className="form-group-prepend">
								<button
									className="button button-outline-secondary"
									type="button"
									id="button-addon1"
									disabled={sellQuantity < 2}
									onClick={(e) => {
										if (sellQuantity > 1)
											setSellQuantity(sellQuantity - 1)
									}}
								>
									-
								</button>
							</div>
							<input
								type="number"
								className="form-control"
								onChange={(e) => setSellQuantity(e.target.value)}
								value={sellQuantity}
								style={{
									textAlign: 'center',
								}}
							/>
							<div className="input-group-append">
								<button
									className="button button-outline-secondary"
									type="button"
									id="button-addon2"
									onClick={(e) => {
										setSellQuantity(sellQuantity + 1)
									}}
								>
									+
								</button>
							</div>
						</div>
					</div>

					{detailsModal.allergens.length > 0 && (
						<div
							className="allergens-details"
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
								marginBottom: 15,
							}}
						>
							<h3
								style={{
									display: 'block',
									marginBottom: 15,
								}}
							>
								{language.allergens}
							</h3>
							<Allergens
								allergens={detailsModal.allergens}
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'row',
									flexWrap: 'wrap',
								}}
								imgstyle={{
									width: 40,
									height: 40,
								}}
								language={language}
							/>
						</div>
					)}

					{detailsModal.additions.map((add, x) => {
						let total = 0
						let disabled = false
						add.items.forEach((it) => {
							if (typeof it.quantity !== 'undefined')
								total += it.quantity
						})
						if (total >= add.max) disabled = true
						let items = add.items.map((it, y) => (
							<label key={it.name}>
								<div className="input-check">
									<input
										type="checkbox"
										disabled={disabled && !it.quantity}
										checked={!!it.quantity}
										onChange={(e) => {
											setDetailsModal({
												...detailsModal,
												additions: detailsModal.additions.map(
													(_add, a) => {
														if (a !== x) return _add
														return {
															..._add,
															items: _add.items.map((_it, b) => {
																if (b !== y) return _it
																return {
																	..._it,
																	quantity: !!e.target.checked
																		? 1
																		: 0,
																}
															}),
														}
													}
												),
											})
										}}
									/>
									<span className="label"></span>
								</div>
								<p className="add">
									<b>{it.name}</b>
									<span className="price price-sm price-gray">
										<b>{it.value}</b>
										<i className="mi">euro_symbol</i>
									</span>
								</p>
							</label>
						))
						return (
							<div className="adds" key={add.name}>
								<em>
									{add.name}: (Max {add.max})
								</em>
								<div>{items}</div>
							</div>
						)
					})}

					<footer>
						<button
							type="button"
							className="button button-primary"
							onClick={() => {
								let prod = { ...detailsModal }
								addProductToCart(prod)
								setSellQuantity(1)
								setDetailsModal(false)
							}}
							disabled={
								detailsModal.price_type === 'units' && sellQuantity <= 0
							}
						>
							<i className="mi">add</i>
							<span>
								{addingTo === null
									? language.add_to_cart
									: `${language.add_to_table_cart}`}
							</span>
						</button>
					</footer>
				</div>
			</ModalForm>
		)
	}

	const addProductToCart = (prod) => {
		let id = `${prod.id}_${Math.floor(Math.random() * 10000000)}`
		let qty = prod.quantity || prod.units || sellQuantity
		let it_charge =
			prod.price_type === 'units' ? prod.price * qty : prod.price
		let value = cart.value + it_charge
		prod.additions.forEach((add) => {
			add.items.forEach((it) => {
				if (it.quantity > 0) {
					value += it.value
				}
			})
		})

		let extra = {}

		if (prod.price_type === 'units') {
			extra.quantity = sellQuantity
			setSellQuantity(0)
		}

		setCart({
			items: [
				...cart.items,
				{
					...prod,
					cartId: id,
					...extra,
				},
			],
			value,
		})
	}

	const toggle = () => setCreatingProduct(false)

	const refreshTable = () => {
		setRedirect('/tables?reload=1')
		setAddingTo(null)
	}

	const onSubmitImport = (values, placeholder = false) => {
		try {
			setSubmitting(true)
			let products_to_import = Object.keys(values).filter(
				(v) => values[v] === true
			)
			if (products_to_import.length === 0) {
				setSubmitting(false)
				setImportProducts(false)
				setImportType(null)
				return
			} else {
				let productsDat = []
				if (!placeholder) {
					productsDat = userProducts.filter(
						(pr) => products_to_import.indexOf(pr.id) !== -1
					)
				} else {
					productsDat = placeholderProducts.filter(
						(pr) => products_to_import.indexOf(pr.id) !== -1
					)
					productsDat = productsDat.map((p) => ({
						...p,
						price: values[`price_${p.id}`],
					}))
				}

				firestore
					.runTransaction(async (transaction) => {
						let placeDocRef = firestore.collection('places').doc(place.id)
						let latestPlaceData = await transaction.get(placeDocRef)
						latestPlaceData = latestPlaceData.data()
						let newCategories = [...latestPlaceData.product_categories]
						let newSubcategories = {
							...latestPlaceData.product_subcategories,
						}
						let _products = [...latestPlaceData.products]

						for (let i = 0; i < productsDat.length; i++) {
							const product = productsDat[i]
							_products.push(product.id)
							let isNewCategory =
								newCategories.indexOf(product.category) === -1

							if (isNewCategory) newCategories.push(product.category)

							if (isNewCategory && product.subcategory !== '') {
								let sub_ =
									typeof newSubcategories[product.category] !==
									'undefined'
										? newSubcategories[product.category]
										: []
								newSubcategories[product.category] = [
									...sub_,
									product.subcategory,
								]
							} else if (
								!isNewCategory &&
								typeof newSubcategories[product.category] !==
									'undefined' &&
								newSubcategories[product.category].indexOf(
									product.subcategory
								) === -1
							) {
								let sub_ =
									typeof newSubcategories[product.category] !==
									'undefined'
										? newSubcategories[product.category]
										: []
								newSubcategories[product.category] = [
									...newSubcategories[product.category],
									...sub_,
									product.subcategory,
								]
							}
						}

						let filteredCats = []
						newCategories.forEach((c) => {
							if (filteredCats.indexOf(c) === -1) filteredCats.push(c)
						})

						let filteredSubcats = {}
						Object.keys(newSubcategories).forEach((c) => {
							let dt = []
							newSubcategories[c].forEach((s) => {
								if (dt.indexOf(s) === -1) dt.push(s)
							})
							filteredSubcats[c] = dt
						})

						transaction.update(placeDocRef, {
							product_categories: filteredCats,
							product_subcategories: filteredSubcats,
							products: _products,
						})
						for (let j = 0; j < productsDat.length; j++) {
							const product = productsDat[j]
							let productDocRef = firestore
								.collection('places')
								.doc(place.id)
								.collection('products')
								.doc(product.id)
							transaction.set(productDocRef, product)
						}
					})
					.then(async () => {
						create_alert(
							'Estamos sincronizando tus productos, no los modifiques.',
							'warning'
						)
						let _place = await firestore
							.collection('places')
							.doc(place.id)
							.get()
						_place = _place.data()

						let promises = _place.products.map((prod_id) => {
							return firestore
								.collection('places')
								.doc(_place.id)
								.collection('products')
								.doc(prod_id)
								.get()
						})
						Promise.all(promises).then(async (prods) => {
							let productsDat = prods.map((prod) => prod.data())
							await firestore.doc(`places_list/${_place.id}`).update({
								productsDat,
								product_categories: _place.product_categories,
								product_subcategories: _place.product_subcategories,
							})
							create_alert('Productos importados', 'success')
							setSubmitting(false)
							setImportProducts(false)
							setImportType(null)
						})
					})
					.catch((e) => {
						setSubmitting(false)
						console.log('ERROR', e)
						create_alert(language.an_error_ocurred, 'danger')
						setImportProducts(false)
						setImportType(null)
					})
			}
		} catch (e) {
			console.log('ERROR', e)
		}
	}

	const getImportInitialValues = () => {
		let values = {}
		userProducts.forEach((pr) => {
			values[pr.id] = false
		})
		return values
	}

	const getImportPlaceholderInitialValues = () => {
		let values = {}
		placeholderProducts.forEach((pr) => {
			values[pr.id] = false
			values[`price_${pr.id}`] = 0
		})
		return values
	}

	const getProductType = (val) => {
		switch (val) {
			case 'Postre':
				return 'dessert'
			case 'Bebida':
				return 'drink'
			case 'Entrada':
				return 'starter'
			default:
				return 'main_course'
		}
	}

	const getAllergen = (val) => {
		switch (val.toString().toLowerCase().trim()) {
			case 'apio':
				return { value: 'celery', label: 'Apio' }
			case 'crustáceos':
				return { value: 'crustaceans', label: 'Crustáceos' }
			case 'huevos':
				return { value: 'eggs', label: 'Huevos' }
			case 'pescado':
				return { value: 'fish', label: 'Pescado' }
			case 'lupino':
				return { value: 'lupin', label: 'Lupino' }
			case 'leche':
				return { value: 'milk', label: 'Leche' }
			case 'moluscos':
				return { value: 'molluscs', label: 'Moluscos' }
			case 'mostaza':
				return { value: 'mustard', label: 'Mostaza' }
			case 'nueces':
				return { value: 'nuts', label: 'Nueces' }
			case 'maní':
				return { value: 'peanut', label: 'Maní' }
			case 'sesamo':
				return { value: 'sesame', label: 'Sesamo' }
			case 'soya':
				return { value: 'soya', label: 'Soya' }
			case 'dióxido de azufre':
				return { value: 'sulphur', label: 'Dióxido de azufre' }
			case 'gluten':
				return { value: 'wheat', label: 'Gluten' }
			default:
				return null
		}
	}

	const createProduct = async (values) => {
		try {
			let _additions = []
			let _allergens_ = values.allergens

			let price = parseFloat(values.price)
			let prep_time = values.preparation_time * 60000
			let product_slug =
				values.name.replace(/ /g, '_') +
				'_' +
				Math.floor(Math.random() * 1000)
			product_slug = product_slug.toLowerCase()

			let url = 'no_image'

			await firestore.runTransaction(async (transaction) => {
				let placeDocRef = firestore.collection('places').doc(place.id)
				let latestPlaceData = await transaction.get(placeDocRef)
				latestPlaceData = latestPlaceData.data()

				let isNewCategory = true
				latestPlaceData.product_categories.forEach((cat) => {
					if (cat === values.category) isNewCategory = false
				})

				if (isNewCategory)
					await transaction.update(placeDocRef, {
						product_categories: [
							...latestPlaceData.product_categories,
							values.category,
						],
					})

				if (values.subcategory !== '') {
					if (isNewCategory)
						await transaction.update(placeDocRef, {
							product_subcategories: {
								...latestPlaceData.product_subcategories,
								[values.category]: [values.subcategory],
							},
						})
					else {
						let isNewSubcategory = true
						let hasSubcategorie =
							typeof latestPlaceData.product_subcategories[
								values.category
							] !== 'undefined'

						if (hasSubcategorie) {
							latestPlaceData.product_subcategories[
								values.category
							].forEach((cat) => {
								if (cat === values.subcategory) isNewSubcategory = false
							})
							if (isNewSubcategory)
								await transaction.update(placeDocRef, {
									product_subcategories: {
										...latestPlaceData.product_subcategories,
										[values.category]: [
											...latestPlaceData.product_subcategories[
												values.category
											],
											values.subcategory,
										],
									},
								})
						} else
							await transaction.update(placeDocRef, {
								product_subcategories: {
									...latestPlaceData.product_subcategories,
									[values.category]: [values.subcategory],
								},
							})
					}
				}

				let productDocRef = firestore
					.collection('places')
					.doc(latestPlaceData.id)
					.collection('products')
					.doc(product_slug)

				await transaction.set(productDocRef, {
					name: values.name,
					id: product_slug,
					price,
					image: url,
					additions: _additions,
					description: values.description,
					category: values.category,
					subcategory: values.subcategory,
					preparation_time: prep_time,
					type: values.type,
					allergens: _allergens_,
					upvotes: 0,
					taxrate: values.taxrate,
					price_type: values.price_type,
					unit: values.unit,
					allow_order_from_app:
						values.allow_order_from_app.toLowerCase().trim() === 'si',
					show_in_products_list:
						values.show_in_products_list.toLowerCase().trim() === 'si',
					show_price_in_products_list:
						values.show_price_in_products_list.toLowerCase().trim() ===
						'si',
					inventory: [],
				})
				await transaction.update(placeDocRef, {
					products: [...latestPlaceData.products, product_slug],
				})
			})
			return true
		} catch (e) {
			toggle(false)
			create_alert(language.an_error_ocurred, 'danger')
			setSubmitting(false)
		}
	}

	const commaStringToArrayAllergens = (val) => {
		let separated = val.split(',')
		let res = separated.map((s) => getAllergen(s))

		return res.filter((res) => res !== null)
	}

	const uploadProducts = async (prods) => {
		setImportProducts(false)
		setImportType(null)
		for (let i = 0; i < prods.length; i++) {
			const prod = prods[i]
			await createProduct(prod)
		}

		create_alert('Productos importados', 'success')
	}

	const readTextFile = (file) => {
		setSubmitting(true)
		var reader = new FileReader()
		reader.onload = async (e) => {
			let text = e.target.result
			let json = await csv({ noheader: true, output: 'csv' }).fromString(
				text
			)
			json.shift()
			let finalObjects = []
			let isInvalid = false
			if (json.length > 11) {
				finalObjects = json.map((val) => {
					if (`${val[0]}`.length <= 1) isInvalid = true
					if (isNaN(parseFloat(val[4]))) isInvalid = true
					if (isNaN(parseFloat(val[6]))) isInvalid = true
					if (isNaN(parseFloat(val[5]))) isInvalid = true
					return {
						name: `${val[0]}`,
						price: parseFloat(val[4]),
						preparation_time: parseInt(val[6]),
						category: `${val[7]}`,
						type: getProductType`${val[2]}`,
						description: `${val[1]}`,
						subcategory: `${val[8]}`,
						taxrate: parseFloat(val[5]),
						price_type: `${val[3]}` === 'Precio Fijo' ? 'fixed' : 'units',
						allow_order_from_app: `${val[12]}`,
						show_in_products_list: `${val[10]}`,
						show_price_in_products_list: `${val[11]}`,
						unit: 'u',
						additions: {},
						allergens: commaStringToArrayAllergens(val[9]),
					}
				})
			} else {
				finalObjects = json.map((val) => {
					if (`${val[0]}`.length <= 1) isInvalid = true
					if (isNaN(parseFloat(val[3]))) isInvalid = true
					if (isNaN(parseFloat(val[4]))) isInvalid = true
					return {
						name: `${val[0]}`,
						price: parseFloat(val[3]),
						preparation_time: 0,
						category: `${val[5]}`,
						type: 'main_course',
						description: `${val[1]}`,
						subcategory: `${val[6]}`,
						taxrate: parseFloat(val[4]),
						price_type: `${val[2]}` === 'Precio Fijo' ? 'fixed' : 'units',
						allow_order_from_app: `${val[10]}`,
						show_in_products_list: `${val[8]}`,
						show_price_in_products_list: `${val[9]}`,
						unit: 'u',
						additions: {},
						allergens: commaStringToArrayAllergens(val[7]),
					}
				})
			}

			uploadProducts(finalObjects)
		}
		reader.readAsText(file)
	}

	const renderImportPlaceholderModal = () => {
		if (placeholderProducts === null)
			return (
				<ModalForm
					toggle={() => {
						setImportType(null)
						setImportProducts(false)
					}}
				>
					<form
						className="form-ui"
						onKeyPress={(e) => {
							if (e.charCode === 13) {
								e.preventDefault()
							}
						}}
					>
						<h1>{language.import}</h1>
					</form>
				</ModalForm>
			)
		return (
			<ModalForm
				toggle={() => {
					setImportType(null)
					setImportProducts(false)
				}}
			>
				<Formik
					validateOnBlur={false}
					validateOnChange={false}
					validate={(values) => {
						let valid = true
						let keys = Object.keys(values).filter((e) =>
							e.startsWith('price_')
						)
						keys = keys.map((k) => k.split('_')[1])
						keys.forEach((k) => {
							if (
								values[k] === true &&
								(values[`price_${k}`] === 0 ||
									typeof values[`price_${k}`] !== 'number')
							)
								valid = `price_${k}`
						})

						if (valid !== true) {
							return {
								[valid]: 'Invalido',
							}
						}
					}}
					initialValues={getImportPlaceholderInitialValues()}
					onSubmit={(v) => onSubmitImport(v, true)}
				>
					{({ errors, touched, setFieldValue, values, setValues }) => {
						return (
							<Form
								className="form-ui"
								onKeyPress={(e) => {
									if (e.charCode === 13) {
										e.preventDefault()
									}
								}}
							>
								<h1>{language.import}</h1>
								<ul className="product-import-list">
									{placeholderProducts.map((pr) => {
										let pr_cats = (
											<em>
												{pr.category !== '' && <b>{pr.category}</b>}
												{pr.subcategory !== '' && (
													<>
														<i className="mi">chevron_right</i>{' '}
														<b>{pr.subcategory}</b>
													</>
												)}
											</em>
										)
										return (
											<li key={pr.id} className="product-import">
												<div key={pr.id}>
													<div className="input-check">
														<Field
															type="checkbox"
															name={pr.id}
															id={pr.id}
														/>
														<label htmlFor={pr.id}>Toggle</label>
													</div>
													<label htmlFor={pr.id}>
														<span
															className="img"
															style={{
																backgroundImage: `url(${pr.image})`,
															}}
														></span>

														<span>{pr.name}</span>

														{pr.category !== '' && pr_cats}
													</label>
													{values[pr.id] === true && (
														<Input
															errors={errors}
															touched={touched}
															label="Precio"
															name={`price_${pr.id}`}
															type="number"
															submitting={submitting}
															rowcolleft="4"
															rowcolright="8"
															append={
																<i className="mi">
																	euro_symbol
																</i>
															}
															custom={{
																step: '0.01',
															}}
														/>
													)}
												</div>
											</li>
										)
									})}
								</ul>

								<footer>
									<button
										disabled={submitting}
										type="submit"
										className="button button-primary "
									>
										{submitting && (
											<div role="status">
												<span>{language.loading}...</span>
											</div>
										)}
										{!submitting && language.continue}
									</button>
								</footer>
							</Form>
						)
					}}
				</Formik>
			</ModalForm>
		)
	}

	const renderSelectImportModal = () => {
		if (importType === 'places') return renderImportModal()
		else if (importType === 'placeholder')
			return renderImportPlaceholderModal()
		else
			return (
				<ModalForm
					toggle={() => {
						setImportType(null)
						setImportProducts(false)
					}}
				>
					<form className="form-ui" onSubmit={(e) => e.preventDefault()}>
						<h1>¿Desde donde quieres importar?</h1>
						<footer>
							<button
								disabled={submitting}
								type="button"
								className="button block button-light"
								onClick={() => setImportType('places')}
							>
								Desde mis otros lugares
							</button>
						</footer>
						<footer>
							<button
								disabled={submitting}
								type="button"
								className="button  block button-light"
								onClick={() => {
									getPredefinedProducts()
									setImportType('placeholder')
								}}
							>
								Desde productos predefinidos
							</button>
						</footer>
					</form>
				</ModalForm>
			)
	}

	const getPredefinedProducts = async () => {
		let collection = await firestore.collection('placeholder_products').get()
		let docs = collection.docs.map((d) => d.data())

		docs = docs.filter((f) => {
			let found = products.find((p) => p.id === f.id)
			if (typeof found === 'undefined') return true
			return false
		})

		setPlaceholderProducts(docs)
	}

	const renderImportModal = () => {
		if (userPlaces === null || userProducts === null) return <Loading />
		return (
			<ModalForm
				toggle={() => {
					setImportType(null)
					setImportProducts(false)
				}}
			>
				<Formik
					validateOnBlur={false}
					validateOnChange={false}
					initialValues={getImportInitialValues()}
					onSubmit={(e) => onSubmitImport(e, false)}
				>
					{({ errors, touched, setFieldValue, values, setValues }) => {
						return (
							<Form
								className="form-ui"
								onKeyPress={(e) => {
									if (e.charCode === 13) {
										e.preventDefault()
									}
								}}
							>
								<h1>{language.import}</h1>
								<ul className="product-import-list">
									{userProducts.map((pr) => {
										let pr_cats = (
											<em>
												{pr.category !== '' && <b>{pr.category}</b>}
												{pr.subcategory !== '' && (
													<>
														<i className="mi">chevron_right</i>{' '}
														<b>{pr.subcategory}</b>
													</>
												)}
											</em>
										)
										return (
											<li key={pr.id} className="product-import">
												<div key={pr.id}>
													<div className="input-check">
														<Field
															type="checkbox"
															name={pr.id}
															id={pr.id}
														/>
														<label htmlFor={pr.id}>Toggle</label>
													</div>
													<label htmlFor={pr.id}>
														<span
															className="img"
															style={{
																backgroundImage: `url(${pr.image})`,
															}}
														></span>

														<span>{pr.name}</span>

														{pr.category !== '' && pr_cats}
													</label>
												</div>
											</li>
										)
									})}
								</ul>

								<footer
									className={`${
										userProducts.length > 0 ? 'cols-2' : ''
									}`}
								>
									{userProducts.length > 0 && (
										<button
											disabled={
												submitting || userProducts.length === 0
											}
											type="submit"
											className="button button-primary "
										>
											{submitting && (
												<div role="status">
													<span>{language.loading}...</span>
												</div>
											)}
											{!submitting && language.continue}
										</button>
									)}
									<label
										for="importInput"
										className="button button-light showHover"
									>
										<span>Importar archivo</span>
										<input
											type="file"
											name="importInput"
											id="importInput"
											style={{
												display: 'none',
											}}
											accept=".csv"
											onChange={(e) => {
												readTextFile(e.target.files[0])
											}}
										/>
									</label>

									{place.type !== 'store' && (
										<Link
											style={{
												marginTop: 50,
											}}
											className="button button-link"
											to="/ImportarProductos.xlsx"
											target="_blank"
											download
										>
											Descargar Archivo de Ejemplo
										</Link>
									)}
									{place.type === 'store' && (
										<Link
											style={{
												marginTop: 50,
											}}
											className="button button-link"
											to="/ImportarProductosTienda.xlsx"
											target="_blank"
											download
										>
											Descargar Archivo de Ejemplo
										</Link>
									)}
								</footer>
								<p className="lead">
									Descarga el archivo de Excel, y luego exportalo como{' '}
									<pre>.csv</pre>
								</p>
							</Form>
						)
					}}
				</Formik>
			</ModalForm>
		)
	}

	const renderEditCategories = () => {
		return (
			<ModalForm toggle={() => setEditCategories(false)}>
				<Formik
					validateOnBlur={false}
					validateOnChange={false}
					initialValues={{
						categories: place.product_categories,
						subcategories: place.product_subcategories,
						hide: place.hide_in_app || [],
					}}
					onSubmit={async (values) => {
						setSubmitting(true)

						let affected_products = []

						for (let i = 0; i < products.length; i++) {
							const product = products[i]
							let prev_cat_index = place.product_categories.indexOf(
								product.category
							)
							let new_cat = values.categories[prev_cat_index]

							let new_sub = ''
							if (product.subcategory !== '') {
								if (
									typeof place.product_subcategories[
										product.category
									] !== 'undefined'
								) {
									let index = place.product_subcategories[
										product.category
									].indexOf(product.subcategory)

									if (index !== -1) {
										new_sub = values.subcategories[new_cat][index]
									}
								} else {
									new_sub = product.subcategory
								}
							}
							if (new_sub === null) new_sub = ''

							if (product.category !== new_cat) {
								if (new_cat === null) {
									affected_products.push({
										...product,
										category: '',
										subcategory: '',
									})
								} else {
									affected_products.push({
										...product,
										category: new_cat,
										subcategory: new_sub,
									})
								}
							} else if (product.subcategory !== new_sub) {
								affected_products.push({
									...product,
									subcategory: new_sub,
								})
							}
						}

						let new_cats = []
						let new_subs = {}

						values.categories.forEach((c) => {
							if (c !== null) new_cats.push(c)
						})

						Object.keys(values.subcategories).forEach((k) => {
							let val = values.subcategories[k]
							let new_vals = []
							val.forEach((c) => {
								if (c !== null) new_vals.push(c)
							})
							new_subs[k] = new_vals
						})

						await firestore.runTransaction(async (transaction) => {
							await transaction.update(
								firestore.doc(`places_list/${place.id}`),
								{
									products: products.map((p) => {
										let index = affected_products.indexOf(p.id)
										if (index !== -1) return affected_products[index]
										return p
									}),
									product_categories: new_cats,
									product_subcategories: new_subs,
									hide_in_app: values.hide,
								}
							)
							await transaction.update(
								firestore.doc(`places/${place.id}`),
								{
									product_categories: new_cats,
									product_subcategories: new_subs,
									hide_in_app: values.hide,
								}
							)
							for (let l = 0; l < affected_products.length; l++) {
								const prod = affected_products[l]
								await transaction.update(
									firestore.doc(
										`places/${place.id}/products/${prod.id}`
									),
									prod
								)
							}
						})

						update_products(
							products.map((p) => {
								let found = affected_products.find(
									(_p) => p.id === _p.id
								)
								if (typeof found !== 'undefined') {
									return found
								}
								return p
							})
						)

						setSubmitting(false)
						setEditCategories(false)
					}}
				>
					{({ errors, touched, setFieldValue, values, setValues }) => {
						return (
							<Form
								className="form-ui"
								onKeyPress={(e) => {
									if (e.charCode === 13) {
										e.preventDefault()
									}
								}}
							>
								<h1>Editar categorías</h1>
								{values.categories.map((cat, i) => {
									if (cat === null) return null
									if (i === 0) return null
									return (
										<div className="group">
											<div className="row-group">
												<div className="col-10">
													<input
														value={cat}
														className="text-control"
														onChange={(e) => {
															if (
																typeof values.subcategories[
																	cat
																] !== 'undefined'
															) {
																let new_subs =
																	values.subcategories
																new_subs[e.target.value] =
																	values.subcategories[cat]
																delete new_subs[cat]
																setFieldValue(
																	'subcategories',
																	new_subs
																)
															}
															setFieldValue(
																'categories',
																values.categories.map(
																	(c, x) => {
																		if (x !== i) return c
																		else return e.target.value
																	}
																)
															)
														}}
													/>
												</div>
												<div className="col-2">
													<button
														type="button"
														className="button button-danger button-small"
														title="Eliminar"
														onClick={(e) => {
															if (
																typeof values.subcategories[
																	cat
																] !== 'undefined'
															) {
																let new_subs =
																	values.subcategories
																delete new_subs[cat]
																setFieldValue(
																	'subcategories',
																	new_subs
																)
															}
															setFieldValue(
																'categories',
																values.categories.map(
																	(c, x) => {
																		if (x !== i) return c
																		else return null
																	}
																)
															)
														}}
													>
														<i className="mi">delete</i>
													</button>
												</div>
											</div>

											<div className="subs">
												{typeof values.subcategories[cat] !==
													'undefined' &&
													values.subcategories[cat].map((sub, j) =>
														sub === null ? null : (
															<div className="row-group">
																<div className="col-1"></div>
																<div className="col-9">
																	<input
																		value={sub}
																		className="text-control"
																		onChange={(e) =>
																			setFieldValue(
																				'subcategories',
																				{
																					...values.subcategories,
																					[cat]: values.subcategories[
																						cat
																					].map(
																						(_sub, k) => {
																							if (
																								j !== k
																							)
																								return _sub
																							return e
																								.target
																								.value
																						}
																					),
																				}
																			)
																		}
																	/>
																</div>

																<div className="col-2">
																	<button
																		type="button"
																		className="button button-danger button-small"
																		title="eliminar"
																		onClick={(e) => {
																			setFieldValue(
																				'subcategories',
																				{
																					...values.subcategories,
																					[cat]: values.subcategories[
																						cat
																					].map(
																						(_sub, k) => {
																							if (
																								j !== k
																							)
																								return _sub
																							return null
																						}
																					),
																				}
																			)
																		}}
																	>
																		<i className="mi">
																			delete
																		</i>
																	</button>
																</div>
															</div>
														)
													)}
											</div>
											<div className="form-group form-group-check">
												<div className="form-row">
													<div className="col-12">
														<input
															type="checkbox"
															name={`hide_${cat}`}
															id={`hide_${cat}`}
															checked={
																values.hide.indexOf(cat) !== -1
															}
															onClick={() => {
																if (
																	values.hide.indexOf(cat) !==
																	-1
																) {
																	setFieldValue(
																		'hide',
																		values.hide.filter(
																			(c) => c !== cat
																		)
																	)
																} else {
																	setFieldValue('hide', [
																		...values.hide,
																		cat,
																	])
																}
															}}
														/>
														<label htmlFor={`hide_${cat}`}>
															<b></b>{' '}
															<span>
																Esconder categoría en la App
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									)
								})}
								<footer className={`cols-2`}>
									<button
										disabled={submitting}
										type="button"
										onClick={() => setEditCategories(false)}
										className="button button-light"
									>
										Cancelar
									</button>
									<button
										disabled={submitting}
										type="submit"
										className="button button-primary "
									>
										{submitting && (
											<div role="status">
												<span>{language.loading}...</span>
											</div>
										)}
										{!submitting && language.continue}
									</button>
								</footer>
							</Form>
						)
					}}
				</Formik>
			</ModalForm>
		)
	}

	let employee_permissions = place.employee_categories.find(
		(cat) => cat.id === employee.category
	).permissions

	let showSyncAndCats =
		addingTo === null &&
		employee_permissions.indexOf('administrator') !== -1 &&
		!checkout
	let showImportBtn =
		!checkout && employee_permissions.indexOf('administrator') !== -1
	let showDropdown = showSyncAndCats && showImportBtn

	return (
		<>
			<Helmet>
				<title>Vywap | Ventas</title>
			</Helmet>
			{redirect && <Redirect to={redirect} />}
			{showCart && (
				<Cart
					set_cart={setCart}
					cart={cart}
					toggle={setShowCart}
					table={addingTo}
					refreshTable={refreshTable}
					total={cart.value}
					checkoutTable={cart.checkoutTable}
					setRedirect={setRedirect}
				/>
			)}
			{showCustom && (
				<ModalForm size="sm" toggle={() => setShowCustom(false)}>
					<Formik
						validateOnBlur={false}
						validateOnChange={false}
						initialValues={{
							name: '',
							price: 0,
						}}
						validate={(v) => {
							if (v.name.length < 2)
								return { name: 'Descripción del producto' }
						}}
						onSubmit={(e) => {
							let id = `${Math.floor(
								Math.random() * 10000000
							)}_${Math.floor(Math.random() * 10000000)}`
							setCart({
								items: [
									...cart.items,
									{
										additions: [],
										allergens: [],
										allow_order_from_app: false,
										category: 'custom',
										description: '',
										id,
										image: null,
										inventory: [],
										name: e.name,
										price: e.price,
										price_type: 'fixed',
										show_in_products_list: false,
										show_price_in_products_list: false,
										subcategory: 'custom',
										taxrate: 0,
										type: 'custom',
										unit: 'u',
										upvotes: 0,
										cartId: id,
									},
								],
								value: cart.value + e.price,
							})
							setShowCustom(false)
						}}
					>
						{({ errors, touched, setFieldValue, values, setValues }) => {
							return (
								<Form
									className="form-ui"
									onKeyPress={(e) => {
										if (e.charCode === 13) {
											e.preventDefault()
										}
									}}
								>
									<h1>Cobro Personalizado</h1>
									<Input
										label="Descripción"
										submitting={submitting}
										type="text"
										name="name"
										errors={errors}
										touched={touched}
									/>
									<Input
										label="Precio"
										disabled={submitting}
										type="number"
										name="price"
										errors={errors}
										touched={touched}
										append={<i className="mi">euro_symbol</i>}
										custom={{
											step: '0.01',
										}}
									/>
									<footer>
										<button
											disabled={submitting}
											type="submit"
											className="button button-primary "
										>
											{submitting && (
												<div role="status">
													<span>{language.loading}...</span>
												</div>
											)}
											{!submitting && language.continue}
										</button>
									</footer>
								</Form>
							)
						}}
					</Formik>
				</ModalForm>
			)}
			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs">
					{!checkout &&
						employee_permissions.indexOf('administrator') !== -1 && (
							<>
								<li
									className={`nav-link ${
										active === 0 ? 'active' : ''
									}`}
								>
									<Link onClick={() => setActive(0)} to="/products">
										Productos
									</Link>
								</li>
								<li
									className={`nav-link ${
										active === 1 ? 'active' : ''
									}`}
								>
									<Link
										onClick={() => setActive(1)}
										to="/products/recommendations"
									>
										Recomendaciones
									</Link>
								</li>
							</>
						)}
				</ul>
				<div className="actions">
					{addingTo !== null && (
						<>
							<div className="tag blue">
								<button
									onClick={() => {
										setCart({
											items: [],
											value: 0,
											checkoutTable: false,
										})
										setAddingTo(null)
										setRedirect('/tables')
									}}
								>
									<i className="mi">close</i>
								</button>
								<span>Carrito de la mesa {addingTo.name}</span>
							</div>
						</>
					)}

					{addingTo === null &&
						employee_permissions.indexOf('administrator') !== -1 &&
						!checkout && (
							<>
								{place.type !== 'restaurant' &&
									place.type !== 'disco' &&
									place.type !== 'store' && (
										<button
											className="button inline  button-primary-outline"
											disabled={
												!(
													(user.type === 'admin_free' &&
														place.products.length < 70) ||
													(user.type === 'admin_payasyougo' &&
														place.products.length < 100) ||
													user.type === 'admin_premium'
												)
											}
											onClick={() => {
												if (
													(user.type === 'admin_free' &&
														place.products.length < 70) ||
													(user.type === 'admin_payasyougo' &&
														place.products.length < 100) ||
													user.type === 'admin_premium'
												)
													setCreatingService(true)
											}}
										>
											<i className="mi">add</i>
											<span>Nuevo servicio</span>
										</button>
									)}

								<button
									className="button inline  button-primary"
									disabled={
										!(
											(user.type === 'admin_free' &&
												place.products.length < 70) ||
											(user.type === 'admin_payasyougo' &&
												place.products.length < 100) ||
											user.type === 'admin_premium'
										)
									}
									onClick={() => {
										if (
											(user.type === 'admin_free' &&
												place.products.length < 70) ||
											(user.type === 'admin_payasyougo' &&
												place.products.length < 100) ||
											user.type === 'admin_premium'
										)
											setCreatingProduct(true)
									}}
								>
									<i className="mi">add</i>
									<span>{language.new_product}</span>
								</button>
							</>
						)}
					{checkout && (
						<>
							<button
								className={`button button-primary`}
								onClick={(e) => setShowCustom(true)}
							>
								<i className="mi">add</i>
								<span>Cobro Personalizado</span>
							</button>
							<button
								disabled={cart.items.length === 0}
								className={`button button-secondary button-cart ${
									addBtnAnimation !== null ? 'pop-it-cart' : ''
								}`}
								onClick={(e) => {
									if (!showCart) setShowCart(true)
								}}
							>
								<i className="mi">shopping_cart</i>
								<span className="price">
									<pre>{cart.value.toFixed(2)}</pre>
									<i className="mi">euro_symbol</i>
								</span>
								<b>{cart.items.length}</b>
							</button>
						</>
					)}
					{showDropdown && (
						<More>
							{showImportBtn && (
								<button
									className="button button-light"
									onClick={() => {
										if (userPlaces === null) getUserPlaces()
										setImportProducts(true)
									}}
								>
									<i className="mi">import_export</i>
									<span>{language.import}</span>
								</button>
							)}
							{showSyncAndCats && (
								<>
									<button
										className="button  button-light"
										onClick={async () => {
											create_alert(
												'Estamos sincronizando tus productos, no los modifiques.',
												'warning'
											)
											let promises = place.products.map(
												(prod_id) => {
													return firestore
														.collection('places')
														.doc(place.id)
														.collection('products')
														.doc(prod_id)
														.get()
												}
											)
											Promise.all(promises).then(async (prods) => {
												let products = prods.map((prod) =>
													prod.data()
												)
												await firestore
													.doc(`places_list/${place.id}`)
													.update({
														products,
														product_categories:
															place.product_categories,
														product_subcategories:
															place.product_subcategories,
													})
												create_alert(
													'Productos sincronizados',
													'success'
												)
											})
										}}
									>
										<i className="mi">cached</i>{' '}
										<span>Sincronzar App</span>
									</button>
									<button
										className="button  button-light"
										onClick={() => setEditCategories(true)}
									>
										<i className="mi">edit</i>
										<span>Categorías</span>
									</button>
								</>
							)}
						</More>
					)}
				</div>
			</div>
			{translatingProd !== false && (
				<Translate
					item={translatingProd}
					toggle={() => setTranslatingProd(false)}
					place={place}
					products={products}
					update_products={update_products}
				/>
			)}
			{editCategories && renderEditCategories()}
			{place.products.length === 0 && renderEmpty()}
			{place.products.length > 0 && renderProducts()}
			{detailsModal !== false && renderAddModal()}
			{importProducts && renderSelectImportModal()}
			{creatingProduct && <Create toggle={toggle} />}
			{creatingService && (
				<Create toggle={() => setCreatingService(false)} service />
			)}
			{edittingProduct && (
				<Create
					toggle={(e) => setEdittingProduct(false)}
					editting={edittingProduct}
					service={editService}
					setService={setEditService}
				/>
			)}
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		products: state.products,
		categories: state.categories,
		tables: state.tables,
		language: state.language.dictionary,
		employee: state.employee,
		user: state.user,
		topbar: state.topbar,
	}),
	{ update_products, create_alert }
)(View)

import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Loading } from '../../components'
import { firestore } from '../../config/firebase'
import { PlaceSetup } from '../../routes'
import {
	events,
	set_configuration,
	set_report,
	set_user_permissions,
	sign_out,
	update_employee_categories,
	update_place,
	update_products,
	update_product_categories,
	update_promotions,
	update_schedule,
	update_tables,
	update_reservations,
	update_user,
	update_topbar,
	create_alert,
	set_pending_orders,
	update_covid,
	update_feedback,
} from '../../store/actions'
import ChooseEmployee from './ChooseEmployee'
import ChoosePlace from './ChoosePlace'
import Configuration from './Configuration'
import Employees from './Employees'
import Events from './Events'
import Navbar from './Navbar'
import Orders from './Orders'
import Products from './Products'
import Report from './Report/index'
import Tables from './Tables'
import Queue from './Queue'
import Reservations from './Reservations'
import RegularReservations from './RegularReservations'
import Chat from './Chat'
import Announcements from './Announcements'
import Stats from './Stats'
import StripeConnect from './StripeConnect'
import ReservationsCovid from './ReservationsCovid'
import ItemsPlace from './ItemsManagement/ItemsPlace'
import ItemsReport from './ItemsManagement/Report'
import Feedback from './Feedback'

function useInterval(callback, delay) {
	const savedCallback = useRef()

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current()
		}
		if (delay !== null) {
			let id = setInterval(tick, delay)
			return () => clearInterval(id)
		}
	}, [delay])
}

function Dashboard({
	history,
	place,
	user,
	update_place,
	update_employee_categories,
	set_configuration,
	update_product_categories,
	update_products,
	create_alert,
	update_tables,
	update_promotions,
	update_inventory,
	update_schedule,
	schedule,
	sign_out,
	set_events,
	employee,
	set_report,
	tables,
	update_reservations,
	language,
	update_user,
	update_topbar,
	topbar,
	reservations,
	pending_orders,
	set_pending_orders,
	covid,
	update_covid,
	update_feedback,
	feedback,
}) {
	const userAlertsWrapperRef = useRef(null)

	const [prevPlace, setPrevPlace] = useState(null)
	const [navbarOpened, setNavbarOpened] = useState(true)
	const [permissions, setPermissions] = useState([])
	const [rep, setRep] = useState(null)
	const [userAlertsH, setUserAlertsH] = useState(0)
	const [prevOrders, setPrevOrders] = useState(null)
	const [prevRes, setPrevRes] = useState(null)
	const [pathClass, setPathClass] = useState('')

	useEffect(() => {
		let navbarOpened =
			localStorage.getItem('navbar') === null
				? true
				: localStorage.getItem('navbar') === 'true'
				? true
				: false

		setNavbarOpened(navbarOpened)
		updatePlacesData()
	}, [])

	useEffect(() => {
		if (typeof place.orders !== 'undefined') {
			let _orders = place.orders.filter(
				(_order) => _order.confirmed !== true
			)
			set_pending_orders(_orders.length)
		}
	}, [place])

	useEffect(() => {
		if (userAlertsWrapperRef.current !== null) {
			setUserAlertsH(userAlertsWrapperRef.current.clientHeight)
			update_topbar({ top: userAlertsWrapperRef.current.clientHeight })
		}
	}, [userAlertsWrapperRef, user.notifications])

	useInterval(() => {
		refreshCovid()
	}, 60000)

	useEffect(() => {
		let config_listener = null
		if (place.id !== null) {
			if (place.id !== prevPlace) {
				setPrevPlace(place.id)
				getProductCategories()
				getFeedback()
				getProducts()
				getPromotions()
				getTables()
				getInventory()
				getSchedule()
				getReport()
				getReservations()
				if (place.features.indexOf('reservations_covid') === -1) {
					update_covid(null)
				} else {
					// Get config real time
					config_listener = firestore
						.doc(`/places/${place.id}/advanced_res_data/config`)
						.onSnapshot(async (col) => {
							if (!col.exists) {
								console.log('SNAPSHOT')
								firestore
									.doc(`/places/${place.id}/advanced_res_data/config`)
									.set({
										blocks: [],
										groups: [],
										max_slot: 0,
									})
							} else {
								update_covid({
									config: col.data(),
								})
								refreshCovid({
									config: col.data(),
								})
							}
						})
				}
			}
		}
		return () => {
			if (config_listener !== null) config_listener()
		}
	}, [place.id])

	useEffect(() => {
		let prevOrdersFiltered = []
		let ordersFiltered = []
		if (typeof prevOrders === 'object' && prevOrders !== null)
			prevOrdersFiltered = prevOrders.filter((f) => f.items.length > 0)
		if (typeof place.orders === 'object' && place.orders !== null)
			ordersFiltered = place.orders.filter((f) => f.items.length > 0)

		if (
			typeof prevOrders === 'object' &&
			typeof place.orders === 'object' &&
			place.orders !== null &&
			prevOrders !== null &&
			ordersFiltered.length > prevOrdersFiltered.length
		)
			create_alert(
				'Tienes un nuevo Pedido',
				'success',
				'/orders/pending',
				true
			)

		let items_changed = null
		prevOrdersFiltered.forEach((ord) => {
			let prev = ord
			let curr = ordersFiltered.find((o) => o.invoiceID === ord.invoiceID)
			if (typeof curr !== 'undefined') {
				if (prev.items.length < curr.items.length) {
					let tbl = null
					tables.floors.forEach((floor) => {
						floor.tables.forEach((table) => {
							if (table.id === ord.table) tbl = table.name
						})
					})
					items_changed = tbl
				}
			}
		})

		if (items_changed !== null)
			create_alert(
				`Nuevos productos para la mesa ${items_changed}`,
				'success',
				'/tables',
				true
			)

		setPrevOrders(place.orders)
	}, [place.orders])

	useEffect(() => {
		if (reservations !== null) {
			if (
				typeof prevRes === 'object' &&
				typeof reservations.pending === 'object' &&
				reservations.pending !== null &&
				prevRes !== null &&
				Object.keys(reservations.pending).length >
					Object.keys(prevRes).length
			)
				create_alert(
					'Tienes una nueva Reserva',
					'success',
					'/reservations/pending',
					true
				)
			setPrevRes(reservations.pending)
		}
	}, [reservations])

	useEffect(() => {
		if (employee !== null && place.id !== null) {
			let cat = place.employee_categories.find(
				(c) => c.id === employee.category
			)
			setPermissions(cat.permissions)
		}
	}, [employee, place.id])

	useEffect(() => {
		getProductCategories()
	}, [place.product_categories])

	useEffect(() => {
		let clss = window.location.pathname.replace(/\//g, ' ')

		setPathClass(clss)
	}, [window.location.pathname])

	const getReport = () => {
		if (rep === null) {
			firestore
				.collection('places')
				.doc(place.id)
				.collection('report')
				.get()
				.then((res) => {
					let emps = place.employees.map((emp) => ({
						id: emp.id,
						category: emp.category,
						name: emp.name,
						working: null,
						history: [],
						since: 0,
						submitting: false,
					}))
					let _emps = emps.map((emp) => ({
						...emp,
						working: false,
						submitting: false,
					}))
					res.docs.forEach((emp) => {
						_emps = _emps.map((_emp) => {
							if (_emp.id.toString() !== emp.id.toString()) return _emp
							return {
								..._emp,
								...emp.data(),
							}
						})
					})
					set_report(_emps)
				})
			setRep(true)
		}
	}

	const getSchedule = () => {
		update_schedule({
			employees: {
				list: [],
				count: 0,
				categories: [],
			},
			schedule: {
				monday: [],
				tuesday: [],
				wednesday: [],
				thursday: [],
				friday: [],
				saturday: [],
				sunday: [],
			},
		})
	}

	const getProducts = () => {
		if (place.products.length === 0) {
			update_products([])
			return
		}
		let promises = place.products.map((prod_id) => {
			return firestore
				.collection('places')
				.doc(place.id)
				.collection('products')
				.doc(prod_id)
				.get()
		})
		Promise.all(promises).then((prods) => {
			let products = prods.map((prod) => prod.data())
			update_products(products)
		})
	}

	const getInventory = async () => {
		let col = await firestore
			.collection('places')
			.doc(place.id)
			.collection('inventory')
			.get()

		let items = {}
		if (!col.empty) {
			col.docs.forEach((doc) => {
				items[doc.id] = doc.data()
			})
			update_inventory(items)
		} else {
			await firestore
				.collection('places')
				.doc(place.id)
				.collection('inventory')
				.doc('items')
				.set({
					items: [],
				})
			getInventory()
		}
	}

	const getPromotions = () => {
		if (
			typeof place.promotions === 'undefined' ||
			place.promotions.length === 0
		) {
			update_promotions(null)
			return
		}
		let promises = place.promotions.map((prom_id) => {
			return firestore
				.collection('places')
				.doc(place.id)
				.collection('promotions')
				.doc(prom_id)
				.get()
		})
		Promise.all(promises).then((proms) => {
			let promotions = proms.map((prod) => prod.data())
			update_promotions(promotions)
		})
	}

	const getTables = async () => {
		await firestore
			.collection('places')
			.doc(place.id)
			.collection('table_maps')
			.doc('tables')
			.get()
			.then((doc) => {
				const data = doc.data()
				update_tables(data)
			})
	}

	const getFormattedHour = (date) => {
		let _date = new Date(date)

		let hr = _date.getHours()
		let mn = _date.getMinutes()

		hr = `0${hr}`.slice(-2)
		mn = `0${mn}`.slice(-2)

		return `${hr}${mn}`
	}

	const getNumberDate = (date) => {
		let dt = new Date(date)
		let val = `${dt.getFullYear()}${`0${dt.getMonth() + 1}`.slice(
			-2
		)}${`0${dt.getDate()}`.slice(-2)}`
		return parseInt(val)
	}

	const refreshCovid = async (col = covid) => {
		try {
			if (col === null || !col.config) return
			let day = new Date().getDay()
			day--
			if (day === -1) day = 6
			let blocksInThatDay = col.config.blocks.filter(
				(b) => b.days.indexOf(day) !== -1
			)
			let slots = []
			blocksInThatDay.forEach((b) => {
				slots = [...slots, ...b.slots.map((s) => ({ ...s, blockId: b.id }))]
			})

			let todayFormatted = parseInt(getFormattedHour(new Date()))
			let slotsFormatted = []

			let blockMax = 0

			slots.forEach((s) => {
				blockMax += s.max
				let formattedStart = parseInt(getFormattedHour(new Date(s.starts)))
				let formattedEnd = parseInt(getFormattedHour(new Date(s.ends)))
				slotsFormatted.push({
					starts: formattedStart,
					ends: formattedEnd,
					id: s.id,
				})
			})

			let finalSlot = null

			slotsFormatted.forEach((s) => {
				if (s.starts <= todayFormatted && s.ends > todayFormatted)
					finalSlot = s
			})

			if (finalSlot !== null) {
				finalSlot = slots.find((s) => s.id === finalSlot.id)
				if (typeof finalSlot === 'undefined') finalSlot = null
			}

			let dt = getNumberDate(new Date().getTime())
			let dayData = await firestore
				.doc(`/places/${place.id}/advanced_res_queue/${dt}`)
				.get()
			if (dayData.exists) dayData = dayData.data().data
			else dayData = []

			let assisted = 0

			dayData.forEach((q) => {
				if (q.status.assist === true) {
					assisted += q.people
				}
			})

			let finalBlockIndex = -1

			let finalBlock = col.config.blocks.find((b, x) => {
				if (finalSlot && b.id === finalSlot.blockId) {
					finalBlockIndex = x
					return true
				}
				return false
			})

			update_covid({
				...col,
				current: {
					slot: finalSlot,
					block: { ...finalBlock, index: finalBlockIndex },
					queue: dayData,
					blockMax: blockMax,
					assisted: assisted,
				},
			})
		} catch (e) {
			update_covid(null)
		}
	}

	const getReservations = () => {
		firestore
			.collection('places')
			.doc(place.id)
			.collection('reservations')
			.onSnapshot((col) => {
				if (col.empty)
					update_reservations({ list: [], tables: [], pending: [] })
				else {
					let _list = col.docs.find((doc) => doc.id === 'list')
					let _tables = col.docs.find((doc) => doc.id === 'tables')
					let _pending = col.docs.find((doc) => doc.id === 'pending')
					let list = _list ? _list.data() : []
					let tables = _tables ? _tables.data() : []
					let pending = _pending ? _pending.data() : {}

					update_reservations({ list, tables, pending })
				}
			})
	}

	const getProductCategories = () => {
		if (place.id !== null) {
			update_product_categories(place.product_categories)
		}
	}

	const getFeedback = () => {
		if (place.id !== null) {
			firestore
				.collection('places')
				.doc(place.id)
				.collection('feedback')
				.onSnapshot((col) => {
					if (col.empty) update_feedback([])
					else {
						let fb = col.docs.map((d) => d.data())
						update_feedback(fb)
					}
				})
		}
	}

	const updatePlacesData = () => {
		const prevPlace = localStorage.getItem('prev_place')

		if (prevPlace) {
			getPlace(prevPlace)
		} else {
			if (!user.owner) {
				getPlace(user.place)
			} else if (user.places.length > 1) {
				// if (!history.location.pathname.startsWith('/items-management'))
				// 	history.replace('/choose')
			} else {
				getPlace(user.places[0])
			}
		}
	}

	const getPlace = (id) => {
		try {
			localStorage.setItem('prev_place', id)
			firestore
				.collection('places')
				.doc(id)
				.onSnapshot(
					(res) => {
						if (res.exists) {
							update_place(res.data())
							if (
								res.data().stripe_connected !== user.stripe_connected
							) {
								firestore.collection('places').doc(id).update({
									stripe_connected: user.stripe_connected,
								})
							}
						}
					},
					(err) => {
						localStorage.clear()
						sign_out()
						window.location.reload()
					}
				)
		} catch (e) {
			let isCollaborator = JSON.parse(localStorage.getItem('collaborator'))
			if (!isCollaborator) {
				localStorage.clear()
				sign_out()
				window.location.reload()
			}
		}
	}

	const redirect = () => {
		if (permissions === null) {
			setTimeout(() => {
				redirect()
			}, 1000)
		} else {
			if (permissions.indexOf('administrator') !== -1)
				return history.replace('/configuration/place/app')
			if (
				permissions.indexOf('tables') !== -1 &&
				place.features.indexOf('tables') !== -1
			)
				return history.replace('/tables')
			if (
				permissions.indexOf('products') !== -1 &&
				place.features.indexOf('products') !== -1
			)
				return history.replace('/products')
			if (
				permissions.indexOf('orders') !== -1 &&
				place.features.indexOf('tables') !== -1
			)
				return history.replace('/orders')
			if (
				permissions.indexOf('events') !== -1 &&
				place.features.indexOf('events') !== -1
			)
				return history.replace('/events')
			if (
				permissions.indexOf('employees') !== -1 &&
				place.features.indexOf('employees') !== -1
			)
				return history.replace('/employees')
			if (permissions.indexOf('sales') !== -1)
				return history.replace('/sales')
			if (
				permissions.indexOf('inventory') !== -1 &&
				place.features.indexOf('inventory') !== -1
			)
				return history.replace('/inventory')
			if (
				permissions.indexOf('reservations') !== -1 &&
				place.features.indexOf('reservations') !== -1
			)
				return history.replace('/reservations')
			if (
				permissions.indexOf('queue') !== -1 &&
				place.features.indexOf('queue') !== -1
			)
				return history.replace('/queue')
			return history.replace('/choose')
		}
	}

	const renderUserAlerts = () => {
		if (typeof user.notifications === 'undefined') return null

		let alerts = user.notifications.map((notification) => (
			<div
				key={notification.id}
				className={`user-alert ${notification.type}`}
			>
				<p>{notification.content}</p>
				<button
					onClick={async () => {
						update_user({
							notifications: user.notifications.filter(
								(n) => n.id !== notification.id
							),
						})

						await firestore
							.collection('users')
							.doc(user.id)
							.update({
								notifications: user.notifications.filter(
									(n) => n.id !== notification.id
								),
							})
					}}
				>
					<i className="mi">clear</i>
				</button>
			</div>
		))
		return (
			<div id="user-alerts" ref={userAlertsWrapperRef}>
				{alerts}
			</div>
		)
	}

	let loadingMainData =
		user.owner === null ||
		permissions.length === 0 ||
		employee === null ||
		schedule === null ||
		tables === null ||
		(place.id === null && window.location.pathname !== '/choose')

	let has_navbar = place.id !== null ? 'has-navbar' : ''

	let has_user_alerts =
		typeof user.notifications === 'undefined' ? 0 : user.notifications.length

	if (loadingMainData) {
		return (
			<div id="main" style={{ paddingTop: userAlertsH + topbar.paddingTop }}>
				<div
					className={`dashboard ${pathClass} theme-${place.theme} ${
						navbarOpened ? 'navbar-opened' : 'navbar-closed'
					} ${
						has_user_alerts > 0
							? `has-user-alerts user-alerts-count-${has_user_alerts}`
							: ''
					}`}
				>
					{renderUserAlerts()}
					<Switch>
						<Route
							path="/items-management"
							render={(props) => <ItemsPlace {...props} />}
						/>
						<Route
							path="/items-management-report"
							render={(props) => <ItemsReport {...props} />}
						/>
						<Route
							path="/choose"
							render={(props) => (
								<ChooseEmployee getPlace={getPlace} {...props} />
							)}
						/>
						<Route
							path="/create-place"
							render={(props) => (
								<div className="main">
									<div
										className="topbar no-navbar"
										style={{ top: topbar.top }}
									>
										<div className="actions">
											<button
												className="button button-outline inline"
												onClick={(e) => history.replace('/choose')}
											>
												<i className="mi">arrow_back</i>
												<span>{language.back}</span>
											</button>
										</div>
									</div>
									<PlaceSetup user={user} {...props} />
								</div>
							)}
						/>
						<Route
							path="/"
							render={(props) => (
								<Redirect
									to={`/choose?redirect=${window.location.pathname}`}
								/>
							)}
						/>
					</Switch>
				</div>
			</div>
		)
	}

	return (
		<div id="main" style={{ paddingTop: userAlertsH + topbar.paddingTop }}>
			<div
				className={`dashboard ${pathClass} theme-${
					place.theme
				} ${has_navbar} ${
					navbarOpened ? 'navbar-opened' : 'navbar-closed'
				} ${
					has_user_alerts > 0
						? `has-user-alerts user-alerts-count-${has_user_alerts}`
						: ''
				}`}
			>
				{renderUserAlerts()}
				{place.id !== null && employee !== null && (
					<Navbar
						user={user}
						permissions={permissions}
						history={history}
						navbarOpened={navbarOpened}
						setNavbarOpened={setNavbarOpened}
					/>
				)}
				<div className="main">
					<Switch>
						{/* <Route
						path="/choose"
						render={props => <ChooseEmployee getPlace={getPlace} />}
					/> */}
						<Route
							path="/create-place"
							render={(props) => (
								<>
									<div
										className="topbar no-navbar"
										style={{ top: topbar.top }}
									>
										<div className="actions">
											<button
												className="button button-link"
												onClick={(e) => history.replace('/choose')}
											>
												{language.back}
											</button>
										</div>
									</div>
									<PlaceSetup user={user} {...props} />
								</>
							)}
						/>

						<Route
							path="/tables"
							render={(props) =>
								permissions.indexOf('tables') !== -1 &&
								place.features.indexOf('tables') !== -1 &&
								user.type === 'admin_premium' ? (
									<Tables {...props} />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						{/* <Route
							path="/sales"
							render={(props) =>
								permissions.indexOf('sales') !== -1 ? (
									<Accounting {...props} />
								) : (
									<Redirect to="/" />
								)
							}
						/> */}
						<Route
							path="/orders"
							render={() =>
								user.type !== 'admin_free' ? (
									<Orders />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							path="/stats"
							exact
							render={() =>
								user.type !== 'admin_free' ? (
									<Redirect to="/stats/sales" />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							path="/stats"
							render={() =>
								permissions.indexOf('administrator') !== -1 &&
								user.type !== 'admin_free' ? (
									<Stats />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							path="/chat"
							render={() =>
								place.allow_chat && user.type === 'admin_premium' ? (
									<Chat />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							path="/queue"
							render={() =>
								permissions.indexOf('queue') !== -1 &&
								place.features.indexOf('queue') !== -1 &&
								user.type === 'admin_premium' ? (
									<Queue />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							path="/announcements"
							render={() =>
								user.type === 'admin_premium' ? (
									<Announcements />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							path="/connect/stripe"
							render={() => <StripeConnect to="/" />}
						/>
						<Route
							path="/reservations"
							render={() => {
								if (
									permissions.indexOf('reservations') !== -1 &&
									place.features.indexOf('reservations') !== -1
								) {
									if (
										place.type === 'restaurant' ||
										place.type === 'disco'
									)
										return <Reservations />
									else return <RegularReservations />
								} else return <Redirect to="/" />
							}}
						/>

						{/* <Route
							path="/inventory"
							render={() =>
								permissions.indexOf('inventory') !== -1 &&
								place.features.indexOf('inventory') !== -1 ? (
									<Inventory />
								) : (
									<Redirect to="/" />
								)
							}
						/> */}

						<Route
							path="/products"
							render={() =>
								permissions.indexOf('products') !== -1 &&
								place.features.indexOf('products') !== -1 &&
								!loadingMainData ? (
									<Products />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							path="/checkout"
							render={() =>
								permissions.indexOf('products') !== -1 &&
								place.features.indexOf('products') !== -1 &&
								!loadingMainData &&
								user.type === 'admin_premium' ? (
									<Products />
								) : (
									<Redirect to="/" />
								)
							}
						/>

						<Route
							path="/employees"
							render={() =>
								permissions.indexOf('employees') !== -1 &&
								place.features.indexOf('employees') !== -1 &&
								user.type === 'admin_premium' ? (
									<Employees />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							path="/reservations_covid"
							render={() =>
								permissions.indexOf('queue') !== -1 &&
								place.features.indexOf('reservations_covid') !== -1 &&
								user.type === 'admin_premium' ? (
									<ReservationsCovid />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route path="/feedback" render={() => <Feedback />} />
						<Route
							path="/events"
							render={() =>
								permissions.indexOf('events') !== -1 &&
								place.features.indexOf('events') !== -1 ? (
									<Events place={place} set_events={set_events} />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						{user.type === 'admin_premium' && (
							<Route
								path="/report"
								render={(props) => <Report {...props} />}
							/>
						)}
						{!loadingMainData && (
							<Route
								path="/configuration"
								render={(props) =>
									permissions.indexOf('administrator') !== -1 ? (
										<Configuration
											update_place={update_place}
											user={user}
											place={place}
											{...props}
										/>
									) : (
										<Redirect to="/" />
									)
								}
							/>
						)}
					</Switch>
				</div>
				<Route path="/signin" render={() => <Redirect to="/" />} />
				<Route path="/signup" render={() => <Redirect to="/" />} />
				<Route path="/" exact render={() => <Redirect to="/products" />} />
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	place: state.place,
	user: state.user,
	categories: state.categories,
	permissions: state.permissions,
	promotions: state.promotions,
	schedule: state.schedule,
	employee: state.employee,
	tables: state.tables,
	language: state.language.dictionary,
	topbar: state.topbar,
	reservations: state.reservations,
	pending_orders: state.pending_orders,
	covid: state.covid,
	feedback: state.feedback,
})

export default connect(mapStateToProps, {
	update_place,
	update_employee_categories,
	set_configuration,
	update_product_categories,
	update_products,
	update_tables,
	set_user_permissions,
	update_promotions,
	update_schedule,
	set_report,
	create_alert,
	update_inventory: (payload) => ({
		type: 'UPDATE_INVENTORY',
		payload,
	}),
	set_events: events,
	sign_out,
	update_reservations,
	update_user,
	update_topbar,
	set_pending_orders,
	update_covid,
	update_feedback,
})(Dashboard)

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, Link } from 'react-router-dom'
import Employees from './Employees'
import Schedule from './Schedule'
import Config from './Config'

function Sch({ language, topbar }) {
	const [active, setActive] = useState(0)
	const [renderCreateEmployee, setRenderCreateEmployee] = useState(false)
	const [search, setSearch] = useState('')

	useEffect(() => {
		const links = [
			'/employees',
			'/employees/schedule',
			'/employees/schedule/config',
		]
		links.forEach((id, i) => {
			switch (id) {
				case '/employees':
					if (id === window.location.pathname) setActive(0)
					break
				case '/employees/schedule':
					if (id === window.location.pathname) setActive(1)
					break
				case '/employees/schedule/config':
					if (id === window.location.pathname) setActive(1)
					break
				default:
					if (id === window.location.pathname) setActive(0)
					break
			}
		})
	})

	useEffect(() => {
		if (active > 1) setActive(1)
	}, [active])

	return (
		<>
			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs">
					<li className={`${active === 0 ? 'active' : ''}`}>
						<Link onClick={() => setActive(0)} to="/employees">
							{language.employees}
						</Link>
					</li>
					{/* <li className={`${active === 1 ? 'active' : ''}`}>
						<Link onClick={() => setActive(1)} to="/employees/schedule">
							Schedule
						</Link>
					</li> */}
				</ul>
				<div className="actions">
					{active === 0 && (
						<>
							<div className="search">
								<input
									type="text"
									placeholder={`${language.search}...`}
									onChange={(e) => setSearch(e.target.value)}
									value={search}
								/>
								<i className="mi">search</i>
							</div>
							<button
								onClick={() => setRenderCreateEmployee(true)}
								className="button button-primary button-add inline"
							>
								<i className="mi">add</i>{' '}
								<span>{language.new_employee}</span>
							</button>
						</>
					)}
				</div>
			</div>
			<Switch>
				<Route
					path="/employees/schedule/config"
					// render={(props) => <Redirect to="/employees" />}
					render={(props) => <Config {...props} />}
				/>
				<Route
					path="/employees/schedule"
					// render={(props) => <Redirect to="/employees" />}
					render={(props) => <Schedule {...props} />}
				/>
				<Route
					path="/employees"
					render={(props) => (
						<Employees
							setRenderCreateEmployee={setRenderCreateEmployee}
							renderCreateEmployee={renderCreateEmployee}
							search={search}
							{...props}
						/>
					)}
				/>
			</Switch>
		</>
	)
}

export default connect((state) => ({
	language: state.language.dictionary,
	topbar: state.topbar,
}))(Sch)

const initialState = []

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'SET_EVENTS':
			let val = state
			
			// payload.forEach(pa=>{
			// 	if(pa.)
			// })
			return payload
		case 'ADD_EVENTS':
			let _vls = [...state, ...payload]
			_vls = _vls.reverse()
			let _already = []
			_vls = _vls.filter(vl => {
				if (_already.indexOf(vl.slug) === -1) {
					_already.push(vl.slug)
					return true
				}
				return false
			})
			_vls = _vls.reverse()
			return _vls
		case 'EDIT_EVENT':
			
			let vls = state.map(ev => {
				if (ev.slug !== payload.slug) return ev
				return payload
			})
			vls = vls.reverse()
			let already = []
			vls = vls.filter(vl => {
				if (already.indexOf(vl.slug) === -1) {
					already.push(vl.slug)
					return true
				}
				return false
			})
			vls = vls.reverse()
			return vls
		case 'SIGN_OUT':
			return []
		default:
			return state
	}
}

import es from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { registerLocale } from 'react-datepicker'
import { useHotkeys } from 'react-hotkeys-hook'
import { useIdleTimer } from 'react-idle-timer'
import { connect } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Alert, Input, Loading, ModalForm } from './components'
import { auth, firestore, functions } from './config/firebase'
import lang from './config/lang'
import {
	Dashboard,
	PaymentSetup,
	PlaceSetup,
	RecoverPassword,
	Signin,
} from './routes'
import ConfirmReservation from './routes/ConfirmReservation'
import StripeConnect from './routes/Dashboard/StripeConnect'
import Terms from './routes/Dashboard/Terms'
import Invoice from './routes/Invoice'
import OnlyCollaborator from './routes/OnlyCollaborator'
import {
	clear_alerts,
	create_alert,
	remove_user,
	set_user,
	sign_employee,
	update_dictionary,
	update_subcategories,
	update_user,
} from './store/actions'
import './styles/app.scss'

registerLocale('es', es)

const currentVersion = '5.2.0'
const subscriptions = ['admin_free', 'admin_payasyougo', 'admin_premium']

function App({
	alerts,
	create_alert,
	set_user,
	remove_user,
	user,
	clear_alerts,
	employee,
	language,
	update_dictionary,
	update_subcategories,
	sign_employee,
	update_user,
}) {
	const [placeReady, setPlaceReady] = useState(null)
	const [error, setError] = useState(false)
	const [renderAsCollaborator, setRenderAsCollaborator] = useState(false)
	const [lastUser, setLastUser] = useState(null)
	const [appDisabled, setAppDisabled] = useState(false)
	const [showCookies, setShowCookies] = useState(false)
	const [latestVersion, setLatestVersion] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [cookies, setCookie, removeCookie] = useCookies(['cookies_policy'])

	const [asCollaborator, setAsCollaborator] = useState(
		JSON.parse(localStorage.getItem('collaborator'))
	)

	const handleOnIdle = (event) => {
		console.log('User is IDLE')
		window.location.reload()
	}

	const handleOnActive = (event) => {
		console.log('User is ACTIVE')
	}

	const { getRemainingTime, getLastActiveTime } = useIdleTimer({
		timeout: 1000 * 60 * 15,
		onIdle: handleOnIdle,
		onActive: handleOnActive,
		debounce: 500,
	})

	useHotkeys('ctrl+shift+o', () => {
		// sign_employee(null)
		auth.signOut()
		localStorage.clear()
		window.location.reload()
	})

	useEffect(() => {
		console.log(
			'%cVywap Version 5.2.0',
			'color: cyan; font-family:sans-serif; font-size: 40px;font-weight: bold;'
		)
		console.log(
			'%cContenido para desarrolladores',
			'color: red; font-family:monospace; font-size: 20px'
		)
		let reloads = localStorage.getItem('reloads')
		if (
			reloads !== null &&
			!isNaN(parseInt(reloads)) &&
			parseInt(reloads) > 4
		) {
			auth.signOut()
			localStorage.clear()
			window.location.reload()
		}

		if (
			typeof cookies.cookies_policy !== 'undefined' &&
			cookies.cookies_policy === 'true'
		)
			setShowCookies(false)
		else setShowCookies(true)

		getLanguage()
		getSubcategories()
		getDisabled()
		auth.onAuthStateChanged((user) => {
			if (user) {
				if (!user.emailVerified) {
					user.sendEmailVerification()
				}
			}
			let _asCollaborator = JSON.parse(localStorage.getItem('collaborator'))
			setAsCollaborator(_asCollaborator)
			if (_asCollaborator && user !== null) initializeAppAsCollaborator(user)
			else initializeApp(user)
		})
	}, [])

	useEffect(() => {
		if (asCollaborator || !user) return
		firestore
			.collection('users')
			.doc(user.id)
			.get()
			.then((doc) => {
				if (!doc.exists) return
				if (!deepCompare(doc.data(), lastUser)) {
					setLastUser({ ...doc.data() })
					initializeApp({ ...doc.data(), uid: doc.data().id })
				}
			})
			.catch((e) => {
				console.log('E', e)
			})
	}, [user])

	const getDisabled = async () => {
		try {
			let res = await firestore.collection('config').doc('variables').get()
			setLatestVersion(res.data().web_version)
			setAppDisabled(res.data().web_disabled)
		} catch (e) {
			console.log('ERROR', e)
		}
	}

	// Deep compare function by crazyx > https://stackoverflow.com/questions/1068834/object-comparison-in-javascript
	function deepCompare() {
		var i, l, leftChain, rightChain

		function compare2Objects(x, y) {
			var p

			// remember that NaN === NaN returns false
			// and isNaN(undefined) returns true
			if (
				isNaN(x) &&
				isNaN(y) &&
				typeof x === 'number' &&
				typeof y === 'number'
			) {
				return true
			}

			// Compare primitives and functions.
			// Check if both arguments link to the same object.
			// Especially useful on the step where we compare prototypes
			if (x === y) {
				return true
			}

			// Works in case when functions are created in constructor.
			// Comparing dates is a common scenario. Another built-ins?
			// We can even handle functions passed across iframes
			if (
				(typeof x === 'function' && typeof y === 'function') ||
				(x instanceof Date && y instanceof Date) ||
				(x instanceof RegExp && y instanceof RegExp) ||
				(x instanceof String && y instanceof String) ||
				(x instanceof Number && y instanceof Number)
			) {
				return x.toString() === y.toString()
			}

			// At last checking prototypes as good as we can
			if (!(x instanceof Object && y instanceof Object)) {
				return false
			}

			if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
				return false
			}

			if (x.constructor !== y.constructor) {
				return false
			}

			if (x.prototype !== y.prototype) {
				return false
			}

			// Check for infinitive linking loops
			if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
				return false
			}

			// Quick checking of one object being a subset of another.
			// todo: cache the structure of arguments[0] for performance
			for (p in y) {
				if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
					return false
				} else if (typeof y[p] !== typeof x[p]) {
					return false
				}
			}

			for (p in x) {
				if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
					return false
				} else if (typeof y[p] !== typeof x[p]) {
					return false
				}

				switch (typeof x[p]) {
					case 'object':
					case 'function':
						leftChain.push(x)
						rightChain.push(y)

						if (!compare2Objects(x[p], y[p])) {
							return false
						}

						leftChain.pop()
						rightChain.pop()
						break

					default:
						if (x[p] !== y[p]) {
							return false
						}
						break
				}
			}

			return true
		}

		if (arguments.length < 1) {
			return true //Die silently? Don't know how to handle such case, please help...
			// throw "Need two or more arguments to compare";
		}

		for (i = 1, l = arguments.length; i < l; i++) {
			leftChain = [] //Todo: this can be cached
			rightChain = []

			if (!compare2Objects(arguments[0], arguments[i])) {
				return false
			}
		}

		return true
	}

	const getLanguage = async () => {
		// This was using translate in firebase

		// let dictionary = {}
		// // let savedDictionary = localStorage.getItem('dictionary')
		// let savedDictionary = null

		// if (savedDictionary === null) {
		// 	let col = await firestore.collection('translations').get()
		// 	col.forEach(doc => {
		// 		dictionary[doc.id] = doc.data().translated[language.lang]
		// 	})
		// 	localStorage.setItem('dictionary', JSON.stringify(dictionary))
		// } else {
		// 	dictionary = JSON.parse(savedDictionary)
		// }

		// This is from a local file
		let dictionary = lang

		update_dictionary(dictionary)
	}

	const getSubcategories = async () => {
		try {
			let _subcategories = {}

			let col = await firestore.collection('config').doc('placetypes').get()
			Object.keys(col.data()).forEach((key) => {
				_subcategories[key] = col.data()[key]
			})
			update_subcategories(_subcategories)
		} catch (e) {
			console.log('ERROR', e)
		}
	}

	const initializeAppAsCollaborator = (user) => {
		if (!user) {
			setPlaceReady(false)
			clear_alerts()
			remove_user()
			setRenderAsCollaborator(false)
			localStorage.clear()
			asCollaborator = false
			window.location.reload()
			return
		}
		setRenderAsCollaborator(true)
	}

	const normalizeUserDoc = (userdoc) => ({
		subscriptions: {},
		...userdoc,
		type:
			userdoc.type === null
				? null
				: subscriptions.indexOf(userdoc.type) !== -1
				? userdoc.type
				: null,
	})

	const checkStripePayment = async (usr) => {
		if (usr.type === 'admin_free') return usr.stripe_customer
		const fn = functions.httpsCallable('retreiveCustomer')
		const res = await fn({ customer: usr.stripe_customer.id })
		return res.data
	}

	const validateStripePayment = (usr) => {
		let type = usr.type
		if (type === null) return true
		if (type === 'admin_free') return true
		if (typeof usr.stripe_customer.subscriptions === 'undefined') return false
		if (usr.stripe_customer.subscriptions.total_count === 0) return false

		return usr.stripe_customer.subscriptions.data[0].status === 'active'
	}

	const initializeApp = async (user) => {
		try {
			// If no user is signed in
			if (!user) {
				setPlaceReady(false)
				clear_alerts()
				remove_user()
				return
			}
			let enteredAsCollaborator = JSON.parse(
				localStorage.getItem('collaborator')
			)

			if (enteredAsCollaborator) {
				let newUserDoc = {
					first_name: '',
					last_name: '',
					id: user.uid,
					places: [],
					employees: [],
					stripe_customer: {},
					stripe_subscription: {},

					email: '',
					accept_app_payment: false,
					slug: user.uid,
					owner: true,
					stripe_connected: null,
					phone: user.phoneNumber,
				}
				await firestore.collection('users').doc(user.uid).set(newUserDoc)
				set_user(newUserDoc)
				setPlaceReady(true)
				return
			}

			let userDoc = await firestore.collection('users').doc(user.uid).get()
			if (userDoc.exists) {
				userDoc = userDoc.data()
			} else {
				let newUserDoc = {
					first_name: '',
					last_name: '',
					id: user.uid,
					places: [],
					employees: [],
					stripe_customer: {},
					stripe_subscription: {},
					subscriptions: {},
					email: user.email,
					accept_app_payment: false,
					slug: user.uid,
					owner: true,
					stripe_connected: null,
					phone: user.phoneNumber,
					type: null,
					terms_accepted: true,
				}
				await firestore.collection('users').doc(user.uid).set(newUserDoc)
				userDoc = newUserDoc
			}

			let normalizedUserDoc = normalizeUserDoc(userDoc)

			// Check payment up to date
			// Check every week
			let prevChecked = localStorage.getItem('lastPaymentChecked')
			let reset = false

			if (prevChecked === null) reset = true
			else {
				let date = parseInt(prevChecked)
				let days = 86400000 * 7
				if (isNaN(date)) reset = true
				else {
					let diff = new Date().getTime() - date
					if (diff > days) reset = true
				}
			}

			if (reset) {
				localStorage.setItem(
					'lastPaymentChecked',
					`${new Date().getTime()}`
				)
				let res = await checkStripePayment(normalizedUserDoc)
				await firestore.collection('users').doc(user.uid).update({
					stripe_customer: res,
				})
				normalizedUserDoc = {
					...normalizedUserDoc,
					stripe_customer: res,
				}
			}

			let res = validateStripePayment(normalizedUserDoc)

			set_user(normalizedUserDoc)

			let main_pass = normalizedUserDoc.main_password || null

			if (!res) {
				// setPlaceReady('payment_error')
				setPlaceReady(true)
			} else if (main_pass === null) {
				setPlaceReady('main_password')
			} else {
				checkForPlace(normalizedUserDoc)
			}
		} catch (e) {
			console.error(e)
			setError(true)
			create_alert('Something happened (Retrying)', 'danger')
			setTimeout(() => {
				let reloads = localStorage.getItem('reloads')
				let actual = 1
				if (reloads !== null && !isNaN(parseInt(reloads))) {
					actual = parseInt(reloads) + 1
				}
				localStorage.setItem('reloads', `${actual}`)
				window.location.reload()
			}, 2000)
		}
	}

	const checkForPlace = (_user) => {
		if (_user.type === null) setPlaceReady('subscription')
		else if (_user.places.length === 0) setPlaceReady('place')
		else setPlaceReady(true)
	}

	const renderCookies = () => {
		// if (showCookies)
		if (false)
			return (
				<aside className="cookies-agreement">
					<p>
						Vywap, S.L. utiliza en este Sitio Web cookies propias y de
						terceros para gestionar el portal, mejorar nuestros servicios
						y conocer el comportamiento del usuario mediante el análisis
						de su navegación. Haga clic en ACEPTAR para permitir el uso de
						las mismas. Puede obtener más información en nuestra página de{' '}
					</p>
					<footer>
						<button
							className="button button-primary button-small"
							onClick={() => {
								setCookie('cookies_policy', 'true', { path: '/' })
								setShowCookies(false)
							}}
						>
							Aceptar
						</button>
						<a
							className="button button-light button-small"
							href="https://legal.vywap.com/cookies.html"
							target="_blank"
						>
							Ver Política de cookies
						</a>
					</footer>
				</aside>
			)
		return null
	}

	const renderAlerts = () => {
		let _alerts = alerts.map(({ type, content, id, redirect, sound }) => (
			<Alert
				key={id}
				type={type}
				content={content}
				id={id}
				redirect={redirect}
				sound={sound}
			/>
		))
		return _alerts.length !== 0 ? (
			<div className="toasts">{_alerts}</div>
		) : null
	}

	if (language.dictionary === null || latestVersion === null)
		return <Loading />

	if (currentVersion !== latestVersion)
		return (
			<BrowserRouter>
				{renderCookies()}
				{renderAlerts()}
				<div className="form-ui form-ui-verification">
					<span className="logo"></span>
					<h1>Nueva versión disponible</h1>
					<p className="lead">
						Tenemos una nueva versión disponible con mas funcionalidades y
						arreglos.
					</p>
					<footer className="cols-1">
						<button
							onClick={() => {
								window.location.reload(true)
							}}
							className="button button-primary"
						>
							Actualizar
						</button>
					</footer>
				</div>
				<small>{currentVersion}</small>
			</BrowserRouter>
		)

	if (appDisabled)
		return (
			<div
				className="temporary_disabled"
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<p>Web temporalmente deshabilitada</p>
			</div>
		)

	if (asCollaborator && renderAsCollaborator) {
		if (window.location.pathname.startsWith('/invoice')) return <Invoice />
		return (
			<BrowserRouter>
				<Route
					path="/terms/review"
					render={(props) => <Terms review {...props} />}
				/>
				<Route
					path="/"
					render={(props) => <OnlyCollaborator {...props} />}
				/>
			</BrowserRouter>
		)
	}

	if (error) return <BrowserRouter>{renderAlerts()}</BrowserRouter>
	if (user === null || placeReady === null) return <Loading />

	if (
		auth.currentUser !== null &&
		!auth.currentUser.emailVerified &&
		!user.dummy
	) {
		return (
			<BrowserRouter>
				{renderCookies()}
				{renderAlerts()}
				<div className="form-ui form-ui-verification">
					<span className="logo"></span>
					<h1>Porfavor verifica tu cuenta</h1>
					<p className="lead">
						Revisa tu correo y haz click en el link de verificación. Luego
						regresa a esta ventana y haz click en "Refrescar"
					</p>
					<footer className="cols-2">
						<button
							onClick={() => {
								try {
									auth.currentUser.sendEmailVerification()
									create_alert('Correo reenviado', 'success')
								} catch (e) {
									create_alert('Hubo un error', 'danger')
								}
							}}
							className="button button-primary"
						>
							Reenviar correo
						</button>
						<button
							onClick={() => window.location.reload()}
							className="button button-light"
						>
							Refrescar
						</button>
					</footer>
					<button
						className="button button-link"
						style={{
							marginBottom: 15,
						}}
						onClick={(e) => {
							auth.signOut()
							localStorage.clear()
							window.location.reload()
						}}
					>
						Cerrar sesión
					</button>
				</div>
			</BrowserRouter>
		)
	}

	// When the site needs to connect with stripe
	// if (placeReady === 'stripe')
	// 	return (
	// 		<>
	// 			<BrowserRouter>
	// 				{renderAlerts()}
	// 				<StripeConnect />
	// 			</BrowserRouter>
	// 		</>
	// 	)
	if (placeReady === 'main_password') {
		return (
			<BrowserRouter>
				{renderAlerts()}
				<ModalForm hideClose size="sm">
					<Formik
						initialValues={{
							password: '',
							repassword: '',
						}}
						validate={(values) => {
							if (values.password.length < 6)
								return { password: 'Contraseña muy corta' }
							if (values.password !== values.repassword)
								return { repassword: 'Contraseñas no coinciden' }
						}}
						onSubmit={async (values) => {
							try {
								setSubmitting(true)
								await firestore.doc(`users/${user.id}`).update({
									main_password: values.password,
								})
								update_user({
									main_password: values.password,
								})
							} catch (e) {
								setSubmitting(false)
							}
						}}
					>
						{({ errors, touched }) => (
							<Form className="form-ui">
								<h1>Configura tu contraseña de Admin Principal</h1>
								<p
									className="lead"
									style={{
										marginTop: 20,
									}}
								>
									Esta contraseña se usará para entrar como
									administrador a todos los lugares, y hacer cambios en
									suscripciones y pagos, no la compartas con nadie.
								</p>
								<Input
									label={'Contraseña'}
									disabled={submitting}
									type="password"
									name="password"
									errors={errors}
									touched={touched}
								/>
								<Input
									label={'Contraseña'}
									disabled={submitting}
									type="password"
									name="repassword"
									errors={errors}
									touched={touched}
								/>
								<footer>
									<button
										type="submit"
										disabled={submitting}
										className="button button-primary"
									>
										{submitting && (
											<div
												className="spinner-border spinner-border-sm"
												role="status"
											>
												<span className="sr-only">
													{language.loading}...
												</span>
											</div>
										)}
										{!submitting && (
											<>
												<span>Continuar</span>
											</>
										)}
									</button>
								</footer>
							</Form>
						)}
					</Formik>
				</ModalForm>
			</BrowserRouter>
		)
	} else if (placeReady === 'payment_error')
		return (
			<BrowserRouter>
				{renderAlerts()}
				<div className="form-ui form-ui-verification">
					<span className="logo"></span>
					<h1>Ocurrió un error procesando tu pago</h1>
					<p className="lead">
						Porfavor comunícate con uno de nuestros asesores usando el
						chat ubicado en la parte inferior derecha de la pantalla
					</p>
				</div>
			</BrowserRouter>
		)
	else if (placeReady === 'subscription')
		return (
			<BrowserRouter>
				{renderAlerts()}
				<PaymentSetup />
			</BrowserRouter>
		)
	else if (placeReady === true)
		return (
			<>
				<BrowserRouter>
					{renderAlerts()}
					<Switch>
						<Route
							path="/terms/review"
							render={(props) => <Terms review {...props} />}
						/>
						<Route
							path="/connect/stripe"
							render={(props) => <StripeConnect />}
						/>
						<Route
							path="/"
							render={(props) => (
								<>
									{renderCookies()}
									<Dashboard {...props} />
								</>
							)}
						/>
					</Switch>
				</BrowserRouter>
			</>
		)
	else
		return (
			<>
				<BrowserRouter>
					{renderCookies()}

					{renderAlerts()}
					<Switch>
						<Route
							path="/terms/review"
							render={(props) => <Terms review {...props} />}
						/>
						{user !== false && (
							<Route
								path="/create-place"
								render={(props) => (
									<PlaceSetup
										user={user}
										onPlaceCreated={(p) => setPlaceReady(true)}
										{...props}
									/>
								)}
							/>
						)}
						{placeReady === 'place' && <Redirect to="/create-place" />}
						<Route
							path="/recover-password"
							render={(props) =>
								!user ? (
									<RecoverPassword {...props} />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							path="/signin"
							render={(props) =>
								!user ? <Signin {...props} /> : <Redirect to="/" />
							}
						/>
						<Route
							path="/confirmation/:id/:type"
							render={(props) => <ConfirmReservation {...props} />}
						/>
						<Route
							path="/"
							render={() => {
								const paths = [
									'/signin',
									'/signup',
									'recover-password',
									'pricing',
								]
								if (paths.indexOf(window.location.pathname) === -1) {
									return <Redirect to="/signin" />
								} else {
									return null
								}
							}}
						/>
					</Switch>
				</BrowserRouter>
			</>
		)
}

// Reducers
const mapStateToProps = (state) => ({
	alerts: state.alerts,
	user: state.user,
	employee: state.employee,
	language: state.language,
	subcategories: state.subcategories,
})

// Export
export default connect(mapStateToProps, {
	create_alert,
	set_user,
	remove_user,
	clear_alerts,
	update_dictionary,
	update_subcategories,
	sign_employee,
	update_user,
})(App)

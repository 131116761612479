import addDays from 'date-fns/addDays'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import { Input, ModalForm } from '../../../components'
import { firestore, storage } from '../../../config/firebase'

function CreateEvent({
	place,
	setShowCreateEvent,
	updateEvents,
	language,
	products,
}) {
	const [submitting, setSubmitting] = useState(false)
	const [groups, setGroups] = useState([])
	const [gallery, setGallery] = useState([])
	const [promotions, setPromotions] = useState([])

	const onCreateEvent = async (values) => {
		setSubmitting(true)
		let storageRef = storage.ref()
		let slug = values.name.replace(/ /g, '_')
		slug = slug.toLowerCase()
		slug += `_${Math.floor(Math.random() * 10000)}`

		let imagesDownloadUrls = []

		for (let ph in gallery) {
			const listImageRef = storageRef.child(
				`/events/${place.id}/${slug}/image_${ph}.jpg`
			)
			await listImageRef.putString(gallery[ph].src, 'data_url')
			let dwnUrl = await listImageRef.getDownloadURL()
			imagesDownloadUrls.push(dwnUrl)
		}
		let event = {
			...values,
			startDate: new Date(values.startDate).getTime(),
			endDate: new Date(values.endDate).getTime(),
			groups: groups.map((gr) => ({
				...gr,
				purchased: 0,
			})),
			promotions,
			slug,
			revenue: 0,
			images: imagesDownloadUrls,
			popularity: 0,
		}

		await firestore
			.collection('places')
			.doc(place.id)
			.collection('events')
			.doc(slug)
			.set(event)

		await firestore
			.collection('events_list')
			.doc(slug)
			.set({
				...values,
				popularity: event.popularity,
				images: imagesDownloadUrls,
				slug,
				promotions,
				groups: event.groups,
				startDate: event.startDate,
				endDate: event.endDate,
				place: {
					id: place.id,
					name: place.name,
				},
				area: place.area,
			})

		updateEvents(event)
		setSubmitting(false)
		setShowCreateEvent(false)
	}

	const validateEvent = (values) => {
		let errors = {}
		if (values.name === '') errors.name = language.cant_be_empty
		if (groups.length === 0) errors.groups = language.cant_be_empty
		if (values.age < 0 || values.age - Math.floor(values.age) !== 0)
			errors.age = language.invalid_field
		if (values.endDate === null) errors.endDate = language.invalid_field
		if (values.startDate >= values.endDate)
			errors.endDate = language.invalid_field

		groups.forEach((gr) => {
			if (typeof gr.name !== 'string' || gr.name.length <= 0)
				errors.groups = `${language.invalid_field}`
			if (gr.seats <= 0) errors.groups = `${language.invalid_field}`
			if (gr.price < 0) errors.groups = `${language.invalid_field}`
		})

		return errors
	}

	const onAddImage = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				let img = new Image()
				img.onload = () => {
					setGallery([
						...gallery,
						{ src: reader.result, width: img.width, height: img.height },
					])
				}
				img.src = reader.result
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}

	const getProductsAsOptions = () => {
		return products.map((p) => ({
			label: p.name,
			value: p.id,
		}))
	}

	const renderCreateEvent = () => {
		return (
			<ModalForm toggle={() => setShowCreateEvent(false)}>
				<Formik
					onSubmit={onCreateEvent}
					validate={validateEvent}
					initialValues={{
						name: '',
						description: '',
						startDate: addDays(new Date().setMinutes(0), 1).setHours(18),
						endDate: null,
						groups: '',
						age: 18,
					}}
				>
					{({
						errors,
						touched,
						values,
						setFieldValue,
						setFieldTouched,
					}) => {
						let maxTime = setMinutes(setHours(new Date(), 0), 0)
						return (
							<Form className="form-ui">
								<h1>{language.new_event}</h1>
								<Input
									label={language.name}
									submitting={submitting}
									type="text"
									name="name"
									errors={errors}
									touched={touched}
									cols={8}
									colside="left"
									rowcolleft={3}
									rowcolright={9}
								/>
								<Input
									label={language.minimum_age}
									submitting={submitting}
									type="number"
									name="age"
									errors={errors}
									touched={touched}
									cols={4}
									colside="right"
									rowcolleft={6}
									rowcolright={6}
								/>
								<div className="form-group cols-12">
									<div className="form-row">
										<div>
											<label htmlFor="notes">
												{language.description} :
											</label>
										</div>
										<div>
											<Field
												name="description"
												disabled={submitting}
												component="textarea"
											/>
										</div>
									</div>
								</div>
								<div className="form-group colside-left cols-6">
									<div className="form-row">
										<div className="col-5">
											<label>{language.start_date}</label>
										</div>
										<div className="col-7">
											<DatePicker
												timeCaption={language.hour}
												locale="es"
												disabled={submitting}
												selected={values.startDate}
												onChange={(e) => {
													setFieldValue('startDate', e)
												}}
												showTimeSelect
												timeFormat="HH:mm"
												minDate={addDays(new Date(), 1)}
												maxDate={values.endDate}
												timeIntervals={30}
												dateFormat="dd/MM/yy - HH:mm"
												className="form-control"
											/>
										</div>
									</div>
								</div>
								<div
									className={`form-group  colside-right cols-6 ${
										errors.endDate && touched.endDate
											? 'is-invalid'
											: ''
									}`}
								>
									<div className="form-row">
										<div className="col-5">
											<label>{language.end_date}</label>
										</div>
										<div className="col-7">
											<DatePicker
												locale="es"
												disabled={submitting}
												selected={values.endDate}
												onChange={(e) => {
													setFieldValue('endDate', e)
													setFieldTouched('endDate', true)
												}}
												showTimeSelect
												timeCaption={language.hour}
												timeFormat="HH:mm"
												timeIntervals={30}
												dateFormat="dd/MM/yy - HH:mm"
												className="form-control"
												minDate={values.startDate}
											/>
										</div>
									</div>
									{errors.endDate && touched.endDate ? (
										<small>
											<span>{errors.endDate}</span>
										</small>
									) : null}
								</div>

								{groups.length > 0 && (
									<>
										{groups.map((gr, i) => {
											let fieldset_class = ''
											if (i === 0) fieldset_class += ' first-child'
											if (i === groups.length - 1)
												fieldset_class += ' last-child'
											return (
												<fieldset
													key={gr.id}
													className={fieldset_class}
												>
													<div className="fieldset-header">
														<h2>{gr.name}</h2>
														<button
															disabled={submitting}
															onClick={(e) => {
																e.preventDefault()
																setGroups(
																	groups.filter(
																		(p, j) => j !== i
																	)
																)
															}}
															type="button"
															className="button button-link button-link-sm button-link-delete"
														>
															<i className="mi">delete</i>
															<span>{language.remove}</span>
														</button>
													</div>
													<div className="fieldset-body">
														<Input
															custom={{
																autoComplete: 'off',
																value: gr.name,
																onChange: (e) => {
																	setGroups(
																		groups.map((p, j) => {
																			if (i !== j) return p
																			return {
																				...p,
																				name:
																					e.target.value,
																			}
																		})
																	)
																},
															}}
															label={language.name}
															disabled={submitting}
															type="text"
															name={`name-${i}`}
															errors={errors}
															touched={touched}
															placeholder="Ejm: VIP"
															noformik
														/>
														<Input
															custom={{
																autoComplete: 'off',
																value: isNaN(parseInt(gr.seats))
																	? 0
																	: parseInt(gr.seats),
																onChange: (e) => {
																	setGroups(
																		groups.map((p, j) => {
																			if (i !== j) return p
																			return {
																				...p,
																				seats: isNaN(
																					parseInt(
																						e.target.value
																					)
																				)
																					? 0
																					: parseInt(
																							e.target
																								.value
																					  ),
																			}
																		})
																	)
																},
															}}
															label="Disponibles"
															disabled={submitting}
															type="number"
															name={`seats-${i}`}
															errors={errors}
															touched={touched}
															placeholder="Ejm: 250"
															noformik
														/>
														<div className="form-group upper-7">
															<div className="form-row">
																<div className="col-3">
																	<label>
																		Productos Incluidos
																	</label>
																</div>
																<div className="col-9">
																	<Select
																		placeholder={'Ninguno'}
																		options={getProductsAsOptions()}
																		onChange={(e) => {
																			if (e === null)
																				setGroups(
																					groups.map(
																						(p, j) => {
																							if (
																								i !== j
																							)
																								return p
																							return {
																								...p,
																								products: [],
																							}
																						}
																					)
																				)
																			else
																				setGroups(
																					groups.map(
																						(p, j) => {
																							if (
																								i !== j
																							)
																								return p
																							return {
																								...p,
																								products: e.map(
																									(
																										e
																									) =>
																										e.value
																								),
																							}
																						}
																					)
																				)
																		}}
																		isMulti
																		className="react-select"
																		classNamePrefix="react-select"
																	/>
																</div>
															</div>
														</div>
														{!gr.multiple_prices && (
															<Input
																custom={{
																	autoComplete: 'off',
																	value: isNaN(
																		parseFloat(gr.price)
																	)
																		? 0
																		: parseFloat(gr.price),
																	onChange: (e) => {
																		setGroups(
																			groups.map((p, j) => {
																				if (i !== j)
																					return p
																				return {
																					...p,
																					price: isNaN(
																						parseFloat(
																							e.target
																								.value
																						)
																					)
																						? 0
																						: parseFloat(
																								e.target
																									.value
																						  ),
																				}
																			})
																		)
																	},
																}}
																label={language.price}
																disabled={submitting}
																type="number"
																name={`price-${i}`}
																errors={errors}
																touched={touched}
																placeholder="24"
																noformik
																append={
																	<i className="mi">
																		euro_symbol
																	</i>
																}
															/>
														)}
														{gr.multiple_prices &&
															gr.prices.map((pt, ind) => (
																<>
																	<Input
																		custom={{
																			autoComplete: 'off',
																			value:
																				gr.prices[ind].name,
																			onChange: (e) => {
																				setGroups(
																					groups.map(
																						(p, j) => {
																							if (
																								i !== j
																							)
																								return p
																							return {
																								...p,
																								prices: p.prices.map(
																									(
																										pc,
																										pi
																									) => {
																										if (
																											pi !==
																											ind
																										)
																											return pc
																										return {
																											...pc,
																											name:
																												e
																													.target
																													.value,
																										}
																									}
																								),
																							}
																						}
																					)
																				)
																			},
																		}}
																		label={'Nombre'}
																		disabled={submitting}
																		type="text"
																		name={`price-name-${i}-${ind}`}
																		errors={errors}
																		touched={touched}
																		placeholder="Ejm. Antes de las 22:00"
																		noformik
																		cols={6}
																		colside={'left'}
																	/>
																	<Input
																		custom={{
																			autoComplete: 'off',
																			value:
																				gr.prices[ind]
																					.price,
																			onChange: (e) => {
																				setGroups(
																					groups.map(
																						(p, j) => {
																							if (
																								i !== j
																							)
																								return p
																							return {
																								...p,
																								prices: p.prices.map(
																									(
																										pc,
																										pi
																									) => {
																										if (
																											pi !==
																											ind
																										)
																											return pc
																										return {
																											...pc,
																											price: isNaN(
																												parseFloat(
																													e
																														.target
																														.value
																												)
																											)
																												? 0
																												: parseFloat(
																														e
																															.target
																															.value
																												  ),
																										}
																									}
																								),
																							}
																						}
																					)
																				)
																			},
																		}}
																		label={'Precio'}
																		disabled={submitting}
																		type="text"
																		name={`price-numb-${i}-${ind}`}
																		errors={errors}
																		touched={touched}
																		placeholder="15"
																		noformik
																		cols={6}
																		colside="right"
																		append={
																			<i className="mi">
																				euro_symbol
																			</i>
																		}
																	/>
																</>
															))}
														{gr.multiple_prices && (
															<button
																type="button"
																className="button button-danger-outline button-small"
																style={{
																	marginTop: 20,
																	marginLeft: 20,
																	marginRight: 20,
																}}
																onClick={() => {
																	if (gr.prices.length === 2) {
																		setGroups(
																			groups.map((p, j) => {
																				if (i !== j)
																					return p
																				return {
																					...p,
																					multiple_prices: false,
																					prices: [],
																				}
																			})
																		)
																	} else {
																		setGroups(
																			groups.map((p, j) => {
																				if (i !== j)
																					return p
																				let prcs = [
																					...p.prices,
																				]
																				prcs.pop()

																				return {
																					...p,
																					multiple_prices: true,
																					prices: prcs,
																				}
																			})
																		)
																	}
																}}
															>
																<i className="mi">delete</i>
																Eliminar ultimo precio
															</button>
														)}
														<button
															type="button"
															className="button button-light button-small"
															style={{
																marginTop: 20,
																marginLeft: 20,
																marginRight: 20,
															}}
															onClick={() => {
																if (gr.multiple_prices) {
																	setGroups(
																		groups.map((p, j) => {
																			if (i !== j) return p
																			return {
																				...p,
																				multiple_prices: true,
																				prices: [
																					...p.prices,
																					{
																						name: '',
																						price: 0,
																					},
																				],
																			}
																		})
																	)
																} else {
																	setGroups(
																		groups.map((p, j) => {
																			if (i !== j) return p
																			return {
																				...p,
																				multiple_prices: true,
																				prices: [
																					{
																						name: '',
																						price: 0,
																					},
																					{
																						name: '',
																						price: 0,
																					},
																				],
																			}
																		})
																	)
																}
															}}
														>
															Agregar otro precio
														</button>
													</div>
												</fieldset>
											)
										})}
									</>
								)}

								<div className="adder">
									<button
										disabled={submitting}
										type="button"
										className="button button-light"
										onClick={() =>
											setGroups([
												...groups,
												{
													name: '',
													seats: 0,
													price: 0,
													multiple_prices: false,
													prices: [],
													products: [],
													id: Math.floor(Math.random() * 10000),
												},
											])
										}
									>
										<i className="mi">add</i>{' '}
										<span>{language.new_area}</span>
									</button>
								</div>
								{/* <div className="alert alert-primary">
									<p>
										Las areas son los tipos de entradas que hay en tu
										evento, por ejemplo VIP, General, Terraza, Etc.
									</p>
								</div> */}

								{gallery.map((img, i) => {
									let fieldset_class = ''
									if (i === 0) fieldset_class += ' first-child'
									if (i === groups.length - 1)
										fieldset_class += ' last-child'
									return (
										<fieldset
											key={Math.floor(Math.random() * 10000)}
											className={`${fieldset_class} fieldset-image`}
										>
											<div className="fieldset-header">
												<h2></h2>
												<button
													className="button button-link button-link-sm button-link-delete"
													type="button"
													onClick={(e) => {
														setGallery(
															gallery.filter((a, b) => b !== i)
														)
													}}
												>
													<i className="mi">delete</i>
													<span>{language.remove}</span>
												</button>
											</div>
											<img src={img.src}></img>
										</fieldset>
									)
								})}

								<div className="adder">
									<label
										className="button button-light"
										htmlFor="addImageInput"
									>
										<i className="mi">add</i>
										<span>{language.add_image}</span>
									</label>
								</div>

								<input
									disabled={submitting}
									id="addImageInput"
									type="file"
									accept="image/x-png,image/jpeg"
									onChange={onAddImage}
								/>

								{promotions.length > 0 && (
									<>
										{promotions.map((pr, i) => {
											let fieldset_class = ''
											if (i === 0) fieldset_class += ' first-child'
											if (i === promotions.length - 1)
												fieldset_class += ' last-child'
											return (
												<fieldset
													key={pr.id}
													className={fieldset_class}
												>
													<div className="fieldset-header">
														<h2>{pr.name}</h2>
														<button
															disabled={submitting}
															type="button"
															onClick={(e) => {
																e.preventDefault()
																setPromotions(
																	promotions.filter(
																		(p, j) => j !== i
																	)
																)
															}}
															className="button button-link button-link-sm button-link-delete"
														>
															<i className="mi">delete</i>
															<span>{language.remove}</span>
														</button>
													</div>
													<div className="fieldset-body">
														<div className="form-group">
															<div className="form-row">
																<div className="col-3">
																	<label>
																		{language.name}:
																	</label>
																</div>
																<div className="col-9">
																	<input
																		disabled={submitting}
																		autoComplete="off"
																		value={pr.name}
																		onChange={(e) => {
																			setPromotions(
																				promotions.map(
																					(p, j) => {
																						if (i !== j)
																							return p
																						return {
																							...p,
																							name:
																								e.target
																									.value,
																						}
																					}
																				)
																			)
																		}}
																		className="form-control"
																		type="text"
																		placeholder={
																			language.promotion_label_example
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="form-group colside-left cols-6">
															<div className="form-row has-append">
																<span className="append">
																	<i>%</i>
																</span>
																<div className="col-5">
																	<label>
																		{language.discount}:
																	</label>
																</div>
																<div className="col-7">
																	<input
																		disabled={submitting}
																		className="form-control"
																		value={parseInt(
																			pr.discount
																		)}
																		onChange={(e) => {
																			setPromotions(
																				promotions.map(
																					(p, j) => {
																						if (i !== j)
																							return p
																						return {
																							...p,
																							discount:
																								e.target
																									.value,
																						}
																					}
																				)
																			)
																		}}
																		type="number"
																		placeholder="Ejm: 30%"
																	/>
																</div>
															</div>
														</div>
														<div className="form-group colside-right cols-6">
															<div className="form-row">
																<div className="col-4">
																	<label>
																		{language.code}:
																	</label>
																</div>
																<div className="col-8">
																	<input
																		disabled={submitting}
																		className="form-control"
																		value={pr.code}
																		onChange={(e) => {
																			setPromotions(
																				promotions.map(
																					(p, j) => {
																						if (i !== j)
																							return p
																						return {
																							...p,
																							code:
																								e.target
																									.value,
																						}
																					}
																				)
																			)
																		}}
																		type="text"
																		placeholder="OCTOPROMO"
																	/>
																</div>
															</div>
														</div>
													</div>
												</fieldset>
											)
										})}
									</>
								)}
								<div className="adder">
									<button
										disabled={submitting}
										type="button"
										className="button button-light"
										onClick={() =>
											setPromotions([
												...promotions,
												{
													name: '',
													discount: 0,
													code: '',
													id: Math.floor(Math.random() * 10000),
												},
											])
										}
									>
										<i className="mi">add</i>
										<span>{language.new_promotion}</span>
									</button>
								</div>
								<Field
									name="groups"
									type="text"
									style={{ display: 'none' }}
								/>
								<footer>
									{errors.groups && touched.groups && (
										<p className="notice danger">{errors.groups}</p>
									)}
									<button
										type="submit"
										disabled={submitting}
										className="button button-primary"
									>
										{submitting && (
											<div
												className="spinner-border spinner-border-sm"
												role="status"
											>
												<span className="sr-only">
													{language.loading}...
												</span>
											</div>
										)}
										{!submitting && (
											<>
												<i className="mi">arrow_forward</i>
												<span>{language.continue}</span>
											</>
										)}
									</button>
								</footer>
							</Form>
						)
					}}
				</Formik>
			</ModalForm>
		)
	}

	return <>{renderCreateEvent()}</>
}

export default connect((state) => ({
	place: state.place,
	language: state.language.dictionary,
	products: state.products,
}))(CreateEvent)

export default (
	values
) => `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html>
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  <meta property="og:title" content="${values.subject}" />
  <title>${values.subject}</title>
  <style type="text/css">
    #outlook a {
      padding: 0;
    }
    body {
      width: 100% !important;
    }
    .ReadMsgBody {
      width: 100%;
    }
    .ExternalClass {
      width: 100%;
    }
    body {
      -webkit-text-size-adjust: none;
    }
    body {
      margin: 0;
      padding: 0;
    }
    img {
      border: 0;
      height: auto;
      line-height: 100%;
      outline: 0;
      text-decoration: none;
    }
    table td {
      border-collapse: collapse;
    }
    #backgroundTable {
      height: 100% !important;
      margin: 0;
      padding: 0;
      width: 100% !important;
    }
    #backgroundTable,
    body {
      background-color: #fafafa;
    }
    #templateContainer {
      border: 1px solid #ddd;
    }
    .h1,
    h1 {
      color: #202020;
      display: block;
      font-family: Arial;
      font-weight: 700;
      line-height: 100%;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 10px;
      margin-left: 0;
    }
    #templateHeader {
      background-color: #fff;
      border-bottom: 0;
    }
    .headerContent {
      color: #202020;
      font-family: Arial;
      font-size: 34px;
      font-weight: 700;
      line-height: 100%;
      padding: 0;
      text-align: center;
      vertical-align: middle;
    }
    .headerContent a .yshortcuts,
    .headerContent a:link,
    .headerContent a:visited {
      color: #369;
      font-weight: 400;
      text-decoration: underline;
    }
    #headerImage {
      height: auto;
      max-width: 600px !important;
    }
    #templateContainer,
    .bodyContent {
      background-color: #fff;
    }
    .bodyContent div {
      color: #505050;
      font-family: Arial;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
    }
    .bodyContent div a .yshortcuts,
    .bodyContent div a:link,
    .bodyContent div a:visited {
      color: #369;
      font-weight: 400;
      text-decoration: underline;
    }
    .templateButton {
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      background-color: #369;
      border: 0;
      border-collapse: separate !important;
      border-radius: 3px;
    }
    .templateButton,
    .templateButton a .yshortcuts,
    .templateButton a:link,
    .templateButton a:visited {
      color: #fff;
      font-family: Arial;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: -0.5px;
      line-height: 100%;
      text-align: center;
      text-decoration: none;
    }
    .bodyContent img {
      display: inline;
      height: auto;
    }
    #templateFooter {
      background-color: #fff;
      border-top: 0;
    }
    .footerContent div {
      color: #707070;
      font-family: Arial;
      font-size: 12px;
      line-height: 125%;
      text-align: center;
    }
    .footerContent div a .yshortcuts,
    .footerContent div a:link,
    .footerContent div a:visited {
      color: #369;
      font-weight: 400;
      text-decoration: underline;
    }
    .footerContent img {
      display: inline;
    }
    #utility {
      background-color: #fff;
      border: 0;
    }
    #utility div {
      text-align: center;
    }
    #monkeyRewards img {
      max-width: 190px;
    }
    .reservation-receipt-ui {
      font-family: sans-serif;
      background-color: #fff;
      font-size: 20px;
      color: #000;
      text-align: center;
      width: 600px;
      top: -9999px;
    }
    .reservation-receipt-ui img {
      width: 260px;
      height: auto;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .reservation-receipt-ui h1 {
      line-height: 1.2;
    }
    .reservation-receipt-ui h1 small {
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      display: block;
      color: #757575;
    }
    .reservation-receipt-ui h1 span {
      font-size: 28px;
    }
    .reservation-receipt-ui small.ref {
      font-size: 15px;
    }
    .reservation-receipt-ui small.ref span {
      color: #757575;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
    }
    .reservation-receipt-ui p {
      margin: 0;
      line-height: 1.2;
      align-items: center;
      margin-top: 15px;
      padding: 0;
    }
    .reservation-receipt-ui p span {
      display: block;
      font-size: 14px;
      color: #757575;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 0.4px;
    }
    .reservation-receipt-ui p strong {
      display: block;
    }
  </style>
</head>
<body
  leftmargin="0"
  marginwidth="0"
  topmargin="0"
  marginheight="0"
  offset="0"
  style="
    -webkit-text-size-adjust: none;
    margin: 0;
    padding: 0;
    background-color: #fafafa;
    width: 100% !important;
  "
>
  <center>
    <table
      border="0"
      cellpadding="0"
      cellspacing="0"
      height="100%"
      width="100%"
      id="backgroundTable"
      style="
        margin: 0;
        padding: 0;
        background-color: #fafafa;
        height: 100% !important;
        width: 100% !important;
      "
    >
      <tr>
        <td
          align="center"
          valign="top"
          style="padding-top: 20px; border-collapse: collapse;"
        >
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="600"
            id="templateContainer"
            style="border: 1px solid #dddddd; background-color: #ffffff;"
          >
            <tr>
              <td
                align="center"
                valign="top"
                style="border-collapse: collapse;"
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="600"
                  id="templateBody"
                >
                  <tr>
                    <td valign="top" style="border-collapse: collapse;">
                      <table
                        border="0"
                        cellpadding="20"
                        cellspacing="0"
                        width="100%"
                      >
                        <tr>
                          <td
                            valign="top"
                            class="bodyContent"
                            style="
                              border-collapse: collapse;
                              background-color: #ffffff;
                            "
                          >
                            <div
                              mc:edit="std_content00"
                              style="
                                color: #505050;
                                font-family: Arial;
                                font-size: 14px;
                                line-height: 150%;
                                text-align: center;
                              "
                            >
                              <div
                                class="reservation-receipt-ui"
                                id="PRINT_COVID"
                                style="
                                  font-family: Arial;
                                  background-color: #fff;
                                  font-size: 14px;
                                  color: #505050;
                                  text-align: center;
                                  width: 600px;
                                  top: -9999px;
                                  line-height: 150%;
                                "
                              >
                                <div
                                  style="
                                    color: #505050;
                                    font-family: Arial;
                                    font-size: 14px;
                                    line-height: 150%;
                                    text-align: center;
                                  "
                                >
                                  <div
                                    style="
                                      color: #505050;
                                      font-family: Arial;
                                      font-size: 14px;
                                      line-height: 150%;
                                      text-align: center;
                                    "
                                  >
                                    <div
                                      style="
                                        color: #505050;
                                        font-family: Arial;
                                        font-size: 14px;
                                        line-height: 150%;
                                        text-align: center;
                                      "
                                    >
                                      <img
                                        src="${values.image}"
                                        style="
                                          border: 0;
                                          height: auto;
                                          line-height: 100%;
                                          outline: none;
                                          text-decoration: none;
                                          display: inline;
                                          width: 260px;
                                          margin-top: 20px;
                                          margin-bottom: 30px;
                                        "
                                      />
                                      <p
                                        style="
                                          margin: 0;
                                          line-height: 1.2;
                                          align-items: center;
                                          margin-top: 15px;
                                          padding: 0;
                                        "
                                      >
                                        <strong style="display: block;"
                                          >${values.content}</strong
                                        >
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <br />
        </td>
      </tr>
    </table>
  </center>
</body>
</html>
`

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	AreaChart,
	Area,
	Line,
	LineChart,
	ResponsiveContainer,
	Text,
	Label
} from 'recharts'

import { firestore } from '../../../config/firebase'

function Products({ place, language, date, data, products }) {
	const [popProducts, setPopProducts] = useState(null)

	useEffect(() => {
		getPopProducts(data)
	}, [])

	useEffect(() => {
		if (data !== null) getPopProducts(data)
	}, [date])

	const upper = lower => lower.charAt(0).toUpperCase() + lower.substring(1)

	const getPopProducts = data => {
		let prods = products.map(prod => {
			let quantity = 0
			let data_prod = data.products[prod.id]
			if (typeof data_prod !== 'undefined') {
				quantity += data_prod.ordered
			}
			return {
				name: prod.name,
				quantity
			}
		})
		prods = prods.filter(prod => prod.quantity > 0)
		prods = prods.sort((a, b) => b.quantity - a.quantity)
		prods = prods.filter((a, b) => b < 10)
		setPopProducts(prods)
	}

	if(popProducts === null)
		return <aside className="empty"><i className="mi">insert_chart</i><h3>No hay estadísticas de productos.</h3></aside>


	return (
		<>
			<div className="ui-stats">
				{popProducts !== null && (
					<div className={`stat stat-wide stat-h-${popProducts.length}`}>
						<div>
							<h1>Productos populares</h1>
							<div className="wrapper">
								<ResponsiveContainer width="100%">
									<BarChart data={popProducts} layout="vertical">
										<XAxis
											dy={5}
											type="number"
											domain={[0, 'dataMax + 5']}
										/>
										<YAxis
											dx={-5}
											dataKey="name"
											type="category"
											width={200}
										/>
										<CartesianGrid
											strokeDasharray="1 0"
											stroke="#dee2e6"
										/>
										<Tooltip
											isAnimationActive={false}
											cursor={{
												stroke: '#000',
												strokeWidth: 0,
												fill: 'rgba(0,0,0,0.05)'
											}}
										/>
										<Bar
											radius={[0, 10, 10, 0]}
											dataKey="quantity"
											fill="#2589f9"
											legendType="none"
											name="Pedidos totales"
										/>
									</BarChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default connect(state => ({
	place: state.place,
	language: state.language.dictionary,
	products: state.products
}))(Products)

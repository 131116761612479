import { useState } from 'react'
import { connect } from 'react-redux'
import Input from '../../../../../components/Input'
import { firestore, storage } from '../../../../../config/firebase'
import { create_alert } from '../../../../../store/actions'
import FeatImage from './FeatImage'
import ListImage from './ListImage'

function App({ place, create_alert, language, user }) {
	const [listImage, setListImage] = useState(null)
	const [featImage, setFeatImage] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [badge, setBadge] = useState(place.badge || '')
	const [minimum, setMinimum] = useState(place.minimum_order || 0)
	const [phone, setPhone] = useState(place.phone || 0)
	const [allowChat, setAllowChat] = useState(place.allow_chat || false)
	const [requireQueueQr, setRequireQueueQr] = useState(
		place.require_queue_qr || false
	)

	const submit = async () => {
		setSubmitting(true)
		const storageRef = storage.ref()

		let listUrl = null
		let featUrl = null

		// Upload list image
		if (listImage !== null) {
			const listImageRef = storageRef.child(`/list_images/${place.id}`)
			await listImageRef.putString(listImage, 'data_url')
			listUrl = await listImageRef.getDownloadURL()
		}

		// Upload feat image
		if (featImage !== null) {
			const featImageRef = storageRef.child(`/featured_images/${place.id}`)
			await featImageRef.putString(featImage, 'data_url')
			featUrl = await featImageRef.getDownloadURL()
		}

		let newImages = {}
		if (listUrl !== null) newImages.list_image = listUrl
		if (featUrl !== null) newImages.featured_image = featUrl

		await firestore
			.collection('places')
			.doc(place.id)
			.update({
				...newImages,
				badge,
				minimum_order: minimum,
				phone,
				allow_chat: allowChat,
				require_queue_qr: requireQueueQr,
			})
		await firestore
			.collection('places_list')
			.doc(place.id)
			.update({
				...newImages,
				badge,
				minimum_order: minimum,
				phone,
				allow_chat: allowChat,
				require_queue_qr: requireQueueQr,
			})
		create_alert('Información actualizada', 'success')
		setSubmitting(false)
	}

	return (
		<div className="login-ui login-ui-lg">
			<div>
				{place.id !== null && (
					<form
						className="form-ui"
						onSubmit={(e) => {
							e.preventDefault()
							submit()
						}}
					>
						<h1>{language.app}</h1>

						<Input
							label="Teléfono"
							name={`phone_${place.id}`}
							submitting={submitting}
							disabled={user.type !== 'admin_premium'}
							noformik
							type="number"
							custom={{
								onChange: (e) => setPhone(parseInt(e.target.value)),
								value: phone,
							}}
						/>
						<Input
							label="Pedido mínimo"
							name={`min_${place.id}`}
							placeholder="0"
							submitting={submitting}
							noformik
							type="number"
							append={<i className="mi">euro_symbol</i>}
							custom={{
								onChange: (e) => setMinimum(parseFloat(e.target.value)),
								value: minimum,
							}}
						/>
						<Input
							label="Destacado"
							name={`badge_${place.id}`}
							placeholder="Ej: Promociones de Verano"
							type="text"
							submitting={submitting}
							noformik
							custom={{
								onChange: (e) => setBadge(`${e.target.value}`),
								value: badge,
							}}
						/>

						<small
							style={{
								width: '100%',
								textAlign: 'center',
								marginTop: 10,
								marginBottom: 40,
							}}
						>
							Este texto se mostrará junto a tu nombre en la App
						</small>

						{user.type === 'admin_premium' && (
							<>
								<div className="form-group form-group-check">
									<div className="form-row">
										<div className="col-12">
											<input
												disabled={user.type !== 'admin_premium'}
												type="checkbox"
												name="allowChat"
												id="allowChat"
												checked={allowChat}
												onChange={(e) => setAllowChat(!allowChat)}
											/>
											<label htmlFor="allowChat">
												<b></b>{' '}
												<span>
													Habilitar soporte y chat desde la App
												</span>
											</label>
										</div>
									</div>
								</div>
								<div className="form-group form-group-check">
									<div className="form-row">
										<div className="col-12">
											<input
												disabled={user.type !== 'admin_premium'}
												type="checkbox"
												name="requireQueueQr"
												id="requireQueueQr"
												checked={requireQueueQr}
												onChange={(e) =>
													setRequireQueueQr(!requireQueueQr)
												}
											/>
											<label htmlFor="requireQueueQr">
												<b></b>{' '}
												<span>Requerir QR para hacer cola</span>
											</label>
										</div>
									</div>
								</div>
							</>
						)}
						<ListImage
							setListImage={setListImage}
							place={place}
							language={language}
						/>
						<FeatImage
							setFeatImage={setFeatImage}
							place={place}
							language={language}
						/>
						<footer>
							<button
								disabled={submitting}
								type="submit"
								className="button button-primary"
							>
								{submitting && (
									<div role="status">
										<span>{language.loading}...</span>
									</div>
								)}
								{!submitting && language.save}
							</button>
						</footer>
					</form>
				)}
			</div>
		</div>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		language: state.language.dictionary,
		user: state.user,
	}),
	{ create_alert }
)(App)

import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { Input, ModalForm } from '../../../../../components'
import axios from 'axios'
import { firestore } from '../../../../../config/firebase'

export default function Translate({
	item,
	toggle,
	place,
	products,
	update_products,
}) {
	const langs = ['en', 'fr', 'de', 'it']

	const [submitting, setSubmitting] = useState(false)
	const [initial, setInitial] = useState(null)

	useEffect(() => {
		let val = {}
		langs.forEach((lang) => {
			val[lang] = {
				name:
					typeof item.translation === 'undefined' ||
					item.translation === null
						? null
						: typeof item.translation[lang] === 'undefined'
						? null
						: item.translation[lang].name || null,
				description:
					typeof item.translation === 'undefined' ||
					item.translation === null
						? null
						: typeof item.translation[lang] === 'undefined'
						? null
						: item.translation[lang].description || null,
				additions:
					typeof item.translation === 'undefined' ||
					item.translation === null
						? null
						: typeof item.translation[lang] === 'undefined'
						? null
						: item.translation[lang].additions || null,
			}
		})
		setInitial(val)
	}, [])

	const getLanguageName = (lang) => {
		switch (lang) {
			case 'es':
				return 'Español'
			case 'en':
				return 'Inglés'
			case 'fr':
				return 'Francés'
			case 'de':
				return 'Alemán'
			case 'it':
				return 'Italiano'
		}
	}

	const validate = (values) => {}

	const onSubmit = async (values) => {
		setSubmitting(true)
		let withContent = {}

		Object.keys(values).forEach((key) => {
			if (values[key].name !== null) withContent[key] = values[key]
		})
		// UPDATE IN PLACE
		await firestore.doc(`/places/${place.id}/products/${item.id}`).update({
			translation: withContent,
		})
		// UPDATE IN PLACE LIST
		let nu_products = products.map((prod) => {
			if (prod.id !== item.id) return prod
			return {
				...prod,
				translation: withContent,
			}
		})
		await firestore.doc(`/places_list/${place.id}`).update({
			products: nu_products,
		})

		update_products(nu_products)
		setSubmitting(false)
		toggle()
	}

	const stripHtml = (html) => {
		var tmp = document.createElement('DIV')
		tmp.innerHTML = html
		return tmp.textContent || tmp.innerText || ''
	}

	const translateTo = async (lang, setFieldValue) => {
		setSubmitting(true)
		let originalValues = [item.name, item.description]
		let translatedValues = []
		for (const value of originalValues) {
			if (typeof value !== 'string' || value.length < 3) {
				translatedValues.push(value)
			} else {
				let res = await axios.post(
					`https://www.googleapis.com/language/translate/v2?key=AIzaSyA4mvs2CtraG-0xgBzoUG7GACUvxoaNjQs&source=es&target=${lang}&q=${value}`
				)
				let val = res.data.data.translations[0].translatedText
				translatedValues.push(val)
			}
		}
		setFieldValue(`${lang}.name`, stripHtml(translatedValues[0]))
		setFieldValue(`${lang}.description`, stripHtml(translatedValues[1]))

		// Translate additions
		for (let x = 0; x < item.additions.length; x++) {
			const add = item.additions[x]
			let translatedName = await axios.post(
				`https://www.googleapis.com/language/translate/v2?key=AIzaSyA4mvs2CtraG-0xgBzoUG7GACUvxoaNjQs&source=es&target=${lang}&q=${add.name}`
			)
			translatedName =
				translatedName.data.data.translations[0].translatedText
			setFieldValue(
				`${lang}.additions[${x}].name`,
				stripHtml(translatedName)
			)
			for (let y = 0; y < add.items.length; y++) {
				const it = add.items[y]
				let translatedItem = await axios.post(
					`https://www.googleapis.com/language/translate/v2?key=AIzaSyA4mvs2CtraG-0xgBzoUG7GACUvxoaNjQs&source=es&target=${lang}&q=${it.name}`
				)
				translatedItem =
					translatedItem.data.data.translations[0].translatedText
				setFieldValue(
					`${lang}.additions[${x}].items[${y}]`,
					stripHtml(translatedItem)
				)
			}
		}

		setSubmitting(false)
	}

	if (initial === null) return null

	return (
		<>
			<ModalForm toggle={toggle}>
				<Formik
					validateOnBlur={false}
					validateOnChange={false}
					initialValues={initial}
					validate={validate}
					onSubmit={onSubmit}
				>
					{({ errors, touched, setFieldValue, values }) => {
						return (
							<Form
								className="form-ui"
								onKeyPress={(e) => {
									if (e.charCode === 13) {
										e.preventDefault()
									}
								}}
							>
								<h1>Traducir Producto {item.name}</h1>

								{Object.keys(values).map((lang) => {
									//
									if (values[lang].name === null)
										return (
											<div className="adder">
												<button
													type="button"
													className="button button-light"
													onClick={() =>
														translateTo(lang, setFieldValue)
													}
												>
													<i className="mi">add</i>
													<span>
														Traducir a {getLanguageName(lang)}
													</span>
												</button>
											</div>
										)
									return (
										<>
											<fieldset className="first-child last-child">
												<div className="fieldset-header">
													<h2>{getLanguageName(lang)}</h2>
													{/* <button className="button button-link button-link-sm button-link-delete">
														<i className="mi">delete</i>
														<span>Eliminar traducción</span>
													</button> */}
												</div>
												<div className="fieldset-body">
													<Input
														label={`Nombre`}
														disabled={submitting}
														type="text"
														name={`${lang}.name`}
														errors={errors}
														touched={touched}
													/>
													<Input
														label={`Descripción`}
														disabled={submitting}
														type="text"
														name={`${lang}.description`}
														errors={errors}
														touched={touched}
													/>
													{values[lang].additions !== null &&
														values[lang].additions.map(
															(add, x) => {
																return (
																	<div className="form-inner-box">
																		<Input
																			label={`Opcional #${
																				x + 1
																			}`}
																			disabled={submitting}
																			type="text"
																			name={`${lang}.additions[${x}].name`}
																			errors={errors}
																			touched={touched}
																		/>
																		<div className="indent">
																			{typeof add.items !==
																				'undefined' &&
																				add.items.map(
																					(item, y) => (
																						<Input
																							label={`Item #${
																								y + 1
																							}`}
																							disabled={
																								submitting
																							}
																							type="text"
																							name={`${lang}.additions[${x}].items[${y}]`}
																							errors={
																								errors
																							}
																							touched={
																								touched
																							}
																						/>
																					)
																				)}
																		</div>
																	</div>
																)
															}
														)}
												</div>
											</fieldset>
											<hr />
										</>
									)
								})}
								<footer className="cols-2">
									<button
										disabled={submitting}
										type="button"
										onClick={async () => {
											setSubmitting(true)
											// UPDATE IN PLACE
											await firestore
												.doc(
													`/places/${place.id}/products/${item.id}`
												)
												.update({
													translation: null,
												})
											// UPDATE IN PLACE LIST
											let nu_products = products.map((prod) => {
												if (prod.id !== item.id) return prod
												return {
													...prod,
													translation: null,
												}
											})
											await firestore
												.doc(`/places_list/${place.id}`)
												.update({
													products: nu_products,
												})

											update_products(nu_products)
											setSubmitting(false)
											toggle()
										}}
										className="button button-light"
									>
										{submitting && (
											<div role="status">
												<span>Cargando ...</span>
											</div>
										)}
										{!submitting && 'Borrar traducciones'}
									</button>
									<button
										disabled={submitting}
										type="submit"
										className="button button-primary"
									>
										{submitting && (
											<div role="status">
												<span>Cargando ...</span>
											</div>
										)}
										{!submitting && 'Guardar'}
									</button>
								</footer>
							</Form>
						)
					}}
				</Formik>
			</ModalForm>
		</>
	)
}

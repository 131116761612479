import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, Route, Switch, Redirect } from 'react-router-dom'
import Accepted from './Accepted'
import Pending from './Pending'
import History from './History'
import Helmet from 'react-helmet'

function Orders({
	history,
	place,
	user,
	update_place,
	language,
	topbar,
	categories,
	pending_orders,
}) {
	const [active, setActive] = useState(0)
	const [showTutorial, setShowTutorial] = useState(false)

	useEffect(() => {
		let hideTutorial = localStorage.getItem('orders_tutorial')
		setShowTutorial(!hideTutorial)
	}, [])

	useEffect(() => {
		const arrayOfLinks = ['accepted', 'pending', 'history']
		let pathname = window.location.pathname
		pathname = pathname.split('/')[2]
		let index = 0

		arrayOfLinks.forEach((id, i) => {
			if (id === pathname) {
				index = i
			}
		})

		setActive(index)
	})

	return (
		<>
			<Helmet>
				<title>Vywap | Orders</title>
			</Helmet>

			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs">
					<li className={active === 0 ? 'active' : ''}>
						<Link to="/orders/accepted" onClick={(e) => setActive(0)}>
							Confirmadas
						</Link>
					</li>
					<li className={active === 1 ? 'active' : ''}>
						<Link to="/orders/pending" onClick={(e) => setActive(1)}>
							Pendientes
						</Link>
						{pending_orders > 0 && <pre>{pending_orders}</pre>}
					</li>
					<li className={active === 2 ? 'active' : ''}>
						<Link to="/orders/history" onClick={(e) => setActive(2)}>
							Historial
						</Link>
					</li>
				</ul>
			</div>
			<div>
				{showTutorial && (
					<div>
						<header className="header-ui">
							<div>
								<h2>Cómo usar los pedidos</h2>
								<p>
									En la pestaña “Pendientes” es a donde llegarán los
									pedidos que tus clientes hagan desde la App.
								</p>
								<p>
									Puedes confirmar, editar, o rechazar los pedidos
									desde “Pendientes”, luego los pedidos que confirmes
									pasarán a la pestaña “Confirmados”.
								</p>
								<p>
									Cuando el pedido esté listo para ser entregado,
									presiona el botón “Pedido Listo” y le avisaremos a tu
									cliente que ya puede reclamar el pedido, cuando ya
									hayas entregado el pedido presiona el botón “Pedido
									Entregado” para terminar la venta.
								</p>
								<button
									onClick={() => {
										localStorage.setItem('orders_tutorial', 'true')
										setShowTutorial(false)
									}}
									className="button button-light button-block"
								>
									Entendido
								</button>
							</div>
						</header>
					</div>
				)}

				<Switch>
					<Route
						path="/orders/accepted"
						render={(props) => <Accepted {...props} />}
					/>
					<Route
						path="/orders/pending"
						render={(props) => <Pending {...props} />}
					/>
					<Route
						path="/orders/history"
						render={(props) => <History {...props} />}
					/>
					<Route
						path="/orders"
						render={() => <Redirect to="/orders/accepted" />}
					/>
				</Switch>
			</div>
		</>
	)
}

export default connect((state) => ({
	language: state.language.dictionary,
	topbar: state.topbar,
	place: state.place,
	pending_orders: state.pending_orders,
	categories: state.categories,
}))(Orders)

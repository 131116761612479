import React, { useEffect, useState } from 'react'
import { functions, auth } from '../../config/firebase'
import qs from 'query-string'
import { connect } from 'react-redux'

function StripeConnect({ user }) {
	const [error, setError] = useState(false)
	useEffect(() => {
		let query = qs.parse(window.location.search)
		if (query.error) setError(true)
		else {
			let code = query.code
			if (code) {
				tryConnect(code)
			} else setError(true)
		}
	}, [])

	const tryConnect = async (code) => {
		try {
			let fn = functions.httpsCallable('connectStripe')
			let res = await fn({
				uid: auth.currentUser.uid,
				code,
				places: user.places,
			})

			if (res.data === false) {
				setError(true)
			} else {
				window.location.replace(`/choose`)
			}
		} catch (e) {
			setError(true)
		}
	}

	const domWrapper = (title, content, action) => {
		return (
			<main id="main">
				<div className="dashboard  choose theme-null navbar-opened ">
					<div className="login-ui wide">
						<div>
							<div className="form-ui">
								<span className="logo"></span>
								<h1>{title}</h1>
								{content}
								<footer>{action}</footer>
							</div>
						</div>
					</div>
				</div>
			</main>
		)
	}

	if (error)
		return domWrapper(
			'Ocurrió un error',
			null,
			<button
				className="button button-primary"
				onClick={() => window.location.replace(`/choose`)}
			>
				Regresar
			</button>
		)

	return domWrapper('Conectando tu cuenta...')
}

export default connect((state) => ({ user: state.user }))(StripeConnect)

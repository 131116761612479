import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Confirmation, More } from '../../../../components'
import { firestore } from '../../../../config/firebase'
import { create_alert } from '../../../../store/actions'
import Employee from './Employee'

function Employees({
	place,
	schedule,
	create_alert,
	renderCreateEmployee,
	setRenderCreateEmployee,
	search,
	language,
	report,
	global = false,
	global_data = {},
}) {
	const [_deleteEmployee, setDeleteEmployee] = useState(null)
	const [editEmployee, setEditEmployee] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [filtered, setFiltered] = useState(null)

	useEffect(() => {
		runFilter()
	}, [search])

	useEffect(() => {
		runFilter()
	}, [place.employees, place.employee_categories])

	useEffect(() => {
		runFilter()
	}, [global_data.employees])

	const runFilter = () => {
		let employees = global ? global_data.employees : place.employees
		setFiltered(
			employees.filter((emp) => {
				if (global === false) {
					let category_name = place.employee_categories.find(
						(c) => c.id === emp.category
					)
					if (typeof category_name === 'undefined') return false
					let ok = false
					if (
						emp.name
							.toString()
							.toLowerCase()
							.includes(search.toLowerCase())
					)
						ok = true
					if (
						emp.category
							.toString()
							.toLowerCase()
							.includes(search.toLowerCase())
					)
						ok = true
					return ok
				} else {
					if (emp.name === 'Admin' && emp.category === 'main_admin')
						return false
					else return true
				}
			})
		)
	}

	const renderEmployees = () => {
		return filtered.map((emp) => {
			let employee_categories = global
				? global_data.employee_categories
				: place.employee_categories
			let category_name = employee_categories.find(
				(c) => c.id === emp.category
			)
			category_name = category_name.name
			category_name =
				category_name.charAt(0).toUpperCase() +
				category_name.slice(1).toLowerCase()
			if (emp.category === 'main_admin') {
				category_name = language.main_admin
			}
			let empReport = report.find((_emp) => _emp.id === emp.id)
			let isWorking = false
			if (empReport) isWorking = empReport.working
			return (
				<tr key={emp.id}>
					<td>
						<strong>{emp.name}</strong>
					</td>
					{!global && (
						<>
							<td>{category_name}</td>
							<td>
								{isWorking && (
									<span className="badge status working primary">
										{language.working}
									</span>
								)}
								{!isWorking && (
									<span className="badge status resting gray">
										{language.resting}
									</span>
								)}
							</td>
						</>
					)}
					<td className="more-cell">
						{emp.category === 'main_admin' &&
						emp.name === 'Admin' ? null : (
							<More>
								{global === false && (
									<button
										onClick={() => setEditEmployee(emp)}
										type="button"
										title="Cancel"
									>
										<i className="mi">edit</i>
										<span>{language.edit}</span>
									</button>
								)}
								<button
									onClick={() => setDeleteEmployee(emp)}
									type="button"
									title="Cancel"
								>
									<i className="mi">delete_forever</i>
									<span>{language.remove}</span>
								</button>
							</More>
						)}
					</td>
				</tr>
			)
		})
	}

	const deleteEmployee = async (res) => {
		setSubmitting(true)
		if (!res) {
			//Nothing
		} else {
			let placeId = global === false ? place.id : global_data.id
			let employees =
				global === false ? place.employees : global_data.employees
			let newEmps = employees.filter((emp) => emp.id !== _deleteEmployee.id)
			if (global) {
				let admin_passed = false
				newEmps = newEmps.filter((e) => {
					if (e.name === 'Admin' && e.category === 'main_admin') {
						if (admin_passed === false) {
							admin_passed = true
							return true
						} else return false
					} else return true
				})
			}
			await firestore.collection('places').doc(placeId).update({
				employees: newEmps,
			})
			create_alert(
				`${language.X_has_been_deleted}`.replace('X', _deleteEmployee.name),
				'success'
			)
			if (global === true) {
				global_data.setEmployees(newEmps)
			}
		}
		setSubmitting(false)
		setDeleteEmployee(null)
	}

	if (filtered === null) return null

	return (
		<>
			{renderCreateEmployee && (
				<Employee
					close={() => setRenderCreateEmployee(false)}
					global={global}
					global_data={global_data}
				/>
			)}
			{editEmployee !== null && (
				<Employee
					close={() => setEditEmployee(null)}
					employee={editEmployee}
					global={global}
					global_data={global_data}
				/>
			)}
			{_deleteEmployee !== null && (
				<Confirmation
					onChange={deleteEmployee}
					submitting={submitting}
					label={language.remove}
					icon="delete"
					type="danger"
				>
					{`${language.remove_x_from_your_employees}`.replace(
						'X',
						_deleteEmployee.name
					)}
				</Confirmation>
			)}
			<div className="table-ui  table-sm">
				<div>
					<table>
						<thead>
							<tr>
								<th>{language.name}</th>
								{!global && (
									<>
										<th>{language.category}</th>
										<th>{language.status}</th>
									</>
								)}
								<th></th>
							</tr>
						</thead>
						<tbody>{renderEmployees()}</tbody>
					</table>
				</div>
			</div>
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		schedule: state.schedule,
		language: state.language.dictionary,
		report: state.report,
	}),
	{ create_alert }
)(Employees)

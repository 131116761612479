import React, { useState } from 'react'
import QrReader from 'react-qr-reader'
import { connect } from 'react-redux'
import { Loading } from '../../../components'
import firebase, { firestore } from '../../../config/firebase'
import { create_alert } from '../../../store/actions'

function Reader({
	create_alert,
	cancel,
	place,
	product = false,
	products,
	events,
}) {
	const [scanned, setScanned] = useState(null)
	const [scanStatus, setScanStatus] = useState(1)
	const [submitting, setSubmitting] = useState(false)

	const onScan = async (scan) => {
		try {
			let _scan = scan.toString()

			setScanStatus(3)
			let doc = await firestore
				.collection('places')
				.doc(place.id)
				.collection('tickets')
				.doc(scan.toString())
				.get()
			if (doc.exists) {
				doc = doc.data()
				if (product === false) {
					setScanned(doc)
				} else {
					let event = events.find((e) => e.slug === doc.event)
					if (typeof event === 'undefined') throw 'evento no valido'
					let seat = event.groups.find((g) => g.id === doc.seat)
					if (typeof seat === 'undefined') throw 'silla invalida'

					let products_used = doc.products_used || false
					if (products_used) {
						create_alert('Ticket usado', 'danger')
						setScanStatus(1)
						setSubmitting(false)
						setScanned(null)
						cancel()
					} else {
						setScanned({ ...doc, seat, event })
					}
				}
			} else {
				throw doc
			}
		} catch (e) {
			create_alert('QR Invalido', 'danger')
			setScanned(null)
			setScanStatus(1)
			setSubmitting(false)
			cancel()
		}
	}

	const useTicket = async () => {
		await firestore
			.collection('places')
			.doc(place.id)
			.collection('tickets')
			.doc(scanned.id.toString())
			.update({
				last_used: new Date().getTime(),
				used: scanned.used + 1,
			})
		setScanStatus(1)
		setScanned(null)
		create_alert('Ticket leido con éxito', 'success')
	}

	const renderScannedModal = () => {
		return (
			<div className="modal-form modal-form-sm show">
				<div>
					<form className="form-ui">
						<h1>Escaneado</h1>
						<div className="info-card">
							<aside>
								<span className="icon-counter success">
									<i className="mi">check</i>
								</span>
							</aside>
							<div>
								<strong>{scanned.name}</strong>
								<ul>
									<li>
										<b>DNI:</b> {scanned.dni}
									</li>
									<li>
										<b>Usado:</b>{' '}
										{scanned.last_used === null ? 'No' : 'Si'}
									</li>
									<li>
										<b>Categoria:</b> {scanned.category}
									</li>
									{scanned.subseat && (
										<li>
											<b>Tipo:</b> {scanned.subseat}
										</li>
									)}
									{scanned.last_used !== null && (
										<li>
											<b>Ultima vez usado:</b>{' '}
											{new Date(
												scanned.last_used
											).toLocaleDateString()}{' '}
											{`0${new Date(
												scanned.last_used
											).getHours()}`.slice(-2)}
											:
											{`0${new Date(
												scanned.last_used
											).getMinutes()}`.slice(-2)}
										</li>
									)}
								</ul>
							</div>
						</div>
						<footer className="cols-2">
							<button
								disabled={submitting}
								type="button"
								className="button button-light"
								onClick={() => {
									setScanned(null)
									setScanStatus(1)
								}}
							>
								<i className="mi">close</i>
								<span>Cancelar</span>
							</button>
							<button
								disabled={submitting}
								type="button"
								className="button button-primary"
								onClick={useTicket}
							>
								<i className="mi">arrow_forward</i>
								<span>Continuar</span>
							</button>
						</footer>
					</form>
					<button
						type="button"
						className="back"
						onClick={() => setScanned(null)}
					>
						<i className="mi">arrow_back</i>
					</button>
				</div>
			</div>
		)
	}

	const increment = firebase.firestore.FieldValue.increment(1)

	const addToOrders = async (prods) => {
		let placeRef = firestore.collection('places').doc(place.id)
		setSubmitting(true)
		firestore
			.runTransaction(async (transaction) => {
				let placeData = await transaction.get(placeRef)
				placeData = placeData.data()

				let newOrder = true
				let _orders = null
				let invoiceID = 0
				let order = null
				let paid = true

				if (typeof placeData.invoiceNumb !== 'undefined')
					invoiceID = placeData.invoiceNumb + 1
				invoiceID = `APP_PICKUP_${invoiceID}`

				await transaction.update(placeRef, {
					invoiceNumb: increment,
				})

				order = {
					invoiceID: invoiceID,
					invoiceNumb: placeData.invoiceNumb + 1,
					confirmed: true,
					status: 1,
					address: placeData.address.value,
					date: Date.now(),
					items: prods.map((p) => ({
						...p,
						state: 0,
						paid,
					})),
					paid: true,
					notes: ['Incluido con Entrada'],
					table: 'inplace',
					uid: null,
					place_name: place.name,
					paymentData: null,
					first_name: scanned.first_name,
					last_name: '',
				}
				_orders = [...placeData.orders, order]

				await transaction.update(placeRef, {
					orders: _orders,
				})

				await transaction.update(
					firestore
						.collection('places')
						.doc(place.id)
						.collection('tickets')
						.doc(scanned.id),
					{
						products_used: true,
					}
				)
			})
			.then(() => {
				create_alert('Agregado a pedidos', 'success')
				cancel()
				setSubmitting(false)
			})
			.catch((e) => {
				create_alert(`Ocurrio un error`, 'danger')
				setSubmitting(false)
			})
	}

	const renderScannedProductModal = () => {
		let prods = []
		let prs = scanned.seat.products || []
		prs.forEach((p) => {
			let pr = products.find((_p) => _p.id === p)
			if (typeof pr !== 'undefined') prods.push(pr)
		})

		return (
			<div className="modal-form modal-form-sm show">
				<div>
					<form className="form-ui">
						<h1>Escaneado</h1>
						<div className="info-card">
							<div>
								<strong>{scanned.name}</strong>
								<ul>
									<ul className="product-import-list">
										{prods.map((pr) => {
											let pr_cats = (
												<em>
													{pr.category !== '' && (
														<b>{pr.category}</b>
													)}
													{pr.subcategory !== '' && (
														<>
															<i className="mi">chevron_right</i>{' '}
															<b>{pr.subcategory}</b>
														</>
													)}
												</em>
											)
											return (
												<li key={pr.id} className="product-import">
													<div key={pr.id}>
														<label htmlFor={pr.id}>
															<span
																className="img"
																style={{
																	backgroundImage: `url(${pr.image})`,
																}}
															></span>

															<span>{pr.name}</span>

															{pr.category !== '' && pr_cats}
														</label>
													</div>
												</li>
											)
										})}
									</ul>
								</ul>
							</div>
						</div>
						<footer className="cols-2">
							<button
								disabled={submitting}
								type="button"
								className="button button-light"
								onClick={() => {
									setScanned(null)
									setScanStatus(1)
								}}
							>
								<i className="mi">close</i>
								<span>Cancelar</span>
							</button>
							<button
								disabled={submitting}
								type="button"
								className="button button-primary"
								onClick={() => addToOrders(prods)}
							>
								<i className="mi">arrow_forward</i>
								<span>Agregar a Pedidos</span>
							</button>
						</footer>
					</form>
					<button
						type="button"
						className="back"
						onClick={() => setScanned(null)}
					>
						<i className="mi">arrow_back</i>
					</button>
				</div>
			</div>
		)
	}

	return (
		<>
			{scanned !== null && !product && renderScannedModal()}
			{scanned !== null && product && renderScannedProductModal()}
			<div
				className="qr-reader"
				style={{
					marginTop: 70,
				}}
			>
				<div>
					{(scanStatus === 1 || scanStatus === 3) && (
						<Loading style={{ width: '100%', height: '100%' }} />
					)}
					{scanStatus !== 0 && scanStatus !== 3 && (
						<QrReader
							delay={100}
							onError={() => {
								create_alert('Unable to reach your camera', 'danger')
								setScanStatus(0)
							}}
							onLoad={() => {
								setScanStatus(2)
							}}
							onScan={(_scan) => {
								try {
									if (typeof _scan === 'string')
										onScan(JSON.parse(_scan))
								} catch (e) {
									create_alert('Error scanning that QR', 'danger')
									setScanned(null)
									setScanStatus(1)
									setSubmitting(false)
									cancel()
								}
							}}
							style={{ width: 414, height: 414 }}
						/>
					)}
					<button
						onClick={cancel}
						style={{
							zIndex: 1000,
						}}
						className="button button-light"
					>
						Cancelar
					</button>
				</div>
			</div>
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		products: state.products,
		events: state.events,
	}),
	{
		create_alert,
	}
)(Reader)

const initialState = null

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'SET_USER_PERMISSIONS':
			return { ...state, ...payload }
		case 'SIGN_OUT':
			return initialState
		default:
			return state
	}
}

import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import Products from './Products/View'
import Recommedations from './Products/Recommendations'

function ProductsIndex({ employee, place }) {
	let employee_permissions = employee
		? place.employee_categories.find((cat) => cat.id === employee.category)
				.permissions
		: []

	return (
		<Switch>
			{/* <Route path="/products/promotions" render={props => <Promotions {...props} />} /> */}
			{/* <Route path="/products/config" render={props => <Config {...props} />} /> */}
			<Route
				path="/products/recommendations"
				render={(props) => <Recommedations {...props} />}
			/>
			<Route path="/products" render={(props) => <Products {...props} />} />
			<Route
				path="/checkout"
				render={(props) => <Products checkout {...props} />}
			/>
		</Switch>
	)
}

export default connect((props) => ({
	employee: props.employee_permissions,
	place: props.place,
}))(ProductsIndex)

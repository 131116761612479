import { Form, Formik, Field } from 'formik'
import React, { useEffect, useState } from 'react'
import {
	Elements,
	StripeProvider,
	CardElement,
	injectStripe,
} from 'react-stripe-elements'
import { connect } from 'react-redux'
import {
	Input,
	Loading,
	ModalForm,
	Pricing,
	Confirmation,
	Popover,
	More,
} from '../../components'
import firebase, { auth, firestore, functions } from '../../config/firebase'
import { sign_employee, create_alert, update_user } from '../../store/actions'
import Employees from './Employees/Employees/index'
import { Redirect, Route } from 'react-router-dom'
import qs from 'query-string'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import PaymentForm from '../PaymentSetup/PaymentForm'
import CreateGroup from './CreateGroup'
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'
import Clients from './ReservationsCovid/Clients'
import algoliasearch from 'algoliasearch'
import DatePicker from 'react-datepicker'
import ReservationHistory from './ReservationHistory'
import ItemsManagement from './ItemsManagement'
import 'react-datepicker/dist/react-datepicker.css'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

const searchClientAlg = algoliasearch(
	'MM6KWVFEQD',
	'fce390db586e17fe0242f65ae2dc9c3c'
)

function ChooseEmployee({
	place,
	sign_employee,
	language,
	user,
	getPlace,
	history,
	create_alert,
	topbar,
	update_user,
}) {
	const [enteringAs, setEnteringAs] = useState(null)
	const [placesToChoose, setPlacesToChoose] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [loading, setLoading] = useState(true)
	const [redirect, setRedirect] = useState(null)
	const [showMasterPassword, setShowMasterPassword] = useState(false)
	const [selectedPlace, setSelectedPlace] = useState(null)
	const [accountConfig, setAccountConfig] = useState(null)
	const [showUpdateCard, setShowUpdateCard] = useState(false)
	const [confirmingDeleteAccount, setConfirmingDeleteAccount] = useState(false)
	const [showUpdateSubscription, setShowUpdateSubscription] = useState(false)
	const [creatingGroup, setCreatingGroup] = useState(false)
	const [showCreateGroup, setShowCreateGroup] = useState(false)
	const [editGroup, setEditGroup] = useState(null)
	const [groups, setGroups] = useState([])
	const [searchClient, setSearchClient] = useState('')
	const [searchReservation, setSearchReservation] = useState('')
	const [placesCovidData, setPlacesCovidData] = useState(null)
	const [active, setActive] = useState(0)
	const [placesClientsPl, setPlacesClientsPl] = useState(null)
	const [sendMail, setSendMail] = useState(false)
	const [fromFilter, setFromFilter] = useState(null)
	const [toFilter, setToFilter] = useState(null)
	const [employees, setEmployees] = useState([])
	const [employeeCategories, setEmployeeCategories] = useState([])
	const [renderCreateEmployee, setRenderCreateEmployee] = useState(false)
	const [dayViewing, setDayViewing] = useState(new Date())
	const [hideTopbar, setHideTopbar] = useState(false)

	const [page, setPage] = useState(0)
	const [pages, setPages] = useState(1)
	const [totalHits, setTotalHits] = useState(0)
	const [searchChanged, setSearchChanged] = useState(false)
	const [doSearch, setDoSearch] = useState(false)
	const [peopleCount, setPeopleCount] = useState(0)
	const [placeSearchFilter, setPlaceSearchFilter] = useState(null)
	const [downloadingFile, setDownloadingFile] = useState(false)

	const [type, setType] = useState(null)

	const [showUploadClients, setShowUploadClients] = useState(false)

	useEffect(() => {
		if (window.location.pathname.startsWith('/choose/items-management')) {
			setActive(5)
		}

		getPlacesToChoose()
		getGroups()
		let old_emp = localStorage.getItem('employee')
		if (old_emp !== null) {
			old_emp = JSON.parse(old_emp)
			sign_employee(old_emp)
			return () => {
				let query = qs.parse(window.location.search)
				let red = query.redirect
				if (typeof red === 'undefined') red = '/'
				// else if (red === '/choose') red = '/'
				history.replace(red)
			}
		} else setLoading(false)

		if (placesCovidData === null) {
			setSelectedPlace(null)
		}
	}, [])

	useEffect(() => {
		if (placesCovidData !== null) {
			let people = 0
			placesCovidData.forEach((p) => {
				people += p.people
			})
			setPeopleCount(people)
		}
	}, [placesCovidData])

	useEffect(() => {
		setPlacesCovidData(null)
		Search()
	}, [fromFilter, toFilter, page, placeSearchFilter])

	useEffect(() => {
		if (searchReservation.length > 2) setSearchChanged(true)
		else {
			setSearchChanged(false)
		}
	}, [searchReservation])

	useEffect(() => {
		if (placesToChoose !== null && placesToChoose.length > 0) {
			let emps = []
			let cats = []
			placesToChoose.forEach((p) => {
				emps = [...emps, ...p.employees]
				cats = [...cats, ...p.employee_categories]
			})
			setEmployees(emps)
			setEmployeeCategories(cats)
		}
	}, [placesToChoose])

	useEffect(() => {
		let listener = null
		if (active === 1) {
			firestore
				.doc(`/places/${user.places[0]}`)
				.get()
				.then((res) => {
					setPlacesClientsPl(res.data())
				})
		}
		return () => {
			if (listener !== null) {
				listener()
			}
		}
	}, [active])

	useEffect(() => {
		let res = 1
		groups.forEach((g) => {
			if (g.category === 'hotel') res = 0
		})
		setType(res)
	}, [groups])

	useEffect(() => {
		if (doSearch === true) {
			if (typeof searchReservation === 'string') {
				setPlacesCovidData(null)
				Search()
			} else {
				setPlacesCovidData([])
			}
			setDoSearch(false)
			setSearchChanged(false)
		}
	}, [doSearch])

	const formatDate = (date) => {
		let _date = new Date(date)
		let day = _date.getDate()
		let month = _date.getMonth() + 1
		let year = _date.getFullYear()
		return `${`0${day}`.slice(-2)}/${`0${month}`.slice(-2)}/${year}`
	}

	const upper = (lower) => lower.charAt(0).toUpperCase() + lower.substring(1)

	const getPlacesToChoose = () => {
		if (typeof user.places === 'undefined') return setPlacesToChoose(false)

		let promises = user.places.map((place_id) => {
			return firestore
				.doc(`/places/${place_id}`)
				.get()
				.catch((e) => false)
		})
		Promise.all(promises)
			.then((_places) => {
				let places = _places.map((place) => place.data())
				if (places.length === 1) setSelectedPlace(places[0])
				setPlacesToChoose(places)
			})
			.catch((res) => {})
	}

	const getGroups = async () => {
		let grs = await firestore
			.collection(`/groups`)
			.where('owner', '==', auth.currentUser.uid)
			.get()

		let f_grs = []
		grs.docs.forEach((gr) => {
			f_grs.push(gr.data())
		})

		setGroups(f_grs)
	}

	const renderPagination = () => {
		let p = []
		for (let i = 0; i < pages; i++) {
			p.push(
				<button
					className={`button button-${i === page ? 'primary' : 'link'}`}
					style={{
						width: 50,
						height: 50,
					}}
					onClick={() => setPage(i)}
				>
					{i + 1}
				</button>
			)
		}
		return p
	}

	const Search = async (force = false) => {
		const index = searchClientAlg.initIndex('reservation_queue')

		let fromDate = fromFilter === null ? 1000 : fromFilter
		let toDate = toFilter === null ? 1912051302000 : toFilter

		let filter = ''
		if (fromFilter !== null || toFilter !== null)
			filter = `date >= ${fromDate} AND date <= ${toDate}`

		let facet = [[`owner:${user.id}`]]
		if (placeSearchFilter !== null) facet.push([`place:${placeSearchFilter}`])

		const { hits, nbPages } = await index.search(searchReservation, {
			facetFilters: facet,
			filters: filter,
			hitsPerPage: 50,
			page: page,
		})
		if (page > nbPages) setPage(nbPages - 1)

		if (nbPages === 1) setTotalHits(hits.length)
		else setTotalHits(nbPages * 50)
		setPages(nbPages)
		setPlacesCovidData(hits)
	}

	const renderEmployees = () => {
		if (selectedPlace === null) return null
		let emps = []
		if (
			typeof user.custom !== 'undefined' &&
			user.custom.indexOf('employees_global') !== -1
		) {
			emps = employees
			let admin_passed = false
			emps = emps.filter((e) => {
				if (e.name === 'Admin' && e.category === 'main_admin') {
					if (admin_passed === false) {
						admin_passed = true
						return true
					} else return false
				} else return true
			})
		} else emps = selectedPlace.employees
		return emps.map((emp) => {
			let category_name = selectedPlace.employee_categories.find(
				(c) => c.id === emp.category
			)
			let dis =
				user.type === 'admin_free' && category_name.id !== 'main_admin'
			if (category_name.id === 'main_admin')
				category_name = language.main_admin
			else category_name = category_name.name

			return (
				<li key={emp.id}>
					<div
						className="card vcard"
						style={{
							opacity: dis ? 0.2 : 1,
						}}
					>
						<div className="avatar">
							<span className="img">
								<i className="mi">account_circle</i>
							</span>
						</div>
						<div className="body">
							<strong className="title">{emp.name}</strong>
							<em>{upper(category_name)}</em>
						</div>
						<button
							disabled={dis}
							className="button button-primary"
							onClick={() => {
								if (dis) return
								if (user.requirePassword === false) {
									submitPassword(emp)
								} else {
									setEnteringAs(emp)
								}
							}}
						>
							<span>{language.enter}</span>
						</button>
					</div>
				</li>
			)
		})
	}

	const submitPassword = (emp = enteringAs) => {
		setSubmitting(true)
		localStorage.setItem('employee', JSON.stringify(emp))
		getPlace(selectedPlace.id)
		sign_employee(emp)
		history.replace('/')
	}

	const validate = (v) => {
		let err = {}
		try {
			if (enteringAs.category === 'main_admin') {
				if (v.password !== user.main_password)
					err.password = language.wrong_password
			} else {
				let real_pass = selectedPlace.employees.find(
					(em) => em.id === enteringAs.id
				).pin
				if (v.password !== real_pass) err.password = language.wrong_password
			}
			return err
		} catch (e) {}
	}

	const updateRequirePassword = async (val) => {
		setSubmitting(true)
		await firestore.doc(`/users/${user.id}`).update({
			requirePassword: val,
		})
		update_user({
			requirePassword: val,
		})
		setSubmitting(false)
	}

	const renderEnterPassword = () => {
		return (
			<ModalForm toggle={() => setEnteringAs(null)} size="sm">
				<Formik
					initialValues={{
						password: '',
					}}
					validateOnBlur={false}
					validateOnChange={false}
					validate={validate}
					onSubmit={() => submitPassword()}
				>
					{({ errors, touched }) => (
						<Form className="form-ui">
							<h1>{language.enter_the_employee_password}</h1>
							<Input
								label={language.password}
								disabled={submitting}
								type="password"
								name="password"
								errors={errors}
								touched={touched}
							/>
							<footer style={{ marginTop: 10 }}>
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">
												{language.loading}...
											</span>
										</div>
									)}
									{!submitting && (
										<>
											<span>{language.continue}</span>
										</>
									)}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	const renderEnterMasterPassword = () => {
		return (
			<ModalForm toggle={() => setShowMasterPassword(false)} size="sm">
				<Formik
					initialValues={{
						password: '',
					}}
					onSubmit={onConfirmPassword}
				>
					{({ errors, touched }) => (
						<Form className="form-ui">
							<h1>Contraseña de Admin principal</h1>
							<Input
								label={language.password}
								disabled={submitting}
								type="password"
								name="password"
								errors={errors}
								touched={touched}
							/>
							<footer>
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">
												{language.loading}...
											</span>
										</div>
									)}
									{!submitting && (
										<>
											<span>{language.continue}</span>
										</>
									)}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	const onConfirmPassword = (values, { setFieldError }) => {
		if (values.password !== user.main_password) {
			setFieldError('password', 'Contraseña incorrecta')
		} else {
			if (showCreateGroup) {
				setCreatingGroup(true)
			} else if (accountConfig === null) {
				history.replace('/create-place')
			} else {
				setAccountConfig(true)
			}
			setShowMasterPassword(false)
		}
	}

	const renderPlaces = () => {
		// Render user places list
		if (placesToChoose === null) return null

		return (
			<>
				{placesToChoose.map((pl, z) => {
					let active = true
					if (user.type === 'admin_free') {
						if (pl.type !== 'restaurant' || z > 5) active = false
					}
					if (user.type === 'admin_payasyougo') if (z > 10) active = false

					let place_img =
						pl.list_image !== null
							? { backgroundImage: `url(${pl.list_image})` }
							: null
					return (
						<li key={pl.id}>
							<div
								className={`card vcard placecard ${
									selectedPlace !== null && selectedPlace.id === pl.id
										? 'active'
										: ''
								}`}
								style={{
									opacity: active ? 1 : 0.2,
								}}
							>
								<div
									className={`avatar ${
										place_img !== null ? 'has-image' : 'no-image'
									}`}
								>
									<span className="img" style={place_img}>
										<i className="mi">store</i>
									</span>
								</div>
								<div className="body">
									<strong className="title">{pl.name}</strong>
									<em>{upper(language[pl.subcategory])}</em>
								</div>
								{!(
									selectedPlace !== null && selectedPlace.id === pl.id
								) && (
									<button
										disabled={!active}
										className="button button-primary"
										onClick={() => {
											if (!active) return
											setSelectedPlace(pl)
											// setTimeout(() => setSelectedPlace(pl), 300)
										}}
									>
										<span>{language.select}</span>
									</button>
								)}
							</div>
						</li>
					)
				})}
			</>
		)
	}

	const renderConfirming = () => {
		return (
			<Confirmation
				label={'Eliminar'}
				icon="delete"
				type="danger"
				submitting={submitting}
				onChange={async (res) => {
					if (!res) {
						setConfirmingDeleteAccount(false)
					} else {
						setSubmitting(true)
						let fn = functions.httpsCallable('removeAdmin')
						let res = await fn({ uid: auth.currentUser.uid })
						if (res.data !== false) {
							localStorage.clear()
							auth.signOut()
							window.location.reload()
						}
					}
				}}
			>
				¿Seguro que quieres borrar tu cuenta?
				<br /> Se borrarán todos tus lugares y se cancelarán tus
				suscripciones
			</Confirmation>
		)
	}

	const renderQueue = () => {
		return placesCovidData.map((q) => {
			let status = 'Esperando mesa'

			if (q.status && q.status.table !== null)
				status = `Mesa ${q.status.table.name}`

			if (q.status && q.status.assist !== null) {
				if (q.status.assist === false) status = `No asistió`
				else status += ` | Pasó`
			}

			return (
				<tr key={q.when}>
					<td>
						<span className="badge">{q.placeName}</span>
					</td>
					<td>
						{q.date_block[0]}
						<span
							className="badge"
							style={{
								marginLeft: 10,
							}}
						>
							{q.date_block[1]}
						</span>
						<span
							className="badge orange"
							style={{
								marginLeft: 10,
							}}
						>
							{q.date_block[2]}
						</span>
					</td>
					<td>
						<strong>{q.name}</strong>
					</td>
					{type === 0 && (
						<>
							<td>
								<strong>{q.room}</strong>
							</td>
							<td>
								{typeof q.group === 'object' && (
									<Popover
										content={q.group.description}
										offset={10}
										offsetY={-5}
										reversed
									>
										<strong>{q.group.initials}</strong>
									</Popover>
								)}
							</td>
						</>
					)}
					<td>
						{q.adults > 0 && `Adultos ${q.adults}, `}{' '}
						{q.children > 0 && `Niños ${q.children}, `}{' '}
						{`Total ${q.people}`}
					</td>
					<td>
						{typeof q.notes === 'string' && q.notes.length > 0 && (
							<Popover
								content={q.notes}
								offset={10}
								offsetY={-5}
								reversed
							>
								<strong>
									{q.notes.slice(0, 20)}
									{q.notes.length > 20 && '...'}
								</strong>
							</Popover>
						)}
					</td>
					<td
						className={`email-cell ${
							q.email.length > 0 ? 'has-email' : 'no-email'
						}`}
					>
						<span>{q.email.length > 0 ? q.email : 'Falta Correo'}</span>
					</td>
					<td>{status}</td>
				</tr>
			)
		})
	}

	const getArrayContent = async () => {
		let its = []
		let result = await getAllReservations()
		result.forEach((q) => {
			let placeName = placesToChoose.find((p) => p.id === q.place)
			if (typeof placeName === 'undefined') placeName = { name: q.place }

			let search_term = `${searchReservation}`
			search_term = search_term
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
			search_term = search_term.toLowerCase()

			let placeNameSearch = `${placeName.name}`
			placeNameSearch = placeNameSearch
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
			placeNameSearch = placeNameSearch.toLowerCase()

			let name = `${q.name}`
			name = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
			name = name.toLowerCase()

			let email = `${q.email}`
			email = email.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
			email = email.toLowerCase()

			let room = `${q.room}`
			room = room.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
			room = room.toLowerCase()

			let initials =
				typeof q.group !== 'undefined' ? `${q.group.initials}` : ''
			initials = initials.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
			initials = initials.toLowerCase()

			let isInSearch = false

			if (placeNameSearch.includes(search_term)) isInSearch = true
			if (name.includes(search_term)) isInSearch = true
			if (room.includes(search_term)) isInSearch = true
			if (initials.includes(search_term)) isInSearch = true

			if (isInSearch) its.push(q)
		})

		let cols = ['Lugar', 'Fecha-Franja-Turno', 'Nombre', 'Email']
		if (type === 0) cols = [...cols, 'Habitación', 'Pensión']
		cols = [...cols, 'Personas', 'Notas']

		return [
			cols,
			...its.map((it) => {
				let placeName = placesToChoose.find((p) => p.id === it.place)
				if (typeof placeName === 'undefined') placeName = { name: it.place }

				let date_block = `${it.date_block[0]} ${it.date_block[1]} ${it.date_block[2]}`

				let group = ''
				if (typeof it.group === 'object') group = it.group.initials

				let people = `${it.adults > 0 ? `Adultos ${it.adults};` : ''} ${
					it.children > 0 ? `Niños ${it.children};` : ''
				} ${`Total ${it.people}`}`

				return [
					placeName.name,
					date_block,
					it.name,
					it.email,
					it.room,
					group,
					people,
					it.notes,
				]
			}),
		]
	}

	const getPlacesAsOptions = () => {
		return [{ id: null, name: 'Todos' }, ...placesToChoose].map((p) => ({
			value: p.id,
			label: p.name,
		}))
	}

	const getAllReservations = async () => {
		let result = []

		for (const user_place of user.places) {
			let place_reservations = await firestore
				.collection(`/places/${user_place}/advanced_res_queue`)
				.get()

			place_reservations = place_reservations.docs
			for (const day_doc of place_reservations) {
				let day_data = day_doc.data()
				if (day_data) {
					day_data = day_data.data
					if (day_data) {
						day_data = day_data.map((d) => ({
							added: d.added,
							adults: d.adults,
							childre: d.children,
							client_id: d.client_id,
							date: d.date,
							date_block: d.date_block,
							email: d.email,
							from: d.from,
							group: d.group,
							id: d.id,
							lang: d.lang,
							name: d.name,
							notes: d.notes,
							objectID: d.id,
							owner: d.owner,
							people: d.people,
							phone: d.phone,
							place: d.place,
							placeName: d.placeName,
							room: d.room,
							slot: d.slot,
							status: d.status,
							to: d.to,
						}))
						result = [...result, ...day_data]
					}
				}
			}
		}

		result = result.sort((a, b) => b.date - a.date)

		return result
	}

	const getExcel = async () => {
		setDownloadingFile(true)
		create_alert(
			'Descargando todas las reservas, puede tardar unos segundos',
			'warning',
			null,
			true
		)
		let arrayContent = await getArrayContent()
		let csvContent = arrayContent.join('\n')
		let link = window.document.createElement('a')
		link.setAttribute(
			'href',
			'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent)
		)
		link.setAttribute('download', 'Lista Reservas Excel.csv')
		link.click()
		create_alert(`Descargadas ${arrayContent.length} reservas.`, 'success')
		setDownloadingFile(false)
	}

	const getPdf = async () => {
		setDownloadingFile(true)
		create_alert(
			'Descargando todas las reservas, puede tardar unos segundos',
			'warning',
			null,
			true
		)
		let result = await getAllReservations()
		let docs = result.map((p) => [
			p.placeName || '',
			`${p.date_block[0]} ${p.date_block[1]}` || '',
			p.name || '',
			p.email || '',
			p.room || '',
			p.group.initials || '',
			p.people || '',
		])
		var docDefinition = {
			pageOrientation: 'landscape',
			pageMargins: 5,
			pageSize: {
				width: 1200,
				height: 1200,
			},
			content: [
				{
					table: {
						body: [
							[
								'LUGAR',
								'FECHA/FRANJA',
								'NOMBRE',
								'EMAIL',
								'HABITACION',
								'PENSION',
								'PAX',
							],
							...docs,
						],
						widths: [
							'auto',
							'auto',
							'auto',
							'auto',
							'auto',
							'auto',
							'auto',
						],
					},
				},
			],
		}
		pdfMake.createPdf(docDefinition).download()
		create_alert(`Descargadas ${docs.length} reservas.`, 'success')
		setDownloadingFile(false)
	}

	if (loading) return <Loading />

	if (confirmingDeleteAccount) return renderConfirming()

	if (redirect !== null) return <Redirect to={redirect} />

	if (creatingGroup)
		return (
			<CreateGroup
				placesToChoose={placesToChoose}
				close={() => {
					setShowCreateGroup(false)
					setCreatingGroup(false)
				}}
			/>
		)

	if (editGroup !== null)
		return (
			<CreateGroup
				edit={editGroup}
				placesToChoose={placesToChoose}
				close={() => {
					getGroups()
					setEditGroup(null)
				}}
			/>
		)

	if (showUpdateSubscription)
		return (
			<>
				<div className={`login-ui pricing-ui`}>
					<div>
						<Formik
							initialValues={{
								type: user.type,
							}}
							onSubmit={async (r) => {
								if (r.type === user.type)
									return create_alert('ya tienes ese plan', 'danger')
								setSubmitting(true)

								if (r.type === 'admin_free') {
									let fn =
										functions.httpsCallable('cancelSubscription')
									let res = await fn({
										customer: user.stripe_customer.id,
									})
									if (res.data === false) {
										create_alert(
											'Hubo un error actualizando tu suscripción',
											'danger'
										)
									} else {
										await firestore.doc(`/users/${user.id}`).update({
											stripe_customer: res.data,
											stripe_subscription: {},
											type: r.type,
										})
										update_user({
											stripe_customer: res.data,
											stripe_subscription: {},
											type: r.type,
										})
										setSubmitting(false)
										create_alert('Suscripción actualizada', 'success')
										setShowUpdateSubscription(false)
									}
								} else {
									let plans = []
									if (r.type === 'admin_premium')
										plans = [
											'plan_HHUta0XNKTVuIw',
											'plan_HHUtYkkB8HAVBN',
										]
									else
										plans = [
											'plan_HHUspfEOAP3LFO',
											'plan_HHUszh4Up8klqD',
										]
									let fn =
										functions.httpsCallable('updateSubscription')
									let res = await fn({
										customer: user.stripe_customer.id,
										plan: plans,
									})
									if (!res.data.error) {
										if (r.type === 'admin_premium') {
											const func_ = functions.httpsCallable(
												'updateOrdersPricing'
											)

											let placesSub =
												res.data.customer.subscriptions.data[0].items.data.find(
													(s) =>
														s.plan.id === 'plan_HHUtYkkB8HAVBN'
												)
											if (typeof placesSub === 'undefined')
												throw { error: true }

											await func_({
												subscription_item: placesSub.id,
												quantity: user.places.length,
												action: 'set',
											})
										}

										await firestore.doc(`/users/${user.id}`).update({
											stripe_customer: res.data.customer,
											stripe_subscription: res.data.subscription,
											type: r.type,
										})
										update_user({
											stripe_customer: res.data.customer,
											stripe_subscription: res.data.subscription,
											type: r.type,
										})
										setSubmitting(false)
										create_alert('Suscripción actualizada', 'success')
										setShowUpdateSubscription(false)
									} else {
										setSubmitting(false)
										create_alert(
											'Error actualizando tu suscripción',
											'danger'
										)
									}
								}
							}}
						>
							{({ errors, touched, values, setFieldValue }) => {
								return (
									<Form className="form-ui">
										<span className="logo"></span>
										<h1>Selecciona tu Suscripción</h1>
										<Pricing values={values}>
											<tr>
												<th scope="row"></th>
												<td
													className={`${
														values.type === 'admin_free'
															? 'active'
															: ''
													}`}
												>
													<button
														type="button"
														disabled={
															values.type === 'admin_free'
														}
														onClick={() =>
															setFieldValue('type', 'admin_free')
														}
														className="button button-cyan-outline"
													>
														{`${
															values.type === 'admin_free'
																? 'Seleccionado'
																: 'Seleccionar'
														}`}
													</button>
												</td>
												{/* <td
													className={`${
														values.type === 'admin_payasyougo'
															? 'active'
															: ''
													}`}
												>
													<button
														type="button"
														disabled={
															values.type === 'admin_payasyougo'
														}
														onClick={() =>
															setFieldValue(
																'type',
																'admin_payasyougo'
															)
														}
														className="button button-cyan"
													>
														{`${
															values.type === 'admin_payasyougo'
																? 'Seleccionado'
																: 'Seleccionar'
														}`}
													</button>
												</td> */}
												<td
													className={`${
														values.type === 'admin_premium'
															? 'active'
															: ''
													}`}
												>
													<button
														type="button"
														disabled={
															values.type === 'admin_premium'
														}
														onClick={() =>
															setFieldValue(
																'type',
																'admin_premium'
															)
														}
														className="button button-orange"
													>
														{`${
															values.type === 'admin_premium'
																? 'Seleccionado'
																: 'Seleccionar'
														}`}
													</button>
												</td>
											</tr>
										</Pricing>
										<footer>
											<button
												disabled={
													submitting || values.type === user.type
												}
												type="submit"
												className="button button-primary"
											>
												{submitting && (
													<div role="status">
														<span>{language.loading}...</span>
													</div>
												)}
												{!submitting && language.continue}
											</button>
											<button
												className="button button-link"
												onClick={() =>
													setShowUpdateSubscription(false)
												}
											>
												Atrás
											</button>
										</footer>
									</Form>
								)
							}}
						</Formik>
					</div>
				</div>
			</>
		)

	if (showUpdateCard)
		return (
			<>
				<StripeProvider apiKey="pk_live_A0gAYAY0ucS2kSjZO0FniJf000A9UAn76Q">
					<Elements>
						<PaymentForm
							create_alert={create_alert}
							user={user}
							customSubmit={async (token, values) => {
								try {
									setSubmitting(true)
									let fn = functions.httpsCallable(
										'updatePaymentMethod'
									)
									let res = await fn({
										token: token.id,
										customer:
											typeof user.stripe_customer.id !== 'undefined'
												? user.stripe_customer.id
												: null,
										prev_card:
											typeof user.stripe_customer.default_source !==
											'undefined'
												? user.stripe_customer.default_source
												: null,
									})
									if (res.data.error === true)
										throw { error: res.data.data }
									await firestore.doc(`/users/${user.id}`).update({
										stripe_customer: res.data,
									})
									update_user({
										stripe_customer: res.data,
									})
									setSubmitting(false)
									setShowUpdateCard(false)
								} catch (e) {
									setSubmitting(false)
									setShowUpdateCard(false)

									create_alert('Hubo un error', 'danger')
								}
							}}
							goBack={(e) => setShowUpdateCard(false)}
						/>
					</Elements>
				</StripeProvider>
			</>
		)

	if (accountConfig === true)
		return (
			<>
				<Helmet>
					<tilte>Vywap | Configuración</tilte>
				</Helmet>
				<div className="login-ui wide">
					<div>
						<Formik
							initialValues={{
								requirePassword:
									typeof user.requirePassword === 'undefined'
										? true
										: user.requirePassword,
							}}
							// onSubmit={onSubmit}
						>
							{({ values, setFieldValue }) => (
								<Form className="form-ui">
									<span className="logo"></span>
									<h1>Configuración de cuenta</h1>
									<div className="form-group">
										<div className="form-row">
											<div className="col-8">
												<p className="info">
													<i className="mi">credit_card</i>{' '}
													{typeof user.stripe_customer.sources !==
														'undefined' &&
													user.stripe_customer.sources
														.total_count > 0 ? (
														<span>
															Tarjeta de credito: **** **** ****{' '}
															{
																user.stripe_customer.sources
																	.data[0].last4
															}
														</span>
													) : null}
													{(typeof user.stripe_customer.sources ===
														'undefined' ||
														user.stripe_customer.sources
															.total_count === 0) && (
														<span>Ninguna</span>
													)}
												</p>
											</div>
											<div className="col-4">
												<button
													className="button button-light"
													onClick={() => setShowUpdateCard(true)}
												>
													Cambiar Tarjeta
												</button>
											</div>
										</div>
									</div>
									<div className="form-group">
										<div className="form-row">
											<div className="col-8">
												<p className="info">
													<i className="mi">star</i>{' '}
													<span>
														Tu suscripción actual es:{' '}
														<b
															className={`badge ${
																user.type === 'admin_premium'
																	? 'orange'
																	: user.type === 'admin_free'
																	? 'red'
																	: 'cyan'
															}`}
														>
															{user.type === 'admin_premium'
																? 'Premium'
																: user.type === 'admin_free'
																? 'Gratis'
																: 'Paga a Medida'}
														</b>
													</span>
												</p>
											</div>
											<div className="col-4">
												<button
													disabled={
														typeof user.stripe_customer
															.sources === 'undefined'
													}
													className="button button-light"
													onClick={() =>
														setShowUpdateSubscription(true)
													}
												>
													Cambiar Plan
												</button>
											</div>
										</div>
									</div>
									<div className="form-group">
										<div className="form-row">
											<div className="col-8">
												<p className="info">
													<i className="mi">account_balance</i>{' '}
													<span>
														Tu cuenta conectada:{' '}
														<b
															className={`badge ${
																user.stripe_connected === null
																	? 'dark'
																	: 'cyan'
															}`}
														>
															{user.stripe_connected === null
																? 'Ninguna'
																: user.stripe_connected.settings
																		.dashboard.display_name}
														</b>
													</span>
												</p>
											</div>
											<div className="col-4">
												{user.stripe_connected === null && (
													<button
														className="button"
														type="button"
														onClick={() => {
															window.location.replace(
																`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_H41UKXjuufrxUsHFYhmjcm6DkAfoYQ9u&scope=read_write&redirect_uri=https://localhost:5000/connect/stripe`
															)
														}}
														style={{
															backgroundColor: 'white',
														}}
													>
														<img
															src={require('../../img/stripe_connect.png')}
															style={{
																height: 30,
																width: 'auto',
															}}
														/>
													</button>
												)}
												{user.stripe_connected !== null && (
													<button
														type="button"
														className="button button-light"
														onClick={async () => {
															create_alert(
																'Refrescando tu cuenta conectada',
																'warning'
															)
															let fn = functions.httpsCallable(
																'refreshConnectStripe'
															)
															let res = await fn({
																uid: auth.currentUser.uid,
																account:
																	user.stripe_connected.id,
															})
															if (res.data !== false) {
																update_user({
																	stripe_connected: res.data,
																})
																create_alert(
																	'Cuenta refrescada',
																	'success'
																)
															} else
																create_alert(
																	'Error refrescando tu cuenta conectada',
																	'danger'
																)
														}}
													>
														Refrescar cuenta
													</button>
												)}
											</div>
										</div>
									</div>
									<div className="form-group form-group-check">
										<div className="form-row">
											<div className="col-12">
												<Field
													type="checkbox"
													name="requirePassword"
													id="requirePassword"
													checked={values.requirePassword}
													onChange={(e) => {
														setFieldValue(
															'requirePassword',
															e.target.checked
														)
														updateRequirePassword(
															e.target.checked
														)
													}}
												/>
												<label htmlFor="requirePassword">
													<b></b>{' '}
													<span>
														Requerir contraseña para entrar a
														empleados
													</span>
												</label>
											</div>
										</div>
									</div>
									{user.stripe_connected !== null &&
										!user.stripe_connected.charges_enabled && (
											<div className="alert alert-warning">
												<i className="mi">warning</i>
												<p>
													Tu cuenta de Stripe no se encuentra
													verificada, no puedes recibir pagos, si
													ya la verificaste has click arriba en
													Refrescar cuenta.
												</p>
											</div>
										)}
									{user.stripe_connected === null && (
										<div className="alert alert-warning">
											<i className="mi">warning</i>
											<p>
												Conecta tu cuenta de{' '}
												<strong>Stripe </strong>
												para recibir pagos desde la App
											</p>
										</div>
									)}

									{typeof user.stripe_customer.sources ===
										'undefined' && (
										<div className="alert alert-warning">
											<i className="mi">warning</i>
											<p>
												Antes de actualizar tu suscripción, agrega
												una tarjeta de crédito
											</p>
										</div>
									)}

									<footer>
										<button
											disabled={submitting}
											type="button"
											className="button button-primary"
											onClick={(e) => setAccountConfig(null)}
										>
											{submitting && (
												<div role="status">
													<span>Cargando...</span>
												</div>
											)}
											{!submitting && 'Atrás'}
										</button>
									</footer>
									<button
										className="button button-link"
										type="button"
										disabled={submitting}
										onClick={() => setConfirmingDeleteAccount(true)}
										style={{
											marginBottom: 20,
										}}
									>
										Borrar cuenta
									</button>
								</Form>
							)}
						</Formik>
					</div>
				</div>
				<img
					src={require('../../img/stripe_powered.png')}
					style={{
						height: 40,
						width: 'auto',
						position: 'fixed',
						bottom: 20,
						left: 20,
					}}
				/>
			</>
		)

	return (
		<>
			<Helmet>
				<tilte>Vywap | Empleados</tilte>
			</Helmet>
			{enteringAs !== null && renderEnterPassword()}
			{showMasterPassword && renderEnterMasterPassword()}
			<div className="main">
				<Route
					path="/choose/items-management"
					render={(props) => (
						<ItemsManagement
							{...props}
							setHideTopbar={setHideTopbar}
							setShowUploadClients={setShowUploadClients}
							showUploadClients={showUploadClients}
						/>
					)}
				/>
				<Route
					path="/choose"
					render={(props) => (
						<>
							{!hideTopbar && (
								<div className="topbar" style={{ top: topbar.top }}>
									<ul className="tabs limit-w">
										<li className={active === 0 ? 'active' : ''}>
											<Link
												to="/choose"
												onClick={(e) => {
													setActive(0)
												}}
											>
												Mis Lugares
											</Link>
										</li>
										<li className={active === 1 ? 'active' : ''}>
											<Link
												to="/choose/clients"
												onClick={(e) => {
													setActive(1)
												}}
											>
												Clientes
											</Link>
										</li>
										<li className={active === 2 ? 'active' : ''}>
											<Link
												to="/choose/reservations_covid"
												onClick={(e) => {
													setActive(2)
												}}
											>
												{typeof user.custom !== 'undefined' &&
												user.custom.indexOf('covid_res') !== -1
													? 'Reservas Covid19'
													: 'Reserva avanzada'}
											</Link>
										</li>
										{typeof user.custom !== 'undefined' &&
											user.custom.indexOf('employees_global') !==
												-1 && (
												<li
													className={active === 3 ? 'active' : ''}
												>
													<Link
														to="/choose/employees"
														onClick={(e) => {
															setActive(3)
														}}
													>
														Empleados
													</Link>
												</li>
											)}
										<li className={active === 4 ? 'active' : ''}>
											<Link
												to="/choose/history"
												onClick={(e) => {
													setActive(4)
												}}
											>
												Historial Reservas
											</Link>
										</li>
										<li className={active === 5 ? 'active' : ''}>
											<Link
												to="/choose/items-management"
												onClick={(e) => {
													setActive(5)
												}}
											>
												Gestión de piscinas
											</Link>
										</li>
									</ul>
									<div className="actions">
										{active === 0 && (
											<>
												<button
													className="button button-primary inline"
													onClick={() => {
														setAccountConfig(false)
														setShowMasterPassword(true)
													}}
												>
													<i className="mi">account_circle</i>
													<span>Cuenta y suscripciones</span>
												</button>
												<button
													className="button button-primary button-primary-outline inline"
													onClick={() => {
														localStorage.clear()
														auth.signOut()
														window.location.reload()
													}}
												>
													<i className="mi">exit_to_app</i>
													<span>{language.sign_out}</span>
												</button>
											</>
										)}
										{active === 1 && (
											<>
												<div className="search">
													<input
														disabled={false}
														type="text"
														placeholder={`Buscar Clientes...`}
														onChange={(e) =>
															setSearchClient(e.target.value)
														}
														value={searchClient}
														style={{
															minWidth: 200,
														}}
													/>
													<i className="mi">search</i>
												</div>
												<button
													className="button button-primary button-add inline"
													onClick={(e) => setSendMail(true)}
												>
													<i className="mi">email</i>{' '}
													<span>Enviar Emails</span>
												</button>
											</>
										)}
										{active === 2 && (
											<>
												<div className="search">
													<input
														disabled={false}
														type="text"
														placeholder={`Buscar Reservas...`}
														onChange={(e) =>
															setSearchReservation(
																e.target.value
															)
														}
														value={searchReservation}
														style={{
															minWidth: 200,
															marginTop: 10,
														}}
													/>
												</div>
												{searchReservation.length > 0 && (
													<>
														<button
															disabled={!searchChanged}
															className="button button-primary button-add inline"
															onClick={() => setDoSearch(true)}
														>
															<span>Buscar Reservas</span>
														</button>
													</>
												)}
												<button
													className="button button-light button-add inline"
													onClick={() => {
														setDoSearch(true)
														setSearchReservation('')
													}}
												>
													<i className="mi">close</i>
												</button>
												<More>
													{/* <button
										className="button button-light inline"
										style={{
											marginRight: 20,
										}}
										onClick={() => {
											htmlToImage
												.toPng(
													document.getElementById(
														'MAINSCREEN_SEARCH'
													)
												)
												.then(function (dataUrl) {
													download(
														dataUrl,
														`Lista Reservas Imprimir.png`
													)
												})
										}}
									>
										<i className="mi">camera_alt</i>
										IMAGEN
									</button> */}
													<button
														className="button button-light inline"
														style={{
															marginRight: 20,
														}}
														onClick={getExcel}
														disabled={downloadingFile}
													>
														<i className="mi">description</i>{' '}
														EXCEL
													</button>
													<button
														className="button button-light inline"
														style={{
															marginRight: 20,
														}}
														onClick={getPdf}
														disabled={downloadingFile}
													>
														<i className="mi">description</i> PDF
													</button>
												</More>
											</>
										)}
										{active === 3 && (
											<>
												<button
													className="button button-primary button-add inline"
													onClick={(e) =>
														setRenderCreateEmployee(true)
													}
												>
													<i className="mi">add</i>
													<span>Nuevo Empleado</span>
												</button>
											</>
										)}
										{active === 4 && (
											<>
												<div
													className="date-wrapper"
													style={{
														paddingRight: 0,
													}}
												>
													<DatePicker
														selected={dayViewing}
														onChange={(date) =>
															setDayViewing(date)
														}
														locale="es"
														customInput={
															<button className="button button-secondary">
																<span>
																	{formatDate(dayViewing) ===
																	formatDate(
																		new Date().getTime()
																	)
																		? 'Hoy'
																		: formatDate(dayViewing)}
																</span>
															</button>
														}
														popperClassName="some-custom-class"
														popperPlacement="bottom-left"
														popperModifiers={{
															offset: {
																enabled: true,
																offset: '5px, 10px',
															},
															preventOverflow: {
																enabled: true,
																escapeWithReference: false,
																boundariesElement: 'viewport',
															},
														}}
													/>
												</div>
											</>
										)}
										{active === 5 && (
											<>
												<button
													className="button button-link button-add inline"
													onClick={(e) => {
														setShowUploadClients(true)
													}}
												>
													<span>Subir clientes manual</span>
												</button>
												<button
													className="button button-primary button-add inline"
													onClick={(e) =>
														history.push(
															'/items-management-report'
														)
													}
												>
													<span>Informe</span>
												</button>
											</>
										)}
									</div>
								</div>
							)}
							{active === 0 && (
								<div className="choose-ui">
									<section>
										<ul>
											{groups.map((gr) => (
												<li>
													<div className="card vcard placecard">
														<div className="avatar has-image">
															<span
																className="img"
																style={{
																	backgroundImage: `url(${gr.list_image})`,
																}}
															>
																<i className="mi">store</i>
															</span>
														</div>
														<div className="body">
															<strong className="title">
																{gr.name}
															</strong>
															<em>Grupo</em>
														</div>
														<button
															className="button button-primary"
															onClick={() => setEditGroup(gr)}
														>
															<span>Editar</span>
														</button>
													</div>
												</li>
											))}
										</ul>
										<ul>{renderPlaces()}</ul>
										<div>
											<button
												className="button button-white button-outline-white"
												style={{
													opacity:
														(user.type === 'admin_free' &&
															user.places.length > 5) ||
														(user.type === 'admin_payasyougo' &&
															user.places.length > 10)
															? 0.2
															: 1,
												}}
												disabled={
													(user.type === 'admin_free' &&
														user.places.length > 5) ||
													(user.type === 'admin_payasyougo' &&
														user.places.length > 10)
												}
												onClick={() => {
													// Confirm password, but what to do when user is signed with google
													// setRedirect('/create-place')
													// onConfirmPassword()
													setShowMasterPassword(true)
													// Escaping password confirmation
													// history.replace('/create-place')
												}}
											>
												<i className="mi">library_add</i>
												<span>{language.new_place}</span>
											</button>
											<button
												className="button button-link"
												style={{
													opacity: user.type === 'admin_free',
													marginTop: 20,
												}}
												disabled={user.type === 'admin_free'}
												onClick={() => {
													setShowCreateGroup(true)
													setShowMasterPassword(true)
												}}
											>
												<i className="mi">add</i>
												<span>Crear grupo</span>
											</button>
										</div>
										<a
											className="button button-link button-block"
											href={`${window.location.origin}/terms/review`}
											target="popup"
											onClick={(e) => {
												window.open(
													`${window.location.origin}/terms/review`,
													'popup',
													'width=600,height=800'
												)
											}}
										>
											Leer Aviso legal y condiciones generales
										</a>
									</section>
									<section>
										{selectedPlace !== null && (
											<ul>{renderEmployees()}</ul>
										)}
										{selectedPlace === null && (
											<aside className="empty">
												<i className="mi">store</i>
												<h3>{language.no_place_selected}</h3>
											</aside>
										)}
									</section>
								</div>
							)}
							{active === 1 && (
								<>
									{placesClientsPl !== null && (
										<Clients
											sendMail={sendMail}
											setSendMail={setSendMail}
											_place={placesClientsPl}
											search={searchClient}
											groups={groups}
										/>
									)}
									{placesClientsPl === null && <Loading />}
								</>
							)}
							{active === 2 && (
								<>
									{placesCovidData === null && <Loading />}
									{placesCovidData !== null && (
										<>
											<div className="indicators-ui">
												<div>
													<div>
														<p>
															<span>Total Reservas:</span>{' '}
															<b>
																{totalHits > 100 ? '+ ' : ''}
																{totalHits}
															</b>
														</p>
													</div>
													<div>
														<p>
															<span>Total Personas:</span>{' '}
															<b>
																{totalHits > 100 ? '+ ' : ''}
																{peopleCount}
															</b>
														</p>
													</div>
												</div>
											</div>
											{pages > 1 && (
												<div
													style={{
														width: '100%',
														height: 'auto',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														flexDirection: 'row',
														flexWrap: 'wrap',
														marginTop: 20,
														marginBottom: 20,
													}}
												>
													{renderPagination()}
												</div>
											)}
											<div
												className="table-ui"
												id="MAINSCREEN_SEARCH"
												style={{
													backgroundColor: 'white',
												}}
											>
												<div>
													<div className="archive-filters">
														{Array.isArray(placesToChoose) &&
															placesToChoose.length > 0 && (
																<div
																	className="form-group upper-8"
																	style={{
																		width: 300,
																	}}
																>
																	<div className="form-row">
																		<div className="col-3">
																			<label>Lugar:</label>
																		</div>
																		<div className="col-9">
																			<Select
																				className="react-select"
																				classNamePrefix="react-select"
																				options={getPlacesAsOptions()}
																				onChange={(e) => {
																					if (e === null) {
																						setPlaceSearchFilter(
																							null
																						)
																					} else {
																						setPlaceSearchFilter(
																							e.value
																						)
																					}
																				}}
																				defaultValue={{
																					value: null,
																					label: 'Todos',
																				}}
																				value={{
																					value:
																						placeSearchFilter !==
																						null
																							? placeSearchFilter
																							: null,
																					label:
																						placeSearchFilter !==
																						null
																							? placesToChoose.find(
																									(
																										f
																									) =>
																										f.id ===
																										placeSearchFilter
																							  ).name
																							: 'Todos',
																				}}
																			/>
																		</div>
																	</div>
																</div>
															)}
														<div className="form-group">
															<div className="form-row">
																<div className="col-3">
																	<label>Desde:</label>
																</div>
																<div className="col-9">
																	<DatePicker
																		locale="es"
																		disabled={submitting}
																		selected={fromFilter}
																		onChange={(e) =>
																			e === null
																				? null
																				: setFromFilter(
																						e.getTime()
																				  )
																		}
																		className="form-control"
																		timeCaption="Hora 24"
																		placeholderText="Seleccionar Fecha..."
																		// showTimeSelect
																		timeFormat="HH:mm"
																		dateFormat="dd/MM/yy"
																	/>
																</div>
															</div>
														</div>
														<div className="form-group">
															<div className="form-row">
																<div className="col-3">
																	<label>Hasta:</label>
																</div>
																<div className="col-9">
																	<DatePicker
																		locale="es"
																		disabled={submitting}
																		selected={toFilter}
																		onChange={(e) =>
																			e === null
																				? null
																				: setToFilter(
																						e.getTime()
																				  )
																		}
																		minDate={
																			fromFilter ||
																			new Date()
																		}
																		className="form-control"
																		timeCaption="Hora 24"
																		placeholderText="Seleccionar Fecha..."
																		// showTimeSelect
																		timeFormat="HH:mm"
																		dateFormat="dd/MM/yy"
																	/>
																</div>
															</div>
														</div>
														<button
															className="button button-link"
															style={{
																display: 'inline',
																width: 'auto',
															}}
															onClick={() => {
																setFromFilter(null)
																setToFilter(null)
																setSearchReservation('')
																setPlaceSearchFilter(null)
															}}
														>
															Limpiar Filtros
														</button>
													</div>
													<table>
														<thead>
															<tr>
																<th>Lugar</th>
																<th>Fecha, Franja y Turno</th>
																<th>Nombre</th>
																{type === 0 && (
																	<>
																		<th>Habitación</th>
																		<th>Pensión</th>
																	</>
																)}
																<th>Personas</th>
																<th>Notas</th>
																<th>Correo</th>
																<th>Estado</th>
															</tr>
														</thead>
														{renderQueue()}
													</table>
												</div>
											</div>
										</>
									)}
								</>
							)}
							{active === 3 && (
								<Employees
									global_data={{
										employees,
										employee_categories: employeeCategories,
										id: user.places[0],
										setEmployees,
									}}
									global
									setRenderCreateEmployee={setRenderCreateEmployee}
									renderCreateEmployee={renderCreateEmployee}
								/>
							)}
							{active === 4 && (
								<ReservationHistory selectedDay={dayViewing} />
							)}
						</>
					)}
				/>
			</div>
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		language: state.language.dictionary,
		user: state.user,
		topbar: state.topbar,
	}),
	{ sign_employee, create_alert, update_user }
)(ChooseEmployee)

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { firestore } from '../../../config/firebase'
import { create_alert } from '../../../store/actions'

const dayNames = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday'
]

function Config({ place, create_alert, history }) {
	const [formState, setFormState] = useState([
		{
			days: [0],
			shifts: [
				{
					enter: [-1, 0],
					exit: [-1, 0],
					employees: {}
				}
			]
		}
	])
	const [usedDays, setUsedDays] = useState([])
	const [showAddDays, setShowAddDays] = useState(-1)
	const [red, setRedirect] = useState(false)

	useEffect(() => {
		if (place.schedule_config === null) return
		setFormState(JSON.parse(place.schedule_config))
		let _usedDays = []
		JSON.parse(place.schedule_config).forEach(gr => {
			gr.days.forEach(d => {
				_usedDays.push(d)
			})
		})
		setUsedDays(_usedDays)
	}, [])

	const renderDays = (days, index) => {
		return days.map(d => {
			return (
				<div
					key={Math.round(Math.random() * 100000000000)}
					style={{
						padding: 4,
						backgroundColor: 'rgba(0,0,0,0.1)',
						borderRadius: 4,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginRight: 10
					}}
				>
					<p style={{ margin: 0, fontSize: 14, marginLeft: 5 }}>
						{dayNames[d]}
					</p>
					<button
						onClick={() => {
							setUsedDays(usedDays.filter(dy => dy !== d))
							setFormState(
								formState.map((st, x) => {
									if (x !== index) return st
									return {
										...st,
										days: st.days.filter(dy => dy !== d)
									}
								})
							)
						}}
						style={{
							backgroundColor: 'transparent',
							border: 'none',
							outline: 'none'
						}}
					>
						<i className="icon-navigation-close"></i>
					</button>
				</div>
			)
		})
	}

	const renderTimes = (day, shift, type) => {
		let _res = new Array(31).fill(new Array(2).fill('t'))
		let res = []
		_res.forEach((a, b) => {
			let _b = b >= 24 ? b - 24 : b
			a.forEach((c, d) => {
				let _d = d >= 24 ? d - 24 : d
				let mins = d === 1 ? 30 : 0

				let __hours =
					_b < 10
						? `${b >= 24 ? '+ ' : ''}0${_b}`
						: `${b >= 24 ? '+ ' : ''}${_b}`
				let __mins_ = _d === 1 ? 30 : 0
				let __mins = __mins_ < 10 ? `0${__mins_}` : __mins_

				let val = `_${b}_${mins}`

				let disabled = false
				let key = type === -1 ? 'exit' : 'enter'
				let value = formState[day].shifts[shift][key]

				if (type === -1) {
					let current_numb = parseInt(
						`${b}${mins < 10 ? `0${mins}` : mins}`
					)
					let prev = formState[day].shifts[shift].enter
					let prev_numb = parseInt(
						`${prev[0]}${prev[1] < 10 ? `0${prev[1]}` : prev[1]}`
					)
					if (current_numb <= prev_numb) disabled = true
				} else if (type === 1) {
					let current_numb = parseInt(
						`${b}${mins < 10 ? `0${mins}` : mins}`
					)
					let prev = formState[day].shifts[shift].exit
					let prev_numb = parseInt(
						`${prev[0]}${prev[1] < 10 ? `0${prev[1]}` : prev[1]}`
					)
					if (current_numb >= prev_numb) disabled = true
					if (prev[0] === -1 || b === -1) disabled = false
				}

				if (!disabled)
					res.push(
						<option
							key={val}
							value={val}
						>{`${__hours}:${__mins}`}</option>
					)
			})
		})
		return res
	}

	const renderSpareDays = index => {
		let days = [0, 1, 2, 3, 4, 5, 6]
		days = days.filter(d => usedDays.indexOf(d) === -1)
		return (
			<ul style={{ position: 'absolute', top: 0, right: -100 }}>
				{days.map(d => (
					<li key={Math.round(Math.random() * 100000000)}>
						<button
							onClick={() => {
								setUsedDays([...usedDays, d])
								setFormState(
									formState.map((st, x) => {
										if (x !== index) return st
										return {
											...st,
											days: [...st.days, d]
										}
									})
								)
								setShowAddDays(-1)
							}}
						>
							{dayNames[d]}
						</button>
					</li>
				))}
			</ul>
		)
	}

	const renderTop = () => {
		let vals = [false, null, null, ...place.employee_categories].map(v => {
			if (v === false)
				return (
					<div
						key={Math.round(Math.random() * 100000000)}
						style={{
							height: 30,
							width: 30
						}}
					></div>
				)
			else if (v === null)
				return (
					<div
						key={Math.round(Math.random() * 100000000)}
						style={{
							height: 30,
							width: 80
						}}
					></div>
				)
			else if (v.length === 0) return null
			else
				return (
					<div
						key={Math.round(Math.random() * 100000000)}
						style={{
							height: 30,
							flex: '1 1 auto',
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							paddingLeft: 20
						}}
					>
						{v.name}
					</div>
				)
		})
		return (
			<div
				style={{
					display: 'flex'
				}}
			>
				{vals}
			</div>
		)
	}

	const renderTimeSelect = (day, shift) => {
		let ent = `_${formState[day].shifts[shift].enter[0]}_${formState[day].shifts[shift].enter[1]}`
		let ext = `_${formState[day].shifts[shift].exit[0]}_${formState[day].shifts[shift].exit[1]}`
		return [
			<div style={{ height: 30, width: 80 }}>
				<select
					style={{
						height: '100%',
						width: '100%'
					}}
					value={ent}
					onChange={e => {
						let val = e.target.value
						val = val.split('_')
						val = [parseInt(val[1]), parseInt(val[2])]
						setFormState(
							formState.map((ft, i) => {
								if (i !== day) return ft
								return {
									...ft,
									shifts: ft.shifts.map((sh, x) => {
										if (x !== shift) return sh
										return {
											...sh,
											enter: val
										}
									})
								}
							})
						)
					}}
				>
					<option value="_-1_0">--:--</option>
					{renderTimes(day, shift, 1)}
				</select>
			</div>,
			<div style={{ height: 30, width: 80 }}>
				<select
					style={{
						height: '100%',
						width: '100%'
					}}
					value={ext}
					onChange={e => {
						let val = e.target.value
						val = val.split('_')
						val = [parseInt(val[1]), parseInt(val[2])]
						setFormState(
							formState.map((ft, i) => {
								if (i !== day) return ft
								return {
									...ft,
									shifts: ft.shifts.map((sh, x) => {
										if (x !== shift) return sh
										return {
											...sh,
											exit: val
										}
									})
								}
							})
						)
					}}
				>
					<option value="_-1_0">--:--</option>
					{renderTimes(day, shift, -1)}
				</select>
			</div>
		]
	}

	const renderQuantitySelect = (day, shift) => {
		return place.employee_categories.map(cat => {
			if (cat.length === 0) return null
			let val = 0
			if (
				typeof formState[day].shifts[shift].employees[cat.id] !==
				'undefined'
			) {
				val = formState[day].shifts[shift].employees[cat.id]
			}
			return (
				<div
					key={Math.round(Math.random() * 100000000)}
					style={{
						height: 30,
						flex: '1 1 auto',
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center'
					}}
				>
					<select
						style={{
							height: '100%',
							width: '100%'
						}}
						value={val}
						onChange={e => {
							let val = parseInt(e.target.value)
							let key = cat.id
							setFormState(
								formState.map((ft, i) => {
									if (i !== day) return ft
									return {
										...ft,
										shifts: ft.shifts.map((sh, x) => {
											if (x !== shift) return sh
											return {
												...sh,
												employees: {
													...sh.employees,
													[key]: val
												}
											}
										})
									}
								})
							)
						}}
					>
						{[...new Array(cat.length + 1)].map((c, x) => (
							<option
								value={x}
								key={Math.round(Math.random() * 100000000)}
							>
								{x}
							</option>
						))}
					</select>
				</div>
			)
		})
	}

	const renderRows = (st, f) => {
		return st.shifts.map((sh, i) => (
			<div
				key={Math.round(Math.random() * 100000000)}
				style={{ display: 'flex', flexDirection: 'row' }}
			>
				<div
					style={{
						width: 30,
						height: 30
					}}
				>
					{i === st.shifts.length - 1 && (
						<button
							className="btn btn-link"
							style={{
								padding: 0,
								width: '100%',
								height: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}
							onClick={() => {
								setFormState(
									formState.map((fs, k) => {
										if (k !== f) return fs
										return {
											...fs,
											shifts: fs.shifts.filter((s, z) => z !== i)
										}
									})
								)
							}}
						>
							<i className="icon-content-remove-circle-outline"> </i>
						</button>
					)}
				</div>
				{renderTimeSelect(f, i)}
				{renderQuantitySelect(f, i)}
			</div>
		))
	}

	const renderSlots = () => {
		return formState.map((st, i) => (
			<div
				key={Math.round(Math.random() * 100000000)}
				className="border"
				style={{
					width: 800,
					margin: '0 auto',
					marginTop: 20,
					padding: 20,
					backgroundColor: 'rgba(0,0,0,0.025)',
					borderRadius: 4,
					position: 'relative'
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginBottom: 20,
						width: '100%',
						justifyContent: 'flex-start',
						alignItems: 'center'
					}}
				>
					{renderDays(st.days, i)}
					<div
						style={{
							position: 'relative'
						}}
					>
						<button
							className="btn btn-link"
							onClick={() => {
								if (i === showAddDays) setShowAddDays(-1)
								else setShowAddDays(i)
							}}
						>
							{i === showAddDays && 'Cancel'}
							{i !== showAddDays && '+ Add day'}
						</button>
						{showAddDays === i && renderSpareDays(i)}
					</div>
				</div>
				{renderTop()}
				{renderRows(st, i)}
				<button
					className="btn btn-link"
					onClick={() => {
						setFormState(
							formState.map((ft, y) => {
								if (y !== i) return ft
								return {
									...ft,
									shifts: [
										...ft.shifts,
										{
											enter: [-1, 0],
											exit: [-1, 0],
											employees: {}
										}
									]
								}
							})
						)
					}}
				>
					+ Add shift
				</button>
				<button
					className="btn btn-link"
					style={{
						position: 'absolute',
						top: 10,
						right: 10
					}}
					onClick={() => {
						setFormState(formState.filter((s, x) => x !== i))
					}}
				>
					<i className="icon-navigation-close"></i>
				</button>
			</div>
		))
	}

	let disabled = false
	let total_days = 0
	formState.forEach(ft => {
		total_days += ft.days.length
		ft.shifts.forEach(sh => {
			if (sh.enter[0] === -1 || sh.exit[0] === -1) disabled = true
		})
	})

	if (total_days === 0) disabled = true

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-start',
				flexDirection: 'column',
				marginBottom: 20
			}}
		>
			<h1
				style={{
					marginTop: 20,
					marginBottom: 20,
					width: '100%',
					textAlign: 'center'
				}}
			>
				Configure your shifts
			</h1>
			{renderSlots()}
			{usedDays.length < 7 && (
				<button
					onClick={() => {
						setFormState([
							...formState,
							{
								days: [],
								shifts: [
									{
										enter: [-1, 0],
										exit: [-1, 0],
										employees: {}
									}
								]
							}
						])
					}}
					style={{ width: 800, margin: '0 auto', marginTop: 20 }}
					className="btn btn-link"
				>
					Add working day
				</button>
			)}
			<button
				onClick={async () => {
					await firestore
						.collection('places')
						.doc(place.id)
						.update({
							schedule_config: JSON.stringify(formState)
						})
					history.replace('/employees/schedule')
				}}
				disabled={disabled}
				style={{ width: 800, margin: '0 auto', marginTop: 20 }}
				className="btn btn-primary"
			>
				Continue
			</button>
		</div>
	)
}

const mapStateToProps = state => ({
	place: state.place
})

export default connect(mapStateToProps, { create_alert })(Config)

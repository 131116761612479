import React from 'react'
import { Link } from 'react-router-dom'
import { Loading } from '../../../components'
import Helmet from 'react-helmet'

function ChoosePlace({ history, getPlace, placesToChoose, place }) {
	if (placesToChoose === null) return <Loading />

	let places = placesToChoose.map(_place => {
		let active = _place.id === place.id ? 'active' : ''
		return (
			<li
				key={_place.id}
				className={`list-group-item ${active}`}
				onClick={() => getPlace(_place.id)}
				key={_place.id}
			>
				<b />
				{_place.name}
			</li>
		)
	})

	return (
		<div>
			<div>
				<div>
					<Link to="/configuration">
						<i />
						<span>Back to account</span>
					</Link>
					<div>
						<div>
							<h5>Select a place</h5>
						</div>
						<ul>{places}</ul>
						<div>
							<Link to="/create-place">
								<i /> Create new place
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ChoosePlace

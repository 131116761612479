import React, { useState } from 'react'
import { connect } from 'react-redux'
import { functions, firestore } from '../../config/firebase'
import { create_alert } from '../../store/actions'
import { Formik, Form } from 'formik'
import { Input, More, ModalForm } from '../../components'

function Feedback({ topbar, place, create_alert, feedback }) {
	const [content, setContent] = useState('')
	const [submitting, setSubmitting] = useState(false)
	const [showRespond, setShowRespond] = useState(false)

	const formatDate = (date) => {
		let _date = new Date(date)
		let day = _date.getDate()
		let month = _date.getMonth() + 1
		let year = _date.getFullYear()
		return `${`0${day}`.slice(-2)}/${`0${month}`.slice(
			-2
		)}/${year} - ${`0${_date.getHours()}`.slice(
			-2
		)}:${`0${_date.getMinutes()}`.slice(-2)}`
	}

	const removeFeedback = async (id) => {
		await firestore.doc(`/places/${place.id}/feedback/${id}`).delete()
	}

	const renderFeedback = () => {
		return feedback.map((f) => {
			console.log('F', f)
			return (
				<tr key={f.id}>
					<td>{f.user.name}</td>
					<td>{formatDate(f.date)}</td>
					<td>{f.content}</td>
					<td>{f.table.length === 0 ? 'N/A' : f.table}</td>
					<td>
						<More>
							<button
								onClick={() => setShowRespond(f.id)}
								type="button"
								title="Chat"
							>
								<i className="mi">chat</i>
								<span>Responder</span>
							</button>
							<button
								onClick={() => removeFeedback(f.id)}
								type="button"
								title="Remove"
							>
								<i className="mi">delete</i>
								<span>Borrar</span>
							</button>
						</More>
					</td>
				</tr>
			)
		})
	}

	const renderRespond = () => {
		let obj = feedback.find((f) => f.id === showRespond)
		let userName = 'Cliente'
		if (typeof obj !== 'undefined') userName = obj.user.name
		return (
			<ModalForm hideClose size="sm">
				<Formik
					initialValues={{
						content: '',
					}}
					validate={(values) => {
						if (values.content.length === 0) return 'Campo invalido'
					}}
					onSubmit={async (values) => {
						try {
							setSubmitting(true)
							let fn = functions.httpsCallable('sendFeedback')
							const _func = functions.httpsCallable('sendNotification')

							await fn({
								...obj,
								id: obj.id,
								content: values.content,
								isResponse: true,
							})
							await removeFeedback(obj.id)

							_func({
								content: `${place.name} - ${values.content}`,
								client_id: obj.user.id,
								data: { screen: 'notifications' },
							})

							setShowRespond(false)
							create_alert('Respuesta enviada', 'success')
							setSubmitting(false)
						} catch (e) {
							setSubmitting(false)
						}
					}}
				>
					{({ errors, touched }) => (
						<Form className="form-ui">
							<h1>{`Respuesta para ${userName}`}</h1>
							<Input
								label={'Respuesta'}
								disabled={submitting}
								name="content"
								errors={errors}
								touched={touched}
							/>
							<footer>
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">Cargando...</span>
										</div>
									)}
									{!submitting && <span>Enviar</span>}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	return (
		<>
			{showRespond !== false && renderRespond()}
			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs limit-w"></ul>
				<div className="pull-right">
					<div className="actions"></div>
				</div>
			</div>
			{feedback.length > 0 && (
				<div className="table-ui">
					<div>
						<table>
							<thead>
								<tr>
									<th>Nombre cliente</th>
									<th>Fecha y hora</th>
									<th>Descripción</th>
									<th>Mesa</th>
									<th />
								</tr>
							</thead>
							<tbody>{renderFeedback()}</tbody>
						</table>
					</div>
				</div>
			)}
			{feedback.length === 0 && (
				<aside className="empty">
					<i className="mi">layers</i>

					<h3>No tienes incidencias</h3>
				</aside>
			)}
		</>
	)
}
export default connect(
	(state) => ({
		topbar: state.topbar,
		place: state.place,
		feedback: state.feedback,
	}),
	{
		create_alert,
	}
)(Feedback)

export default [
	'products',
	'tables',
	'orders',
	'queue',
	'reservations',
	'inventory',
	'sales',
	'employees',
	'administrator',
	'events'
]

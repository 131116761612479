import axios from 'axios'
import { Form, Formik, Field } from 'formik'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Input, Loading } from '../../components'
import _features from '../../config/features'
import { firestore, functions, auth } from '../../config/firebase'
import themes from '../../config/themes'
import { create_alert, update_place } from '../../store/actions'
import Map from './Map'

function PlaceSetup({
	user,
	update_place,
	create_alert,
	language,
	subcategories,
	history,
	onPlaceCreated = () => {},
}) {
	const def = {
		splitted: false,
		hours: [
			[
				[10, 0],
				[19, 0],
			],
			[
				[0, 0],
				[0, 0],
			],
		],
		opens: true,
	}

	const [geoPoint, setGeoPoint] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [loading, setLoading] = useState(true)
	const [openHours, setOpenHours] = useState(new Array(7).fill(def))
	const [features, setFeatures] = useState([
		'products',
		'events',
		'tables',
		'queue',
		'reservations',
		'employees',
		'reservations_covid',
	])

	useEffect(() => {
		try {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(res) => {
						setGeoPoint({
							lat: res.coords.latitude,
							lng: res.coords.longitude,
						})
						setLoading(false)
					},
					() => {
						setGeoPoint({
							lat: 40,
							lng: -75,
						})
						create_alert('No pudimos acceder a tu ubicación', 'warning')
						setLoading(false)
					}
				)
			} else {
				setGeoPoint({
					lat: 39.422485,
					lng: -0.4013385,
				})
				setLoading(false)
			}
		} catch (e) {
			setGeoPoint({
				lat: 39.422485,
				lng: -0.4013385,
			})
			setLoading(false)
		}
	}, [])

	const upper = (lower) => lower.charAt(0).toUpperCase() + lower.substring(1)

	const getPlaceSlug = (slug, number = 0, values) => {
		firestore
			.collection('places_list')
			.doc(slug)
			.get()
			.then((doc) => {
				if (doc.exists) {
					let _numb = number + 1
					let _slug = slug
					if (slug.split('_')[1] === 'undefined') {
						_slug += `_${number}`
					} else {
						_slug = `${slug.split('_')[0]}_${_numb}`
					}
					getPlaceSlug(_slug, _numb, values)
				} else {
					savePlace(slug, values)
				}
			})
	}

	const savePlace = async (slug, values) => {
		try {
			let user_places = user.places
			user_places.push(slug)

			let value = await axios.get(
				`https://maps.googleapis.com/maps/api/geocode/json?latlng=${geoPoint.lat},${geoPoint.lng}&key=AIzaSyDeio_usEPlxcZTa0nIv7mLE89R-_ljiDU&language=en`
			)
			let str = value.data.results.find(
				(res) => res.types.indexOf('street_address') !== -1
			)
			let area = value.data.results.find(
				(res) => res.types.indexOf('administrative_area_level_1') !== -1
			)
			area = area.address_components.find(
				(res) => res.types.indexOf('administrative_area_level_1') !== -1
			)
			area = area.short_name
			area = area.replace(/ /g, '_')
			area = area.toLowerCase()

			console.log('AREA', area)

			if (typeof str === 'undefined') {
				str = value.data.results[0]
			}

			let week_sch = []

			let place = {
				name: values.name,
				id: slug,
				owner: user.id,
				featured_image: null,
				list_image: null,
				area,
				type: values.type,
				invoiceNumb: 0,
				open: false,
				takeaway: values.takeaway,
				subcategory: values.subcategory,
				address: {
					value: str.formatted_address,
					latLng: {
						lat: geoPoint.lat,
						lng: geoPoint.lng,
					},
				},
				country: 'spain',
				employees: [
					{
						category: 'main_admin',
						created: new Date().getTime(),
						id: `${new Date().getTime()}${Math.floor(
							Math.random() * 10000000
						)}`,
						name: values.admin_name,
						pin: '',
					},
				],
				features,
				products: [],
				table_floors: 1,
				recommendations: 0,
				accumulated_product_recommendations: 0,
				employee_categories: [
					{
						id: 'main_admin',
						length: 1,
						name: 'Main Admin',
						permissions: [
							'products',
							'tables',
							'orders',
							'queue',
							'reservations',
							'inventory',
							'sales',
							'employees',
							'administrator',
							'tickets',
							'events',
							'owner',
						],
					},
				],
				billing: {
					name: '',
					dni: '',
					address: '',
					postal_code: '',
					community: '',
				},
				shopping_list: [],
				product_categories: [''],
				product_subcategories: {},
				taxRate: 21,
				created_at: Date.now(),
				orders: [],
				reservations: [],
				queue: [],
				openHours: JSON.stringify(openHours),
				promotions: [],
				stripe_connected: user.stripe_connected,
				schedule: {},
				schedule_config: null,
				collaborators: [],
				collaborating: [],
				showPlace: values.showPlace,
				allowOrders: values.allowOrders,
				acceptInplace: values.acceptInplace,
				acceptPickup: values.acceptPickup,
				is_grouped: false,
			}

			let place_listing = {
				name: values.name,
				id: slug,
				list_image: null,
				featured_image: null,
				area,
				type: values.type,
				open: false,
				subcategory: values.subcategory,
				is_grouped: false,
				address: {
					value: str.formatted_address,
					latLng: {
						lat: geoPoint.lat,
						lng: geoPoint.lng,
					},
				},
				country: 'spain',
				takeaway: values.takeaway,
				openHours: JSON.stringify(openHours),
				popularity: 10,
				products: [],
				product_categories: [],
				product_subcategories: [],
				features,
				stripe_connected: null,
				showPlace: values.showPlace,
				allowOrders: values.allowOrders,
				acceptInplace: values.acceptInplace,
				acceptPickup: values.acceptPickup,
			}

			console.log('A')

			firestore.runTransaction(async (trans) => {
				await trans.set(firestore.collection('places').doc(slug), place)

				await trans.set(
					firestore.collection('places_list').doc(slug),
					place_listing
				)

				await trans.set(
					firestore
						.collection('places')
						.doc(slug)
						.collection('table_maps')
						.doc('tables'),
					{
						floors: [
							{
								tables: [],
							},
						],
					}
				)

				await trans.update(firestore.collection('users').doc(user.id), {
					places: user_places,
				})
			})
			console.log('B')

			if (user.type === 'admin_premium') {
				console.log('C')

				const func_ = functions.httpsCallable('updateOrdersPricing')

				let placesSub = user.stripe_customer.subscriptions.data[0].items.data.find(
					(s) => s.plan.id === 'plan_HHUtYkkB8HAVBN'
				)
				if (typeof placesSub === 'undefined') throw { error: true }

				await func_({
					subscription_item: placesSub.id,
					quantity: user_places.length,
					action: 'set',
				})
			}
			console.log('D')

			let placeTypes = await firestore.doc(`/vars/placetypes`).get()
			if (placeTypes.exists) {
				placeTypes = placeTypes.data()
				let type_ = []
				let subcat_ = []
				if (placeTypes.types.indexOf(values.type) === -1)
					type_ = [values.type]
				if (placeTypes.subcategories.indexOf(values.subcategory) === -1)
					subcat_ = [values.subcategory]
				await firestore.doc(`/vars/placetypes`).update({
					types: [...placeTypes.types, ...type_],
					subcategories: [...placeTypes.subcategories, ...subcat_],
				})
			} else {
				await firestore.doc(`/vars/placetypes`).set({
					types: [values.type],
					subcategories: [values.subcategory],
				})
			}
			console.log('G')

			update_place(place)
			onPlaceCreated()
			history.replace('/')
		} catch (e) {
			setSubmitting(false)
			console.log('ERROR #1', e)

			create_alert(language.an_error_ocurred, 'danger')
		}
	}

	const onSubmit = (v) => {
		setSubmitting(true)
		let slug = v.name.replace(/ /g, '_')
		slug = slug.toLowerCase()
		getPlaceSlug(slug, 0, v)
	}

	const validate = (values) => {
		let errors = {}
		if (values.name.length === 0) errors.name = language.cant_be_empty
		if (values.type === '') errors.type = language.cant_be_empty
		if (values.subcategory === '') errors.subcategory = language.cant_be_empty
		if (values.admin_name.length < 2)
			errors.admin_name = language.cant_be_empty
		if (geoPoint === null) errors.address = language.select_a_place
		if (Object.keys(errors).length > 0) {
			console.log('ERROR #2', errors)

			create_alert(language.an_error_ocurred, 'danger')
		}
		if (
			values.acceptInplace === false &&
			values.acceptPickup === false &&
			values.allowOrders === true
		) {
			create_alert(
				'Necesitas aceptar por lo menos un tipo de pedido',
				'danger'
			)
			return {
				acceptInplace: 'error',
				acceptPickup: 'error',
				allowOrders: 'error',
			}
		}
		return errors
	}

	const renderTimes = (obj, type) => {
		let _res = new Array(31).fill(new Array(2).fill('t'))
		let res = []
		_res.forEach((a, b) => {
			let _b = b >= 24 ? b - 24 : b
			a.forEach((c, d) => {
				let _d = d >= 24 ? d - 24 : d
				let mins = d === 1 ? 30 : 0
				let _mins = mins < 10 ? `0${mins}` : mins
				let _hours = b < 10 ? `0${b}` : b

				let __hours =
					_b < 10
						? `${b >= 24 ? '+ ' : ''}0${_b}`
						: `${b >= 24 ? '+ ' : ''}${_b}`
				let __mins_ = _d === 1 ? 30 : 0
				let __mins = __mins_ < 10 ? `0${__mins_}` : __mins_

				let disabled = false

				if (type === 0) {
					let _time = obj[0][1]
					let _mins_ = _time[1] < 10 ? `0${_time[1]}` : _time[1]
					let _hours_ = _time[0] < 10 ? `0${_time[0]}` : _time[0]
					disabled =
						parseInt(`${_hours}${_mins}`) >=
						parseInt(`${_hours_}${_mins_}`)
				} else if (type === 1) {
					let _time = obj[0][0]
					let _mins_ = _time[1] < 10 ? `0${_time[1]}` : _time[1]
					let _hours_ = _time[0] < 10 ? `0${_time[0]}` : _time[0]
					disabled =
						parseInt(`${_hours}${_mins}`) <=
						parseInt(`${_hours_}${_mins_}`)
				} else if (type === 2) {
					let _time = obj[0][1]
					let _mins_ = _time[1] < 10 ? `0${_time[1]}` : _time[1]
					let _hours_ = _time[0] < 10 ? `0${_time[0]}` : _time[0]
					disabled =
						parseInt(`${_hours}${_mins}`) <=
						parseInt(`${_hours_}${_mins_}`)
				} else if (type === 3) {
					let _time = obj[1][0]
					let _mins_ = _time[1] < 10 ? `0${_time[1]}` : _time[1]
					let _hours_ = _time[0] < 10 ? `0${_time[0]}` : _time[0]
					disabled =
						parseInt(`${_hours}${_mins}`) <=
						parseInt(`${_hours_}${_mins_}`)
				}

				res.push(
					<option
						disabled={disabled}
						key={`${b}_${mins}`}
						value={[b, mins]}
					>{`${__hours}:${__mins}`}</option>
				)
			})
		})
		return res
	}

	const renderOpenHours = () => {
		let rows = []
		let weekdays = [
			upper(language.monday),
			upper(language.tuesday),
			upper(language.wednesday),
			upper(language.thursday),
			upper(language.friday),
			upper(language.saturday),
			upper(language.sunday),
		]
		for (let x = 0; x < openHours.length; x++) {
			let row = []
			row.push(
				<div key={x} id={x}>
					<div className="input-check">
						<input
							id={`switch_${x}`}
							name={`switch_${x}`}
							type="checkbox"
							checked={openHours[x].opens}
							className="check-hours"
							onChange={(e) => {
								let _op = [...openHours]
								_op[x] = {
									..._op[x],
									opens: e.target.checked,
								}
								setOpenHours(_op)
							}}
						/>
						<label htmlFor={`switch_${x}`}>Toggle</label>
					</div>
					<p>
						{weekdays[x]}
						<button
							disabled={!openHours[x].opens}
							type="button"
							onClick={() => {
								let _op = [...openHours]
								_op[x] = {
									..._op[x],
									splitted: !_op[x].splitted,
								}
								_op[x].hours[1][0] = [
									_op[x].hours[0][1][0] + 1,
									_op[x].hours[0][1][1],
								]
								_op[x].hours[1][1] = [
									_op[x].hours[0][1][0] + 2,
									_op[x].hours[0][1][1],
								]
								setOpenHours(_op)
							}}
						>
							{openHours[x].splitted
								? 'Servicio normal'
								: 'Dividir servicio'}
						</button>
					</p>
					<div>
						<div className="select">
							<select
								disabled={!openHours[x].opens}
								value={`${openHours[x].hours[0][0]}`}
								onChange={(e) => {
									setOpenHours(
										openHours.map((op, i) => {
											if (i !== x) return op
											let val = e.target.value.split(',')
											val = val.map((vl) => parseInt(vl))
											return {
												...op,
												hours: [[val, op.hours[0][1]], op.hours[1]],
											}
										})
									)
								}}
							>
								{renderTimes(openHours[x].hours, 0)}
							</select>
						</div>
						<div className="select">
							<select
								disabled={!openHours[x].opens}
								value={`${openHours[x].hours[0][1]}`}
								onChange={(e) => {
									setOpenHours(
										openHours.map((op, i) => {
											if (i !== x) return op
											let val = e.target.value.split(',')
											val = val.map((vl) => parseInt(vl))
											return {
												...op,
												hours: [[op.hours[0][0], val], op.hours[1]],
											}
										})
									)
								}}
							>
								{renderTimes(openHours[x].hours, 1)}
							</select>
						</div>
						{openHours[x].splitted && (
							<>
								<p> - </p>
								<div className="select">
									<select
										disabled={!openHours[x].opens}
										value={`${openHours[x].hours[1][0]}`}
										onChange={(e) => {
											setOpenHours(
												openHours.map((op, i) => {
													if (i !== x) return op
													let val = e.target.value.split(',')
													val = val.map((vl) => parseInt(vl))
													return {
														...op,
														hours: [
															op.hours[0],
															[val, op.hours[1][1]],
														],
													}
												})
											)
										}}
									>
										{renderTimes(openHours[x].hours, 2)}
									</select>
								</div>
								<div className="select">
									<select
										disabled={!openHours[x].opens}
										value={`${openHours[x].hours[1][1]}`}
										onChange={(e) => {
											setOpenHours(
												openHours.map((op, i) => {
													if (i !== x) return op
													let val = e.target.value.split(',')
													val = val.map((vl) => parseInt(vl))
													return {
														...op,
														hours: [
															op.hours[0],
															[op.hours[1][0], val],
														],
													}
												})
											)
										}}
									>
										{renderTimes(openHours[x].hours, 3)}
									</select>
								</div>
							</>
						)}
					</div>
				</div>
			)
			rows.push(row)
		}
		return <>{rows}</>
	}

	const renderFeatures = (type) => {
		// Filtered features
		let filt_features = _features.filter((feat) => {
			switch (feat.key) {
				case 'products':
					return true
				case 'tables':
					if (type === 'restaurant' || type === 'disco') return true
					return false
				case 'events':
					return true
				case 'employees':
					return true
				case 'queue':
					return true
				case 'reservations':
					return true
				case 'reservations_covid':
					return true
				default:
					return false
			}
		})
		let descriptions = {
			products:
				'Recibe pedidos desde la App (Mas adelante puedes configurar las formas de pago).',
			tables: 'Maneja los estados de los pedidos en las mesas de tu local.',
			events: 'Crea y vende entradas para los eventos en tu local.',
			employees:
				'Administra a todos tus empleados, asigna roles, y analiza las estadisticas del tiempo.',
			queue: 'Crea filas virtuales para tu local evitando congestiones.',
			reservations:
				'Administra las reservas de las mesas de tu local (Requiere Mesas).',
			reservations_covid: '',
		}
		let fields = filt_features.map((feat) => (
			<div>
				<input
					key={feat.key}
					type="checkbox"
					name={`feat_${feat.key}`}
					id={`feat_${feat.key}`}
					checked={features.indexOf(feat.key) !== -1}
					onChange={(e) => {
						let _feats = [...features]

						if (e.target.checked) {
							setFeatures([..._feats, feat.key])
						} else if (_feats.length > 1) {
							if (feat.key !== 'products') {
								setFeatures(_feats.filter((f) => f !== feat.key))
							}
						}
					}}
				/>
				<label htmlFor={`feat_${feat.key}`}>
					<b></b>{' '}
					<span>
						{feat.key === 'products'
							? 'Pedidos'
							: feat.key === 'reservations_covid' &&
							  typeof user.custom !== 'undefined' &&
							  user.custom.indexOf('covid_res') !== -1
							? 'Reservas Covid19'
							: language[feat.key]}
					</span>
					<em style={{ marginTop: 10, fontSize: 14 }}>
						{descriptions[feat.key]}
					</em>
				</label>
			</div>
		))
		return (
			<div className="form-group form-group-check multiple">
				<div className="form-row">{fields}</div>
			</div>
		)
	}

	const getPlaceOptions = () => {
		let vars = [
			{
				value: 'restaurant',
				label: language.restaurant,
			},
		]

		if (user.type !== 'admin_free') {
			vars = [
				{ value: '', label: language.select_type },
				...vars,
				{
					value: 'store',
					label: language.store,
				},
				{
					value: 'disco',
					label: language.discos_and_pubs,
				},
				{
					value: 'beauty',
					label: 'Belleza',
				},
			]
		}
		return vars
	}

	if (themes === null || loading || subcategories === null) return <Loading />

	return (
		<div className="login-ui login-ui-lg">
			<div>
				<Formik
					initialValues={{
						name: '',
						theme: themes[0].value,
						address: null,
						type: '',
						admin_name: 'Admin',
						admin_password: '',
						admin_password_re: '',
						subcategory: '',
						takeaway: false,
						showPlace: false,
						allowOrders: user.type === 'admin_payasyougo',
						acceptInplace: false,
						acceptPickup: false,
					}}
					validateOnBlur={false}
					validateOnChange={false}
					onSubmit={onSubmit}
					validate={validate}
				>
					{({ errors, touched, setFieldValue, values }) => (
						<Form className="form-ui">
							<h1>{language.create_your_place}</h1>
							<Input
								placeholder=""
								name="name"
								errors={errors}
								touched={touched}
								label={language.place_name}
								submitting={submitting}
							/>

							<div className="form-group upper-8">
								<div className="form-row">
									<div className="col-3">
										<label>{language.category}:</label>
									</div>
									<div className="col-9">
										<Select
											className="react-select"
											classNamePrefix="react-select"
											options={getPlaceOptions()}
											onChange={(e) => {
												setFieldValue('type', e.value)
											}}
											defaultValue={{
												value: '',
												label: language.select_type,
											}}
										/>
									</div>
								</div>
								{errors.type && touched.type ? (
									<small>
										<span>{errors.type}</span>
									</small>
								) : null}
							</div>
							{values.type !== '' && (
								<div className="form-group upper-5">
									<div className="form-row">
										<div className="col-3">
											<label>{language.subcategory}:</label>
										</div>
										<div className="col-9">
											<Select
												className="react-select"
												classNamePrefix="react-select"
												options={[
													{
														value: '',
														label: language.select_subcategory,
													},
													...subcategories[values.type].map(
														(x) => ({
															value: x,
															label: upper(language[x]),
														})
													),
													// { value: 'event', label: 'Events' }
												]}
												onChange={(e) => {
													setFieldValue('subcategory', e.value)
												}}
												defaultValue={{
													value: '',
													label: language.select_subcategory,
												}}
											/>
										</div>
									</div>
									{errors.subcategory && touched.subcategory ? (
										<small>
											<span>{errors.subcategory}</span>
										</small>
									) : null}
								</div>
							)}

							{/* {values.subcategory !== '' && (
								<div className="form-group form-group-check">
									<div className="form-row">
										<div className="col-12">
											<Field
												type="checkbox"
												name="takeaway"
												id="takeaway"
												checked={values.takeaway}
											/>
											<label htmlFor="takeaway">
												<b></b> <span>¿Ofreces Domicilios?</span>
											</label>
										</div>
									</div>
								</div>
							)} */}

							<p className="info">
								<i className="mi">info</i>
								<strong>
									Antes de mostrar tu lugar en la App te recomendamos
									agregar productos y foto de perfil.{' '}
								</strong>
								<br />
								<em>
									Siempre puedes cambiar las opciones desde la pestaña
									"Configuración"
								</em>
							</p>

							<div className="form-group form-group-check">
								<div className="form-row">
									<div className="col-12">
										<Field
											type="checkbox"
											name="showPlace"
											id="showPlace"
											checked={values.showPlace}
										/>
										<label htmlFor="showPlace">
											<b></b> <span>Mostrar mi lugar en la App</span>
										</label>
									</div>
								</div>
							</div>

							{values.showPlace && user.type !== 'admin_free' && (
								<div className="form-group form-group-check">
									<div className="form-row">
										<div className="col-12">
											<Field
												disabled={user.type !== 'admin_premium'}
												type="checkbox"
												name="allowOrders"
												id="allowOrders"
												checked={values.allowOrders}
											/>
											<label htmlFor="allowOrders">
												<b></b>{' '}
												<span>Recibir Pedidos desde la App</span>
											</label>
										</div>
									</div>
								</div>
							)}
							{values.allowOrders && user.type === 'admin_premium' && (
								<>
									<div className="form-group form-group-check">
										<div className="form-row">
											<div className="col-12">
												<Field
													type="checkbox"
													name="acceptPickup"
													id="acceptPickup"
													checked={values.acceptPickup}
												/>
												<label htmlFor="acceptPickup">
													<b></b>{' '}
													<span>Acepto pedidos para recoger</span>
												</label>
											</div>
										</div>
									</div>
									<div className="form-group form-group-check">
										<div className="form-row">
											<div className="col-12">
												<Field
													type="checkbox"
													name="acceptInplace"
													id="acceptInplace"
													checked={values.acceptInplace}
												/>
												<label htmlFor="acceptInplace">
													<b></b>{' '}
													<span>
														Acepto pedidos para consumir aquí
													</span>
												</label>
											</div>
										</div>
									</div>
								</>
							)}

							<div className="form-group form-group-map upper-2">
								<div className="location-map">
									{errors.address ? (
										<small>{errors.address}</small>
									) : null}
									<Map
										_point={geoPoint}
										onPointSelected={(latLng) => {
											setGeoPoint({
												lat: latLng.lat,
												lng: latLng.lng,
											})
											setFieldValue('address', latLng)
										}}
									/>
								</div>
							</div>
							<div className="form-group form-group-schedule">
								<div>
									<label>{language.schedule}:</label>
									<div>{renderOpenHours()}</div>
								</div>
							</div>
							{/* <h1>{language.admin_credentials}</h1>
							<small
								style={{
									paddingLeft: 40,
									paddingRight: 40,
									marginTop: 20,
								}}
							>
								Esta contraseña es para diferenciar el{' '}
								<strong>administrador</strong> de los empleados, por
								seguridad <strong>no uses la misma contraseña</strong>{' '}
								que usas para entrar a la cuenta de Vywap.
							</small>
							<Input
								name="admin_name"
								errors={errors}
								touched={touched}
								label={language.name}
								submitting={submitting}
								disabled
							/>
							<Input
								name="admin_password"
								errors={errors}
								touched={touched}
								label={language.password}
								submitting={submitting}
								type="password"
							/>
							<Input
								name="admin_password_re"
								errors={errors}
								touched={touched}
								label={language.repassword}
								submitting={submitting}
								type="password"
							/> */}
							{user.type === 'admin_premium' && (
								<>
									<h1>{language.active_features}</h1>
									{renderFeatures(values.type)}
								</>
							)}
							<footer>
								<button
									disabled={submitting}
									type="submit"
									className="button button-primary"
								>
									{submitting && (
										<div role="status">
											<span>{language.loading}...</span>
										</div>
									)}
									{!submitting && language.continue}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</div>
			<button
				className="button button-link"
				style={{
					marginBottom: 30,
					marginTop: 20,
				}}
				onClick={(e) => {
					auth.signOut()
					localStorage.clear()
					window.location.reload()
				}}
			>
				Cerrar sesión
			</button>
		</div>
	)
}

export default connect(
	(state) => ({
		language: state.language.dictionary,
		lang: state.language.lang,
		subcategories: state.subcategories,
	}),
	{
		update_place,
		create_alert,
	}
)(PlaceSetup)

import addDays from 'date-fns/addDays'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import { Input } from '../../../components'
import Select from 'react-select'
import { firestore, storage } from '../../../config/firebase'
import { events } from '../../../store/actions'

function EditEvent({
	place,
	setEditEvent,
	updateEvents,
	event,
	language,
	set_events,
	events,
	products,
}) {
	const [submitting, setSubmitting] = useState(false)
	const [groups, setGroups] = useState(event.groups)
	const [gallery, setGallery] = useState(event.images)
	const [promotions, setPromotions] = useState(event.promotions)

	const getProductsAsOptions = () => {
		return products.map((p) => ({
			label: p.name,
			value: p.id,
		}))
	}

	const getDefaultVal = (gr) => {
		let prods = gr.products || []
		return prods.map((p) => {
			let pr = products.find((_p) => _p.id === p)
			if (typeof pr !== 'undefined')
				return {
					value: p,
					label: pr.name,
				}
		})
	}

	const updateEvent = async (values) => {
		setSubmitting(true)
		let storageRef = storage.ref()

		let imagesDownloadUrls = []

		for (let ph in gallery) {
			if (typeof gallery[ph] === 'string')
				imagesDownloadUrls.push(gallery[ph])
			else {
				const listImageRef = storageRef.child(
					`/events/${place.id}/${event.slug}/image_${ph}.jpg`
				)
				await listImageRef.putString(gallery[ph].src, 'data_url')
				let dwnUrl = await listImageRef.getDownloadURL()
				imagesDownloadUrls.push(dwnUrl)
			}
		}
		let _event = {
			...event,
			...values,
			area: place.area,
			place: {
				id: place.id,
				name: place.name,
			},
			images: imagesDownloadUrls,
			startDate: new Date(values.startDate).getTime(),
			endDate: new Date(values.endDate).getTime(),
			groups: groups.map((gr) => ({
				purchased: 0,
				...gr,
			})),
			promotions,
		}
		await firestore
			.collection('places')
			.doc(place.id)
			.collection('events')
			.doc(event.slug)
			.set(_event)

		await firestore
			.collection('events_list')
			.doc(event.slug)
			.set({
				...event,
				...values,
				area: place.area,
				popularity: _event.popularity,
				images: imagesDownloadUrls,
				slug: _event.slug,
				promotions,
				groups: _event.groups,
				startDate: _event.startDate,
				endDate: _event.endDate,
			})

		set_events(
			events.map((e) => {
				if (e.slug !== _event.slug) return e
				return _event
			})
		)

		setSubmitting(false)
		setEditEvent(null)
	}

	const validateEvent = (values) => {
		let errors = {}
		if (values.name === '') errors.name = language.cant_be_empty
		if (groups.length === 0) errors.groups = language.cant_be_empty
		if (values.age < 0 || values.age - Math.floor(values.age) !== 0)
			errors.age = language.invalid_field
		if (values.endDate === null) errors.endDate = language.invalid_field
		if (values.startDate >= values.endDate)
			errors.endDate = language.invalid_field

		groups.forEach((gr) => {
			if (typeof gr.name !== 'string' || gr.name.length <= 0)
				errors.groups = `${language.invalid_field}`
			if (gr.seats <= 0) errors.groups = `${language.invalid_field}`
			if (gr.price < 0) errors.groups = `${language.invalid_field}`
			if (gr.seats - gr.purchased < 0) errors.groups = language.invalid_field
		})

		return errors
	}

	const onAddImage = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				let img = new Image()
				img.onload = () => {
					setGallery([
						...gallery,
						{
							src: reader.result,
							width: img.width,
							height: img.height,
							saved: false,
						},
					])
				}
				img.src = reader.result
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}

	const renderCreateEvent = () => {
		return (
			<div className="modal-form modal-form-md show">
				<div>
					<Formik
						onSubmit={updateEvent}
						validate={validateEvent}
						initialValues={{
							name: event.name,
							description: event.description,
							startDate: new Date(event.startDate),
							endDate: new Date(event.endDate),
							groups: event.groups,
							age: event.age,
						}}
					>
						{({
							errors,
							touched,
							values,
							setFieldValue,
							setFieldTouched,
						}) => {
							return (
								<Form className="form-ui">
									<h1>{language.edit_event}</h1>
									<Input
										label={language.name}
										submitting={submitting}
										type="text"
										name="name"
										errors={errors}
										touched={touched}
										cols={8}
										colside="left"
										rowcolleft={3}
										rowcolright={9}
									/>
									<Input
										label={language.minimum_age}
										submitting={submitting}
										type="number"
										name="age"
										errors={errors}
										touched={touched}
										cols={4}
										colside="right"
										rowcolleft={6}
										rowcolright={6}
									/>
									<div className="form-group cols-12">
										<div className="form-row">
											<div>
												<label htmlFor="notes">
													{language.description} :
												</label>
											</div>
											<div>
												<Field
													name="description"
													disabled={submitting}
													component="textarea"
												/>
											</div>
										</div>
									</div>
									<div className="form-group colside-left cols-6">
										<div className="form-row">
											<div className="col-5">
												<label>{language.start_date}</label>
											</div>
											<div className="col-7">
												<DatePicker
													disabled={submitting}
													selected={values.startDate}
													onChange={(e) =>
														setFieldValue('startDate', e)
													}
													showTimeSelect
													timeFormat="HH:mm"
													timeCaption={language.hour}
													minDate={addDays(new Date(), 1)}
													maxDate={values.endDate}
													timeIntervals={30}
													dateFormat="dd/MM/yy - HH:mm"
													className="form-control"
													locale="es"
												/>
											</div>
										</div>
									</div>

									<div
										className={`form-group colside-right cols-6 ${
											errors.endDate && touched.endDate
												? 'is-invalid'
												: ''
										}`}
									>
										<div className="form-row">
											<div className="col-5">
												<label>{language.end_date}</label>
											</div>
											<div className="col-7">
												<DatePicker
													disabled={submitting}
													selected={values.endDate}
													locale="es"
													onChange={(e) => {
														setFieldValue('endDate', e)
														setFieldTouched('endDate', true)
													}}
													showTimeSelect
													timeFormat="HH:mm"
													timeCaption={language.hour}
													timeIntervals={30}
													dateFormat="dd/MM/yy - HH:mm"
													className="form-control"
													minDate={values.startDate}
												/>
											</div>
										</div>
										{errors.endDate && touched.endDate ? (
											<small>
												<span>{errors.endDate}</span>
											</small>
										) : null}
									</div>

									{groups.length > 0 && (
										<>
											{groups.map((gr, i) => {
												let fieldset_class = ''
												if (i === 0)
													fieldset_class += ' first-child'
												if (i === groups.length - 1)
													fieldset_class += ' last-child'
												return (
													<fieldset
														key={gr.id}
														className={fieldset_class}
													>
														<div className="fieldset-header">
															<h2>{gr.name}</h2>
															<button
																disabled={
																	submitting ||
																	gr.purchased > 0
																}
																onClick={(e) => {
																	e.preventDefault()
																	setGroups(
																		groups.filter(
																			(p, j) => j !== i
																		)
																	)
																}}
																type="button"
																className="button button-link button-link-sm button-link-delete"
															>
																<i className="mi">delete</i>
																<span>{language.remove}</span>
															</button>
														</div>
														<div className="fieldset-body">
															<Input
																custom={{
																	autoComplete: 'off',
																	value: gr.name,
																	onChange: (e) => {
																		setGroups(
																			groups.map((p, j) => {
																				if (i !== j)
																					return p
																				return {
																					...p,
																					name:
																						e.target
																							.value,
																				}
																			})
																		)
																	},
																}}
																label={language.name}
																disabled={submitting}
																type="text"
																name="name"
																errors={errors}
																touched={touched}
																placeholder="Ejm: VIP"
																noformik
																rowcolleft={4}
																rowcolright={8}
															/>
															{/* <Input
																custom={{
																	autoComplete: 'off',
																	value: isNaN(
																		parseFloat(gr.price)
																	)
																		? 0
																		: parseFloat(gr.price),
																	onChange: (e) => {
																		setGroups(
																			groups.map((p, j) => {
																				if (i !== j)
																					return p
																				return {
																					...p,
																					price: isNaN(
																						parseFloat(
																							e.target
																								.value
																						)
																					)
																						? 0
																						: parseFloat(
																								e.target
																									.value
																						  ),
																				}
																			})
																		)
																	},
																}}
																label={language.price}
																disabled={submitting}
																type="number"
																name="name"
																errors={errors}
																touched={touched}
																placeholder="24"
																noformik
																cols={4}
																colside="right"
																rowcolleft={4}
																rowcolright={8}
																append={
																	<i className="mi">
																		euro_symbol
																	</i>
																}
															/> */}
															<Input
																custom={{
																	autoComplete: 'off',
																	value: isNaN(
																		parseInt(gr.seats)
																	)
																		? 0
																		: parseInt(gr.seats),
																	onChange: (e) => {
																		setGroups(
																			groups.map((p, j) => {
																				if (i !== j)
																					return p
																				return {
																					...p,
																					seats: isNaN(
																						parseInt(
																							e.target
																								.value
																						)
																					)
																						? 0
																						: parseInt(
																								e.target
																									.value
																						  ),
																				}
																			})
																		)
																	},
																}}
																label={language.quantity}
																disabled={submitting}
																type="number"
																name="name"
																errors={errors}
																touched={touched}
																placeholder="250"
																noformik
															/>
															<div className="form-group upper-7">
																<div className="form-row">
																	<div className="col-3">
																		<label>
																			Productos Incluidos
																		</label>
																	</div>
																	<div className="col-9">
																		<Select
																			placeholder={'Ninguno'}
																			options={getProductsAsOptions()}
																			defaultValue={getDefaultVal(
																				gr
																			)}
																			onChange={(e) => {
																				if (e === null)
																					setGroups(
																						groups.map(
																							(p, j) => {
																								if (
																									i !==
																									j
																								)
																									return p
																								return {
																									...p,
																									products: [],
																								}
																							}
																						)
																					)
																				else
																					setGroups(
																						groups.map(
																							(p, j) => {
																								if (
																									i !==
																									j
																								)
																									return p
																								return {
																									...p,
																									products: e.map(
																										(
																											e
																										) =>
																											e.value
																									),
																								}
																							}
																						)
																					)
																			}}
																			isMulti
																			className="react-select"
																			classNamePrefix="react-select"
																		/>
																	</div>
																</div>
															</div>
															{typeof gr.purchased ===
																'number' && (
																<Input
																	custom={{
																		value: gr.purchased,
																	}}
																	label={language.sold}
																	disabled={true}
																	type="text"
																	name="purchased"
																	noformik
																	cols={6}
																	colside="left"
																	rowcolleft={6}
																	rowcolright={6}
																/>
															)}
															{!isNaN(
																gr.seats - gr.purchased
															) && (
																<Input
																	custom={{
																		value:
																			gr.seats -
																				gr.purchased <
																			0
																				? language.invalid_field
																				: gr.seats -
																				  gr.purchased,
																	}}
																	label={language.left}
																	disabled={true}
																	type="text"
																	name="left"
																	noformik
																	cols={6}
																	colside="right"
																	rowcolleft={6}
																	rowcolright={6}
																/>
															)}
														</div>
													</fieldset>
												)
											})}
										</>
									)}
									<div className="adder">
										<button
											disabled={submitting}
											type="button"
											className="button button-light"
											onClick={() =>
												setGroups([
													...groups,
													{
														name: '',
														seats: 0,
														price: 0,
														id: Math.floor(Math.random() * 10000),
													},
												])
											}
										>
											<i className="mi">add</i>
											<span>{language.new_area}</span>
										</button>
									</div>

									{gallery !== null &&
										gallery.map((img, i) => {
											let fieldset_class = ''
											if (i === 0) fieldset_class += ' first-child'
											if (i === gallery.length - 1)
												fieldset_class += ' last-child'
											let src =
												typeof img === 'string' ? img : img.src
											return (
												<fieldset
													key={Math.floor(Math.random() * 10000)}
													className={`${fieldset_class} fieldset-image`}
												>
													<div className="fieldset-header">
														<h2></h2>
														<button
															onClick={(e) => {
																setGallery(
																	gallery.filter(
																		(a, b) => b !== i
																	)
																)
															}}
															type="button"
															className="button button-link button-link-sm button-link-delete"
														>
															<i className="mi">delete</i>
															<span>{language.remove}</span>
														</button>
													</div>
													<img src={src}></img>
												</fieldset>
											)
										})}
									{gallery === null && <fieldset>Loading...</fieldset>}

									<div className="adder">
										<label
											className="button button-light"
											htmlFor="addImageInput"
										>
											<i className="mi">add</i>
											<span>{language.add_image}</span>
										</label>
									</div>
									<input
										disabled={submitting}
										id="addImageInput"
										type="file"
										accept="image/x-png,image/jpeg"
										onChange={onAddImage}
									/>

									{promotions.length > 0 && (
										<>
											{promotions.map((pr, i) => {
												let fieldset_class = ''
												if (i === 0)
													fieldset_class += ' first-child'
												if (i === promotions.length - 1)
													fieldset_class += ' last-child'
												return (
													<fieldset
														key={pr.id}
														className={fieldset_class}
													>
														<div className="fieldset-header">
															<h2>{pr.name}</h2>
															<button
																disabled={submitting}
																type="button"
																onClick={(e) => {
																	e.preventDefault()
																	setPromotions(
																		promotions.filter(
																			(p, j) => j !== i
																		)
																	)
																}}
																className="button button-link button-link-sm button-link-delete"
															>
																<i className="mi">delete</i>
																<span>{language.remove}</span>
															</button>
														</div>
														<div className="fieldset-body">
															<div className="form-group">
																<div className="form-row">
																	<div className="col-3">
																		<label>
																			{language.name}:
																		</label>
																	</div>
																	<div className="col-9">
																		<input
																			disabled={submitting}
																			autoComplete="off"
																			value={pr.name}
																			onChange={(e) => {
																				setPromotions(
																					promotions.map(
																						(p, j) => {
																							if (
																								i !== j
																							)
																								return p
																							return {
																								...p,
																								name:
																									e
																										.target
																										.value,
																							}
																						}
																					)
																				)
																			}}
																			className="form-control"
																			type="text"
																			placeholder={
																				language.promotion_label_example
																			}
																		/>
																	</div>
																</div>
															</div>
															<div className="form-group colside-left cols-6">
																<div className="form-row has-append">
																	<span className="append">
																		<i>%</i>
																	</span>
																	<div className="col-5">
																		<label>
																			{language.discount}:
																		</label>
																	</div>
																	<div className="col-7">
																		<input
																			disabled={submitting}
																			className="form-control"
																			value={parseInt(
																				pr.discount
																			)}
																			onChange={(e) => {
																				setPromotions(
																					promotions.map(
																						(p, j) => {
																							if (
																								i !== j
																							)
																								return p
																							return {
																								...p,
																								discount:
																									e
																										.target
																										.value,
																							}
																						}
																					)
																				)
																			}}
																			type="number"
																			placeholder="Ejm: 30%"
																		/>
																	</div>
																</div>
															</div>
															<div className="form-group colside-right cols-6">
																<div className="form-row">
																	<div className="col-4">
																		<label>
																			{language.code}:
																		</label>
																	</div>
																	<div className="col-8">
																		<input
																			disabled={submitting}
																			className="form-control"
																			value={pr.code}
																			onChange={(e) => {
																				setPromotions(
																					promotions.map(
																						(p, j) => {
																							if (
																								i !== j
																							)
																								return p
																							return {
																								...p,
																								code:
																									e
																										.target
																										.value,
																							}
																						}
																					)
																				)
																			}}
																			type="text"
																			placeholder="OCTPROMO"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</fieldset>
												)
											})}
										</>
									)}
									<Field
										name="groups"
										type="text"
										style={{ display: 'none' }}
									/>
									<div className="adder">
										<button
											disabled={submitting}
											type="button"
											className="button button-light"
											onClick={() =>
												setPromotions([
													...promotions,
													{
														name: '',
														discount: 0,
														code: '',
														id: Math.floor(Math.random() * 10000),
													},
												])
											}
										>
											<i className="mi">add</i>
											<span>{language.new_promotion}</span>
										</button>
									</div>
									<footer>
										{errors.groups && touched.groups && (
											<p className="notice danger">
												{errors.groups}
											</p>
										)}
										<button
											type="submit"
											disabled={submitting || gallery === null}
											className="button button-primary"
										>
											{submitting && (
												<div
													className="spinner-border spinner-border-sm"
													role="status"
												>
													<span className="sr-only">
														{language.loading}...
													</span>
												</div>
											)}
											{!submitting && (
												<>
													<i className="mi">arrow_forward</i>
													<span>{language.continue}</span>
												</>
											)}
										</button>
									</footer>
								</Form>
							)
						}}
					</Formik>
					<button
						type="button"
						className="close"
						onClick={() => setEditEvent(null)}
					>
						<i className="mi">close</i>
					</button>
				</div>
			</div>
		)
	}

	return renderCreateEvent()
}

export default connect(
	(state) => ({
		place: state.place,
		language: state.language.dictionary,
		events: state.events,
		products: state.products,
	}),
	{
		set_events: events,
	}
)(EditEvent)

import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Input, ModalForm } from '../../../../components'
import { firestore } from '../../../../config/firebase'
import _permissions from '../../../../config/permissions'
import { create_alert } from '../../../../store/actions'

function Employee({
	close,
	create_alert,
	place,
	employee = null,
	language,
	global = false,
	global_data = {},
}) {
	const [submitting, setSubmitting] = useState(false)
	const [category, setCategory] = useState({
		new: false,
		value: employee !== null ? employee.category : null,
	})
	const [permissions, setPermissions] = useState([])

	const validate = (values) => {
		let errors = {}
		if (values.name.toString().length <= 1)
			errors.name = language.invalid_field
		if (values.pin.toString().length <= 3) errors.pin = language.pin_length
		let id = category.value.toLowerCase().replace(/ /g, '_')
		if (
			category.value === null ||
			category.value === '' ||
			id === 'main_admin'
		)
			errors.category = language.invalid_field
		return errors
	}

	const createEmployee = async (values) => {
		let placeId = global === false ? place.id : global_data.id
		let employees = global === false ? place.employees : global_data.employees

		if (global) {
			let admin_passed = false
			employees = employees.filter((e) => {
				if (e.name === 'Admin' && e.category === 'main_admin') {
					if (admin_passed === false) {
						admin_passed = true
						return true
					} else return false
				} else return true
			})
		}

		let newEmps = [
			...employees,
			{
				id: parseInt(
					Math.floor(Math.random() * 1000000) + '' + new Date().getTime()
				),
				name: values.name,
				pin: values.pin,
				category:
					category.value !== null
						? category.value.toLowerCase().replace(/ /g, '')
						: 'main_admin',
				created: new Date().getTime(),
			},
		]

		setSubmitting(true)
		await firestore.collection('places').doc(placeId).update({
			employees: newEmps,
		})
		if (global === false && category.new)
			createCategory(category.value, permissions)
		else if (global === false)
			await firestore
				.collection('places')
				.doc(placeId)
				.update({
					employee_categories: place.employee_categories.map((cat) => {
						if (cat.id !== category.value) return cat
						return {
							...cat,
							length: cat.length + 1,
						}
					}),
				})

		if (global === true) {
			global_data.setEmployees(newEmps)
		}

		setSubmitting(false)
		close()
		create_alert(
			`${language.x_is_now_an_employee}`.replace('x', values.name),
			'success'
		)
	}

	const updateEmployee = async (values) => {
		setSubmitting(true)
		await firestore
			.collection('places')
			.doc(place.id)
			.update({
				employees: place.employees.map((emp) => {
					if (emp.id !== employee.id) return emp
					return {
						...emp,
						name: values.name,
						category: category.value.toLowerCase().replace(/ /g, ''),
						pin: values.pin,
					}
				}),
			})
		if (category.new) createCategory(category.value, permissions)

		setSubmitting(false)
		close()
		create_alert(
			`${language.x_has_been_updated}`.replace('x', values.name),
			'success'
		)
	}

	const createCategory = async (_name, _permissions) => {
		await firestore
			.collection('places')
			.doc(place.id)
			.update({
				employee_categories: [
					...place.employee_categories,
					{
						name: _name,
						id: _name.toLowerCase().replace(/ /g, ''),
						permissions: [..._permissions],
						length: 1,
					},
				],
			})
	}

	const handleCategoryChange = (v) => {
		if (v === null)
			setCategory({
				new: false,
				value: null,
			})
		else
			setCategory({
				new: v.__isNew__,
				value: v.value,
			})
	}

	const handlePermissionsChange = (v) => {
		if (v === null) return setPermissions([])
		setPermissions(v.map((_v) => _v.value))
	}

	let categoriesAsOptions = []

	if (!global) {
		categoriesAsOptions = place.employee_categories
			.filter((c) => c.id !== 'main_admin')
			.map((cat) => ({
				label: cat.name,
				value: cat.id,
			}))
	}

	const perms_ = {
		products: 'Productos',
		tables: 'Mesas',
		orders: 'Pedidos',
		queue: 'Cola',
		reservations: 'Reservas',
		inventory: 'Inventario',
		sales: 'Ventas',
		employees: 'Empleados',
		events: 'Eventos',
		administrator: 'Administrador',
	}

	const permissionsAsOptions = () => {
		let _per = _permissions.filter(
			(e) => ['sales', 'inventory'].indexOf(e) === -1
		)
		return _per.map((per) => ({
			label: perms_[per],
			value: per,
		}))
	}

	const submitEmployee = (v) => {
		if (employee !== null) updateEmployee(v)
		if (employee === null) createEmployee(v)
	}

	return (
		<ModalForm toggle={close}>
			<Formik
				initialValues={{
					name: employee !== null ? employee.name : '',
					pin: employee !== null ? employee.pin : '',
					category: '',
				}}
				validate={validate}
				onSubmit={submitEmployee}
			>
				{({ errors, touched, values, setFieldValue }) => {
					return (
						<Form
							className="form-ui"
							style={{
								overflow: 'visible',
							}}
						>
							<h1>
								{employee === null
									? language.new_employee
									: language.edit_employee}
							</h1>
							<Input
								errors={errors}
								touched={touched}
								label={language.full_name}
								type="text"
								submitting={submitting}
								name="name"
								disabled={category.value === 'main_admin'}
							/>
							<Input
								errors={errors}
								touched={touched}
								label={language.password}
								type="text"
								submitting={submitting}
								name="pin"
							/>
							{!global && (
								<>
									<div
										className={`form-group upper-1 ${
											category.value === 'main_admin'
												? 'is-disabled'
												: ''
										} ${
											errors.category && touched.category
												? 'is-invalid'
												: ''
										}`}
									>
										<div className="form-row">
											<div className="col-3">
												<label>{language.category}:</label>
											</div>
											<div className="col-9">
												<CreatableSelect
													placeholder={
														language.type_or_select_a_category
													}
													isDisabled={
														category.value === 'main_admin'
													}
													isClearable
													onChange={handleCategoryChange}
													options={categoriesAsOptions}
													value={{
														value: category.value,
														label:
															category.value === null
																? language.type_or_select_a_category
																: category.value
																		.charAt(0)
																		.toUpperCase() +
																  category.value
																		.slice(1)
																		.toLowerCase(),
													}}
													className="react-select"
													classNamePrefix="react-select"
													formatCreateLabel={(str) =>
														`Crear "${str}"`
													}
												/>
											</div>
										</div>
									</div>
									{category.new && (
										<div className="form-group">
											<div className="form-row">
												<div className="col-3">
													<label>{language.permissions}</label>
												</div>
												<div className="col-9">
													<Select
														placeholder={
															language.select_permissions_for_that_category
														}
														options={permissionsAsOptions()}
														onChange={handlePermissionsChange}
														isMulti
														className="react-select"
														classNamePrefix="react-select"
													/>
												</div>
											</div>
										</div>
									)}
								</>
							)}
							<footer>
								{errors.category && touched.category && (
									<p className="notice danger">{errors.category}</p>
								)}
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">
												{language.loading}...
											</span>
										</div>
									)}
									{!submitting && (
										<>
											<i className="mi">arrow_forward</i>
											<span>{language.continue}</span>
										</>
									)}
								</button>
							</footer>
						</Form>
					)
				}}
			</Formik>
		</ModalForm>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		language: state.language.dictionary,
	}),
	{
		create_alert,
	}
)(Employee)

import { Field, Form, Formik } from 'formik'
import { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Input } from '../../components'
import { auth } from '../../config/firebase'
import { create_alert } from '../../store/actions'

function Signin({ history, create_alert, language }) {
	const [submitting, setSubmitting] = useState(false)
	const [area, setArea] = useState('34')
	const [collaborator, setCollaborator] = useState(null)
	const [asCollaborator, setAsCollaborator] = useState(null)
	const [type, setType] = useState('signin')

	const validate = (values) => {
		let errors = {}

		// if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(values.password))
		// 	errors.password =
		// 		'Debe incluir mayúscula, minúscula, números, mínimo 8 caracteres'

		if (!validateEmail(values.email)) errors.email = 'Email invalido'

		if (type === 'signup' && values.repassword !== values.password)
			errors.repassword = language.passwords_dont_match

		if (type === 'signup' && !values.accepted) {
			// create_alert('Debes aceptar los términos', 'danger', )
			errors.accepted = 'Debes aceptar los términos'
		}

		return errors
	}

	function validateEmail(email) {
		var re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(String(email).toLowerCase())
	}

	const onSubmit = (values) => {
		setSubmitting(true)
		if (asCollaborator)
			localStorage.setItem('collaborator', JSON.stringify(asCollaborator))

		if (type === 'signin') {
			auth
				.signInWithEmailAndPassword(values.email, values.password)
				.then((res) => {
					create_alert(language.welcome, 'success')
				})
				.catch((err) => {
					let txt = language.an_error_ocurred
					if (err.code === 'auth/wrong-password')
						txt = language.wrong_password
					if (err.code === 'auth/user-not-found')
						txt = language.user_not_found
					create_alert(txt, 'danger')
					setSubmitting(false)
					setCollaborator(null)
				})
		} else if (type === 'signup') {
			auth
				.createUserWithEmailAndPassword(values.email, values.password)
				.then((res) => {
					create_alert(language.welcome, 'success')
				})
				.catch((err) => {
					if (err.code === 'auth/email-already-in-use') {
						create_alert('El correo ya se encuentra en uso', 'danger')
						setSubmitting(false)
						setCollaborator(null)
					} else {
						create_alert(language.an_error_ocurred, 'danger')
						setSubmitting(false)
						setCollaborator(null)
					}
				})
		} else {
			auth
				.sendPasswordResetEmail(values.email)
				.then(() => {
					setSubmitting(false)
					setType('signin')
					create_alert('Revisa tu correo', 'success')
				})
				.catch(() => {
					setSubmitting(false)
					create_alert('Correo no registrado', 'danger')
				})
		}
	}

	return (
		<div className="login-ui login">
			<small
				style={{
					position: 'absolute',
					top: 11,
					right: 11,
				}}
			>
				V 5.2.0
			</small>
			<div>
				<Formik
					validate={validate}
					initialValues={{
						email: '',
						password: '',
						repassword: '',
						accepted: false,
					}}
					onSubmit={onSubmit}
				>
					{({ errors, touched, values, setFieldValue }) => {
						return (
							<Form className="form-ui">
								<Link to="/" className="logo"></Link>
								<h1>Vywap</h1>
								<Input
									rowcolright="7"
									rowcolleft="5"
									submitting={submitting}
									errors={errors}
									touched={touched}
									type="email"
									name="email"
									label={language.email}
									custom={{ autoComplete: 'off' }}
								/>
								{type !== 'recover' && (
									<Input
										rowcolright="7"
										rowcolleft="5"
										submitting={submitting}
										errors={errors}
										touched={touched}
										type="password"
										name="password"
										label={language.password}
										custom={{ autoComplete: 'off' }}
									/>
								)}
								{type === 'signup' && (
									<Input
										rowcolright="7"
										rowcolleft="5"
										submitting={submitting}
										errors={errors}
										touched={touched}
										type="password"
										name="repassword"
										label={language.repassword}
										custom={{ autoComplete: 'off' }}
									/>
								)}
								<footer>
									{collaborator !== null && asCollaborator !== false && (
										<button
											disabled={submitting}
											type="submit"
											className="button button-primary"
											id="sign-in-button"
											onClick={() => {
												localStorage.setItem('collaborator', 'true')
												setAsCollaborator(true)
											}}
										>
											{submitting && (
												<div role="status">
													<span>{language.loading}...</span>
												</div>
											)}
											{!submitting && language.enter_as_collaborator}
										</button>
									)}
									{asCollaborator !== true && (
										<>
											{type === 'signin' && (
												<>
													<button
														disabled={submitting}
														onClick={() => {
															localStorage.setItem(
																'collaborator',
																'false'
															)
															setAsCollaborator(false)
														}}
														type="submit"
														style={{ marginTop: 20 }}
														className={`button ${
															collaborator !== null
																? 'button-light'
																: 'button-primary'
														}`}
														id="sign-in-button"
													>
														{submitting && (
															<div role="status">
																<span>
																	{language.loading}...
																</span>
															</div>
														)}
														{!submitting && language.enter}
													</button>
													{/* <button
														disabled={submitting}
														onClick={e => {
															e.preventDefault()
															e.stopPropagation()
															let provider = new firebase.auth.GoogleAuthProvider()
															firebase.auth().languageCode = 'es'
															firebase
																.auth()
																.signInWithPopup(provider)
																.catch(error => {
																	
																	var errorMessage =
																		error.message
																	create_alert(
																		errorMessage,
																		'danger'
																	)
																})
														}}
														type="button"
														style={{ marginTop: 20 }}
														className={`button button-light`}
														id="sign-in-button"
													>
														{submitting && (
															<div role="status">
																<span>
																	{language.loading}...
																</span>
															</div>
														)}
														{!submitting &&
															'Continuar con Google'}
													</button> */}
													<aside>
														<button
															className="button button-link l"
															type="button"
															onClick={() => setType('recover')}
														>
															{language.forgot_password}
														</button>
														<button
															disabled={submitting}
															onClick={() => setType('signup')}
															type="button"
															className={`button button-link r`}
														>
															{language.register}
														</button>
													</aside>
												</>
											)}
											{type === 'recover' && (
												<>
													<button
														disabled={submitting}
														type="submit"
														style={{ marginTop: 20 }}
														className={`button`}
														id="sign-in-button"
													>
														{submitting && (
															<div role="status">
																<span>
																	{language.loading}...
																</span>
															</div>
														)}
														{!submitting && language.recover}
													</button>

													<aside>
														<button
															className="button button-link l"
															type="button"
															onClick={() => setType('signin')}
														>
															{language.enter}
														</button>
														<button
															disabled={submitting}
															onClick={() => setType('signup')}
															type="button"
															className={`button button-link r`}
														>
															{language.register}
														</button>
													</aside>
												</>
											)}
											{type === 'signup' && (
												<>
													<a
														className="button button-link button-block"
														href={`${window.location.origin}/terms/review`}
														target="popup"
														onClick={(e) => {
															window.open(
																`${window.location.origin}/terms/review`,
																'popup',
																'width=600,height=800'
															)
														}}
													>
														Leer Aviso legal y condiciones
														generales
													</a>
													<div
														className="form-group form-group-check"
														style={{
															width: '100%',
														}}
													>
														<div className="form-row">
															<div className="col-12">
																<Field
																	type="checkbox"
																	name="accepted"
																	id="accepted"
																/>
																<label htmlFor="accepted">
																	<b></b>{' '}
																	<span>
																		Acepto el Aviso legal y
																		las condiciones generales
																	</span>
																</label>
															</div>
														</div>
													</div>
													<button
														disabled={submitting}
														onClick={() => {
															localStorage.setItem(
																'collaborator',
																'false'
															)
															setAsCollaborator(false)
														}}
														type="submit"
														style={{ marginTop: 20 }}
														className={`button ${
															collaborator !== null
																? 'button-light'
																: 'button-primary'
														}`}
														id="sign-in-button"
													>
														{submitting && (
															<div role="status">
																<span>
																	{language.loading}...
																</span>
															</div>
														)}
														{!submitting && language.register}
													</button>
													{/* <button
														disabled={submitting}
														onClick={(e) => {
															e.preventDefault()
															e.stopPropagation()
															let provider = new firebase.auth.GoogleAuthProvider()
															firebase.auth().languageCode = 'es'
															firebase
																.auth()
																.signInWithPopup(provider)
																.catch((error) => {
																	var errorMessage =
																		error.message
																	create_alert(
																		errorMessage,
																		'danger'
																	)
																})
														}}
														type="button"
														style={{ marginTop: 20 }}
														className={`button button-light`}
														id="sign-in-button"
													>
														{submitting && (
															<div role="status">
																<span>
																	{language.loading}...
																</span>
															</div>
														)}
														{!submitting &&
															'Continuar con Google'}
													</button> */}
													<aside>
														<button
															disabled={submitting}
															onClick={() => setType('signin')}
															type="submit"
															style={{ marginTop: 20 }}
															className={`button button-link`}
														>
															{language.enter}
														</button>
													</aside>
												</>
											)}
										</>
									)}
								</footer>
							</Form>
						)
					}}
				</Formik>
			</div>
		</div>
	)
}

export default connect((state) => ({ language: state.language.dictionary }), {
	create_alert,
})(Signin)

import React from 'react'
import General from './General'

function Place({ place, update_place, setShowRemovePlace, history }) {
	return (
		<General
			place={place}
			update_place={update_place}
			setShowRemovePlace={setShowRemovePlace}
			history={history}
		/>
	)
}

export default Place

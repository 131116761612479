import { combineReducers } from 'redux'

import alerts from './alerts'
import configuration from './configuration'
import user from './user'
import place from './place'
import products from './products'
import categories from './categories'
import tables from './tables'
import permissions from './permissions'
import promotions from './promotions'
import inventory from './inventory'
import schedule from './schedule'
import events from './events'
import employee from './employee'
import subcategories from './subcategories'
import language from './language'
import report from './report'
import reservations from './reservations'
import topbar from './topbar'
import pending_orders from './pending_orders'
import covid from './covid'
import feedback from './feedback'

export default combineReducers({
	alerts,
	user,
	products,
	place,
	categories,
	configuration,
	tables,
	permissions,
	promotions,
	inventory,
	schedule,
	events,
	employee,
	language,
	subcategories,
	report,
	reservations,
	topbar,
	covid,
	pending_orders,
	feedback,
})

import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Chrono, Input, ModalForm } from '../../../components'
import { firestore } from '../../../config/firebase'
import { create_alert, set_report } from '../../../store/actions'

function Report({ place, create_alert, report, set_report, language, topbar }) {
	const [showPass, setShowPass] = useState(false)

	const onEnterWork = async (emp) => {
		// set_report(
		// 	report.map(_emp => {
		// 		if (_emp.id !== emp.id) return _emp
		// 		return { ..._emp, submitting: true }
		// 	})
		// )

		let prev = {}
		let action = 'set'
		let res = await firestore
			.collection('places')
			.doc(place.id)
			.collection('report')
			.doc(emp.id.toString())
			.get()
		if (res.exists) action = 'update'
		else prev = emp

		let data = {
			...prev,
			working: true,
			since: Math.floor(new Date().getTime() / 1000),
		}

		if (typeof prev.history === 'undefined') data.history = []

		set_report(
			report.map((r) => {
				if (r.id !== emp.id) return r
				return {
					...r,
					...data,
				}
			})
		)

		await firestore
			.collection('places')
			.doc(place.id)
			.collection('report')
			.doc(emp.id.toString())
			[action]({ ...data })
	}

	const onExitWork = async (emp) => {
		// set_report(
		// 	report.map(_emp => {
		// 		if (_emp.id !== emp.id) return _emp
		// 		return { ..._emp, submitting: true }
		// 	})
		// )

		let id = parseInt(
			`${new Date().getTime()}${Math.floor(Math.random() * 1000)}`
		)
		let to = new Date().getTime()
		let total = new Date().getTime() - emp.since

		set_report(
			report.map((r) => {
				if (r.id !== emp.id) return r
				return {
					...r,
					working: false,
					since: 0,
					history: [
						...emp.history,
						{
							since: emp.since,
							to,
							total,
							id,
						},
					],
				}
			})
		)
		await firestore
			.collection('places')
			.doc(place.id)
			.collection('report')
			.doc(emp.id.toString())
			.update({
				working: false,
				since: 0,
				history: [
					...emp.history,
					{
						since: emp.since,
						to,
						total,
						id,
					},
				],
			})
	}

	const renderEmployees = () => {
		return report.map((emp) => {
			let category_name = place.employee_categories.find(
				(c) => c.id === emp.category
			)
			if (typeof category_name === 'undefined') return null
			category_name = category_name.name
			category_name =
				category_name.charAt(0).toUpperCase() +
				category_name.slice(1).toLowerCase()
			if (emp.category === 'main_admin') {
				category_name = language.main_admin
			}

			return (
				<li key={emp.id}>
					<div className="card vcard">
						<div className="avatar">
							<span className="img">
								<i className="mi">account_circle</i>
							</span>
						</div>
						<div className="body">
							<strong className="title">{emp.name}</strong>
							<em>{category_name}</em>
							{emp.working && (
								<Chrono
									time={emp.since * 1000}
									nodays
									showSeconds
									showHours
								/>
							)}
						</div>
						{emp.working === null && (
							<div className="">{language.loading}</div>
						)}
						{emp.working === false && (
							<button
								disabled={emp.submitting}
								onClick={() => setShowPass([emp, 'enter'])}
								className="button button-light"
							>
								<span>{language.enter}</span>
							</button>
						)}
						{emp.working === true && (
							<button
								disabled={emp.submitting}
								onClick={() => setShowPass([emp, 'exit'])}
								className="button button-danger"
							>
								<span>{language.exit}</span>
							</button>
						)}
					</div>
				</li>
			)
		})
	}

	const renderEnterPassword = (act) => {
		return (
			<ModalForm toggle={() => setShowPass(false)} size="sm">
				<Formik
					initialValues={{
						password: '',
					}}
					onSubmit={(values) => {
						let emp = place.employees.find(
							(em) => em.id.toString() === act[0].id.toString()
						)
						if (emp.pin.toString() !== values.password.toString()) {
							create_alert(language.wrong_password, 'danger')
							setShowPass(false)
						} else {
							if (act[1] === 'enter') {
								onEnterWork(act[0])
								create_alert(
									`${language.x_has_started_to_work}`.replace(
										'x',
										act[0].name
									),
									'success'
								)
							} else if (act[1] === 'exit') {
								onExitWork(act[0])
								create_alert(
									`${language.x_has_exited_from_work}`.replace(
										'x',
										act[0].name
									),
									'success'
								)
							}
							setShowPass(false)
						}
					}}
				>
					{({ errors, touched }) => (
						<Form className="form-ui">
							<h1>{language.enter_the_employee_password}</h1>
							<Input
								label={language.password}
								type="password"
								name="password"
								errors={errors}
								touched={touched}
							/>
							<footer style={{ marginTop: 10 }}>
								<button type="submit" className="button button-primary">
									<span>{language.continue}</span>
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	return (
		<>
			{showPass !== false && renderEnterPassword(showPass)}
			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs">
					<li className="active">
						<Link to="/queue">{language.report}</Link>
					</li>
				</ul>
			</div>
			<div className="list-ui">
				<div>
					<ul>{renderEmployees()}</ul>
				</div>
			</div>
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		report: state.report,
		language: state.language.dictionary,
		topbar: state.topbar,
	}),
	{ create_alert, set_report }
)(Report)

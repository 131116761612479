const initialState = []

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'UPDATE_PROMOTIONS':
			if (payload === null) return []
			return [...payload]
		default:
			return state
	}
}

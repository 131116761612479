const initialState = {
	id: null,
	name: null,
	owner: null,
	invoices: [],
	employees: [],
	products: [],
	created: [],
	table_floors: 0,
	theme: null,
	slug: '',
}

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'UPDATE_PLACE':
			if (state !== null && payload === null) return state
			if (payload === null) return initialState
			let badge = payload.badge || ''
			let phone = payload.phone || 0
			let minimum_order = payload.minimum_order || 0
			let allow_chat = payload.allow_chat || false
			let require_queue_qr = payload.require_queue_qr || false
			let seats = payload.seats || []
			let chat = payload.chat || {}
			let subscribers = payload.subscribers || []
			let smtp = payload.smtp || {}

			let new_place = {
				...state,
				...payload,
				badge,
				phone,
				minimum_order,
				allow_chat,
				require_queue_qr,
				seats,
				chat,
				subscribers,
				smtp,
			}

			let place_feats = new_place.features

			return { ...new_place, features: place_feats }

		case 'REMOVE_PLACE':
		case 'SIGN_OUT':
			return initialState
		default:
			return state
	}
}

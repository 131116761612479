import React, { useEffect, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

function More({ children, forceClose = false, custom }) {
	const [className, setClassName] = useState('more')
	const [drop, setDrop] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setClassName(`${className}`)
		}, 50)
	}, [])

	useEffect(() => {
		if (forceClose) onToggle(null, true)
	}, [forceClose])

	const onToggle = (e, force = false) => {
		if (e !== null) e.stopPropagation()
		let _drop = !drop
		if (force) _drop = false
		if (_drop) {
			setDrop(_drop)
			setTimeout(() => {
				setClassName('more show')
			}, 20)
		} else {
			setClassName('more show close')
			setTimeout(() => {
				setClassName('more')
				setDrop(_drop)
			}, 300)
		}
	}

	return (
		<OutsideClickHandler
			onOutsideClick={e => {
				if (drop) onToggle(e)
			}}
		>
			<div className={className} {...custom}>
				<button title="Actions" onClick={onToggle}>
					<i className="mi">{drop ? 'close' : 'more_vert'}</i>
				</button>
				{drop === true && <div className="dropdown">{children}</div>}
			</div>
		</OutsideClickHandler>
	)
}

export default More

import React from 'react'
import { connect } from 'react-redux'
import ModalForm from './ModalForm'

function Confirmation({
	onChange,
	children,
	submitting,
	label,
	icon,
	type,
	language,
	cancelLabel = false,
	renderPlain = false,
}) {
	return (
		<ModalForm hideClose size="sm">
			<div className="form-ui">
				{renderPlain === false && <h1>{children}</h1>}
				{renderPlain === true && children}
				<footer className="cols-2">
					<button
						disabled={submitting}
						className="button button-light"
						onClick={() => onChange(false)}
					>
						<i className="mi">close</i>
						<span>{cancelLabel || language.cancel}</span>
					</button>
					<button
						disabled={submitting}
						className={`button button-${type}`}
						onClick={() => onChange(true)}
					>
						<i className="mi">{icon}</i>
						<span>{label}</span>
					</button>
				</footer>
			</div>
		</ModalForm>
	)
}

export default connect((state) => ({ language: state.language.dictionary }))(
	Confirmation
)

import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import xml2js from 'xml2js'

import { Input, ModalForm, More, Spinner } from '../../../components'
import { firestore, functions } from '../../../config/firebase'
import create_alert from '../../../store/actions/create_alert'

function ItemsManagement({
	user,
	setShowUploadClients = () => {},
	showUploadClients = false,
	create_alert,
}) {
	let history = useHistory()

	const [data, setData] = useState(null)
	const [showCreatePlace, setShowCreatePlace] = useState(false)
	const [showCreateMaterial, setShowCreateMaterial] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const [showEditPlace, setShowEditPlace] = useState(null)
	const [showEditMaterial, setShowEditMaterial] = useState(null)
	const [roomFile, setRoomFile] = useState(null)
	const [notificationWatch, setNotificationWatch] = useState(null)
	const [percentage, setPercentage] = useState(0)

	useEffect(() => {
		let listener = firestore
			.doc(`/items_management/${user.id}`)
			.onSnapshot((snap) => {
				snap = snap.data()
				setData(snap || null)
			})
		return () => {
			listener()
		}
	}, [])

	useEffect(() => {
		if (typeof notificationWatch === 'string') {
			let listener = firestore
				.doc(`/clientNotifications/${notificationWatch}`)
				.onSnapshot((snap) => {
					snap = snap.data() || {}
					setPercentage(snap?.percentage || 0)
					if (snap?.completed === true) {
						setSubmitting(false)
						setNotificationWatch(null)
						setShowUploadClients(false)
						setRoomFile(null)
						setPercentage(0)
						create_alert('Clientes subidos exitosamente', 'success')
					} else if (snap?.completed === false) {
						setSubmitting(false)
						setNotificationWatch(null)
						setShowUploadClients(false)
						setRoomFile(null)
						setPercentage(0)
						create_alert(
							'Hubo un problema subiendo los clientes',
							'danger'
						)
					}
				})
			return () => {
				listener()
			}
		}
	}, [notificationWatch])

	const readTextFile = (file) => {
		setSubmitting(true)
		var reader = new FileReader()
		reader.onload = async (e) => {
			let text = e.target.result
			let response = await xml2js.parseStringPromise(text)
			response = response?.MODULE1?.LIST_G_C9[0]?.G_C9 || null

			if (Array.isArray(response)) setRoomFile(response)
			else setRoomFile(false)
			setSubmitting(false)
		}
		reader.readAsText(file)
	}

	const uploadClients = async () => {
		setSubmitting(true)
		let notificationId = uuidv4()

		await firestore.doc(`/clientNotifications/${notificationId}`).set({
			completed: null,
			percentage: 0,
			id: notificationId,
		})

		let fn = functions.httpsCallable('manuallyUploadAsiagardens')
		fn({ reservations: roomFile, id: notificationId })
		setNotificationWatch(notificationId)
	}

	const renderPlaces = () => {
		return (
			<>
				{(data.places || []).map((place, z) => {
					return (
						<li key={place.id}>
							<div className={`card vcard placecard`}>
								<div className={`avatar`}>
									<span className="img">
										<i className="mi">store</i>
									</span>
								</div>
								<div className="body">
									<strong className="title">{place.name}</strong>
								</div>
								<button
									className="button button-primary"
									onClick={() =>
										history.push(`/items-management/${place.id}`)
									}
								>
									<span>Seleccionar</span>
								</button>
								<More>
									<button
										onClick={() => setShowEditPlace(place)}
										type="button"
										style={{
											marginTop: 5,
										}}
									>
										<i className="mi">edit</i>
										<span>Editar nombre</span>
									</button>
									<button
										onClick={() => deletePlace(place.id)}
										type="button"
										style={{
											marginTop: 5,
										}}
									>
										<i className="mi">delete</i>
										<span>Borrar puesto</span>
									</button>
								</More>
							</div>
						</li>
					)
				})}
			</>
		)
	}

	const createMaterial = async (values) => {
		setSubmitting(true)
		let id = uuidv4()
		let ref = firestore.doc(`/items_management/${user.id}`)
		await firestore.runTransaction(async (trans) => {
			let data = await trans.get(ref)
			data = data.data()
			let materials = data.materials
			materials.push({
				name: `${values.name}`.trim(),
				id,
			})
			await trans.update(ref, {
				materials,
			})
		})
		setSubmitting(false)
		setShowCreateMaterial(false)
	}

	const deleteMaterial = async (id) => {
		let ref = firestore.doc(`/items_management/${user.id}`)
		await firestore.runTransaction(async (trans) => {
			let data = await trans.get(ref)
			data = data.data()
			let materials = data.materials
			materials = materials.filter((f) => f.id !== id)
			await trans.update(ref, {
				materials,
			})
		})
	}

	const createPlace = async (values) => {
		setSubmitting(true)
		let id = uuidv4()

		await firestore.doc(`/items_management/${user.id}/places/${id}`).set({
			id,
			name: `${values.name}`.trim(),
			current_service: null,
			last_service: null,
		})

		let ref = firestore.doc(`/items_management/${user.id}`)
		await firestore.runTransaction(async (trans) => {
			let data = await trans.get(ref)
			data = data.data()
			let places = data.places
			places.push({
				name: `${values.name}`.trim(),
				id,
			})
			await trans.update(ref, {
				places,
			})
		})
		setSubmitting(false)
		setShowCreatePlace(false)
	}

	const editPlace = async (values) => {
		setSubmitting(true)
		let id = showEditPlace?.id || ''
		let name = `${values.name}`.trim()

		await firestore.doc(`/items_management/${user.id}/places/${id}`).update({
			name,
		})
		let ref = firestore.doc(`/items_management/${user.id}`)
		await firestore.runTransaction(async (trans) => {
			let data = await trans.get(ref)
			data = data.data()
			let places = data.places
			places = places.map((p) => {
				if (p.id !== id) return p
				return {
					...p,
					name,
				}
			})
			await trans.update(ref, {
				places,
			})
		})

		setSubmitting(false)
		setShowEditPlace(false)
	}

	const editMaterial = async (values) => {
		setSubmitting(true)
		let id = showEditMaterial?.id || ''
		let name = `${values.name}`.trim()

		let ref = firestore.doc(`/items_management/${user.id}`)
		await firestore.runTransaction(async (trans) => {
			let data = await trans.get(ref)
			data = data.data()
			let materials = data.materials
			materials = materials.map((p) => {
				if (p.id !== id) return p
				return {
					...p,
					name,
				}
			})
			await trans.update(ref, {
				materials,
			})
		})

		setSubmitting(false)
		setShowEditMaterial(false)
	}

	const deletePlace = async (id) => {
		await firestore.doc(`/items_management/${user.id}/places/${id}`)
		let ref = firestore.doc(`/items_management/${user.id}`)
		await firestore.runTransaction(async (trans) => {
			let data = await trans.get(ref)
			data = data.data()
			let places = data.places
			places = places.filter((f) => f !== id)
			await trans.update(ref, {
				places,
			})
		})
	}

	const renderMaterials = () => {
		return (
			<>
				{(data.materials || []).map((material, z) => {
					return (
						<li key={material.id}>
							<div className={`card vcard placecard`}>
								<div className={`avatar`}>
									<span className="img">
										<i className="mi">shopping_cart</i>
									</span>
								</div>
								<div
									className="body"
									style={{
										marginTop: 10,
										marginBottom: 10,
									}}
								>
									<strong className="title">{material.name}</strong>
								</div>
								<More>
									<button
										onClick={() => setShowEditMaterial(material)}
										type="button"
										style={{
											marginTop: 5,
										}}
									>
										<i className="mi">edit</i>
										<span>Editar nombre</span>
									</button>
									<button
										onClick={() => deleteMaterial(material.id)}
										type="button"
										style={{
											marginTop: 5,
										}}
									>
										<i className="mi">delete</i>
										<span>Borrar material</span>
									</button>
								</More>
							</div>
						</li>
					)
				})}
			</>
		)
	}

	const validate = (values) => {
		let errors = {}
		if (values.name.length < 2) errors.name = 'Escribe un nombre'
		return errors
	}

	const renderCreatePlace = () => {
		return (
			<ModalForm toggle={() => setShowCreatePlace(false)} size="sm">
				<Formik
					initialValues={{
						name: '',
					}}
					validateOnBlur={false}
					validateOnChange={false}
					validate={validate}
					onSubmit={createPlace}
				>
					{({ errors, touched }) => (
						<Form className="form-ui">
							<h1>Crear puesto</h1>
							<Input
								label="Nombre"
								disabled={submitting}
								name="name"
								errors={errors}
								touched={touched}
							/>
							<footer style={{ marginTop: 10 }}>
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">Cargando...</span>
										</div>
									)}
									{!submitting && (
										<>
											<span>Crear</span>
										</>
									)}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	const renderEditPlace = () => {
		return (
			<ModalForm toggle={() => setShowEditPlace(null)} size="sm">
				<Formik
					initialValues={{
						name: showEditPlace?.name || '',
					}}
					validateOnBlur={false}
					validateOnChange={false}
					validate={(values) => {
						if (values.name.length <= 1)
							return { name: 'Ingresa el nombre' }
					}}
					onSubmit={editPlace}
				>
					{({ errors, touched }) => (
						<Form className="form-ui">
							<h1>Editar puesto</h1>
							<Input
								label="Nombre"
								disabled={submitting}
								name="name"
								errors={errors}
								touched={touched}
							/>
							<footer style={{ marginTop: 10 }}>
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">Cargando...</span>
										</div>
									)}
									{!submitting && (
										<>
											<span>Editar</span>
										</>
									)}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	const renderEditMaterial = () => {
		return (
			<ModalForm toggle={() => setShowEditMaterial(null)} size="sm">
				<Formik
					initialValues={{
						name: showEditMaterial?.name || '',
					}}
					validateOnBlur={false}
					validateOnChange={false}
					validate={(values) => {
						if (values.name.length <= 1)
							return { name: 'Ingresa el nombre' }
					}}
					onSubmit={editMaterial}
				>
					{({ errors, touched }) => (
						<Form className="form-ui">
							<h1>Editar material</h1>
							<Input
								label="Nombre"
								disabled={submitting}
								name="name"
								errors={errors}
								touched={touched}
							/>
							<footer style={{ marginTop: 10 }}>
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">Cargando...</span>
										</div>
									)}
									{!submitting && (
										<>
											<span>Editar</span>
										</>
									)}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	const renderCreateMaterial = () => {
		return (
			<ModalForm toggle={() => setShowCreateMaterial(false)} size="sm">
				<Formik
					initialValues={{
						name: '',
					}}
					validateOnBlur={false}
					validateOnChange={false}
					validate={validate}
					onSubmit={createMaterial}
				>
					{({ errors, touched }) => (
						<Form className="form-ui">
							<h1>Crear material</h1>
							<Input
								label="Nombre"
								disabled={submitting}
								name="name"
								errors={errors}
								touched={touched}
							/>
							<footer style={{ marginTop: 10 }}>
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">Cargando...</span>
										</div>
									)}
									{!submitting && (
										<>
											<span>Crear</span>
										</>
									)}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	const renderUploadClients = () => {
		return (
			<ModalForm
				toggle={() => {
					setShowUploadClients(false)
					setRoomFile(null)
				}}
				size="sm"
				hideClose={submitting}
			>
				<form className="form-ui" onSubmit={(e) => e.preventDefault()}>
					<h1>Subir clientes</h1>
					<div
						style={{
							padding: 15,
						}}
					>
						{(roomFile === null || roomFile === false) && (
							<label
								htmlFor="importInput"
								className={`button ${
									roomFile === false ? 'button-danger' : `button-light`
								} showHover`}
							>
								{roomFile === null && <span>Subir archivo XML</span>}
								{roomFile === false && <span>Formato invalido</span>}
								<input
									type="file"
									name="importInput"
									id="importInput"
									style={{
										display: 'none',
									}}
									accept=".xml"
									onChange={(e) => {
										readTextFile(e.target.files[0])
									}}
								/>
							</label>
						)}
						<em
							style={{
								display: 'block',
								padding: 25,
								paddingBottom: 0,
							}}
						>
							Subir clientes puede tardar entre 2 y 9 minutos dependiendo
							de la cantidad.
							<br />
							Porfavor no interrumpir el proceso.
						</em>
						{percentage > 0 && (
							<p
								style={{
									fontSize: '2em',
									fontWeight: 'bold',
									width: '100%',
									textAlign: 'center',
									padding: 25,
								}}
							>
								{parseFloat(percentage / 10).toFixed(1)}%
							</p>
						)}
						{roomFile !== null && roomFile !== false && submitting && (
							<button className="button button-light" disabled>
								Subiendo clientes
							</button>
						)}
						{roomFile !== null && roomFile !== false && !submitting && (
							<button className="button button-light" disabled>
								{roomFile.length} clientes
							</button>
						)}
					</div>
					<footer style={{ marginTop: 10 }}>
						<button
							type="button"
							onClick={() => uploadClients()}
							disabled={
								submitting || roomFile === null || roomFile === false
							}
							className="button button-primary"
						>
							{submitting && (
								<div
									className="spinner-border spinner-border-sm"
									role="status"
								>
									<span className="sr-only">Cargando...</span>
								</div>
							)}
							{!submitting && (
								<>
									<span>Subir</span>
								</>
							)}
						</button>
					</footer>
				</form>
			</ModalForm>
		)
	}

	if (data === null) return <Spinner />

	return (
		<div
			className="main"
			style={{
				height: '100%',
			}}
		>
			{showUploadClients && renderUploadClients()}
			{showCreatePlace && renderCreatePlace()}
			{showCreateMaterial && renderCreateMaterial()}
			{showEditPlace && renderEditPlace()}
			{showEditMaterial && renderEditMaterial()}
			<div className="choose-ui">
				<section>
					<ul>{renderPlaces()}</ul>
					<div>
						<button
							className="button button-white button-outline-white"
							onClick={() => setShowCreatePlace(true)}
						>
							<i className="mi">library_add</i>
							<span>Nuevo puesto</span>
						</button>
					</div>
				</section>
				<section
					style={{
						height: '100%',
					}}
				>
					<ul>{renderMaterials()}</ul>
					<div>
						<button
							className="button button-white button-outline-white"
							onClick={() => setShowCreateMaterial(true)}
						>
							<i className="mi">library_add</i>
							<span>Crear Material</span>
						</button>
					</div>
				</section>
			</div>
		</div>
	)
}

export default connect((state) => ({ user: state.user }), { create_alert })(
	ItemsManagement
)

import React, { useEffect, useState } from 'react'
import { Input, Popover } from '../../../../../components'

function Additions({
	values,
	submitting,
	setFieldValue,
	errors,
	touched,
	initial = null,
	language,
}) {
	const [adds, setAdds] = useState([])
	const [items, setItems] = useState([])

	useEffect(() => {
		if (initial !== null) {
			setAdds(initial.map((i) => i.id))
			setItems(initial.map((i) => i.items))
		}
	}, [initial])

	const renderAdds = () => {
		let _adds = adds.map((add, i) => {
			return renderAddsFields(add, i, adds.length)
		})
		return (
			<>
				{_adds}
				<div className="adder has-popover">
					<button
						type="button"
						className="button button-light"
						onClick={() => {
							let id = `add_${Math.round(Math.random() * 100000)}`
							//setFieldValue(`additions[${id}].items[${adds.length - 1}].value`, 0)
							setAdds([...adds, id])
							setItems([...items, 1])
						}}
					>
						<i className="mi">add</i>{' '}
						<span>{language.add_additions_group}</span>
					</button>
					<Popover
						content={
							language[
								`create_a_group_of_additions_like_sauces,_drinks_or_complements`
							]
						}
						prevent={false}
						offset={10}
						reversed
					>
						<i className="mi">help</i>
					</Popover>
				</div>
			</>
		)
	}

	const renderMinOptions = (index) => {
		let opts = []
		for (let i = 0; i <= items[index]; i++) {
			opts.push(
				<option key={i} value={i}>
					{i}
				</option>
			)
		}
		return opts
	}

	const renderMaxOptions = (index) => {
		let opts = []
		for (let i = 1; i <= items[index]; i++) {
			opts.push(
				<option key={i} value={i}>
					{i}
				</option>
			)
		}
		return opts
	}

	// const onSubmit = values => {
	// 	setSubmitting(false)
	// 	if (preset === null) {
	// 		setAdds([...adds, { ...values, id: Math.round(Math.random() * 10000000) }])
	// 	}
	// 	setShowForm(false)
	// 	setPreset(null)
	// 	setAddsCount(['default'])
	// 	setErrors(false)
	// 	refs = {
	// 		name: null,
	// 		values: {},
	// 		min_selections: 1,
	// 		max_selections: 1
	// 	}
	// }

	// const validate = () => {
	// 	setSubmitting(true)
	// 	setErrors(false)
	// 	let _errors = false

	// 	let values = {}
	// 	values.min_selections = parseInt(refs.min_selections.value)
	// 	values.max_selections = parseInt(refs.max_selections.value)
	// 	if (values.min_selections > values.max_selections) _errors = true
	// 	values.name = refs.name.value
	// 	if (refs.name.value.length === 0) _errors = true
	// 	values.additions = Object.keys(refs.values).map(val => {
	// 		let obj = refs.values[val]
	// 		let price = parseFloat(obj.price.value)

	// 		if (typeof price !== 'number') _errors = true
	// 		else if (obj.name.value.length === 0) _errors = true

	// 		return {
	// 			name: obj.name.value,
	// 			price: price
	// 		}
	// 	})
	// 	if (!_errors) onSubmit(values)
	// 	if (_errors) {
	// 		setErrors(true)
	// 		setSubmitting(false)
	// 	}
	// }

	const renderAddFields = (id, _i) => {
		let adds_fields = []
		for (let i = 0; i < items[_i]; i++) {
			let fieldset_class = ''
			if (i === 0) fieldset_class += ' first-child'
			if (i === items.length - 1) fieldset_class += ' last-child'
			adds_fields.push(
				<fieldset key={`${id}_${i}`} className={fieldset_class}>
					<div className="fieldset-body">
						<Input
							name={`additions[${id}].items[${i}].name`}
							popover={language.the_name_of_the_item_to_choose}
							submitting={submitting}
							type="text"
							touched={touched}
							errors={errors}
							cols={8}
							rowcolright={12}
							colside="left"
							prepend={`#${i + 1}`}
						/>
						<Input
							name={`additions[${id}].items[${i}].value`}
							submitting={submitting}
							defaultValue={0}
							type="number"
							touched={touched}
							errors={errors}
							append={<i className="mi">euro_symbol</i>}
							cols={4}
							rowcolright={12}
							colside="right"
							custom={{
								step: '0.01',
							}}
						/>
					</div>
					{items[_i] - 1 === i && items[_i] > 1 && (
						<button
							type="button"
							className="button button-link button-link-sm button-link-delete"
							onClick={() => {
								setItems(
									items.map((it, _i_) => {
										if (_i_ === _i) return it - 1
										return it
									})
								)
								setFieldValue(`additions[${id}].items[${i}].name`, '')
								setFieldValue(`additions[${id}].items[${i}].value`, 0)
							}}
						>
							<i className="mi">remove_circle_outline</i>
							<span>
								{language.remove} {language.item}
							</span>
						</button>
					)}
				</fieldset>
			)
		}
		return adds_fields
	}

	const renderAddsFields = (id, i) => {
		let fieldset_class = ''
		if (i === 0) fieldset_class += ' first-child'
		if (i === adds.length - 1) fieldset_class += ' last-child'
		return (
			<fieldset key={id} className={fieldset_class}>
				<div className="fieldset-header">
					<h2></h2>
					<button
						type="button"
						className="button button-link button-link-sm button-link-delete"
						onClick={() => {
							let _i = -1
							setAdds(
								adds.filter((add, i) => {
									if (id === add) _i = i
									return id !== add
								})
							)
							setFieldValue(`additions[${id}]`, undefined)
							let _its = items
							_its.splice(_i, 1)
							setItems(_its)
						}}
					>
						<i className="mi">delete</i>
						<span>
							{language.remove} {language.group}
						</span>
					</button>
				</div>
				<div className="fieldset-body">
					<Input
						label={language.name}
						submitting={submitting}
						name={`additions[${id}].name`}
						popover={language.the_name_of_the_group_of_choices}
					/>
					<Input
						label={language.min_selection}
						name={`additions[${id}].min`}
						type="select"
						noformik
						cols="6"
						rowcolleft="6"
						rowcolright="6"
						colside="left"
						custom={{
							component: 'select',
							onChange: (e) =>
								setFieldValue(`additions[${id}].min`, e.target.value),
							defaultValue:
								typeof values.additions[id] !== 'undefined'
									? values.additions[id].min
									: 0,
						}}
					>
						{renderMinOptions(i)}
					</Input>

					<Input
						label={language.max_selection}
						name={`additions[${id}].max`}
						type="select"
						noformik
						cols="6"
						rowcolleft="6"
						rowcolright="6"
						colside="right"
						custom={{
							component: 'select',
							onChange: (e) =>
								setFieldValue(`additions[${id}].max`, e.target.value),
							defaultValue:
								typeof values.additions[id] !== 'undefined'
									? values.additions[id].max
									: 1,
						}}
					>
						{renderMaxOptions(i)}
					</Input>

					{renderAddFields(id, i)}
					<div className="adder">
						<button
							type="button"
							className="button button-light"
							onClick={() =>
								setItems(
									items.map((it, _i) => {
										if (_i === i) return it + 1
										return it
									})
								)
							}
						>
							<i className="mi">add</i>{' '}
							<span>
								{language.add} {language.item}
							</span>
						</button>
					</div>
				</div>
			</fieldset>
		)
	}

	return renderAdds()
}

export default Additions

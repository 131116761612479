import React, { useEffect, useRef, useState } from 'react'

function Popover({
	children,
	prevent = false,
	content,
	offset = 0,
	style,
	force = false,
	offsetY = 0,
	reversed = false
}) {
	const [tOut, setTOut] = useState(null)
	const [showingPopper, setShowingPopper] = useState(false)
	const parent = useRef(null)

	let pos = 'left'
	let distance = -1000

	if (parent.current !== null) {
		if (reversed) {
			pos = 'right'
			distance =
				document.body.clientWidth -
				parent.current.getBoundingClientRect().left +
				offset
		} else {
			distance = parent.current.getBoundingClientRect().right + offset
		}
	}

	useEffect(() => {
		if (prevent) setShowingPopper(false)
	}, [prevent])

	return (
		<div
			className={`popover ${reversed ? 'right' : ''}`}
			style={style}
			ref={parent}
			onMouseEnter={() => {
				setTOut(
					setTimeout(() => {
						if (!prevent) setShowingPopper(true)
					}, 50)
				)
			}}
			onMouseLeave={() => {
				clearTimeout(tOut)
				setTOut(null)
				setShowingPopper(false)
			}}
		>
			{(showingPopper || force) && (
				<div
					style={{
						[pos]: distance,
						top:
							parent.current !== null
								? parent.current.getBoundingClientRect().y +
								  Math.floor(parent.current.clientHeight / 2 - 15) +
								  offsetY
								: -1000
					}}
				>
					<p>{content}</p>
				</div>
			)}
			{children}
		</div>
	)
}

export default Popover

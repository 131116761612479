export default (payload) => {
	if (payload === false)
		return {
			type: 'PENDING_ORDERS',
		}
	else
		return {
			type: 'PENDING_ORDERS',
			payload,
		}
}

import React, { useEffect, useState } from 'react'
import { auth } from '../../config/firebase'

function Loading({ label, reload = false }) {
	const [showLogout, setShowLogout] = useState(false)

	useEffect(() => {
		let timeout = setTimeout(() => {
			setShowLogout(true)
		}, 10000)
		return () => {
			clearTimeout(timeout)
		}
	}, [])

	return (
		<div className="loading">
			<div className="spinner spinner2"></div>
			{showLogout && (
				<div
					style={{
						position: 'fixed',
						bottom: 30,
						width: '100%',
					}}
				>
					<div
						style={{
							width: 300,
							height: 100,
							backgroundColor: 'rgba(0,0,0,0.05)',
							margin: '0 auto',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							borderRadius: 20,
						}}
					>
						<p>Tienes problemas?</p>
						<button
							className="button button-link button-block"
							onClick={() => {
								localStorage.clear()
								window.location.reload()
							}}
						>
							Intenta Refrescar
						</button>
					</div>
				</div>
			)}
		</div>
	)
}

export default Loading

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Loading } from '../../../components'
import FlipMove from 'react-flip-move'
import { firestore, functions } from '../../../config/firebase'

const months = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre',
]

function Pending({ reservations, setConfirmingReservation, history }) {
	const [submitting, setSubmitting] = useState(false)

	const confirmReservation = (res) => {
		let date = new Date(parseInt(res.date))
		let mn = res.time.slice(-2)
		let hrs = `00${res.time}`.slice(-4)
		hrs = hrs.slice(-4)
		hrs = hrs.slice(0, 2)
		date.setHours(parseInt(hrs))
		date.setMinutes(parseInt(mn))
		date.setMilliseconds(0)
		date.setSeconds(0)
		setConfirmingReservation({ ...res, duration: 60, date })
		history.replace('/reservations/accepted')
	}

	const rejectReservation = async (res) => {
		setSubmitting(true)
		const fn = functions.httpsCallable('modifyReservation')
		await fn({
			reservation_id: res.id,
			place_id: res.place,
			client_id: res.uid,
			action: [0, 0],
			reservation: res,
		})
		const _func = functions.httpsCallable('sendNotification')
		const _res = await _func({
			content: `Reserva en ${res.placeName} Rechazada`,
			client_id: res.uid,
			channel: 'reservations',
			data: {
				type: 'reservations',
			},
		})
		setSubmitting(false)
	}

	const renderPending = () => {
		let items = []
		Object.keys(reservations.pending).forEach((v) => {
			let p = reservations.pending[v]
			let date = new Date(parseInt(p.date))
			let mn = p.time.slice(-2)
			let hrs = `00${p.time}`.slice(-4)
			hrs = hrs.slice(-4)
			hrs = hrs.slice(0, 2)
			date.setHours(parseInt(hrs))
			date.setMinutes(parseInt(mn))

			let month = months[date.getMonth()]
			let day = date.getDate()
			let hour = `${`0${date.getHours()}`.slice(
				-2
			)}:${`0${date.getMinutes()}`.slice(-2)}`

			items.push(
				<tr
					className={`sep`}
					key={`HEAD_${p.id}`}
					style={{
						opacity: submitting ? 0.4 : 1,
					}}
				>
					<td colSpan="6">
						<h4>
							{p.name}
							<button
								className="button inline button-danger button-small"
								style={{ marginLeft: 10 }}
								onClick={(e) => rejectReservation(p)}
								disabled={submitting}
							>
								<i className="mi" style={{ marginRight: 10 }}>
									close
								</i>
								RECHAZAR RESERVA
							</button>
							<button
								className="button inline button-success button-small"
								style={{ marginLeft: 10 }}
								onClick={(e) => confirmReservation(p)}
								disabled={submitting}
							>
								<i className="mi" style={{ marginRight: 10 }}>
									done
								</i>
								BUSCAR ESPACIO
							</button>
						</h4>
					</td>
				</tr>
			)

			items.push(
				<tr
					key={`BODY_${p.id}`}
					className={`color-border last-item`}
					style={{
						opacity: submitting ? 0.4 : 1,
					}}
				>
					<td className="color"></td>
					<td>{`Para el ${day} de ${month} a las ${hour}`}</td>
					<td>{p.phone}</td>
					<td>
						{p.products.map((_p, x) => (
							<p>
								{_p.label}
								{x !== p.products.length - 1 ? ', ' : ''}{' '}
							</p>
						))}
					</td>
					<td>{p.notes}</td>
					<td className="color"></td>
				</tr>
			)
		})
		return items
	}

	if (
		reservations === null ||
		typeof reservations.pending === 'undefined' ||
		reservations.pending === null
	)
		return <Loading />

	if (Object.keys(reservations.pending).length === 0)
		return (
			<aside className="empty">
				<i className="mi">class</i>
				<h3>No hay reservaciones pendientes</h3>
			</aside>
		)

	return (
		<div className="table-ui table-ui-groups">
			<div>
				<table>
					<thead>
						<tr>
							<th width="2" className="color"></th>
							<th>Fecha</th>
							<th>Teléfono</th>
							<th>Productos</th>
							<th>Notas</th>
							<th width="2" className="color"></th>
						</tr>
					</thead>
					<FlipMove
						enterAnimation="accordionVertical"
						leaveAnimation="accordionVertical"
						duration={100}
						typeName="tbody"
					>
						{renderPending()}
					</FlipMove>
				</table>
			</div>
		</div>
	)
}

export default connect((state) => ({ reservations: state.reservations }))(
	Pending
)

import React, { useState, useEffect, useRef } from 'react'
import { ModalForm, Loading } from '../../../components'
import QRCode from 'qrcode'
import { firestore, auth } from '../../../config/firebase'
import { create_alert } from '../../../store/actions'
import { isMobile } from 'react-device-detect'
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'
import { connect } from 'react-redux'
import { useCookies } from 'react-cookie'

function ShowTickets({ onCancel, event, place, language, create_alert }) {
	const [tickets, setTickets] = useState(null)
	const [printInvoice, setPrintInvoice] = useState(false)
	const invoiceRef = useRef(null)
	const [cookies, setCookie, removeCookie] = useCookies(['print'])

	useEffect(() => {
		getTickets()
	}, [])

	const doInvoice = (id) => {
		if (!isMobile)
			htmlToImage
				.toPng(document.getElementById('PRINT_INVOICE'))
				.then(function (dataUrl) {
					download(dataUrl, `${place.id}-ticket-${id}.png`)
					setPrintInvoice(false)
				})
	}

	const printOnDesktop = async (order) => {
		let id = Math.floor(Math.random() * 100000000000)
		let obj = {
			order,
			place,
			id,
		}
		await firestore.runTransaction(async (trans) => {
			let latestData = await trans.get(
				firestore.doc(
					`/users/${auth.currentUser.uid}/printers/${cookies.print}`
				)
			)
			latestData = latestData.data()
			let q = latestData.queue || []
			trans.update(
				firestore.doc(
					`/users/${auth.currentUser.uid}/printers/${cookies.print}`
				),
				{
					queue: [...q, obj],
				}
			)
		})
	}

	const renderInvoice = () => {
		let billing_info = Object.keys(place.billing).map((info_key) => {
			return (
				<span>
					{language[info_key]}: {place.billing[info_key]}
				</span>
			)
		})

		let invoiceDate = new Date(printInvoice.date)
		let invoiceTime = `${`0${invoiceDate.getHours()}`.slice(
			-2
		)}:${`0${invoiceDate.getMinutes()}`.slice(-2)}`

		return (
			<div ref={invoiceRef} id="PRINT_INVOICE">
				<h3>{place.name}</h3>
				<strong>TICKET ID #{printInvoice.invoiceID.slice(-6)}</strong>
				<p className="billing">
					<span>
						Fecha: {invoiceDate.toLocaleDateString()} {invoiceTime}
					</span>
					{billing_info}
				</p>
				<table>
					<thead>
						<tr>
							<th>Producto</th>
							<th></th>
							<th></th>
							<th>Valor</th>
						</tr>
					</thead>
					<tbody>
						{printInvoice.items.map((it) => {
							return (
								<>
									<tr>
										<td>
											<strong>{it.name}</strong>
										</td>
										<td />
										<td />
										<td>{it.price} €</td>
									</tr>
									<tr>
										<td colSpan="4">
											<img
												src={it.qr}
												style={{
													width: 500,
													height: 500,
												}}
											/>
										</td>
									</tr>
								</>
							)
						})}

						<tr>
							<td colSpan="3">
								<strong>Total:</strong>
							</td>

							<td>
								<strong>{printInvoice.amount} €</strong>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}

	const getTickets = async () => {
		let res = await firestore
			.collection(`/places/${place.id}/tickets`)
			.where('event', '==', event.slug)
			.get()
		if (res.empty) setTickets([])
		else {
			let final_tickets = res.docs.map((ticket) => ticket.data())
			setTickets(final_tickets)
		}
	}

	const renderTickets = () => {
		if (tickets.length === 0)
			return (
				<div>
					<p>No has vendido entradas</p>
				</div>
			)
		return (
			<table
				class="table"
				style={{
					paddingTop: 100,
				}}
			>
				<thead>
					<tr>
						<th scope="col">Nombre</th>
						<th scope="col">Email</th>
						<th scope="col">DNI</th>
						<th scope="col">Entrada</th>
						<th scope="col">Factura</th>
					</tr>
				</thead>
				<tbody>
					{tickets.map((ticket) => (
						<tr>
							<th>{ticket.name}</th>
							<th>{ticket.email}</th>
							<th>{ticket.dni}</th>
							<th>
								{typeof event.groups.find(
									(r) => ticket.seat === r.id
								) === 'undefined'
									? 'ERROR'
									: event.groups.find((r) => ticket.seat === r.id)
											.name}
							</th>
							<th>
								<button
									type="button"
									onClick={(e) => {
										if (cookies.print) {
											printOnDesktop(ticket)
											create_alert(
												`Imprimiendo en ${cookies.print}`,
												'success'
											)
										} else {
											setPrintInvoice(ticket)
											setTimeout(() => {
												doInvoice(ticket.id)
											}, 200)
										}
									}}
									className="button button-small button-light"
								>
									Factura
								</button>
							</th>
						</tr>
					))}
				</tbody>
			</table>
		)
	}

	return (
		<>
			<ModalForm toggle={onCancel} size="lg">
				<form
					className="form-ui"
					style={{
						padding: 20,
					}}
					onSubmit={(e) => {
						e.preventDefault()
					}}
				>
					<h1>Todas las entradas de {event.name}</h1>
					{tickets === null && <p>Cargando</p>}
					{tickets !== null && renderTickets()}
				</form>
			</ModalForm>
			{printInvoice !== false && renderInvoice()}
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		language: state.language.dictionary,
	}),
	{
		create_alert,
	}
)(ShowTickets)

import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Input, ModalForm, More, Spinner } from '../../../components'
import { firestore, functions } from '../../../config/firebase'
import { create_alert } from '../../../store/actions'

function Collaborators({
	events,
	place,
	user,
	topbarCreateCollaborator,
	setTopbarCreateCollaborator,
	language,
}) {
	const [submitting, setSubmitting] = useState(false)
	const [showCollaborator, setShowCollaborator] = useState(null)
	const [eventsAllowed, setEventsAllowed] = useState([])
	const [collabs, setCollabs] = useState(null)
	const [forceCloseMore, setForceCloseMore] = useState(false)

	useEffect(() => {
		if (topbarCreateCollaborator === true) setShowCollaborator(true)
	}, [topbarCreateCollaborator])

	useEffect(() => {
		getCollabs(place.collaborators)
	}, [])

	const getCollabs = async (data = []) => {
		let _collabs = []
		for (let x = 0; x < data.length; x++) {
			const col = data[x]

			let _col = await firestore.collection('collaborators').doc(col).get()
			_collabs.push(_col.data())
		}
		setCollabs(_collabs)
	}

	const renderEmpty = () => {
		return (
			<aside className="empty">
				<i className="mi">supervisor_account</i>
				<h3>{language.no_collaborators_found}</h3>
				<button
					onClick={() => setShowCollaborator(true)}
					className="button inline button-primary"
				>
					<i className="mi">add</i>
					<span>{language.new_collaborator}</span>
				</button>
			</aside>
		)
	}

	const onChangeEventsPermissions = (v) => {
		if (v === null) return setEventsAllowed([])
		setEventsAllowed(v.map((_v) => _v.value))
	}

	const validateFunction = (v) => {
		let errors = {}
		if (v.name.length < 2) errors.name = language.invalid_field
		if (eventsAllowed.length === 0) errors.events = language.invalid_field
		let user_mail = user.email

		if (user_mail === v.email) errors.email = language.invalid_field
		if (
			typeof parseFloat(v.discount) !== 'number' ||
			parseFloat(v.discount / 100) > 1
		)
			errors.discount = language.invalid_field
		return errors
	}

	const createCollaborator = async (v) => {
		setSubmitting(true)
		let email = v.email

		if (place.collaborators.indexOf(email) !== -1) {
			create_alert(language.an_error_ocurred, 'danger')
			return
		}

		let discount = parseFloat(v.discount) / 100
		if (discount > 1) discount = 1
		if (isNaN(discount)) discount = 0

		let colab = await firestore.collection('collaborators').doc(email).get()
		if (!colab.exists) {
			await firestore
				.collection('collaborators')
				.doc(email)
				.set({
					places: {
						[place.id]: {
							id: place.id,
							events: [...eventsAllowed],
							sales: [],
							discount,
						},
					},
					email,
					created: new Date().getTime(),
					name: v.name,
				})
		} else {
			await firestore
				.collection('collaborators')
				.doc(email)
				.update({
					places: {
						...colab.data().places,
						[place.id]: {
							id: place.id,
							events: [...eventsAllowed],
							sales: [],
							discount,
						},
					},
				})
		}

		const fn = functions.httpsCallable('addExistingCollaborator')

		let res = await fn({
			email,
			eventsAllowed,
			place: place.id,
		})

		await firestore
			.collection('places')
			.doc(place.id)
			.update({
				collaborators: [...place.collaborators, email],
			})

		setSubmitting(false)
		setShowCollaborator(null)
		setTopbarCreateCollaborator(false)
		getCollabs([...place.collaborators, email])
	}

	const deleteCollaborator = async (col) => {
		setForceCloseMore(true)
		setTimeout(() => {
			setForceCloseMore(false)
		}, 500)

		await firestore
			.collection('places')
			.doc(place.id)
			.update({
				collaborators: place.collaborators.filter(
					(_col) => _col !== col.email
				),
			})
		let newPlaces = {}
		Object.keys(col.places).forEach((pl) => {
			// if (pl !== place.id) newPlaces.push(col.places[pl])
			if (pl !== place.id) newPlaces[pl] = col.places[pl]
		})
		await firestore.collection('collaborators').doc(col.email).update({
			places: newPlaces,
		})
		getCollabs(place.collaborators.filter((_col_) => _col_ !== col.email))
	}

	const renderCollaborators = () => {
		let rows = collabs.map((col) => {
			try {
				let revenue = 0
				col.places[place.id].sales.forEach((sale) => {
					revenue += sale.value
				})
				let c_events_items = []
				col.places[place.id].events.forEach((event) => {
					let _evt = events.find((_event) => _event.slug === event)
					if (typeof _evt !== 'undefined') {
						c_events_items.push(<li key={_evt.slug}>{_evt.name}</li>)
					}
				})
				let collaborator_events =
					c_events_items.length > 0 ? <ol>{c_events_items}</ol> : null
				return (
					<tr key={col.id}>
						<td>
							<strong>{col.name}</strong>
						</td>
						<td>{col.email}</td>
						<td>{collaborator_events}</td>
						<td className="center">
							{col.places[place.id].sales.length}
						</td>
						<td className="center">
							<span className="price">
								<pre>{revenue.toFixed(2)}</pre>{' '}
								<i className="mi">euro_symbol</i>
							</span>
						</td>
						<td className="more-cell">
							<More forceClose={forceCloseMore}>
								<button
									onClick={() => {
										setEventsAllowed(col.places[place.id].events)
										setShowCollaborator(col.email)
									}}
								>
									<i className="mi">edit</i>{' '}
									<span>{language.edit}</span>
								</button>
								<button onClick={() => deleteCollaborator(col)}>
									<i className="mi">delete</i>{' '}
									<span>{language.remove}</span>
								</button>
							</More>
						</td>
					</tr>
				)
			} catch (e) {
				return null
			}
		})
		return (
			<div className="table-ui table-sm">
				<div>
					<table>
						<thead>
							<tr>
								<th width="200">{language.name}</th>
								<th width="170">{language.email}</th>
								<th>{language.events}</th>
								<th width="170" className="center">
									{language.tickets_sold}
								</th>
								<th className="center">{language.revenue}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>{rows}</tbody>
					</table>
				</div>
			</div>
		)
	}

	const updateCollaborator = async (v) => {
		setSubmitting(true)
		let col = collabs.find((col) => col.phone === showCollaborator)
		let discount = parseFloat(v.discount) / 100
		if (discount > 1) discount = 1
		if (isNaN(discount)) discount = 0

		await firestore
			.collection('collaborators')
			.doc(col.email)
			.update({
				name: v.name,
				places: {
					...col.places,
					[place.id]: {
						...col.places[place.id],
						events: [...eventsAllowed],
						discount,
					},
				},
			})
		setSubmitting(false)
		setShowCollaborator(null)
		setTopbarCreateCollaborator(false)
		getCollabs(place.collaborators)
	}

	const submitCollaborator = (v) => {
		if (showCollaborator === true) createCollaborator(v)
		if (showCollaborator !== true) updateCollaborator(v)
	}

	if (showCollaborator !== null) {
		const colab = collabs.find((col) => col.phone === showCollaborator)

		let defaultVal = []
		const eventsAsOptions = events.map((ev) => ({
			label: ev.name,
			value: ev.slug,
		}))

		if (colab) {
			defaultVal = colab.places[place.id].events.map((_ev) => ({
				label: events.find((_ev_) => _ev === _ev_.slug).name,
				value: _ev,
			}))
		}

		return (
			<ModalForm
				toggle={() => {
					setShowCollaborator(null)
					setTopbarCreateCollaborator(false)
				}}
			>
				<Formik
					initialValues={{
						name: showCollaborator === true ? '' : colab.name,
						email: showCollaborator === true ? '' : colab.email,
						discount:
							showCollaborator === true
								? ''
								: colab.places[place.id].discount * 100,
						events: showCollaborator === true ? [] : colab.events,
					}}
					validate={validateFunction}
					onSubmit={submitCollaborator}
					validateOnBlur={false}
					validateOnChange={false}
				>
					{({ errors, touched }) => (
						<Form className="form-ui">
							<h1>{language.new_collaborator}</h1>
							<Input
								label={language.name}
								disabled={submitting}
								type="text"
								name="name"
								errors={errors}
								touched={touched}
							/>
							<Input
								label={language.email}
								disabled={submitting || showCollaborator !== true}
								type="email"
								name="email"
								errors={errors}
								touched={touched}
								popover="mail@dominio.com"
							/>
							<div className="form-group upper-7">
								<Select
									placeholder={language.allowed_events}
									options={eventsAsOptions}
									isMulti
									onChange={onChangeEventsPermissions}
									defaultValue={defaultVal}
									className="react-select"
									classNamePrefix="react-select"
								/>
							</div>
							<Input
								label={language.discount}
								disabled={submitting}
								type="text"
								name="discount"
								errors={errors}
								touched={touched}
								popover={
									language.this_applies_to_all_the_tickets_that_this_collaborator_sells
								}
							/>
							<footer style={{ marginTop: 10 }}>
								{errors.events && touched.events && (
									<p className="notice danger">{errors.events}</p>
								)}
								<button
									type="submit"
									disabled={submitting}
									className="button button-primary"
								>
									{submitting && (
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">
												{language.loading}...
											</span>
										</div>
									)}
									{!submitting && (
										<>
											<i className="mi">arrow_forward</i>
											<span>{language.save}</span>
										</>
									)}
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</ModalForm>
		)
	}

	return (
		<div>
			{collabs === null && <Spinner />}
			{collabs !== null && collabs.length === 0 && renderEmpty()}
			{collabs !== null && collabs.length > 0 && renderCollaborators()}
		</div>
	)
}

export default connect(
	(state) => ({
		events: state.events,
		place: state.place,
		user: state.user,
		language: state.language.dictionary,
	}),
	{ create_alert }
)(Collaborators)

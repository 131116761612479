import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Input, Popover, ModalForm } from '../../../components'
import { connect } from 'react-redux'
import Select from 'react-select'
import { create_alert } from '../../../store/actions'
import { firestore } from '../../../config/firebase'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const RenderEnterPassword = ({ setPasswordEnabled, user }) => {
	return (
		<div className="login-ui">
			<div>
				<Formik
					initialValues={{
						password: '',
					}}
					onSubmit={(v, { setFieldError }) => {
						if (v.password === user.main_password)
							setPasswordEnabled(true)
						else setFieldError('password', 'Contraseña incorrecta')
					}}
				>
					{({ errors, touched }) => (
						<Form className="form-ui">
							<h1>Ingresar Contraseña Principal</h1>
							<Input
								label={'Contraseña'}
								type="password"
								name="password"
								errors={errors}
								touched={touched}
							/>
							<footer style={{ marginTop: 10 }}>
								<button type="submit" className="button button-primary">
									<span>Continuar</span>
								</button>
							</footer>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	)
}

function Config({ place, covid, create_alert, user }) {
	const [submitting, setSubmitting] = useState(false)
	const [passwordEnabled, setPasswordEnabled] = useState(false)

	const [type, setType] = useState(null)

	useEffect(() => {
		// 1 is restaurant
		// 2 is hotel
		if (typeof place.groups === 'object') {
			if (place.groups.length > 0) {
				if (place.groups[0].category === 'hotel') setType(0)
				else setType(1)
			} else setType(1)
		} else setType(1)
	}, [place])

	const validate = (values) => {
		let errors = {}
		values.blocks.forEach((block) => {
			if (block.days.length === 0) errors.blocks = 'Selecciona dias'
			block.slots.forEach((slot) => {
				if (
					slot.starts !== null &&
					slot.ends !== null &&
					typeof slot.max === 'number'
				) {
					// Good
				} else {
					errors.blocks = 'Error en las franjas horarias'
				}
			})
		})
		Object.keys(errors).forEach((err) => {
			create_alert(errors[err], 'danger')
		})
		return errors
	}

	const getFormattedTime = (_date) => {
		let date =
			typeof _date === 'number' ? new Date(_date) : new Date(_date.getTime())
		return `${`0${date.getHours()}`.slice(
			-2
		)}:${`0${date.getMinutes()}`.slice(-2)}`
	}

	const onSubmit = async (values) => {
		setSubmitting(true)
		await firestore
			.doc(`/places/${place.id}/advanced_res_data/config`)
			.update({
				blocks: values.blocks.map((block) => ({
					...block,
					slots: block.slots.map((slot) => ({
						...slot,
						starts:
							typeof slot.starts === 'number'
								? slot.starts
								: slot.starts.getTime(),
						ends:
							typeof slot.ends === 'number'
								? slot.ends
								: slot.ends.getTime(),
						text: [
							getFormattedTime(slot.starts),
							getFormattedTime(slot.ends),
						],
					})),
				})),
				groups: values.groups,
				max_slot: values.max_slot,
			})
		create_alert('Configuración guardada', 'success')
		setSubmitting(false)
	}

	const getDayName = (value) => {
		switch (value) {
			case 0:
				return 'Lunes'
			case 1:
				return 'Martes'
			case 2:
				return 'Miércoles'
			case 3:
				return 'Jueves'
			case 4:
				return 'Viernes'
			case 5:
				return 'Sábado'
			case 6:
				return 'Domingo'
			default:
				return 'Error'
		}
	}

	const getDayOptions = () => {
		let days = [0, 1, 2, 3, 4, 5, 6]
		return days.map((d) => ({
			label: getDayName(d),
			value: d,
		}))
	}

	if (!passwordEnabled)
		return (
			<>
				<RenderEnterPassword
					setPasswordEnabled={setPasswordEnabled}
					user={user}
				/>
			</>
		)

	return (
		<div className="login-ui login-ui-lg">
			<div>
				<Formik
					initialValues={{
						blocks: covid.config.blocks || [],
						groups: covid.config.groups || [],
						max_slot: covid.config.max_slot || 0,
					}}
					validateOnBlur={false}
					validateOnChange={false}
					validate={validate}
					onSubmit={onSubmit}
				>
					{({ errors, touched, setFieldValue, values }) => {
						console.log('val', values)
						if (typeof values.groups === 'undefined') return null
						return (
							<Form className="form-ui">
								<h1>Configuración</h1>
								{type === 0 && (
									<fieldset className="first-child last-child">
										<div className="fieldset-header">
											<h2>Pensiones</h2>
										</div>
										<div className="fieldset-body">
											{values.groups.map((group, y) => {
												let subfieldset_class = ''
												if (y === 0)
													subfieldset_class += ' first-child'
												if (y === values.groups.length - 1)
													subfieldset_class += ' last-child'
												return (
													<>
														<fieldset
															className={subfieldset_class}
														>
															<div className="fieldset-body">
																<Input
																	name={`groups[${y}].name`}
																	type="text"
																	label="Nombre"
																	colside="left"
																	cols="6"
																	rowcolleft="3"
																	rowcolright="9"
																/>
																<Input
																	name={`groups[${y}].initials`}
																	type="text"
																	label="Iniciales"
																	colside="right"
																	cols="6"
																	rowcolleft="4"
																	rowcolright="8"
																/>
															</div>
															<Input
																name={`groups[${y}].description`}
																type="text"
																label="Descripción"
																cols="12"
																rowcolleft="3"
																rowcolright="9"
															/>
															<hr />
														</fieldset>
														{values.groups.length - 1 === y &&
															values.groups.length > 1 && (
																<button
																	onClick={() => {
																		setFieldValue(
																			`groups`,
																			values.groups.filter(
																				(s, i) => i !== y
																			)
																		)
																	}}
																	type="button"
																	className="button button-link button-link-sm button-link-delete"
																>
																	<i className="mi">
																		remove_circle_outline
																	</i>
																	<span>
																		Remover última pensión
																	</span>
																</button>
															)}
													</>
												)
											})}

											<div className="adder">
												<button
													type="button"
													className="button button-light"
													onClick={() => {
														setFieldValue('groups', [
															...values.groups,
															{
																id: `${Math.floor(
																	Math.random() * 1000000
																)}_${new Date().getTime()}`,
																name: '',
																initials: '',
																description: '',
															},
														])
													}}
												>
													<i className="mi">add</i>{' '}
													<span>Añadir pensión</span>
												</button>
											</div>
										</div>
									</fieldset>
								)}
								<h3>Turnos</h3>
								{values.blocks.map((block, x) => {
									let fieldset_class = ''
									if (x === 0) fieldset_class += ' first-child'
									if (x === values.blocks.length - 1)
										fieldset_class += ' last-child'
									return (
										<fieldset
											className={fieldset_class}
											key={block.id}
										>
											<div className="fieldset-header">
												<h2>Turno #{x + 1}</h2>
												<button
													onClick={() => {
														setFieldValue(
															'blocks',
															values.blocks.filter(
																(b, j) => j !== x
															)
														)
													}}
													type="button"
													className="button button-link button-link-sm button-link-delete"
												>
													<i className="mi">delete</i>
													<span>Eliminar Turno</span>
												</button>
											</div>
											<div className="fieldset-body">
												<div
													className={`form-group upper-${3 + x}`}
												>
													<div className="form-row">
														<div className="col-3">
															<label>Días:</label>
														</div>
														<div className="col-9">
															<Select
																placeholder="Selecciona los días que aplican"
																defaultValue={block.days.map(
																	(d) => ({
																		label: getDayName(d),
																		value: d,
																	})
																)}
																isMulti
																name="colors"
																options={getDayOptions()}
																className="react-select"
																classNamePrefix="react-select"
																onChange={(_values) => {
																	let vls = _values || []
																	setFieldValue(
																		`blocks[${x}].days`,
																		vls.map((v) => v.value)
																	)
																}}
															/>
														</div>
													</div>
												</div>
												{block.slots.map((slot, y) => {
													let subfieldset_class = ''
													if (y === 0)
														subfieldset_class += ' first-child'
													if (y === block.slots.length - 1)
														subfieldset_class += ' last-child'
													return (
														<>
															<fieldset
																className={subfieldset_class}
															>
																<div className="fieldset-body">
																	<div className="form-group colside-left cols-4">
																		<div className="form-row">
																			<div className="col-5">
																				<label>
																					Empieza
																				</label>
																			</div>
																			<div className="col-7">
																				<DatePicker
																					selected={
																						values.blocks[
																							x
																						].slots[y]
																							.starts
																					}
																					onChange={(
																						date
																					) =>
																						setFieldValue(
																							`blocks[${x}].slots[${y}].starts`,
																							date
																						)
																					}
																					showTimeSelect
																					showTimeSelectOnly
																					timeIntervals={
																						15
																					}
																					timeCaption="Hora"
																					dateFormat="HH:mm"
																					timeFormat="HH:mm"
																				/>
																			</div>
																		</div>
																	</div>
																	<div className="form-group colside-center cols-4">
																		<div className="form-row">
																			<div className="col-5">
																				<label>
																					Termina
																				</label>
																			</div>
																			<div className="col-7">
																				<DatePicker
																					selected={
																						values.blocks[
																							x
																						].slots[y]
																							.ends
																					}
																					onChange={(
																						date
																					) =>
																						setFieldValue(
																							`blocks[${x}].slots[${y}].ends`,
																							date
																						)
																					}
																					showTimeSelect
																					showTimeSelectOnly
																					timeIntervals={
																						15
																					}
																					timeCaption="Hora"
																					dateFormat="HH:mm"
																					timeFormat="HH:mm"
																				/>
																			</div>
																		</div>
																	</div>
																	<Input
																		name={`blocks[${x}].slots[${y}].max`}
																		type="number"
																		label="Personas Max"
																		colside="right"
																		cols="4"
																		rowcolleft="8"
																		rowcolright="4"
																	/>
																</div>
															</fieldset>
															{block.slots.length - 1 === y &&
																block.slots.length > 1 && (
																	<button
																		onClick={() => {
																			setFieldValue(
																				`blocks[${x}].slots`,
																				values.blocks[
																					x
																				].slots.filter(
																					(s, i) =>
																						i !==
																						values.blocks[
																							x
																						].slots
																							.length -
																							1
																				)
																			)
																		}}
																		type="button"
																		className="button button-link button-link-sm button-link-delete"
																	>
																		<i className="mi">
																			remove_circle_outline
																		</i>
																		<span>
																			Remover última franja
																		</span>
																	</button>
																)}
														</>
													)
												})}

												<div className="adder">
													<button
														type="button"
														className="button button-light"
														onClick={() => {
															setFieldValue(
																'blocks',
																values.blocks.map((b, _x) => {
																	if (_x !== x) return b
																	return {
																		...b,
																		slots: [
																			...b.slots,
																			{
																				id: `${Math.floor(
																					Math.random() *
																						1000000
																				)}_${new Date().getTime()}`,
																				starts: null,
																				ends: null,
																				max: '',
																			},
																		],
																	}
																})
															)
														}}
													>
														<i className="mi">add</i>{' '}
														<span>Añadir Franja</span>
													</button>
												</div>
											</div>
										</fieldset>
									)
								})}

								<div className="adder has-popover">
									<button
										type="button"
										className="button button-light"
										onClick={() => {
											setFieldValue('blocks', [
												...values.blocks,
												{
													id: `${Math.floor(
														Math.random() * 1000000
													)}_${new Date().getTime()}`,
													days: [],
													slots: [
														{
															id: `${Math.floor(
																Math.random() * 1000000
															)}_${new Date().getTime()}`,
															starts: null,
															ends: null,
															max: '',
														},
													],
												},
											])
										}}
									>
										<i className="mi">add</i>{' '}
										<span>Agregar Turno</span>
									</button>
									<Popover
										content="Agregue una configuración de turnos que puede aplicar a varios días de la semana"
										prevent={false}
										offset={10}
										reversed
									>
										<i className="mi">help</i>
									</Popover>
								</div>
								<hr />
								<div className="alert alert-blue">
									Maximo para excederse en personas por franja
								</div>
								<Input
									name={`max_slot`}
									type="number"
									label="Maximo"
									rowcolleft="4"
									rowcolright="8"
								/>
								<footer>
									<button
										disabled={submitting}
										type="submit"
										className="button button-primary"
									>
										{submitting && (
											<div role="status">
												<span>Cargando...</span>
											</div>
										)}
										{!submitting && 'Guardar'}
									</button>
								</footer>
							</Form>
						)
					}}
				</Formik>
			</div>
		</div>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		covid: state.covid,
		user: state.user,
	}),
	{
		create_alert,
	}
)(Config)

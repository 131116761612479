import React, { useState, useEffect } from 'react'
import { Link, Switch, Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Sales from './Sales'
import Products from './Products'
import Events from './Events'
import Reservations from './Reservations'
import DatePicker from 'react-datepicker'
import { firestore } from '../../../config/firebase'

function Stats({ language, place, topbar }) {
	const [active, setActive] = useState(0)
	const [currentDate, setCurrentDate] = useState(new Date())
	const [data, setData] = useState(null)

	useEffect(() => {
		const arrayOfLinks = ['sales', 'products', 'events', 'reservations']
		let pathname = window.location.pathname
		pathname = pathname.split('/')[2]
		let index = 0

		arrayOfLinks.forEach((id, i) => {
			if (id === pathname) {
				index = i
			}
		})

		setActive(index)

		firestore
			.collection('places')
			.doc(place.id)
			.collection('stats')
			.doc('sales')
			.get()
			.then((res) => {
				if (res.exists) setData(res.data())
			})
	}, [])

	return (
		<>
			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs limit-w">
					<li className={active === 0 ? 'active' : ''}>
						<Link
							to="/stats/sales"
							onClick={(e) => {
								setActive(0)
							}}
						>
							{language.sales}
						</Link>
					</li>

					<li className={active === 1 ? 'active' : ''}>
						<Link
							to="/stats/products"
							onClick={(e) => {
								setActive(1)
							}}
						>
							{language.products}
						</Link>
					</li>
					<li className={active === 2 ? 'active' : ''}>
						<Link
							to="/stats/events"
							onClick={(e) => {
								setActive(2)
							}}
						>
							{language.events}
						</Link>
					</li>
					<li className={active === 3 ? 'active' : ''}>
						<Link
							to="/stats/reservations"
							onClick={(e) => {
								setActive(3)
							}}
						>
							{language.reservations}
						</Link>
					</li>
				</ul>
				<div className="pull-right">
					<div className="actions">
						{active === 0 && (
							<div
								className="date-wrapper"
								style={{
									paddingRight: 0,
								}}
							>
								<DatePicker
									selected={currentDate}
									onChange={(date) => setCurrentDate(date)}
									locale="es"
									customInput={
										<button className="button button-secondary">
											<span>
												{new Date(currentDate).toLocaleString(
													'es-ES',
													{
														month: 'long',
													}
												)}
											</span>
										</button>
									}
									maxDate={new Date()}
									popperPlacement="bottom-left"
									popperModifiers={{
										offset: {
											enabled: true,
											offset: '5px, 10px',
										},
										preventOverflow: {
											enabled: true,
											escapeWithReference: false,
											boundariesElement: 'viewport',
										},
									}}
									dateFormat="MMMM yyyy"
									showMonthYearPicker
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			{data !== null && (
				<Switch>
					<Route
						path="/stats/sales"
						render={() => <Sales date={currentDate} data={data} />}
					/>
					<Route
						path="/stats/products"
						render={() => <Products date={currentDate} data={data} />}
					/>
					<Route
						path="/stats/events"
						render={() => <Events date={currentDate} data={data} />}
					/>
					<Route
						path="/stats/reservations"
						render={() => <Reservations date={currentDate} data={data} />}
					/>
				</Switch>
			)}
		</>
	)
}

export default connect((state) => ({
	language: state.language.dictionary,
	place: state.place,
	topbar: state.topbar,
}))(Stats)

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Confirmation, Loading, More } from '../../../components'
import { firestore, storage, functions } from '../../../config/firebase'
import {
	add_event,
	create_alert,
	edit_event,
	events,
} from '../../../store/actions'
import CreateEvent from './CreateEvent'
import EditEvent from './EditEvent'
import Reader from './Reader'
import SellTickets from './SellTickets'
import ShowTickets from './ShowTickets'

function Events({
	place,
	set_events,
	events,
	create_alert,
	showReader,
	setShowReader,
	showCreateEvent,
	setShowCreateEvent,
	isCollaborator = false,
	edit_event,
	add_event,
	language,
	loading,
	showProductReader,
	setShowProductReader,
}) {
	const [sellTickets, setSellTickets] = useState(null)
	const [removingEvent, setRemovingEvent] = useState(null)
	const [editEvent, setEditEvent] = useState(null)
	const [outerEvents, setOuterEvents] = useState({})
	const [lastEv, setLastEv] = useState('')
	const [submitting, setSubmitting] = useState(false)
	const [showTickets, setShowTickets] = useState(null)

	useEffect(() => {
		if (isCollaborator) {
			setUpAsCollaborator()
		} else {
			setUpAsPlace()
		}
	}, [])

	useEffect(() => {
		if (lastEv !== JSON.stringify(events) && isCollaborator) {
			setLastEv(JSON.stringify(events))
			updateAsCollaborator()
		}
	}, [events])

	const setUpAsPlace = async () => {
		const fn = functions.httpsCallable('getCollaboratorEvents')
		let _events = {}
		for (let j = 0; j < place.collaborating.length; j++) {
			const placeCol = place.collaborating[j]
			let res = await fn({
				placeCol,
			})
			if (res.data !== false) _events = { ..._events, ...res.data }
		}

		setOuterEvents({
			..._events,
		})
	}

	const setUpAsCollaborator = async () => {
		for (let x = 0; x < events.length; x++) {
			const ev = events[x]
			add_event([{ ...ev }])
		}
	}

	const updateAsCollaborator = async () => {
		for (let x = 0; x < events.length; x++) {
			const ev = events[x]
			let pl = await firestore.collection('places').doc(ev.place).get()
			pl = pl.data()
			edit_event({ ...ev, placeName: pl.name })
		}
	}

	const updateEvents = (event) => {
		set_events([...events, { ...event }])
	}

	const removeEvent = async (event) => {
		set_events(events.filter((ev) => ev.slug !== event.slug))
		await firestore
			.collection('places')
			.doc(place.id)
			.collection('events')
			.doc(event.slug)
			.delete()
		await firestore.collection('events_list').doc(event.slug).delete()
		create_alert(
			`${language.X_has_been_deleted}`.replace('X', event.name),
			'success'
		)
		setSubmitting(false)
	}

	const renderEvents = () => {
		let outerEvs = []
		Object.keys(outerEvents).forEach((key) => outerEvs.push(outerEvents[key]))
		let allEvents = [...events, ...outerEvs]
		return allEvents.map((event) => {
			let revenue = 0
			event.groups.forEach((gr) => {
				revenue += gr.purchased * gr.price
			})
			let img = event.images[0]

			let start_date = new Date(event.startDate).toDateString()
			start_date = start_date.replace(new Date().getFullYear(), '')

			let end_date = new Date(event.endDate).toDateString()
			end_date = end_date.replace(new Date().getFullYear(), '')

			let start_h =
				new Date(event.startDate).getHours() < 10
					? `0${new Date(event.startDate).getHours()}`
					: new Date(event.startDate).getHours()
			let start_m =
				new Date(event.startDate).getMinutes() < 10
					? `0${new Date(event.startDate).getMinutes()}`
					: new Date(event.startDate).getMinutes()
			let start = `${start_h}:${start_m}`
			let end_h =
				new Date(event.endDate).getHours() < 10
					? `0${new Date(event.endDate).getHours()}`
					: new Date(event.endDate).getHours()
			let end_m =
				new Date(event.endDate).getMinutes() < 10
					? `0${new Date(event.endDate).getMinutes()}`
					: new Date(event.endDate).getMinutes()
			let end = `${end_h}:${end_m}`

			let status = 0
			if (
				new Date().getTime() < event.endDate &&
				new Date().getTime() > event.startDate
			)
				status = 1
			if (new Date().getTime() > event.endDate) status = 2

			return (
				<li key={event.slug}>
					{!isCollaborator && (
						<More className="more-holder">
							<button
								onClick={() => setShowTickets(event)}
								type="button"
							>
								<i className="mi">list</i>
								<span>Ver entradas</span>
							</button>
							<button
								onClick={() => setRemovingEvent(event)}
								type="button"
							>
								<i className="mi">delete</i>
								<span>{language.remove}</span>
							</button>
							<button onClick={() => setEditEvent(event)} type="button">
								<i className="mi">edit</i>
								<span>{language.edit_event}</span>
							</button>
						</More>
					)}
					<a
						className="card card-horizontal"
						onClick={() => {
							if (status !== 2) setSellTickets(event)
						}}
					>
						<span
							className="poster"
							style={{
								backgroundImage: `url(${img})`,
							}}
						>
							{(img === '' || img === null) && (
								<i className="mi">local_activity</i>
							)}
						</span>
						<div className="body">
							<strong className="title">{event.name}</strong>
							<em className="date">
								<i className="mi">event</i>{' '}
								<time>
									{start_date} - {start}{' '}
									{end_date !== start_date && <br />}
									{end_date !== start_date && end_date} - {end}
								</time>
							</em>
							<div className="badges">
								{status != 0 && (
									<b
										className={`event-badge ${
											status === 1 ? 'running' : 'ended'
										}`}
									>
										<i className="mi">{`${
											status === 1
												? 'timer'
												: 'remove_circle_outline'
										}`}</i>
										<span>
											{status === 1 ? 'Corriendo' : 'Finalizado'}
										</span>
									</b>
								)}

								{typeof event.placeName !== 'undefined' && (
									<b>
										<i className="mi">star</i>
										<span>{event.placeName}</span>
									</b>
								)}
							</div>
						</div>
					</a>
				</li>
			)
		})
	}

	if (loading) return <Loading reload />

	if (showProductReader)
		return <Reader cancel={() => setShowProductReader(false)} product />
	if (showReader) return <Reader cancel={() => setShowReader(false)} />

	let showEventsList = events.length > 0 || Object.keys(outerEvents).length > 0

	return (
		<>
			{showTickets && (
				<ShowTickets
					onCancel={() => setShowTickets(null)}
					event={showTickets}
				/>
			)}
			{removingEvent !== null && (
				<Confirmation
					label={language.remove}
					icon="delete"
					submitting={submitting}
					onChange={async (res) => {
						if (res === false) {
							setRemovingEvent(null)
						} else {
							setSubmitting(true)
							removeEvent(removingEvent)
							setRemovingEvent(null)
						}
					}}
				>
					{language.sure_you_want_to_delete}
					<br /> {removingEvent.name}?
				</Confirmation>
			)}
			{!showEventsList && (
				<aside className="empty">
					<i className="mi">local_activity</i>
					<h3>{language.no_events_found}</h3>
					<button
						className="button inline button-primary"
						onClick={() => setShowCreateEvent(true)}
					>
						<i className="mi">add</i>
						<span>{language.new_event}</span>
					</button>
				</aside>
			)}
			{showEventsList && (
				<div className="grid-ui grid-ui-horizontal">
					<div>
						<ul>{renderEvents()}</ul>
					</div>
				</div>
			)}
			{showCreateEvent && (
				<CreateEvent
					setShowCreateEvent={setShowCreateEvent}
					updateEvents={updateEvents}
				/>
			)}
			{editEvent !== null && (
				<EditEvent
					updateEvents={updateEvents}
					setEditEvent={setEditEvent}
					event={editEvent}
				/>
			)}
			{sellTickets !== null && (
				<SellTickets
					event={sellTickets}
					setSellTickets={setSellTickets}
					updateEvents={updateEvents}
					isCollaborator={isCollaborator}
				/>
			)}
		</>
	)
}

export default connect(
	(state) => ({
		place: state.place,
		events: state.events,
		language: state.language.dictionary,
	}),
	{
		set_events: events,
		create_alert,
		edit_event,
		add_event,
	}
)(Events)

import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Confirmation } from '../../../components'
import { auth } from '../../../config/firebase'
import {
	sign_employee,
	remove_place,
	create_alert,
} from '../../../store/actions'

function Navbar({
	place,
	user,
	permissions,
	history,
	collapse,
	navbarOpened,
	setNavbarOpened,
	collaborator = false,
	sign_employee,
	employee,
	language,
	pending_orders,
	remove_place,
	pending_reservations,
	create_alert,
	feedback,
}) {
	const [active, setActive] = useState(-1)
	const [perms, setPerms] = useState([])
	const [signingOut, setSigningOut] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const [navbarMinHeight, _setNavbarMinHeight] = useState(null)
	const [navbarTop, setNavbarTop] = useState(0)
	const [navbarTopPosition, setNavbarTopPosition] = useState('absolute')
	const [chatNotification, setChatNotification] = useState(false)

	const navbarMinHeightRef = useRef(navbarMinHeight)
	const navbarRef = useRef(null)
	const navbarRefA = useRef(null)
	const navbarRefB = useRef(null)
	const navbarRefC = useRef(null)

	const setNavbarMinHeight = (val) => {
		navbarMinHeightRef.current = val
		_setNavbarMinHeight(val)
	}

	useEffect(() => {
		let notify = 0
		let savedPrevChat = localStorage.getItem('savedChat')
		savedPrevChat = savedPrevChat !== null ? JSON.parse(savedPrevChat) : []

		if (typeof place.chat !== 'undefined') {
			Object.keys(place.chat).forEach((k) => {
				let chat = place.chat[k]
				let last_msg = chat[chat.length - 1]
				if (
					last_msg.sender !== place.id &&
					savedPrevChat.indexOf(last_msg.id) === -1
				) {
					notify++
					savedPrevChat.push(last_msg.id)
				}
			})
		}
		localStorage.setItem('savedChat', JSON.stringify(savedPrevChat))

		if (notify > 0) create_alert('Nuevo mensaje', 'success', '/chat', true)
		setChatNotification(notify)
	}, [place.chat])

	useEffect(() => {
		if (
			navbarRefA.current !== null &&
			navbarRefB.current !== null &&
			navbarRefC.current !== null
		) {
			setTimeout(() => {
				let sum =
					navbarRefA.current.clientHeight +
					navbarRefB.current.clientHeight +
					navbarRefC.current.clientHeight
				setNavbarMinHeight(sum)
			}, 1000)
		}
	}, [navbarRefA, navbarRefB, navbarRefC])

	useLayoutEffect(() => {
		function updateSize() {
			if (
				navbarRefA.current !== null &&
				navbarRefB.current !== null &&
				navbarRefC.current !== null
			) {
				let sum =
					navbarRefA.current.clientHeight +
					navbarRefB.current.clientHeight +
					navbarRefC.current.clientHeight
				setNavbarMinHeight(sum)
			}
		}

		function updateScroll() {
			let isOverflown = navbarMinHeightRef.current > window.innerHeight
			if (!isOverflown) {
				setNavbarTopPosition('fixed')
				setNavbarTop(0)
			} else {
				let hiddenPart = navbarMinHeightRef.current - window.innerHeight
				if (window.scrollY > hiddenPart) {
					setNavbarTopPosition('fixed')
					setNavbarTop(-hiddenPart)
				} else {
					setNavbarTopPosition('absolute')
					setNavbarTop(0)
				}
			}
		}
		window.addEventListener('resize', updateSize)
		window.addEventListener('scroll', updateScroll)
		updateScroll()
		return () => {
			window.removeEventListener('resize', updateSize)
			window.removeEventListener('scroll', updateScroll)
		}
	}, [])

	useEffect(() => {
		if (collaborator) return
		let cat = place.employee_categories.find(
			(c) => c.id === employee.category
		)

		setPerms(cat.permissions)
		onChangeUrl(window.location)
		history.listen(onChangeUrl)
	}, [])

	const onChangeUrl = (location) => {
		const arrayOfLinks = [
			'tables',
			'invoicing',
			'orders',
			'products',
			'queue',
			'reservations',
			'inventory',
			'sales',
			'employees',
			'configuration',
			'account',
			'events',
			'report',
			'stats',
			'checkout',
			'chat',
			'announcements',
			'reservations_covid',
			'feedback',
		]
		let pathname = location.pathname
		pathname = pathname.split('/')[1]
		let index = -1
		arrayOfLinks.forEach((id, i) => {
			if (id === pathname) {
				index = i
			}
		})
		setActive(index)
	}

	let showEmployees =
		perms.indexOf('employees') !== -1 &&
		place.features.indexOf('employees') !== -1 &&
		user.type === 'admin_premium'

	if (
		typeof user.custom !== 'undefined' &&
		user.custom.indexOf('employees_global') !== -1
	)
		showEmployees = false

	return (
		<>
			{signingOut && (
				<Confirmation
					onChange={(res) => {
						setSubmitting(true)
						if (res) {
							setTimeout(() => {
								if (collaborator) auth.signOut()
								sign_employee(null)
								// remove_place(null)
								setSigningOut(false)
								localStorage.clear()
								setSubmitting(false)
							}, 300)
						} else {
							setSubmitting(false)
							setSigningOut(false)
						}
					}}
					label={language.sign_out}
					icon="exit_to_app"
					type="primary"
					submitting={submitting}
				>
					{language.sign_out_from}{' '}
					{collaborator ? user.name : employee.name}?
				</Confirmation>
			)}

			<nav
				id="navbar"
				style={{
					position: navbarTopPosition,
					top: navbarTopPosition === 'absolute' ? 0 : navbarTop,
				}}
			>
				<div ref={navbarRef}>
					<div ref={navbarRefA}>
						<div
							className={`status ${place.open ? 'success' : 'danger'}`}
						>
							<p>{place.open ? 'ABIERTO' : 'CERRADO'}</p>
						</div>
						<div className="brand">
							{place.list_image !== null && (
								<img src={place.list_image} />
							)}
							{place.list_image === null && (
								<i className="mi">
									{collaborator ? 'person' : 'store'}
								</i>
							)}
							<strong>{collaborator ? user.name : place.name}</strong>
						</div>
						{collaborator && (
							<ul>
								<li className={`${active === 11 ? 'active' : ''}`}>
									<Link
										onClick={(e) => {
											e.stopPropagation()

											setActive(11)
										}}
										to="/events/list"
										title={language.events}
									>
										<i className="mi">local_activity</i>
										<span>{language.events}</span>
									</Link>
								</li>
							</ul>
						)}
						{!collaborator && (
							<ul>
								{perms.indexOf('tables') !== -1 &&
									place.type !== 'beauty' &&
									place.type !== 'store' &&
									user.type === 'admin_premium' &&
									place.features.indexOf('tables') !== -1 && (
										<li className={`${active === 0 ? 'active' : ''}`}>
											<Link
												onClick={(e) => {
													e.stopPropagation()
													setActive(0)
												}}
												to="/tables"
												title="Mesas"
											>
												<i className="mi">grid_on</i>
												<span>Mesas</span>
											</Link>
										</li>
									)}

								<li className={`${active === 3 ? 'active' : ''}`}>
									<Link
										onClick={(e) => {
											e.stopPropagation()
											setActive(3)
										}}
										to="/products"
										title="Productos"
									>
										<i className="mi">
											{place.type === 'beauty'
												? 'spa'
												: 'restaurant'}
										</i>
										<span>Productos</span>
									</Link>
								</li>

								{user.type === 'admin_premium' && (
									<li className={`${active === 14 ? 'active' : ''}`}>
										<Link
											onClick={(e) => {
												e.stopPropagation()
												setActive(14)
											}}
											to="/checkout"
											title="Ventas"
										>
											<i className="mi">shopping_basket</i>
											<span>Ventas</span>
										</Link>
									</li>
								)}

								{user.type !== 'admin_free' && (
									<li className={`${active === 2 ? 'active' : ''}`}>
										<Link
											onClick={(e) => {
												e.stopPropagation()

												setActive(2)
											}}
											to="/orders"
											title="Pedidos"
										>
											<i className="mi">receipt</i>
											<span>Pedidos</span>
											{pending_orders > 0 && (
												<pre>{pending_orders}</pre>
											)}
										</Link>
									</li>
								)}

								{perms.indexOf('events') !== -1 &&
									place.features.indexOf('events') !== -1 &&
									user.type !== 'admin_free' && (
										<li
											className={`${active === 11 ? 'active' : ''}`}
										>
											<Link
												onClick={(e) => {
													e.stopPropagation()

													setActive(11)
												}}
												title="Eventos"
												to="/events/list"
											>
												<i className="mi">local_activity</i>
												<span>Eventos</span>
											</Link>
										</li>
									)}
								{showEmployees && (
									<li className={`${active === 8 ? 'active' : ''}`}>
										<Link
											onClick={(e) => {
												e.stopPropagation()

												setActive(8)
											}}
											title="Empleados"
											to="/employees"
										>
											<i className="mi">work</i>
											<span>Empleados</span>
										</Link>
									</li>
								)}
								{perms.indexOf('employees') !== -1 &&
									place.features.indexOf('employees') !== -1 &&
									user.type === 'admin_premium' && (
										<li
											className={`${active === 12 ? 'active' : ''}`}
										>
											<Link
												onClick={(e) => {
													e.stopPropagation()

													setActive(12)
												}}
												title="Reportar Horas"
												to="/report"
											>
												<i className="mi">access_time</i>
												<span>Reportar Horas</span>
											</Link>
										</li>
									)}
								{perms.indexOf('queue') !== -1 &&
									place.features.indexOf('queue') !== -1 &&
									user.type === 'admin_premium' && (
										<li className={`${active === 4 ? 'active' : ''}`}>
											<Link
												onClick={() => setActive(4)}
												to="/queue"
												title="Cola Virtual"
											>
												<i className="mi">supervisor_account</i>
												<span>Cola Virtual</span>
											</Link>
										</li>
									)}
								{perms.indexOf('queue') !== -1 &&
									place.features.indexOf('reservations_covid') !==
										-1 &&
									user.type === 'admin_premium' && (
										<li
											className={`${active === 17 ? 'active' : ''}`}
										>
											<Link
												onClick={() => setActive(17)}
												to="/reservations_covid/list"
												title={
													typeof user.custom !== 'undefined' &&
													user.custom.indexOf('covid_res') !== -1
														? 'Reservas Covid19'
														: 'Reserva Avanzada'
												}
											>
												<i className="mi">class</i>
												<span>
													{typeof user.custom !== 'undefined' &&
													user.custom.indexOf('covid_res') !== -1
														? 'Reservas Covid19'
														: 'Reserva Avanzada'}
												</span>
											</Link>
										</li>
									)}

								{/* {feedback.length > 0 && ( */}
								<li className={`${active === 18 ? 'active' : ''}`}>
									<Link
										onClick={() => setActive(18)}
										to="/feedback"
										title="Incidencias"
									>
										<i className="mi">report_problem</i>
										<span>Incidencias</span>
									</Link>
								</li>
								{/* )} */}

								{perms.indexOf('reservations') !== -1 &&
									place.features.indexOf('reservations') !== -1 &&
									user.type !== 'admin_free' && (
										<li className={`${active === 5 ? 'active' : ''}`}>
											<Link
												onClick={() => setActive(5)}
												to="/reservations"
												title="Reservas"
											>
												<i className="mi">class</i>
												<span>Reservas</span>
												{Object.keys(pending_reservations).length >
													0 && (
													<pre>
														{
															Object.keys(pending_reservations)
																.length
														}
													</pre>
												)}
											</Link>
										</li>
									)}

								{perms.indexOf('administrator') !== -1 &&
									user.type !== 'admin_free' && (
										<li
											className={`${active === 13 ? 'active' : ''}`}
											title="Estadísticas"
										>
											<Link
												onClick={() => setActive(13)}
												to="/stats"
											>
												<i className="mi">pie_chart</i>
												<span>Estadísticas</span>
											</Link>
										</li>
									)}

								{/* {perms.indexOf('inventory') !== -1 &&
									place.features.indexOf('inventory') !== -1 && (
										<li className={`${active === 6 ? 'active' : ''}`}>
											<Link
												onClick={() => setActive(6)}
												to="/inventory"
												title="Inventario"
											>
												<i className="mi">kitchen</i>
												<span>Inventario</span>
											</Link>
										</li>
									)} */}
								{/* {perms.indexOf('sales') !== -1 && (
										
											<li className={`${active === 7 ? 'active' : ''}`}>
												<Link onClick={() => setActive(7)} to="/sales">
													<i className="mi">timeline</i>
													<span>Sales</span>
												</Link>
											</li>
										
									)} */}

								{place.allow_chat && user.type === 'admin_premium' && (
									<li className={`${active === 15 ? 'active' : ''}`}>
										<Link
											onClick={(e) => {
												e.stopPropagation()
												setActive(15)
											}}
											to="/chat"
											title="Chat"
										>
											<i className="mi">chat</i>
											<span>Chat</span>
											{chatNotification > 0 && (
												<pre>{chatNotification}</pre>
											)}
										</Link>
									</li>
								)}
								{user.type === 'admin_premium' && (
									<li className={`${active === 16 ? 'active' : ''}`}>
										<Link
											onClick={(e) => {
												e.stopPropagation()
												setActive(16)
											}}
											to="/announcements"
											title="Announcements"
										>
											<i className="mi">notifications</i>
											<span>Anuncios</span>
										</Link>
									</li>
								)}
								{perms.indexOf('administrator') !== -1 && (
									<li className={`${active === 9 ? 'active' : ''}`}>
										<Link
											onClick={(e) => {
												e.stopPropagation()
												setActive(9)
											}}
											to="/configuration/place/general"
											title={language.place_configuration}
										>
											<i className="mi">settings</i>
											<span>{language.place_configuration}</span>
										</Link>
									</li>
								)}
							</ul>
						)}
						<button
							className="collapse"
							ref={navbarRefB}
							onClick={() => {
								setNavbarOpened(!navbarOpened)
								localStorage.setItem(
									'navbar',
									navbarOpened ? 'false' : 'true'
								)
							}}
							title={`${
								navbarOpened ? 'Cerrar Navegación' : 'Abrir Navegación'
							}`}
						>
							<i className="mi">
								{navbarOpened
									? 'keyboard_arrow_left'
									: 'keyboard_arrow_right'}
							</i>
						</button>
					</div>
					<button
						ref={navbarRefC}
						className="user"
						onClick={(e) => {
							e.stopPropagation()
							setSigningOut(true)
						}}
					>
						<i className="mi">power_settings_new</i>
						<span>{language.switch_navbar}</span>
					</button>
				</div>
			</nav>
		</>
	)
}

export default connect(
	(state) => ({
		employee: state.employee,
		place: state.place,
		language: state.language.dictionary,
		pending_orders: state.pending_orders,
		pending_reservations:
			state.reservations !== null ? state.reservations.pending : {},
		feedback: state.feedback,
	}),
	{ sign_employee, remove_place, create_alert }
)(Navbar)

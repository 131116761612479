import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { update_user, create_alert } from '../../../store/actions'
import { firestore } from '../../../config/firebase'
import { Loading } from '../../../components'
import { Link } from 'react-router-dom'

function Terms({ update_user, user, create_alert, review = false }) {
	const [submitting, setSubmitting] = useState(false)
	const [terms, setTerms] = useState(null)

	useEffect(() => {
		firestore
			.collection('config')
			.doc('variables')
			.get()
			.then((res) => {
				let data = res.data()
				setTerms(data.terms)
			})
	}, [])

	const onSubmit = async () => {
		setSubmitting(true)
		let userRef = firestore.collection('users').doc(user.id)
		firestore
			.runTransaction(async (transaction) => {
				let latestUser = await transaction.get(userRef)
				latestUser = latestUser.data()

				await transaction.update(userRef, {
					terms_accepted: true,
				})
			})
			.then(() => {
				setSubmitting(false)
				update_user({
					...user,
					terms_accepted: true,
				})
				create_alert('Terminos aceptados', 'success')
			})
			.catch((err) => {
				create_alert('Hubo un error #05', 'danger')
				setSubmitting(false)
			})
	}

	if (terms === null) return <Loading />

	return (
		<div className="login-ui wide">
			<Helmet>
				<title>Vywap | Terminos</title>
			</Helmet>
			<div>
				<Formik
					initialValues={{
						accepted: false,
					}}
					onSubmit={onSubmit}
					validate={(e) => {
						if (!e.accepted)
							return { accepted: 'Tienes que aceptar los terminos' }
					}}
				>
					{({ values, setFieldValue }) => (
						<Form
							className="form-ui form-ui-terms"
							style={{
								marginTop: 0,
								marginBottom: 0,
								padding: 20,
							}}
						>
							<span className="logo"></span>
							<h1>Aviso legal y condiciones generales</h1>
							<div
								style={{
									padding: 30,
								}}
								dangerouslySetInnerHTML={{ __html: terms }}
							/>

							{!review && (
								<>
									<div className="form-group form-group-check">
										<div className="form-row">
											<div className="col-12">
												<Field
													type="checkbox"
													name="accepted"
													id="accepted"
													checked={values.accepted}
													onChange={(e) =>
														setFieldValue('accepted', true)
													}
												/>
												<label htmlFor="accepted">
													<b></b> <span>Acepto los Terminos</span>
												</label>
											</div>
										</div>
									</div>
								</>
							)}
						</Form>
					)}
				</Formik>
			</div>
		</div>
	)
}

export default connect((state) => ({ user: state.user }), {
	update_user,
	create_alert,
})(Terms)

export default [
	{ name: 'red', value: 'red' },
	{ name: 'pink', value: 'pink' },
	{ name: 'purple', value: 'purple' },
	{ name: 'indigo', value: 'indigo' },
	{ name: 'blue', value: 'blue' },
	{ name: 'lightblue', value: 'lightblue' },
	{ name: 'cyan', value: 'cyan' },
	{ name: 'aqua', value: 'aqua' },
	{ name: 'teal', value: 'teal' },
	{ name: 'green', value: 'green' },
	{ name: 'grassgreen', value: 'grassgreen' },
	{ name: 'lime', value: 'lime' },
	{ name: 'yellow', value: 'yellow' },
	{ name: 'gold', value: 'gold' },
	{ name: 'tangerine', value: 'tangerine' },
	{ name: 'orange', value: 'orange' },
	{ name: 'brown', value: 'brown' },
	{ name: 'slategray', value: 'slategray' },
	{ name: 'black', value: 'black' }
]

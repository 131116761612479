import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

import * as firebaseConfigJSON from './firebase.prod.config.json'
// import * as firebaseConfigJSON from './firebase.dev.config.json'

const firebaseConfig = firebaseConfigJSON.default

firebase.initializeApp(firebaseConfig)

let _auth = firebase.auth()

export const auth = _auth
export const storage = firebase.storage()
export const functions = firebase.functions()
export const firestore = firebase.firestore()
export default firebase

export default [
	{ name: 'Celery', value: 'celery' },
	{ name: 'Crustaceans', value: 'crustaceans' },
	{ name: 'Eggs', value: 'eggs' },
	{ name: 'Fish', value: 'fish' },
	{ name: 'Lupin', value: 'lupin' },
	{ name: 'Milk', value: 'milk' },
	{ name: 'Molluscs', value: 'molluscs' },
	{ name: 'Mustard', value: 'mustard' },
	{ name: 'Nuts', value: 'nuts' },
	{ name: 'Peanut', value: 'peanut' },
	{ name: 'Sesame', value: 'sesame' },
	{ name: 'Soya', value: 'soya' },
	{ name: 'Sulphur dioxide', value: 'sulphur' },
	{ name: 'Cereals containing wheat', value: 'wheat' }
]

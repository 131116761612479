import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'
import Place from './Place'
import App from './Place/App'
import Billing from './Place/Billing'
import Print from './Place/Print'
import Mails from './Place/Mail'
import Helmet from 'react-helmet'
import { ModalForm, Input } from '../../../components'
import { firestore, functions } from '../../../config/firebase'
import Security from './Place/Security'

function Configuration({
	history,
	place,
	user,
	update_place,
	language,
	topbar,
}) {
	const [active, setActive] = useState(0)
	const [submitting, setSubmitting] = useState(false)
	const [showRemovePlace, setShowRemovePlace] = useState(false)
	const [removeConfirmation, setRemoveConfirmation] = useState('')

	useEffect(() => {
		const arrayOfLinks = [
			'general',
			'app',
			'billing',
			'print',
			'mails',
			'security',
		]
		let pathname = window.location.pathname
		pathname = pathname.split('/')[3]
		let index = 0

		arrayOfLinks.forEach((id, i) => {
			if (id === pathname) {
				index = i
			}
		})

		setActive(index)
	})

	const removePlace = () => {
		setSubmitting(true)
		let userRef = firestore.doc(`/users/${user.id}`)
		let placeRef = firestore.doc(`/places/${place.id}`)
		let placeListRef = firestore.doc(`/places_list/${place.id}`)
		firestore
			.runTransaction(async (transaction) => {
				let latestUserData = await transaction.get(userRef)
				await transaction.delete(placeRef)
				await transaction.delete(placeListRef)
				latestUserData = latestUserData.data()
				await transaction.update(userRef, {
					...latestUserData,
					places: latestUserData.places.filter(
						(place_id) => place_id !== place.id
					),
				})
				return latestUserData
			})
			.then(async (r) => {
				if (r.type === 'admin_premium') {
					const func_ = functions.httpsCallable('updateOrdersPricing')

					let placesSub = r.stripe_customer.subscriptions.data[0].items.data.find(
						(s) => s.plan.id === 'plan_HHUtYkkB8HAVBN'
					)
					if (typeof placesSub === 'undefined') throw { error: true }

					await func_({
						subscription_item: placesSub.id,
						quantity: r.places.length - 1,
						action: 'set',
					})
				}
				localStorage.clear()
				window.location.replace('/')
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	const renderRemovePlace = () => {
		return (
			<ModalForm hideClose size="sm">
				<div className="form-ui">
					<h1>Borrar {place.name}</h1>
					<p>Esta acción no se puede revertir.</p>
					<p>
						Para confirmar escribe
						<span
							style={{
								backgroundColor: 'rgba(255,50,50,0.1)',
								padding: 5,
								borderRadius: 5,
								marginLeft: 10,
							}}
						>
							{' '}
							{place.name}{' '}
						</span>
					</p>
					<Input
						noformik
						label="Confirmar"
						custom={{
							onChange: (e) => setRemoveConfirmation(e.target.value),
						}}
					/>
					<footer className="cols-2">
						<button
							disabled={submitting}
							className="button button-light"
							onClick={() => setShowRemovePlace(false)}
						>
							<i className="mi">close</i>
							<span>{language.cancel}</span>
						</button>
						<button
							disabled={submitting || removeConfirmation !== place.name}
							className={`button button-danger`}
							onClick={() => removePlace()}
						>
							{submitting && (
								<div role="status">
									<span>{language.loading}...</span>
								</div>
							)}
							{!submitting && (
								<>
									<i className="mi">delete</i>
									<span>Eliminar</span>
								</>
							)}
						</button>
					</footer>
				</div>
			</ModalForm>
		)
	}

	return (
		<>
			{showRemovePlace && renderRemovePlace()}
			<Helmet>
				<title>Vywap | Configuración</title>
			</Helmet>
			<div className="topbar" style={{ top: topbar.top }}>
				<ul className="tabs">
					<li className={active === 0 ? 'active' : ''}>
						<Link to="/configuration/place/general">
							{language.general}
						</Link>
					</li>
					<li className={active === 1 ? 'active' : ''}>
						<Link to="/configuration/place/app">{language.app}</Link>
					</li>
					<li className={active === 2 ? 'active' : ''}>
						<Link to="/configuration/place/billing">Facturación</Link>
					</li>
					<li className={active === 3 ? 'active' : ''}>
						<Link to="/configuration/place/print">Imprimir</Link>
					</li>
					<li className={active === 4 ? 'active' : ''}>
						<Link to="/configuration/place/mails">Correos</Link>
					</li>
					<li className={active === 5 ? 'active' : ''}>
						<Link to="/configuration/place/security">Seguridad</Link>
					</li>
				</ul>
			</div>
			<div>
				<Switch>
					<Route
						path="/configuration/place/general"
						render={(props) => (
							<Place
								place={place}
								update_place={update_place}
								user={user}
								setShowRemovePlace={setShowRemovePlace}
								{...props}
							/>
						)}
					/>
					<Route
						path="/configuration/place/app"
						render={(props) => <App {...props} />}
					/>
					<Route
						path="/configuration/place/billing"
						render={(props) => <Billing {...props} />}
					/>
					<Route
						path="/configuration/place/print"
						render={(props) => <Print {...props} />}
					/>
					<Route
						path="/configuration/place/mails"
						render={(props) => <Mails {...props} />}
					/>
					<Route
						path="/configuration/place/security"
						render={(props) => <Security {...props} />}
					/>
				</Switch>
			</div>
		</>
	)
}

export default connect((state) => ({
	language: state.language.dictionary,
	topbar: state.topbar,
}))(Configuration)

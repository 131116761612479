import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Input, Loading } from '../../../../components'
import { create_alert } from '../../../../store/actions'
import Map from '../../../PlaceSetup/Map'
import { firestore } from '../../../../config/firebase'

import { useCookies } from 'react-cookie'

function Print({ language, place, create_alert, user }) {
	const [submitting, setSubmitting] = useState(false)
	const [hasPrinter, setHasPrinter] = useState(null)
	const [selectedPrinter, setSelectedPrinter] = useState(null)
	const [cookies, setCookie, removeCookie] = useCookies(['print'])

	useEffect(() => {
		if (cookies.print) {
			setSelectedPrinter(cookies.print)
		}
		if (
			typeof user.printers === 'undefined' ||
			typeof user.printers !== 'object'
		)
			setHasPrinter(false)
		else if (Object.keys(user.printers).length === 0) setHasPrinter(false)
		else setHasPrinter(true)
	}, [])

	// if (hasPrinter === null) return <Loading />

	if (true)
		return (
			<div className="login-ui login-ui-lg">
				<div>
					<form className="form-ui">
						<h1>Pasos para configurar tu impresora</h1>
						<ol>
							<li>
								Descarga esta aplicación para tu computadora{' '}
								<a href="https://firebasestorage.googleapis.com/v0/b/vywap-prod.appspot.com/o/installables%2FVywap%20Desktop%20Setup%200.1.0.exe?alt=media&token=3117e8c5-cf88-4908-87e5-75b062831fe6">
									<i className="mi">arrow_downward</i>{' '}
									<span>WINDOWS</span>
								</a>{' '}
								<a href="https://firebasestorage.googleapis.com/v0/b/vywap-prod.appspot.com/o/installables%2FVywap%20Desktop-0.1.0.dmg?alt=media&token=29d8dd04-7859-44f9-987d-cb5c4e46392e">
									<i className="mi">arrow_downward</i> <span>MAC</span>
								</a>
							</li>
							<li>
								Tu impresora debe ser compatible con el protocolo
								ESC/POS
							</li>
							<li>
								Abre el archivo descargado y sigue los pasos de
								instalación
							</li>
							<li>
								Abre la aplicación <strong>Vywap Print</strong> e
								ingresa con tu correo y contraseña
							</li>
							<li>
								Sigue los pasos que se te indican en{' '}
								<strong>Vywap Print</strong> para seleccionar tu
								impresora
							</li>
						</ol>

						<footer>
							<button
								disabled={submitting}
								type="submit"
								className="button button-primary"
							>
								{submitting && (
									<div role="status">
										<span>{language.loading}...</span>
									</div>
								)}
								{!submitting && language.save}
							</button>
						</footer>
					</form>
				</div>
			</div>
		)

	return (
		<div className="login-ui login-ui-lg printers-ui">
			<div>
				<form className="form-ui" onSubmit={(e) => e.preventDefault()}>
					<h1>Selecciona una impresora</h1>
					{typeof user.printers === 'object' &&
						Object.keys(user.printers).map((printer_key) => (
							<div
								className={`printer ${
									selectedPrinter === printer_key ? 'selected' : ''
								}`}
							>
								<p>
									<i className="mi">print</i>
									<span>
										{printer_key} -
										{user.printers[printer_key].split('_NAME_')[0]}
									</span>
								</p>
								<button
									onClick={(e) => {
										setSelectedPrinter(printer_key)
										setCookie('print', printer_key, { path: '/' })
									}}
									className="button button-light"
									disabled={selectedPrinter === printer_key}
								>
									{selectedPrinter === printer_key
										? 'SELECCIONADA'
										: 'SELECCIONAR'}
								</button>
							</div>
						))}
					{selectedPrinter !== null && (
						<div className={`printer`}>
							<p>
								<i className="mi">close</i> <span>NINGUNA</span>
							</p>
							<button
								onClick={(e) => {
									setSelectedPrinter(null)
									removeCookie('print', { path: '/' })
								}}
								className="button button-light"
							>
								SELECCIONAR
							</button>
						</div>
					)}
				</form>
			</div>
			<footer>
				<p>
					<span>
						Descarga <strong>Vywap Print</strong> para:{' '}
					</span>
					<a href="https://firebasestorage.googleapis.com/v0/b/vywap-prod.appspot.com/o/installables%2FVywap%20Desktop%20Setup%200.1.0.exe?alt=media&token=3117e8c5-cf88-4908-87e5-75b062831fe6">
						<i className="mi">arrow_downward</i> <span>WINDOWS</span>
					</a>{' '}
					<a href="https://firebasestorage.googleapis.com/v0/b/vywap-prod.appspot.com/o/installables%2FVywap%20Desktop-0.1.0.dmg?alt=media&token=29d8dd04-7859-44f9-987d-cb5c4e46392e">
						<i className="mi">arrow_downward</i> <span>MAC</span>
					</a>
				</p>
			</footer>
		</div>
	)
}

export default connect(
	(state) => ({
		language: state.language.dictionary,
		place: state.place,
		user: state.user,
	}),
	{ create_alert }
)(Print)

import axios from 'axios'
import { Form, Formik, Field } from 'formik'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Input, ModalForm } from '../../../../components'
import _features from '../../../../config/features'
import { firestore, functions } from '../../../../config/firebase'
import { create_alert } from '../../../../store/actions'
import Map from '../../../PlaceSetup/Map'

function General({
	history,
	place,
	user,
	update_place,
	create_alert,
	language,
	lang,
	subcategories,
	setShowRemovePlace,
	employee,
}) {
	const [openHours, setOpenHours] = useState(null)
	const [point, setPoint] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [features, setFeatures] = useState(place.features)
	const [hasBilling, setHasBilling] = useState(false)

	useEffect(() => {
		setPoint(place.address.latLng)
		setOpenHours(JSON.parse(place.openHours))

		setHasBilling(place.billing.address.length > 0)
	}, [])

	const upper = (lower) => lower.charAt(0).toUpperCase() + lower.substring(1)

	if (openHours === null) return null

	const validatePlaceUpdate = (values) => {
		if (values.subcategory === '')
			return { subcategory: language.cant_be_empty }
		if (point === null) return { address: language.select_a_place }
		if (values.name === '') return { name: language.cant_be_empty }
		if (!(values.tax_rate >= 0))
			return { tax_rate: language.enter_a_valid_value }
		if (
			values.acceptInplace === false &&
			values.acceptPickup === false &&
			values.allowOrders === true
		) {
			create_alert(
				'Necesitas aceptar por lo menos un tipo de pedido',
				'danger'
			)
			return {
				acceptInplace: 'error',
				acceptPickup: 'error',
				allowOrders: 'error',
			}
		}
	}

	const submitPlaceUpdate = async (values) => {
		setSubmitting(true)
		let value = await axios.get(
			`https://maps.googleapis.com/maps/api/geocode/json?latlng=${point.lat},${point.lng}&key=AIzaSyDeio_usEPlxcZTa0nIv7mLE89R-_ljiDU&language=en`
		)
		let str = value.data.results.find(
			(res) => res.types.indexOf('street_address') !== -1
		)
		if (typeof str === 'undefined') str = value.data.results[0]

		firestore
			.runTransaction(async (transaction) => {
				let placeRef = firestore.doc(`/places/${place.id}`)
				let latestPlaceData = await transaction.get(placeRef)
				latestPlaceData = latestPlaceData.data()

				let placeListRef = firestore.doc(`/places_list/${place.id}`)
				let latestPlaceListData = await transaction.get(placeListRef)
				latestPlaceListData = latestPlaceListData.data()

				await transaction.update(placeRef, {
					name: values.name,
					type: values.type,
					address: {
						value: str.formatted_address,
						latLng: {
							lat: point.lat,
							lng: point.lng,
						},
					},
					features,
					openHours: JSON.stringify(openHours),
					taxRate: values.tax_rate,
					subcategory: values.subcategory,
					takeaway: values.takeaway,
					showPlace: values.showPlace,
					allowOrders: values.allowOrders,
					acceptInplace: values.acceptInplace,
					acceptOnlyDrink: values.acceptOnlyDrink,
					acceptPickup: values.acceptPickup,
				})

				await transaction.update(placeListRef, {
					name: values.name,
					type: values.type,
					takeaway: values.takeaway,
					address: {
						value: str.formatted_address,
						latLng: {
							lat: point.lat,
							lng: point.lng,
						},
					},
					openHours: JSON.stringify(openHours),
					subcategory: values.subcategory,
					features,
					showPlace: values.showPlace,
					allowOrders: values.allowOrders,
					acceptInplace: values.acceptInplace,
					acceptOnlyDrink: values.acceptOnlyDrink,
					acceptPickup: values.acceptPickup,
				})
			})
			.then(async () => {
				await functions.httpsCallable('updatePlaceSchedule')({
					id: place.id,
				})
				update_place({
					name: values.name,
					type: values.type,
					address: {
						value: str.formatted_address,
						latLng: {
							lat: point.lat,
							lng: point.lng,
						},
					},
					features,
					openHours: JSON.stringify(openHours),
					taxRate: values.tax_rate,
					subcategory: values.subcategory,
				})
				create_alert(language.place_updated, 'success')
				setSubmitting(false)
			})
			.catch((e) => {
				create_alert(language.an_error_ocurred, 'danger')
			})
	}

	const renderTimes = (obj, type) => {
		let _res = new Array(31).fill(new Array(2).fill('t'))
		let res = []
		_res.forEach((a, b) => {
			let _b = b >= 24 ? b - 24 : b
			a.forEach((c, d) => {
				let _d = d >= 24 ? d - 24 : d
				let mins = d === 1 ? 30 : 0
				let _mins = mins < 10 ? `0${mins}` : mins
				let _hours = b < 10 ? `0${b}` : b

				let __hours =
					_b < 10
						? `${b >= 24 ? '+ ' : ''}0${_b}`
						: `${b >= 24 ? '+ ' : ''}${_b}`
				let __mins_ = _d === 1 ? 30 : 0
				let __mins = __mins_ < 10 ? `0${__mins_}` : __mins_

				let disabled = false

				if (type === 0) {
					let _time = obj[0][1]
					let _mins_ = _time[1] < 10 ? `0${_time[1]}` : _time[1]
					let _hours_ = _time[0] < 10 ? `0${_time[0]}` : _time[0]
					disabled =
						parseInt(`${_hours}${_mins}`) >=
						parseInt(`${_hours_}${_mins_}`)
				} else if (type === 1) {
					let _time = obj[0][0]
					let _mins_ = _time[1] < 10 ? `0${_time[1]}` : _time[1]
					let _hours_ = _time[0] < 10 ? `0${_time[0]}` : _time[0]
					disabled =
						parseInt(`${_hours}${_mins}`) <=
						parseInt(`${_hours_}${_mins_}`)
				} else if (type === 2) {
					let _time = obj[0][1]
					let _mins_ = _time[1] < 10 ? `0${_time[1]}` : _time[1]
					let _hours_ = _time[0] < 10 ? `0${_time[0]}` : _time[0]
					disabled =
						parseInt(`${_hours}${_mins}`) <=
						parseInt(`${_hours_}${_mins_}`)
				} else if (type === 3) {
					let _time = obj[1][0]
					let _mins_ = _time[1] < 10 ? `0${_time[1]}` : _time[1]
					let _hours_ = _time[0] < 10 ? `0${_time[0]}` : _time[0]
					disabled =
						parseInt(`${_hours}${_mins}`) <=
						parseInt(`${_hours_}${_mins_}`)
				}

				res.push(
					<option
						disabled={disabled}
						key={`${b}_${mins}`}
						value={[b, mins]}
					>{`${__hours}:${__mins}`}</option>
				)
			})
		})
		return res
	}

	const renderOpenHours = () => {
		let rows = []
		let weekdays = [
			upper(language.monday),
			upper(language.tuesday),
			upper(language.wednesday),
			upper(language.thursday),
			upper(language.friday),
			upper(language.saturday),
			upper(language.sunday),
		]
		for (let x = 0; x < openHours.length; x++) {
			let row = []
			row.push(
				<div key={x} id={x}>
					<div className="input-check">
						<input
							type="checkbox"
							id={`check-${x}`}
							checked={openHours[x].opens}
							onChange={(e) => {
								let _op = [...openHours]
								_op[x] = {
									..._op[x],
									opens: e.target.checked,
								}
								setOpenHours(_op)
							}}
						/>
						<label htmlFor={`check-${x}`}>Toggle</label>
					</div>
					<p>
						{weekdays[x]}
						<button
							type="button"
							onClick={() => {
								let _op = [...openHours]
								_op[x] = {
									..._op[x],
									splitted: !_op[x].splitted,
								}
								_op[x].hours[1][0] = [
									_op[x].hours[0][1][0] + 1,
									_op[x].hours[0][1][1],
								]
								_op[x].hours[1][1] = [
									_op[x].hours[0][1][0] + 2,
									_op[x].hours[0][1][1],
								]
								setOpenHours(_op)
							}}
						>
							{openHours[x].splitted
								? 'Servicio normal'
								: 'Dividir servicio'}
						</button>
					</p>
					<div>
						<div className="select">
							<select
								disabled={!openHours[x].opens}
								value={`${openHours[x].hours[0][0]}`}
								onChange={(e) => {
									setOpenHours(
										openHours.map((op, i) => {
											if (i !== x) return op
											let val = e.target.value.split(',')
											val = val.map((vl) => parseInt(vl))
											return {
												...op,
												hours: [[val, op.hours[0][1]], op.hours[1]],
											}
										})
									)
								}}
							>
								{renderTimes(openHours[x].hours, 0)}
							</select>
						</div>
						<div className="select">
							<select
								disabled={!openHours[x].opens}
								value={`${openHours[x].hours[0][1]}`}
								onChange={(e) => {
									setOpenHours(
										openHours.map((op, i) => {
											if (i !== x) return op
											let val = e.target.value.split(',')
											val = val.map((vl) => parseInt(vl))
											return {
												...op,
												hours: [[op.hours[0][0], val], op.hours[1]],
											}
										})
									)
								}}
							>
								{renderTimes(openHours[x].hours, 1)}
							</select>
						</div>
						{openHours[x].splitted && (
							<>
								<p> - </p>
								<div className="select">
									<select
										disabled={!openHours[x].opens}
										value={`${openHours[x].hours[1][0]}`}
										onChange={(e) => {
											setOpenHours(
												openHours.map((op, i) => {
													if (i !== x) return op
													let val = e.target.value.split(',')
													val = val.map((vl) => parseInt(vl))
													return {
														...op,
														hours: [
															op.hours[0],
															[val, op.hours[1][1]],
														],
													}
												})
											)
										}}
									>
										{renderTimes(openHours[x].hours, 2)}
									</select>
								</div>
								<div className="select">
									<select
										disabled={!openHours[x].opens}
										value={`${openHours[x].hours[1][1]}`}
										onChange={(e) => {
											setOpenHours(
												openHours.map((op, i) => {
													if (i !== x) return op
													let val = e.target.value.split(',')
													val = val.map((vl) => parseInt(vl))
													return {
														...op,
														hours: [
															op.hours[0],
															[op.hours[1][0], val],
														],
													}
												})
											)
										}}
									>
										{renderTimes(openHours[x].hours, 3)}
									</select>
								</div>
							</>
						)}
					</div>
				</div>
			)
			rows.push(row)
		}
		return rows
	}

	const renderFeatures = () => {
		// Filtered features
		let filt_features = _features.filter((feat) => {
			switch (feat.key) {
				case 'products':
					if (
						typeof user.custom !== 'undefined' &&
						user.custom.indexOf('hide_orders') !== -1
					)
						return false
					return true
				case 'tables':
					if (place.type === 'restaurant' || place.type === 'disco')
						return true
					return false
				case 'events':
					return true
				case 'employees':
					if (
						typeof user.custom !== 'undefined' &&
						user.custom.indexOf('employees_global') !== -1
					)
						return false
					return true
				case 'queue':
					return true
				case 'reservations':
					return true
				case 'reservations_covid':
					return true
				default:
					return false
			}
		})
		let descriptions = {
			products:
				'Recibe pedidos desde la App (Mas adelante puedes configurar las formas de pago).',
			tables: 'Maneja los estados de los pedidos en las mesas de tu local.',
			events: 'Crea y vende entradas para los eventos en tu local.',
			employees:
				'Administra a todos tus empleados, asigna roles, y analiza las estadisticas del tiempo.',
			queue: 'Crea filas virtuales para tu local evitando congestiones.',
			reservations_covid:
				'Crea reservas por Franjas y Turnos, y controla los aforos de tu sitio.',
			reservations:
				'Administra las reservas de las mesas de tu local (Requiere Mesas).',
		}
		let fields = filt_features.map((feat) => (
			<div>
				<input
					key={feat.key}
					type="checkbox"
					name={`feat_${feat.key}`}
					id={`feat_${feat.key}`}
					checked={features.indexOf(feat.key) !== -1}
					onChange={(e) => {
						let _feats = [...features]

						if (e.target.checked) {
							setFeatures([..._feats, feat.key])
						} else if (_feats.length > 1) {
							if (feat.key !== 'products') {
								setFeatures(_feats.filter((f) => f !== feat.key))
							}
						}
					}}
				/>
				<label htmlFor={`feat_${feat.key}`}>
					<b></b>{' '}
					<span>
						{feat.key === 'products'
							? 'Pedidos'
							: feat.key === 'reservations_covid' &&
							  typeof user.custom !== 'undefined' &&
							  user.custom.indexOf('covid_res') === -1
							? 'Reserva Avanzada'
							: language[feat.key]}
					</span>
					<em>{descriptions[feat.key]}</em>
				</label>
			</div>
		))
		return (
			<div className="form-group form-group-check multiple">
				<div className="form-row">{fields}</div>
			</div>
		)
	}

	return (
		<>
			{!hasBilling && (
				<div className="alert alert-warning">
					<i className="mi">warning</i>
					<p>Aún no has configurado la información de facturación</p>
					<button
						className="button button-warning buton-small"
						onClick={() => {
							history.replace('/configuration/place/billing')
						}}
					>
						Configurar
					</button>
				</div>
			)}
			<div className="login-ui login-ui-lg">
				<div>
					{place.id !== null && (
						<Formik
							initialValues={{
								name: place.name,
								address: null,
								type: place.type,
								tax_rate: place.taxRate,
								takeaway:
									typeof place.takeaway !== 'undefined'
										? place.takeaway
										: false,
								subcategory:
									typeof place.subcategory === 'undefined'
										? ''
										: place.subcategory,
								showPlace:
									typeof place.showPlace !== 'undefined'
										? place.showPlace
										: false,
								allowOrders:
									typeof place.allowOrders !== 'undefined'
										? place.allowOrders
										: false,
								acceptInplace:
									typeof place.acceptInplace !== 'undefined'
										? place.acceptInplace
										: false,
								acceptOnlyDrink:
									typeof place.acceptOnlyDrink !== 'undefined'
										? place.acceptOnlyDrink
										: false,
								acceptPickup:
									typeof place.acceptPickup !== 'undefined'
										? place.acceptPickup
										: false,
							}}
							validate={validatePlaceUpdate}
							onSubmit={submitPlaceUpdate}
						>
							{({ errors, touched, setFieldValue, values }) => (
								<Form className="form-ui">
									{/* <h1>Active features</h1>
									{renderFeatures()} */}
									<h1>{language.general}</h1>
									{typeof place.groups === 'object' &&
										place.groups.length > 0 && (
											<div className="card vcard placecard">
												<div
													className="avatar has-image"
													className={`avatar ${
														place.groups[0].photo !== null
															? 'has-image'
															: ''
													}`}
												>
													<span
														className="img"
														style={{
															backgroundImage: `url(${place.groups[0].photo})`,
														}}
													>
														<i className="mi">store</i>
													</span>
												</div>
												<div className="body">
													<strong className="title">
														{place.groups[0].name}
													</strong>
													<em>Incluído en este grupo</em>
												</div>
											</div>
										)}
									<Input
										label={language.place_name}
										submitting={submitting}
										type="text"
										name="name"
										errors={errors}
										touched={touched}
									/>
									<div className="form-group upper-7">
										<div className="form-row">
											<div className="col-3">
												<label>{language.category}:</label>
											</div>
											<div className="col-9">
												<Select
													className="react-select"
													classNamePrefix="react-select"
													options={[
														{
															value: 'restaurant',
															label: language.restaurant,
														},
														{
															value: 'disco',
															label: language.discos_and_pubs,
														},
														{
															value: 'store',
															label: language.store,
														},
														{
															value: 'beauty',
															label: 'Belleza',
														},
													]}
													onChange={(e) => {
														setFieldValue('type', e.value)
														setFieldValue('subcategory', '')
													}}
													defaultValue={{
														value: place.type,
														label: `${
															place.type === 'restaurant'
																? language.restaurant
																: place.type === 'store'
																? language.store
																: place.type === 'beauty'
																? 'Belleza'
																: language.discos_and_pubs
														}`,
													}}
												/>
											</div>
										</div>
									</div>
									<div
										className={`form-group upper-5 ${
											errors.subcategory && touched.subcategory
												? 'is-invalid'
												: ''
										}`}
									>
										<div className="form-row">
											<div className="col-3">
												<label>{language.subcategory}:</label>
											</div>
											<div className="col-9">
												<Select
													className="react-select"
													classNamePrefix="react-select"
													options={[
														{
															value: '',
															label: language.select_subcategory,
														},
														...subcategories[values.type].map(
															(x) => ({
																value: x,
																label: upper(language[x]),
															})
														),
													]}
													onChange={(e) => {
														setFieldValue('subcategory', e.value)
													}}
													defaultValue={{
														value:
															typeof place.subcategory ===
															'undefined'
																? ''
																: place.subcategory,
														label:
															typeof place.subcategory ===
															'undefined'
																? language.select_subcategory
																: upper(
																		language[
																			place.subcategory
																		]
																  ),
													}}
													value={{
														value: values.subcategory,
														label:
															values.subcategory === ''
																? language.select_subcategory
																: upper(
																		language[
																			values.subcategory
																		]
																  ),
													}}
												/>
											</div>
										</div>
										{errors.subcategory && touched.subcategory ? (
											<small>
												<span>{errors.subcategory}</span>
											</small>
										) : null}
									</div>

									{/* <div className="form-group form-group-check">
										<div className="form-row">
											<div className="col-12">
												<Field
													type="checkbox"
													name="takeaway"
													id="takeaway"
													checked={values.takeaway}
												/>
												<label htmlFor="takeaway">
													<b></b> <span>¿Ofreces Domicilios?</span>
												</label>
											</div>
										</div>
									</div> */}

									<div className="form-group form-group-check">
										<div className="form-row">
											<div className="col-12">
												<Field
													type="checkbox"
													name="showPlace"
													id="showPlace"
													checked={values.showPlace}
												/>
												<label htmlFor="showPlace">
													<b></b>{' '}
													<span>Mostrar mi lugar en la App</span>
												</label>
											</div>
										</div>
									</div>

									{values.showPlace && user.type !== 'admin_free' && (
										<div className="form-group form-group-check">
											<div className="form-row">
												<div className="col-12">
													<Field
														disabled={
															user.type !== 'admin_premium'
														}
														type="checkbox"
														name="allowOrders"
														id="allowOrders"
														checked={values.allowOrders}
													/>
													<label htmlFor="allowOrders">
														<b></b>{' '}
														<span>
															Recibir pedidos desde la App
														</span>
													</label>
												</div>
											</div>
										</div>
									)}
									{values.allowOrders && (
										<>
											<div className="form-group form-group-check">
												<div className="form-row">
													<div className="col-12">
														<Field
															type="checkbox"
															name="acceptPickup"
															id="acceptPickup"
															checked={values.acceptPickup}
														/>
														<label htmlFor="acceptPickup">
															<b></b>{' '}
															<span>
																Acepto pedidos para recoger
															</span>
														</label>
													</div>
												</div>
											</div>
											{(place.type === 'restaurant' ||
												place.type === 'disco') && (
												<div className="form-group form-group-check">
													<div className="form-row">
														<div className="col-12">
															<Field
																type="checkbox"
																name="acceptInplace"
																id="acceptInplace"
																checked={values.acceptInplace}
															/>
															<label htmlFor="acceptInplace">
																<b></b>{' '}
																<span>
																	Acepto pedidos para consumir
																	aquí
																</span>
															</label>
														</div>
													</div>
												</div>
											)}
											{(place.type === 'restaurant' ||
												place.type === 'disco') &&
												values.acceptInplace && (
													<div className="form-group form-group-check">
														<div className="form-row">
															<div className="col-12">
																<Field
																	type="checkbox"
																	name="acceptOnlyDrink"
																	id="acceptOnlyDrink"
																	checked={
																		values.acceptOnlyDrink
																	}
																/>
																<label htmlFor="acceptOnlyDrink">
																	<b></b>{' '}
																	<span>
																		Acepto{' '}
																		<strong>
																			Exclusivamente{' '}
																		</strong>
																		bebidas <br />
																		para consumir en mi
																		negocio
																	</span>
																</label>
															</div>
														</div>
													</div>
												)}
										</>
									)}

									<div className="form-group form-group-map upper-2">
										<div className="location-map">
											<Map
												_point={point}
												onPointSelected={(latLng) => {
													setPoint({
														lat: latLng.lat,
														lng: latLng.lng,
													})
													setFieldValue('address', latLng)
												}}
											/>
										</div>
									</div>
									<div className="form-group form-group-schedule">
										<div>
											<label>{language.schedule}:</label>
											<div>{renderOpenHours()}</div>
										</div>
									</div>
									{user.type === 'admin_premium' && (
										<>
											<h1>{language.active_features}</h1>
											{renderFeatures()}
										</>
									)}
									<footer>
										<button
											disabled={submitting}
											type="submit"
											className="button button-primary"
										>
											{submitting && (
												<div role="status">
													<span>{language.loading}...</span>
												</div>
											)}
											{!submitting && language.save}
										</button>
										{employee.category === 'main_admin' && (
											<button
												onClick={(e) => setShowRemovePlace(true)}
												className="button button-link button-link-sm button-link-delete "
												type="button"
											>
												<i className="mi">delete</i>
												<span>Eliminar lugar</span>
											</button>
										)}
									</footer>
								</Form>
							)}
						</Formik>
					)}
				</div>
			</div>
		</>
	)
}

export default connect(
	(state) => ({
		language: state.language.dictionary,
		lang: state.language.lang,
		subcategories: state.subcategories,
		employee: state.employee,
		user: state.user,
	}),
	{ create_alert }
)(General)
